import { Loader, Select, SelectProps } from '@mantine/core';
import { useCommodities } from '../api/commodity';

export interface CommoditySelectSpecificProps {
  value: string | null;
  onChange: (commodityId: string | null) => void;
}

export type CommoditySelectProps = Omit<
  SelectProps,
  keyof CommoditySelectSpecificProps | 'data'
> &
  CommoditySelectSpecificProps;

export function CommoditySelect(props: CommoditySelectProps) {
  const {
    value,
    onChange,
    label = 'Commodity',
    placeholder = 'Select commodity',
    nothingFound = 'No matching commodities',
    clearable = true,
    ...selectProps
  } = props;
  const {
    data: commodities,
    isLoading,
    isLoadingError,
    error,
  } = useCommodities();

  if (isLoadingError) {
    throw error;
  }

  const selectData =
    commodities?.map(({ id, name }) => ({ label: name, value: id })) ?? [];

  return (
    <Select
      value={value}
      onChange={onChange}
      data={selectData}
      label={label}
      rightSection={isLoading ? <Loader size='xs' /> : undefined}
      placeholder={placeholder}
      nothingFound={nothingFound}
      clearable={clearable}
      {...selectProps}
    />
  );
}
