import { Group, Loader, Text } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  Controls,
  Edge,
  Node,
  Panel,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useOnSelectionChange,
} from 'reactflow';
import { match } from 'ts-pattern';
import { MaterialSetDTO } from '../rest-client';
import FeedFlowGroupNode from './FeedFlowGroupNode';
import {
  GenealogyGraphProvider,
  useGenealogyGraphCtx,
} from './GenealogyGraphContext';
import { InternalMaterialSinkNode } from './InternalMaterialSinkNode';
import MaterialSetNode from './MaterialSetNode';
import { useGenealogyGraphLayout } from './elkLayout';
import useGenealogyGraphData from './useGenealogyGraphData';
import useGenealogyGraphStructure, {
  GenealogyGraphEdge,
} from './useGenealogyGraphStructure';

const nodeTypes = {
  materialSet: MaterialSetNode,
  feedFlowGroup: FeedFlowGroupNode,
  internalSink: InternalMaterialSinkNode,
};

export interface FlowMaterialSetNodeData {
  producerIsFringe: boolean;
  consumerIsFringe: boolean;
  materialSet: MaterialSetDTO;
  isRoot: boolean;
}

type FlowMaterialSetNode = Node<FlowMaterialSetNodeData> & {
  type: 'materialSet';
};

export interface FlowFeedFlowGroupNodeData {
  feedFlowGroupId: string;
}

type FlowFeedFlowGroupNode = Node<FlowFeedFlowGroupNodeData> & {
  type: 'feedFlowGroup';
};

export interface FlowInternalNodeSinkData {
  internalMaterialSinkId: string;
}

type FlowNodeData =
  | FlowMaterialSetNodeData
  | FlowFeedFlowGroupNodeData
  | FlowInternalNodeSinkData;

type FlowInternalSinkNode = Node<FlowInternalNodeSinkData> & {
  type: 'internalSink';
};

type FlowNode =
  | FlowInternalSinkNode
  | FlowFeedFlowGroupNode
  | FlowMaterialSetNode;

function flowMaterialSetNode(
  materialSet: MaterialSetDTO,
  isRoot: boolean,
  position: { x: number; y: number } | null,
  producerIsFringe: boolean,
  consumerIsFringe: boolean,
): FlowMaterialSetNode {
  return {
    id: materialSet.hash,
    position: position ?? { x: 0, y: 0 },
    type: 'materialSet',
    selectable: true,
    hidden: false,
    data: {
      materialSet,
      isRoot,
      producerIsFringe,
      consumerIsFringe,
    },
  };
}

function flowFeedFlowGroupNode(
  feedFlowGroupId: string,
  position: { x: number; y: number } | undefined,
): FlowFeedFlowGroupNode {
  return {
    id: feedFlowGroupId,
    position: position ?? { x: 0, y: 0 },
    type: 'feedFlowGroup',
    selectable: false,
    hidden: false,
    data: {
      feedFlowGroupId,
    },
  };
}

function flowInternalSinkNode(
  internalMaterialSinkId: string,
  position: { x: number; y: number } | undefined,
): FlowInternalSinkNode {
  return {
    id: internalMaterialSinkId,
    position: position ?? { x: 0, y: 0 },
    type: 'internalSink',
    selectable: false,
    hidden: false,
    data: {
      internalMaterialSinkId,
    },
  };
}

function flowEdge(edge: GenealogyGraphEdge): Edge<unknown> {
  const commonEdgeProps = {
    labelStyle: {
      fontSize: 14,
      fontWeight: 400,
    },
    hidden: false,
    animated: true,
  };

  return match(edge)
    .with({ kind: 'inter-material-set' }, ({ id, sourceHash, targetHash }) => ({
      id,
      source: sourceHash,
      target: targetHash,
      // label: 'Feedstock', // TODO(2315):  see interMaterialSetEdgeLabel
      ...commonEdgeProps,
    }))
    .with(
      { kind: 'feedstock' },
      ({ id, materialSetHash, feedFlowGroupId }) => ({
        id,
        source: materialSetHash,
        target: feedFlowGroupId,
        label: 'Feedstock',
        ...commonEdgeProps,
      }),
    )
    .with({ kind: 'process-output' }, ({ id, effect }) => ({
      id,
      source: effect.feedFlowGroupId,
      sourceHandle: effect.outputPortId,
      target: effect.output,
      // TODO(2315): Include port name on output label
      label: `Output`,
      ...commonEdgeProps,
    }))
    .with(
      { kind: 'internal-material-sink' },
      ({ id, materialSetHash, internalMaterialSinkId }) => ({
        id,
        source: materialSetHash,
        target: internalMaterialSinkId,
        label: 'Removed',
        ...commonEdgeProps,
      }),
    )
    .exhaustive();
}

function ReactFlowGenealogyGraph(props: {
  setSelectedMaterialSet: (materialSet: MaterialSetDTO | null) => void;
}) {
  const { setSelectedMaterialSet } = props;
  const { rootMaterialSet } = useGenealogyGraphCtx();

  useOnSelectionChange({
    onChange: ({ nodes }) => {
      if (nodes.length === 0) {
        setSelectedMaterialSet(null);
      } else {
        const firstNode = nodes[0] as FlowNode;
        if (firstNode.type === 'materialSet') {
          setSelectedMaterialSet(firstNode.data.materialSet);
        }
      }
    },
  });

  // initialize with just the root node
  const rootNode = flowMaterialSetNode(
    rootMaterialSet,
    true,
    null,
    false,
    false,
  );

  const [nodes, setNodes, onNodesChange] = useNodesState<FlowNodeData>([
    rootNode,
  ]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const materialSetsResult = useGenealogyGraphData();

  const structureResult = useGenealogyGraphStructure(materialSetsResult);
  const layout = useGenealogyGraphLayout(
    match(structureResult)
      .with({ status: 'success' }, ({ structure }) => structure)
      .otherwise(() => undefined),
  );

  // TODO(2315): I'm not sure if there is an easy way to do this without useEffect
  useEffect(() => {
    const nodePositions = layout.data;

    if (nodePositions && structureResult.status === 'success') {
      const { structure } = structureResult;

      const flowMaterialSetNodes = structure.materialSetNodes.map(
        ({ id, materialSet, onAncestorFringe, onDescendantFringe }) =>
          flowMaterialSetNode(
            materialSet,
            materialSet.hash === rootMaterialSet.hash,
            nodePositions.get(id) ?? null,
            onAncestorFringe ||
              structure.fringeEffects.has(
                materialSet.producingEffect.effectHash,
              ),
            onDescendantFringe ||
              (materialSet.consumingEffect !== null &&
                structure.fringeEffects.has(
                  materialSet.consumingEffect.effectHash,
                )),
          ),
      );
      const flowFfgNodes = structure.feedFlowGroupNodes.map((ffgNode) =>
        flowFeedFlowGroupNode(ffgNode.id, nodePositions.get(ffgNode.id)),
      );

      const flowInternalSinkNodes = structure.internalSinkNodes.map(
        (internalSinkNode) =>
          flowInternalSinkNode(
            internalSinkNode.internalMaterialSinkId,
            nodePositions.get(internalSinkNode.id),
          ),
      );

      setNodes([
        ...flowMaterialSetNodes,
        ...flowFfgNodes,
        ...flowInternalSinkNodes,
      ]);
      setEdges(structure.edges.map(flowEdge));
    }
    // TODO(2315): Hook deps are not correct because things aren't getting memoized correctly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layout.data,
    structureResult.status,
    rootMaterialSet.hash,
    setNodes,
    setEdges,
  ]);

  // TODO(2315): Render loading indicators for various states of layout and structure.
  // TODO(2315): Remember to include progress bar for material set queries

  return (
    <ReactFlow
      id={`genealogy-${rootMaterialSet.materialStateInferenceHash}/${rootMaterialSet.hash}`}
      style={{ height: '100%', width: '100%' }}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      elementsSelectable={false}
      nodesConnectable={false}
      // TODO(2315): Selection change and map to context
      proOptions={{ hideAttribution: true }}
    >
      <Background />
      <Controls />
      <Panel position='top-left'>
        {match(structureResult)
          .with({ status: 'ledger-error' }, () => (
            <Text color='red'>Ledger Error</Text>
          ))
          .with({ status: 'query-error' }, () => (
            <Text color='red'>Data Fetching Error</Text>
          ))
          .with({ status: 'loading' }, () => (
            <Group>
              <Loader variant='dots' size='xs' />
              <Text color='dimmed' size='xs'>
                Loading material data
              </Text>
            </Group>
          ))
          .with({ status: 'success' }, () => null)
          .exhaustive()}
      </Panel>
      <Panel position='top-right'>
        {layout.isError ? (
          <Text color='red' size='md'>
            Layout failed
          </Text>
        ) : null}
        {layout.isFetching ? (
          <Group>
            <Loader variant='dots' size='xs' />
            <Text color='dimmed' size='xs'>
              Running layout
            </Text>
          </Group>
        ) : null}
      </Panel>
    </ReactFlow>
  );
}

function producingEffectRelatedMaterialSets(materialSet: MaterialSetDTO) {
  return [
    ...materialSet.directAncestors,
    ...materialSet.producingEffect.consumedMaterialSets,
    ...materialSet.producingEffect.producedMaterialSets,
  ];
}

function consumingEffectRelatedMaterialSets(materialSet: MaterialSetDTO) {
  return [
    ...materialSet.directDescendants,
    ...(materialSet.consumingEffect?.consumedMaterialSets ?? []),
    ...(materialSet.consumingEffect?.producedMaterialSets ?? []),
  ];
}

export default function GenealogyGraph2(props: {
  rootMaterialSet: MaterialSetDTO;
  setSelectedMaterialSet: (materialSet: MaterialSetDTO | null) => void;
}) {
  const { rootMaterialSet, setSelectedMaterialSet } = props;

  const [materialSetHashes, setMaterialSetHashes] = useState<string[]>([
    // initialize to the immedate neighborhood
    ...rootMaterialSet.directAncestors,
    ...rootMaterialSet.directDescendants,
    ...rootMaterialSet.producingEffect.producedMaterialSets,
    ...rootMaterialSet.producingEffect.consumedMaterialSets,
    ...(rootMaterialSet.consumingEffect?.consumedMaterialSets ?? []),
    ...(rootMaterialSet.consumingEffect?.producedMaterialSets ?? []),
  ]);

  // TODO(2315): These should set the selected material set. Need to make that controlled somehow
  const addProducingEffect = useCallback(
    (materialSet: MaterialSetDTO) => {
      setMaterialSetHashes((currentHashes) => [
        ...new Set([
          ...currentHashes,
          ...producingEffectRelatedMaterialSets(materialSet),
        ]),
      ]);
    },
    [setMaterialSetHashes],
  );

  const addConsumingEffect = useCallback(
    (materialSet: MaterialSetDTO) => {
      setMaterialSetHashes((currentHashes) => [
        ...new Set([
          ...currentHashes,
          ...consumingEffectRelatedMaterialSets(materialSet),
        ]),
      ]);
    },
    [setMaterialSetHashes],
  );

  return (
    <GenealogyGraphProvider
      rootMaterialSet={rootMaterialSet}
      materialSetHashes={materialSetHashes}
      addProducingEffect={addProducingEffect}
      addConsumingEffect={addConsumingEffect}
    >
      <ReactFlowProvider>
        <ReactFlowGenealogyGraph
          setSelectedMaterialSet={setSelectedMaterialSet}
        />
      </ReactFlowProvider>
    </GenealogyGraphProvider>
  );
}
