import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import Temporal from '../Temporal/temporal';
import {
  FacilityDTO,
  FacilityId,
  ProcessPlannedProductionIntervalDTO,
  ProcessPlannedProductionIntervalId,
  ProcessPlannedProductionIntervalPatchDTO,
  ProductionService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchProductionIntervals(
  ctx: QueryFunctionContexts['processPlannedProductionInterval']['forProcessDay'],
) {
  const [{ facilityId, processId, date }] = ctx.queryKey;
  return await ProductionService.getProductionIntervals(
    facilityId,
    processId,
    date?.format('YYYY-MM-DD'),
  );
}

export function useProductionIntervals({
  facility,
  processId,
  date,
}: {
  facility: FacilityDTO;
  processId: string | undefined;
  date: Dayjs | undefined;
}) {
  return useQuery({
    queryKey: queryKeys.processPlannedProductionInterval.forProcessDay(
      facility.id,
      processId,
      date?.tz(facility.timeZoneId),
    ),
    queryFn: fetchProductionIntervals,
  });
}

async function fetchProductionInterval(
  ctx: QueryFunctionContexts['processPlannedProductionInterval']['detail'],
) {
  const [{ id }] = ctx.queryKey;
  return await ProductionService.getProductionInterval(id);
}

export function useProductionInterval(id: ProcessPlannedProductionIntervalId) {
  return useQuery({
    queryKey: queryKeys.processPlannedProductionInterval.detail(id),
    queryFn: fetchProductionInterval,
  });
}

async function createProductionIntervals(
  productionIntervals: ProcessPlannedProductionIntervalDTO[],
) {
  await ProductionService.createProductionIntervals(productionIntervals);
}

export function useAddProductionIntervals() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createProductionIntervals,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.processPlannedProductionInterval.lists(),
      );
    },
  });
}
async function patchProductionInterval(
  productionIntervalId: ProcessPlannedProductionIntervalId,
  productionIntervalPatch: ProcessPlannedProductionIntervalPatchDTO,
) {
  await ProductionService.patchProductionInterval(
    productionIntervalId,
    productionIntervalPatch,
  );
}

export function usePatchProductionInterval(
  productionIntervalId: ProcessPlannedProductionIntervalId,
  facilityId: FacilityId,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (patch: ProcessPlannedProductionIntervalPatchDTO) =>
      await patchProductionInterval(productionIntervalId, patch),
    onSettled() {
      void queryClient.invalidateQueries(
        queryKeys.processPlannedProductionInterval.forFacility(facilityId),
      );
      void queryClient.invalidateQueries(
        queryKeys.processPlannedProductionInterval.temporalStats.byId(
          productionIntervalId,
        ),
      );
      void queryClient.invalidateQueries(
        queryKeys.processPlannedProductionInterval.detail(productionIntervalId),
      );
    },
  });
}

async function deleteProductionInterval(
  productionIntervalId: ProcessPlannedProductionIntervalId,
) {
  await ProductionService.deleteProductionInterval(productionIntervalId);
}

export function useDeleteProductionInterval(facilityId: FacilityId) {
  const invalidator = useQueryKeyInvalidator();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteProductionInterval,
    onSettled(data, error, productionIntervalId) {
      invalidator.invalidateKeys(
        queryKeys.processPlannedProductionInterval.temporalStats.byId(
          productionIntervalId,
        ),
        queryKeys.processPlannedProductionInterval.detail(productionIntervalId),
      );
      void queryClient.invalidateQueries(
        queryKeys.processPlannedProductionInterval.forFacility(facilityId),
      );
    },
  });
}

async function fetchProductionIntervalStatus(
  ctx: QueryFunctionContexts['processPlannedProductionInterval']['temporalStats']['byId'],
) {
  const [{ id }] = ctx.queryKey;

  return await ProductionService.getProductionIntervalStatus(id);
}

export function useProductionIntervalStatus(
  id: ProcessPlannedProductionIntervalId,
) {
  return useQuery({
    queryKey: queryKeys.processPlannedProductionInterval.temporalStats.byId(id),
    queryFn: fetchProductionIntervalStatus,
  });
}

async function fetchProductionIntervalMassStats(
  ctx: QueryFunctionContexts['processPlannedProductionInterval']['massStats']['byId'],
) {
  const [{ id }] = ctx.queryKey;

  return await ProductionService.getProductionIntervalMassStats(id);
}

export function useProductionIntervalMassStats(id: string) {
  return useQuery({
    queryKey: queryKeys.processPlannedProductionInterval.massStats.byId(id),
    queryFn: fetchProductionIntervalMassStats,
  });
}

async function fetchProductionAggregateTemporalMetrics(
  ctx: QueryFunctionContexts['productionAggregateTemporalMetrics']['detail'],
) {
  const [{ facilityId, after, before }] = ctx.queryKey;
  const defaultedBefore = before ?? Temporal.Now.instant();
  return await ProductionService.getProductionAggregateTemporalMetrics(
    after.toString(),
    defaultedBefore.toString(),
    facilityId ?? undefined,
  );
}

export function useProductionAggregateTemporalMetrics(args: {
  facilityId: string;
  after: Temporal.Instant;
  before?: Temporal.Instant;
}) {
  return useQuery({
    queryKey: queryKeys.productionAggregateTemporalMetrics.detail(args),
    queryFn: fetchProductionAggregateTemporalMetrics,
  });
}
