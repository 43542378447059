import { useMutation, useQuery } from '@tanstack/react-query';
import {
  SamplingSuiteCapturePatchDTO,
  SamplingSuiteService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';
import { client } from './restClient';

async function fetchSamplingSuites() {
  const { data } = await client.GET('/sampling-suites');
  if (!data) throw new Error();
  return data;
}

export function useSamplingSuites() {
  return useQuery({
    queryKey: queryKeys.samplingSuite.list(),
    queryFn: fetchSamplingSuites,
  });
}

async function fetchSamplingSuiteCaptures(
  ctx: QueryFunctionContexts['samplingSuiteCapture']['list'],
) {
  const [{ unlinked, suiteId }] = ctx.queryKey;
  return await SamplingSuiteService.getSamplingSuiteCaptures(
    suiteId ?? undefined,
    unlinked ?? undefined,
  );
}

export function useSamplingSuiteCaptures(args: {
  unlinked: boolean | null;
  suiteId: string | null;
}) {
  return useQuery({
    queryKey: queryKeys.samplingSuiteCapture.list(args),
    queryFn: fetchSamplingSuiteCaptures,
  });
}

async function fetchSamplingSuiteCapturePng(
  ctx: QueryFunctionContexts['samplingSuiteCapture']['png'],
) {
  const [{ captureId }] = ctx.queryKey;
  const { data } = await client.GET(
    '/sampling-suites/captures/{captureGuid}/image',
    {
      params: {
        path: {
          captureGuid: captureId,
        },
      },
      parseAs: 'blob',
    },
  );
  if (!data) {
    throw new Error();
  }
  return data;
}

export function useSamplingSuiteCapturePng(captureId: string) {
  return useQuery({
    queryKey: queryKeys.samplingSuiteCapture.png(captureId),
    queryFn: fetchSamplingSuiteCapturePng,
    staleTime: Infinity,
  });
}

async function fetchSamplingSuiteCaptureSegmentation(
  ctx: QueryFunctionContexts['samplingSuiteCapture']['segmentation'],
) {
  const [{ captureId }] = ctx.queryKey;
  return await SamplingSuiteService.getCaptureSegmentation(captureId);
}

export function useSamplingSuiteCaptureSegmentation(captureId: string) {
  return useQuery({
    queryKey: queryKeys.samplingSuiteCapture.segmentation(captureId),
    queryFn: fetchSamplingSuiteCaptureSegmentation,
    // TODO(2298): Figure out how to refetch on interval while pending but hold in cache forever if completed
    refetchIntervalInBackground: false,
  });
}

async function patchSamplingSuiteCapture(args: {
  captureId: string;
  patch: SamplingSuiteCapturePatchDTO;
}) {
  await client.PATCH('/sampling-suites/captures/{captureGuid}', {
    params: {
      path: {
        captureGuid: args.captureId,
      },
    },
    body: args.patch,
  });
}

export function usePatchSamplingSuiteCapture() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchSamplingSuiteCapture,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.samplingSuiteCapture.all,
        queryKeys.containerSampleAnalysis.all,
        queryKeys.samplingSuiteContainerSampleAnalysis.all,
      );
      invalidator.invalidateMaterialState();
    },
  });
}
