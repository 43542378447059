/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OutputContainerChangeCreationDTO } from '../models/OutputContainerChangeCreationDTO';
import type { OutputContainerChangeDTO } from '../models/OutputContainerChangeDTO';
import type { OutputContainerChangePatchDTO } from '../models/OutputContainerChangePatchDTO';
import type { PreviousProcessOutputPortStateDTO } from '../models/PreviousProcessOutputPortStateDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OutputContainerChangeService {
    /**
     * @param outputContainerChangeGuid
     * @returns OutputContainerChangeDTO Success
     * @throws ApiError
     */
    public static getOutputContainerChangeById(
        outputContainerChangeGuid: string,
    ): CancelablePromise<OutputContainerChangeDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/output-container-changes/{outputContainerChangeGuid}',
            path: {
                'outputContainerChangeGuid': outputContainerChangeGuid,
            },
        });
    }
    /**
     * @param outputContainerChangeGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchOutputContainerChange(
        outputContainerChangeGuid: string,
        requestBody: OutputContainerChangePatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/output-container-changes/{outputContainerChangeGuid}',
            path: {
                'outputContainerChangeGuid': outputContainerChangeGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param outputContainerChangeGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteOutputContainerChange(
        outputContainerChangeGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/output-container-changes/{outputContainerChangeGuid}',
            path: {
                'outputContainerChangeGuid': outputContainerChangeGuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createOutputContainerChange(
        requestBody: OutputContainerChangeCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/output-container-changes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param processGuid
     * @param outputPortGuid
     * @param maybeInstant
     * @returns PreviousProcessOutputPortStateDTO Success
     * @throws ApiError
     */
    public static getPreviousProcessOutputPortState(
        processGuid: string,
        outputPortGuid: string,
        maybeInstant?: string,
    ): CancelablePromise<PreviousProcessOutputPortStateDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/output-container-changes/process/{processGuid}/output-port/{outputPortGuid}',
            path: {
                'processGuid': processGuid,
                'outputPortGuid': outputPortGuid,
            },
            query: {
                'maybeInstant': maybeInstant,
            },
        });
    }
}
