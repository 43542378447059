import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { The404 } from '../The404';
import { Router } from '../router';
import { ContainerSampleDetailPage } from './ContainerSampleDetailPage';
import { ContainerSamplingPAge } from './ContainerSamplingPage';
import { CreateContainerSamplePage } from './CreateContainerSamplePage';

export function ContainerSampleArea() {
  const route = Router.useRoute([
    'ContainerSampleList',
    'ContainerSampleDetail',
    'ContainerSampleCreate',
  ]);

  if (route === undefined) return <The404 />;

  return match(route)
    .with({ name: 'ContainerSampleList' }, () => <ContainerSamplingPAge />)
    .with(
      { name: 'ContainerSampleDetail' },
      ({ params: { containerSampleId } }) => (
        <ContainerSampleDetailPage containerSampleId={containerSampleId} />
      ),
    )
    .with(
      { name: 'ContainerSampleCreate' },
      ({ params: { containerId, timestamp } }) => (
        <CreateContainerSamplePage
          containerId={containerId}
          sampleTakenTimestamp={
            timestamp !== undefined ? dayjs.utc(timestamp) : undefined
          }
        />
      ),
    )
    .exhaustive();
}
