import { Alert, Group, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import { useFacilityContext } from '../Facility/FacilityContext';
import { FormActionButton } from '../Form/FormActionButton';
import { useAddContainerSample } from '../api/containerSample';
import { ContainerSampleCreationDTO, ContainerSampleId } from '../rest-client';
import {
  OccupationInterval,
  OccupationIntervalSelect,
} from './OccupationIntervalScaleReadingForm';

export interface OccupationIntervalContainerSampleFormProps {
  samplableIntervals: OccupationInterval[];
  onClose?: () => void;
  onSuccess: (containerSampleId: ContainerSampleId) => void;
}

export function OccupationIntervalContainerSampleForm(
  props: OccupationIntervalContainerSampleFormProps,
) {
  const { samplableIntervals, onClose } = props;

  if (samplableIntervals.length === 0) {
    throw new Error('empty interval list not allowed');
  }

  const facility = useFacilityContext();
  const addContainerSampleMutation = useAddContainerSample();

  const containerSampleId = useMemo(() => uuidv4(), []);

  const [selectedSamplableIntervalIdx, setSelectedSamplableIntervalIdx] =
    useState<string>('0');

  if (Number(selectedSamplableIntervalIdx) >= samplableIntervals.length) {
    setSelectedSamplableIntervalIdx('0');
  }

  const samplableInterval =
    samplableIntervals[Number(selectedSamplableIntervalIdx)];

  const [intervalStart, intervalEnd] = samplableInterval.interval;
  const timestamp = dayjs.min(
    intervalStart.add(5, 'minutes'),
    intervalEnd ?? dayjs(),
  );
  if (timestamp === null) {
    // This isn't possible but the dayjs types are bad
    throw new Error('timestamp cannot be null');
  }

  if (addContainerSampleMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Creating Container Sample'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => {
          addContainerSampleMutation.reset();
        }}
      >
        The container sample creation may or may not have been saved. You can
        clear the error and try again.
      </Alert>
    );
  }

  const onSubmit = () => {
    const showErrorNotification = () =>
      showNotification({
        title: 'Error Creating Container Sample',
        message: 'An error occurred creating container sample.',
        color: 'red',
        icon: <IconX />,
      });
    const showSuccessNotification = () =>
      showNotification({
        title: 'Container Sample Created',
        message: 'Container sample was successfuly created.',
        color: 'green',
        icon: <IconCheck />,
      });

    match(samplableInterval)
      .with({ kind: 'container' }, ({ containerId }) => {
        const containerSample: ContainerSampleCreationDTO = {
          id: containerSampleId,
          containerId,
          sampleTakenTimestamp: timestamp.utc().toISOString(),
        };
        addContainerSampleMutation.mutate(containerSample, {
          onError() {
            showErrorNotification();
          },
          onSuccess() {
            props.onSuccess(containerSampleId);
            showSuccessNotification();
          },
        });
      })
      .with({ kind: 'truck-load' }, () => {
        // implement this if we ever want truck load sampling
      })
      .exhaustive();
  };

  return (
    <Stack>
      <OccupationIntervalSelect
        occupationIntervals={samplableIntervals}
        selectedOccupationIntervalIdx={selectedSamplableIntervalIdx}
        setSelectedOccupationIntervalIdx={setSelectedSamplableIntervalIdx}
        disabled={!addContainerSampleMutation.isIdle}
      />
      <Alert color='blue'>
        Container sample will be created at{' '}
        {timestamp.tz(facility.timeZoneId).format('LLLL')}
      </Alert>

      <Group position='right' mt='md'>
        <FormActionButton
          action='cancel'
          onClick={onClose}
          loading={addContainerSampleMutation.isLoading}
        />
        <FormActionButton
          action='saveCreation'
          onClick={onSubmit}
          loading={addContainerSampleMutation.isLoading}
        >
          Add Container Sample
        </FormActionButton>
      </Group>
    </Stack>
  );
}
