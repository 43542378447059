import { match } from 'ts-pattern';
import { The404 } from '../The404';
import { Router } from '../router';
import { CommoditiesPage } from './CommoditiesPage';
import { CommodityDetailPage } from './CommodityDetailPage';

export function CommodityArea() {
  const route = Router.useRoute(['CommodityList', 'CommodityDetail']);
  if (route === undefined) {
    return <The404 />;
  }
  return match(route)
    .with({ name: 'CommodityList' }, () => <CommoditiesPage />)
    .with({ name: 'CommodityDetail' }, ({ params: { commodityId } }) => (
      <CommodityDetailPage commodityId={commodityId} />
    ))
    .exhaustive();
}
