import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useAddInternallySourcedMaterial } from '../api/internallySourcedMaterial';
import {
  InternallySourcedMaterialCreationDTO,
  InternallySourcedMaterialPartitionCreationDTO,
  MaterialContainerId,
} from '../rest-client';
import { Router } from '../router';
import {
  InternallySourcedMaterialFormFields,
  InternallySourcedMaterialFormProps,
  InternallySourcedMaterialFormValues,
  useInternallySourcedMaterialForm,
} from './InternallySourcedMaterialForm';

export type AddInternallySourceMaterialDrawerFormProps =
  InternallySourcedMaterialFormProps & {
    containerId?: MaterialContainerId;
    onSuccess?: () => void;
  } & DrawerFormDrawerProps;

export function AddInternallySourcedMaterialDrawerForm(
  props: AddInternallySourceMaterialDrawerFormProps,
) {
  const {
    internalMaterialSourceId,
    containerId,
    onSuccess = (
      internallySourcedMaterial: InternallySourcedMaterialCreationDTO,
    ) => {
      Router.replace('InternalSourceMaterialsMaterialDetail', {
        internalMaterialSourceId:
          internallySourcedMaterial.internalMaterialSourceId,
        internallySourcedMaterialId: internallySourcedMaterial.id,
      });
    },
    onClose,
    ...drawerFormDrawerProps
  } = props;

  const createMutation = useAddInternallySourcedMaterial();
  const internallySourcedMaterialId = useMemo(() => uuidv4(), []);
  const form = useInternallySourcedMaterialForm({
    internalMaterialSourceId,
  });

  const formValuesToDto = ({
    name,
    internalMaterialSourceId,
    creationTime,
    weight,
  }: InternallySourcedMaterialFormValues) => {
    if (internalMaterialSourceId === null) {
      throw new Error('Internal material source cannot be null');
    }

    let partition: InternallySourcedMaterialPartitionCreationDTO | null = null;
    if (containerId !== undefined) {
      partition = {
        containerDestinations: [
          {
            containerId: containerId,
            filled: false,
          },
        ],
        truckLoadDestinations: [],
      };
    }

    const internallySourcedMaterial: InternallySourcedMaterialCreationDTO = {
      id: internallySourcedMaterialId,
      internalMaterialSourceId,
      name,
      creationTime: creationTime.utc().toISOString(),
      weight: null,
      partition: partition,
    };

    if (weight.enabled) {
      if (
        weight.tickSize === null ||
        weight.ticks === null ||
        weight.unit === null
      ) {
        throw new Error(
          'Weight cannot be null if specify net weight option is enabled',
        );
      }
      internallySourcedMaterial.weight = {
        unit: weight.unit,
        tickSize: `${weight.tickSize}`,
        ticks: weight.ticks,
      };
    }

    return internallySourcedMaterial;
  };

  return (
    <DrawerForm
      entityName='Sourced Material'
      form={form}
      drawerFormVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <InternallySourcedMaterialFormFields form={form} />
    </DrawerForm>
  );
}
