import { match } from 'ts-pattern';
import { InventoryLedgerEventDTO } from '../rest-client';
import { ContainerSampleRow } from './RowVariants/ContainerSampleEventRow';
import { OutputContainerChangeRow } from './RowVariants/OutputContainerChangeRow';
import { ProcessBufferDepletionRow } from './RowVariants/ProcessBufferDepletionRow';
import { ProcessBufferRestorationRow } from './RowVariants/ProcessBufferRestorationRow';
import { ScaleReadingRow } from './RowVariants/ScaleReadingRow';
import { TransactionRow } from './RowVariants/TransactionRow';

export interface InventoryLedgerEventRowProps {
  event: InventoryLedgerEventDTO;
}

export function InventoryLedgerEventRow(props: InventoryLedgerEventRowProps) {
  const { event } = props;

  return match(event)
    .with({ eventType: 'Transaction' }, ({ transaction }) => (
      <TransactionRow txn={transaction} />
    ))
    .with({ eventType: 'ScaleReading' }, ({ scaleReading }) => (
      <ScaleReadingRow scaleReading={scaleReading} />
    ))
    .with({ eventType: 'ContainerSample' }, ({ containerSampleEvent }) => (
      <ContainerSampleRow containerSampleEvent={containerSampleEvent} />
    ))
    .with(
      { eventType: 'OutputContainerChange' },
      ({ outputContainerChange }) => (
        <OutputContainerChangeRow
          outputContainerChange={outputContainerChange}
        />
      ),
    )
    .with(
      { eventType: 'ProcessBufferRestoration' },
      ({ processBufferRestoration }) => (
        <ProcessBufferRestorationRow
          processBufferRestoration={processBufferRestoration}
        />
      ),
    )
    .with(
      { eventType: 'ProcessBufferDepletion' },
      ({ processBufferDepletion }) => (
        <ProcessBufferDepletionRow
          processBufferDepletion={processBufferDepletion}
        />
      ),
    )
    .exhaustive();
}
