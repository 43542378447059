import { Badge, Box, Flex, Text } from '@mantine/core';
import { match } from 'ts-pattern';
import { useCommodity } from '../api/commodity';
import {
  AssignedMaterialSetDefaultCommodityDTO,
  MaterialSetDTO,
} from '../rest-client';
import { CommodityIdName } from './CommodityName';
import cssClasses from './MaterialSetCommodity.module.css';

export function MaterialSetCommodity(props: {
  materialSet: MaterialSetDTO;
  orientation?: 'vertical' | 'horizontal';
  variant?: 'verbose' | 'succint';
}) {
  const {
    materialSet,
    orientation = 'horizontal',
    variant = 'verbose',
  } = props;

  return match(materialSet.defaultCommodity)
    .with({ kind: 'intermediate' }, () => (
      <Badge color='gray'>intermediate</Badge>
    ))
    .with({ kind: 'assigned' }, (a) => (
      <AssignedMaterialSetCommodity assignment={a} />
    ))
    .with({ kind: 'multiple' }, (multi) =>
      match(variant)
        .with('verbose', () => (
          <Flex align='stretch'>
            <Text
              color='white'
              weight='bold'
              bg='red'
              variant='filled'
              p={4}
              className={cssClasses.mixedVerbose}
            >
              Mixed
            </Text>
            {variant === 'verbose' && (
              <Flex
                justify='space-around'
                gap={orientation === 'horizontal' ? 'xs' : 0}
                px='xs'
                align='center'
                direction={match(orientation)
                  .with('horizontal', () => 'row' as const)
                  .with('vertical', () => 'column' as const)
                  .exhaustive()}
                className={cssClasses.mixedCommodityName}
              >
                {multi.commodityIds.map((commodityId) => (
                  <Box key={commodityId}>
                    <CommodityIdName commodityId={commodityId} noLink />
                  </Box>
                ))}
                {multi.includesIntermediates ? (
                  <Badge color='gray'>intermediate</Badge>
                ) : null}
              </Flex>
            )}
          </Flex>
        ))
        .with('succint', () => <Badge color='red'>mixed</Badge>)
        .exhaustive(),
    )
    .with({ kind: 'no-source' }, () => (
      <Badge color='red'>
        {match(variant)
          .with('verbose', () => <Text>no source commodity</Text>)
          .with('succint', () => <Text>unassigned</Text>)
          .exhaustive()}
      </Badge>
    ))
    .with({ kind: 'no-paths' }, () => (
      <Badge color='orange'>
        {match(variant)
          .with('verbose', () => <Text>missing recovery goal sequence</Text>)
          .with('succint', () => <Text>unassigned</Text>)
          .exhaustive()}
      </Badge>
    ))
    .with({ kind: 'unassigned-commodity-path' }, () => (
      <Badge color='red'>
        {match(variant)
          .with('verbose', () => <Text>unassigned commodity path</Text>)
          .with('succint', () => <Text>unassigned</Text>)
          .exhaustive()}
      </Badge>
    ))
    .exhaustive();
}

function AssignedMaterialSetCommodity(props: {
  assignment: AssignedMaterialSetDefaultCommodityDTO;
}) {
  const commodityQuery = useCommodity(props.assignment.commodityId);
  if (commodityQuery.data) {
    return <Text>{commodityQuery.data.name}</Text>;
  }

  if (commodityQuery.isLoading) {
    return <Text color='dimmed'>Loading...</Text>;
  }

  return <Text color='red'>error</Text>;
}
