import { Alert, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { useFacilityContext } from '../../Facility/FacilityContext';
import { SortSystemName } from '../../SortSystem/SortSystemName';
import {
  MaterialTransactionApplicationErrorDTO,
  TransactionFlowConcurrencyWarningDTO,
} from '../../rest-client';
import { InventoryLedgerErrorExplanationRow } from '../InventoryLedgerErrorExplanationRow';
import {
  TransactionClaimConcurrencyErrorExplanation,
  TransactionConflictExplanation,
} from './TransactionConflictExplanation';
import {
  EmptySourceErrorExplanation,
  FullDestinationErrorExplanation,
} from './TranscationEmptyingAndFillingStatusExplanations';

export function TransactionErrorExplanationRow({
  error,
}: {
  error: MaterialTransactionApplicationErrorDTO;
}) {
  const explanation = match(error)
    .with({ kind: 'MaterialTransactionConflict' }, (conflictError) => (
      <TransactionConflictExplanation conflict={conflictError} />
    ))
    .with({ kind: 'TransactionClaimConcurrency' }, (claimConcurrency) => (
      <TransactionClaimConcurrencyErrorExplanation error={claimConcurrency} />
    ))
    .with({ kind: 'ExtractionEmptySource' }, (emptySourceError) => (
      <EmptySourceErrorExplanation error={emptySourceError} />
    ))
    .with({ kind: 'AdditionFullDestination' }, (fullDestinationError) => (
      <FullDestinationErrorExplanation error={fullDestinationError} />
    ))
    .exhaustive();

  return (
    <InventoryLedgerErrorExplanationRow>
      {explanation}
    </InventoryLedgerErrorExplanationRow>
  );
}

export function TransactionWarningExplanationRow({
  warning,
}: {
  warning: TransactionFlowConcurrencyWarningDTO;
}) {
  const { timeZoneId } = useFacilityContext();
  const startTime = dayjs.utc(warning.outputFlowInterval.start).tz(timeZoneId);
  const endTime = dayjs.utc(warning.outputFlowInterval.end).tz(timeZoneId);
  return (
    <InventoryLedgerErrorExplanationRow>
      <Alert color='orange' title='Transaction Flow Concurrency Warning'>
        This transaction overlaps with material flow on{' '}
        <SortSystemName sortSystem={warning.outputFlowInterval.sortSystem} />
        <Text mx='0.5ch' weight={700} span>
          •
        </Text>
        {warning.outputFlowInterval.systemPortName} on {startTime.format('ll')}{' '}
        between {startTime.format('LT')} and {endTime.format('LT')}. A
        transaction on material in a container during an interval where material
        was flowing into that container may result in the transaction being
        applied to the wrong material.
      </Alert>
    </InventoryLedgerErrorExplanationRow>
  );
}

export function CommodityMixingWarningExplanationRow() {
  return (
    <InventoryLedgerErrorExplanationRow>
      <Alert color='orange' title='Commodity Mixing Warning'>
        This transaction results in distinct commodities being mixed together.
      </Alert>
    </InventoryLedgerErrorExplanationRow>
  );
}
