import { Box, BoxProps } from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/utils';
import { useMemo } from 'react';
import cssClasses from './SvgRunLengthEncodedMask.module.css';

export type SvgRunLengthEncodedMaskProps = {
  mask: number[];
  imageWidth: number;
  color: string;
  hoverColor: string;
  opacity: number;
} & Omit<
  PolymorphicComponentProps<'path', BoxProps>,
  'stroke' | 'strokeOpacity' | 'fill' | 'strokeWidth' | 'd' | 'mask'
>;
export function SvgRunLengthEncodedMask(props: SvgRunLengthEncodedMaskProps) {
  const { mask, imageWidth, color, hoverColor, opacity, ...rest } = props;

  const path = useMemo(
    () => rleMaskToSvgPath(mask, Math.ceil(imageWidth)),
    [mask, imageWidth],
  );
  return (
    <Box
      sx={{
        stroke: color,
        '&:hover': {
          stroke: hoverColor,
        },
      }}
      className={cssClasses.svgMasks}
      component='path'
      d={path}
      strokeWidth={1}
      strokeLinecap='butt'
      fill='none'
      strokeOpacity={opacity}
      {...rest}
    />
  );
}

function rleMaskToSvgPath(mask: number[], imageWidth: number) {
  const commands: string[] = [];
  let linearPosition = 0;
  let inside = false;
  for (const runLength of mask) {
    if (inside) {
      let remainingLength = runLength;
      while (remainingLength > 0) {
        const xStart = linearPosition % imageWidth;
        const yStart = Math.floor(linearPosition / imageWidth);
        const spaceInRow = imageWidth - xStart;
        const rowRunLength = Math.min(remainingLength, spaceInRow);

        commands.push(`M ${xStart} ${yStart} h ${rowRunLength}`);
        linearPosition += rowRunLength;
        remainingLength -= rowRunLength;
      }
    } else {
      linearPosition += runLength;
    }
    inside = !inside;
  }
  return commands.join(' ');
}
