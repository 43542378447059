import { Center, Group, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { ContainerIdName } from '../../Container/ContainerIdName';
import { FullContainerIcon, PartiallyFullContainerIcon } from '../../Icons';
import { TruckLoadIdName } from '../../TruckLoad/TruckLoadIdName';
import {
  InternallySourcedMaterialPartitionDTO,
  PurchasedMaterialPartitionDTO,
} from '../../rest-client';

export function PartitionDestinationCell({
  partition,
}: {
  partition:
    | PurchasedMaterialPartitionDTO
    | InternallySourcedMaterialPartitionDTO;
}) {
  return (
    <Stack spacing='xs'>
      {/* TODO(1809): Polish layout */}
      {/* TODO(1809): This means that edits to truck loads or containers should invalidate partition queries */}
      {partition.destinations.map((destination) =>
        match(destination)
          .with({ kind: 'Container' }, ({ containerId, destinationFilled }) => (
            <Group key={containerId} spacing='xs'>
              <ContainerIdName
                containerId={containerId}
                variant='icon-name-link'
                time={dayjs.utc(partition.effectiveTimestamp)}
              />
              <Center>
                {destinationFilled ? (
                  <FullContainerIcon />
                ) : (
                  <PartiallyFullContainerIcon />
                )}
              </Center>
            </Group>
          ))
          .with({ kind: 'TruckLoad' }, ({ truckLoadId }) => (
            <TruckLoadIdName
              key={truckLoadId}
              truckLoadId={truckLoadId}
              variant='icon-name-link'
            />
          ))
          .exhaustive(),
      )}
    </Stack>
  );
}
