import { Alert, Flex, Stack, Text } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { IconAlertTriangle, IconHistory } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { match } from 'ts-pattern';
import { z } from 'zod';
import ContainerSelect from '../Container/ContainerSelect';
import { DayjsDateTimePicker } from '../DayjsDateTimePicker';
import { useFacilityContext } from '../Facility/FacilityContext';
import { TransactionLedgerIcon } from '../Icons';
import { ShowDetailsToggleButton } from '../ShowDetailsToggleButton';
import { useContainerGenealogy } from '../api/container';
import { MaterialContainerId } from '../rest-client';
import cssClasses from './ContainerSampleForm.module.css';
import { ContainerSampleCreateProps } from './CreateContainerSamplePage';

const ContainerSampleFormSchema = z.object({
  sampleTakenTimestamp: z.instanceof(dayjs as unknown as typeof Dayjs, {
    message: 'Sample time is required',
  }),
  containerId: z
    .string()
    .uuid()
    .nullable()
    .refine((val) => val !== null, { message: 'Container is required' }),
});

export type ContainerSampleFormValues = z.infer<
  typeof ContainerSampleFormSchema
>;

export function useContainerSampleForm(props: ContainerSampleCreateProps) {
  const { containerId, sampleTakenTimestamp } = props;

  const facility = useFacilityContext();

  return useForm<ContainerSampleFormValues>({
    initialValues: {
      containerId: containerId ?? null,
      sampleTakenTimestamp:
        sampleTakenTimestamp?.tz(facility.timeZoneId) ?? null!,
    },
    validate: zodResolver(ContainerSampleFormSchema),
  });
}

export interface ContainerSampleFormFieldsProps {
  form: UseFormReturnType<ContainerSampleFormValues>;
  disableContainerSelect?: boolean;
  showContainerDetails: boolean;
  onToggleContainerDetails: () => void;
}

export function ContainerSampleFormFields(
  props: ContainerSampleFormFieldsProps,
) {
  const {
    form,
    disableContainerSelect = false,
    showContainerDetails,
    onToggleContainerDetails,
  } = props;

  const facility = useFacilityContext();

  const containerUnspecified = form.values.containerId === null;
  const timeUnspecified = form.values.sampleTakenTimestamp === null;

  return (
    <Flex gap='sm' justify='flex-start' align='flex-start'>
      <Stack>
        <DayjsDateTimePicker
          label='Sample Time'
          description='Time at which the material was taken from the container.'
          tz={facility.timeZoneId}
          withAsterisk
          {...form.getInputProps('sampleTakenTimestamp')}
        />
        <Flex gap='sm' justify='flex-start' align='flex-end'>
          <ContainerSelect
            variant='material'
            label='Sampled Container'
            description='Container the sampled material was taken out of.'
            placeholder='Select container'
            facilityId={facility.id}
            timestamp={form.values.sampleTakenTimestamp?.utc() ?? dayjs()}
            hideEmpty={false} // Should we toggle on this?
            hideFull={false}
            disabled={disableContainerSelect}
            withAsterisk
            {...form.getInputProps('containerId')}
          />
          <ShowDetailsToggleButton
            value={showContainerDetails}
            onToggle={onToggleContainerDetails}
            tooltip='View container material data at sample time'
            disabled={containerUnspecified || timeUnspecified}
          />
        </Flex>
      </Stack>
    </Flex>
  );
}

export function ContainerSampleFormAlerts(props: {
  containerId: MaterialContainerId;
  timestamp: Dayjs;
}) {
  const { containerId, timestamp } = props;

  const facility = useFacilityContext();
  const genealogyQuery = useContainerGenealogy(containerId, timestamp);

  const materialDataStatus = genealogyQuery.data?.status;
  const formattedTimestamp = timestamp.tz(facility.timeZoneId).format('llll');

  return (
    <Stack>
      <Alert
        color='violet'
        title='Historical Container Sample'
        icon={<IconHistory />}
        className={cssClasses.alertContainer}
      >
        <Text>
          The sample will be associated to the material in the container on{' '}
          {formattedTimestamp}.
        </Text>
      </Alert>
      {match(materialDataStatus)
        .with('empty', () => (
          <Alert
            color='orange'
            title='Empty Container Sample'
            icon={<IconAlertTriangle />}
            className={cssClasses.alertContainer}
          >
            <Text>
              This container did not contain any material on{' '}
              {formattedTimestamp}. You can still create the sample, but it will
              be orphaned and not associated to any set of material.
            </Text>
          </Alert>
        ))
        .with('error', () => (
          <Alert
            color='red'
            title='Inventory Ledger Error'
            icon={<TransactionLedgerIcon />}
            className={cssClasses.alertContainer}
          >
            <Text>
              The container state is unknown at {formattedTimestamp} because
              there are currently ledger errors. You can still create the
              sample, but it may result in the sample not being associated to
              any material if the container was empty at
              {formattedTimestamp}.
            </Text>
          </Alert>
        ))
        .with('occupied', undefined, () => undefined)
        .exhaustive()}
    </Stack>
  );
}
