import { Text } from '@mantine/core';
import { useRecoveryGoal } from '../api/recoveryGoal';
import { RecoveryGoalName } from './RecoveryGoalName';

export function RecoveryGoalIdName(props: {
  recoveryGoalId: string;
  noLink?: boolean;
}) {
  const { recoveryGoalId, noLink = false } = props;
  const recoveryGoalQuery = useRecoveryGoal(recoveryGoalId);
  if (recoveryGoalQuery.data) {
    return (
      <RecoveryGoalName recoveryGoal={recoveryGoalQuery.data} noLink={noLink} />
    );
  }
  if (recoveryGoalQuery.isLoadingError) {
    return <Text color='red'>Error getting recovery goal name</Text>;
  }
  return <Text color='dimmed'>Loading...</Text>;
}
