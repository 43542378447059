import { useMutation, useQuery } from '@tanstack/react-query';
import {
  InternalMaterialSourceService,
  InternallySourcedMaterialPartitionCreationDTO,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchInternallySourcedMaterialPartitionStatus(
  ctx: QueryFunctionContexts['transaction']['internallySourcedMaterialPartition']['status'],
) {
  const [{ internallySourcedMaterialId }] = ctx.queryKey;

  return await InternalMaterialSourceService.getInternallySourcedMaterialPartitionStatus(
    internallySourcedMaterialId,
  );
}

export function useInternallySourcedMaterialPartitionStatus(
  internallySourcedMaterialId: string,
) {
  return useQuery({
    queryKey: queryKeys.transaction.internallySourcedMaterialPartition.status(
      internallySourcedMaterialId,
    ),
    queryFn: fetchInternallySourcedMaterialPartitionStatus,
  });
}

async function createInternallySourcedMaterialPartition({
  internallySourcedMaterialId,
  partition,
}: {
  internallySourcedMaterialId: string;
  partition: InternallySourcedMaterialPartitionCreationDTO;
}) {
  await InternalMaterialSourceService.createInternallySourcedMaterialPartition(
    internallySourcedMaterialId,
    partition,
  );
}

export function useAddInternallySourcedMaterialPartition() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createInternallySourcedMaterialPartition,
    onSettled(_data, _error, { internallySourcedMaterialId }) {
      invalidator.invalidateKeys(
        queryKeys.transaction.internallySourcedMaterialPartition.status(
          internallySourcedMaterialId,
        ),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function deleteInternallySourcedMaterialPartition(partitionId: string) {
  await InternalMaterialSourceService.deleteInternallySourcedMaterialPartition(
    partitionId,
  );
}

export function useDeleteInternallySourcedMaterialPartition() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteInternallySourcedMaterialPartition,
    onSettled(_data, _error, partitionId) {
      invalidator.invalidateKeys(
        queryKeys.transaction.internallySourcedMaterialPartition.status(
          partitionId,
        ),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}
