import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ProcessBufferTransferIcon } from '../Icons';
import {
  AddProcessBufferTransferDrawerForm,
  AddProcessBufferTransferDrawerFormProps,
} from './AddProcessBufferTransferDrawerForm';

export type AddProcessBufferTransferButtonProps = Omit<
  ButtonProps,
  'onClick'
> & {
  addProcessBufferTransferDrawerFormProps: Omit<
    AddProcessBufferTransferDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddProcessBufferTransferButton(
  props: AddProcessBufferTransferButtonProps,
) {
  const {
    addProcessBufferTransferDrawerFormProps,
    leftIcon = <ProcessBufferTransferIcon />,
    children = 'Add Feedstock Transfer',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddProcessBufferTransferDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addProcessBufferTransferDrawerFormProps}
      />
    </>
  );
}
