import { useMutation, useQuery } from '@tanstack/react-query';
import {
  InternalMaterialSourceService,
  InternallySourcedMaterialCreationDTO,
  InternallySourcedMaterialId,
  InternallySourcedMaterialPatchDTO,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchInternallySourcedMaterial(
  ctx: QueryFunctionContexts['internallySourcedMaterial']['detail'],
) {
  const [{ internallySourcedMaterialId }] = ctx.queryKey;
  return await InternalMaterialSourceService.getInternallySourcedMaterialById(
    internallySourcedMaterialId,
  );
}

export function useInternallySourcedMaterial(
  internallySourcedMaterialId: InternallySourcedMaterialId,
) {
  return useQuery({
    queryKey: queryKeys.internallySourcedMaterial.detail(
      internallySourcedMaterialId,
    ),
    queryFn: fetchInternallySourcedMaterial,
  });
}

async function patchInternallySourcedMaterial({
  internallySourcedMaterialId,
  patch,
}: {
  internallySourcedMaterialId: InternallySourcedMaterialId;
  patch: InternallySourcedMaterialPatchDTO;
}) {
  await InternalMaterialSourceService.patchInternallySourcedMaterial(
    internallySourcedMaterialId,
    patch,
  );
}

export function usePatchInternallySourcedMaterial(
  internallySourcedMaterialId: InternallySourcedMaterialId,
) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: InternallySourcedMaterialPatchDTO) =>
      await patchInternallySourcedMaterial({
        internallySourcedMaterialId,
        patch,
      }),
    onSettled() {
      invalidator.invalidateKey(
        queryKeys.internallySourcedMaterial.detail(internallySourcedMaterialId),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function fetchInternallySourcedMaterials(
  ctx: QueryFunctionContexts['internallySourcedMaterial']['list'],
) {
  const [{ internalMaterialSourceId }] = ctx.queryKey;
  return InternalMaterialSourceService.allInternallySourcedMaterials(
    internalMaterialSourceId,
  );
}

export function useInternallySourcedMaterials(
  internalMaterialSourceId?: string,
) {
  return useQuery({
    queryKey: queryKeys.internallySourcedMaterial.list(
      internalMaterialSourceId,
    ),
    queryFn: fetchInternallySourcedMaterials,
  });
}

async function addInternallySourcedMaterial(
  dto: InternallySourcedMaterialCreationDTO,
) {
  await InternalMaterialSourceService.createInternallySourcedMaterial(dto);
}

export function useAddInternallySourcedMaterial() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: addInternallySourcedMaterial,
    onSettled(data, error, ism) {
      invalidator.invalidateKeys(
        queryKeys.internallySourcedMaterial.list(ism.internalMaterialSourceId),
      );
      invalidator.invalidateMaterialState();
    },
  });
}

async function deleteInternallySourcedMaterial(
  internallySourcedMaterialId: string,
) {
  await InternalMaterialSourceService.deleteInternallySourcedMaterial(
    internallySourcedMaterialId,
  );
}

export function useDeleteInternallySourcedMaterial() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteInternallySourcedMaterial,
    onSuccess(data, id) {
      invalidator.removeKey(queryKeys.internallySourcedMaterial.detail(id));
      invalidator.invalidateInventoryLedger();
    },
    onError(error, id) {
      invalidator.resetKey(queryKeys.internallySourcedMaterial.detail(id));
    },
    onSettled() {
      invalidator.invalidateKeys(queryKeys.internallySourcedMaterial.lists());
    },
  });
}

async function fetchInternallySourcedMaterialGenealogy(
  ctx: QueryFunctionContexts['genealogy']['internallySourcedMaterial'],
) {
  const [{ internallySourcedMaterialId }] = ctx.queryKey;
  return await InternalMaterialSourceService.getInternallySourcedMaterialGenealogyById(
    internallySourcedMaterialId,
  );
}

export function useInternallySourcedMaterialGenealogy(
  internallySourcedMaterialId: string,
) {
  return useQuery({
    queryKey: queryKeys.genealogy.internallySourcedMaterial(
      internallySourcedMaterialId,
    ),
    queryFn: fetchInternallySourcedMaterialGenealogy,
  });
}

async function fetchInternallySourcedMaterialSets(
  ctx: QueryFunctionContexts['materialSet']['internallySourcedMaterials'],
) {
  const [{ internalMaterialSourceId }] = ctx.queryKey;
  return await InternalMaterialSourceService.allInternallySourcedMaterialSets(
    internalMaterialSourceId,
  );
}

export function useInternallySourcedMaterialSets(
  internalMaterialSourceId?: string,
) {
  return useQuery({
    queryKey: queryKeys.materialSet.internallySourcedMaterials(
      internalMaterialSourceId,
    ),
    queryFn: fetchInternallySourcedMaterialSets,
  });
}
