import { AppPage } from '../App/AppPage';
import { Router } from '../router';
import { AddVendorForm } from './AddVendorForm';

export function AddVendorPage() {
  const breadcrumbs = [{ routeName: Router.VendorList(), title: 'Vendors' }];

  return (
    <AppPage breadcrumbs={breadcrumbs} title='Add Vendor'>
      <AppPage.Section>
        <AddVendorForm />
      </AppPage.Section>
    </AppPage>
  );
}
