import { useMutation, useQuery } from '@tanstack/react-query';
import {
  PurchasedMaterialCreationDTO,
  PurchasedMaterialService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchPurchasedMaterial(
  ctx: QueryFunctionContexts['purchasedMaterial']['detail'],
) {
  const [{ purchasedMaterialId }] = ctx.queryKey;
  return await PurchasedMaterialService.getPurchasedMaterialById(
    purchasedMaterialId,
  );
}

export function usePurchasedMaterial(purchasedMaterialId: string) {
  return useQuery({
    queryKey: queryKeys.purchasedMaterial.detail(purchasedMaterialId),
    queryFn: fetchPurchasedMaterial,
  });
}

async function fetchPurchasedMaterials(
  ctx: QueryFunctionContexts['purchasedMaterial']['list'],
) {
  const [{ vendorId }] = ctx.queryKey;
  return await PurchasedMaterialService.getAllPurchasedMaterials(vendorId);
}

export function usePurchasedMaterials(vendorId?: string) {
  return useQuery({
    queryKey: queryKeys.purchasedMaterial.list(vendorId),
    queryFn: fetchPurchasedMaterials,
  });
}

async function addPurchasedMaterial(dto: PurchasedMaterialCreationDTO) {
  await PurchasedMaterialService.createPurchasedMaterial(dto);
}

export function useAddPurchasedMaterial() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: addPurchasedMaterial,
    onSettled(data, error, purchasedMaterial) {
      invalidator.invalidateKeys(
        queryKeys.purchasedMaterial.list(purchasedMaterial.vendorId),
      );
      invalidator.invalidateMaterialState();
    },
  });
}

async function deletePurchasedMaterial(purchasedMaterialId: string) {
  await PurchasedMaterialService.deletePurchasedMaterialById(
    purchasedMaterialId,
  );
}

export function useDeletePurchasedMaterial() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deletePurchasedMaterial,
    onSuccess(data, purchasedMaterialId) {
      invalidator.removeKey(
        queryKeys.purchasedMaterial.detail(purchasedMaterialId),
      );
    },
    onError(error, purchasedMaterialId) {
      invalidator.resetKey(
        queryKeys.purchasedMaterial.detail(purchasedMaterialId),
      );
    },
    onSettled() {
      invalidator.invalidateKeys(queryKeys.purchasedMaterial.lists());
    },
  });
}

async function fetchPurchasedMaterialGenealogy(
  ctx: QueryFunctionContexts['genealogy']['purchasedMaterial'],
) {
  const [{ purchasedMaterialId }] = ctx.queryKey;
  return await PurchasedMaterialService.getPurchasedMaterialGenealogyById(
    purchasedMaterialId,
  );
}

export function usePurchasedMaterialGenealogy(purchasedMaterialId: string) {
  return useQuery({
    queryKey: queryKeys.genealogy.purchasedMaterial(purchasedMaterialId),
    queryFn: fetchPurchasedMaterialGenealogy,
  });
}

async function fetchPurchasedMaterialSets(
  ctx: QueryFunctionContexts['materialSet']['purchasedMaterials'],
) {
  const [{ vendorId }] = ctx.queryKey;
  return await PurchasedMaterialService.getAllPurchasedMaterialSets(vendorId);
}

export function usePurchasedMaterialSets(vendorId?: string) {
  return useQuery({
    queryKey: queryKeys.materialSet.purchasedMaterials(vendorId),
    queryFn: fetchPurchasedMaterialSets,
  });
}
