import { Badge, Group, Stack } from '@mantine/core';
import { IconCirclePlus, IconSquareRoundedMinus } from '@tabler/icons-react';
import { useMaterialClasses } from '../api/materialClass';
import { MaterialClassEffect } from '../rest-client';

export default function MaterialClassEffects(props: {
  effects: Record<string, MaterialClassEffect>;
}) {
  const { effects } = props;

  const materialClassesQuery = useMaterialClasses();

  const materialClassNames =
    materialClassesQuery.data &&
    new Map(materialClassesQuery.data.map((mc) => [mc.id, mc.name]));

  const classEffects = Object.entries(effects);

  const includedMaterialClassIds = classEffects
    .filter(([, effect]) => effect === MaterialClassEffect.INCLUDED)
    .map(([mcId]) => mcId);

  const unaffectedMaterialClassIds = classEffects
    .filter(([, effect]) => effect === MaterialClassEffect.NONE)
    .map(([mcId]) => mcId);

  const excludedMaterialClassIds = classEffects
    .filter(([, effect]) => effect === MaterialClassEffect.EXCLUDED)
    .map(([mcId]) => mcId);

  if (
    includedMaterialClassIds.length === 0 &&
    unaffectedMaterialClassIds.length === 0 &&
    excludedMaterialClassIds.length > 0
  ) {
    return (
      <Badge color='red' variant='filled'>
        NONE
      </Badge>
    );
  }

  if (
    includedMaterialClassIds.length > 0 &&
    unaffectedMaterialClassIds.length === 0
  ) {
    return (
      <Group spacing={4}>
        {includedMaterialClassIds.map((mcId) => (
          <Badge key={mcId} color='green' variant='filled'>
            {materialClassNames?.get(mcId) ?? '...'}
          </Badge>
        ))}
      </Group>
    );
  }

  if (
    includedMaterialClassIds.length === 0 &&
    excludedMaterialClassIds.length === 0
  ) {
    return (
      <Badge color='gray' variant='outline'>
        Unprocessed
      </Badge>
    );
  }

  return (
    <Stack spacing='xs'>
      <Group spacing={4}>
        {unaffectedMaterialClassIds.length > 0 ? (
          <Badge color='gray' variant='outline'>
            Unprocessed
          </Badge>
        ) : null}
        {includedMaterialClassIds.map((mcId) => (
          <Badge
            key={mcId}
            color='green'
            variant='filled'
            leftSection={
              <IconCirclePlus
                size='1rem'
                style={{
                  marginBottom: '-0.2rem',
                }}
              />
            }
          >
            {materialClassNames?.get(mcId) ?? '...'}
          </Badge>
        ))}
        {excludedMaterialClassIds.map((mcId) => (
          <Badge
            key={mcId}
            color='orange'
            variant='filled'
            leftSection={
              <IconSquareRoundedMinus
                size='1rem'
                style={{ marginBottom: '-0.25rem' }}
              />
            }
          >
            {materialClassNames?.get(mcId) ?? '...'}
          </Badge>
        ))}
      </Group>
    </Stack>
  );
}
