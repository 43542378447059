/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TenantDTO } from '../models/TenantDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TenantService {
    /**
     * @returns TenantDTO Success
     * @throws ApiError
     */
    public static getCurrentTenant(): CancelablePromise<TenantDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tenants/current',
        });
    }
}
