import { Badge, Loader, Stack, Table, Text } from '@mantine/core';
import Temporal from '../Temporal/temporal.ts';
import { useProcessTemporalProductionHistory } from '../api/process';
import { useProcessStopReasons } from '../api/processStopReason';
import { analyzeProductionIntervalPattern } from './ProcessProductionIntervalStatusTable';

function formatHours(hours: number) {
  return `${Math.floor(hours)}h ${Math.floor((60 * hours) % 60)}m`;
}

export default function ProcessProductionHistoryStopReasonBreakdown(props: {
  processId: string;
  after: Temporal.Instant | null;
  before: Temporal.Instant | null;
}) {
  const { processId, after, before } = props;

  const stopReasonsQuery = useProcessStopReasons();

  const productionHistoryQuery = useProcessTemporalProductionHistory({
    processId,
    after,
    before,
  });

  if (productionHistoryQuery.isLoading || stopReasonsQuery.isLoading) {
    return (
      <Stack align='center'>
        <Loader size='lg' variant='bars' />
        <Text color='dimmed'>Analyzing stoppage data...</Text>
      </Stack>
    );
  }

  if (
    productionHistoryQuery.isLoadingError ||
    stopReasonsQuery.isLoadingError
  ) {
    throw productionHistoryQuery.error;
  }
  const productionHistory = productionHistoryQuery.data;
  const stopReasons = new Map(
    stopReasonsQuery.data.map((stopReason) => [stopReason.id, stopReason]),
  );

  const unplannedStopHours = analyzeProductionIntervalPattern({
    subIntervalStates: productionHistory.productionIntervalStatuses.flatMap(
      (pis) => pis.subIntervalStates,
    ),
    pattern: {
      stopReason: { unplanned: true },
    },
    ignoreUnplannedStops: false,
  });

  const plannedStopHours = analyzeProductionIntervalPattern({
    subIntervalStates: productionHistory.productionIntervalStatuses.flatMap(
      (pis) => pis.subIntervalStates,
    ),
    pattern: {
      stopReason: { unplanned: false },
    },
    ignoreUnplannedStops: false,
  });

  const totalStopHours = unplannedStopHours + plannedStopHours;

  return (
    <Table w='fit-content' withBorder>
      <thead>
        <tr>
          <th>Reason</th>
          <th>Planned</th>
          <th style={{ textAlign: 'right' }}>Hours</th>
          <th style={{ textAlign: 'right' }}>Total %</th>
          <th style={{ textAlign: 'right' }}>Category %</th>
        </tr>
      </thead>
      <tbody>
        {/* Empty state (shouldn't ever really happen) */}
        {Object.entries(productionHistory.plannedStopReasonHours).map(
          ([stopReasonId, stopHours]) => (
            <tr key={stopReasonId}>
              <td>{stopReasons.get(stopReasonId)?.name ?? '?'}</td>
              <td>
                <Badge color='yellow'>planned</Badge>
              </td>
              <td style={{ textAlign: 'right' }}>{formatHours(stopHours)}</td>
              <td style={{ textAlign: 'right' }}>
                {((100 * stopHours) / totalStopHours).toFixed(1)}%
              </td>
              <td style={{ textAlign: 'right' }}>
                {((100 * stopHours) / plannedStopHours).toFixed(1)}%
              </td>
            </tr>
          ),
        )}

        {Object.entries(productionHistory.unplannedStopReasonHours).map(
          ([stopReasonId, stopHours]) => (
            <tr key={stopReasonId}>
              <td>{stopReasons.get(stopReasonId)?.name ?? '?'}</td>
              <td>
                <Badge color='orange'>unplanned</Badge>
              </td>
              <td style={{ textAlign: 'right' }}>{formatHours(stopHours)}</td>
              <td style={{ textAlign: 'right' }}>
                {((100 * stopHours) / totalStopHours).toFixed(1)}%
              </td>
              <td style={{ textAlign: 'right' }}>
                {((100 * stopHours) / unplannedStopHours).toFixed(1)}%
              </td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  );
}
