import { Grid, Skeleton, Stack, Title } from '@mantine/core';
import { Dayjs } from 'dayjs';
import { AppPage } from '../App/AppPage';
import GenealogyGraph2 from '../GenealogyGraph/GenealogyGraph';
import { useContainerGenealogy } from '../api/container';
import {
  MaterialContainerId,
  OccupiedGenealogyResultDTO,
} from '../rest-client';
import {
  EmptyMaterialDataSection,
  ErrantMaterialDataSection,
  SelectedMaterialDetailsSection,
} from './GenealogyExplorer';
import cssClasses from './SelectedContainerGenealogy.module.css';

export interface SelectedContainerGenealogyProps {
  containerId: MaterialContainerId;
  timestamp: Dayjs | undefined;
}

export function SelectedContainerGenealogy(
  props: SelectedContainerGenealogyProps,
) {
  const { containerId, timestamp } = props;

  const genealogyQuery = useContainerGenealogy(containerId, timestamp);

  if (genealogyQuery.data?.status === 'empty') {
    return <EmptyMaterialDataSection />;
  }

  if (genealogyQuery.data?.status === 'error') {
    return <ErrantMaterialDataSection />;
  }

  const occupiedGenealogyResult = genealogyQuery.data;
  const materialSet =
    occupiedGenealogyResult?.materialSets[occupiedGenealogyResult.occupantHash];

  return (
    <Grid className={cssClasses.wrapper}>
      <Grid.Col span={7}>
        <SelectedGenealogyGraphSection
          occupiedGenealogyResult={occupiedGenealogyResult}
        />
      </Grid.Col>
      <Grid.Col span={5}>
        <SelectedMaterialDetailsSection materialSet={materialSet} readOnly />
      </Grid.Col>
    </Grid>
  );
}

interface SelectedSectionProps {
  occupiedGenealogyResult: OccupiedGenealogyResultDTO | undefined;
}

function SelectedGenealogyGraphSection(props: SelectedSectionProps) {
  const { occupiedGenealogyResult } = props;

  return (
    <AppPage.Section h='100%'>
      <Stack h='100%'>
        <Title ml={'md'} order={3}>
          Material Derivation
        </Title>
        {occupiedGenealogyResult === undefined ? (
          <Skeleton h='100%' w='100%' />
        ) : (
          <GenealogyGraph2
            rootMaterialSet={
              occupiedGenealogyResult.materialSets[
                occupiedGenealogyResult.occupantHash
              ]
            }
            setSelectedMaterialSet={() => {
              void 0;
            }}
          />
        )}
      </Stack>
    </AppPage.Section>
  );
}
