import { Stack, Title } from '@mantine/core';
import { match } from 'ts-pattern';
import Temporal from '../../Temporal/temporal.ts';
import { ProcessDTO, SortSystemDTO } from '../../rest-client';
import { ChutecSystemMetrics } from './chutec/ChutecSystemMetrics';
import { RedWaveSystemMetrics } from './redwave/RedWaveSystemMetrics';

export function SystemNodeMetrics(props: {
  system: SortSystemDTO;
  process: ProcessDTO;
  startTime: Temporal.Instant;
  endTime: Temporal.Instant;
}) {
  const { system, process, startTime, endTime } = props;
  return (
    <Stack>
      <Title order={2}>{system.name} Metrics</Title>
      {match(system)
        .with({ kind: 'Chutec' }, (chutec) => (
          <ChutecSystemMetrics
            system={chutec}
            process={process}
            startTime={startTime}
            endTime={endTime}
          />
        ))
        .with({ kind: 'RedWave' }, (redwave) => (
          <RedWaveSystemMetrics
            system={redwave}
            process={process}
            startTime={startTime}
            endTime={endTime}
          />
        ))
        .exhaustive()}
    </Stack>
  );
}
