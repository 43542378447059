import { Text } from '@mantine/core';
import { useSortSystem } from '../api/sortSystem';
import { SortSystemId } from '../rest-client';
import { SortSystemName, SortSystemNameProps } from './SortSystemName';

export type SortSystemIdNameProps = Omit<SortSystemNameProps, 'sortSystem'> & {
  sortSystemId: SortSystemId;
};

export function SortSystemIdName(props: SortSystemIdNameProps) {
  const { sortSystemId, ...other } = props;
  const sortSystemQuery = useSortSystem(sortSystemId);
  if (sortSystemQuery.data !== undefined) {
    return <SortSystemName sortSystem={sortSystemQuery.data} {...other} />;
  }
  if (sortSystemQuery.isLoadingError) {
    return <Text color='red'>Error getting Sort System name</Text>;
  }
  return <Text color='dimmed'>Loading...</Text>;
}
