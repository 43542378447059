/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyGenealogyResultDTO } from '../models/EmptyGenealogyResultDTO';
import type { LedgerErrorGenealogyResultDTO } from '../models/LedgerErrorGenealogyResultDTO';
import type { LedgerErrorOccupiedTruckLoadMaterialSetsResultDTO } from '../models/LedgerErrorOccupiedTruckLoadMaterialSetsResultDTO';
import type { MaterialTruckLoadCreationDTO } from '../models/MaterialTruckLoadCreationDTO';
import type { MaterialTruckLoadDTO } from '../models/MaterialTruckLoadDTO';
import type { MaterialTruckLoadPatchDTO } from '../models/MaterialTruckLoadPatchDTO';
import type { MaterialTruckLoadTransferCreationDTO } from '../models/MaterialTruckLoadTransferCreationDTO';
import type { OccupiedGenealogyResultDTO } from '../models/OccupiedGenealogyResultDTO';
import type { OccupiedTruckLoadMaterialSetsDTO } from '../models/OccupiedTruckLoadMaterialSetsDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TruckLoadService {
    /**
     * @returns MaterialTruckLoadDTO Success
     * @throws ApiError
     */
    public static getAllTruckLoads(): CancelablePromise<Array<MaterialTruckLoadDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/truck-loads',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createTruckLoad(
        requestBody: MaterialTruckLoadCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/truck-loads',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param truckLoadGuid
     * @returns MaterialTruckLoadDTO Success
     * @throws ApiError
     */
    public static getTruckLoadById(
        truckLoadGuid: string,
    ): CancelablePromise<MaterialTruckLoadDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/truck-loads/{truckLoadGuid}',
            path: {
                'truckLoadGuid': truckLoadGuid,
            },
        });
    }
    /**
     * @param truckLoadGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchTruckLoad(
        truckLoadGuid: string,
        requestBody: MaterialTruckLoadPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/truck-loads/{truckLoadGuid}',
            path: {
                'truckLoadGuid': truckLoadGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param truckLoadGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteTruckLoadById(
        truckLoadGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/truck-loads/{truckLoadGuid}',
            path: {
                'truckLoadGuid': truckLoadGuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createTruckLoadTransfer(
        requestBody: MaterialTruckLoadTransferCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/truck-loads/transfers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param transferGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteTruckLoadTransfer(
        transferGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/truck-loads/transfers/{transferGuid}',
            path: {
                'transferGuid': transferGuid,
            },
        });
    }
    /**
     * @param truckLoadGuid
     * @param timestamp
     * @returns any Success
     * @throws ApiError
     */
    public static getTruckLoadGenealogy(
        truckLoadGuid: string,
        timestamp?: string,
    ): CancelablePromise<(OccupiedGenealogyResultDTO | LedgerErrorGenealogyResultDTO | EmptyGenealogyResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/truck-loads/{truckLoadGuid}/genealogy',
            path: {
                'truckLoadGuid': truckLoadGuid,
            },
            query: {
                'timestamp': timestamp,
            },
        });
    }
    /**
     * @param timestamp
     * @returns any Success
     * @throws ApiError
     */
    public static getOccupiedTruckLoadContents(
        timestamp?: string,
    ): CancelablePromise<(OccupiedTruckLoadMaterialSetsDTO | LedgerErrorOccupiedTruckLoadMaterialSetsResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/truck-loads/occupied-contents',
            query: {
                'timestamp': timestamp,
            },
        });
    }
}
