import { Skeleton, Table, Text, createStyles } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import ScaleDisplayDivisionsInput from '../../Weights/ScaleDisplayDivisionsInput';
import { MaterialClassSetDTO, ScaleTypeDTO } from '../../rest-client';
import { ManualSampleAnalysisFormValues } from './ManualSampleAnalysisForm';

const useContainerSampleAnalysisMaterialClassTableStyles = createStyles(
  (t) => ({
    firstCol: {
      position: 'sticky',
      width: '8em',
      zIndex: 1,
      background: t.colorScheme === 'light' ? t.white : t.black,
      left: 0,
    },
    col: {
      width: '14em',
      background: t.colorScheme === 'light' ? t.white : t.black,
    },
  }),
);

export function ContainerSampleAnalysisMaterialClassTable({
  form,
  scaleType,
  materialClassSet,
}: {
  form: UseFormReturnType<ManualSampleAnalysisFormValues>;
  scaleType: ScaleTypeDTO | undefined;
  materialClassSet: MaterialClassSetDTO;
}) {
  const { classes } = useContainerSampleAnalysisMaterialClassTableStyles();

  const materialClasses = materialClassSet.materialClasses;

  return (
    <div
      style={{
        overflowX: 'scroll',
        scrollbarGutter: 'stable',
      }}
    >
      <Table
        withBorder
        withColumnBorders
        sx={{
          whiteSpace: 'nowrap',
          width: 'min-content',
          borderCollapse: 'separate',
        }}
      >
        <thead>
          <tr>
            <th className={classes.firstCol}>
              <div style={{ background: 'white' }}>Material Class</div>
            </th>
            {materialClasses.map((m) => (
              <th
                key={m.id}
                className={classes.col}
                style={{ borderLeft: 'none' }}
              >
                <Text>{m.name}</Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className={classes.firstCol}>Net Weight</th>
            {materialClasses.map((m, i) => (
              <td
                key={m.id}
                className={classes.col}
                style={{ padding: 0, borderLeft: 'none' }}
              >
                {scaleType ? (
                  <ScaleDisplayDivisionsInput
                    miw='8ch'
                    hideControls
                    hideUnit
                    scale={scaleType}
                    {...form.getInputProps(
                      `materialClasses.${i}.netWeightScaleDisplayDivisions`,
                    )}
                  />
                ) : (
                  <Skeleton visible>...</Skeleton>
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
