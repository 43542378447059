import { Text } from '@mantine/core';
import { LinkText } from '../Link';
import { useRecoveryStrategy } from '../api/recoveryStrategy';
import { RecoveryStrategyDTO, RecoveryStrategyId } from '../rest-client';
import { Router } from '../router';

export interface RecoveryStrategyNameProps {
  recoveryStrategy: RecoveryStrategyDTO;
  noLink?: boolean;
}

export function RecoveryStrategyName(props: RecoveryStrategyNameProps) {
  const { recoveryStrategy, noLink } = props;
  if (noLink) {
    return (
      <Text>{recoveryStrategy.name ? recoveryStrategy.name : 'unnamed'}</Text>
    );
  }
  return (
    <LinkText
      to={Router.RecoveryStrategyDetail({
        recoveryStrategyId: recoveryStrategy.id,
      })}
    >
      {recoveryStrategy.name ? recoveryStrategy.name : 'unnamed'}
    </LinkText>
  );
}

export function RecoveryStrategyIdName(props: {
  recoveryStrategyId: RecoveryStrategyId;
  noLink?: boolean;
}) {
  const { recoveryStrategyId, noLink = false } = props;
  const recoveryStrategyQuery = useRecoveryStrategy(recoveryStrategyId);
  if (recoveryStrategyQuery.data) {
    return (
      <RecoveryStrategyName
        recoveryStrategy={recoveryStrategyQuery.data}
        noLink={noLink}
      />
    );
  }
  if (recoveryStrategyQuery.isLoadingError) {
    return <Text color='red'>Error getting recovery strategy name</Text>;
  }
  return <Text color='dimmed'>Loading...</Text>;
}
