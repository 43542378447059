import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CommodityIcon } from '../../Icons';
import {
  AddDefaultCommodityAssignmentDrawerForm,
  AddDefaultCommodityAssignmentDrawerFormProps,
} from './AddDefaultCommodityAssignmentDrawerForm';

export type AddDefaultCommodityAssignmentButtonProps = Omit<
  ButtonProps,
  'onClick'
> & {
  addDefaultCommodityAssignmentFormProps: Omit<
    AddDefaultCommodityAssignmentDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddDefaultCommodityAssignmentButton(
  props: AddDefaultCommodityAssignmentButtonProps,
) {
  const {
    addDefaultCommodityAssignmentFormProps,
    leftIcon = <CommodityIcon />,
    children = 'Add Default Output Commodity Assignement',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddDefaultCommodityAssignmentDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addDefaultCommodityAssignmentFormProps}
      />
    </>
  );
}
