/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProcessDTO } from '../models/ProcessDTO';
import type { ProductionIntervalMassHistoryDTO } from '../models/ProductionIntervalMassHistoryDTO';
import type { ProductionIntervalTemporalHistoryDTO } from '../models/ProductionIntervalTemporalHistoryDTO';
import type { SystemConfigChangesDTO } from '../models/SystemConfigChangesDTO';
import type { SystemObjectQuantitiesDTO } from '../models/SystemObjectQuantitiesDTO';
import type { SystemOperationalStateHistoryDTO } from '../models/SystemOperationalStateHistoryDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProcessService {
    /**
     * @returns ProcessDTO Success
     * @throws ApiError
     */
    public static allProcesses(): CancelablePromise<Array<ProcessDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes',
        });
    }
    /**
     * @param processGuid
     * @returns ProcessDTO Success
     * @throws ApiError
     */
    public static getProcessById(
        processGuid: string,
    ): CancelablePromise<ProcessDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes/{processGuid}',
            path: {
                'processGuid': processGuid,
            },
        });
    }
    /**
     * @param processGuid
     * @param after
     * @param before
     * @returns SystemConfigChangesDTO Success
     * @throws ApiError
     */
    public static getSystemConfigChanges(
        processGuid: string,
        after: string,
        before?: string,
    ): CancelablePromise<SystemConfigChangesDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes/{processGuid}/system-config-changes',
            path: {
                'processGuid': processGuid,
            },
            query: {
                'after': after,
                'before': before,
            },
        });
    }
    /**
     * @param processGuid
     * @param after
     * @param before
     * @returns SystemObjectQuantitiesDTO Success
     * @throws ApiError
     */
    public static getSystemObjectQuantities(
        processGuid: string,
        after: string,
        before: string,
    ): CancelablePromise<SystemObjectQuantitiesDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes/{processGuid}/system-object-quantities',
            path: {
                'processGuid': processGuid,
            },
            query: {
                'after': after,
                'before': before,
            },
        });
    }
    /**
     * @param processGuid
     * @param after
     * @param before
     * @returns SystemOperationalStateHistoryDTO Success
     * @throws ApiError
     */
    public static getSystemOperationalStateHistories(
        processGuid: string,
        after: string,
        before: string,
    ): CancelablePromise<Record<string, SystemOperationalStateHistoryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes/{processGuid}/system-operational-state-histories',
            path: {
                'processGuid': processGuid,
            },
            query: {
                'after': after,
                'before': before,
            },
        });
    }
    /**
     * @param processGuid
     * @param before
     * @param after
     * @returns ProductionIntervalTemporalHistoryDTO Success
     * @throws ApiError
     */
    public static getTemporalProductionHistory(
        processGuid: string,
        before?: string,
        after?: string,
    ): CancelablePromise<ProductionIntervalTemporalHistoryDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes/{processGuid}/temporal-production-history',
            path: {
                'processGuid': processGuid,
            },
            query: {
                'before': before,
                'after': after,
            },
        });
    }
    /**
     * @param processGuid
     * @param before
     * @param after
     * @returns ProductionIntervalMassHistoryDTO Success
     * @throws ApiError
     */
    public static getMassProductionHistory(
        processGuid: string,
        before?: string,
        after?: string,
    ): CancelablePromise<ProductionIntervalMassHistoryDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/processes/{processGuid}/mass-production-history',
            path: {
                'processGuid': processGuid,
            },
            query: {
                'before': before,
                'after': after,
            },
        });
    }
}
