/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChutecMaterialClassDTO } from '../models/ChutecMaterialClassDTO';
import type { ChutecMaterialClassWindowsDTO } from '../models/ChutecMaterialClassWindowsDTO';
import type { ChutecSortProgramDTO } from '../models/ChutecSortProgramDTO';
import type { ChutecSortSystemDTO } from '../models/ChutecSortSystemDTO';
import type { ChutecStream } from '../models/ChutecStream';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ChutecService {
    /**
     * @param sortSystemGuid
     * @returns ChutecSortProgramDTO Success
     * @throws ApiError
     */
    public static getChutecSortPrograms(
        sortSystemGuid?: string,
    ): CancelablePromise<Array<ChutecSortProgramDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chutec/sort-programs',
            query: {
                'sortSystemGuid': sortSystemGuid,
            },
        });
    }
    /**
     * @returns ChutecSortSystemDTO Success
     * @throws ApiError
     */
    public static getChutecSystems(): CancelablePromise<Array<ChutecSortSystemDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chutec/systems',
        });
    }
    /**
     * @param chutecSortSystemGuid
     * @returns ChutecSortSystemDTO Success
     * @throws ApiError
     */
    public static getChutecSystemById(
        chutecSortSystemGuid: string,
    ): CancelablePromise<ChutecSortSystemDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chutec/systems/{chutecSortSystemGuid}',
            path: {
                'chutecSortSystemGuid': chutecSortSystemGuid,
            },
        });
    }
    /**
     * @param materialClassGuid
     * @returns ChutecMaterialClassDTO Success
     * @throws ApiError
     */
    public static getChutecMaterialClassById(
        materialClassGuid: string,
    ): CancelablePromise<ChutecMaterialClassDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chutec/material-classes/{materialClassGuid}',
            path: {
                'materialClassGuid': materialClassGuid,
            },
        });
    }
    /**
     * @param systemGuid
     * @param stream
     * @param start
     * @param maxPoints
     * @param end
     * @returns ChutecMaterialClassWindowsDTO Success
     * @throws ApiError
     */
    public static getMaterialClassAreaWindows(
        systemGuid: string,
        stream: ChutecStream,
        start: string,
        maxPoints: number,
        end?: string,
    ): CancelablePromise<ChutecMaterialClassWindowsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chutec/material-class-area-windows',
            query: {
                'systemGuid': systemGuid,
                'stream': stream,
                'start': start,
                'maxPoints': maxPoints,
                'end': end,
            },
        });
    }
}
