import { Flex, Input, Switch, Text, TextInput } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { match } from 'ts-pattern';
import { z } from 'zod';
import ContainerTypeSelect, {
  ContainerTypeSchema,
} from '../ContainerType/ContainerTypeSelect';
import SwitchAsLabeledInputClasses from '../Input/SwitchAsLabeledInput.module.css';
import QuantizedWeight from '../Weights/QuantizedWeight';
import QuantizedWeightTicksInput from '../Weights/QuantizedWeightTicksInput';
import { OptionalWeightSchema } from '../Weights/WeightZodSchemas';
import { WeightUnit } from '../rest-client';

const ContainerFormSchema = z
  .object({
    name: z.string().min(1, 'Container name is required'),
    containerType: ContainerTypeSchema.nullable(),
    overrideTareWeight: OptionalWeightSchema,
  })
  .refine((container) => container.containerType !== null, {
    message: 'Container type is required',
    path: ['containerType'],
  });

export type ContainerFormValues = z.infer<typeof ContainerFormSchema>;

export function useContainerForm() {
  return useForm<ContainerFormValues>({
    initialValues: {
      name: '',
      containerType: null,
      overrideTareWeight: {
        enabled: false,
        unit: WeightUnit.POUND,
        tickSize: 1,
        ticks: null,
      },
    },
    validate: zodResolver(ContainerFormSchema),
  });
}

export function ContainerFormFields(props: {
  form: UseFormReturnType<ContainerFormValues>;
}) {
  const { form } = props;

  const containerTypeDisabled =
    form.values.containerType !== null && !form.isDirty('containerType');

  return (
    <>
      <TextInput
        label='Container Name'
        description='Identifier for the container. If there is a name written or labeled on the physical container, type that label here.'
        placeholder='Name'
        data-autofocus
        withAsterisk
        {...form.getInputProps('name')}
      />
      {/* TODO(2156): when implementing container stacking behavior, include some sort of specification that the container type selection affects that behavior */}
      <ContainerTypeSelect
        label='Container Type'
        description='The type of phsical container. This selection sets default tare weight.'
        placeholder='Select container type'
        disabled={containerTypeDisabled}
        withAsterisk
        {...form.getInputProps('containerType')}
      />
      <Flex gap='md'>
        <Input.Wrapper
          label='Default Tare Weight'
          description='The default tare weight for the selected type of container.'
          labelProps={{ style: { color: 'gray.6' } }}
          maw='33%'
        >
          {match(form.values.containerType?.defaultTareWeight)
            .with(undefined, () => (
              <Text c='dimmed' size='md'>
                Unknown
              </Text>
            ))
            .with(null, () => <Text c='dimmed'>None</Text>)
            .otherwise((defaultTareWeight) => (
              <QuantizedWeight weight={defaultTareWeight} />
            ))}
        </Input.Wrapper>
        {match(form.values.containerType?.defaultTareWeight)
          .with(null, () => (
            <Input.Wrapper
              label='Specify Tare Weight'
              description='Is the tare weight of this container known?'
              maw='33%'
            >
              <Switch
                checked={form.values.overrideTareWeight.enabled}
                onChange={(e) => {
                  form.setFieldValue(
                    'overrideTareWeight.enabled',
                    e.currentTarget.checked,
                  );
                  // reset overriding tare weight on switch being disabled
                  if (!e.currentTarget.checked) {
                    form.setFieldValue('overrideTareWeight.ticks', null);
                  }
                }}
                color='orange'
                size='lg'
                className={SwitchAsLabeledInputClasses.switch}
              />
            </Input.Wrapper>
          ))
          .otherwise((defaultTareWeight) => (
            <Input.Wrapper
              label='Override Tare Weight'
              description='Ignore and override the default tare weight?'
              maw='33%'
            >
              <Switch
                checked={form.values.overrideTareWeight.enabled}
                onChange={(e) => {
                  form.setFieldValue(
                    'overrideTareWeight.enabled',
                    e.currentTarget.checked,
                  );
                  // reset overriding tare weight on switch being disabled
                  if (!e.currentTarget.checked) {
                    form.setFieldValue('overrideTareWeight.ticks', null);
                  }
                }}
                color='orange'
                size='lg'
                disabled={defaultTareWeight === undefined}
                className={SwitchAsLabeledInputClasses.switch}
              />
            </Input.Wrapper>
          ))}
        <QuantizedWeightTicksInput
          label='Tare Weight'
          description='The weight of this container when it is empty.'
          unit={form.values.overrideTareWeight.unit}
          tickSize={form.values.overrideTareWeight.tickSize}
          withAsterisk={form.values.overrideTareWeight.enabled}
          disabled={!form.values.overrideTareWeight.enabled}
          maw='33%'
          {...form.getInputProps('overrideTareWeight.ticks')}
        />
      </Flex>
    </>
  );
}
