import {
  Checkbox,
  Group,
  Loader,
  Pagination,
  Stack,
  Table,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { P, match } from 'ts-pattern';
import { ContainerIdName } from '../Container/ContainerIdName';
import { ContainerIcon, ProcessIcon, RecoveryGoalIcon } from '../Icons';
import { ProcessIdName } from '../Process/ProcessIdName';
import { RecoveryGoalPathName } from '../RecoveryGoal/RecoveryGoalPathName';
import { TableEmptyBasicContent } from '../TableEmptyBasicContent.tsx';
import Temporal from '../Temporal/temporal.ts';
import { useFeedFlowGroups } from '../api/feedFlowGroup';
import { CalendarDateTime, DurationTooltip } from '../common';
import { FeedFlowGroupDTO, ProcessId } from '../rest-client';
import { Router } from '../router';
import { FeedFlowGroupRecoveryGoalPathName } from './FeedFlowGroupName';

interface FeedFlowGroupRowProps {
  processId?: ProcessId; // used to determine whether the process data should be shown
  feedFlowGroup: FeedFlowGroupDTO;
  processOutputPortCount: number; // used to determine <tr> height
}

function FeedFlowGroupRow(props: FeedFlowGroupRowProps) {
  const { feedFlowGroup, processId, processOutputPortCount } = props;
  const showProcess = processId === undefined;
  const processRecoveryGoalPathsMap = new Map(
    feedFlowGroup.processState.processRecoveryGoalPaths.map((path) => [
      path.outputPortId,
      path,
    ]),
  );

  return (
    <tr
      style={{
        height: `${2.5 * processOutputPortCount}rem`,
        cursor: 'pointer',
      }}
      onClick={() =>
        Router.push('FeedFlowGroupDetail', {
          feedFlowGroupId: feedFlowGroup.id,
        })
      }
    >
      {showProcess && (
        <td>
          <ProcessIdName processId={feedFlowGroup.processState.processId} />
        </td>
      )}
      <td>
        <FeedFlowGroupRecoveryGoalPathName
          recoveryGoalPaths={
            feedFlowGroup.processState.processRecoveryGoalPaths
          }
          noLink={false}
        />
      </td>
      <td>
        <CalendarDateTime iso8601={feedFlowGroup.effectiveTimestamp} />
      </td>
      <td>
        <DurationTooltip
          startIso8601={feedFlowGroup.effectiveTimestamp}
          endIso8601={feedFlowGroup.lastFlowIntervalEnd}
        />
      </td>
      <td>
        <Stack>
          {feedFlowGroup.portFlowGroupsByKey.map((portFlowGroup) =>
            portFlowGroup.portFlowGroups.map((pfg, i) => {
              const recoveryGoalPath = processRecoveryGoalPathsMap.get(
                portFlowGroup.outputPortId,
              );
              return (
                <Group spacing='xs' key={i}>
                  {match(recoveryGoalPath)
                    .with(undefined, () => (
                      <Text c='dimmed'>Unknown Recovery Goal</Text>
                    ))
                    .with({ recoveryGoalPath: null }, () => (
                      <Text c='dimmed'>Unknown Recovery Goal</Text>
                    ))
                    .with(
                      { recoveryGoalPath: P.not(null) },
                      ({ recoveryGoalPath: path }) => (
                        <RecoveryGoalPathName recoveryGoalPath={path} noLink />
                      ),
                    )
                    .exhaustive()}
                  <IconArrowRight />
                  {pfg.containerId === null ? (
                    <Text c='red'>No Container</Text>
                  ) : (
                    <ContainerIdName
                      key={pfg.containerId}
                      containerId={pfg.containerId}
                      variant='name-only-link'
                      time={dayjs.utc(feedFlowGroup.effectiveTimestamp)}
                    />
                  )}
                </Group>
              );
            }),
          )}
        </Stack>
      </td>
    </tr>
  );
}

export type FeedFlowGroupTableProps = Omit<
  FeedFlowGroupRowProps,
  'feedFlowGroup' | 'processOutputPortCount'
> & {
  feedFlowGroups: FeedFlowGroupDTO[];
  isLoading: boolean;
  pageSize?: number;
  defaultOutputContainersFiltered?: boolean;
};

export function FeedFlowGroupTable(props: FeedFlowGroupTableProps) {
  const {
    feedFlowGroups,
    isLoading,
    pageSize = 10,
    defaultOutputContainersFiltered = false,
    processId,
    ...otherProps
  } = props;

  const theme = useMantineTheme();
  // TODO this state management could/should live with the Pagination abstraction
  const [page, setPage] = useState(1);
  const [outputContainersChecked, setOutputContainersChecked] = useState(
    defaultOutputContainersFiltered,
  );

  const showProcess = processId === undefined;

  let feedFlowGroupsFiltered = feedFlowGroups;
  if (outputContainersChecked) {
    feedFlowGroupsFiltered = feedFlowGroups.filter((ffg) =>
      ffg.portFlowGroupsByKey.every((pfgbk) =>
        pfgbk.portFlowGroups.every((pfg) => pfg.containerId !== null),
      ),
    );
  }
  const pages = Math.ceil(feedFlowGroupsFiltered.length / pageSize);

  const feedFlowGroupsPage = feedFlowGroupsFiltered.slice(
    pageSize * (page - 1),
    pageSize * page,
  );

  const isEmpty = feedFlowGroupsFiltered.length === 0;
  const processOutputPortCount = isEmpty
    ? 0
    : Object.entries(feedFlowGroups[0].processState.processRecoveryGoalPaths)
        .length;

  const tableBody = feedFlowGroupsPage.map((feedFlowGroup) => (
    <FeedFlowGroupRow
      key={feedFlowGroup.id}
      feedFlowGroup={feedFlowGroup}
      processId={processId}
      processOutputPortCount={processOutputPortCount}
      {...otherProps}
    />
  ));

  return (
    <Stack>
      <Table highlightOnHover>
        {(isLoading || isEmpty) && (
          <caption
            style={{
              captionSide: 'bottom',
              textAlign: 'center',
              padding: theme.spacing.md,
            }}
          >
            {isLoading && <Loader />}
            {!isLoading && isEmpty && (
              <TableEmptyBasicContent>No Process Runs</TableEmptyBasicContent>
            )}
          </caption>
        )}
        <thead>
          <tr>
            {showProcess && (
              <th>
                <Group spacing='xs'>
                  <ProcessIcon />
                  <Text>Process</Text>
                </Group>
              </th>
            )}
            <th>
              <Group spacing='xs'>
                <RecoveryGoalIcon />
                <Text>Recovery Goal</Text>
              </Group>
            </th>
            <th>Date</th>
            <th>Duration</th>
            <th>
              <Group spacing='xs'>
                <ContainerIcon />
                <Text>Output Containers</Text>
                <Checkbox
                  checked={outputContainersChecked}
                  onChange={(e) => {
                    setOutputContainersChecked(e.currentTarget.checked);
                    setPage(1);
                  }}
                />
              </Group>
            </th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
      <Pagination value={page} onChange={setPage} total={pages} />
    </Stack>
  );
}

export type AllFeedFlowGroupsTableProps = Omit<
  FeedFlowGroupTableProps,
  'feedFlowGroups' | 'isLoading'
> & {
  before?: Temporal.Instant;
  after?: Temporal.Instant;
  systemId?: string;
};

export function AllFeedFlowGroupsTable(props: AllFeedFlowGroupsTableProps) {
  const { before, after, processId, systemId, ...otherProps } = props;
  const feedFlowGroupsQuery = useFeedFlowGroups({
    before,
    after,
    processId,
    systemId,
  });

  if (feedFlowGroupsQuery.isLoadingError) {
    throw feedFlowGroupsQuery.error;
  }

  const feedFlowGroups = feedFlowGroupsQuery.data;

  const orderedFeedFlowGroups = feedFlowGroups
    ? feedFlowGroups.sort((a, b) =>
        b.effectiveTimestamp.localeCompare(a.effectiveTimestamp),
      )
    : [];

  return (
    <FeedFlowGroupTable
      feedFlowGroups={orderedFeedFlowGroups}
      isLoading={feedFlowGroupsQuery.isLoading}
      processId={processId}
      {...otherProps}
    />
  );
}
