import { useMutation } from '@tanstack/react-query';
import { ProcessStopCreationDTO, ProductionService } from '../rest-client';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function createProcessStop(args: {
  productionIntervalId: string;
  dto: ProcessStopCreationDTO;
}) {
  await ProductionService.createProcessStop(
    args.productionIntervalId,
    args.dto,
  );
}

export function useAddProcessStop() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createProcessStop,
    onSettled(data, error, { productionIntervalId }) {
      invalidator.invalidateKeys(
        queryKeys.processPlannedProductionInterval.temporalStats.byId(
          productionIntervalId,
        ),
      );
    },
  });
}

async function deleteProcessStop(args: {
  productionIntervalId: string;
  stopId: string;
}) {
  await ProductionService.deleteProcessStop(
    args.productionIntervalId,
    args.stopId,
  );
}

export function useDeleteProcessStop() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteProcessStop,
    onSettled(data, error, { productionIntervalId }) {
      invalidator.invalidateKeys(
        queryKeys.processPlannedProductionInterval.temporalStats.byId(
          productionIntervalId,
        ),
      );
    },
  });
}
