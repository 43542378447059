import { ActionIcon, Group, Table, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { match } from 'ts-pattern';
import { CommodityIdName } from '../Commodity/CommodityName';
import { EditIcon } from '../Icons';
import { InferredPropertyTextWrapper } from '../InferredPropertyWrapper';
import NetWeight, { NetWeightZero } from '../Weights/NetWeight';
import { useCommodities } from '../api/commodity';
import { useInternalMaterialSourcesDetailed } from '../api/internalMaterialSource';
import { WeightUnit } from '../rest-client';
import { EditInternalMaterialSourceDrawerForm } from './EditInternalMaterialSourceDrawerForm';
import { InternalMaterialSourceName } from './InternalMaterialSourceName';

export function InternalMaterialSourceTable() {
  const sourcesQuery = useInternalMaterialSourcesDetailed(undefined);
  const commoditiesQuery = useCommodities();
  const [editDrawerOperned, { open: openEditDrawer, close: closeEditDrawer }] =
    useDisclosure(false);

  const commodityNameLookup = commoditiesQuery.data
    ? new Map(commoditiesQuery.data.map((c) => [c.id, c.name]))
    : undefined;

  if (sourcesQuery.isLoadingError) {
    throw sourcesQuery.error;
  }
  const sources = sourcesQuery.data;

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Commodity</th>
          <th style={{ textAlign: 'right' }}>Sourced Material Count</th>
          <th style={{ textAlign: 'right' }}>Aggregate Sourced Mass</th>
          <th style={{ textAlign: 'right' }}>
            Sourced Material Unknown Mass Count
          </th>
        </tr>
      </thead>
      <tbody>
        {sources?.map((ims) => (
          <tr key={ims.internalMaterialSource.id}>
            <td>
              <InternalMaterialSourceName
                internalMaterialSource={ims.internalMaterialSource}
              />
            </td>
            <td>
              {ims.internalMaterialSource.commodityId ? (
                commodityNameLookup ? (
                  <CommodityIdName
                    commodityId={ims.internalMaterialSource.commodityId}
                  />
                ) : (
                  <Text color='dimmed'>Loading...</Text>
                )
              ) : (
                <Group>
                  <Text color='red' weight='bold'>
                    not set
                  </Text>
                  <EditInternalMaterialSourceDrawerForm
                    opened={editDrawerOperned}
                    onClose={closeEditDrawer}
                    internalMaterialSourceId={ims.internalMaterialSource.id}
                  />
                  <ActionIcon onClick={openEditDrawer}>
                    <EditIcon />
                  </ActionIcon>
                </Group>
              )}
            </td>
            <td style={{ textAlign: 'right' }}>
              {ims.internallySourcedMaterialCount}
            </td>
            <td style={{ textAlign: 'right' }}>
              {match(ims)
                .with({ status: 'ledger-error' }, () => (
                  <Text color='red'>Ledger Error</Text>
                ))
                .with(
                  { status: 'success' },
                  ({ netWeight, internallySourcedMaterialUnknownMassCount }) =>
                    netWeight.totalIsZero &&
                    internallySourcedMaterialUnknownMassCount === 0 ? (
                      0
                    ) : (
                      <Text>
                        {internallySourcedMaterialUnknownMassCount > 0
                          ? '> '
                          : undefined}
                        <InferredPropertyTextWrapper>
                          {netWeight.totalIsZero ? (
                            <NetWeightZero unit={WeightUnit.POUND} />
                          ) : (
                            <NetWeight
                              weight={netWeight}
                              sourceIconMode='icon-tooltip'
                            />
                          )}
                        </InferredPropertyTextWrapper>
                      </Text>
                    ),
                )
                .exhaustive()}
            </td>
            <td style={{ textAlign: 'right' }}>
              {match(ims)
                .with({ status: 'ledger-error' }, () => (
                  <Text color='red'>Ledger Error</Text>
                ))
                .with(
                  { status: 'success' },
                  ({ internallySourcedMaterialUnknownMassCount }) =>
                    internallySourcedMaterialUnknownMassCount,
                )
                .exhaustive()}
            </td>
          </tr>
        )) ?? null}
      </tbody>
    </Table>
  );
}
