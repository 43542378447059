import { Alert, Badge, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { match } from 'ts-pattern';
import { useFacilityContext } from '../../Facility/FacilityContext';
import { useDeleteEntityModal } from '../../Form/useDeleteEntityModal.tsx';
import {
  FullContainerIcon,
  OutputContainerChangeIcon,
  OutputContainerRemovedIcon,
  PartiallyFullContainerIcon,
} from '../../Icons';
import { EditOutputContainerChangeDrawerForm } from '../../OutputContainerChange/EditOutputContainerChangeDrawerForm.tsx';
import { ProcessName } from '../../Process/ProcessName';
import { ContainerName } from '../../Repository/RepositoryName';
import { useDeleteOutputContainerChange } from '../../api/outputContainerChange.ts';
import { CalendarDateTime } from '../../common';
import {
  OutputContainerChangeDTO,
  OutputContainerChangeErrorDTO,
} from '../../rest-client';
import { DeleteMenuItem } from '../DeleteMenuItem.tsx';
import { EditMenuItem } from '../EditMenuItem.tsx';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { useInventoryLedgerStatusContext } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu.tsx';

export function OutputContainerChangeRow(props: {
  outputContainerChange: OutputContainerChangeDTO;
}) {
  const { outputContainerChange } = props;
  const facility = useFacilityContext();
  const ledgerStatus = useInventoryLedgerStatusContext();
  const status = ledgerStatus.outputContainerChangeStatus(
    outputContainerChange,
  );

  const deleteMutation = useDeleteOutputContainerChange();
  const openDeleteModal = useDeleteEntityModal(
    outputContainerChange.id,
    deleteMutation,
    'Output Container Change',
  );

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const renderEventTypeCell = useCallback(
    () => (
      <Group spacing='xs'>
        {outputContainerChange.container === null ? (
          <OutputContainerRemovedIcon />
        ) : (
          <OutputContainerChangeIcon />
        )}
        <Badge color='yellow'>
          {outputContainerChange.container === null
            ? 'Output Container Removed'
            : 'Output Container Replaced'}
        </Badge>
      </Group>
    ),
    [outputContainerChange],
  );

  let errorExplanationContent = null;
  if (status.status === 'conflict') {
    errorExplanationContent = (
      <>
        {status.errors.map((error, i) => (
          <OutputContainerChangeErrorExplanation
            outputContainerChangeError={error}
            key={i}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <InventoryLedgerRowTemplate
        entryStatus={status}
        eventType={renderEventTypeCell()}
        date={
          <CalendarDateTime
            iso8601={outputContainerChange.effectiveTimestamp}
          />
        }
        source={
          outputContainerChange.container === null ? (
            <Group spacing='xs'>
              <OutputContainerRemovedIcon />
              <Text c='dimmed'>No Container</Text>
            </Group>
          ) : (
            <Group spacing='xs'>
              <OutputContainerChangeIcon />
              <ContainerName
                container={outputContainerChange.container}
                time={dayjs
                  .utc(outputContainerChange.effectiveTimestamp)
                  .tz(facility.timeZoneId)}
              />
              {outputContainerChange.previousContainerFilled ? (
                <PartiallyFullContainerIcon />
              ) : (
                <FullContainerIcon />
              )}
            </Group>
          )
        }
        destination={
          <Group spacing={0}>
            <ProcessName process={outputContainerChange.process} withIcon />
            <Text mx='0.5ch' weight={700} span>
              •
            </Text>
            <Text>{outputContainerChange.outputPort.name}</Text>
          </Group>
        }
        actions={
          <RowActionsMenu>
            <EditMenuItem onClick={openDrawer} />
            <DeleteMenuItem onClick={openDeleteModal} />
          </RowActionsMenu>
        }
      />
      {errorExplanationContent}
      <EditOutputContainerChangeDrawerForm
        outputContainerChangeId={outputContainerChange.id}
        opened={drawerOpened}
        onClose={closeDrawer}
      />
    </>
  );
}

function OutputContainerChangeErrorExplanation({
  outputContainerChangeError,
}: {
  outputContainerChangeError: OutputContainerChangeErrorDTO;
}) {
  return match(outputContainerChangeError)
    .with({ kind: 'SimultaneousOutputContainerChangeError' }, (error) => (
      <Alert color='red' title='Output Container Change Concurrency Conflict'>
        This output container change conflicts with another output container
        change that occurs at the same time on the same process output port:{' '}
        <ProcessName process={error.outputContainerChangeA.process} />{' '}
        {error.outputContainerChangeA.outputPort.name}.
      </Alert>
    ))
    .exhaustive();
}
