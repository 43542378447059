/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeedFlowGroupDTO } from '../models/FeedFlowGroupDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProcessStateService {
    /**
     * @param feedFlowGroupId
     * @returns FeedFlowGroupDTO Success
     * @throws ApiError
     */
    public static getFeedFlowGroupById(
        feedFlowGroupId: string,
    ): CancelablePromise<FeedFlowGroupDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/process-state/feed-flow-groups/{feedFlowGroupId}',
            path: {
                'feedFlowGroupId': feedFlowGroupId,
            },
        });
    }
}
