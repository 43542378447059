import { match } from 'ts-pattern';
import { The404 } from '../The404';
import { Router } from '../router';
import { ProcessDetail } from './ProcessDetailPage';
import { ProcessesPage } from './ProcessesPage';

export function ProcessArea() {
  const route = Router.useRoute(['ProcessDetail', 'ProcessList']);
  if (route === undefined) {
    return <The404 />;
  }
  return match(route)
    .with({ name: 'ProcessList' }, () => <ProcessesPage />)
    .with({ name: 'ProcessDetail' }, ({ params: { processId } }) => (
      <ProcessDetail processId={processId} key={processId} />
    ))
    .exhaustive();
}
