import Temporal from './temporal.ts';

export function plainDateToStartOfDayInstant(
  plainDate: Temporal.PlainDate,
  tz: string,
): Temporal.Instant {
  return plainDate.toZonedDateTime(tz).toInstant();
}

export type DateCalculator = (date: Temporal.PlainDate) => Temporal.PlainDate;
export type PredefinedDateOffsetId = 'd7' | 'd14' | 'd30' | 'mo3' | 'mo12';
export type DateOffsetId = PredefinedDateOffsetId | 'custom';
export type DateOffsetName =
  | 'Last 7 Days'
  | 'Last 14 Days'
  | 'Last 30 Days'
  | 'Last 3 Months'
  | 'Last 12 Months';
export interface PredefinedDateOffset {
  id: DateOffsetId;
  name: DateOffsetName;
  calculateStartDate: DateCalculator;
}

export const predefinedDateOffsets = new Map<
  PredefinedDateOffsetId,
  PredefinedDateOffset
>([
  [
    'd7',
    {
      id: 'd7',
      name: 'Last 7 Days',
      calculateStartDate: (endDate) => endDate.subtract({ days: 7 }),
    },
  ],
  [
    'd14',
    {
      id: 'd14',
      name: 'Last 14 Days',
      calculateStartDate: (endDate) => endDate.subtract({ days: 14 }),
    },
  ],
  [
    'd30',
    {
      id: 'd30',
      name: 'Last 30 Days',
      calculateStartDate: (endDate) => endDate.subtract({ days: 30 }),
    },
  ],
  [
    'mo3',
    {
      id: 'mo3',
      name: 'Last 3 Months',
      calculateStartDate: (endDate) => endDate.subtract({ months: 3 }),
    },
  ],
  [
    'mo12',
    {
      id: 'mo12',
      name: 'Last 12 Months',
      calculateStartDate: (endDate) => endDate.subtract({ months: 12 }),
    },
  ],
]);
export interface DateRange {
  startDate: Temporal.PlainDate;
  endDate: Temporal.PlainDate;
  id: DateOffsetId;
  name?: string;
}
