import dayjs from 'dayjs';
import { useFacilityContext } from '../Facility/FacilityContext';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import {
  usePatchProcessBufferRestoration,
  useProcessBufferRestoration,
} from '../api/processBufferRestoration';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import {
  ProcessBufferRestorationId,
  ProcessBufferRestorationPatchDTO,
} from '../rest-client';
import {
  ProcessBufferRestorationFormFields,
  ProcessBufferRestorationFormValues,
  useProcessBufferRestorationForm,
} from './ProcessBufferRestorationForm';

export type EditProcessBufferRestorationDrawerFormProps = {
  processBufferRestorationId: ProcessBufferRestorationId;
  onSuccess?: (
    processBufferRestoration: ProcessBufferRestorationPatchDTO,
  ) => void;
} & DrawerFormDrawerProps;

export function EditProcessBufferRestorationDrawerForm(
  props: EditProcessBufferRestorationDrawerFormProps,
) {
  const {
    processBufferRestorationId,
    onSuccess,
    onClose,
    ...drawerFormDrawerProps
  } = props;

  const facility = useFacilityContext();
  const {
    data: processBufferRestoration,
    isLoadingError,
    error,
  } = useProcessBufferRestoration(processBufferRestorationId);
  const patchMutation = usePatchProcessBufferRestoration(
    processBufferRestorationId,
  );
  const form = useProcessBufferRestorationForm({});

  useSetFormInitialValuesFromQuery(
    form,
    processBufferRestoration && {
      restoredAt: dayjs
        .utc(processBufferRestoration.effectiveTimestamp)
        .tz(facility.timeZoneId),
      processId: processBufferRestoration.processId,
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({
    restoredAt,
    processId,
  }: ProcessBufferRestorationFormValues) => {
    if (processId === null) {
      throw new Error('Process cannot be null ');
    }

    const processBufferRestorationPatch: ProcessBufferRestorationPatchDTO = {
      ...(form.isDirty('restoredAt') && {
        effectiveTimestamp: restoredAt.utc().toISOString(),
      }),
      ...(form.isDirty('processId') && { processId }),
    };

    return processBufferRestorationPatch;
  };

  return (
    <DrawerForm
      entityName='Feedstock Restoration'
      form={form}
      drawerFormVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <ProcessBufferRestorationFormFields form={form} />
    </DrawerForm>
  );
}
