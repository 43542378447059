import { Alert, Anchor, Text } from '@mantine/core';
import { IconCircleArrowRightFilled } from '@tabler/icons-react';
import { useCallback } from 'react';
import Temporal from '../Temporal/temporal.ts';
import { InlineIcon } from '../UI/InlineIcon';

export const InventoryLedgerEmptyState = ({
  start,
  end,
  lastEvent,
  findLedgerEntry,
}: {
  start: Temporal.ZonedDateTime;
  end: Temporal.ZonedDateTime;
  lastEvent?: Temporal.ZonedDateTime;
  findLedgerEntry: ({ date }: { date: Temporal.PlainDate }) => void;
}) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (evt) => {
      evt.preventDefault();
      lastEvent &&
        findLedgerEntry({ date: Temporal.PlainDate.from(lastEvent) });
    },
    [findLedgerEntry, lastEvent],
  );

  let linkContent = null;
  if (lastEvent) {
    const now = Temporal.Now.instant().toZonedDateTimeISO(lastEvent.timeZoneId);
    // we're only rendering this when the user is viewing 'today', as
    // that's the default state that most needs handling. The
    // upcoming calendar will better support general nav in the ledger
    if (
      Temporal.ZonedDateTime.compare(now, start) >= 0 &&
      Temporal.ZonedDateTime.compare(now, end) < 0
    ) {
      linkContent = (
        <Text size='md'>
          <Anchor color='yellow' href='' onClick={handleClick}>
            <InlineIcon>
              <IconCircleArrowRightFilled width='1em' height='1em' />
            </InlineIcon>
            Jump to last ledger event
          </Anchor>
        </Text>
      );
    }
  }

  return (
    <tr>
      <td colSpan={6} style={{ textAlign: 'center' }}>
        <Alert color='yellow' title=''>
          <Text size='md'>No events occurred during this period.</Text>
          {linkContent}
        </Alert>
      </td>
    </tr>
  );
};
