import { Text } from '@mantine/core';
import { IconCircleFilled } from '@tabler/icons-react';
import { useAlertColors } from '../lib/colors';
import { MaterialClassDTO } from '../rest-client';
import { useRecoveryStrategySimulationCtx } from './RecoveryStrategySimulationContext';

export function MaterialClassLegendItem(props: {
  materialClass: MaterialClassDTO;
  color: string;
}) {
  const { materialClass, color } = props;
  const { selectedMaterialClassId, setSelectedMaterialClassId } =
    useRecoveryStrategySimulationCtx();
  const alertColors = useAlertColors();

  return (
    <Text
      p={3}
      key={materialClass.id}
      onClick={() => setSelectedMaterialClassId(materialClass.id)}
      color={color}
      weight={500}
      style={{
        transition: '0.2s',
        cursor: 'pointer',
        userSelect: 'none',
        borderRadius: '4px',
        backgroundColor:
          selectedMaterialClassId === materialClass.id
            ? alertColors.yellow
            : 'rgba(0,0,0,0)',
      }}
    >
      <IconCircleFilled size='1.2em' style={{ marginBottom: '-.2em' }} />
      {materialClass.name}
    </Text>
  );
}
