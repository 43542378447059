import { Skeleton, Text } from '@mantine/core';
import {
  ContainerName,
  ContainerNameProps,
} from '../Repository/RepositoryName';
import { useContainer } from '../api/container';

export type ContainerIdNameProps = Omit<ContainerNameProps, 'container'> & {
  containerId: string;
};

export function ContainerIdName(props: ContainerIdNameProps) {
  const { containerId, truncate, ...rest } = props;

  const { data: container, isError } = useContainer(containerId);

  if (container) {
    return (
      <ContainerName container={container} truncate={truncate} {...rest} />
    );
  }

  if (isError) {
    return (
      <Text color='red' truncate={truncate}>
        Error geting container name
      </Text>
    );
  }

  return (
    <Skeleton visible>
      <Text truncate={truncate}>Loading container name...</Text>
    </Skeleton>
  );
}
