import { Breadcrumbs, Text } from '@mantine/core';
import classes from './css/MetricHeaderBreadcrumbs.module.css';
import { LinkText } from '../../../Link.tsx';
import { ReactNode } from 'react';

export interface MetricHeaderBreadcrumbsNode {
  title: ReactNode;
  url?: string;
}

export interface MetricHeaderBreadcrumbsProps {
  data: MetricHeaderBreadcrumbsNode[];
}

export function MetricHeaderBreadcrumbs(props: MetricHeaderBreadcrumbsProps) {
  const { data } = props;

  return (
    <Breadcrumbs separator={<Text fz='xl'>/</Text>}>
      {data.map(({ title, url }, i) => {
        if (url) {
          return (
            <LinkText key={i} to={url} replace={false} className={classes.node}>
              {title}
            </LinkText>
          );
        }

        return (
          <Text key={i} className={classes.node}>
            {title}
          </Text>
        );
      })}
    </Breadcrumbs>
  );
}
