import { Loader } from '@mantine/core';
import dayjs from 'dayjs';
import { ChutecAreasChart } from '../../../ChutecAreasChart';
import Temporal from '../../../Temporal/temporal.ts';
import { useChutecMaterialClassAreaWindows } from '../../../api/chutec';
import { ChutecSortSystemDTO, ChutecStream } from '../../../rest-client';

const WINDOW_HACK_BEFORE_SECONDS = 7;
const WINDOW_HACK_AFTER_SECONDS = 4;

export function CumulativeChutecSystemMetrics(props: {
  system: ChutecSortSystemDTO;
  stream: ChutecStream;
  startTime: Temporal.Instant;
  endTime: Temporal.Instant;
}) {
  const { system, stream, startTime, endTime } = props;
  // TODO(chutec): Gross hack to not deal with alignment of windows
  const windowStartTime = dayjs.utc(
    startTime.subtract({ seconds: WINDOW_HACK_BEFORE_SECONDS })
      .epochMilliseconds,
  );
  const windowEndTime = dayjs.utc(
    endTime.add({ seconds: WINDOW_HACK_AFTER_SECONDS }).epochMilliseconds,
  );

  const windowsQuery = useChutecMaterialClassAreaWindows(
    system.id,
    stream,
    windowStartTime,
    windowEndTime,
  );
  if (windowsQuery.data) {
    // TODO(chutec): This isn't always the _final_ entry. We should always include the last time point even if it doesn't align with the time basis stride
    const cumulativeAreas = Object.fromEntries(
      Object.entries(windowsQuery.data.areasM2).map(
        ([materialClassId, areas]) => [
          materialClassId,
          areas[areas.length - 1],
        ],
      ),
    );
    return <ChutecAreasChart areas={cumulativeAreas} />;
  }
  return <Loader />;
}
