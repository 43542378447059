import { useVendor } from '../api/vendor';
import { AppPage } from '../App/AppPage';
import { MaterialVendorId } from '../rest-client';
import { Router } from '../router';
import { EditVendorForm } from './EditVendorForm';

export function EditVendorPage(props: { vendorId: MaterialVendorId }) {
  const { vendorId } = props;
  const vendorQuery = useVendor(vendorId);
  const vendor = vendorQuery.data;

  const breadcrumbs = [
    { routeName: Router.VendorList(), title: 'Vendors' },
    {
      routeName: Router.VendorDetail({ vendorId }),
      title: vendor === undefined ? null : vendor.name,
    },
  ];

  return (
    <AppPage breadcrumbs={breadcrumbs} title='Edit'>
      <AppPage.Section>
        <EditVendorForm vendorId={vendorId} />
      </AppPage.Section>
    </AppPage>
  );
}
