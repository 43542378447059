import { Alert } from '@mantine/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { useCallback } from 'react';
import { match } from 'ts-pattern';
import { EChart } from './echarts/BareEChart';
import { Composition } from './util/mixture';

echarts.use([BarChart, GridComponent, LegendComponent, TooltipComponent]);

export interface FormatterParams {
  color: string;
  name: string;
  seriesName: string;
  value: number;
}

export function CompositionComparisonChart(props: {
  compositions: {
    name: string;
    composition: Composition<string>;
  }[];
  variant?: 'bar';
  seriesLabelEnabled?: boolean;
  compositionNameChartFormatter?: (name: string) => string;
  tooltipFormatter?: (paramsArr: FormatterParams[]) => string;
}) {
  const {
    compositions: labeledCompositions,
    variant = 'bar',
    seriesLabelEnabled = true,
    tooltipFormatter,
    compositionNameChartFormatter = (name) => name,
  } = props;

  const percentageFormatter = useCallback(
    (val: number | null) =>
      `${val === null ? 'unknown ' : (val * 100).toFixed(1)}%`,
    [],
  );

  const percentageValueFormatter = useCallback(
    (p: { value: number }) => (p.value * 100).toFixed(1) + '%',
    [],
  );

  if (labeledCompositions.length === 0) {
    return <Alert color='yellow'>No compositions to compare.</Alert>;
  }

  const keySet = [...labeledCompositions[0].composition.keys()];
  return match(variant)
    .with('bar', () => (
      <EChart
        style={{ height: 350, width: '100%' }}
        option={{
          grid: {
            bottom: 80,
          },
          xAxis: {
            type: 'category',
            data: keySet,
            axisLabel: {
              interval: 0,
              rotate: 45,
            },
          },
          yAxis: {
            axisLabel: {
              formatter: percentageFormatter,
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: tooltipFormatter,
            valueFormatter: percentageFormatter,
          },
          legend: {
            formatter: compositionNameChartFormatter,
          },
          series: labeledCompositions.map(
            ({ name: compName, composition }) => ({
              type: 'bar',
              data: keySet.map((k) => composition.get(k)),
              name: compName,
              label: {
                show: seriesLabelEnabled,
                position: 'top',
                align: 'middle',
                verticalAlign: 'middle',
                formatter: percentageValueFormatter,
              },
              emphasis: {
                focus: 'series',
              },
              barGap: 0,
            }),
          ),
        }}
      />
    ))
    .exhaustive();
}
