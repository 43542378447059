import { useState } from 'react';
import { match } from 'ts-pattern';
import { LinkText } from '../../../Link';
import {
  DateRange,
  plainDateToStartOfDayInstant,
} from '../../../Temporal/dateRangeAndOffset';
import Temporal from '../../../Temporal/temporal';
import { useProductionAggregateTemporalMetrics } from '../../../api/production';
import { formatPercentage } from '../../../lib/percentages';
import {
  PlannedProductionTemporalMetricsDTO,
  ProcessProductionAggregateTemporalMetricsDTO,
  ProductionAggregateTemporalMetricsDTO,
} from '../../../rest-client';
import { Router } from '../../../router';
import { useFacilityContext } from '../../FacilityContext';
import { useInsightsSelectedDateRange } from '../DateRange/InsightsDateRange';
import {
  MetricDetailPageTemplate,
  MetricDetailTableColumn,
} from './MetricDetailPageTemplate';

function calculateActivityRate(
  productionIntervalTemporalMetric: PlannedProductionTemporalMetricsDTO,
) {
  const { totalActiveMinutes, totalPlannedProductionMinutes } =
    productionIntervalTemporalMetric;
  return totalActiveMinutes / totalPlannedProductionMinutes;
}

export function getSeriesData(
  queryData: ProductionAggregateTemporalMetricsDTO,
) {
  return queryData.processProductionMetrics.map((metric) => {
    const processName = metric.process.name;
    return {
      name: processName,
      entries: metric.productionIntervalStats.map(
        (productionIntervalTemporalMetric) => {
          const { productionInterval, metrics } =
            productionIntervalTemporalMetric;
          return {
            timestamp: productionInterval.endTime,
            name: processName,
            value: +(calculateActivityRate(metrics) * 100).toFixed(1),
          };
        },
      ),
    };
  });
}

export function SystemUtilizationMetricPage() {
  const { id: facilityId, timeZoneId: tz } = useFacilityContext();
  const [nowZonedDateTime] = useState(Temporal.Now.zonedDateTimeISO(tz));
  const thirtyDaysAgo = nowZonedDateTime.subtract({ days: 30 });
  const { dateRange, setDateRange } = useInsightsSelectedDateRange({
    startDate: thirtyDaysAgo.toPlainDate(),
    endDate: nowZonedDateTime.toPlainDate(),
    name: 'Last 30 Days',
    id: 'd30',
  });
  const { startDate, endDate } = dateRange;
  const rangeInstants = {
    startInstant: plainDateToStartOfDayInstant(startDate, tz),
    // TODO end of day, probably
    endInstant: plainDateToStartOfDayInstant(endDate, tz),
  };
  const { startInstant, endInstant } = rangeInstants;
  const query = useProductionAggregateTemporalMetrics({
    facilityId,
    after: startInstant,
    before: endInstant,
  });

  const { seriesData, tableData } = match(query)
    .with({ status: 'loading' }, () => ({
      loading: true,
      error: null,
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'error' }, (query) => ({
      loading: false,
      error: query.error as Error,
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'success' }, (query) => ({
      loading: false,
      error: null,
      seriesData: getSeriesData(query.data),
      tableData: query.data.processProductionMetrics,
    }))
    .exhaustive();

  interface RenderColumnArgs {
    productionAggregates: ProcessProductionAggregateTemporalMetricsDTO;
    dateRange?: DateRange;
  }

  type CommodityPurityThroughputColumn = MetricDetailTableColumn & {
    renderTableValue: ({
      productionAggregates,
      dateRange,
    }: Required<RenderColumnArgs>) => JSX.Element;
  };

  const columns: CommodityPurityThroughputColumn[] = [
    {
      title: 'Process',
      numeric: false,
      renderTableValue: ({
        productionAggregates,
      }: Required<RenderColumnArgs>) => (
        <td key='process'>
          <LinkText
            to={Router.FacilityInsightsMetricFacetDetail({
              metricName: 'system-utilization',
              facetName: productionAggregates.process.id,
            })}
          >
            {productionAggregates.process.name}
          </LinkText>
        </td>
      ),
    },
    {
      title: 'Activity Rate',
      numeric: true,
      renderTableValue: ({ productionAggregates }: RenderColumnArgs) => (
        <td style={{ textAlign: 'right' }} key='activity'>
          {formatPercentage(
            calculateActivityRate(productionAggregates.metrics),
          )}
        </td>
      ),
    },
    {
      title: 'Planned Stops',
      numeric: true,
      renderTableValue: ({ productionAggregates }: RenderColumnArgs) => (
        <td style={{ textAlign: 'right' }} key='planned'>
          {formatPercentage(
            productionAggregates.metrics.totalPlannedStopMinutes /
              productionAggregates.metrics.totalPlannedProductionMinutes,
          )}
        </td>
      ),
    },
    {
      title: 'Unplanned Stops',
      numeric: true,
      renderTableValue: ({ productionAggregates }: RenderColumnArgs) => (
        <td style={{ textAlign: 'right' }} key='unplanned'>
          {formatPercentage(
            productionAggregates.metrics.totalUnplannedStopMinutes /
              productionAggregates.metrics.totalPlannedProductionMinutes,
          )}
        </td>
      ),
    },
    {
      title: 'Faulted',
      numeric: true,
      renderTableValue: ({ productionAggregates }: RenderColumnArgs) => (
        <td style={{ textAlign: 'right' }} key='faulted'>
          {formatPercentage(
            productionAggregates.metrics.totalFaultedMinutes /
              productionAggregates.metrics.totalPlannedProductionMinutes,
          )}
        </td>
      ),
    },
  ];

  const renderTableRow = (
    productionAggregates: ProcessProductionAggregateTemporalMetricsDTO,
  ) => (
    <tr key={productionAggregates.process.id}>
      {columns.map((col) => {
        return col.renderTableValue({
          productionAggregates,
          dateRange,
        });
      })}
    </tr>
  );

  return (
    <MetricDetailPageTemplate<ProcessProductionAggregateTemporalMetricsDTO>
      dateRange={dateRange}
      setDateRange={setDateRange}
      title='System Activity'
      graphTitle='System Utilization Rate'
      tableTitle='System Utilization by Process'
      tableColumns={columns}
      seriesData={seriesData}
      tableData={tableData}
      renderTableRow={renderTableRow}
      query={query}
      yAxisLabel='Utilization %'
    />
  );
}
