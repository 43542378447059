import { Text } from '@mantine/core';
import { ScaleIcon } from '../Icons';
import { IconText } from '../Repository/RepositoryName';
import { useScale } from '../api/scale';
import { ScaleId } from '../rest-client';

export function ScaleIdName({
  scaleId,
  withIcon,
}: {
  scaleId: ScaleId;
  withIcon?: boolean;
}) {
  const scaleQuery = useScale(scaleId);
  // TODO(420): Set up page for scales and link this to that properly
  if (withIcon) {
    return (
      <IconText>
        <ScaleIcon />
        <Text>{scaleQuery.data?.name ?? 'loading...'}</Text>
      </IconText>
    );
  } else {
    return <Text>{scaleQuery.data?.name ?? 'loading...'}</Text>;
  }
}
