import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateProcessBufferTransfer } from '../api/processBufferTransfer';
import { ProcessBufferTransferCreationDTO } from '../rest-client';
import {
  ProcessBufferTransferFormFields,
  ProcessBufferTransferFormProps,
  ProcessBufferTransferFormValues,
  useProcessBufferTransferForm,
} from './ProcessBufferTransferForm';

export type AddProcessBufferTransferDrawerFormProps =
  ProcessBufferTransferFormProps & {
    onSuccess?: (
      processBufferTransfer: ProcessBufferTransferCreationDTO,
    ) => void;
  } & DrawerFormDrawerProps;

export function AddProcessBufferTransferDrawerForm(
  props: AddProcessBufferTransferDrawerFormProps,
) {
  const {
    sourceContainerId,
    processId,
    onSuccess,
    onClose,
    ...drawerFormDrawerProps
  } = props;

  const createMutation = useCreateProcessBufferTransfer();
  const form = useProcessBufferTransferForm({
    sourceContainerId,
    processId,
  });
  const processBufferTransferId = useMemo(() => uuidv4(), []);

  const formValuesToDto = ({
    timestamp,
    sourceContainerId,
    sourceEmptied,
    processId,
  }: ProcessBufferTransferFormValues) => {
    if (sourceContainerId === null) {
      throw new Error('Source container cannot be null ');
    }
    if (processId === null) {
      throw new Error('Process cannot be null ');
    }

    const processBufferTransfer: ProcessBufferTransferCreationDTO = {
      id: processBufferTransferId,
      effectivelyTransferred: timestamp.utc().toISOString(),
      materialContainerId: sourceContainerId,
      sourceEmptied,
      processId,
    };

    return processBufferTransfer;
  };

  return (
    <DrawerForm
      entityName='Feedstock Transfer'
      form={form}
      drawerFormVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <ProcessBufferTransferFormFields
        form={form}
        containerSelectVariant='material'
      />
    </DrawerForm>
  );
}
