import { Badge, Group } from '@mantine/core';
import { PartitionIcon } from '../../Icons';
import { InternallySourcedMaterialIdName } from '../../InternallySourcedMaterial/InternallySourcedMaterialIdName';
import { CalendarDateTime } from '../../common';
import { InternallySourcedMaterialPartitionDTO } from '../../rest-client';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { TransactionStatus } from '../LedgerStatusContext';
import { PartitionDestinationCell } from './PartitionDestinationCell';

export function InternallySourcedMaterialPartitionRow(props: {
  id: string;
  partition: InternallySourcedMaterialPartitionDTO;
  status: TransactionStatus;
}) {
  const { partition, status, id } = props;

  return (
    <InventoryLedgerRowTemplate
      id={id}
      entryStatus={status}
      date={<CalendarDateTime iso8601={partition.effectiveTimestamp} />}
      eventType={
        <Group spacing='xs'>
          <PartitionIcon />
          <Badge color='grape'>Partition</Badge>
        </Group>
      }
      source={
        <InternallySourcedMaterialIdName
          internallySourcedMaterialId={partition.internallySourcedMaterialId}
          variant='icon-name-link'
        />
      }
      destination={<PartitionDestinationCell partition={partition} />}
      actions={undefined}
      // TODO(1809): Should actions here just route to the detail page?
    />
  );
}
