import { useMutation, useQuery } from '@tanstack/react-query';
import {
  MaterialContainerService,
  MaterialContainerTransferCreationDTO,
  MaterialContainerTransferId,
  MaterialContainerTransferPatchDTO,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchContainerTransfer(
  ctx: QueryFunctionContexts['transaction']['containerTransfer']['detail'],
) {
  const [{ containerTransferId }] = ctx.queryKey;
  return await MaterialContainerService.getContainerTransferById(
    containerTransferId,
  );
}

export function useContainerTransfer(containerTransferId: string) {
  return useQuery({
    queryKey:
      queryKeys.transaction.containerTransfer.detail(containerTransferId),
    queryFn: fetchContainerTransfer,
  });
}

async function createContainerTransfer(
  transfer: MaterialContainerTransferCreationDTO,
) {
  await MaterialContainerService.createContainerTransfer(transfer);
}

export function useCreateContainerTransfer() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createContainerTransfer,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.transaction.containerTransfer.lists(),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function patchContainerTransfer({
  transferId,
  patch,
}: {
  transferId: string;
  patch: MaterialContainerTransferPatchDTO;
}) {
  await MaterialContainerService.patchContainerTransfer(transferId, patch);
}

export function usePatchContainerTransfer(
  transferId: MaterialContainerTransferId,
) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: MaterialContainerTransferPatchDTO) =>
      await patchContainerTransfer({ transferId, patch }),
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.transaction.containerTransfer.lists(),
        queryKeys.transaction.containerTransfer.detail(transferId),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function deleteContainerTransfer(containerTransferId: string) {
  await MaterialContainerService.deleteContainerTransfer(containerTransferId);
}

export function useDeleteContainerTransfer() {
  const invalidator = useQueryKeyInvalidator();

  return useMutation({
    mutationFn: deleteContainerTransfer,
    onSettled(data, error, containerTransferId) {
      invalidator.invalidateKeys(
        queryKeys.transaction.containerTransfer.lists(),
        queryKeys.transaction.containerTransfer.detail(containerTransferId),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}
