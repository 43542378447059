import { Button, Group, Popover, Text } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { IconArrowUp } from '@tabler/icons-react';
import { FormActionButton } from '../../../Form/FormActionButton.tsx';
import { MetricHeaderProps } from './MetricHeader.tsx';

export type MetricHeaderExportButtonProps = Pick<MetricHeaderProps, 'title'>;

export function MetricHeaderExportButton(props: MetricHeaderExportButtonProps) {
  const { title } = props;

  const [opened, toggleOpened] = useToggle();

  return (
    <Popover
      opened={opened}
      onChange={toggleOpened}
      width={400}
      position='bottom-end'
    >
      <Popover.Target>
        <Button leftIcon={<IconArrowUp />} onClick={() => toggleOpened()}>
          Export to CSV
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Text fw='700' mb='md'>
          Export .CSV file for:
        </Text>
        <Text>
          Metrics:{' '}
          <Text span fw='700'>
            {title}
          </Text>
        </Text>
        <Text>
          Range:{' '}
          <Text span fw='700'>
            {/* TODO dateRangeText */}
          </Text>
        </Text>
        <Group position='right' mt='xl'>
          <FormActionButton action='cancel' onClick={() => toggleOpened(false)}>
            Cancel
          </FormActionButton>
          <Button
            leftIcon={<IconArrowUp />}
            onClick={() => toggleOpened(false)}
          >
            Export
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
}
