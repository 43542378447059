import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { InternalMaterialSourceIcon } from '../Icons';
import {
  AddInternalMaterialSourceDrawerForm,
  AddInternalMaterialSourceDrawerFormProps,
} from './AddInternalMaterialSourceDrawerForm';

export type AddInternalMaterialSourceButtonProps = Omit<
  ButtonProps,
  'onClick'
> & {
  addInternalMaterialSourceDrawerFormProps: Omit<
    AddInternalMaterialSourceDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddInternalMaterialSourceButton(
  props: AddInternalMaterialSourceButtonProps,
) {
  const {
    addInternalMaterialSourceDrawerFormProps,
    leftIcon = <InternalMaterialSourceIcon />,
    children = 'Add Upstream Material Source',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddInternalMaterialSourceDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addInternalMaterialSourceDrawerFormProps}
      />
    </>
  );
}
