import { Text } from '@mantine/core';

export type MoneyProps = {
  value: number;
};

export function Money(props: MoneyProps) {
  const { value } = props;
  return <Text>{formatCurrency(value)}</Text>;
}

function formatCurrency(value: number, currency: string = 'USD') {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(value);
}
