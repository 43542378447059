import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useContainer, usePatchContainer } from '../api/container';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import {
  MaterialContainerId,
  MaterialContainerPatchDTO,
  WeightUnit,
} from '../rest-client';
import {
  ContainerFormFields,
  ContainerFormValues,
  useContainerForm,
} from './ContainerForm';

export type EditContainerDrawerFormProps = {
  containerId: MaterialContainerId;
  onSuccess?: (container: MaterialContainerPatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditContainerDrawerForm(props: EditContainerDrawerFormProps) {
  const { containerId, onSuccess, onClose, ...drawerFormDrawerProps } = props;

  const { data: container, isLoadingError, error } = useContainer(containerId);
  const patchMutation = usePatchContainer(containerId);
  const form = useContainerForm();

  useSetFormInitialValuesFromQuery(
    form,
    container && {
      name: container.name,
      containerType: container.containerType,
      overrideTareWeight:
        container.overrideTareWeight !== null
          ? {
              enabled: true,
              unit: container.overrideTareWeight.unit,
              tickSize: Number(container.overrideTareWeight.tickSize),
              ticks: container.overrideTareWeight.ticks,
            }
          : {
              enabled: false,
              unit: WeightUnit.POUND,
              tickSize: 1,
              ticks: null,
            },
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({
    name,
    overrideTareWeight,
  }: ContainerFormValues) => {
    const containerPatch: MaterialContainerPatchDTO = {
      ...(form.isDirty('name') && { name }),
    };

    if (overrideTareWeight.enabled) {
      if (overrideTareWeight.ticks === null)
        throw new Error(
          'Ticks cannot be null if override tare weight is enabled',
        );
      containerPatch.overrideTareWeight = {
        unit: overrideTareWeight.unit,
        tickSize: `${overrideTareWeight.tickSize}`,
        ticks: overrideTareWeight.ticks,
      };
    } else {
      containerPatch.overrideTareWeight = null;
    }

    return containerPatch;
  };

  return (
    <DrawerForm
      entityName='Container'
      form={form}
      drawerFormVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <ContainerFormFields form={form} />
    </DrawerForm>
  );
}
