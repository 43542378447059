import { Loader } from '@mantine/core';
import { match } from 'ts-pattern';
import { useSortSystem } from '../api/sortSystem';
import { ChutecSortSystemDetail } from './chutec/ChutecSortSystemDetail';
import { RedWaveSortSystemDetail } from './redwave/RedWaveSortSystemDetail';

export function SortSystemDetail(props: { sortSystemId: string }) {
  const { sortSystemId } = props;

  const systemQuery = useSortSystem(sortSystemId);

  if (systemQuery.isLoadingError) {
    throw systemQuery.error;
  }

  if (systemQuery.isLoading) {
    return <Loader />;
  }

  const system = systemQuery.data;

  return match(system)
    .with({ kind: 'Chutec' }, (chutecSystem) => (
      <ChutecSortSystemDetail chutecSystem={chutecSystem} />
    ))
    .with({ kind: 'RedWave' }, (redWaveSystem) => (
      <RedWaveSortSystemDetail redWaveSystem={redWaveSystem} />
    ))
    .exhaustive();
}
