import dayjs from 'dayjs';
import { ContainerIdName } from '../../Container/ContainerIdName';
import { useDeleteEntityModal } from '../../Form/useDeleteEntityModal';
import { InternalMaterialSinkTransferIcon } from '../../Icons';
import { InternalMaterialSinkIdName } from '../../InternalMaterialSink/InternalMaterialSinkIdName';
import { useDeleteInternalMaterialSinkContainerTransfer } from '../../api/internalSinkContainerTransfer';
import { CalendarDateTime } from '../../common';
import { InternalSinkContainerTransferDTO } from '../../rest-client';
import { DeleteMenuItem } from '../DeleteMenuItem';
import { EventTypeCellTemplate } from '../InventoryLedgerEventTypeCell';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { TransactionStatus } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu';

export function InternalSinkContainerTransferRow(props: {
  id: string;
  transfer: InternalSinkContainerTransferDTO;
  status: TransactionStatus;
}) {
  const { transfer, status } = props;
  const deleteMutation = useDeleteInternalMaterialSinkContainerTransfer();
  const openDeleteModal = useDeleteEntityModal(
    transfer.id,
    deleteMutation,
    'Material Export',
  );

  return (
    <InventoryLedgerRowTemplate
      entryStatus={status}
      date={<CalendarDateTime iso8601={transfer.effectiveTimestamp} />}
      eventType={
        <EventTypeCellTemplate
          icon={<InternalMaterialSinkTransferIcon />}
          color={'pink'}
          name={'Material Export'}
        />
      }
      source={
        <ContainerIdName
          containerId={transfer.containerId}
          variant='icon-name-link'
          time={dayjs.utc(transfer.effectiveTimestamp)}
        />
      }
      destination={
        <InternalMaterialSinkIdName
          internalMaterialSinkId={transfer.internalSinkId}
          variant='icon-name-link'
        />
      }
      actions={
        <RowActionsMenu>
          <DeleteMenuItem onClick={openDeleteModal} />
        </RowActionsMenu>
      }
    />
  );
}
