import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Checkbox,
  Loader,
  Table,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { ReactNode, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AddIcon, DeleteIcon } from '../../Icons';
import { TableEmptyBasicContent } from '../../TableEmptyBasicContent.tsx';
import {
  useAddRedWaveMaterialClassMetadata,
  useDeleteRedwaveMaterialClassMetadata,
  useRedWaveProfileRevision,
} from '../../api/redWave';
import {
  RedWaveMaterialClassMetadataCreationDTO,
  RedWaveMaterialClassMetadataDTO,
  RedWaveMaterialClassNumber,
  RedWaveSortProgramProfileRevisionId,
} from '../../rest-client';
import useRedWaveMaterialClassTableStyles from './RedWaveMaterialClassTable.style';

export function RedWaveMaterialClassTable(props: {
  profileRevisionId: string;
}) {
  const { profileRevisionId } = props;
  const profileRevisionQuery = useRedWaveProfileRevision(profileRevisionId);

  if (profileRevisionQuery.isLoadingError) {
    return (
      <Alert title='Error Loading Material Classes' color='red'>
        An error occurred loading Material Classes for this Sort Program. This
        may be because no sorting data has been collected for this Sort Program.
      </Alert>
    );
  }
  const isLoading = profileRevisionQuery.isLoading;
  if (isLoading) {
    return (
      <RedWaveMaterialClassTableTemplate isLoading={isLoading}>
        {null}
      </RedWaveMaterialClassTableTemplate>
    );
  }
  const profileRevision = profileRevisionQuery.data;

  // TODO(147): Fix nullability in openapi schema
  type FixedMaterialClassMetadata = Record<
    string,
    RedWaveMaterialClassMetadataDTO | null
  >;
  const materialClassNumberMetadata =
    profileRevision.materialClassNumberMetadata as FixedMaterialClassMetadata;

  if (Object.keys(materialClassNumberMetadata).length === 0) {
    return (
      <Alert title='No Material Classes' color='yellow'>
        No material classes are defined on this sort program.
      </Alert>
    );
  }

  const existingMaterialClassNames = new Set(
    Object.values(materialClassNumberMetadata)
      .filter((metadata) => metadata !== null)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map((metadata) => metadata!.name),
  );

  const materialClassRows = Object.entries(materialClassNumberMetadata).map(
    ([materialClassNumberString, metadata]) => {
      const materialClassNumber = Number(materialClassNumberString);
      return metadata === null ? (
        <RedWaveMaterialClassTableNumberRow
          key={materialClassNumber}
          profileRevisionId={profileRevision.id}
          materialClassNumber={materialClassNumber}
          existingMaterialClassNames={existingMaterialClassNames}
        />
      ) : (
        <RedWaveMaterialClassTableMetadataRow
          key={materialClassNumber}
          profileRevisionId={profileRevisionId}
          materialClassNumber={materialClassNumber}
          materialClassMetadata={metadata}
        />
      );
    },
  );

  return (
    <RedWaveMaterialClassTableTemplate isLoading={false}>
      {materialClassRows}
    </RedWaveMaterialClassTableTemplate>
  );
}

export function RedWaveMaterialClassTableTemplate({
  children,
  isLoading,
}: {
  children?: ReactNode | undefined;
  isLoading: boolean;
}) {
  const theme = useMantineTheme();

  const isEmpty = children === null;

  return (
    <Table verticalSpacing='xs' highlightOnHover>
      {(isLoading || isEmpty) && (
        <caption
          style={{
            captionSide: 'bottom',
            textAlign: 'center',
            padding: theme.spacing.md,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <TableEmptyBasicContent>No Material Classes</TableEmptyBasicContent>
          )}
        </caption>
      )}

      <thead>
        <tr>
          <th>Number</th>
          <th>Name</th>
          <th>Eject/Pass</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </Table>
  );
}

function RedWaveMaterialClassTableNumberRow({
  materialClassNumber,
  profileRevisionId,
  existingMaterialClassNames,
}: {
  materialClassNumber: number;
  profileRevisionId: RedWaveSortProgramProfileRevisionId;
  existingMaterialClassNames: Set<string>;
}) {
  const addMaterialClassMetadataMutation =
    useAddRedWaveMaterialClassMetadata(profileRevisionId);

  const isBackground = materialClassNumber === 0;

  const [name, setName] = useState<string>(isBackground ? 'Background' : '');
  const [ejected, setEjected] = useState<boolean | null>(null);

  const nameIsUnique = !existingMaterialClassNames.has(name);
  const nameHasValidLength = name.length > 0;
  let metadata: RedWaveMaterialClassMetadataCreationDTO | null = null;
  if (nameIsUnique && nameHasValidLength && ejected !== null) {
    metadata = {
      id: uuidv4(),
      materialClassNumber: materialClassNumber,
      ejected: ejected,
      name: name,
    };
  }

  return (
    <tr>
      <td>{materialClassNumber}</td>
      <td>
        <TextInput
          placeholder='enter unique material class name'
          size='sm'
          disabled={isBackground}
          value={name}
          error={!nameIsUnique}
          onChange={(e) => setName(e.currentTarget.value)}
        />
      </td>
      <td>
        <Checkbox
          checked={ejected ?? false}
          color={ejected === null ? undefined : ejected ? 'green' : 'red'}
          indeterminate={ejected === null}
          onChange={(e) => setEjected(e.currentTarget.checked)}
        />
      </td>
      <td>
        <ActionIcon
          color='green'
          disabled={metadata === null}
          onClick={() => {
            if (metadata === null) {
              throw new Error(
                'The RedWaveMaterialClassMetadataCreationDTO object must be defined.',
              );
            }
            return addMaterialClassMetadataMutation.mutate(metadata, {
              onError(error, errantMetadata) {
                showNotification({
                  title: 'Error Saving Material Class Metadata',
                  message: `An error occurred saving Material Class Metadata for class number ${errantMetadata.materialClassNumber}.`,
                  color: 'red',
                  icon: <IconX />,
                });
              },
              onSuccess(data, createdMetadata) {
                showNotification({
                  title: 'Material Class Metadata Saved',
                  message: `Material Class Metadata was successfuly saved for class number ${createdMetadata.materialClassNumber}.`,
                  color: 'green',
                  icon: <IconCheck />,
                });
              },
            });
          }}
        >
          <AddIcon />
        </ActionIcon>
      </td>
    </tr>
  );
}

function RedWaveMaterialClassTableMetadataRow({
  materialClassNumber,
  materialClassMetadata,
  profileRevisionId,
}: {
  materialClassNumber: RedWaveMaterialClassNumber;
  materialClassMetadata: RedWaveMaterialClassMetadataDTO;
  profileRevisionId: string;
}) {
  const { classes } = useRedWaveMaterialClassTableStyles();

  const deleteMaterialClassMetadataMutation =
    useDeleteRedwaveMaterialClassMetadata(profileRevisionId);

  return (
    <Box component='tr' className={classes.row}>
      <td>{materialClassNumber}</td>
      <td>{materialClassMetadata.name}</td>
      <td>
        <Badge
          color={materialClassMetadata.ejected ? 'teal' : 'gray'}
          variant='filled'
        >
          {materialClassMetadata.ejected ? 'Eject' : 'Pass'}
        </Badge>
      </td>
      <td>
        <ActionIcon
          color='red'
          className={classes.iconDelete}
          onClick={() => {
            deleteMaterialClassMetadataMutation.mutate(materialClassMetadata, {
              onError(data, dto) {
                showNotification({
                  title: 'Error Deleting Material Class Metadata',
                  message: `An error occurred deleting ${dto.name}.`,
                  color: 'red',
                  icon: <IconX />,
                });
              },
              onSuccess(data, dto) {
                showNotification({
                  title: 'Material Class Metadata Deleted',
                  message: `${dto.name} was successfully deleted.`,
                  color: 'green',
                  icon: <IconCheck />,
                });
              },
            });
          }}
        >
          <DeleteIcon />
        </ActionIcon>
      </td>
    </Box>
  );
}
