import { createContext, ReactNode, useContext } from 'react';
import { FacilityDTO } from '../rest-client';

const FacilityContext = createContext<FacilityDTO | undefined>(undefined);

export function useFacilityContext(): FacilityDTO {
  const facility = useContext(FacilityContext);
  if (facility === undefined) {
    throw new Error('component must be within a facility context');
  }
  return facility;
}

export function FacilityProvider(props: {
  facility: FacilityDTO;
  children: ReactNode;
}) {
  const { children, facility } = props;
  return (
    <FacilityContext.Provider value={facility}>
      {children}
    </FacilityContext.Provider>
  );
}
