import dayjs from 'dayjs';
import { usePatchCommoditySpotPrice } from '../api/commoditySpotPrice';
import { useFacilityContext } from '../Facility/FacilityContext';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import {
  CommodityId,
  CommoditySpotPriceDTO,
  CommoditySpotPricePatchDTO,
} from '../rest-client';
import {
  CommoditySpotPriceFormFields,
  CommoditySpotPriceFormValues,
  useCommoditySpotPriceForm,
} from './CommoditySpotPriceForm';

export type EditCommoditySpotPriceDrawerFormProps = {
  commodityId: CommodityId;
  commoditySpotPrice: CommoditySpotPriceDTO;
  onSuccess?: (commoditySpotPrice: CommoditySpotPricePatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditCommoditySpotPriceDrawerForm(
  props: EditCommoditySpotPriceDrawerFormProps,
) {
  const {
    commodityId,
    commoditySpotPrice,
    onSuccess,
    onClose,
    ...drawerFormDrawerProps
  } = props;

  const facility = useFacilityContext();

  const patchMutation = usePatchCommoditySpotPrice(
    commodityId,
    commoditySpotPrice.id,
  );
  const form = useCommoditySpotPriceForm();

  useSetFormInitialValuesFromQuery(
    form,
    commoditySpotPrice && {
      pricedAt: dayjs.tz(commoditySpotPrice.pricedAt, facility.timeZoneId),
      usdPerUnitOfWeight: commoditySpotPrice.usdPerUnitOfWeight,
      weightUnit: commoditySpotPrice.weightUnit,
    },
  );

  const formValuesToDto = ({
    pricedAt,
    usdPerUnitOfWeight,
  }: CommoditySpotPriceFormValues) => {
    const commoditySpotPricePatch: CommoditySpotPricePatchDTO = {
      ...(form.isDirty('pricedAt') && {
        pricedAt: pricedAt.format('YYYY-MM-DD'),
      }),
      ...(form.isDirty('usdPerUnitOfWeight') && { usdPerUnitOfWeight }),
    };
    return commoditySpotPricePatch;
  };

  return (
    <DrawerForm
      entityName='Commodity Spot Price'
      form={form}
      drawerFormVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <CommoditySpotPriceFormFields form={form} />
    </DrawerForm>
  );
}
