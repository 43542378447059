import { Badge, BadgeProps } from '@mantine/core';
import { MaterialContainerTypeDTO } from '../rest-client';

export type ContainerTypeBadgeProps = BadgeProps & {
  containerType: MaterialContainerTypeDTO;
};

export function ContainerTypeBadge(props: ContainerTypeBadgeProps) {
  const { containerType, size = 'md', ...badgeProps } = props;

  return (
    <Badge size={size} {...badgeProps}>
      {containerType.name}
    </Badge>
  );
}
