import {
  Alert,
  Container,
  Flex,
  Group,
  Input,
  SegmentedControl,
  Select,
  Stack,
  Table,
  Tabs,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { IconInfoCircle, IconInfoTriangle } from '@tabler/icons-react';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { CommodityName } from '../Commodity/CommodityName';
import { CompositionComparisonChart } from '../CompositionComparisonChart';
import {
  InferredPropertyTextWrapper,
  InferredPropertyWrapper,
} from '../InferredPropertyWrapper';
import { MaterialClassSetName } from '../MaterialClassSet/MaterialClassSetName';
import NetWeight, { NetWeightZero } from '../Weights/NetWeight';
import { LabeledValue } from '../common';
import {
  CommodityInternalMaterialSourceAggregateCompositionsDTO,
  MaterialClassSetInternalMaterialSourceAggregateCompositionsDTO,
  WeightUnit,
} from '../rest-client';
import { composition } from '../util/mixture';
import { InternalMaterialSourceName } from './InternalMaterialSourceName';

export function InternalMaterialSourceCompositionByCommodityTabs(props: {
  commoditesMcsImsComps: CommodityInternalMaterialSourceAggregateCompositionsDTO[];
}) {
  const { commoditesMcsImsComps } = props;
  const [activeCommodityTab, setActiveCommodityTab] = useState<string | null>(
    commoditesMcsImsComps[0].commodity.id,
  );
  return (
    <Tabs
      value={activeCommodityTab}
      onTabChange={setActiveCommodityTab}
      variant='outline'
    >
      <Tabs.List>
        {commoditesMcsImsComps.map((c) => (
          <Tabs.Tab value={c.commodity.id} key={c.commodity.id}>
            <Text size='md'>{c.commodity.name}</Text>
          </Tabs.Tab>
        ))}
      </Tabs.List>

      {commoditesMcsImsComps.map((c) => (
        <Tabs.Panel value={c.commodity.id} key={c.commodity.id}>
          <InternalMaterialSourceCompositionsByCommoditySection
            commodityMcsImsComps={c}
          />
        </Tabs.Panel>
      ))}
    </Tabs>
  );
}

export function InternalMaterialSourceCompositionsByCommoditySection(props: {
  commodityMcsImsComps: CommodityInternalMaterialSourceAggregateCompositionsDTO;
}) {
  const { commodityMcsImsComps } = props;
  const {
    commodity,
    materialClassSets,
    internallySourcedMaterialCount,
    aggregateSourcedWeight,
    internallySourcedMaterialUnknownWeightCount,
  } = commodityMcsImsComps;

  const [selectedMaterialClassSetId, setSelectedMaterialClassSetId] = useState<
    string | null
  >(
    materialClassSets.length > 0
      ? materialClassSets[0].materialClassSet.id
      : null,
  );

  const selectedMaterialClassSet =
    selectedMaterialClassSetId !== null
      ? materialClassSets[
          materialClassSets
            .map((mcs) => mcs.materialClassSet.id)
            .indexOf(selectedMaterialClassSetId)
        ]
      : null;

  return (
    <Stack mt={10}>
      <Group>
        <LabeledValue label='Commodity'>
          <CommodityName commodity={commodity} />
        </LabeledValue>
        <LabeledValue label='Aggregated Sourced Weight'>
          <div
            style={{
              display: 'inline-flex',
              gap: '0.5ch',
            }}
          >
            <InferredPropertyWrapper>
              <NetWeight
                weight={aggregateSourcedWeight}
                sourceIconMode='icon-tooltip'
              />{' '}
            </InferredPropertyWrapper>
            <Text c='dimmed'>
              {internallySourcedMaterialUnknownWeightCount > 0
                ? ` + ${internallySourcedMaterialUnknownWeightCount} unknown sourced materials`
                : ''}
            </Text>
          </div>
        </LabeledValue>
        <LabeledValue label='Sourced Material Count'>
          {internallySourcedMaterialCount}
        </LabeledValue>
        {match(materialClassSets.length)
          .with(0, () => (
            <LabeledValue label='Sample Analysis Material Class Set'>
              <Text c='dimmed'>No Sample Analyses Recorded</Text>
            </LabeledValue>
          ))
          .with(1, () => (
            <LabeledValue label='Sample Material Class Set'>
              <MaterialClassSetName
                materialClassSet={materialClassSets[0].materialClassSet}
              />
            </LabeledValue>
          ))
          .otherwise(() => (
            <Select
              value={selectedMaterialClassSetId}
              onChange={setSelectedMaterialClassSetId}
              data={materialClassSets.map((mcs) => mcs.materialClassSet.id)}
            />
          ))}
      </Group>
      {selectedMaterialClassSet !== null ? (
        <InternalMaterialSourceCompositionsByCommodityChart
          commodityImsComps={selectedMaterialClassSet}
        />
      ) : (
        <Alert
          color='blue'
          title='No Inferred Upstream Material Source Compositions'
        >
          None of the upstream material source compositions for this source
          commodity could be inferred. In order for an upstream material source
          composition to be inferred, that upstream material source must have at
          least one upstream-sourced material with an inferred composition.
        </Alert>
      )}
    </Stack>
  );
}

function InternalMaterialSourceCompositionsByCommodityChart(props: {
  commodityImsComps: MaterialClassSetInternalMaterialSourceAggregateCompositionsDTO;
}) {
  const { commodityImsComps } = props;
  const { internalMaterialSources, materialClassSet } = commodityImsComps;

  type CompTypeValue = 'weightedAggregate' | 'unweightedAggregate';
  const [compTypeValue, setCompTypeValue] = useState<CompTypeValue>(
    'unweightedAggregate',
  );

  const materialClassNameLookup = new Map(
    materialClassSet.materialClasses.map((mc) => [mc.id, mc.name]),
  );

  const unweightedCompsFiltered = internalMaterialSources.filter(
    (ims) => ims.unweightedComposition !== null,
  );

  const unweightedComps = unweightedCompsFiltered.map((imsComp) => {
    if (!imsComp.unweightedComposition) throw new Error();
    return {
      name: imsComp.internalMaterialSource.name,
      composition: composition(
        Object.entries(imsComp.unweightedComposition).map(
          ([mcId, p]) =>
            [materialClassNameLookup.get(mcId) ?? mcId, p] as const,
        ),
      ),
    } as const;
  });
  const weightedCompsFiltered = internalMaterialSources.filter(
    (ims) => ims.weightedComposition !== null,
  );
  const unweightedCompInfo = `Computes the upstream material source composition as an unweighted
  average of its upstream-sourced material constituents' inferred compositions, ignoring the 
  inferred weight of each constituent.`;

  const weightedComps =
    weightedCompsFiltered.length > 0
      ? weightedCompsFiltered.map((imsComp) => {
          if (!imsComp.weightedComposition) throw new Error();
          return {
            name: imsComp.internalMaterialSource.name,
            composition: composition(
              Object.entries(imsComp.weightedComposition).map(
                ([mcId, p]) =>
                  [materialClassNameLookup.get(mcId) ?? mcId, p] as const,
              ),
            ),
          } as const;
        })
      : null;

  const weightedCompInfo = `Computes the upstream material source compositions as a 
  weighted average of its upstream-sourced material constituents' compositions, weighted by
  their inferred masses.`;
  const noWeightedComp = weightedCompsFiltered.length === 0;
  const noWeightedCompInfo = `No weighted average composition comparison for this internal 
    material source could be computed because there are no upstream material sources with internally
    sourced materials with both inferred compositions and weights.`;

  return (
    <Stack>
      <Group position='apart'>
        <Title order={3}>Upstream Material Source Compositions</Title>
        <Flex align='flex-end'>
          <Stack spacing={0}>
            <Input.Label>Composition Type</Input.Label>
            <SegmentedControl
              value={compTypeValue}
              onChange={(value: CompTypeValue) => setCompTypeValue(value)}
              disabled={noWeightedComp}
              data={[
                { value: 'unweightedAggregate', label: 'Unweighted Average' },
                { value: 'weightedAggregate', label: 'Weighted Average' },
              ]}
            />
          </Stack>
          <Container pl={5}>
            <Tooltip
              label={match(compTypeValue)
                .with('unweightedAggregate', () => unweightedCompInfo)
                .with('weightedAggregate', () => weightedCompInfo)
                .exhaustive()}
              multiline
              width={500}
            >
              <IconInfoCircle color='grey' size={30} />
            </Tooltip>
            {noWeightedComp && (
              <Tooltip label={noWeightedCompInfo} multiline width={500}>
                <IconInfoTriangle color='orange' size={30} />
              </Tooltip>
            )}
          </Container>
        </Flex>
      </Group>
      {match(compTypeValue)
        .with('weightedAggregate', () => {
          if (!weightedComps) throw new Error();
          return (
            <CompositionComparisonChart
              key='weighted'
              compositions={weightedComps}
              seriesLabelEnabled={false}
            />
          );
        })
        .with('unweightedAggregate', () => (
          <CompositionComparisonChart
            key='unweighted'
            compositions={unweightedComps}
            seriesLabelEnabled={false}
          />
        ))
        .exhaustive()}
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th style={{ textAlign: 'right' }}>Total Weight</th>
            <th style={{ textAlign: 'right' }}>Sourced</th>
            <th style={{ textAlign: 'right' }}>Compositions</th>
            {materialClassSet.materialClasses.map((mc) => (
              <th key={mc.id} style={{ textAlign: 'right' }}>
                {mc.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {internalMaterialSources.map((ims) => (
            <tr key={ims.internalMaterialSource.id}>
              <td>
                <InternalMaterialSourceName
                  internalMaterialSource={ims.internalMaterialSource}
                />
              </td>
              <td style={{ textAlign: 'right' }}>
                {match(ims.aggregateSourcedWeight)
                  .with(null, () =>
                    ims.internallySourcedMaterialUnknownMassCount > 0 ? (
                      <Text>
                        {'> '}
                        <InferredPropertyWrapper>
                          <NetWeightZero unit={WeightUnit.POUND} />
                        </InferredPropertyWrapper>
                      </Text>
                    ) : (
                      <Text c='dimmed'>?</Text>
                    ),
                  )
                  .otherwise((netWeight) => (
                    <Text>
                      {ims.internallySourcedMaterialUnknownMassCount > 0
                        ? '> '
                        : undefined}
                      <InferredPropertyTextWrapper>
                        <NetWeight
                          weight={netWeight}
                          sourceIconMode='icon-tooltip'
                        />
                      </InferredPropertyTextWrapper>
                    </Text>
                  ))}
              </td>
              <td style={{ textAlign: 'right' }}>
                {ims.internallySourcedMaterialCount}
              </td>
              <td style={{ textAlign: 'right' }}>
                {match(compTypeValue)
                  .with(
                    'weightedAggregate',
                    () => ims.internallySourcedMaterialWeightedCompositionCount,
                  )
                  .with(
                    'unweightedAggregate',
                    () => ims.internallySourcedMaterialCompositionCount,
                  )
                  .exhaustive()}
              </td>
              {materialClassSet.materialClasses.map((mc) => (
                <td key={mc.id} style={{ textAlign: 'right' }}>
                  {match(compTypeValue)
                    .with('weightedAggregate', () =>
                      ims.weightedComposition !== null ? (
                        <Text>
                          {(100 * ims.weightedComposition[mc.id]).toFixed(1)}%
                        </Text>
                      ) : (
                        <Text c='dimmed' span>
                          ?
                        </Text>
                      ),
                    )
                    .with('unweightedAggregate', () =>
                      ims.unweightedComposition !== null ? (
                        <Text>
                          {(100 * ims.unweightedComposition[mc.id]).toFixed(1)}%
                        </Text>
                      ) : (
                        <Text c='dimmed' span>
                          ?
                        </Text>
                      ),
                    )
                    .exhaustive()}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Stack>
  );
}
