import { useCallback } from 'react';
import { match } from 'ts-pattern';
import {
  DateOffsetId,
  DateRange,
  PredefinedDateOffsetId,
  predefinedDateOffsets,
} from '../../../Temporal/dateRangeAndOffset.ts';
import Temporal from '../../../Temporal/temporal.ts';
import { usePersistentRouteParams } from '../../../util/useRouteParams.ts';
import { useFacilityContext } from '../../FacilityContext.tsx';

export function dateRangeToUrlParams(dateRange: Partial<DateRange>) {
  return {
    ...('id' in dateRange ? { dateRange: dateRange.id } : {}),
    ...('startDate' in dateRange
      ? { startDate: dateRange.startDate?.toString() }
      : {}),
    ...('endDate' in dateRange
      ? { endDate: dateRange.endDate?.toString() }
      : {}),
  };
}

function getDateRangeForId(
  offsetId: DateOffsetId | undefined,
  timezone: Temporal.TimeZoneLike,
): DateRange | null {
  if (!offsetId) {
    return null;
  }
  const offset = predefinedDateOffsets.get(offsetId as PredefinedDateOffsetId);
  if (!offset) {
    return null;
  }

  const nowPlainDate = Temporal.Now.plainDateISO(timezone);
  return {
    id: offset.id,
    name: offset.name,
    startDate: offset.calculateStartDate(nowPlainDate),
    endDate: nowPlainDate,
  };
}

export function useInsightsSelectedDateRange(defaultDateRange: DateRange): {
  dateRange: DateRange;
  setDateRange: (args: Partial<DateRange> | null) => void;
} {
  const tz = useFacilityContext().timeZoneId;

  const [routeParams, setRouteParams] = usePersistentRouteParams([
    'FacilityInsightsDashboard',
    'FacilityInsightsMetricDetail',
    'FacilityInsightsMetricFacetDetail',
  ]);

  const {
    startDate: rawStartDate,
    endDate: rawEndDate,
    dateRange: rawDateRange,
  } = routeParams;

  const routeStartDate =
    rawStartDate === undefined ? null : tryParsePlainDate(rawStartDate);
  if (routeStartDate === undefined) {
    // The route start date couldn't be parsed - wipe it from the URL
    setRouteParams({ startDate: undefined });
  }
  const routeEndDate =
    rawEndDate === undefined ? null : tryParsePlainDate(rawEndDate);
  if (routeEndDate === undefined) {
    // The route end date couldn't be parsed - wipe it from the URL
    setRouteParams({ endDate: undefined });
  }

  const routeDateOffsetId: DateOffsetId | null | undefined = match(rawDateRange)
    .with(undefined, () => null)
    .with('custom', () => 'custom' as const)
    .otherwise((id) =>
      predefinedDateOffsets.has(id as PredefinedDateOffsetId)
        ? (id as PredefinedDateOffsetId)
        : undefined,
    );
  if (routeDateOffsetId === undefined) {
    // The route date range was not a valid id - wipe it from the URL
    setRouteParams({ dateRange: undefined });
  }

  const startDate = routeStartDate ?? defaultDateRange.startDate;
  const endDate = routeEndDate ?? defaultDateRange.endDate;
  const id = routeDateOffsetId ?? defaultDateRange.id;
  const name = match(id)
    .with('custom', () => 'Custom')
    .otherwise(
      (predefinedId) =>
        predefinedDateOffsets.get(predefinedId)?.name ?? 'Unknown',
    );
  const defaultedDateRange = {
    startDate,
    endDate,
    id,
    name,
  };

  const setDateRange = useCallback(
    (dateRange: Partial<DateRange> | null) => {
      if (dateRange) {
        if ('id' in dateRange) {
          // the set event specified an id, so we recalculate the date range if it is a predefined range
          const dateRangeFromId = getDateRangeForId(dateRange.id, tz);
          if (dateRangeFromId) {
            setRouteParams(dateRangeToUrlParams(dateRangeFromId));
            return;
          }
        }
        setRouteParams(dateRangeToUrlParams(dateRange));
      } else {
        setRouteParams({
          dateRange: undefined,
          startDate: undefined,
          endDate: undefined,
        });
      }
    },
    [setRouteParams, tz],
  );

  return {
    dateRange: defaultedDateRange,
    setDateRange,
  };
}

function tryParsePlainDate(rawPlainDate: string) {
  try {
    return Temporal.PlainDate.from(rawPlainDate);
  } catch {
    return undefined;
  }
}
