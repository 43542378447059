import {
  ActionIcon,
  ActionIconProps,
  Group,
  Modal,
  ModalProps,
  Stack,
  Table,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconMaximize } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { CommodityIdName } from '../Commodity/CommodityName';
import { GlobalRecoveryGoalPathDTO } from '../rest-client';
import { RecoveryGoalStepName } from './RecoveryGoalPathName';

export interface RecoveryGoalPathsTextModalProps {
  pathsOrderedByDescendingLength: GlobalRecoveryGoalPathDTO[];
  topSection?: ReactNode;
  referToPathsAs?: string;
  actionIconProps?: Omit<ActionIconProps, 'onClick'>;
  modalProps?: Omit<ModalProps, 'opened' | 'onClose'>;
}

export function RecoveryGoalPathsTextModal(
  props: RecoveryGoalPathsTextModalProps,
) {
  const {
    pathsOrderedByDescendingLength,
    topSection,
    referToPathsAs: pathLabel = 'sequences',
    actionIconProps = {
      variant: 'outline',
      color: 'teal',
      size: 'sm',
    },
    modalProps = {
      title: 'Recovery Sequences',
      size: 'auto',
    },
  } = props;

  const [opened, { open, close }] = useDisclosure(false);

  const stepCount = pathsOrderedByDescendingLength[0].steps.length;

  return (
    <>
      <Group spacing='xs'>
        <Text>
          {pathsOrderedByDescendingLength.length === 0
            ? 'None'
            : pathsOrderedByDescendingLength.length.toString() +
              ' ' +
              pathLabel}
        </Text>
        <ActionIcon {...actionIconProps} onClick={open}>
          <IconMaximize />
        </ActionIcon>
      </Group>
      <Modal opened={opened} onClose={close} {...modalProps}>
        <Stack>
          {topSection}
          <Table withColumnBorders>
            <thead>
              <tr>
                <th>Source Commodity</th>
                {pathsOrderedByDescendingLength[0].steps.map((_, idx) => (
                  <th key={idx}>
                    <Text>Step {idx}</Text>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pathsOrderedByDescendingLength.map((path, pathIdx) => (
                <tr key={pathIdx}>
                  <td>
                    {path.sourceCommodityId === null ? (
                      <Text c='dimmed'>Unknown</Text>
                    ) : (
                      <CommodityIdName commodityId={path.sourceCommodityId} />
                    )}
                  </td>
                  {path.steps.map((step) => (
                    <td key={step.recoveryGoalId}>
                      <RecoveryGoalStepName step={step} />
                    </td>
                  ))}
                  {Array.from(
                    { length: stepCount - path.steps.length },
                    (_, idx) => (
                      <td key={idx} style={{ textAlign: 'center' }}>
                        <Text c='dimmed'>-</Text>
                      </td>
                    ),
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Stack>
      </Modal>
    </>
  );
}
