import { Button, Container, Group } from '@mantine/core';
import { useLinkProps } from '@swan-io/chicane';
import { IconLogout } from '@tabler/icons-react';
import { msalInstance } from '../Microsoft/msalConfig';
import SvgValisLogo from '../SVG/ValisLogo';
import { Router } from '../router';
import classes from './SchupanHeader.module.css';

export function SchupanHeader() {
  const dashboardLink = useLinkProps({ href: Router.Home() });
  const snapshotLink = useLinkProps({ href: Router.SchupanRimasSnapshots() });

  return (
    <header className={classes.header}>
      <Container maw='100%' className={classes.inner}>
        <Group spacing='xl'>
          <SvgValisLogo style={{ height: '2.5rem' }} />
          <Button
            size='md'
            variant={dashboardLink.active ? 'light' : 'subtle'}
            onClick={dashboardLink.onClick}
          >
            Dashboard
          </Button>
          <Button
            size='md'
            variant={snapshotLink.active ? 'light' : 'subtle'}
            onClick={snapshotLink.onClick}
          >
            Snapshots
          </Button>
        </Group>

        <Button
          color='blue'
          variant='outline'
          rightIcon={<IconLogout />}
          onClick={() =>
            void msalInstance.logoutPopup().catch((e) => {
              console.error(e);
            })
          }
        >
          Logout
        </Button>
      </Container>
    </header>
  );
}
