import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Flex,
  Input,
  InputWrapperProps,
  Loader,
  Paper,
  PaperProps,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { Ref, forwardRef, useState } from 'react';
import { match } from 'ts-pattern';
import { z } from 'zod';
import { useMaterialClasses } from '../api/materialClass';
import { MaterialClassDTO, MaterialClassId } from '../rest-client';

const materialClassSetFormSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  materialClassIds: z
    .string()
    .uuid()
    .array()
    .min(2, 'At least two material classes are required'),
});

export type MaterialClassSetFormValues = z.infer<
  typeof materialClassSetFormSchema
>;

export function useMaterialClassSetForm() {
  return useForm<MaterialClassSetFormValues>({
    initialValues: {
      name: '',
      materialClassIds: [],
    },
    validate: zodResolver(materialClassSetFormSchema),
  });
}

export function MaterialClassFormFields(props: {
  form: UseFormReturnType<MaterialClassSetFormValues>;
}) {
  const { form } = props;

  return (
    <>
      <TextInput
        label='Name'
        placeholder={'Name'}
        autoFocus
        withAsterisk
        {...form.getInputProps('name')}
      />
      <OrderedMaterialClassSetSelect
        value={form.values.materialClassIds}
        onChange={(materialClassIds) =>
          form.setFieldValue('materialClassIds', materialClassIds)
        }
        withAsterisk
      />
    </>
  );
}

export type OrderedMaterialClassSetSelectProps = Omit<
  InputWrapperProps,
  'children' | 'value' | 'onChange'
> & {
  value: MaterialClassId[];
  onChange: (materialClassIds: MaterialClassId[]) => void;
};

function OrderedMaterialClassSetSelect(
  props: OrderedMaterialClassSetSelectProps,
) {
  const {
    value,
    onChange,
    label = 'Material Classes',
    description = 'Drag and drop material classes from the right side to the left side. The order of the material classes will determine the order they are presented across the application.',
    ...inputWrapperProps
  } = props;

  const materialClassesQuery = useMaterialClasses();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [activeMaterialClass, setActiveMaterialClass] =
    useState<MaterialClassDTO | null>(null);

  if (materialClassesQuery.data) {
    const materialClasses = materialClassesQuery.data;
    const materialClassLookup = new Map(
      materialClasses.map((mc) => [mc.id, mc]),
    );

    const includedIdSet = new Set(value);
    const excludedIds = materialClasses
      .map((mc) => mc.id)
      .filter((mcId) => !includedIdSet.has(mcId));

    const handleDragStart = (event: DragStartEvent) => {
      const dragStartedOnClass = materialClassLookup.get(
        event.active.id as string,
      );
      setActiveMaterialClass(dragStartedOnClass ?? null);
    };

    const handleDragOver = (event: DragOverEvent) => {
      const { active, over } = event;

      if (!over) return;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const activeContainerId = active.data.current?.sortable.containerId as
        | 'included'
        | 'excluded';
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const overContainerId = (over.data.current?.sortable.containerId ||
        over.id) as 'included' | 'excluded';

      if (activeContainerId !== overContainerId) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const activeIdx = active.data.current?.sortable.index as
          | number
          | undefined;

        if (activeIdx === undefined) return;

        if (overContainerId === 'included') {
          onChange([
            ...value.slice(0, activeIdx),
            active.id as string,
            ...value.slice(activeIdx),
          ]);
        } else {
          onChange(value.filter((cId) => cId !== active.id));
        }
      }
    };

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
      setActiveMaterialClass(null);

      if (!over) return;

      if (active.id !== over.id) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const activeContainer = active.data.current?.sortable.containerId as
          | 'included'
          | 'excluded';
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const overContainer = (over.data.current?.sortable.containerId ||
          over.id) as 'included' | 'excluded';

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const activeIdx = active.data.current?.sortable.index as number;
        const overIdx = match(over.id)
          .with('included', () => value.length)
          .with('excluded', () => excludedIds.length)
          .otherwise(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            () => over.data.current?.sortable.index as number,
          );

        if (activeContainer == overContainer && overContainer === 'excluded') {
          return; // Don't allow reordering of the excluded classes
        }

        if (activeContainer == overContainer && overContainer === 'included') {
          // reorder within included
          onChange(arrayMove(value, activeIdx, overIdx));
          return;
        }
      }
    };

    return (
      <Input.Wrapper
        label={label}
        description={description}
        {...inputWrapperProps}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDragCancel={() => setActiveMaterialClass(null)}
        >
          <SimpleGrid cols={2} mt={5}>
            <Paper withBorder>
              <SortableMaterialClassItemContainer
                id='included'
                materialClassIds={value}
                materialClassLookup={materialClassLookup}
              />
            </Paper>
            <Paper withBorder>
              <SortableMaterialClassItemContainer
                id='excluded'
                materialClassIds={excludedIds}
                materialClassLookup={materialClassLookup}
              />
            </Paper>
          </SimpleGrid>

          <DragOverlay>
            {activeMaterialClass ? (
              <MaterialClassItem
                materialClass={activeMaterialClass}
                shadow='xl'
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </Input.Wrapper>
    );
  }

  if (materialClassesQuery.isError) {
    throw materialClassesQuery.error;
  }

  // TODO(2317): Skeletonize
  return <Loader variant='bars' />;
}

export function SortableMaterialClassItemContainer(props: {
  id: string;
  materialClassIds: MaterialClassId[];
  materialClassLookup: Map<MaterialClassId, MaterialClassDTO>;
}) {
  const { id, materialClassIds, materialClassLookup } = props;

  const { setNodeRef } = useDroppable({ id });
  return (
    <SortableContext id={id} items={materialClassIds}>
      <Stack ref={setNodeRef} spacing='xs' py='xs' w='100%'>
        {materialClassIds.map((materialClassId, idx) => {
          const materialClass = materialClassLookup.get(materialClassId);
          return (
            materialClass && (
              <Flex align='center' key={materialClassId}>
                {id === 'included' && <Text ml='sm'>{idx + 1}</Text>}
                <SortableMaterialClassItem materialClass={materialClass} />
              </Flex>
            )
          );
        })}
      </Stack>
    </SortableContext>
  );
}

export type SortableMaterialClassItem = {
  materialClass: MaterialClassDTO;
};

export function SortableMaterialClassItem(props: SortableMaterialClassItem) {
  const { materialClass } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: materialClass.id,
    });

  return (
    <MaterialClassItem
      materialClass={materialClass}
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
        userSelect: 'none',
      }}
      {...listeners}
      {...attributes}
    />
  );
}

export type MaterialClassItemProps = PaperProps & SortableMaterialClassItem;

export const MaterialClassItem = forwardRef(function MaterialClassItem(
  props: MaterialClassItemProps,
  ref: Ref<HTMLDivElement>,
) {
  const { materialClass, ...rest } = props;
  return (
    <Paper ref={ref} {...rest} withBorder p='xs' mx='xs'>
      <Text size='sm'>{materialClass.name}</Text>
    </Paper>
  );
});
