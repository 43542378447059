/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ScaleDTO } from '../models/ScaleDTO';
import type { ScaleReadingCreationDTO } from '../models/ScaleReadingCreationDTO';
import type { ScaleReadingDTO } from '../models/ScaleReadingDTO';
import type { ScaleReadingPatchDTO } from '../models/ScaleReadingPatchDTO';
import type { ScaleTypeDTO } from '../models/ScaleTypeDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ScaleService {
    /**
     * @returns ScaleTypeDTO Success
     * @throws ApiError
     */
    public static getAllScaleTypes(): CancelablePromise<Array<ScaleTypeDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scales/types',
        });
    }
    /**
     * @param scaleTypeGuid
     * @returns ScaleTypeDTO Success
     * @throws ApiError
     */
    public static getScaleTypeById(
        scaleTypeGuid: string,
    ): CancelablePromise<ScaleTypeDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scales/types/{scaleTypeGuid}',
            path: {
                'scaleTypeGuid': scaleTypeGuid,
            },
        });
    }
    /**
     * @param scaleGuid
     * @returns ScaleDTO Success
     * @throws ApiError
     */
    public static getScaleById(
        scaleGuid: string,
    ): CancelablePromise<ScaleDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scales/{scaleGuid}',
            path: {
                'scaleGuid': scaleGuid,
            },
        });
    }
    /**
     * @param facilityGuid
     * @param scaleTypeGuid
     * @param containerTypeId
     * @param truckLoadCapable
     * @param sampleCapable
     * @returns ScaleDTO Success
     * @throws ApiError
     */
    public static getAllScales(
        facilityGuid?: string,
        scaleTypeGuid?: string,
        containerTypeId?: string,
        truckLoadCapable?: boolean,
        sampleCapable?: boolean,
    ): CancelablePromise<Array<ScaleDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scales',
            query: {
                'facilityGuid': facilityGuid,
                'scaleTypeGuid': scaleTypeGuid,
                'containerTypeId': containerTypeId,
                'truckLoadCapable': truckLoadCapable,
                'sampleCapable': sampleCapable,
            },
        });
    }
    /**
     * @param scaleGuid
     * @returns ScaleReadingDTO Success
     * @throws ApiError
     */
    public static getAllScaleReadings(
        scaleGuid: string,
    ): CancelablePromise<Array<ScaleReadingDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scales/{scaleGuid}/readings',
            path: {
                'scaleGuid': scaleGuid,
            },
        });
    }
    /**
     * @param scaleGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createScaleReading(
        scaleGuid: string,
        requestBody: ScaleReadingCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scales/{scaleGuid}/readings',
            path: {
                'scaleGuid': scaleGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param scaleGuid
     * @param scaleReadingGuid
     * @returns ScaleReadingDTO Success
     * @throws ApiError
     */
    public static getScaleReadingById(
        scaleGuid: string,
        scaleReadingGuid: string,
    ): CancelablePromise<ScaleReadingDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scales/{scaleGuid}/readings/{scaleReadingGuid}',
            path: {
                'scaleGuid': scaleGuid,
                'scaleReadingGuid': scaleReadingGuid,
            },
        });
    }
    /**
     * @param scaleGuid
     * @param scaleReadingGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchScaleReading(
        scaleGuid: string,
        scaleReadingGuid: string,
        requestBody: ScaleReadingPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/scales/{scaleGuid}/readings/{scaleReadingGuid}',
            path: {
                'scaleGuid': scaleGuid,
                'scaleReadingGuid': scaleReadingGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param scaleGuid
     * @param scaleReadingGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteScaleReading(
        scaleGuid: string,
        scaleReadingGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/scales/{scaleGuid}/readings/{scaleReadingGuid}',
            path: {
                'scaleGuid': scaleGuid,
                'scaleReadingGuid': scaleReadingGuid,
            },
        });
    }
}
