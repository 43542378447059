import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ContainerSampleCreationDTO,
  ContainerSamplingService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchContainerSamples(
  ctx: QueryFunctionContexts['containerSample']['list'],
) {
  const [{ containerId }] = ctx.queryKey;
  return await ContainerSamplingService.getContainerSamples(containerId);
}

export function useContainerSamples(args: { containerId?: string }) {
  return useQuery({
    queryKey: queryKeys.containerSample.list(args),
    queryFn: fetchContainerSamples,
  });
}

async function fetchContainerSample(
  ctx: QueryFunctionContexts['containerSample']['detail'],
) {
  const [{ containerSampleId }] = ctx.queryKey;
  return await ContainerSamplingService.getContainerSampleById(
    containerSampleId,
  );
}

export function useContainerSample(id: string) {
  return useQuery({
    queryKey: queryKeys.containerSample.detail(id),
    queryFn: fetchContainerSample,
  });
}

async function addContainerSample(dto: ContainerSampleCreationDTO) {
  await ContainerSamplingService.createContainerSample(dto);
}

export function useAddContainerSample() {
  const invalidator = useQueryKeyInvalidator();

  return useMutation({
    mutationFn: addContainerSample,
    onSettled(_d, _e, { containerId }) {
      invalidator.invalidateKeys(
        queryKeys.containerSample.list({ containerId }),
        queryKeys.containerSample.list({}),
        queryKeys.materialClassSetsContainerSampleAnalyses.list(),
      );
    },
  });
}

async function deleteContainerSample(id: string) {
  await ContainerSamplingService.deleteContainerSampleById(id);
}

export function useDeleteContainerSample() {
  const invalidator = useQueryKeyInvalidator();

  return useMutation({
    mutationFn: deleteContainerSample,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.containerSample.list({}),
        queryKeys.materialClassSetsContainerSampleAnalyses.list(),
      );
    },
  });
}
