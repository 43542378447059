import {
  Input,
  InputWrapperProps,
  Switch,
  SwitchProps,
  Tooltip,
} from '@mantine/core';
import { IconSquare, IconSquareHalf } from '@tabler/icons-react';
import React from 'react';
import { match } from 'ts-pattern';
import SwitchAsLabeledInputClasses from './SwitchAsLabeledInput.module.css';

export type ContainerFullnessSwitchProps = Omit<
  SwitchProps,
  'onLabel' | 'offLabel' | 'mx'
> & {
  variant: 'source' | 'destination';
  orientation: 'vertical' | 'horizontal';
  inputWrapperProps: Omit<InputWrapperProps, 'children'>;
};
export default React.forwardRef<HTMLInputElement, ContainerFullnessSwitchProps>(
  function ContainerFullnessSwitch(props, ref) {
    const { variant, orientation, inputWrapperProps, ...switchProps } = props;
    const variantMatch = {
      variant: variant,
      checked: switchProps.checked ?? false,
      orientation: orientation,
    };
    return (
      <Tooltip
        label={match(variantMatch)
          .with(
            { variant: 'destination', checked: true },
            () => 'Completely Filled',
          )
          .with(
            { variant: 'destination', checked: false },
            () => 'Partially Filled',
          )
          .with(
            { variant: 'source', checked: true },
            () => 'Completely Emptied',
          )
          .with(
            { variant: 'source', checked: false },
            () => 'Partially Emptied',
          )
          .exhaustive()}
        events={{ hover: true, focus: true, touch: false }}
        position='top-start'
      >
        <div>
          <Input.Wrapper {...inputWrapperProps}>
            <Switch
              ref={ref}
              style={{
                transform: match(variantMatch)
                  .with(
                    { variant: 'destination', orientation: 'vertical' },
                    () => 'rotate(270deg)',
                  )
                  .with(
                    { variant: 'destination', orientation: 'horizontal' },
                    () => undefined,
                  )
                  .with(
                    { variant: 'source', orientation: 'vertical' },
                    () => 'rotate(90deg)',
                  )
                  .with(
                    { variant: 'source', orientation: 'horizontal' },
                    () => undefined,
                  )
                  .exhaustive(),
              }}
              onLabel={
                <IconSquare
                  size={match(orientation)
                    .with('vertical', () => '1rem')
                    .with('horizontal', () => '1.5rem')
                    .exhaustive()}
                  fill={match(variant)
                    .with('destination', () => 'white')
                    .with('source', () => undefined)
                    .exhaustive()}
                  fillOpacity={match(variant)
                    .with('destination', () => 100)
                    .with('source', () => 0)
                    .exhaustive()}
                />
              }
              offLabel={
                <IconSquareHalf
                  size={match(orientation)
                    .with('vertical', () => '1rem')
                    .with('horizontal', () => '1.5rem')
                    .exhaustive()}
                  style={{
                    transform: match(variantMatch)
                      .with(
                        { variant: 'destination', orientation: 'vertical' },
                        () => 'rotate(180deg)',
                      )
                      .with(
                        { variant: 'destination', orientation: 'horizontal' },
                        () => 'rotate(90deg)',
                      )
                      .with(
                        { variant: 'source', orientation: 'vertical' },
                        () => undefined,
                      )
                      .with(
                        { variant: 'source', orientation: 'horizontal' },
                        () => 'rotate(90deg)',
                      )
                      .exhaustive(),
                  }}
                />
              }
              className={SwitchAsLabeledInputClasses.switch}
              {...switchProps}
            />
          </Input.Wrapper>
        </div>
      </Tooltip>
    );
  },
);
