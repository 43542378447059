/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FacilityDTO } from '../models/FacilityDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FacilityService {
    /**
     * @returns FacilityDTO Success
     * @throws ApiError
     */
    public static allFacilities(): CancelablePromise<Array<FacilityDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/facilities',
        });
    }
    /**
     * @param facilityGuid
     * @returns FacilityDTO Success
     * @throws ApiError
     */
    public static getFacilityById(
        facilityGuid: string,
    ): CancelablePromise<FacilityDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/facilities/{facilityGuid}',
            path: {
                'facilityGuid': facilityGuid,
            },
        });
    }
}
