import { WeightUnit } from '../rest-client';
import QuantizedWeightTicksInput, {
  QuantizedWeightTicksInputProps,
} from './QuantizedWeightTicksInput';

export type ScaleDisplayDivisionsInputProps = Omit<
  QuantizedWeightTicksInputProps,
  'tickSize' | 'unit'
> & {
  scale: {
    unit: WeightUnit;
    displayDivision: number;
  };
};

export default function ScaleDisplayDivisionsInput(
  props: ScaleDisplayDivisionsInputProps,
) {
  const { scale, ...rest } = props;
  return (
    <QuantizedWeightTicksInput
      unit={scale.unit}
      tickSize={scale.displayDivision}
      {...rest}
    />
  );
}
