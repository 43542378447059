import { ReactNode, createContext, useContext } from 'react';

export interface RecoveryStrategySimulationCompositionContext {
  selectedMaterialClassSetComposition: Record<string, number>;
  setSelectedMaterialClassSetComposition: (
    composition: Record<string, number>,
  ) => void;
  selectedSourceComposition: string;
  setSelectedSourceComposition: (composition: string) => void;
}

const RecoveryStrategySimulationCompositionContext = createContext<
  RecoveryStrategySimulationCompositionContext | undefined
>(undefined);

export function useRecoveryStrategySimulationCompositionCtx(): RecoveryStrategySimulationCompositionContext {
  const ctx = useContext(RecoveryStrategySimulationCompositionContext);
  if (ctx === undefined) {
    throw new Error(
      'component must be within a recovery strategy simulation composition context',
    );
  }

  return ctx;
}

export function RecoveryStrategySimulationCompositionCtxProvider(
  props: {
    children: ReactNode;
  } & RecoveryStrategySimulationCompositionContext,
) {
  const { children, ...value } = props;
  return (
    <RecoveryStrategySimulationCompositionContext.Provider value={value}>
      {children}
    </RecoveryStrategySimulationCompositionContext.Provider>
  );
}
