import { Box, Tooltip, rem } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { ReactNode } from 'react';
import cssClasses from './InferredPropertyWrapper.module.css';

interface InferredPropertyWrapperProps {
  children: ReactNode;
  hoverContent?: ReactNode;
  showIcon?: boolean;
  color?: string;
}

export function InferredPropertyWrapper({
  children,
  hoverContent,
  showIcon,
  color,
}: InferredPropertyWrapperProps) {
  const label = hoverContent ?? 'Inferred Value';

  let content = (
    <InferredPropertyTextWrapper>{children}</InferredPropertyTextWrapper>
  );
  if (showIcon) {
    content = (
      <Box className={cssClasses.withIconWrapper}>
        {content}
        <IconInfoCircle color={color} size={rem(20)} />
      </Box>
    );
  }
  return (
    <Tooltip label={label} openDelay={100} position='right' color={color}>
      {content}
    </Tooltip>
  );
}

export function InferredPropertyTextWrapper({
  children,
}: {
  children: ReactNode;
}) {
  return <Box className={cssClasses.textWrapper}>{children}</Box>;
}
