import { Button, Center, Group, Stack, Switch, Text } from '@mantine/core';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ColGroupDef,
  ColumnRowGroupChangedEvent,
  FilterChangedEvent,
  GridState,
  IAggFuncParams,
  IRowNode,
  ModuleRegistry,
  NumberFilterModel,
  RowDataUpdatedEvent,
  SetFilterModel,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { Temporal } from '@js-temporal/polyfill';
import { showNotification } from '@mantine/notifications';
import {
  IconCircleCheck,
  IconCircleX,
  IconFilter,
  IconFilterX,
  IconLink,
  IconList,
  IconListDetails,
  IconProgress,
  IconX,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useCallback, useMemo, useRef } from 'react';
import { P, match } from 'ts-pattern';
import { ControlTagsAnalysisDTO } from '../rest-client';
import { useRouteParams } from '../util/useRouteParams';
import './RimasReportAgGrid.css';
import {
  MassFractionValueObject,
  PoundsPerHourValueObject,
  RowData,
  RowOutputPoundsData,
  Status,
  createMassFractionValueObject,
  createPoundsPerHourValueObject,
  dtoToRow,
  massFractionValueCalculator,
  poundsPerLaborHourCalculator,
  useRimasTableStore,
} from './RimasTableState';

import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

const LICENSEKEY =
  'Using_this_{AG_Grid}_Enterprise_key_{AG-065673}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Valis_Insights,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{valis-production-dashboard}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{valis-production-dashboard}_need_to_be_licensed___{valis-production-dashboard}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{21_August_2025}____[v3]_[01]_MTc1NTczMDgwMDAwMA==8022f27af1109363614e152c84b785e0';

LicenseManager.setLicenseKey(LICENSEKEY);

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  SetFilterModule,
  MenuModule,
  ColumnsToolPanelModule,
]);

export function RimasBalerAgGridTable(props: {
  report: ControlTagsAnalysisDTO;
}) {
  const { report } = props;

  // we need ag-grid component refs for table to total row column alignment
  const topTableGrid = useRef<AgGridReact>(null);
  const bottomSummaryRowGrid = useRef<AgGridReact>(null);

  const [{ initialGridState: queryParamInitialGridState }, setRouteParams] =
    useRouteParams(['Home']);

  const initialGridState = useMemo(() => {
    if (!queryParamInitialGridState) {
      return undefined;
    }
    try {
      return JSON.parse(
        decodeURIComponent(queryParamInitialGridState),
      ) as GridState;
    } catch {
      // TODO: Pop a notification that we couldn't parse the saved grid state
      setRouteParams({ initialGridState: undefined }, { replace: true });
      return undefined;
    }
  }, [queryParamInitialGridState, setRouteParams]);

  // clear initial state from URL after the grid is ready
  const onGridReady = useCallback(() => {
    if (queryParamInitialGridState !== undefined) {
      setRouteParams({ initialGridState: undefined }, { replace: true });
    }
  }, [queryParamInitialGridState, setRouteParams]);

  const aggregateFilteredRows = useCallback((rows: RowData[]) => {
    const newTotalRow: RowData = {
      controlNumber: '',
      account: '',
      customerOrder: '',
      startDate: '',
      status: 'incomplete',
      receivedBales: 0,
      receivedPounds: 0,
      laborHours: 0,
      outputs: {
        ubcBales: 0,
        ubcPounds: 0,
        alFinesPounds: 0,
        ferrousPounds: 0,
        mixedClipPounds: 0,
        heaviesPounds: 0,
        trashPounds: 0,
        totalByproductPounds: 0,
        reconciledPounds: 0,
      },
    };

    if (rows === undefined) {
      return newTotalRow;
    }

    const controlNumbers = new Set(rows.map((r) => r.controlNumber));
    controlNumbers.delete('');
    newTotalRow.controlNumber = '' + controlNumbers.size;

    const accounts = new Set(rows.map((r) => r.account));
    accounts.delete('');
    newTotalRow.account = '' + accounts.size;

    const customerOrders = new Set(rows.map((r) => r.customerOrder));
    customerOrders.delete('');
    newTotalRow.customerOrder = '' + customerOrders.size;

    const statuses = [...new Set(rows.map((r) => r.status))];
    newTotalRow.status = match(statuses)
      .with(
        P.when((statuses) => statuses.includes('incomplete')),
        () => 'incomplete',
      )
      .with(P.set('unprocessed'), () => 'unprocessed')
      .with(
        P.when((statuses) => statuses.includes('unprocessed')),
        () => 'incomplete',
      )
      .otherwise(() => 'complete') as Status;

    const earliestStateDate = dayjs
      .min(rows.map((r) => dayjs(r.startDate, 'YYYY-MM-DD')))
      ?.format('YYYY-MM-DD');
    newTotalRow.startDate = earliestStateDate ?? '';

    rows.forEach((row) => {
      newTotalRow.receivedBales! += row.receivedBales ?? 0;
      newTotalRow.receivedPounds += row.receivedPounds ?? 0;
      newTotalRow.laborHours! += row.laborHours ?? 0;
      match(row)
        .with({ status: 'complete' }, { status: 'incomplete' }, (row) => {
          newTotalRow.outputs!.ubcBales += row.outputs!.ubcBales ?? 0;
          newTotalRow.outputs!.ubcPounds += row.outputs!.ubcPounds ?? 0;
          newTotalRow.outputs!.alFinesPounds += row.outputs!.alFinesPounds ?? 0;
          newTotalRow.outputs!.ferrousPounds += row.outputs!.ferrousPounds ?? 0;
          newTotalRow.outputs!.mixedClipPounds +=
            row.outputs!.mixedClipPounds ?? 0;
          newTotalRow.outputs!.heaviesPounds += row.outputs!.heaviesPounds ?? 0;
          newTotalRow.outputs!.trashPounds += row.outputs!.trashPounds ?? 0;
          newTotalRow.outputs!.reconciledPounds +=
            row.outputs!.reconciledPounds ?? 0;
        })
        .with({ status: 'unprocessed' }, { status: undefined }, () => null)
        .exhaustive();
    });
    newTotalRow.outputs!.totalByproductPounds =
      newTotalRow.outputs!.alFinesPounds +
      newTotalRow.outputs!.ferrousPounds +
      newTotalRow.outputs!.mixedClipPounds +
      newTotalRow.outputs!.heaviesPounds +
      newTotalRow.outputs!.trashPounds;

    return newTotalRow;
  }, []);

  // controls whether we include the reconciled weight in the mass fraction denominators
  // const [filterBlanks, setFilterBlanks] = useState(true);

  /**
   * filteredRows: the resulting filtered rows from user interaction with the ag-grid table
   * tableRows: the result of the csv parsing; does not change with filtering; passed to the ag-grid table
   * totalRow: calculated on csv parsing and on every ag-grid table filter change event
   */

  const tableRows = useMemo(
    () => report.balerControlAnalyses.map(dtoToRow),
    [report],
  );
  const {
    filteredRows,
    setFilteredRows,
    includeReconciled,
    setIncludeReconciled,
  } = useRimasTableStore();
  const totalRow = aggregateFilteredRows(filteredRows ?? []);

  // calculate the min and max date to pass to the date column filters
  const [minDate, maxDate] = useMemo(() => {
    const dates = tableRows?.map((r) => dayjs(r.startDate, 'YYYY-MM-DD'));
    if (dates === undefined) {
      return [undefined, undefined];
    }
    return [
      dayjs.min(dates)?.format('YYYY-MM-DD'),
      dayjs.max(dates)?.format('YYYY-MM-DD'),
    ];
  }, [tableRows]);

  const onFilterOrRowDataChanged = useCallback(
    (
      e:
        | FilterChangedEvent<RowData>
        | RowDataUpdatedEvent<RowData>
        | AgGridReact<RowData>,
    ) => {
      const newFilteredRows: RowData[] = [];
      e.api.forEachNodeAfterFilter((rowNode) => {
        if (rowNode.data) {
          newFilteredRows.push(rowNode.data);
        }
      });
      newFilteredRows.sort((a, b) =>
        Temporal.PlainDate.compare(
          Temporal.PlainDate.from(a.startDate),
          Temporal.PlainDate.from(b.startDate),
        ),
      );
      setFilteredRows(newFilteredRows);
    },
    [setFilteredRows],
  );

  const onColumnRowGroupChanged = useCallback(
    (e: ColumnRowGroupChangedEvent<RowData>) => {
      const columnGroups = e.api
        .getRowGroupColumns()
        .map((col) => col.getColId());
      bottomSummaryRowGrid.current?.api.setRowGroupColumns(columnGroups);
    },
    [],
  );

  function reconciledHeader() {
    return (
      <Group>
        <Text>Yield Loss</Text>
        <Switch
          checked={includeReconciled}
          onChange={(e) => {
            setIncludeReconciled(e.currentTarget.checked);
            onFilterOrRowDataChanged(topTableGrid.current!);
          }}
        />
      </Group>
    );
  }

  const massFractionComparator = (
    valueA: MassFractionValueObject,
    valueB: MassFractionValueObject,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _nodeA: IRowNode<RowData>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _nodeB: IRowNode<RowData>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _isDescending: boolean,
  ) => {
    return valueA.toPercent() - valueB.toPercent();
  };

  const poundsPerHourComparator = (
    valueA: PoundsPerHourValueObject,
    valueB: PoundsPerHourValueObject,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _nodeA: IRowNode<RowData>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _nodeB: IRowNode<RowData>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _isDescending: boolean,
  ) => {
    return valueA.toNumber() - valueB.toNumber();
  };

  /**
   * TODO: figure out robust grouping that...
   * - conserves column pinning
   * - allows for source header name
   * - does not resize columns
   * - does not mess up the aggregation row
   */

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'account',
      headerName: 'Supplier',
      cellDataType: 'text',
      type: '',
      filter: 'agSetColumnFilter',
      enableRowGroup: true,
      aggFunc: 'first',
    },
    {
      field: 'customerOrder',
      headerName: 'Location',
      cellDataType: 'text',
      type: '',
      filter: 'agSetColumnFilter',
      enableRowGroup: true,
      aggFunc: 'count',
    },
    {
      field: 'controlNumber',
      headerName: 'Control',
      cellDataType: 'text',
      type: '',
      filter: 'agSetColumnFilter',
      aggFunc: 'count',
    },
    {
      headerName: 'Status',
      openByDefault: false,
      children: [
        {
          field: 'startDate',
          headerName: 'Start Date',
          cellDataType: 'date',
          columnGroupShow: null!,
          // TODO(2759): fix date equals filter
          filter: 'agDateColumnFilter',
          filterParams: {
            maxNumConditions: 1,
            minValidDate: minDate,
            maxValidDate: maxDate,
          },
          valueGetter: startDateValueGetter,
          valueFormatter: dateValueFormatter,
          aggFunc: 'min',
        },
        {
          field: 'status',
          headerName: 'Status',
          cellDataType: 'text',
          type: '',
          filter: 'agSetColumnFilter',
          columnGroupShow: 'open',
          hide: true,
          cellRenderer: statusValueFormatter,
        },
        {
          colId: 'processed',
          headerName: 'Processed',
          columnGroupShow: 'open',
          hide: true,
          valueGetter: (row) => processedValueGetter(row, includeReconciled),
          valueFormatter: processedValueFormatter,
        },
      ],
    },
    {
      headerName: 'Received',
      children: [
        {
          field: 'receivedBales',
          headerName: 'Bales',
          hide: true,
          valueFormatter: baleValueFormatter,
          aggFunc: 'sum',
        },
        {
          field: 'receivedPounds',
          headerName: 'Lbs',
          valueFormatter: weightValueFormatter,
          aggFunc: 'sum',
        },
      ],
    },
    {
      headerName: 'Labor',
      children: [
        {
          field: 'laborHours',
          headerName: 'Hours',
          valueFormatter: hoursValueFormatter,
          aggFunc: 'sum',
        },
      ],
    },
    {
      headerName: 'UBC',
      children: [
        {
          colId: 'ubcBalesPerLaborHour',
          headerName: 'Bales/Hour',
          hide: true,
          valueGetter: ubcBalesPerLaborHourValueGetter,
          valueFormatter: baleRateValueFormatter,
        },
        {
          field: 'outputs.ubcBales',
          headerName: 'Bales',
          hide: true,
          valueFormatter: baleValueFormatter,
          aggFunc: 'sum',
        },
        {
          colId: 'ubcPoundsPerLaborHour',
          headerName: 'Lbs/Labor Hr',
          valueGetter: ubcPoundsPerLaborHourValueGetter,
          valueFormatter: poundsPerHourValueFormatter,
          aggFunc: ubcPoundsPerLaborHourAggFunc,
          comparator: poundsPerHourComparator,
        },
        {
          field: 'outputs.ubcPounds',
          headerName: 'Lbs',
          valueFormatter: weightValueFormatter,
          aggFunc: 'sum',
        },
        {
          colId: 'ubcMassFraction',
          headerName: 'WT%',
          comparator: massFractionComparator,
          valueGetter: (params: ValueGetterParams<RowData>) =>
            massFractionValueGetter(params, 'ubcPounds', includeReconciled),
          valueFormatter: massFractionValueFormatter,
          filterValueGetter: (params: ValueGetterParams<RowData>) =>
            massFractionFilterValueGetter(
              params,
              'ubcPounds',
              includeReconciled,
            ),
          aggFunc: massFractionAggFunc,
        },
      ],
    },
    {
      headerName: 'Byproducts',
      openByDefault: false,
      children: [
        {
          headerName: 'Total',
          columnGroupShow: 'closed',
          children: [
            {
              field: 'outputs.totalByproductPounds',
              headerName: 'Lbs',
              columnGroupShow: 'closed',
              valueFormatter: weightValueFormatter,
              aggFunc: 'sum',
            },
            {
              colId: 'totalByproductMassFraction',
              headerName: 'WT%',
              comparator: massFractionComparator,
              columnGroupShow: 'closed',
              valueGetter: (params: ValueGetterParams<RowData>) =>
                massFractionValueGetter(
                  params,
                  'totalByproductPounds',
                  includeReconciled,
                ),
              valueFormatter: massFractionValueFormatter,
              filterValueGetter: (params: ValueGetterParams<RowData>) =>
                massFractionFilterValueGetter(
                  params,
                  'totalByproductPounds',
                  includeReconciled,
                ),
              aggFunc: massFractionAggFunc,
            },
          ],
        },
        {
          headerName: 'Al Fines',
          columnGroupShow: 'open',
          children: [
            {
              field: 'outputs.alFinesPounds',
              headerName: 'Lbs',
              columnGroupShow: 'open',
              valueFormatter: weightValueFormatter,
              aggFunc: 'sum',
            },
            {
              colId: 'alFinesMassFraction',
              headerName: 'WT%',
              comparator: massFractionComparator,
              columnGroupShow: 'open',
              valueGetter: (params: ValueGetterParams<RowData>) =>
                massFractionValueGetter(
                  params,
                  'alFinesPounds',
                  includeReconciled,
                ),
              valueFormatter: massFractionValueFormatter,
              filterValueGetter: (params: ValueGetterParams<RowData>) =>
                massFractionFilterValueGetter(
                  params,
                  'alFinesPounds',
                  includeReconciled,
                ),
              aggFunc: massFractionAggFunc,
            },
          ],
        },
        {
          headerName: 'Ferrous',
          columnGroupShow: 'open',
          children: [
            {
              field: 'outputs.ferrousPounds',
              headerName: 'Lbs',
              columnGroupShow: 'open',
              valueFormatter: weightValueFormatter,
              aggFunc: 'sum',
            },
            {
              colId: 'ferrousMassFraction',
              headerName: 'WT%',
              comparator: massFractionComparator,
              columnGroupShow: 'open',
              valueGetter: (params: ValueGetterParams<RowData>) =>
                massFractionValueGetter(
                  params,
                  'ferrousPounds',
                  includeReconciled,
                ),
              valueFormatter: massFractionValueFormatter,
              filterValueGetter: (params: ValueGetterParams<RowData>) =>
                massFractionFilterValueGetter(
                  params,
                  'ferrousPounds',
                  includeReconciled,
                ),
              aggFunc: massFractionAggFunc,
            },
          ],
        },
        {
          headerName: 'Mixed Clip',
          columnGroupShow: 'open',
          children: [
            {
              field: 'outputs.mixedClipPounds',
              headerName: 'Lbs',
              columnGroupShow: 'open',
              valueFormatter: weightValueFormatter,
              aggFunc: 'sum',
            },
            {
              colId: 'mixedClipMassFraction',
              headerName: 'WT%',
              comparator: massFractionComparator,
              columnGroupShow: 'open',
              valueGetter: (params: ValueGetterParams<RowData>) =>
                massFractionValueGetter(
                  params,
                  'mixedClipPounds',
                  includeReconciled,
                ),
              valueFormatter: massFractionValueFormatter,
              filterValueGetter: (params: ValueGetterParams<RowData>) =>
                massFractionFilterValueGetter(
                  params,
                  'mixedClipPounds',
                  includeReconciled,
                ),
              aggFunc: massFractionAggFunc,
            },
          ],
        },
        {
          headerName: 'Heavies',
          columnGroupShow: 'open',
          hide: true,
          children: [
            {
              field: 'outputs.heaviesPounds',
              headerName: 'Lbs',
              columnGroupShow: 'open',
              hide: true,
              valueFormatter: weightValueFormatter,
              aggFunc: 'sum',
            },
            {
              colId: 'heaviesMassFraction',
              headerName: 'WT%',
              comparator: massFractionComparator,
              columnGroupShow: 'open',
              hide: true,
              valueGetter: (params: ValueGetterParams<RowData>) =>
                massFractionValueGetter(
                  params,
                  'heaviesPounds',
                  includeReconciled,
                ),
              valueFormatter: massFractionValueFormatter,
              filterValueGetter: (params: ValueGetterParams<RowData>) =>
                massFractionFilterValueGetter(
                  params,
                  'heaviesPounds',
                  includeReconciled,
                ),
              aggFunc: massFractionAggFunc,
            },
          ],
        },
        {
          headerName: 'Trash',
          columnGroupShow: 'open',
          children: [
            {
              field: 'outputs.trashPounds',
              headerName: 'Lbs',
              columnGroupShow: 'open',
              valueFormatter: weightValueFormatter,
              aggFunc: 'sum',
            },
            {
              colId: 'trashMassFraction',
              headerName: 'WT%',
              comparator: massFractionComparator,
              columnGroupShow: 'open',
              valueGetter: (params: ValueGetterParams<RowData>) =>
                massFractionValueGetter(
                  params,
                  'trashPounds',
                  includeReconciled,
                ),
              valueFormatter: massFractionValueFormatter,
              filterValueGetter: (params: ValueGetterParams<RowData>) =>
                massFractionFilterValueGetter(
                  params,
                  'trashPounds',
                  includeReconciled,
                ),
              aggFunc: massFractionAggFunc,
            },
          ],
        },
      ],
    },
    {
      headerGroupComponent: reconciledHeader,
      headerTooltip:
        'Include reconciled weight in denominator of percentage by weight (WT%) calculations',
      children: [
        {
          field: 'outputs.reconciledPounds',
          headerName: 'Lbs',
          valueFormatter: weightValueFormatter,
          aggFunc: 'sum',
        },
        {
          colId: 'reconciledMassFraction',
          headerName: 'WT%',
          comparator: massFractionComparator,
          valueGetter: (params: ValueGetterParams<RowData>) =>
            massFractionValueGetter(params, 'reconciledPounds', true),
          valueFormatter: massFractionValueFormatter,
          filterValueGetter: (params: ValueGetterParams<RowData>) =>
            massFractionFilterValueGetter(params, 'reconciledPounds', true),
          aggFunc: massFractionAggFunc,
        },
      ],
    },
  ];

  // TODO(2892): fix column resizing on byproducts column group expansion/collapse
  // TODO: add cell styling to direct user attention to most important data
  // TODO: flag hours/rate when blank values are included
  // TODO(2763): serialize URL params for all the table group, filter query params
  // TODO(2759): group by month, quarter shortcuts
  // TODO: move table-wide functions out of column options into menu bar

  const defaultColDef: ColDef | ColGroupDef = {
    suppressHeaderFilterButton: true,
    cellDataType: 'number',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    comparator: (valueA, valueB, _nodeA, _nodeB, isDescending) => {
      if (valueA == valueB) return 0;
      return valueA > valueB ? 1 : -1;
    },
    filter: 'agNumberColumnFilter',
    filterParams: {
      buttons: ['reset', 'apply'],
      closeOnApply: true,
    },
    type: 'rightAligned',
    cellRendererParams: {
      suppressCount: true,
    },
  };

  // TODO: refactor this logic to update filters rather than just setting them; if the user has filters they will be lost
  const filterOutRowsWithMissingCriticalData = useCallback(() => {
    // controlNumnber
    const filterBlankControlNumberModel: SetFilterModel = {
      values: tableRows.map((row) => row.controlNumber).filter((v) => v),
    };
    void topTableGrid.current!.api.setColumnFilterModel(
      'controlNumber',
      filterBlankControlNumberModel,
    );
    // account
    const filterBlankAccountModel: SetFilterModel = {
      filterType: 'set',
      values: tableRows.map((row) => row.account).filter((v) => v),
    };
    void topTableGrid.current!.api.setColumnFilterModel(
      'account',
      filterBlankAccountModel,
    );
    // customerOrder
    const filterBlankCustomerOrderModel: SetFilterModel = {
      filterType: 'set',
      values: tableRows.map((row) => row.customerOrder).filter((v) => v),
    };
    void topTableGrid.current!.api.setColumnFilterModel(
      'customerOrder',
      filterBlankCustomerOrderModel,
    );
    // laborHours
    const filterMissingValuesModel: NumberFilterModel = {
      filterType: 'number',
      type: 'notEqual',
      filter: 0,
    };
    void topTableGrid.current!.api.setColumnFilterModel(
      'laborHours',
      filterMissingValuesModel,
    );
    // we must manually apply filter changes to the table
    topTableGrid.current!.api.onFilterChanged();
  }, [tableRows]);

  const resetFilters = useCallback(() => {
    topTableGrid.current!.api.setFilterModel(null);
  }, []);

  const groupByLocation = useCallback(() => {
    topTableGrid.current!.api.setRowGroupColumns(['customerOrder']);
    topTableGrid.current!.api.forEachNode((node) => {
      if (node.level === 1) {
        topTableGrid.current!.api.setRowNodeExpanded(node, true);
      }
    });
    topTableGrid.current!.api.onGroupExpandedOrCollapsed();
    // TODO(2892): the column widths get updated and should be reset to fit the column header content width
  }, []);

  const resetGroupings = useCallback(() => {
    topTableGrid.current!.api.removeRowGroupColumns([
      'account',
      'customerOrder',
    ]);
    topTableGrid.current!.api.onGroupExpandedOrCollapsed();
  }, []);

  return (
    <Stack
      sx={{
        flex: '1 1 auto',
      }}
    >
      <Group align='left'>
        <Button
          onClick={filterOutRowsWithMissingCriticalData}
          variant='outline'
          leftIcon={<IconFilter />}
        >
          Filter Missing Data
        </Button>
        <Button
          onClick={resetFilters}
          variant='outline'
          leftIcon={<IconFilterX />}
          color='red'
        >
          Reset Filters
        </Button>
        <Button
          onClick={groupByLocation}
          variant='outline'
          leftIcon={<IconListDetails />}
        >
          Group by Location
        </Button>
        <Button
          onClick={resetGroupings}
          variant='outline'
          leftIcon={<IconList />}
          color='red'
        >
          Reset Groupings
        </Button>

        <Button
          ml='auto'
          leftIcon={<IconLink />}
          onClick={() => {
            if (!topTableGrid.current) return; // TODO: Some kind of warning?

            const gridState = topTableGrid.current.api.getState();
            const serializedGridState = encodeURIComponent(
              JSON.stringify(gridState),
            );
            const url = new URL(window.location.href);
            url.searchParams.set('initialGridState', serializedGridState);
            navigator.clipboard
              .writeText(url.toString())
              .then(() => {
                showNotification({
                  title: 'Link Copied to Clipboard',
                  message:
                    'A link to this report has been copied to your clipboard',
                  color: 'teal',
                  icon: <IconLink />,
                });
              })
              .catch(() => {
                showNotification({
                  title: 'Failed to Copy Link to Clipboard',
                  message: 'An error occurred copying a link to this dashboard',
                  color: 'red',
                  icon: <IconX />,
                });
              });
          }}
        >
          Copy Link
        </Button>
      </Group>
      {/**
       * ag-grid's pinnedBottomRowData does not work with column grouping.
       * Aligning two grids means columns are kept aligned; we exploit this to represent a total bottom row.
       * https://www.ag-grid.com/react-data-grid/aligned-grids/#example:-aligned-grid-as-footer
       */}
      <div
        className={'table-container ag-theme-quartz'}
        style={{
          flex: '1 0 450px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div style={{ flex: '1 1 auto' }}>
          <AgGridReact
            ref={topTableGrid}
            initialState={initialGridState}
            onGridReady={onGridReady}
            alignedGrids={[bottomSummaryRowGrid]}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={tableRows}
            onFilterChanged={onFilterOrRowDataChanged}
            onRowDataUpdated={onFilterOrRowDataChanged}
            autoSizeStrategy={{ type: 'fitGridWidth' }}
            suppressColumnVirtualisation
            suppressHorizontalScroll
            alwaysShowVerticalScroll
            tooltipShowDelay={0}
            groupDisplayType='multipleColumns'
            suppressAggFuncInHeader
            onColumnRowGroupChanged={onColumnRowGroupChanged}
          />
        </div>
        <div style={{ flex: 'none', height: 45 }}>
          <AgGridReact
            ref={bottomSummaryRowGrid}
            alignedGrids={[topTableGrid]}
            rowData={totalRow ? [totalRow] : undefined}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            headerHeight={0}
            alwaysShowVerticalScroll
            rowStyle={{ fontWeight: 'bold' }}
            groupDisplayType='multipleColumns'
          />
        </div>
      </div>
    </Stack>
  );
}

// #region date
/**
 *  By default, the date filter assumes you are using Date objects.
 *  https://www.ag-grid.com/react-data-grid/filter-date/#filter-comparator
 */
const startDateValueGetter = (row: { data: RowData }) =>
  Date.parse(row.data.startDate);

const dateValueFormatter = (params: ValueFormatterParams<RowData, Date>) =>
  dayjs(params.value).format('YYYY-MM-DD');
//#endregion

//#region pounds

const ubcPoundsPerLaborHourValueGetter = (
  params: ValueGetterParams<RowData>,
) => {
  if (!(params.node && params.node.group)) {
    return poundsPerLaborHourCalculator(
      'ubcPounds',
      params.data!.outputs,
      params.data!.laborHours,
      undefined,
    );
  }
};

const ubcPoundsPerLaborHourAggFunc = (params: {
  values: PoundsPerHourValueObject[];
}) => {
  let totalPounds = 0;
  let totalHours = 0;
  let missingBlankValues = false;
  params.values.forEach((value: PoundsPerHourValueObject) => {
    if (value) {
      if (value.pounds !== null && value.hours !== null) {
        totalPounds += value.pounds;
        totalHours += value.hours;
      } else {
        missingBlankValues = true;
      }
    }
  });
  return createPoundsPerHourValueObject(
    totalPounds,
    totalHours,
    missingBlankValues,
  );
};

const poundsPerHourValueFormatter = (
  params: ValueFormatterParams<PoundsPerHourValueObject>,
) => {
  if (!params.value) {
    return '';
  }
  return params.value as string;
};
//#endregion

//#region massFraction

const massFractionFilterValueGetter = (
  params: ValueGetterParams<RowData>,
  prop: keyof RowOutputPoundsData,
  includeReconciled: boolean,
) => {
  if (params.data!.outputs) {
    return massFractionValueCalculator(
      prop,
      params.data!.outputs,
      includeReconciled,
    ).toPercent();
  }
};

const massFractionValueGetter = (
  params: ValueGetterParams<RowData>,
  prop: keyof RowOutputPoundsData,
  includeReconciled: boolean,
) => {
  if (!(params.node && params.node.group)) {
    // if complete or incomplete
    if (params.data!.outputs) {
      return massFractionValueCalculator(
        prop,
        params.data!.outputs,
        includeReconciled,
      );
    }
    return createMassFractionValueObject(undefined, undefined);
  }
};

const massFractionAggFunc = (params: IAggFuncParams) => {
  let totalNumerator = 0;
  let totalDenominator = 0;
  params.values.forEach((value: MassFractionValueObject) => {
    if (value && value.numerator !== null && value.denominator !== null) {
      totalNumerator += value.numerator;
      totalDenominator += value.denominator;
    }
  });
  return createMassFractionValueObject(totalNumerator, totalDenominator);
};

const massFractionValueFormatter = (
  params: ValueFormatterParams<MassFractionValueObject>,
) => {
  if (!params.value) {
    return '';
  }
  return params.value as string;
};

//#endregion

const ubcBalesPerLaborHourValueGetter = (row: { data: RowData }) => {
  return match(row.data)
    .with({ status: 'complete' }, { status: 'incomplete' }, (data) =>
      data.laborHours !== undefined && data.laborHours > 0
        ? row.data.outputs!.ubcPounds / data.laborHours
        : null,
    )
    .with({ status: 'unprocessed' }, () => null)
    .otherwise(() => null);
};

const processedValueGetter = (
  row: { data: RowData },
  includeReconciled: boolean,
) => {
  return match(row.data)
    .with(
      { status: 'complete' },
      { status: 'incomplete' },
      ({ outputs, receivedPounds }) => {
        let processedPounds =
          outputs!.ubcPounds + outputs!.totalByproductPounds;
        if (includeReconciled) {
          processedPounds += outputs!.reconciledPounds;
        }
        return processedPounds / receivedPounds;
      },
    )
    .with({ status: 'unprocessed' }, () => 0)
    .otherwise(() => null);
};

const processedValueFormatter = (row: { value: number | null }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 0,
  });
  return match(row.value)
    .with(P.number, (value) => formatter.format(value))
    .with(null, () => '')
    .otherwise(() => '');
};

const hoursValueFormatter = (row: { value: number | undefined | null }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
  return match(row.value)
    .with(P.number, (value) => formatter.format(value))
    .with(undefined, null, () => '')
    .exhaustive();
};

const baleRateValueFormatter = (row: { value: number | undefined | null }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
  return match(row.value)
    .with(P.number, (value) => formatter.format(value))
    .with(undefined, null, () => '')
    .exhaustive();
};

const baleValueFormatter = (row: { value: number | undefined | null }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
  });
  return match(row.value)
    .with(P.number, (value) => formatter.format(value))
    .with(undefined, null, () => '')
    .exhaustive();
};

const weightValueFormatter = (row: { value: number | undefined | null }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
  });
  return match(row.value)
    .with(P.number, (value) => formatter.format(value))
    .with(undefined, null, () => '')
    .exhaustive();
};

const statusValueFormatter = (row: { value: Status | undefined }) => (
  <Center h='100%'>
    {match(row.value)
      .with('complete', () => <IconCircleCheck color='green' />)
      .with('incomplete', () => <IconProgress color='yellow' />)
      .with('unprocessed', () => <IconCircleX color='grey' />)
      .with(undefined, () => '')
      .otherwise(() => '')}
  </Center>
);
