import { useState } from 'react';
import { AppPage } from '../../App/AppPage';
import { InsightsIcon } from '../../Icons';
import { plainDateToStartOfDayInstant } from '../../Temporal/dateRangeAndOffset.ts';
import Temporal from '../../Temporal/temporal';
import {
  useGetCommodityPurities,
  useProducedCommodityMasses,
} from '../../api/commodity';
import { useProductionAggregateTemporalMetrics } from '../../api/production';
import { useRecoveryGoalClassificationPerformance } from '../../api/recoveryGoal';
import { Router } from '../../router.ts';
import { getWeightFromNetWeight } from '../../util/weightFromNetWeight.ts';
import { useFacilityContext } from '../FacilityContext';
import { useInsightsSelectedDateRange } from './DateRange/InsightsDateRange.tsx';
import { MetricHeader } from './Header/MetricHeader.tsx';
import { MetricValueCard } from './MetricCard';
import { MetricGraphCard } from './MetricGraphCard';
import { getSeriesData as getSeriesDataMassThroughput } from './Metrics/CommodityMassThroughputMetric.tsx';
import { getSeriesData as getSeriesDataPurity } from './Metrics/CommodityPurityMetric';
import { getSeriesData as getSeriesDataLoss } from './Metrics/MaterialLossMetric';
import { getSeriesData as getSeriesDataSystemUtilization } from './Metrics/SystemUtilizationMetric';
import dashboardPageCssClasses from './css/DashboardPage.module.css';

export function DashboardPage() {
  const { timeZoneId: tz, id: facilityId } = useFacilityContext();

  const [nowZonedDateTime] = useState(Temporal.Now.zonedDateTimeISO(tz));
  const thirtyDaysAgo = nowZonedDateTime.subtract({ days: 30 });

  const { dateRange, setDateRange } = useInsightsSelectedDateRange({
    startDate: thirtyDaysAgo.toPlainDate(),
    endDate: nowZonedDateTime.toPlainDate(),
    name: 'Last 30 Days',
    id: 'd30',
  });

  const { startDate, endDate } = dateRange;

  const [startInstant, endInstant] = [
    plainDateToStartOfDayInstant(startDate, tz),
    plainDateToStartOfDayInstant(endDate, tz),
  ];

  const PageTitle = (
    <>
      <InsightsIcon className={dashboardPageCssClasses.pageTitleIcon} />
      Facility Insights
    </>
  );

  // TODO should these hooks get their own `facilityId`?
  const purityQuery = useGetCommodityPurities({
    after: startInstant,
    before: endInstant,
    facilityId,
  });
  const lossQuery = useRecoveryGoalClassificationPerformance({
    after: startInstant,
    before: endInstant,
    facilityId,
  });
  const utilizationQuery = useProductionAggregateTemporalMetrics({
    facilityId,
    after: startInstant,
    before: endInstant,
  });
  const massThroughputQuery = useProducedCommodityMasses({
    facilityId,
    after: startInstant,
    before: endInstant,
  });

  const purityData = purityQuery.data
    ? getSeriesDataPurity(purityQuery.data)
    : null;
  const lossData = lossQuery.data ? getSeriesDataLoss(lossQuery.data) : null;
  const systemUtilizationData = utilizationQuery.data
    ? getSeriesDataSystemUtilization(utilizationQuery.data)
    : null;
  const massThrougputData = massThroughputQuery.data
    ? getSeriesDataMassThroughput(massThroughputQuery.data)
    : null;

  // TODO include explanatory subheadings e.g. when there is no activity
  let activityRate = 0;
  if (
    utilizationQuery.data?.metrics.totalActiveMinutes &&
    utilizationQuery.data.metrics.totalPlannedProductionMinutes
  ) {
    activityRate = Number.parseFloat(
      (
        (utilizationQuery.data.metrics.totalActiveMinutes /
          utilizationQuery.data.metrics.totalPlannedProductionMinutes) *
        100
      ).toFixed(1),
    );
  }

  const totalMass = massThroughputQuery.data?.processedWeight
    ? getWeightFromNetWeight(massThroughputQuery.data.processedWeight)
    : 0;

  const systemUtilizationUrl = Router.FacilityInsightsMetricDetail({
    metricName: 'system-utilization',
  });
  const massThroughputUrl = Router.FacilityInsightsMetricDetail({
    metricName: 'commodity-mass-throughput',
  });
  const materialLossUrl = Router.FacilityInsightsMetricDetail({
    metricName: 'material-loss',
  });
  const materialPurityUrl = Router.FacilityInsightsMetricDetail({
    metricName: 'material-purity',
  });

  return (
    <AppPage>
      <MetricHeader
        title={PageTitle}
        setDateRange={setDateRange}
        dateRange={dateRange}
      />
      <div className={dashboardPageCssClasses.cardRow}>
        <MetricValueCard
          href={systemUtilizationUrl}
          className={dashboardPageCssClasses.card}
          heading='System Activity Rate'
          metricVal={activityRate}
          metricValPrecision={1}
          metricUnit='%'
          query={utilizationQuery}
        />
        <MetricValueCard
          href={massThroughputUrl}
          className={dashboardPageCssClasses.card}
          heading='Facility Net Weight Processed'
          metricVal={totalMass}
          metricUnit='lbs.'
          query={massThroughputQuery}
        />
      </div>
      <div className={dashboardPageCssClasses.cardRow}>
        <MetricGraphCard
          href={systemUtilizationUrl}
          className={dashboardPageCssClasses.card}
          heading='System Utilization'
          seriesLines={systemUtilizationData}
          yAxisLabel='Utilization %'
          query={utilizationQuery}
        />
        <MetricGraphCard
          href={massThroughputUrl}
          className={dashboardPageCssClasses.card}
          heading='Commodity Mass Throughput'
          seriesLines={massThrougputData}
          yAxisLabel='Pounds'
          query={massThroughputQuery}
        />
      </div>
      <div className={dashboardPageCssClasses.cardRow}>
        <MetricGraphCard
          href={materialLossUrl}
          className={dashboardPageCssClasses.card}
          heading='Material Loss'
          seriesLines={lossData}
          yAxisLabel='Loss %'
          query={lossQuery}
        />
        <MetricGraphCard
          href={materialPurityUrl}
          className={dashboardPageCssClasses.card}
          heading='Material Purity'
          seriesLines={purityData}
          yAxisLabel='Purity %'
          query={purityQuery}
        />
      </div>
    </AppPage>
  );
}
