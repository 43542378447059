import { TextInput } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { CommoditySelect } from '../Commodity/CommoditySelect';
import { FacilitySelect } from '../Facility/FacilitySelect';

const InternalMaterialSourceFormSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  facilityId: z
    .string()
    .uuid()
    .nullable()
    .refine((val) => val !== null, { message: 'Facility is required' }),
  commodityId: z
    .string()
    .uuid()
    .nullable()
    .refine((val) => val !== null, { message: 'Commodity is required' }),
});

export type InternalMaterialSourceFormValues = z.infer<
  typeof InternalMaterialSourceFormSchema
>;

export function useInternalMaterialSourceForm() {
  return useForm<InternalMaterialSourceFormValues>({
    initialValues: {
      name: '',
      facilityId: null,
      commodityId: null,
    },
    validate: zodResolver(InternalMaterialSourceFormSchema),
  });
}

export function InternalMaterialSourceFormFields(props: {
  form: UseFormReturnType<InternalMaterialSourceFormValues>;
}) {
  const { form } = props;
  return (
    <>
      <TextInput
        label='Upstream Source Name'
        placeholder='Name'
        autoFocus
        withAsterisk
        {...form.getInputProps('name')}
      />
      <FacilitySelect
        label='Facility'
        placeholder='Select facility'
        description='The facility where the upstream source is located.'
        withAsterisk
        {...form.getInputProps('facilityId')}
      />
      <CommoditySelect
        label='Default Commodity'
        description='The commodity which will be default assigned to all materials sourced from this upstream source.'
        placeholder='Select commodity'
        withAsterisk
        {...form.getInputProps('commodityId')}
      />
    </>
  );
}
