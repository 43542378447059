import { Button, Group, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { P, match } from 'ts-pattern';
import { AppPage, TitleRoute } from '../App/AppPage';
import { DeleteIcon, EditIcon } from '../Icons';
import { useCommodity, useDeleteCommodity } from '../api/commodity';
import { LabeledValue } from '../common';
import { Router } from '../router';
import { CommodityInternalMaterialSourceCompositions } from './CommodityInternalMaterialSourceCompositions';
import { CommoditySpotPrices } from './CommoditySpotPrices';
import { EditCommodityDrawerForm } from './EditCommodityDrawerForm';

export function CommodityDetailPage({ commodityId }: { commodityId: string }) {
  const deleteMutation = useDeleteCommodity();

  const { data: commodity, isLoadingError, error } = useCommodity(commodityId);
  const [editDrawerOpened, { open: openEditDrawer, close: closeEditDrawer }] =
    useDisclosure(false);

  if (isLoadingError) {
    throw error;
  }
  const breadcrumbs: TitleRoute[] = [
    {
      routeName: Router.CommodityList(),
      title: 'Commodities',
    },
  ];

  return (
    <AppPage breadcrumbs={breadcrumbs} title={commodity?.name ?? null}>
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Commodity Details</Title>
            <Group>
              <>
                <Button onClick={openEditDrawer} leftIcon={<EditIcon />}>
                  Edit
                </Button>
                <EditCommodityDrawerForm
                  opened={editDrawerOpened}
                  onClose={closeEditDrawer}
                  commodityId={commodityId}
                />
              </>
              <Button
                leftIcon={<DeleteIcon />}
                color='red'
                disabled={commodity === undefined}
                loading={deleteMutation.isLoading}
                onClick={() => {
                  deleteMutation.mutate(commodityId, {
                    onError() {
                      showNotification({
                        title: 'Error Deleting Commodity',
                        message: `An error occured deleting the commodity.`,
                        color: 'red',
                        icon: <IconX />,
                      });
                    },
                    onSuccess() {
                      Router.push('CommodityList');
                      showNotification({
                        title: 'Commodity Deleted',
                        message: `Commodity was successfully deleted.`,
                        color: 'green',
                        icon: <IconCheck />,
                      });
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Group>
          </Group>
          <Group>
            <LabeledValue label='Commodity Name'>
              {commodity?.name ?? 'Loading...'}
            </LabeledValue>
            <LabeledValue label='Description'>
              {match(commodity?.description)
                .with(undefined, () => 'Loading...')
                .with(P.union(null, ''), () => <Text color='dimmed'>None</Text>)
                .otherwise((d) => d)}
            </LabeledValue>
          </Group>
          {/* TODO(2301): Link to commodity paths (input and output) */}
        </Stack>
      </AppPage.Section>
      <CommodityInternalMaterialSourceCompositions commodityId={commodityId} />
      <CommoditySpotPrices commodityId={commodityId} />
    </AppPage>
  );
}
