import Elk, {
  ElkExtendedEdge,
  ElkLayoutArguments,
  ElkNode,
  LayoutOptions,
} from 'elkjs/lib/elk-api';
import ElkWorkerUrl from 'elkjs/lib/elk-worker?worker&url';
import { forwardRef, useEffect } from 'react';
import ReactFlow, {
  Background,
  Edge as ReactFlowEdge,
  ReactFlowInstance,
  Node as ReactFlowNode,
  ReactFlowProps,
  useEdgesState,
  useNodesInitialized,
  useNodesState,
  useStoreApi,
} from 'reactflow';
import 'reactflow/dist/style.css';

const elk = new Elk({
  workerFactory() {
    return new Worker(ElkWorkerUrl, {
      type: 'module',
    });
  },
});

export const elkOpts: unique symbol = Symbol();

export interface ElkElementOptions {
  layoutOptions?: LayoutOptions;
}
export type ElkNodeOptions = ElkElementOptions;
export type ElkEdgeOptions = ElkElementOptions;

export type ElkNodeData<T> = { [elkOpts]?: ElkNodeOptions } & T;
export type ElkEdgeData<T> = T & { [elkOpts]?: ElkEdgeOptions };

export type ElkFlowNode<T = unknown> = ReactFlowNode<ElkNodeData<T>>;
export type ElkFlowEdge<T = unknown> = ReactFlowEdge<ElkEdgeData<T>>;

export type ElkReactFlowInstance<
  NodeData = unknown,
  EdgeData = unknown,
> = ReactFlowInstance<ElkNodeData<NodeData>, ElkEdgeData<EdgeData>>;

async function elkNodePositions<TNodeData, TEdgeData>(
  flowNodes: ElkFlowNode<TNodeData>[],
  flowEdges: ElkFlowEdge<TEdgeData>[],
  layoutArgs?: ElkLayoutArguments,
) {
  // TODO(2315): Can find handles on ports by analyzing sourceHandle and targetHandle on every edge and associating with the node.
  // The problem is we don't know where those handles are!

  // First map the reactflow nodes to elk nodes
  const elkNodes: ElkNode[] = flowNodes.map((flowNode) => {
    const layoutOptions = flowNode.data[elkOpts]?.layoutOptions;
    return {
      id: flowNode.id,
      width: flowNode.width ?? 200,
      height: flowNode.height ?? 50,
      layoutOptions,
    };
  });
  const elkEdges: ElkExtendedEdge[] = flowEdges.map((flowEdge) => {
    return {
      id: flowEdge.id,
      targets: [flowEdge.target],
      sources: [flowEdge.source],
      layoutOptions: flowEdge.data
        ? flowEdge.data[elkOpts]?.layoutOptions
        : undefined,
    };
  });

  // Run the layout in elk space
  const layoutedGraph = await elk.layout(
    {
      id: '__elk-reactflow-root',
      children: elkNodes,
      edges: elkEdges,
    },
    layoutArgs,
  );

  // Transform the resulting node layout back to reactflow space
  if (!layoutedGraph.children) {
    throw new Error();
  }
  const nodePositions = new Map(
    layoutedGraph.children.map((layoutedElkNode) => {
      if (!layoutedElkNode.x || !layoutedElkNode.y) {
        throw new Error();
      }
      return [
        layoutedElkNode.id,
        { x: layoutedElkNode.x, y: layoutedElkNode.y },
      ];
    }),
  );

  return nodePositions;
}

export type ElkReactFlowProps = Omit<
  ReactFlowProps,
  'nodes' | 'edges' | 'onNodesChange' | 'onEdgesChange'
> & {
  id: string;
  nodes: ElkFlowNode[];
  edges: ElkFlowEdge[];
  defaultNodes?: ElkFlowNode[];
  defaultEdges?: ElkFlowEdge[];
  layoutArgs?: ElkLayoutArguments;
};
export const ElkReactFlow = forwardRef<HTMLDivElement, ElkReactFlowProps>(
  function ElkReactFlow(props, ref) {
    const {
      id,
      children,
      layoutArgs,
      nodes: initialNodes,
      edges: initialEdges,
      ...otherProps
    } = props;

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, , onEdgesChange] = useEdgesState(initialEdges);
    const nodesInitialized = useNodesInitialized();
    const store = useStoreApi();

    useEffect(() => {
      if (nodesInitialized) {
        const { nodeInternals, edges: currentEdges } = store.getState();
        elkNodePositions(
          Array.from(nodeInternals.values()),
          currentEdges,
          layoutArgs,
        )
          .then((nodePositions) => {
            setNodes(
              Array.from(nodeInternals.values()).map((n) => ({
                ...n,
                position: nodePositions.get(n.id) ?? { x: 0, y: 0 },
              })),
            );
          })
          .catch((reason) => {
            console.error(reason);
          });
      }
    }, [nodesInitialized, store, setNodes, layoutArgs]);

    return (
      <ReactFlow
        ref={ref}
        id={id}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        {...otherProps}
        proOptions={{ hideAttribution: true }}
      >
        <Background />
        {children}
      </ReactFlow>
    );
  },
);
