import { Text } from '@mantine/core';
import {
  DayjsDateTimePicker,
  DayjsDateTimePickerProps,
  RequiredDayjsDateTimePicker,
  RequiredDayjsDateTimePickerProps,
} from './DayjsDateTimePicker';
import { useFacilityContext } from './Facility/FacilityContext';
import { FacilityDTO } from './rest-client';

export type FacilityDateTimePickerProps = Omit<
  DayjsDateTimePickerProps,
  'tz'
> & {
  facility: FacilityDTO;
};

function FacilityTzDescriptor({ facility }: { facility: FacilityDTO }) {
  return <Text>{`${facility.name} (${facility.timeZoneId})`}</Text>;
}

export function FacilityDateTimePicker(props: FacilityDateTimePickerProps) {
  const { facility, description, ...otherProps } = props;
  return (
    <DayjsDateTimePicker
      {...otherProps}
      tz={facility.timeZoneId}
      description={
        <>
          {description}
          <FacilityTzDescriptor facility={facility} />
        </>
      }
    />
  );
}

export type FacilityRequiredDateTimePickerProps = Omit<
  RequiredDayjsDateTimePickerProps,
  'tz'
> & {
  facility: FacilityDTO;
};

export function FacilityRequiredDateTimePicker(
  props: FacilityRequiredDateTimePickerProps,
) {
  const { facility, description, ...otherProps } = props;
  return (
    <RequiredDayjsDateTimePicker
      {...otherProps}
      tz={facility.timeZoneId}
      description={
        <>
          {description}
          <FacilityTzDescriptor facility={facility} />
        </>
      }
    />
  );
}

export function SelectedFacilityRequiredDateTimePicker(
  props: Omit<FacilityRequiredDateTimePickerProps, 'facility'>,
) {
  const facility = useFacilityContext();
  return <FacilityRequiredDateTimePicker facility={facility} {...props} />;
}
