import { Stack, Title } from '@mantine/core';
import { AppPage } from '../../App/AppPage';
import { FacilityIdName } from '../../Facility/FacilityIdName';
import { LabeledValue } from '../../common';
import { ChutecSortSystemDTO } from '../../rest-client';
import { Router } from '../../router';
import { ChutecSortProgramTable } from './ChutecSortProgramTable';

export function ChutecSortSystemDetail(props: {
  chutecSystem: ChutecSortSystemDTO;
}) {
  const { chutecSystem } = props;

  const breadcrumbs = [
    { routeName: Router.ProcessList(), title: 'Processes' },
    'Sort Systems',
  ];

  return (
    <AppPage breadcrumbs={breadcrumbs} title={chutecSystem.name}>
      <AppPage.Section>
        <Stack>
          <Title order={2}>Sort System Details</Title>
          <Stack align='flex-start'>
            <LabeledValue label='Facility Name'>
              <FacilityIdName facilityId={chutecSystem.facilityId} />
            </LabeledValue>
          </Stack>
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Title order={2}>Sort Programs</Title>
          <ChutecSortProgramTable sortSystemId={chutecSystem.id} />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
