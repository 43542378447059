import { Alert, Badge, Group, Table } from '@mantine/core';
import { LinkText } from '../Link';
import { useMaterialClassSets } from '../api/materialClassSet';
import { Router } from '../router';

export function MaterialClassSetTable() {
  const { data: sets, isLoadingError, error } = useMaterialClassSets();

  if (isLoadingError) {
    throw error;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Material Classes</th>
        </tr>
      </thead>
      <tbody>
        {sets?.length === 0 ? (
          <tr>
            <td colSpan={2}>
              <Alert color='blue'>No material class sets defined</Alert>
            </td>
          </tr>
        ) : undefined}
        {sets?.map((set) => (
          <tr key={set.id}>
            <td>
              <LinkText
                to={Router.MaterialClassSetDetail({
                  materialClassSetId: set.id,
                })}
              >
                {set.name}
              </LinkText>
            </td>
            <td>
              <Group>
                {set.materialClasses.map((mc) => (
                  <Badge key={mc.id} variant='outline' color='blue'>
                    {mc.name}
                  </Badge>
                ))}
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
