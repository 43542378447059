import { Loader, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import Temporal from '../../../Temporal/temporal.ts';
import { useRedWaveTelemetry } from '../../../api/redWave';
import { RedWaveSortSystemDTO, RedWaveStream } from '../../../rest-client';
import {
  RedWaveMaterialClassAreaProportionTimeseriesChart,
  RedWaveMaterialClassParticlesPerMinuteTimeSeriesChart,
} from './RedWaveMaterialClassTimeseriesChart';

function renormalize(
  keyedValues: Record<string, number[]>,
): Record<string, number[]> {
  const allValues = Object.values(keyedValues);
  const totals = allValues[0].map((_, i) =>
    allValues.reduce((total, v) => total + v[i], 0),
  );
  return Object.fromEntries(
    Object.entries(keyedValues).map(([k, vals]) => [
      k,
      vals.map((v, i) => v / totals[i]),
    ]),
  );
}
export function DifferentialRedWaveSystemMetrics(props: {
  system: RedWaveSortSystemDTO;
  stream: RedWaveStream;
  startTime: Temporal.Instant;
  endTime?: Temporal.Instant;
}) {
  const { system, stream, startTime, endTime } = props;
  const start = dayjs.utc(startTime.epochMilliseconds);
  const end = endTime ? dayjs.utc(endTime.epochMilliseconds) : undefined;
  const telemetryQuery = useRedWaveTelemetry(system.id, start, end);

  if (telemetryQuery.isLoadingError) {
    throw telemetryQuery.error;
  }

  if (telemetryQuery.isLoading) {
    return <Loader />;
  }

  const telemetry = telemetryQuery.data;

  const filteredMaterialClasses = Object.fromEntries(
    Object.entries(telemetry.materialClasses)
      .filter(
        ([, { ejected }]) =>
          stream === RedWaveStream.INPUT ||
          ejected === (stream == RedWaveStream.EJECT),
      )
      .map(([classNumber, metadata]) => [classNumber, metadata]),
  );

  const classAreaProportions = renormalize(
    Object.fromEntries(
      Object.keys(filteredMaterialClasses).map((classNumber) => [
        classNumber,
        telemetry.differentialTelemetry.materialClassAreaProportions[
          classNumber
        ],
      ]),
    ),
  );

  const classObjectCounts = Object.fromEntries(
    Object.keys(filteredMaterialClasses).map((classNumber) => [
      classNumber,
      telemetry.differentialTelemetry.materialClassObjectCounts[classNumber],
    ]),
  );

  return (
    <Stack key={stream}>
      <RedWaveMaterialClassAreaProportionTimeseriesChart
        timestamps={telemetry.differentialTelemetry.timestamps}
        classAreaProportions={classAreaProportions}
        classMetadata={filteredMaterialClasses}
      />
      <RedWaveMaterialClassParticlesPerMinuteTimeSeriesChart
        timestamps={telemetry.differentialTelemetry.timestamps}
        classParticlesPerMinute={classObjectCounts}
        classMetadata={filteredMaterialClasses}
      />
    </Stack>
  );
}
