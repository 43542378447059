import { Text } from '@mantine/core';
import { match } from 'ts-pattern';
import { LinkText } from '../Link';
import { useRecoveryStrategySimulation } from '../api/recoveryStrategySimulation';
import {
  RecoveryStrategySimulationDTO,
  RecoveryStrategySimulationId,
} from '../rest-client';
import { Router } from '../router';

export interface RecoveryStrategySimulationNameProps {
  noLink?: boolean;
}

export function RecoveryStrategySimulationName(
  props: RecoveryStrategySimulationNameProps & {
    simulation: RecoveryStrategySimulationDTO;
  },
) {
  const { simulation, noLink } = props;

  const simulationName = simulation.name === '' ? 'unnamed' : simulation.name;
  if (noLink) {
    return <Text>{simulationName}</Text>;
  }
  return (
    <LinkText
      to={Router.RecoveryStrategySimulationDetail({
        simulationId: simulation.id,
      })}
    >
      {simulationName}
    </LinkText>
  );
}

export function RecoveryStrategySimulationIdName(
  props: RecoveryStrategySimulationNameProps & {
    simulationId: RecoveryStrategySimulationId;
  },
) {
  const { simulationId, noLink } = props;
  const simulationQuery = useRecoveryStrategySimulation(simulationId);
  return match(simulationQuery)
    .with({ isError: true }, () => (
      <Text c='red'>Error getting simulation name</Text>
    ))
    .with({ isLoading: true }, () => <Text c='dimmed'>Loading...</Text>)
    .otherwise(({ data: simulation }) => (
      <RecoveryStrategySimulationName simulation={simulation} noLink={noLink} />
    ));
}
