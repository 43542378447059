import { Box, Text, TextProps } from '@mantine/core';
import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import { match } from 'ts-pattern';
import {
  ContainerIcon,
  InternalMaterialSinkIcon,
  InternallySourcedMaterialIcon,
  PurchaseIcon,
  SaleIcon,
  TruckLoadIcon,
} from '../Icons';
import { LinkText } from '../Link';
import {
  InternalMaterialSinkDTO,
  InternallySourcedMaterialDTO,
  MaterialContainerDTO,
  MaterialTruckLoadDTO,
  PurchasedMaterialDTO,
  SoldMaterialDTO,
} from '../rest-client';
import { Router } from '../router';
import cssClasses from './RepositoryName.module.css';

export type RepositoryNameVariant =
  | 'name-only'
  | 'icon-name'
  | 'name-only-link'
  | 'icon-name-link';

export function IconText(props: { children: ReactNode }) {
  return <Box className={cssClasses.iconText}>{props.children}</Box>;
}

export interface RepositoryNameBaseProps {
  variant?: RepositoryNameVariant;
  truncate?: TextProps['truncate'];
}

interface RepositoryNameProps extends RepositoryNameBaseProps {
  icon: ReactNode;
  name: string;
  route: string;
}

function RepositoryName(props: RepositoryNameProps) {
  const { variant = 'name-only-link', icon, name, route, truncate } = props;

  return match(variant)
    .with('name-only', () => <Text>{name}</Text>)
    .with('name-only-link', () => <LinkText to={route}>{name}</LinkText>)
    .with('icon-name', () => (
      <IconText>
        {icon}
        <Text truncate={truncate}>{name}</Text>
      </IconText>
    ))
    .with('icon-name-link', () => (
      <IconText>
        {icon}
        <LinkText to={route} truncate={truncate}>
          {name}
        </LinkText>
      </IconText>
    ))
    .exhaustive();
}

export interface PurchasedMaterialNameProps extends RepositoryNameBaseProps {
  purchasedMaterial: PurchasedMaterialDTO;
}
export function PurchasedMaterialName(props: PurchasedMaterialNameProps) {
  const { purchasedMaterial, ...rest } = props;
  return (
    <RepositoryName
      icon={<PurchaseIcon />}
      name={purchasedMaterial.name}
      route={Router.VendorPurchasedMaterialDetail({
        vendorId: purchasedMaterial.vendorId,
        purchasedMaterialId: purchasedMaterial.id,
      })}
      {...rest}
    />
  );
}

export interface InternallySourcedMaterialNameProps
  extends RepositoryNameBaseProps {
  internallySourcedMaterial: InternallySourcedMaterialDTO;
}
export function InternallySourcedMaterialName(
  props: InternallySourcedMaterialNameProps,
) {
  const { internallySourcedMaterial, ...rest } = props;

  return (
    <RepositoryName
      icon={<InternallySourcedMaterialIcon />}
      name={internallySourcedMaterial.name}
      route={Router.InternalSourceMaterialsMaterialDetail({
        internalMaterialSourceId:
          internallySourcedMaterial.internalMaterialSourceId,
        internallySourcedMaterialId: internallySourcedMaterial.id,
      })}
      {...rest}
    />
  );
}

export interface TruckLoadNameProps extends RepositoryNameBaseProps {
  truckLoad: MaterialTruckLoadDTO;
}
export function TruckLoadName(props: TruckLoadNameProps) {
  const { truckLoad, ...rest } = props;

  return (
    <RepositoryName
      icon={<TruckLoadIcon />}
      name={truckLoad.name}
      route={Router.TruckLoadDetail({
        truckLoadId: truckLoad.id,
      })}
      {...rest}
    />
  );
}

export interface ContainerNameProps extends RepositoryNameBaseProps {
  container: MaterialContainerDTO;
  time: Dayjs | null;
}
export function ContainerName(props: ContainerNameProps) {
  const { container, time, ...rest } = props;

  return (
    <RepositoryName
      icon={<ContainerIcon />}
      name={container.name}
      route={Router.ContainerDetail({
        containerId: container.id,
        timestamp: time?.utc().toISOString(),
      })}
      {...rest}
    />
  );
}

export interface SoldMaterialNameProps extends RepositoryNameBaseProps {
  soldMaterial: SoldMaterialDTO;
}
export function SoldMaterialName(props: SoldMaterialNameProps) {
  const { soldMaterial, ...rest } = props;

  return (
    <RepositoryName
      icon={<SaleIcon />}
      name={soldMaterial.name}
      route={Router.CustomerSoldMaterialsDetail({
        customerId: soldMaterial.customerId,
        soldMaterialId: soldMaterial.id,
      })}
      {...rest}
    />
  );
}

export interface InternalMaterialSinkNameProps extends RepositoryNameBaseProps {
  internalMaterialSink: InternalMaterialSinkDTO;
}
export function InternalMaterialSinkName(props: InternalMaterialSinkNameProps) {
  const { internalMaterialSink, ...rest } = props;

  return (
    <RepositoryName
      icon={<InternalMaterialSinkIcon />}
      name={internalMaterialSink.name}
      route={Router.InternalSinkDetail({
        internalMaterialSinkId: internalMaterialSink.id,
      })}
      {...rest}
    />
  );
}
