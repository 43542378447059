import { Alert, Box, BoxProps, Divider, Group, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { FormActionButton } from '../Form/FormActionButton';
import { useFormNavBlocker } from '../Form/useFormNavBlocker';
import {
  PartitionFormFields,
  usePartitionForm,
} from '../Partition/PartitionForm';
import { useAddInternallySourcedMaterialPartition } from '../api/internallySourcedMaterialPartition';
import {
  InternallySourcedMaterialDTO,
  InternallySourcedMaterialPartitionCreationDTO,
} from '../rest-client';
import { Router } from '../router';

export type InternallySourcedMaterialPartitionFormProps = Omit<
  React.ComponentPropsWithoutRef<'form'>,
  'onSubmit'
> &
  Omit<BoxProps, 'component'> & {
    internallySourcedMaterial: InternallySourcedMaterialDTO | undefined;
  };

export function InternallySourcedMaterialPartitionForm(
  props: InternallySourcedMaterialPartitionFormProps,
) {
  const { internallySourcedMaterial, ...rest } = props;
  const addMutation = useAddInternallySourcedMaterialPartition();
  const form = useFormNavBlocker(usePartitionForm());

  if (addMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Creating Partition'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => addMutation.reset()}
      >
        The partition may or may not have been saved. You can clear the error
        and try again.
      </Alert>
    );
  }

  const noContainersSelected = form.values.containerDestinations.length === 0;
  const noTruckloadsSelected = form.values.truckLoadDestinations.length === 0;

  return (
    <Box
      {...rest}
      component='form'
      onSubmit={form.onSubmit((formValues) => {
        if (internallySourcedMaterial === undefined) throw new Error();

        const containerDestinations = formValues.containerDestinations.map(
          ({ containerId, filled }) => {
            if (containerId === null) throw new Error();
            return { containerId, filled };
          },
        );

        const truckLoadDestinations = formValues.truckLoadDestinations.map(
          ({ truckLoadId }) => {
            if (truckLoadId === null) throw new Error();
            return { truckLoadId };
          },
        );

        const partition: InternallySourcedMaterialPartitionCreationDTO = {
          containerDestinations:
            formValues.destinationMode === 'container'
              ? containerDestinations
              : [],
          truckLoadDestinations:
            formValues.destinationMode === 'truck-load'
              ? truckLoadDestinations
              : [],
        };

        addMutation.mutate(
          {
            internallySourcedMaterialId: internallySourcedMaterial.id,
            partition,
          },
          {
            onError() {
              showNotification({
                title: 'Error Creating Upstream Sourced Material Partition',
                message: 'An error occurred creating the partition',
                color: 'red',
                icon: <IconX />,
              });
            },
            onSuccess() {
              showNotification({
                title: 'Upstream Sourced Material Partition Created',
                message:
                  'The upstream-sourced material partition was sucessfully recorded',
                color: 'green',
                icon: <IconCheck />,
              });
              Router.replace('InternalSourceMaterialsMaterialDetail', {
                internallySourcedMaterialId: internallySourcedMaterial.id,
                internalMaterialSourceId:
                  internallySourcedMaterial.internalMaterialSourceId,
              });
            },
          },
        );
      })}
    >
      <Stack>
        <PartitionFormFields
          form={form}
          timestamp={
            internallySourcedMaterial &&
            dayjs.utc(internallySourcedMaterial.creationTime)
          }
        />
        <Divider />
        <Group position='right'>
          <FormActionButton
            type='submit'
            action='saveCreation'
            loading={addMutation.isLoading}
            disabled={noContainersSelected && noTruckloadsSelected}
          >
            Add Partition
          </FormActionButton>
        </Group>
      </Stack>
    </Box>
  );
}
