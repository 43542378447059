import { UseFormReturnType } from '@mantine/form';
import { useBlocker } from '@swan-io/chicane';
import { useMemo, useState } from 'react';

export function useFormNavBlocker<T>(
  form: UseFormReturnType<T>,
  messageOverride?: string,
): UseFormReturnType<T> {
  const [disableBlocker, setDisableBlocker] = useState(false);

  const shouldBlock = useMemo(() => {
    return form.isDirty() && !disableBlocker;
  }, [form, disableBlocker]);

  const message =
    messageOverride ??
    'Are you sure you want to leave the page? You have unsaved progress that will be discarded if you leave.';

  useBlocker(shouldBlock, message);

  const newForm: UseFormReturnType<T> = useMemo(() => {
    return {
      ...form,
      onSubmit: (...args) => {
        const callback = form.onSubmit(...args);

        return (e) => {
          setDisableBlocker(true);
          callback(e);
        };
      },
    };
  }, [form]);

  return newForm;
}
