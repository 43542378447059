/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SystemOperationalState {
    UNREACHABLE = 'Unreachable',
    OFFLINE = 'Offline',
    SETTLING = 'Settling',
    STANDBY = 'Standby',
    ENGAGING = 'Engaging',
    ENGAGED = 'Engaged',
    DISENGAGING = 'Disengaging',
    FAULTED = 'Faulted',
}
