import { Button, Group, Loader, Navbar, Select, Title } from '@mantine/core';
import { Link, useLinkProps, useLocation } from '@swan-io/chicane';
import { Icon, IconCalendar, IconLogout } from '@tabler/icons-react';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import {
  CommodityIcon,
  ContainerIcon,
  ContainerSamplingIcon,
  InsightsIcon,
  InternalMaterialSinkIcon,
  InternalMaterialSourceIcon,
  MaterialClassIcon,
  ProcessIcon,
  ProductionIcon,
  RecoveryStrategyIcon,
  TransactionLedgerIcon,
} from '../Icons';
import { msalInstance } from '../Microsoft/msalConfig';
import SvgPlainV from '../SVG/PlainV';
import { useFacilities } from '../api/facilities';
import { useMaterialStateStatus } from '../api/materialState';
import { FacilityDTO } from '../rest-client';
import { Router } from '../router';
import { ColorSchemeToggle } from './ColorSchemeToggle';
import cssClasses from './css/AppNavbar.module.css';

interface NavItemLink {
  id: string;
  label: string;
  href: string;
  icon: Icon;
  isActive?: boolean;
}

interface NavSection {
  id: string;
  label: string;
  navItems: NavItemLink[];
}

const structuredNavigationData: NavSection[] = [
  {
    label: 'Insights',
    id: 'insights',
    navItems: [
      {
        id: 'insights-dashboard',
        label: 'Dashboard',
        href: Router.FacilityInsightsDashboard(),
        icon: InsightsIcon,
      },
    ],
  },
  {
    label: 'Production',
    id: 'production',
    navItems: [
      {
        id: 'production-history',
        label: 'Production History',
        href: Router.ProductionHistory(),
        icon: ProductionIcon,
      },
      {
        id: 'daily-production',
        label: 'Daily Production',
        href: Router.ProductionDaily(),
        icon: IconCalendar,
      },
      {
        id: 'sorting-processes',
        label: 'Sorting Processes',
        href: Router.ProcessList(),
        icon: ProcessIcon,
      },
      {
        id: 'recovery-strategies',
        label: 'Recovery Strategies',
        href: Router.RecoveryStrategyList(),
        icon: RecoveryStrategyIcon,
      },
    ],
  },
  {
    label: 'Inventory',
    id: 'inventory',
    navItems: [
      {
        id: 'containers',
        label: 'Container Inventory',
        href: Router.ContainerList(),
        icon: ContainerIcon,
      },
      {
        id: 'container-samples',
        label: 'Container Sampling',
        href: Router.ContainerSampleList(),
        icon: ContainerSamplingIcon,
      },
      {
        id: 'material-sourcing',
        label: 'Material Sourcing',
        href: Router.InternalSourceList(),
        icon: InternalMaterialSourceIcon,
      },
      {
        id: 'material-exports',
        label: 'Material Exports',
        href: Router.InternalSinkList(),
        icon: InternalMaterialSinkIcon,
      },
      {
        id: 'commodities',
        label: 'Commodities',
        href: Router.CommodityList(),
        icon: CommodityIcon,
      },
      {
        id: 'material-classes',
        label: 'Material Classes',
        href: Router.MaterialClassList(),
        icon: MaterialClassIcon,
      },
    ],
  },
  {
    label: 'Event Ledger',
    id: 'event-ledger',
    navItems: [
      {
        id: 'event-ledger',
        label: 'Event Ledger',
        href: Router.LedgerHistory(),
        icon: TransactionLedgerIcon,
      },
    ],
  },
];

function NavItemLink(props: NavItemLink): ReactNode {
  const { label, href, isActive } = props;
  const { onClick } = useLinkProps({ href });
  const activeClass = isActive ? cssClasses.linkActive : '';
  return (
    <a
      className={`${cssClasses.link} ${activeClass}`}
      href={href}
      onClick={onClick}
    >
      <props.icon className={cssClasses.linkIcon} stroke={1.5} />
      <span>{label}</span>
    </a>
  );
}

function NavSection(props: {
  isOpen: boolean;
  currPath: string;
  isCurrentSection: boolean;
  id: string;
  navItems: NavItemLink[];
  label: string;
  onClick: (id: string) => void;
}) {
  const { isOpen, currPath, isCurrentSection, id, navItems, label, onClick } =
    props;
  const listRef = useRef(null);
  const navSectionIsOpen = isOpen ? cssClasses.navSectionOpen : '';
  const isCurrent = isCurrentSection ? cssClasses.currentSection : '';
  return (
    <div
      className={`${cssClasses.navSection} ${isCurrent} ${navSectionIsOpen}`}
      key={id}
    >
      <Title
        className={cssClasses.navSectionTitle}
        order={4}
        onClick={() => {
          onClick(id);
        }}
      >
        {label}
      </Title>

      <div className={`${cssClasses.navListWrapper} `}>
        <ul className={`${cssClasses.navSectionList}`} ref={listRef}>
          {navItems.map((navItem) => {
            const isActive = currPath.startsWith(navItem.href);
            return (
              <li key={navItem.id} className={cssClasses.navListItem}>
                <NavItemLink
                  isActive={isActive}
                  id={navItem.id}
                  href={navItem.href}
                  label={navItem.label}
                  icon={navItem.icon}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export function AppNavbar(props: {
  facility: FacilityDTO | null;
  onFacilityChange: (facility: FacilityDTO | null) => void;
}) {
  const { facility, onFacilityChange } = props;

  const materialStateStatusQuery = useMaterialStateStatus();

  const currLocation = useLocation();
  const currPath = `/${currLocation.path.join('/')}`;

  const currSection = structuredNavigationData.find((navSection) => {
    return !!navSection.navItems.find((navItem) =>
      currPath.startsWith(navItem.href),
    );
  });
  /**
   * TODO(2299): fix this to work with pages that don't have dedicated nav items
   *  FFG detail page
   *  recovery goal detail page
   *  etc.
   */

  // if (!currSection) {
  //   throw new ReferenceError('Could not determine current nav section.');
  // }

  const initialNavSectionsOpen = structuredNavigationData.reduce(
    (navSectionsOpen, navSection) => {
      return {
        ...navSectionsOpen,
        [navSection.id]: !!navSection.navItems.find((navItem) =>
          currPath.startsWith(navItem.href),
        ),
      };
    },
    {},
  );
  const [navSectionsOpen, setNavSectionsOpen] = useState<
    Record<string, boolean>
  >(initialNavSectionsOpen);

  const facilitiesQuery = useFacilities();

  useEffect(() => {
    if (
      facilitiesQuery.data &&
      facilitiesQuery.data.length > 0 &&
      facility === null
    ) {
      onFacilityChange(facilitiesQuery.data[0]);
    }
  }, [facilitiesQuery.data, onFacilityChange, facility]);

  const currentSectionId = currSection?.id;

  const handleSectionClick = useCallback(
    (clickedSectionId: string) => {
      if (currentSectionId?.startsWith(clickedSectionId)) {
        return;
      }

      const newBaseOpenSections = Object.keys(navSectionsOpen).reduce(
        (openSections: Record<string, boolean>, sectionId) => {
          openSections[sectionId] = false;
          return openSections;
        },
        {},
      );
      const currSectionOpen =
        currentSectionId === undefined ? {} : { [currentSectionId]: true };

      setNavSectionsOpen({
        ...newBaseOpenSections,
        ...currSectionOpen,
        [clickedSectionId]: !navSectionsOpen[clickedSectionId],
      });
    },
    [currentSectionId, navSectionsOpen],
  );

  useEffect(() => {
    const newBaseOpenSections = Object.keys(navSectionsOpen).reduce(
      (openSections: Record<string, boolean>, sectionId) => {
        openSections[sectionId] = false;
        return openSections;
      },
      {},
    );

    const currSectionOpen =
      currSection === undefined ? {} : { [currSection.id]: true };

    setNavSectionsOpen({
      ...newBaseOpenSections,
      ...currSectionOpen,
    });
    // TODO(2299): Try to get rid of this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currLocation.path]);

  const navMenu = structuredNavigationData.map((navSection) => {
    const isCurrentSection = currSection?.id.startsWith(navSection.id);
    const isOpen = navSectionsOpen[navSection.id];
    return (
      <NavSection
        isOpen={isOpen}
        currPath={currPath}
        isCurrentSection={isCurrentSection ?? false}
        key={navSection.id}
        id={navSection.id}
        navItems={navSection.navItems}
        label={navSection.label}
        onClick={() => {
          handleSectionClick(navSection.id);
        }}
      />
    );
  });

  return (
    <>
      <nav className={cssClasses.mainNav}>
        <Navbar.Section className={cssClasses.header}>
          <Link to={Router.Home()}>
            <SvgPlainV className={cssClasses.logo} />
          </Link>
          <Link to={Router.Home()} className={cssClasses.logoText}>
            <Title order={2}>VALI-Sort</Title>
          </Link>
        </Navbar.Section>

        <Navbar.Section grow>
          <div className={cssClasses.navMenuWrapper}>{navMenu}</div>
        </Navbar.Section>
      </nav>
      <div className={cssClasses.actionsOverlay}>
        <Navbar.Section>
          <Group noWrap align='end'>
            <Select
              label='Facility'
              value={facility?.id ?? null}
              onChange={(facilityId) =>
                onFacilityChange(
                  facilitiesQuery.data?.find((f) => f.id === facilityId) ??
                    null,
                )
              }
              data={
                facilitiesQuery.data?.map((f) => ({
                  value: f.id,
                  label: f.name,
                })) ?? []
              }
              disabled={facilitiesQuery.isLoading}
            />
            {materialStateStatusQuery.data?.status === 'processing' ? (
              <Loader />
            ) : null}
          </Group>
        </Navbar.Section>

        <Navbar.Section className={cssClasses.footer}>
          <Button
            leftIcon={<IconLogout />}
            onClick={() =>
              void msalInstance.logoutPopup().catch((e) => {
                console.error(e);
              })
            }
          >
            Logout
          </Button>
          <ColorSchemeToggle />
        </Navbar.Section>
      </div>
    </>
  );
}
