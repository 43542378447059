import { useMutation } from '@tanstack/react-query';
import {
  MaterialClassSetCompositionCreationDTO,
  MaterialClassSetCompositionPatchDTO,
  MaterialClassSetCompositionService,
} from '../rest-client';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function createMaterialClassSetComposition(
  dto: MaterialClassSetCompositionCreationDTO,
) {
  await MaterialClassSetCompositionService.createComposition(dto);
}

export function useCreateMaterialClassSetComposition() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createMaterialClassSetComposition,
    onSettled() {
      invalidator.invalidateKeys(queryKeys.materialClassSetComposition.list());
    },
  });
}

async function patchMaterialClassSetComposition(args: {
  id: string;
  patch: MaterialClassSetCompositionPatchDTO;
}) {
  const { id, patch } = args;
  await MaterialClassSetCompositionService.patchComposition(id, patch);
}

export function usePatchMaterialClassSetComposition() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchMaterialClassSetComposition,
    onSettled(data, error, { id }) {
      invalidator.invalidateKeys(
        queryKeys.materialClassSetComposition.list(),
        queryKeys.materialClassSetComposition.detail(id),
        queryKeys.recoveryStrategySimulation.all,
      );
    },
  });
}
