import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { MaterialClassSetDTO } from '../../rest-client';

const ManualSampleAnalysisFormSchema = z.object({
  specifiedTotalScaleDisplayDivisions: z
    .number({ message: 'Total sample weight must be a number' })
    .nonnegative({ message: 'Total sample weight cannot be negative' })
    .nullable(),
  materialClasses: z
    .object({
      materialClassId: z
        .string({ message: 'Material class is required' })
        .uuid(),
      netWeightScaleDisplayDivisions: z
        .number({ message: 'Material class weight must be a number' })
        .nonnegative({ message: 'Material class weight cannot be negative' })
        .nullable(),
    })
    .array(),
});

export type ManualSampleAnalysisFormValues = z.infer<
  typeof ManualSampleAnalysisFormSchema
>;

export function useManualSampleAnalysisForm(
  materialClassSet: MaterialClassSetDTO,
  initialValues?: ManualSampleAnalysisFormValues,
) {
  return useForm<ManualSampleAnalysisFormValues>({
    initialValues: initialValues ?? {
      specifiedTotalScaleDisplayDivisions: null,
      materialClasses: materialClassSet.materialClasses.map((mc) => ({
        materialClassId: mc.id,
        netWeightScaleDisplayDivisions: null,
      })),
    },
    validate: zodResolver(ManualSampleAnalysisFormSchema),
    validateInputOnBlur: true,
  });
}
