import { Loader, Skeleton, Table, Text, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import { P, match } from 'ts-pattern';
import { MaterialSetCommodity } from '../Commodity/MaterialSetCommodity';
import { MaterialSetMass } from '../Mass/MaterialSetMass';
import { PurchasedMaterialName } from '../Repository/RepositoryName';
import { TableEmptyBasicContent } from '../TableEmptyBasicContent.tsx';
import { VendorIdName } from '../Vendor/VendorIdName';
import {
  usePurchasedMaterialSets,
  usePurchasedMaterials,
} from '../api/purchasedMaterial';
import { MaterialVendorId } from '../rest-client';

export function PurchasedMaterialTable(props: { vendorId?: MaterialVendorId }) {
  const { vendorId } = props;

  const theme = useMantineTheme();

  const purchasedMaterialsQuery = usePurchasedMaterials(vendorId);
  const purchasedMaterialSetsQuery = usePurchasedMaterialSets(vendorId);

  if (purchasedMaterialsQuery.isLoadingError) {
    throw purchasedMaterialsQuery.error;
  }

  const getMaterialSet = (pmId: string) =>
    match(purchasedMaterialSetsQuery.data)
      .with(undefined, () => undefined)
      .with({ status: 'ledger-error' }, () => null)
      .with(
        { status: 'success', purchasedMaterialSets: P.select() },
        (pmSets) => pmSets[pmId],
      )
      .exhaustive();

  const materials = purchasedMaterialsQuery.data ?? [];
  const hideVendor = vendorId !== undefined;

  const isLoading =
    purchasedMaterialsQuery.isLoading || purchasedMaterialSetsQuery.isLoading;
  const isEmpty = materials.length === 0;

  return (
    <Table>
      {(isLoading || isEmpty) && (
        <caption
          style={{
            captionSide: 'bottom',
            textAlign: 'center',
            padding: theme.spacing.md,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <TableEmptyBasicContent>
              No Purchased Materials
            </TableEmptyBasicContent>
          )}
        </caption>
      )}
      <thead>
        <tr>
          <th>Name</th>
          {!hideVendor && <th>Vendor</th>}
          <th>Date Purchased</th>
          <th>Commodity</th>
          <th style={{ textAlign: 'right' }}>Mass</th>
        </tr>
      </thead>
      <tbody>
        {materials.map((material) => {
          const materialSet = getMaterialSet(material.id);
          return (
            <tr key={material.id}>
              <td>
                <PurchasedMaterialName purchasedMaterial={material} />
              </td>
              {!hideVendor && (
                <td>
                  <VendorIdName vendorId={material.vendorId} />
                </td>
              )}
              <td>{dayjs(material.creationTime).format('L')}</td>

              {match(materialSet)
                .with(undefined, () => (
                  <>
                    <td>
                      <Skeleton>Loading...</Skeleton>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Skeleton>Loading...</Skeleton>
                    </td>
                  </>
                ))
                .with(null, () => (
                  <>
                    <td>
                      <Text color='orange'>Ledger Error</Text>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Text color='orange'>Ledger Error</Text>
                    </td>
                  </>
                ))
                .otherwise((m) => (
                  <>
                    <td>
                      <MaterialSetCommodity materialSet={m} />
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <MaterialSetMass materialSet={m} />
                    </td>
                  </>
                ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
