import {
  ActionIcon,
  Divider,
  Group,
  Paper,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import {
  IconChevronDown,
  IconChevronUp,
  IconPlaceholder,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { Handle, NodeProps, Position } from 'reactflow';
import { MaterialSetCommodity } from '../Commodity/MaterialSetCommodity';
import { useFacilityContext } from '../Facility/FacilityContext';
import { MaterialSetMass } from '../Mass/MaterialSetMass';
import { LabeledValue } from '../common';
import { FlowMaterialSetNodeData } from './GenealogyGraph';
import { useGenealogyGraphCtx } from './GenealogyGraphContext';
import { OccupationHistory } from './OccupationHistory';
import { RepositoryOccupationStart } from './RepositoryOccupationStart';

export default function MaterialSetNode(
  props: NodeProps<FlowMaterialSetNodeData>,
) {
  const {
    data: { materialSet, isRoot, producerIsFringe, consumerIsFringe },
    selected,
  } = props;

  const facility = useFacilityContext();
  const theme = useMantineTheme();
  const { addProducingEffect, addConsumingEffect } = useGenealogyGraphCtx();

  // TODO(2315): CSS
  // const { classes } = useNodeStyles({
  //   type: 'material-set',
  //   zIndex: props.zIndex,
  //   selected: props.selected,
  //   isOrigin,
  // });
  const currentOccupationStart = materialSet.currentRepositoryOccupationStart;
  const repoHistory = materialSet.historicalRepositoryOccupationStarts;

  const creationTime = dayjs
    .utc(materialSet.creationTime)
    .tz(facility.timeZoneId)
    .format('l');

  // TODO(2315): Add ancestor toggle
  return (
    <Paper
      px='sm'
      py='xs'
      bg={isRoot ? 'green.1' : undefined}
      style={{
        zIndex: props.zIndex,
        outlineColor: isRoot
          ? theme.colors.green[5]
          : selected
            ? theme.colors.orange[6]
            : theme.colors.gray[6],
        outlineWidth: isRoot || selected ? '5px' : '1px', // TODO(2315): selected should be different
        outlineStyle: selected ? 'dotted' : 'solid', // TODO(2315): selected should be different
      }}
    >
      {producerIsFringe ? (
        <Stack align='center'>
          <ActionIcon
            onClick={() => addProducingEffect(materialSet)}
            color='blue'
            variant='outline'
          >
            <IconChevronUp size='1rem' />
          </ActionIcon>
        </Stack>
      ) : null}

      {materialSet.directAncestors.length > 0 && (
        <Handle type='target' id='input' position={Position.Top} />
      )}

      {repoHistory.length > 0 ? (
        <OccupationHistory materialSet={materialSet} />
      ) : undefined}

      {currentOccupationStart !== null ? (
        // This material set is currently in a repo
        <RepositoryOccupationStart occupationStart={currentOccupationStart} />
      ) : undefined}

      {repoHistory.length === 0 && currentOccupationStart === null ? (
        <Group>
          <IconPlaceholder />
          <Text weight={500}>Intermediate</Text>
          <Text>{creationTime}</Text>
        </Group>
      ) : undefined}

      <Divider mt='sm' mb='xs' />

      <Group align='start'>
        <LabeledValue label='Weight'>
          <MaterialSetMass materialSet={materialSet} showBoth showAllClaims />
        </LabeledValue>
        <LabeledValue label='Commodity'>
          <MaterialSetCommodity
            materialSet={materialSet}
            orientation='vertical'
          />
        </LabeledValue>
      </Group>

      {consumerIsFringe ? (
        <Stack align='center' justify='center'>
          <ActionIcon
            onClick={() => addConsumingEffect(materialSet)}
            color='blue'
            variant='outline'
          >
            <IconChevronDown size='1rem' />
          </ActionIcon>
        </Stack>
      ) : null}

      {materialSet.consumingEffect ? (
        <Handle type='source' position={Position.Bottom}>
          {/* TODO(2315): make the button add that consuming effect */}
          {/* <ActionIcon
                          size='xs'
                          variant='subtle'
                          radius='lg'
                          onClick={() => onDescendantsToggled(materialSet.hash)}
                        >
                          {descendantsExpanded ? <IconMinus /> : <IconPlus />}
                        </ActionIcon> */}
        </Handle>
      ) : null}
    </Paper>
  );
}
