import { useMutation, useQuery } from '@tanstack/react-query';
import {
  MaterialClassUnionCreationDTO,
  MaterialClassUnionPatchDTO,
  MaterialClassUnionService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchMaterialClassUnion(
  ctx: QueryFunctionContexts['materialClassUnion']['detail'],
) {
  const [{ unionId }] = ctx.queryKey;
  return await MaterialClassUnionService.getMaterialClassUnion(unionId);
}

export function useMaterialClassUnion(unionId: string) {
  return useQuery({
    queryKey: queryKeys.materialClassUnion.detail(unionId),
    queryFn: fetchMaterialClassUnion,
  });
}

async function fetchMaterialClassUnions(
  ctx: QueryFunctionContexts['materialClassUnion']['list'],
) {
  const [{ materialClassSetId }] = ctx.queryKey;

  return await MaterialClassUnionService.getMaterialClassUnions(
    materialClassSetId,
  );
}

export function useMaterialClassUnions(args: { materialClassSetId?: string }) {
  return useQuery({
    queryKey: queryKeys.materialClassUnion.list(args),
    queryFn: fetchMaterialClassUnions,
  });
}

async function createMaterialClassUnion(dto: MaterialClassUnionCreationDTO) {
  await MaterialClassUnionService.createMaterialClassUnion(dto);
}

export function useAddMaterialClassUnion() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createMaterialClassUnion,
    onSettled() {
      invalidator.invalidateKeys(queryKeys.materialClassUnion.list({}));
    },
  });
}

async function patchMaterialClassUnion({
  unionId,
  patch,
}: {
  unionId: string;
  patch: MaterialClassUnionPatchDTO;
}) {
  await MaterialClassUnionService.patchMaterialClassUnion(unionId, patch);
}

export function usePatchMaterialClassUnion() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchMaterialClassUnion,
    onSettled(_data, _error, { unionId }) {
      invalidator.invalidateKeys(
        queryKeys.materialClassUnion.list({}),
        queryKeys.materialClassUnion.detail(unionId),
      );
    },
  });
}

async function deleteMaterialClassUnion(unionId: string) {
  await MaterialClassUnionService.deleteMaterialClassUnion(unionId);
}

export function useDeleteMaterialClassUnion() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteMaterialClassUnion,
    onSettled(_data, _error, unionId) {
      invalidator.removeKey(queryKeys.materialClassUnion.detail(unionId));
      invalidator.invalidateKeys(queryKeys.materialClassUnion.list({}));
    },
  });
}
