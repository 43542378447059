import { ActionIcon, Button, Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconRowInsertBottom, IconX } from '@tabler/icons-react';
import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import { DeleteIcon, SaveIcon } from '../Icons';
import { usePatchRecoveryStrategy } from '../api/recoveryStrategy';

import { MutationErrorAlert } from '../Form/MutationErrorAlert';
import { RecoveryPathEdit } from '../RecoveryGoal/RecoveryPathEdit';
import { RecoveryStrategyDTO } from '../rest-client';

export interface RecoveryStrategyEditFormState {
  name: string;
  paths: {
    id: string;
    state: 'new' | 'old' | 'deleted';
    steps: {
      recoveryGoalId: string | null;
      negative: boolean;
    }[];
  }[];
}
// TODO(2333): polish this form and the add form
export function RecoveryStrategyEditForm(props: {
  recoveryStrategy: RecoveryStrategyDTO;
  onCancel: () => void;
  onComplete: () => void;
}) {
  const { recoveryStrategy, onCancel, onComplete } = props;
  const patchMutation = usePatchRecoveryStrategy();

  const form = useForm<RecoveryStrategyEditFormState>({
    initialValues: {
      name: recoveryStrategy.name,
      paths: recoveryStrategy.paths.map((path) => ({
        id: path.id,
        state: 'old',
        steps: path.steps.map((step) => ({
          recoveryGoalId: step.recoveryGoalId,
          negative: step.negative,
        })),
      })),
    },
    validate: {
      paths: {
        steps: {
          recoveryGoalId(value) {
            if (!value) {
              return 'required';
            }

            return null;
          },
        },
      },
    },
  });

  if (patchMutation.isError) {
    return (
      <MutationErrorAlert
        errorTitle='Error Updating Recovery Strategy Steps'
        entityName='Recovery Stratgegy Steps'
        mutation={patchMutation}
        formVariant='edit'
      />
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        patchMutation.mutate(
          {
            id: recoveryStrategy.id,
            patch: {
              name: values.name, // TODO(2333): only if name is dirty
              deletedPathIds: values.paths
                .filter((p) => p.state === 'deleted')
                .map((p) => p.id),
              newPaths: values.paths
                .filter((p) => p.state === 'new')
                .map((p) => ({
                  id: p.id,
                  steps: p.steps.map((s) => {
                    if (s.recoveryGoalId === null) {
                      throw new Error('unset recovery goal id');
                    }
                    return {
                      recoveryGoalId: s.recoveryGoalId,
                      negative: s.negative,
                    };
                  }),
                })),
              // TODO(2333): should be filtered to dirty paths
              updatedPaths: values.paths
                .filter((p) => p.state === 'old')
                .map((p) => ({
                  id: p.id,
                  steps: p.steps.map((s) => {
                    if (s.recoveryGoalId === null) {
                      throw new Error('unset recovery goal id');
                    }

                    return {
                      recoveryGoalId: s.recoveryGoalId,
                      negative: s.negative,
                    };
                  }),
                })),
            },
          },
          {
            onSuccess() {
              onComplete();
            },
          },
        );
      })}
    >
      <Stack align='flex-start'>
        <TextInput label='Name' {...form.getInputProps('name')} />
        {form.values.paths
          .filter((p) => p.state !== 'deleted')
          .map((path, pathIdx) => (
            <Group key={path.id}>
              <ActionIcon
                disabled={patchMutation.isLoading}
                color='red'
                onClick={() => {
                  match(path.state)
                    .with('new', () => form.removeListItem('paths', pathIdx))
                    .with('old', () =>
                      form.setFieldValue(`paths.${pathIdx}.state`, 'deleted'),
                    )
                    .with('deleted', () => {
                      // nothing to do
                    })
                    .exhaustive();
                }}
              >
                <DeleteIcon />
              </ActionIcon>
              <RecoveryPathEdit
                form={form}
                formPathPrefix={`paths.${pathIdx}.`}
                accessor={(v) => v.paths[pathIdx]}
                disabled={!patchMutation.isIdle}
              />
            </Group>
          ))}
        <Button
          disabled={patchMutation.isLoading}
          leftIcon={<IconRowInsertBottom />}
          color='blue'
          onClick={() =>
            form.insertListItem('paths', {
              id: uuidv4(),
              state: 'new',
              steps: [
                {
                  recoveryGoalId: null,
                  negative: true,
                },
              ],
            })
          }
        >
          Add Sequence
        </Button>
        <Group>
          <Button
            leftIcon={<IconX />}
            onClick={onCancel}
            color='gray'
            disabled={patchMutation.isLoading}
          >
            Cancel
          </Button>

          <Button
            leftIcon={<SaveIcon />}
            type='submit'
            loading={patchMutation.isLoading}
          >
            Save Changes
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
