import { useMutation, useQuery } from '@tanstack/react-query';
import {
  PurchasedMaterialPartitionCreationDTO,
  PurchasedMaterialService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchPurchasedMaterialPartitionStatus(
  ctx: QueryFunctionContexts['transaction']['purchasedMaterialPartition']['status'],
) {
  const [{ purchasedMaterialId }] = ctx.queryKey;
  return await PurchasedMaterialService.getPurchasedMaterialPartition(
    purchasedMaterialId,
  );
}

export function usePurchasedMaterialPartitionStatus(
  purchasedMaterialId: string,
) {
  return useQuery({
    queryKey:
      queryKeys.transaction.purchasedMaterialPartition.status(
        purchasedMaterialId,
      ),
    queryFn: fetchPurchasedMaterialPartitionStatus,
  });
}

async function createPurchasedMaterialPartition({
  purchasedMaterialId,
  partition,
}: {
  purchasedMaterialId: string;
  partition: PurchasedMaterialPartitionCreationDTO;
}) {
  await PurchasedMaterialService.createPurchasedMaterialPartition(
    purchasedMaterialId,
    partition,
  );
}

export function useAddPurchasedMaterialPartition() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createPurchasedMaterialPartition,
    onSettled(_data, _error, { purchasedMaterialId }) {
      invalidator.invalidateKey(
        queryKeys.transaction.purchasedMaterialPartition.status(
          purchasedMaterialId,
        ),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}
