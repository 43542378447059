import { Drawer, DrawerProps, Group, Stack, SystemProp } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { UseMutationResult } from '@tanstack/react-query';
import { CSSProperties, ReactNode, useMemo } from 'react';
import { match } from 'ts-pattern';
import cssClasses from './DrawerForm.module.css';
import {
  FormActionButton,
  FormActionButtonSubmitAction,
} from './FormActionButton';
import { MutationErrorAlert } from './MutationErrorAlert';

export type DrawerFormVariant = 'create' | 'edit';

export type DrawerFormDrawerProps = Omit<
  DrawerProps,
  | 'children'
  | 'title'
  | 'onClose'
  | 'opened'
  | 'styles'
  | 'maxWidth'
  | 'onSubmit'
> & {
  title?: ReactNode;
  onClose: () => void;
  opened: boolean;
};

type DrawerFormFormProps<TDTO, TFormValues> = {
  /**
   * The {@link UseFormReturnType} to be controlled in the drawer.
   */
  form: UseFormReturnType<TFormValues>;
  /**
   * Logic to perform on form submission
   * @param values - the form values, of type {@link TFormValues}
   * @returns the patch or creation {@link TDTO} for the form
   */
  formValuesToDto: (values: TFormValues) => TDTO;
  /**
   * The {@link UseMutationResult} for the form {@link mutation}. Called in the form's obSubmit.
   */
  mutation: UseMutationResult<void, unknown, TDTO, unknown>;
  /**
   * Any additional behavior to perform on the {@link mutation} successful completion.
   * @param dto - the valid patch or creation {@link TDTO} for the form
   * @returns
   */
  onSuccess?: (dto: TDTO) => void;
};

export type DrawerFormProps<TDTO, TFormValues> = DrawerFormDrawerProps &
  DrawerFormFormProps<TDTO, TFormValues> & {
    children: ReactNode;
    /**
     * Controls the form submit button presentation and message language.
     */
    drawerFormVariant: DrawerFormVariant;
    /**
     * Entity name to use for user-facing text: drawer title, messages, notifications.
     */
    entityName: string;
    maxWidth?: SystemProp<CSSProperties['maxWidth']>;
  };

/**
 * This component needs to be accompanied by something to control the opening of the drawer,
 * e.g., a button or menu item.
 *
 * @remark we can add variants to this component if we want to have a modal version.
 *
 * @param TDTO - the creation or patch DTO type.
 * @param TFormValues - the form values type, specified in the {@link UseFormReturnType}.
 * @param {ReactNode} children - form fields fragmant to be stacked in the drawer content.
 * @returns Controllable drawer component containing full form create or edit functionality.
 */
export function DrawerForm<TDTO, TFormValues>(
  props: DrawerFormProps<TDTO, TFormValues>,
) {
  const {
    children,
    entityName,
    maxWidth,
    // FormProps
    form,
    formValuesToDto,
    mutation,
    onSuccess,
    drawerFormVariant,
    // DrawerProps
    title = match(drawerFormVariant)
      .with('create', () => `Add ${entityName}`)
      .with('edit', () => `Edit ${entityName}`)
      .exhaustive(),
    onClose,
    opened,
    size = 'md',
    position = 'right',
    closeOnClickOutside = false,
  } = props;

  // TODO(2443): figure out robust nav blocker for drawer forms

  type NotificationTitleMessage = {
    title: string;
    message: string;
  };
  const errorTitleMessage: NotificationTitleMessage = match(drawerFormVariant)
    .with('create', () => ({
      title: `Error Creating ${entityName}`,
      message: `An error occurred creating the ${entityName.toLowerCase()}.`,
    }))
    .with('edit', () => ({
      title: `Error Updating ${entityName}`,
      message: `An error occurred making edits to the ${entityName.toLowerCase()}.`,
    }))
    .exhaustive();
  const successTitleMessage: NotificationTitleMessage = match(drawerFormVariant)
    .with('create', () => ({
      title: `${entityName} Created`,
      message: `The new ${entityName.toLowerCase()} was sucessfully created.`,
    }))
    .with('edit', () => ({
      title: `${entityName} Updated`,
      message: `The edits to the ${entityName.toLowerCase()} were sucessfully saved.`,
    }))
    .exhaustive();

  const formOnSubmit = useMemo(
    () =>
      form.onSubmit((values: TFormValues) => {
        const dto = formValuesToDto(values);
        mutation.mutate(dto, {
          onError() {
            showNotification({
              ...errorTitleMessage,
              color: 'red',
              icon: <IconX />,
            });
          },
          onSuccess(_, variables) {
            showNotification({
              ...successTitleMessage,
              color: 'green',
              icon: <IconCheck />,
            });
            onClose();
            mutation.reset();
            onSuccess?.(variables);
            form.reset();
          },
        });
      }),
    [
      form,
      formValuesToDto,
      mutation,
      errorTitleMessage,
      successTitleMessage,
      onClose,
      onSuccess,
    ],
  );

  if (!opened) {
    return null;
  }

  return (
    <Drawer
      title={title}
      opened={opened}
      onClose={() => {
        onClose();
        form.reset();
      }}
      closeOnClickOutside={closeOnClickOutside}
      size={size}
      position={position}
      styles={{ inner: { height: '100%' } }}
    >
      <form onSubmit={formOnSubmit}>
        <Stack maw={maxWidth}>{children}</Stack>
        <Stack spacing='md' className={cssClasses.footerContainer}>
          <Group position='right'>
            <FormActionButton
              action='cancel'
              onClick={() => {
                onClose();
                form.reset();
              }}
              loading={mutation.isLoading}
            />
            <FormActionButton
              type='submit'
              action={match(drawerFormVariant)
                .with(
                  'create',
                  () => 'saveCreation' as FormActionButtonSubmitAction,
                )
                .with('edit', () => 'saveEdits' as FormActionButtonSubmitAction)
                .exhaustive()}
              disabled={!mutation.isIdle && !mutation.isLoading}
              loading={mutation.isLoading}
            />
          </Group>
          {mutation.isError && (
            <MutationErrorAlert
              errorTitle={errorTitleMessage.title}
              entityName={entityName}
              mutation={mutation}
              formVariant={drawerFormVariant}
            />
          )}
        </Stack>
      </form>
    </Drawer>
  );
}
