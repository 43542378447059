import { ReactNode } from 'react';

export function InventoryLedgerErrorExplanationRow(props: {
  children: ReactNode;
}) {
  const { children } = props;

  return (
    <tr>
      <td colSpan={6}>{children}</td>
    </tr>
  );
}
