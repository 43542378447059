import { TextInput } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { FacilitySelect } from '../Facility/FacilitySelect';

const InternalMaterialSinkFormSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  facilityId: z
    .string()
    .uuid()
    .nullable()
    .refine((val) => val !== null, { message: 'Facility is required' }),
});

export type InternalMaterialSinkFormValues = z.infer<
  typeof InternalMaterialSinkFormSchema
>;

export function useInternalMaterialSinkForm() {
  return useForm<InternalMaterialSinkFormValues>({
    initialValues: {
      name: '',
      facilityId: null,
    },
    validate: zodResolver(InternalMaterialSinkFormSchema),
  });
}

export function InternalMaterialSinkFormFields(props: {
  form: UseFormReturnType<InternalMaterialSinkFormValues>;
}) {
  const { form } = props;
  return (
    <>
      <TextInput
        label='Name'
        placeholder='Name'
        autoFocus
        withAsterisk
        {...form.getInputProps('name')}
      />
      <FacilitySelect
        label='Facility'
        description='The destination facility for material removed from inventory.'
        placeholder='Select facility'
        withAsterisk
        {...form.getInputProps('facilityId')}
      />
    </>
  );
}
