import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RecoveryGoalIcon } from '../Icons';
import {
  AddRecoveryGoalDrawerForm,
  AddRecoveryGoalDrawerFormProps,
} from './AddRecoveryGoalDrawerForm';

export type AddRecoveryGoalButtonProps = Omit<ButtonProps, 'onClick'> & {
  addRecoveryGoalDrawerFormProps: Omit<
    AddRecoveryGoalDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddRecoveryGoalButton(
  props: AddRecoveryGoalButtonProps,
) {
  const {
    addRecoveryGoalDrawerFormProps,
    leftIcon = <RecoveryGoalIcon />,
    children = 'Add Recovery Goal',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddRecoveryGoalDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addRecoveryGoalDrawerFormProps}
      />
    </>
  );
}
