// All this machinery is to align the object URL lifetime with the query cache lifetime of the key

import { QueryCacheNotifyEvent } from '@tanstack/react-query';
import { queryKeys } from './api/queryKeys';
import { useSamplingSuiteCapturePng } from './api/samplingSuite';
import { queryClient } from './queryClient';

// We remove object URLs from the cache when the png query expires in the react-query cache
const pngsQueryKey = queryKeys.samplingSuiteCapture.pngs()[0];
type PngQueryKeyType = ReturnType<typeof queryKeys.samplingSuiteCapture.png>;

function queryKeyIsSamplingSuitieCapturePngKey(
  queryKey: unknown,
): queryKey is PngQueryKeyType {
  if (!Array.isArray(queryKey)) return false;
  const keyPart: unknown = queryKey[0];
  if (typeof keyPart !== 'object') return false;
  if (keyPart === null) return false;

  return (
    Object.entries(pngsQueryKey).every(
      ([k, v]) => v === (keyPart as Record<string, unknown>)[k],
    ) && 'captureId' in keyPart
  );
}
// maps capture ids to the png object urls
const SAMPLING_SUITE_CAPTURE_PNG_OBJECT_URL_CACHE = new Map<string, string>();
function queryCacheListener(e: QueryCacheNotifyEvent) {
  if (e.type !== 'removed') return;
  const queryKey = e.query.queryKey as unknown;
  if (!queryKeyIsSamplingSuitieCapturePngKey(queryKey)) return;
  const [{ captureId }] = queryKey;
  const objectUrl = SAMPLING_SUITE_CAPTURE_PNG_OBJECT_URL_CACHE.get(captureId);
  if (objectUrl) {
    URL.revokeObjectURL(objectUrl);
    SAMPLING_SUITE_CAPTURE_PNG_OBJECT_URL_CACHE.delete(captureId);
  }
}
queryClient.getQueryCache().subscribe(queryCacheListener);

export function useSamplingSuitePngObjectUrl(captureId: string) {
  const pngQuery = useSamplingSuiteCapturePng(captureId);

  const cachedObjectUrl =
    SAMPLING_SUITE_CAPTURE_PNG_OBJECT_URL_CACHE.get(captureId);
  if (cachedObjectUrl) return cachedObjectUrl;

  if (pngQuery.data) {
    const objectUrl = URL.createObjectURL(pngQuery.data);
    SAMPLING_SUITE_CAPTURE_PNG_OBJECT_URL_CACHE.set(captureId, objectUrl);
    return objectUrl;
  }

  // TODO(2294): Caller can't tell if it's an error or loading state
  return undefined;
}
