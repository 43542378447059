import { Alert, Flex, Loader, Paper, Title } from '@mantine/core';
import { UseQueryResult } from '@tanstack/react-query';
import { LinkText } from '../../Link';
import classes from './css/MetricCard.module.css';

export interface MetricCardProps {
  href: string;
  heading: string;
  className: string;
  query: UseQueryResult;
}

type MetricValueCardProps = MetricCardProps & {
  metricVal: number;
  metricValPrecision?: number;
  metricUnit: string;
};

// TODO support tooltips alongside heading
export function MetricValueCard(props: MetricValueCardProps) {
  const {
    href,
    heading,
    metricVal,
    metricValPrecision = 0,
    metricUnit,
    className,
    query,
  } = props;

  let metricValueContent = null;

  if (query.isLoading) {
    metricValueContent = (
      <Flex className={classes.cardLoading}>
        <Loader variant='bars' size='xl' />
      </Flex>
    );
  } else if (query.isLoadingError) {
    metricValueContent = (
      <Alert color='red'>An error occurred loading this data.</Alert>
    );
  } else {
    metricValueContent = (
      <Title order={4} className={classes.metricVal}>
        <LinkText className={classes.cardLink} to={href}>
          {new Intl.NumberFormat(undefined, {
            maximumFractionDigits: metricValPrecision,
          }).format(metricVal)}
          <span className={classes.metricUnit}> {metricUnit}</span>
        </LinkText>
      </Title>
    );
  }

  return (
    <Paper className={className} radius='lg'>
      <div className={classes.cardLayout}>
        <Title className={classes.heading} order={4}>
          <LinkText className={classes.cardLink} to={href}>
            {heading}
          </LinkText>
        </Title>
        {metricValueContent}
      </div>
    </Paper>
  );
}
