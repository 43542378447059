import { AppPage } from '../App/AppPage';
import { GenealogyExplorer } from '../GenealogyExplorer/GenealogyExplorer';
import { useTruckLoad, useTruckLoadGenealogy } from '../api/truckLoad';
import { Router } from '../router';

export function TruckLoadDetailPage(props: { truckLoadId: string }) {
  const { truckLoadId } = props;
  const truckLoadQuery = useTruckLoad(truckLoadId);
  const genealogyQuery = useTruckLoadGenealogy(truckLoadId);

  return (
    <AppPage
      breadcrumbs={[
        {
          title: 'Truck Loads',
          routeName: Router.TruckLoadList(),
        },
      ]}
      title={truckLoadQuery.data?.name}
    >
      <GenealogyExplorer genealogyQuery={genealogyQuery} />
    </AppPage>
  );
}
