import { Text } from '@mantine/core';
import { useVendor } from '../api/vendor';
import { MaterialVendorId } from '../rest-client';
import { VendorName, VendorNameProps } from './VendorName';

export type VendorIdNameProps = Omit<VendorNameProps, 'vendor'> & {
  vendorId: MaterialVendorId;
};

export function VendorIdName(props: VendorIdNameProps) {
  const { vendorId, ...other } = props;
  const vendorQuery = useVendor(vendorId);
  if (vendorQuery.data !== undefined) {
    return <VendorName vendor={vendorQuery.data} {...other} />;
  }
  if (vendorQuery.isLoadingError) {
    return <Text color='red'>Error getting Vendor name</Text>;
  }
  return <Text color='dimmed'>Loading...</Text>;
}
