import { useQuery } from '@tanstack/react-query';
import { FacilityId, FacilityService } from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys } from './queryKeys';

async function fetchFacilities() {
  return await FacilityService.allFacilities();
}

export function useFacilities() {
  return useQuery({
    queryKey: queryKeys.facility.list(),
    queryFn: fetchFacilities,
  });
}

async function fetchFacility(ctx: QueryFunctionContexts['facility']['detail']) {
  const [{ facilityId }] = ctx.queryKey;
  return await FacilityService.getFacilityById(facilityId);
}

export function useFacility(facilityId: FacilityId) {
  return useQuery({
    queryKey: queryKeys.facility.detail(facilityId),
    queryFn: fetchFacility,
  });
}
