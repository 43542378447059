import { Alert, Button, ButtonProps } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { DeleteIcon } from '../Icons';
import { useDeleteRecoveryStategySimulation } from '../api/recoveryStrategySimulation';
import { RecoveryStrategySimulationId } from '../rest-client';
import { Router } from '../router';

type DeleteRecoveryStrategySimulationButtonProps = ButtonProps & {
  simulationId: RecoveryStrategySimulationId;
};

export function DeleteRecoveryStrategySimulationButton(
  props: DeleteRecoveryStrategySimulationButtonProps,
) {
  const {
    simulationId,
    leftIcon = <DeleteIcon />,
    color = 'red',
    ...buttonProps
  } = props;
  const deleteMutation = useDeleteRecoveryStategySimulation();

  const openDeleteModal = () => {
    openConfirmModal({
      title: 'Confirm Recovery Sequence Simulation Deletion',
      centered: true,
      labels: { confirm: 'Delete Simulation', cancel: 'Back to Safety' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        deleteMutation.mutate(simulationId, {
          onError: () => {
            showNotification({
              title: 'Error Deleting Simulation',
              message:
                'An error occurred deleting this recovery strategy simulation. You changes may have not applied.',
              color: 'red',
              icon: <IconX />,
            });
          },
          onSuccess: () => {
            showNotification({
              title: 'Simulation Deleted',
              message:
                'The recovery strategy simulation was successfully deleted.',
              color: 'green',
              icon: <IconCheck />,
            });
            Router.push('RecoveryStrategyList');
          },
          onSettled: () => {
            deleteMutation.reset();
          },
        });
      },
      children: (
        <Alert title='Warning!' color='red' variant='filled'>
          Deleting this recovery strategy simulation is irreversible! None of
          the parameters specified for this simulation will persist upon
          confirming deletion. They will be lost. Are you absolutely sure you
          want to delete this simulation?
        </Alert>
      ),
    });
  };

  return (
    <Button
      leftIcon={leftIcon}
      color={color}
      onClick={openDeleteModal}
      {...buttonProps}
    >
      Delete
    </Button>
  );
}
