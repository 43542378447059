import { useMutation, useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import Temporal from '../Temporal/temporal';
import {
  MaterialContainerCreationDTO,
  MaterialContainerId,
  MaterialContainerPatchDTO,
  MaterialContainerService,
  OccupiedContainerStateDTO,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchContainer(
  ctx: QueryFunctionContexts['container']['detail'],
) {
  const [{ containerId }] = ctx.queryKey;
  if (containerId === undefined) {
    throw new Error('container is is required');
  }
  return await MaterialContainerService.getMaterialContainerById(containerId);
}

export function useContainer(containerId: string | undefined) {
  return useQuery({
    queryKey: queryKeys.container.detail(containerId),
    queryFn: fetchContainer,
    enabled: containerId !== undefined,
  });
}

async function fetchFacilityContainers(
  ctx: QueryFunctionContexts['container']['list'],
) {
  const [{ facilityId }] = ctx.queryKey;
  return await MaterialContainerService.getMaterialContainers(facilityId);
}

export function useFacilityContainers(facilityId: string) {
  return useQuery({
    queryKey: queryKeys.container.list(facilityId),
    queryFn: fetchFacilityContainers,
  });
}

async function addContainer(dto: MaterialContainerCreationDTO) {
  await MaterialContainerService.createMaterialContainer(dto);
}

export function useAddContainer() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: addContainer,
    onSettled(data, error, dto) {
      invalidator.invalidateKeys(queryKeys.container.list(dto.facilityId));
    },
  });
}

async function patchContainer({
  containerId,
  patch,
}: {
  containerId: string;
  patch: MaterialContainerPatchDTO;
}) {
  await MaterialContainerService.patchMaterialContainer(containerId, patch);
}

export function usePatchContainer(containerId: MaterialContainerId) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: MaterialContainerPatchDTO) =>
      await patchContainer({ containerId, patch }),
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.container.lists(),
        queryKeys.container.detail(containerId),
      );
    },
  });
}

async function deleteContainer(containerId: string) {
  await MaterialContainerService.deleteMaterialContainerById(containerId);
}

export function useDeleteContainer() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteContainer,
    onSuccess(data, containerId) {
      invalidator.removeKey(queryKeys.container.detail(containerId));
    },
    onError(error, containerId) {
      invalidator.resetKey(queryKeys.container.detail(containerId));
    },
    onSettled() {
      invalidator.invalidateKeys(queryKeys.container.lists());
      // We don't invalidate the material state because a container can only be deleted if it is not involved in any transactions
    },
  });
}

async function fetchContainerGenealogy(
  ctx: QueryFunctionContexts['genealogy']['container'],
) {
  const [{ containerId, timestamp }] = ctx.queryKey;
  return await MaterialContainerService.getContainerGenealogy(
    containerId,
    timestamp?.utc().toISOString(),
  );
}

export function useContainerGenealogy(
  containerId: string,
  timestamp: Dayjs | undefined,
) {
  return useQuery({
    queryKey: queryKeys.genealogy.container(containerId, timestamp),
    queryFn: fetchContainerGenealogy,
  });
}

async function fetchOccupiedContainerStates(
  ctx: QueryFunctionContexts['container']['occupiedStates'],
) {
  const [{ facilityId, timestamp }] = ctx.queryKey;
  return await MaterialContainerService.occupiedContainerStates(
    facilityId,
    timestamp?.utc().toISOString(),
  );
}

export function useOccupiedContainerStates(
  facilityId: string,
  timestamp: Dayjs | undefined,
) {
  return useQuery({
    queryKey: queryKeys.container.occupiedStates(facilityId, timestamp),
    queryFn: fetchOccupiedContainerStates,
  });
}

export type ContainerLookupState =
  | { status: 'request-error' }
  | {
      status: 'ledger-error';
    }
  | {
      status: 'occupied';
      state: OccupiedContainerStateDTO;
    }
  | { status: 'empty' }
  | { status: 'loading' };

export function useContainerStateLookup(params: {
  facilityId: string;
  timestamp: Dayjs | undefined;
}) {
  const { facilityId, timestamp } = params;
  const occupiedContainerStatesQuery = useOccupiedContainerStates(
    facilityId,
    timestamp,
  );

  const getContainerState = useCallback(
    (containerId: string): ContainerLookupState => {
      if (occupiedContainerStatesQuery.isLoadingError) {
        return { status: 'request-error' };
      }
      if (occupiedContainerStatesQuery.isLoading) {
        return { status: 'loading' };
      }

      if (occupiedContainerStatesQuery.data.status === 'ledger-error') {
        return { status: 'ledger-error' };
      }

      const { occupiedContainerStates } = occupiedContainerStatesQuery.data;
      if (containerId in occupiedContainerStates) {
        return {
          status: 'occupied',
          state: occupiedContainerStates[containerId],
        };
      }

      return {
        status: 'empty',
      };
    },
    [
      occupiedContainerStatesQuery.isLoadingError,
      occupiedContainerStatesQuery.isLoading,
      occupiedContainerStatesQuery.data,
    ],
  );

  return getContainerState;
}

async function fetchContainerHistory(
  ctx: QueryFunctionContexts['container']['history'],
) {
  const [{ containerId }] = ctx.queryKey;
  return await MaterialContainerService.getContainerHistoryId(containerId);
}

export function useContainerHistory(containerId: string) {
  return useQuery({
    queryKey: queryKeys.container.history(containerId),
    queryFn: fetchContainerHistory,
  });
}

async function fetchContainerWeighedAndSampledConstituents(
  ctx: QueryFunctionContexts['container']['weighedAndSampledConstituents'],
) {
  const [{ containerId, instant }] = ctx.queryKey;
  return await MaterialContainerService.getContainerWeighedAndSampledConstituents(
    containerId,
    instant?.toString() ?? undefined,
  );
}

export function useContainerWeighedAndSampledConstituents(args: {
  containerId: string;
  instant: Temporal.Instant | null;
}) {
  return useQuery({
    queryKey: queryKeys.container.weighedAndSampledConstituents(args),
    queryFn: fetchContainerWeighedAndSampledConstituents,
  });
}
