/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LedgerErrorGenealogyResultDTO } from '../models/LedgerErrorGenealogyResultDTO';
import type { LedgerErrorMaterialSetResultDTO } from '../models/LedgerErrorMaterialSetResultDTO';
import type { LedgerErrorPurchasedMaterialSetsResultDTO } from '../models/LedgerErrorPurchasedMaterialSetsResultDTO';
import type { MaterialSetSuccessDTO } from '../models/MaterialSetSuccessDTO';
import type { OccupiedGenealogyResultDTO } from '../models/OccupiedGenealogyResultDTO';
import type { PartitionedPurchasedMaterialPartitionStatusDTO } from '../models/PartitionedPurchasedMaterialPartitionStatusDTO';
import type { PurchasedMaterialCreationDTO } from '../models/PurchasedMaterialCreationDTO';
import type { PurchasedMaterialDTO } from '../models/PurchasedMaterialDTO';
import type { PurchasedMaterialPartitionCreationDTO } from '../models/PurchasedMaterialPartitionCreationDTO';
import type { PurchasedMaterialSetsDTO } from '../models/PurchasedMaterialSetsDTO';
import type { UnpartitionedPurchasedMaterialPartitionStatusDTO } from '../models/UnpartitionedPurchasedMaterialPartitionStatusDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PurchasedMaterialService {
    /**
     * @param vendorId
     * @returns PurchasedMaterialDTO Success
     * @throws ApiError
     */
    public static getAllPurchasedMaterials(
        vendorId?: string,
    ): CancelablePromise<Array<PurchasedMaterialDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchased-materials',
            query: {
                'vendorId': vendorId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createPurchasedMaterial(
        requestBody: PurchasedMaterialCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchased-materials',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param purchasedMaterialGuid
     * @returns PurchasedMaterialDTO Success
     * @throws ApiError
     */
    public static getPurchasedMaterialById(
        purchasedMaterialGuid: string,
    ): CancelablePromise<PurchasedMaterialDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchased-materials/{purchasedMaterialGuid}',
            path: {
                'purchasedMaterialGuid': purchasedMaterialGuid,
            },
        });
    }
    /**
     * @param purchasedMaterialGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deletePurchasedMaterialById(
        purchasedMaterialGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/purchased-materials/{purchasedMaterialGuid}',
            path: {
                'purchasedMaterialGuid': purchasedMaterialGuid,
            },
        });
    }
    /**
     * @param vendorGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getAllPurchasedMaterialSets(
        vendorGuid?: string,
    ): CancelablePromise<(PurchasedMaterialSetsDTO | LedgerErrorPurchasedMaterialSetsResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchased-materials/material-sets',
            query: {
                'vendorGuid': vendorGuid,
            },
        });
    }
    /**
     * @param purchasedMaterialGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getPurchasedMaterialSet(
        purchasedMaterialGuid: string,
    ): CancelablePromise<(LedgerErrorMaterialSetResultDTO | MaterialSetSuccessDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchased-materials/{purchasedMaterialGuid}/material-set',
            path: {
                'purchasedMaterialGuid': purchasedMaterialGuid,
            },
        });
    }
    /**
     * @param purchasedMaterialGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getPurchasedMaterialGenealogyById(
        purchasedMaterialGuid: string,
    ): CancelablePromise<(OccupiedGenealogyResultDTO | LedgerErrorGenealogyResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchased-materials/{purchasedMaterialGuid}/genealogy',
            path: {
                'purchasedMaterialGuid': purchasedMaterialGuid,
            },
        });
    }
    /**
     * @param purchasedMaterialGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getPurchasedMaterialPartition(
        purchasedMaterialGuid: string,
    ): CancelablePromise<(PartitionedPurchasedMaterialPartitionStatusDTO | UnpartitionedPurchasedMaterialPartitionStatusDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/purchased-materials/{purchasedMaterialGuid}/partition',
            path: {
                'purchasedMaterialGuid': purchasedMaterialGuid,
            },
        });
    }
    /**
     * @param purchasedMaterialGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createPurchasedMaterialPartition(
        purchasedMaterialGuid: string,
        requestBody: PurchasedMaterialPartitionCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/purchased-materials/{purchasedMaterialGuid}/partition',
            path: {
                'purchasedMaterialGuid': purchasedMaterialGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param purchasedMaterialGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deletePurchasedMaterialPartition(
        purchasedMaterialGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/purchased-materials/{purchasedMaterialGuid}/partition',
            path: {
                'purchasedMaterialGuid': purchasedMaterialGuid,
            },
        });
    }
}
