import { match } from 'ts-pattern';
import { Router } from '../router';
import RecoveryStrategiesPage from './RecoveryStrategiesPage';
import RecoveryStrategyDetailPage from './RecoveryStrategyDetailPage';

export default function RecoveryStrategyArea() {
  const route = Router.useRoute([
    'RecoveryStrategyDetail',
    'RecoveryStrategyList',
  ]);
  if (route === undefined) {
    throw new Error('no route');
  }

  return match(route)
    .with(
      { name: 'RecoveryStrategyDetail' },
      ({ params: { recoveryStrategyId } }) => (
        <RecoveryStrategyDetailPage recoveryStrategyId={recoveryStrategyId} />
      ),
    )
    .with({ name: 'RecoveryStrategyList' }, () => <RecoveryStrategiesPage />)
    .exhaustive();
}
