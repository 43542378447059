import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ProcessBufferDepletionIcon } from '../Icons';
import {
  AddProcessBufferDepletionDrawerForm,
  AddProcessBufferDepletionDrawerFormProps,
} from './AddProcessBufferDepletionDrawerForm';

export type AddProcessBufferDepletionButtonProps = Omit<
  ButtonProps,
  'onClick'
> & {
  addProcessBufferDepletionDrawerFormProps: Omit<
    AddProcessBufferDepletionDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddProcessBufferDepletionButton(
  props: AddProcessBufferDepletionButtonProps,
) {
  const {
    addProcessBufferDepletionDrawerFormProps,
    leftIcon = <ProcessBufferDepletionIcon />,
    children = 'Add Feedstock Depletion',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddProcessBufferDepletionDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addProcessBufferDepletionDrawerFormProps}
      />
    </>
  );
}
