import { PurchasedMaterialIdName } from '../../PurchasedMaterial/PurchasedMaterialIdName';
import { CalendarDateTime } from '../../common';
import { PurchasedMaterialPartitionDTO } from '../../rest-client';
import { PartitionTypeCell } from '../InventoryLedgerEventTypeCell';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { TransactionStatus } from '../LedgerStatusContext';
import { PartitionDestinationCell } from './PartitionDestinationCell';

export function PurchasedMaterialPartitionRow(props: {
  partition: PurchasedMaterialPartitionDTO;
  status: TransactionStatus;
  id: string;
}) {
  const { partition, status, id } = props;
  return (
    <InventoryLedgerRowTemplate
      id={id}
      entryStatus={status}
      date={<CalendarDateTime iso8601={partition.effectiveTimestamp} />}
      eventType={<PartitionTypeCell />}
      source={
        <PurchasedMaterialIdName
          purchasedMaterialId={partition.purchasedMaterialId}
          variant='icon-name-link'
        />
      }
      destination={<PartitionDestinationCell partition={partition} />}
      // TODO(1809): Should this actually have actions or should it just route to the detail page
      actions={undefined}
    />
  );
}
