import { useQuery } from '@tanstack/react-query';
import { TenantService } from '../rest-client';
import { queryKeys } from './queryKeys';

async function fetchCurrentTenant() {
  return await TenantService.getCurrentTenant();
}

export function useCurrentTenant() {
  return useQuery({
    queryKey: queryKeys.tenant.current(),
    queryFn: fetchCurrentTenant,
  });
}
