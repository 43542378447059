import { BarChart } from 'echarts/charts';
import { GridComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { EChart } from '../../../echarts/BareEChart';
import { Mixture } from '../../../util/mixture';
import cssClasses from './RedWaveMaterialClassObjectCountChart.module.css';

echarts.use([BarChart, GridComponent]);

export function RedWaveMaterialClassObjectCountChart(props: {
  classObjectCounts: Mixture<string>;
}) {
  const { classObjectCounts } = props;

  const maxObjectCountDigits = Math.max(
    ...classObjectCounts.values(),
  ).toString().length;
  return (
    <EChart
      className={cssClasses.chart}
      option={{
        title: {
          text: 'Material Class Particle Count',
        },
        xAxis: {
          name: 'Material Class',
          type: 'category',
          data: [...classObjectCounts.keys()],
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 40,
          },
        },
        yAxis: {
          name: 'Particle Count',
          type: 'value',
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 40 + 12 * maxObjectCountDigits,
          },
        },
        series: [
          {
            type: 'bar',
            colorBy: 'data',
            data: [...classObjectCounts.values()],
            label: {
              show: true,
              position: 'top',
            },
          },
        ],
      }}
    />
  );
}
