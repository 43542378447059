import { Group, MantineColor, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { InternalMaterialSinkTransferIcon } from '../Icons';
import { LinkText } from '../Link';
import { InternalSinkContainerTransferDTO } from '../rest-client';
import { Router } from '../router';

export interface InternallySinkedMaterialNameProps {
  sinkTransfer: InternalSinkContainerTransferDTO;
  noLink?: boolean;
  withIcon?: boolean;
  color?: 'dimmed' | MantineColor;
}

export function InternallySinkedMaterialName(
  props: InternallySinkedMaterialNameProps,
) {
  const { sinkTransfer, noLink, withIcon, color } = props;

  const IconWrapper = ({ children }: { children: ReactNode }) => {
    if (withIcon) {
      return (
        <Group spacing='xs'>
          <InternalMaterialSinkTransferIcon />
          {children}
        </Group>
      );
    } else {
      return <>{children}</>;
    }
  };

  if (noLink) {
    return (
      <IconWrapper>
        <Text color={color}>{sinkTransfer.name ?? 'Unnamed'}</Text>
      </IconWrapper>
    );
  }

  return (
    <IconWrapper>
      <LinkText
        to={Router.InternalSinkTransferDetail({
          sinkTransferId: sinkTransfer.id,
        })}
      >
        {sinkTransfer.name ?? 'Unnamed'}
      </LinkText>
    </IconWrapper>
  );
}
