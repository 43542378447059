import { useMaterialSets } from '../api/materialSet';
import { MaterialSetDTO } from '../rest-client';
import { useGenealogyGraphCtx } from './GenealogyGraphContext';

export interface LoadingGenealogyGraphDataResult {
  status: 'loading';
  progressPct: number;
}

export interface SuccessfulGenealogyGraphDataResult {
  status: 'success';
  materialSets: Map<string, MaterialSetDTO>;
}

export interface LedgerErrorGenealogyGraphDataResult {
  status: 'ledger-error';
}

export interface QueryErrorGenealogyGraphDataResult {
  status: 'query-error';
}

export type GenealogyGraphDataResult =
  | LoadingGenealogyGraphDataResult
  | SuccessfulGenealogyGraphDataResult
  | LedgerErrorGenealogyGraphDataResult
  | QueryErrorGenealogyGraphDataResult;

export default function useGenealogyGraphData(): GenealogyGraphDataResult {
  const { rootMaterialSet, materialSetHashes } = useGenealogyGraphCtx();
  const materialSetQueries = useMaterialSets({
    stateHash: rootMaterialSet.materialStateInferenceHash,
    materialSetHashes,
  });

  const materialSets = new Map<string, MaterialSetDTO>();
  materialSets.set(rootMaterialSet.hash, rootMaterialSet);

  const loadingMaterialSetHashes = new Set<string>();
  for (const [i, materialSetQuery] of materialSetQueries.entries()) {
    if (materialSetQuery.data) {
      const materialSetResult = materialSetQuery.data;

      if (materialSetResult.status === 'ledger-error') {
        return { status: 'ledger-error' };
      }

      const { materialSet } = materialSetResult;
      materialSets.set(materialSet.hash, materialSet);
      continue;
    }

    if (materialSetQuery.isLoading) {
      loadingMaterialSetHashes.add(materialSetHashes[i]);
    }

    if (materialSetQuery.isError) {
      return { status: 'query-error' };
    }
  }

  if (loadingMaterialSetHashes.size > 0) {
    return {
      status: 'loading',
      progressPct:
        (100 * loadingMaterialSetHashes.size) / materialSetHashes.length,
    };
  }

  return { status: 'success', materialSets };
}
