import { Alert, Button, Center, Flex, Loader, Text } from '@mantine/core';
import { IconFaceIdError, IconLogout } from '@tabler/icons-react';
import { createContext, ReactNode, useContext } from 'react';
import { useCurrentTenant } from '../api/tenant';
import { msalInstance } from '../Microsoft/msalConfig';
import { TenantDTO } from '../rest-client';

const TenantContext = createContext<TenantDTO | undefined>(undefined);

export function useTenantContext(): TenantDTO {
  const tenant = useContext(TenantContext);
  if (tenant === undefined) {
    throw new Error('component must be within a tenant context');
  }
  return tenant;
}

export function TenantContextProvider(props: { children: ReactNode }) {
  const { children } = props;

  const currentTenantQuery = useCurrentTenant();
  if (currentTenantQuery.data) {
    const tenant = currentTenantQuery.data;
    return (
      <TenantContext.Provider value={tenant}>{children}</TenantContext.Provider>
    );
  }

  return (
    <Center h='100vh'>
      <Flex direction='column' justify='center' align='center' gap='xl'>
        {currentTenantQuery.isLoading ? (
          <>
            <Loader
              variant='bars'
              size='xl'
              color={currentTenantQuery.failureCount > 0 ? 'orange' : undefined}
            />
            <Text size='sm' color='dimmed'>
              Loading modules...
            </Text>

            {currentTenantQuery.failureCount > 0 ? (
              <Alert
                color='orange'
                icon={<IconFaceIdError />}
                title={`Error Loading Data (attempt ${currentTenantQuery.failureCount + 1})`}
              >
                <Text size='lg'>
                  {"We're having trouble loading this page"}
                </Text>
                <Text size='lg'>If the issue persists, try logging out.</Text>
              </Alert>
            ) : null}
          </>
        ) : null}

        {currentTenantQuery.isError ? (
          <Alert
            color='red'
            title='Error'
            icon={<IconFaceIdError />}
            withCloseButton
            onClose={() => void currentTenantQuery.refetch()}
          >
            <Text size='lg'>There was a problem loading the app.</Text>
            <Text size='lg'>
              Please try logging out and refreshing the page.
            </Text>
          </Alert>
        ) : null}

        <Button
          mt='xl'
          size='lg'
          variant={currentTenantQuery.failureCount > 0 ? 'filled' : 'outline'}
          rightIcon={<IconLogout />}
          onClick={() =>
            void msalInstance.logoutPopup().catch((e) => {
              console.error(e);
            })
          }
        >
          Logout
        </Button>
      </Flex>
    </Center>
  );
}
