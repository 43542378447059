import { useMutation, useQuery } from '@tanstack/react-query';
import {
  CommodityId,
  CommodityService,
  CommoditySpotPriceCreationDTO,
  CommoditySpotPriceId,
  CommoditySpotPricePatchDTO,
} from '../rest-client';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';
import { QueryFunctionContexts } from './queryKeyTypeUtils';

async function patchCommoditySpotPrice({
  commodityId,
  commoditySpotPriceId,
  patch,
}: {
  commodityId: string;
  commoditySpotPriceId: string;
  patch: CommoditySpotPricePatchDTO;
}) {
  await CommodityService.patchCommoditySpotPrice(
    commodityId,
    commoditySpotPriceId,
    patch,
  );
}

export function usePatchCommoditySpotPrice(
  commodityId: CommodityId,
  commoditySpotPriceId: CommoditySpotPriceId,
) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: CommoditySpotPricePatchDTO) =>
      await patchCommoditySpotPrice({
        commodityId,
        commoditySpotPriceId,
        patch,
      }),
    onSettled() {
      invalidator.invalidateKeys(queryKeys.commoditySpotPrice.all);
    },
  });
}

async function fetchCommoditySpotPrices(commodityId: string) {
  return await CommodityService.getCommoditySpotPrices(commodityId);
}

export function useCommoditySpotPrices(commodityId: CommodityId) {
  return useQuery({
    queryKey: queryKeys.commoditySpotPrice.byCommodityId(commodityId),
    queryFn: () => fetchCommoditySpotPrices(commodityId),
  });
}

async function fetchCommoditySpotPrice(
  ctx: QueryFunctionContexts['commoditySpotPrice']['detail'],
) {
  const [{ commodityId, commoditySpotPriceId }] = ctx.queryKey;
  if (!commodityId) {
    throw new Error('Commodity Id must be defined.');
  }
  if (!commoditySpotPriceId) {
    throw new Error('Commodity Spot Price Id must be defined.');
  }
  const commoditySpotPrice = await CommodityService.getCommoditySpotPrice(
    commodityId,
    commoditySpotPriceId,
  );

  return commoditySpotPrice;
}

export function useCommoditySpotPrice(
  commodityId: string | undefined,
  commoditySpotPriceId: string | undefined,
) {
  return useQuery({
    queryKey: queryKeys.commoditySpotPrice.detail(
      commodityId,
      commoditySpotPriceId,
    ),
    queryFn: fetchCommoditySpotPrice,
    enabled: !!commodityId && !!commoditySpotPriceId,
  });
}

async function deleteCommoditySpotPrice(
  commodityId: CommodityId,
  commoditySpotPriceId: CommoditySpotPriceId,
) {
  await CommodityService.deleteCommoditySpotPrice(
    commodityId,
    commoditySpotPriceId,
  );
}

export function useDeleteCommoditySpotPrice(commodityId: CommodityId) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: (commoditySpotPriceId: CommoditySpotPriceId) =>
      deleteCommoditySpotPrice(commodityId, commoditySpotPriceId),
    onSettled() {
      invalidator.invalidateKeys(queryKeys.commoditySpotPrice.all);
    },
  });
}

async function createCommoditySpotPrice(
  commodityGuid: string,
  dto: CommoditySpotPriceCreationDTO,
) {
  await CommodityService.createCommoditySpotPrice(commodityGuid, dto);
}

export function useCreateCommoditySpotPrice(commodityId: CommodityId) {
  const invalidator = useQueryKeyInvalidator();

  return useMutation({
    mutationFn: (dto: CommoditySpotPriceCreationDTO) =>
      createCommoditySpotPrice(commodityId, dto),
    onSettled() {
      invalidator.invalidateKeys(queryKeys.commoditySpotPrice.all);
    },
  });
}
