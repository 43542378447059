import { Loader, Select, SelectProps } from '@mantine/core';
import { useRecoveryGoals } from '../api/recoveryGoal';
import { RecoveryGoalId } from '../rest-client';

export interface RecoveryGoalSelectProps
  extends Omit<
    SelectProps,
    | 'value'
    | 'onChange'
    | 'data'
    | 'disabled'
    | 'placeholder'
    | 'searchable'
    | 'hoverOnSearchChange'
    | 'nothingFound'
    | 'rightSection'
  > {
  value: RecoveryGoalId | null;
  onChange: (id: RecoveryGoalId | null) => void;
}
export function RecoveryGoalSelect(props: RecoveryGoalSelectProps) {
  const {
    value: selectedId,
    onChange: onSelectedIdChange,
    ...otherProps
  } = props;
  const {
    data: recoveryGoals,
    isError,
    error,
    isLoading,
    isFetching,
  } = useRecoveryGoals();

  if (isError) {
    throw error;
  }

  return (
    <Select
      data={
        recoveryGoals?.map(({ name, id }) => ({ value: id, label: name })) ?? []
      }
      disabled={isLoading}
      placeholder={isLoading ? 'Loading...' : 'Select a recovery goal'}
      value={selectedId}
      onChange={onSelectedIdChange}
      searchable
      nothingFound='No goals found'
      rightSection={isLoading || isFetching ? <Loader size='xs' /> : undefined}
      {...otherProps}
    />
  );
}
