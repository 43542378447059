import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { RedWaveSortProgramDetail } from '../SortProgram/redwave/RedWaveSortProgramDetail';
import { The404 } from '../The404';
import { Router } from '../router';
import { SortSystemDetail } from './SortSystemDetail';

export function SortSystemArea() {
  const route = Router.useRoute([
    'SortSystemDetail',
    'SortSystemRedWaveSortProgramDetail',
  ]);
  if (route === undefined) {
    return <The404 />;
  }
  return match(route)
    .with({ name: 'SortSystemDetail' }, ({ params: { sortSystemId } }) => (
      <SortSystemDetail sortSystemId={sortSystemId} />
    ))
    .with(
      { name: 'SortSystemRedWaveSortProgramDetail' },
      ({ params: { sortSystemId, sortProgramNumber, timestamp } }) => (
        <RedWaveSortProgramDetail
          sortSystemId={sortSystemId}
          sortProgramNumber={Number(sortProgramNumber)}
          timestamp={timestamp !== undefined ? dayjs.utc(timestamp) : undefined}
        />
      ),
    )
    .exhaustive();
}
