import { useState } from 'react';
import { match } from 'ts-pattern';
import { FeedFlowGroupIdName } from '../../../../FeedFlowGroup/FeedFlowGroupName';
import { FormatTimeWithUnits } from '../../../../Temporal/FormatTimeWithUnits';
import { plainDateToStartOfDayInstant } from '../../../../Temporal/dateRangeAndOffset';
import Temporal from '../../../../Temporal/temporal';
import NetWeight from '../../../../Weights/NetWeight';
import { NetWeightThroughputWithUnits } from '../../../../Weights/NetWeightThroughputWithUnits';
import { useProducedCommodityMasses } from '../../../../api/commodity';
import {
  ProducedCommodityMassesDTO,
  ProducedCommodityPortFlowGroupDTO,
} from '../../../../rest-client';
import { getWeightFromNetWeight } from '../../../../util/weightFromNetWeight';
import { useFacilityContext } from '../../../FacilityContext';
import { useInsightsSelectedDateRange } from '../../DateRange/InsightsDateRange';
import {
  MetricDetailPageTemplate,
  MetricDetailTableColumn,
} from '../MetricDetailPageTemplate';

export function getSeriesData(
  producedCommodityMass: ProducedCommodityMassesDTO,
) {
  const commodityName = producedCommodityMass.commodity.name.toLowerCase();
  return [
    {
      name: commodityName,
      entries: producedCommodityMass.knownWeightProducingFeedFlowGroups
        .sort((ffgA, ffgB) =>
          ffgA.feedFlowGroupStart < ffgB.feedFlowGroupStart ? -1 : 1,
        )
        .map((ffg) => {
          return {
            timestamp: ffg.feedFlowGroupStart,
            name: commodityName,
            value: getWeightFromNetWeight(ffg.massMetrics.producedWeight),
          };
        }),
    },
  ];
}

export function MassThroughputCommoditySubMetricPage({
  commodityId,
}: {
  commodityId: string;
}) {
  const { timeZoneId: tz, id: facilityId } = useFacilityContext();
  const [nowZonedDateTime] = useState(Temporal.Now.zonedDateTimeISO(tz));
  const thirtyDaysAgo = nowZonedDateTime.subtract({ days: 30 });
  const { dateRange, setDateRange } = useInsightsSelectedDateRange({
    startDate: thirtyDaysAgo.toPlainDate(),
    endDate: nowZonedDateTime.toPlainDate(),
    name: 'Last 30 Days',
    id: 'd30',
  });

  const { startDate, endDate } = dateRange;
  const rangeInstants = {
    startInstant: plainDateToStartOfDayInstant(startDate, tz),
    // TODO end of day, probably
    endInstant: plainDateToStartOfDayInstant(endDate, tz),
  };
  const { startInstant, endInstant } = rangeInstants;
  const query = useProducedCommodityMasses({
    after: startInstant,
    before: endInstant,
    facilityId,
  });

  const { commodityName, seriesData, tableData } = match(query)
    .with({ status: 'loading' }, () => ({
      loading: true,
      error: null,
      commodityName: '',
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'error' }, (query) => ({
      loading: false,
      error: query.error as Error,
      commodityName: 'Unknown',
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'success' }, (query) => {
      const metricData = query.data.producedCommodityMasses.find(
        (producedCommodityMass) =>
          producedCommodityMass.commodity.id === commodityId,
      );
      if (!metricData) {
        return {
          loading: false,
          error: new ReferenceError('Metric facet not found'),
          commodityName: 'Unknown',
          seriesData: [],
          tableData: [],
        };
      }
      return {
        loading: false,
        error: null,
        commodityName: metricData.commodity.name,
        seriesData: getSeriesData(metricData),
        tableData: metricData.knownWeightProducingFeedFlowGroups,
      };
    })
    .exhaustive();

  interface RenderColumnArgs {
    producedCommodityFFG: ProducedCommodityPortFlowGroupDTO;
    tz?: string;
  }

  type CommoditySubMetricMassThroughputColumn = MetricDetailTableColumn & {
    renderTableValue: ({
      producedCommodityFFG,
      tz,
    }: Required<RenderColumnArgs>) => JSX.Element;
  };

  const columns: CommoditySubMetricMassThroughputColumn[] = [
    {
      title: 'Process Run',
      numeric: false,
      renderTableValue: ({
        producedCommodityFFG,
      }: Required<RenderColumnArgs>) => (
        <td key='ffg'>
          <FeedFlowGroupIdName
            feedFlowGroupId={producedCommodityFFG.feedFlowGroupId.value}
          />
        </td>
      ),
    },
    {
      title: 'Active Time',
      numeric: true,
      renderTableValue: ({ producedCommodityFFG }: RenderColumnArgs) => (
        <td key='time' style={{ textAlign: 'right' }}>
          <FormatTimeWithUnits
            totalMinutes={producedCommodityFFG.activeMinutes}
            verbosity='normal'
          />
        </td>
      ),
    },
    {
      title: 'Produced Weight',
      numeric: true,
      renderTableValue: ({ producedCommodityFFG }: RenderColumnArgs) => {
        return (
          <td key='produced' style={{ textAlign: 'right' }}>
            <NetWeight
              weight={producedCommodityFFG.massMetrics.producedWeight}
              sourceIconMode='icon-tooltip'
            />
          </td>
        );
      },
    },
    {
      title: 'Throughput',
      numeric: true,
      renderTableValue: ({ producedCommodityFFG }: RenderColumnArgs) => {
        return (
          <td key='throughput' style={{ textAlign: 'right' }}>
            <NetWeightThroughputWithUnits
              netWeightPerHour={
                producedCommodityFFG.massMetrics
                  .averageProducedWeightPerActiveHour
              }
              verbosity='normal'
            />
          </td>
        );
      },
    },
  ];

  const renderTableRow = (
    producedCommodityFFG: ProducedCommodityPortFlowGroupDTO,
  ) => {
    return (
      <tr key={producedCommodityFFG.feedFlowGroupId.value}>
        {columns.map((col) => {
          return col.renderTableValue({ producedCommodityFFG, tz });
        })}
      </tr>
    );
  };

  return (
    <MetricDetailPageTemplate<ProducedCommodityPortFlowGroupDTO>
      dateRange={dateRange}
      setDateRange={setDateRange}
      title={`${commodityName} Mass Throughput`}
      graphTitle={`${commodityName} Throughput`}
      tableTitle={`${commodityName} Mass Throughput by Process Run`}
      tableColumns={columns}
      tableData={tableData}
      seriesData={seriesData}
      renderTableRow={renderTableRow}
      query={query}
      yAxisLabel='Lbs/Hr'
    />
  );
}
