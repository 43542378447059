import { match } from 'ts-pattern';
import { InternallySinkedMaterialDetailsPage } from '../InternalSinkContainerTransferredMaterials/InternallySinkedMaterialDetailsPage';
import { The404 } from '../The404';
import { Router } from '../router';
import { InternalMaterialSinkDetailPage } from './InternalMaterialSinkDetailPage';
import { InternalMaterialSinksPage } from './InternalMaterialSinksPage';

export function InternalSinkArea() {
  const route = Router.useRoute([
    'InternalSinkList',
    'InternalSinkDetail',
    'InternalSinkTransferDetail',
  ]);
  if (route === undefined) {
    return <The404 />;
  }
  return match(route)
    .with({ name: 'InternalSinkList' }, () => <InternalMaterialSinksPage />)
    .with(
      { name: 'InternalSinkDetail' },
      ({ params: { internalMaterialSinkId } }) => (
        <InternalMaterialSinkDetailPage
          internalMaterialSinkId={internalMaterialSinkId}
        />
      ),
    )
    .with(
      { name: 'InternalSinkTransferDetail' },
      ({ params: { sinkTransferId } }) => (
        <InternallySinkedMaterialDetailsPage sinkTransferId={sinkTransferId} />
      ),
    )
    .exhaustive();
}
