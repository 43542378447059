/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChutecSortSystemDTO } from '../models/ChutecSortSystemDTO';
import type { RedWaveSortSystemDTO } from '../models/RedWaveSortSystemDTO';
import type { RedWaveSystemConfigurationDTO } from '../models/RedWaveSystemConfigurationDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SortSystemService {
    /**
     * @param facilityGuid
     * @returns any Success
     * @throws ApiError
     */
    public static allSortSystems(
        facilityGuid?: string,
    ): CancelablePromise<Array<(RedWaveSortSystemDTO | ChutecSortSystemDTO)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sort-systems',
            query: {
                'facilityGuid': facilityGuid,
            },
        });
    }
    /**
     * @param sortSystemGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getSortSystemById(
        sortSystemGuid: string,
    ): CancelablePromise<(RedWaveSortSystemDTO | ChutecSortSystemDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sort-systems/{sortSystemGuid}',
            path: {
                'sortSystemGuid': sortSystemGuid,
            },
        });
    }
    /**
     * @param sortSystemGuid
     * @param timestamp
     * @returns any Success
     * @throws ApiError
     */
    public static getActiveConfiguration(
        sortSystemGuid: string,
        timestamp?: string,
    ): CancelablePromise<RedWaveSystemConfigurationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sort-systems/{sortSystemGuid}/active-configuration',
            path: {
                'sortSystemGuid': sortSystemGuid,
            },
            query: {
                'timestamp': timestamp,
            },
        });
    }
}
