import dayjs from 'dayjs';
import { useFacilityContext } from '../Facility/FacilityContext';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import {
  usePatchProcessBufferDepletion,
  useProcessBufferDepletion,
} from '../api/processBufferDepletion';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import {
  ProcessBufferDepletionId,
  ProcessBufferDepletionPatchDTO,
} from '../rest-client';
import {
  ProcessBufferDepletionFormFields,
  ProcessBufferDepletionFormValues,
  useProcessBufferDepletionForm,
} from './ProcessBufferDepletionForm';

export type EditProcessBufferDepletionDrawerFormProps = {
  processBufferDepletionId: ProcessBufferDepletionId;
  onSuccess?: (processBufferDepletion: ProcessBufferDepletionPatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditProcessBufferDepletionDrawerForm(
  props: EditProcessBufferDepletionDrawerFormProps,
) {
  const {
    processBufferDepletionId,
    onSuccess,
    onClose,
    ...drawerFormDrawerProps
  } = props;

  const facility = useFacilityContext();
  const {
    data: processBufferDepletion,
    isLoadingError,
    error,
  } = useProcessBufferDepletion(processBufferDepletionId);
  const patchMutation = usePatchProcessBufferDepletion(
    processBufferDepletionId,
  );
  const form = useProcessBufferDepletionForm({});

  useSetFormInitialValuesFromQuery(
    form,
    processBufferDepletion && {
      depletedAt: dayjs
        .utc(processBufferDepletion.effectiveTimestamp)
        .tz(facility.timeZoneId),
      processId: processBufferDepletion.processId,
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({
    depletedAt,
    processId,
  }: ProcessBufferDepletionFormValues) => {
    if (processId === null) {
      throw new Error('Process cannot be null ');
    }

    const processBufferDepletionPatch: ProcessBufferDepletionPatchDTO = {
      ...(form.isDirty('depletedAt') && {
        effectiveTimestamp: depletedAt.utc().toISOString(),
      }),
      ...(form.isDirty('processId') && { processId }),
    };

    return processBufferDepletionPatch;
  };

  return (
    <DrawerForm
      entityName='Feedstock Depletion'
      form={form}
      drawerFormVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <ProcessBufferDepletionFormFields form={form} />
    </DrawerForm>
  );
}
