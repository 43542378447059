import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import {
  useInternalMaterialSink,
  usePatchInternalMaterialSink,
} from '../api/internalMaterialSink';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import {
  InternalMaterialSinkId,
  InternalMaterialSinkPatchDTO,
} from '../rest-client';
import {
  InternalMaterialSinkFormFields,
  InternalMaterialSinkFormValues,
  useInternalMaterialSinkForm,
} from './InternalMaterialSinkForm';

export type EditInternalMaterialSinkDrawerFormProps = {
  internalMaterialSinkId: InternalMaterialSinkId;
  onSuccess?: (internalMaterialSink: InternalMaterialSinkPatchDTO) => void;
} & DrawerFormDrawerProps;

export function EditInternalMaterialSinkDrawerForm(
  props: EditInternalMaterialSinkDrawerFormProps,
) {
  const {
    internalMaterialSinkId,
    onSuccess,
    onClose,
    ...drawerFormDrawerProps
  } = props;

  const {
    data: internalMaterialSink,
    isLoadingError,
    error,
  } = useInternalMaterialSink(internalMaterialSinkId);
  const patchMutation = usePatchInternalMaterialSink(internalMaterialSinkId);
  const form = useInternalMaterialSinkForm();

  useSetFormInitialValuesFromQuery(
    form,
    internalMaterialSink && {
      name: internalMaterialSink.name,
      facilityId: internalMaterialSink.facilityId,
    },
  );

  if (isLoadingError) {
    throw error;
  }

  const formValuesToDto = ({
    name,
    facilityId,
  }: InternalMaterialSinkFormValues) => {
    if (facilityId === null) {
      throw new Error('Facility cannot be null');
    }
    const internalMaterialSinkPatch: InternalMaterialSinkPatchDTO = {
      ...(form.isDirty('name') && { name }),
      ...(form.isDirty('facilityId') && { facilityId }),
    };

    return internalMaterialSinkPatch;
  };

  return (
    <DrawerForm
      entityName='Material Export Destination'
      form={form}
      drawerFormVariant='edit'
      mutation={patchMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <InternalMaterialSinkFormFields form={form} />
    </DrawerForm>
  );
}
