import { match } from 'ts-pattern';
import { The404 } from '../The404';
import { Router } from '../router';

export function CustomerArea() {
  const route = Router.useRoute([]);
  if (route === undefined) {
    return <The404 />;
  }
  return match(route).exhaustive();
}
