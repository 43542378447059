import { useMutation, useQuery } from '@tanstack/react-query';
import {
  SchupanRimasService,
  SchupanRimasSnapshotPatchDTO,
} from '../rest-client';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchSchupanRimasSnapshots() {
  return await SchupanRimasService.getSchupanRimasSnapshots();
}

export function useSchupanRimasSnapshots() {
  return useQuery({
    queryKey: queryKeys.schupanRimasSnapshot.list(),
    queryFn: fetchSchupanRimasSnapshots,
  });
}

async function patchSchupamRimasSnapshot(args: {
  id: number;
  patch: SchupanRimasSnapshotPatchDTO;
}) {
  await SchupanRimasService.patchSchupanRimasSnapshot(args.id, args.patch);
}

export function usePatchSchupanRimasSnapshot() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchSchupamRimasSnapshot,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.schupanRimasSnapshot.all,
        queryKeys.schupanRimasSnapshotAnalysis.all,
      );
    },
  });
}

async function createSchupanRimasSnapshot(args: {
  controlCsv: string;
  tagCsv: string;
}) {
  await SchupanRimasService.createSchupanRimasSnapshot(args);
}

export function useCreateSchupanRimasSnapshot() {
  const invalidator = useQueryKeyInvalidator();

  return useMutation({
    mutationFn: createSchupanRimasSnapshot,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.schupanRimasSnapshot.all,
        queryKeys.schupanRimasSnapshotAnalysis.all,
      );
    },
  });
}

async function fetchSchupanRimasSnapshotAnalysis() {
  return await SchupanRimasService.getSchupanRimasSnapshotReport();
}

export function useSchupanRimasSnapshotAnalysis() {
  return useQuery({
    queryKey: queryKeys.schupanRimasSnapshotAnalysis.curent(),
    queryFn: fetchSchupanRimasSnapshotAnalysis,
  });
}
