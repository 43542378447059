import { useMutation, useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import {
  MaterialSetDTO,
  MaterialTruckLoadCreationDTO,
  MaterialTruckLoadDTO,
  MaterialTruckLoadTransferCreationDTO,
  TruckLoadService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchTruckLoad(
  ctx: QueryFunctionContexts['truckLoad']['detail'],
) {
  const [{ truckLoadId }] = ctx.queryKey;
  return await TruckLoadService.getTruckLoadById(truckLoadId);
}

export function useTruckLoad(truckLoadId: string) {
  return useQuery({
    queryKey: queryKeys.truckLoad.detail(truckLoadId),
    queryFn: fetchTruckLoad,
  });
}

async function fetchTruckLoads() {
  return await TruckLoadService.getAllTruckLoads();
}

export function useTruckLoads() {
  return useQuery({
    queryKey: queryKeys.truckLoad.list(),
    queryFn: fetchTruckLoads,
  });
}

async function addTruckLoad(dto: MaterialTruckLoadCreationDTO) {
  await TruckLoadService.createTruckLoad(dto);
}

export function useAddTruckLoad() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: addTruckLoad,
    onSettled() {
      invalidator.invalidateKeys(queryKeys.truckLoad.list());
    },
  });
}

async function patchTruckLoad({
  truckLoadId,
  patch,
}: {
  truckLoadId: string;
  patch: MaterialTruckLoadDTO;
}) {
  await TruckLoadService.patchTruckLoad(truckLoadId, patch);
}

export function usePatchTruckLoad() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchTruckLoad,
    onSettled(data, error, { truckLoadId }) {
      invalidator.invalidateKeys(
        queryKeys.truckLoad.list(),
        queryKeys.truckLoad.detail(truckLoadId),
      );
    },
  });
}

async function deleteTruckLoad(truckLoadId: string) {
  await TruckLoadService.deleteTruckLoadById(truckLoadId);
}

export function useDeleteTruckLoad() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteTruckLoad,
    onSuccess(data, truckLoadId) {
      invalidator.removeKey(queryKeys.truckLoad.detail(truckLoadId));
    },
    onError(error, truckLoadId) {
      invalidator.resetKey(queryKeys.truckLoad.detail(truckLoadId));
    },
    onSettled() {
      invalidator.invalidateKeys(queryKeys.truckLoad.list());
    },
  });
}

async function addTruckLoadTransfer(dto: MaterialTruckLoadTransferCreationDTO) {
  await TruckLoadService.createTruckLoadTransfer(dto);
}

export function useAddTruckLoadTransfer() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: addTruckLoadTransfer,
    onSettled() {
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function deleteTruckLoadTransfer(id: string) {
  await TruckLoadService.deleteTruckLoadTransfer(id);
}

export function useDeleteTruckLoadTransfer() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteTruckLoadTransfer,
    onSettled() {
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function fetchTruckLoadGenealogy(
  ctx: QueryFunctionContexts['genealogy']['truckLoad'],
) {
  const [{ truckLoadId, timestamp }] = ctx.queryKey;
  return await TruckLoadService.getTruckLoadGenealogy(
    truckLoadId,
    timestamp?.utc().toISOString(),
  );
}

export function useTruckLoadGenealogy(truckLoadId: string, timestamp?: Dayjs) {
  return useQuery({
    queryKey: queryKeys.genealogy.truckLoad(truckLoadId, timestamp),
    queryFn: fetchTruckLoadGenealogy,
  });
}

async function fetchOccupiedTruckLoadContents(
  ctx: QueryFunctionContexts['truckLoad']['occupiedStates'],
) {
  const [{ timestamp }] = ctx.queryKey;
  return await TruckLoadService.getOccupiedTruckLoadContents(
    timestamp?.utc().toISOString(),
  );
}

export function useOccupiedTruckLoadContents(timestamp: Dayjs | undefined) {
  return useQuery({
    queryKey: queryKeys.truckLoad.occupiedStates(timestamp),
    queryFn: fetchOccupiedTruckLoadContents,
  });
}

export type TruckLoadContentsLookupState =
  | {
      status: 'loading';
    }
  | { status: 'request-error' }
  | { status: 'ledger-error' }
  | { status: 'empty' }
  | { status: 'occupied'; materialSet: MaterialSetDTO };

export function useTruckLoadContentsLookup(timestamp: Dayjs | undefined) {
  const occupiedTruckLoadContentsQuery =
    useOccupiedTruckLoadContents(timestamp);

  return (truckLoadId: string): TruckLoadContentsLookupState => {
    if (occupiedTruckLoadContentsQuery.isError) {
      return { status: 'request-error' };
    }

    if (occupiedTruckLoadContentsQuery.isLoading) {
      return { status: 'loading' };
    }

    if (occupiedTruckLoadContentsQuery.data.status === 'ledger-error') {
      return { status: 'ledger-error' };
    }

    if (
      truckLoadId in
      occupiedTruckLoadContentsQuery.data.occupiedTruckLoadMaterialSets
    ) {
      return {
        status: 'occupied',
        materialSet:
          occupiedTruckLoadContentsQuery.data.occupiedTruckLoadMaterialSets[
            truckLoadId
          ],
      };
    }

    return { status: 'empty' };
  };
}
