import { Box, Drawer } from '@mantine/core';
import { ScrollAreaComponent } from '@mantine/core/lib/Drawer/Drawer.context';
import { useDisclosure } from '@mantine/hooks';
import { FormActionButton } from '../Form/FormActionButton';
import { ScaleId, ScaleReadingId } from '../rest-client';
import { EditScaleReadingForm } from './EditScaleReadingForm';

export interface EditScaleReadingButtonProps {
  scaleId: ScaleId;
  scaleReadingId: ScaleReadingId;
}

export function EditScaleReadingButton(props: EditScaleReadingButtonProps) {
  const { scaleId, scaleReadingId } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Drawer
        size='lg'
        opened={drawerOpened}
        onClose={closeDrawer}
        title='Edit Scale Reading'
        position='right'
        styles={{ inner: { height: '100%' } }}
        scrollAreaComponent={Box as ScrollAreaComponent}
      >
        <EditScaleReadingForm
          scaleId={scaleId}
          scaleReadingId={scaleReadingId}
          onCancel={closeDrawer}
          onSuccess={closeDrawer}
        />
      </Drawer>

      <FormActionButton
        action='edit'
        size='sm'
        compact
        variant='outline'
        onClick={() => openDrawer()}
      />
    </>
  );
}
