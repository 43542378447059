import { Box, Group, Skeleton, Text } from '@mantine/core';
import { IconQuestionMark } from '@tabler/icons-react';
import { Fragment } from 'react';
import { P, match } from 'ts-pattern';
import { InferredPropertyWrapper } from '../InferredPropertyWrapper';
import { DeleteScaleReadingButton } from '../Scale/DeleteScaleReadingButton';
import { EditScaleReadingButton } from '../Scale/EditScaleReadingButton';
import NetWeight from '../Weights/NetWeight';
import { WithUnit } from '../WithUnit';
import { useInferredProperties } from '../api/inference';
import { InferredPropertiesDTO, MaterialSetDTO } from '../rest-client';
import { MassClaimText } from './MassClaimText';
import cssClasses from './MaterialSetMass.module.css';

const InferredMassText = (props: {
  inferredProperties: InferredPropertiesDTO;
  showIcon?: boolean;
  unknownVerbose?: boolean;
}) => {
  const { inferredProperties, unknownVerbose, showIcon } = props;
  const netWeight = inferredProperties.mass;

  if (unknownVerbose && netWeight === null) {
    return <Text color='dimmed'>unknown</Text>;
  }

  return (
    <InferredPropertyWrapper
      showIcon={showIcon}
      color={netWeight?.totalIsNegative ? 'red' : undefined}
      hoverContent={undefined}
    >
      {netWeight ? (
        <NetWeight weight={netWeight} sourceIconMode='icon-tooltip' />
      ) : (
        <Text color='dimmed'>?</Text>
      )}
    </InferredPropertyWrapper>
  );
};

const InferredMassTextSkeleton = () => (
  <InferredPropertyWrapper>
    <WithUnit unit='lbs'>
      <Skeleton visible>
        <Text>123,456</Text>
      </Skeleton>
    </WithUnit>
  </InferredPropertyWrapper>
);

const InferredMassError = () => (
  <InferredPropertyWrapper>
    <WithUnit unit='lbs'>
      <IconQuestionMark color='red' />
    </WithUnit>
  </InferredPropertyWrapper>
);

InferredMassText.Skeleton = InferredMassTextSkeleton;
InferredMassText.Error = InferredMassError;

function MaterialSetMassInferredPart(props: {
  materialSet: MaterialSetDTO;
  showIcon?: boolean;
  hideIfNothing?: boolean;
  unknownVerbose?: boolean;
}) {
  const {
    materialSet,
    showIcon,
    hideIfNothing = false,
    unknownVerbose,
  } = props;
  const { data: inferredProperties, isLoading } =
    useInferredProperties(materialSet);

  if (inferredProperties) {
    // Refetch errors aren't rendering anything differently, which is fine
    if (hideIfNothing && inferredProperties.mass === null) {
      return <></>;
    }
    return (
      <InferredMassText
        inferredProperties={inferredProperties}
        showIcon={showIcon}
        unknownVerbose={unknownVerbose}
      />
    );
  }

  if (isLoading) {
    return <InferredMassText.Skeleton />;
  }

  return <InferredMassText.Error />;
}

export interface MaterialSetMassProps {
  materialSet: MaterialSetDTO;
  showBoth?: boolean;
  showAllClaims?: boolean;
  unknownVerbose?: boolean;
}

export function MaterialSetMass(props: MaterialSetMassProps) {
  const {
    materialSet,
    showBoth = false,
    showAllClaims = false,
    unknownVerbose,
  } = props;

  const bestMassClaim = materialSet.massClaims.at(0);

  const renderedClaims = showAllClaims
    ? materialSet.massClaims
    : bestMassClaim
      ? [bestMassClaim]
      : [];
  return (
    <Box className={cssClasses.wrapper}>
      <Group spacing='xs' noWrap>
        {renderedClaims.map((c, i) => (
          <MassClaimText key={i} massClaim={c} />
        ))}
      </Group>

      {!bestMassClaim || showBoth ? (
        <MaterialSetMassInferredPart
          materialSet={materialSet}
          hideIfNothing={bestMassClaim !== undefined}
          unknownVerbose={unknownVerbose}
        />
      ) : undefined}
    </Box>
  );
}

interface AllMaterialSetMassProps {
  materialSet: MaterialSetDTO;
  showInferredIcon?: boolean;
  showDeleteButton?: boolean;
}

export function AllMaterialSetMasses({
  materialSet,
  showInferredIcon,
  showDeleteButton,
}: AllMaterialSetMassProps) {
  return (
    <Group>
      {materialSet.massClaims.map((claim, i) => (
        <Fragment key={i}>
          <MassClaimText massClaim={claim} />
          {showDeleteButton
            ? match(claim)
                .with(
                  {
                    kind: P.union(
                      'ContainerScaleReading',
                      'TruckLoadScaleReading',
                    ),
                  },
                  (scaleReading) => (
                    <Group>
                      <EditScaleReadingButton
                        scaleId={scaleReading.scaleId}
                        scaleReadingId={scaleReading.scaleReadingId}
                      />
                      <DeleteScaleReadingButton
                        scaleId={scaleReading.scaleId}
                        scaleReadingId={scaleReading.scaleReadingId}
                      />
                    </Group>
                  ),
                )
                // TODO (964): implement other delete mass claim variants
                .otherwise(() => undefined)
            : undefined}
        </Fragment>
      ))}
      <MaterialSetMassInferredPart
        materialSet={materialSet}
        showIcon={showInferredIcon}
        hideIfNothing={materialSet.massClaims.length > 0}
      />
    </Group>
  );
}
