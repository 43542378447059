import { Divider, Loader, Stack, Title } from '@mantine/core';
import { useSortSystem } from '../../api/sortSystem';
import Temporal from '../../Temporal/temporal.ts';
import { FlowDTO } from '../../rest-client';
import { DifferentialSystemMetrics } from './DifferentialSystemMetrics';

export function FlowEdgeMetrics(props: {
  flow: FlowDTO;
  startTime: Temporal.Instant;
  endTime: Temporal.Instant;
}) {
  const { flow, startTime, endTime } = props;
  const outputSystemQuery = useSortSystem(flow.sourceSystemId);
  const inputSystemQuery = useSortSystem(flow.destinationSortSystemId);

  return (
    <Stack>
      <Title order={3}>Source System Metrics</Title>
      {outputSystemQuery.isLoading && <Loader />}
      {outputSystemQuery.data && (
        <DifferentialSystemMetrics
          system={outputSystemQuery.data}
          port={{ type: 'output', portId: flow.sourceOutputPortId }}
          startTime={startTime}
          endTime={endTime}
        />
      )}
      <Divider my='md' />
      <Title order={3}>Destination System Metrics</Title>
      {inputSystemQuery.isLoading && <Loader />}
      {inputSystemQuery.data && (
        <DifferentialSystemMetrics
          system={inputSystemQuery.data}
          port={{ type: 'input' }}
          startTime={startTime}
          endTime={endTime}
        />
      )}
    </Stack>
  );
}
