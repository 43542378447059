import {
  Alert,
  Button,
  Group,
  Skeleton,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { P, match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { DeleteIcon, EditIcon, PurchaseIcon } from '../Icons';
import { LinkButton } from '../Link';
import { PurchasedMaterialTable } from '../PurchasedMaterial/PurchasedMaterialTable';
import { useDeleteVendor, useVendor } from '../api/vendor';
import { LabeledValue } from '../common';
import { Router } from '../router';

export function VendorDetail({ vendorId }: { vendorId: string }) {
  const vendorQuery = useVendor(vendorId);
  const deleteVendorMutation = useDeleteVendor();
  const breadcrumbs = [{ routeName: Router.VendorList(), title: 'Vendors' }];
  const vendor = vendorQuery.data;

  if (vendorQuery.isLoadingError) {
    return (
      <AppPage breadcrumbs={breadcrumbs}>
        <Alert color='red' title='Error Loading Vendor'>
          An error occurred loading this vendor.
        </Alert>
      </AppPage>
    );
  }

  return (
    <AppPage breadcrumbs={breadcrumbs} title={vendor?.name ?? null}>
      <AppPage.Section>
        <Stack>
          <Group position='apart'>
            <Title order={2}>Vendor Details</Title>
            <Group>
              <Button
                leftIcon={<EditIcon />}
                onClick={() => {
                  Router.push('VendorEdit', {
                    vendorId,
                  });
                }}
              >
                Edit
              </Button>
              <Button
                leftIcon={<DeleteIcon />}
                color='red'
                disabled={vendor === undefined}
                onClick={() => {
                  if (vendor === undefined) {
                    throw new Error('vendor must be defined');
                  }
                  deleteVendorMutation.mutate(vendor, {
                    onError(data, variables) {
                      showNotification({
                        title: 'Error Deleting Vendor',
                        message: `An error occurred deleting ${variables.name}. This could be due to ${variables.name} having associated Purchased Materials or it could be an internal error.`,
                        color: 'red',
                        icon: <IconX />,
                      });
                    },
                    onSuccess(data, variables) {
                      Router.replace('VendorList');
                      showNotification({
                        title: 'Vendor Deleted',
                        message: `${variables.name} was successfully deleted.`,
                        color: 'green',
                        icon: <IconCheck />,
                      });
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Group>
          </Group>
          <Stack align='flex-start'>
            <LabeledValue label='Contact Name'>
              <Skeleton visible={vendorQuery.isLoading}>
                {match(vendor?.contactName)
                  .with(undefined, () => <Text>Loading...</Text>)
                  .with(null, () => <Text c='dimmed'>None</Text>)
                  .with(P.string, (contactName) => <Text>{contactName}</Text>)
                  .exhaustive()}
              </Skeleton>
            </LabeledValue>
            <LabeledValue label='Location'>
              <Skeleton visible={vendorQuery.isLoading}>
                {match(vendor?.location)
                  .with(undefined, () => <Text>Loading...</Text>)
                  .with(null, () => <Text c='dimmed'>None</Text>)
                  .with(P.string, (location) => <Text>{location}</Text>)
                  .exhaustive()}
              </Skeleton>
            </LabeledValue>
          </Stack>
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Group position='apart'>
            <Title order={2}>Purchased Materials</Title>
            <LinkButton
              to={Router.VendorPurchasedMaterialCreate({ vendorId })}
              leftIcon={<PurchaseIcon />}
            >
              Add Purchase
            </LinkButton>
          </Group>
          <PurchasedMaterialTable vendorId={vendorId} />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
