const contrastColor = '#eee';
const axisCommon = function () {
  return {
    axisLine: {
      lineStyle: {
        color: contrastColor,
      },
    },
    axisTick: {
      lineStyle: {
        color: contrastColor,
      },
    },
    axisLabel: {
      color: contrastColor,
    },
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: '#aaa',
      },
    },
    splitArea: {
      areaStyle: {
        color: contrastColor,
      },
    },
  };
};

const colorPalette = [
  '#458c6b',
  '#f2da87',
  '#d9a86c',
  '#d94436',
  '#a62424',
  '#76bc9b',
  '#cce6da',
  '#eeeeee',
];
export default {
  color: colorPalette,
  backgroundColor: '#1a1b1e',
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: contrastColor,
      },
      crossStyle: {
        color: contrastColor,
      },
    },
  },
  legend: {
    textStyle: {
      color: contrastColor,
    },
  },
  title: {
    textStyle: {
      color: contrastColor,
    },
  },
  toolbox: {
    iconStyle: {
      borderColor: contrastColor,
    },
  },

  // Area scaling controller
  dataZoom: {
    dataBackgroundColor: '#eee', // Data background color
    fillerColor: 'rgba(200,200,200,0.2)', // Fill the color
    handleColor: '#458c6b', // Handle color
  },

  timeline: {
    itemStyle: {
      color: colorPalette[1],
    },
    lineStyle: {
      color: contrastColor,
    },
    controlStyle: {
      color: contrastColor,
      borderColor: contrastColor,
    },
    label: {
      color: contrastColor,
    },
  },

  timeAxis: axisCommon(),
  logAxis: axisCommon(),
  valueAxis: axisCommon(),
  categoryAxis: { ...axisCommon(), ...{ splitLine: { show: false } } },

  line: {
    symbol: 'circle',
  },
  graph: {
    color: colorPalette,
  },

  gauge: {
    axisLine: {
      lineStyle: {
        color: [
          [0.2, '#f2da87'],
          [0.8, '#458c6b'],
          [1, '#a62424'],
        ],
        width: 8,
      },
    },
  },
};
