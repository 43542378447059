import { useQuery } from '@tanstack/react-query';
import { MaterialStateService } from '../rest-client';
import { queryKeys } from './queryKeys';

async function fetchMaterialStateStatus() {
  return await MaterialStateService.getMaterialStateStatus();
}

export function useMaterialStateStatus() {
  return useQuery({
    queryKey: queryKeys.materialStateStatus,
    queryFn: fetchMaterialStateStatus,
    refetchInterval(data) {
      // If the material state
      if (data?.status === 'ready') {
        // TODO(2295): We should also do some kind of tracking on this status
        return 10_000;
      }
      return 1000; // TODO(2295): Is this too fast?
    },
  });
}
