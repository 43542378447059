import { Text } from '@mantine/core';
import { LinkText } from '../Link';
import { RecoveryGoalDTO } from '../rest-client';
import { Router } from '../router';

export interface RecoveryGoalNameProps {
  recoveryGoal: RecoveryGoalDTO;
  noLink?: boolean;
}

export function RecoveryGoalName(props: RecoveryGoalNameProps) {
  const { recoveryGoal, noLink } = props;
  if (noLink) {
    return <Text>{recoveryGoal.name}</Text>;
  }
  return (
    <LinkText
      to={Router.RecoveryGoalDetail({ recoveryGoalId: recoveryGoal.id })}
    >
      {recoveryGoal.name}
    </LinkText>
  );
}
