/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ControlTagsAnalysisDTO } from '../models/ControlTagsAnalysisDTO';
import type { SchupanRimasSnapshotCreationDTO } from '../models/SchupanRimasSnapshotCreationDTO';
import type { SchupanRimasSnapshotDTO } from '../models/SchupanRimasSnapshotDTO';
import type { SchupanRimasSnapshotPatchDTO } from '../models/SchupanRimasSnapshotPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SchupanRimasService {
    /**
     * @returns SchupanRimasSnapshotDTO Success
     * @throws ApiError
     */
    public static getSchupanRimasSnapshots(): CancelablePromise<Array<SchupanRimasSnapshotDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/schupan/rimas/snapshots',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createSchupanRimasSnapshot(
        requestBody: SchupanRimasSnapshotCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/schupan/rimas/snapshots',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param snapshotId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchSchupanRimasSnapshot(
        snapshotId: number,
        requestBody: SchupanRimasSnapshotPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/schupan/rimas/snapshots/{snapshotId}',
            path: {
                'snapshotId': snapshotId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ControlTagsAnalysisDTO Success
     * @throws ApiError
     */
    public static getSchupanRimasSnapshotReport(): CancelablePromise<ControlTagsAnalysisDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/schupan/rimas/snapshots/report',
        });
    }
}
