import { Paper, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { Handle, NodeProps, Position } from 'reactflow';
import { useFacilityContext } from '../Facility/FacilityContext';
import { LinkText } from '../Link';
import { useFeedFlowGroupMaterialSets } from '../api/feedFlowGroup';
import { useProcess } from '../api/process';
import { Router } from '../router';
import { FlowFeedFlowGroupNodeData } from './GenealogyGraph';
import { useGenealogyGraphCtx } from './GenealogyGraphContext';

export default function FeedFlowGroupNode(
  props: NodeProps<FlowFeedFlowGroupNodeData>,
) {
  const {
    data: { feedFlowGroupId },
  } = props;
  const {
    rootMaterialSet: { materialStateInferenceHash },
  } = useGenealogyGraphCtx();

  const tz = useFacilityContext().timeZoneId;

  const materialSetsQuery = useFeedFlowGroupMaterialSets({
    feedFlowGroupId,
    inferenceHash: materialStateInferenceHash,
  });

  const processQuery = useProcess(
    materialSetsQuery.data?.feedFlowGroup.processState.processId,
  );

  // TODO(2315): CSS
  // const { classes } = useNodeStyles({
  //   type: 'process-run',
  //   zIndex: props.zIndex,
  //   selected: props.selected,
  //   isOrigin: false,
  // });

  const outputPortNames = new Map<string, string>();
  if (processQuery.data) {
    for (const output of processQuery.data.outputs) {
      outputPortNames.set(output.outputPortId, output.name);
    }
  }
  // TODO(2315): Get output port names

  // TODO(2315): Add ancestor/descendant toggle
  return (
    <Paper
      bg='gray.2'
      p='sm'
      style={{ zIndex: props.zIndex, outline: '1px solid gray' }}
    >
      <Handle type='target' id='input' position={Position.Top} />

      <Stack spacing='xs'>
        <LinkText to={Router.FeedFlowGroupDetail({ feedFlowGroupId })}>
          {materialSetsQuery.data ? (
            <>
              {dayjs
                .utc(materialSetsQuery.data.feedFlowGroup.effectiveTimestamp)
                .tz(tz)
                .format('L LT')}{' '}
              <Text span>
                {materialSetsQuery.data.feedFlowGroup.processState.processName}
              </Text>
            </>
          ) : (
            '...'
          )}
        </LinkText>

        <Text size='lg'>
          {materialSetsQuery.data &&
            Object.values(
              materialSetsQuery.data.feedFlowGroup.processState
                .effectiveConfigurationChanges,
            ).map((configChange) => configChange.systemConfiguration.name)}
        </Text>
      </Stack>
      {[...outputPortNames].map(([outputPortId], i) => (
        <Handle
          key={i.toString()}
          style={{ left: `${((i + 1) / (outputPortNames.size + 1)) * 100}%` }}
          id={outputPortId}
          type='source'
          position={Position.Bottom}
        />
      ))}
    </Paper>
  );
}
