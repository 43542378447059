import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Group,
  HoverCard,
  Loader,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconAlertCircle, IconCheck, IconX } from '@tabler/icons-react';
import { match } from 'ts-pattern';
import { CompositionChart } from '../CompositionChart';
import { DeleteIcon, EditIcon } from '../Icons';
import {
  ParticleSizeDistributionChart,
  ParticleSizeDistributionChartProps,
} from '../SamplingSuite/ParticleSizeDistributionChart';
import { SamplingSuiteCapturePngThumbnail } from '../SamplingSuite/SamplingSuiteCapturePng';
import NetWeight from '../Weights/NetWeight';
import { WithUnit } from '../WithUnit';
import {
  useDeleteSamplingSuiteContainerSampleAnalysis,
  usePatchSamplingSuiteContainerSampleAnalysis,
} from '../api/samplingSuiteContainerSampleAnalysis';
import {
  CompleteSamplingSuiteCaptureSegmentationDTO,
  FailedSamplingSuiteCaptureSegmentationDTO,
  PendingSamplingSuiteCaptureSegmentationDTO,
  SamplingSuiteCaptureSegmentationDTO,
  SamplingSuiteContainerSampleAnalysisDTO,
} from '../rest-client';
import { composition } from '../util/mixture';
import cssClasses from './SamplingSuiteAnalysisDetail.module.css';
import { SamplingSuiteAnalysisEntryForm } from './SamplingSuiteAnalysisEntryForm';

export function SamplingSuiteAnalysisDetail(props: {
  analysis: SamplingSuiteContainerSampleAnalysisDTO;
  isFetching: boolean;
  isError: boolean;
}) {
  const { analysis, isFetching, isError } = props;

  const deleteMutation = useDeleteSamplingSuiteContainerSampleAnalysis();
  const patchMutation = usePatchSamplingSuiteContainerSampleAnalysis();

  return (
    <Stack>
      <Group position='apart'>
        <Group align='baseline'>
          <Title order={3}>VALI-Vision Analysis</Title>
          {isFetching ? <Loader variant='dots' /> : null}
          {isError ? (
            <HoverCard>
              <HoverCard.Target>
                <Text c='red'>
                  <IconAlertCircle />
                </Text>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text>Refresh error</Text>
              </HoverCard.Dropdown>
            </HoverCard>
          ) : null}
          {analysis.isComplete ? (
            <Group>
              <Text color='green' weight='bold'>
                (Complete)
              </Text>
              <Button
                loading={patchMutation.isLoading || isFetching}
                leftIcon={<EditIcon />}
                variant='default'
                onClick={() => {
                  patchMutation.mutate(
                    {
                      analysisId: analysis.id,
                      patch: { completed: false },
                    },
                    {
                      onSettled() {
                        patchMutation.reset();
                      },
                    },
                  );
                }}
              >
                Edit
              </Button>
            </Group>
          ) : (
            <Text color='orange' weight='bold'>
              (Incomplete)
            </Text>
          )}
        </Group>

        <Button
          variant='outline'
          leftIcon={<DeleteIcon />}
          color='red'
          onClick={() => {
            modals.openConfirmModal({
              title: 'Delete sample analysis forever',
              centered: true,
              children: (
                <Text size='sm'>
                  Are you sure you want to delete this analysis? This action is
                  irreversible and the analysis will be deleted forever.
                </Text>
              ),
              labels: {
                confirm: 'Delete Analysis Forever',
                cancel: 'Back to Safety',
              },
              confirmProps: { color: 'red' },
              onConfirm: () => {
                deleteMutation.mutate(analysis.id, {
                  onError() {
                    showNotification({
                      title: 'Error Deleting Analysis',
                      message: `An error occurred the analysis.`,
                      color: 'red',
                      icon: <IconX />,
                    });
                  },
                  onSuccess() {
                    showNotification({
                      title: 'Analysis Deleted',
                      message: `The analysis was successfully deleted`,
                      color: 'green',
                      icon: <IconCheck />,
                    });
                  },
                  onSettled() {
                    deleteMutation.reset();
                  },
                });
              },
            });
          }}
          loading={deleteMutation.isLoading}
        >
          Delete Analysis
        </Button>
      </Group>

      {analysis.isComplete ? (
        <SimpleGrid cols={2}>
          <CompleteAnalysisTable analysis={analysis} />
          <Stack w='100%'>
            <Paper withBorder p='md'>
              <Title order={4}>Mass Composition</Title>
              <CompositionChart
                composition={composition(
                  analysis.materialClassSet.materialClasses.map(
                    (materialClass) =>
                      [
                        materialClass.name,
                        materialClass.id in analysis.materialClassCaptures
                          ? analysis.materialClassCaptures[materialClass.id]
                              .weightProportion ?? 0
                          : 0,
                      ] as const,
                  ),
                )}
                rotateXAxisLabels
              />
            </Paper>
            <Paper withBorder p='md'>
              <Title order={4} mb='md'>
                Material Size Distribution
              </Title>
              <MultiSegPsdCell
                w='100%'
                h='200px'
                hideYLabel={false}
                segmentations={Object.values(analysis.materialClassCaptures)
                  .flatMap((c) => c.captures)
                  .map((c) => c.segmentation)}
              />
            </Paper>
          </Stack>
        </SimpleGrid>
      ) : (
        <SamplingSuiteAnalysisEntryForm analysis={analysis} />
      )}
    </Stack>
  );
}

function CompleteAnalysisTable(props: {
  analysis: SamplingSuiteContainerSampleAnalysisDTO;
}) {
  const { analysis } = props;
  const materialClassCaptures = new Map(
    Object.entries(analysis.materialClassCaptures),
  );

  const { materialClasses } = analysis.materialClassSet;

  let largestMaxFeretDiameter: number | undefined = undefined;

  for (const { captures } of Object.values(analysis.materialClassCaptures)) {
    for (const capture of captures) {
      if (capture.segmentation.status !== 'complete') continue;
      for (const mask of capture.segmentation.masks) {
        largestMaxFeretDiameter ??= mask.maxFeretDiameterInches;
        largestMaxFeretDiameter = Math.max(
          largestMaxFeretDiameter,
          mask.maxFeretDiameterInches,
        );
      }
    }
  }

  return (
    <Paper withBorder className={cssClasses.completeAnalysisTable}>
      <Box className={cssClasses.fauxTableHeadings}>
        <div>
          <Text w='max-content'>Material Class</Text>
        </div>
        <Flex justify='center'>
          <Text>Captures</Text>
        </Flex>
        <Flex justify='right'>
          <Text>Weight</Text>
        </Flex>
        <Flex justify='right'>
          <Text w='max-content'>Wt. %</Text>
        </Flex>
        <Flex justify='center'>
          <Text>
            PSD{' '}
            <Text span color='dimmed'>
              (Max Diameter)
            </Text>
          </Text>
        </Flex>
      </Box>
      {materialClasses.map((mc) => {
        const classCaptures = materialClassCaptures.get(mc.id);

        if (!classCaptures) {
          return (
            <div key={mc.id} style={{ display: 'contents' }}>
              <Text weight={500} color='dimmed'>
                {mc.name}
              </Text>
              <div style={{ textAlign: 'center' }}>
                <Text color='dimmed'>none</Text>
              </div>
              <div style={{ textAlign: 'right' }}>
                <WithUnit unit='g'>
                  <Text c='dimmed'>0</Text>
                </WithUnit>
              </div>
              <div style={{ textAlign: 'right' }}>
                <Text c='dimmed'>0%</Text>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Text c='dimmed'>none</Text>
              </div>
            </div>
          );
        }

        const segmentations = classCaptures.captures.map((c) => c.segmentation);

        return (
          <div key={mc.id} style={{ display: 'contents' }}>
            <Flex align='center'>
              <Text weight={600}>{mc.name}</Text>
            </Flex>

            <Stack>
              {classCaptures.captures.map((c) => (
                <Card key={c.captureId} shadow='sm'>
                  <Card.Section>
                    <SamplingSuiteCapturePngThumbnail capture={c} />
                  </Card.Section>
                </Card>
              ))}
            </Stack>
            <Flex justify='right' align='center'>
              <NetWeight weight={classCaptures.totalNetWeight} />
            </Flex>
            <Flex justify='right' align='center'>
              {classCaptures.weightProportion ? (
                <Text>
                  {(classCaptures.weightProportion * 100).toFixed(1)}%
                </Text>
              ) : null}
            </Flex>
            <Flex align='center'>
              <MultiSegPsdCell
                segmentations={segmentations}
                xMax={largestMaxFeretDiameter}
              />
            </Flex>
          </div>
        );
      })}

      <Box className={cssClasses.totalWrapper}>
        <Flex align='center' justify='left'>
          <Text weight='bolder'>TOTAL</Text>
        </Flex>
        <Flex align='center' justify='center'>
          {
            [...materialClassCaptures.values()].flatMap((c) => c.captures)
              .length
          }
        </Flex>
        <Flex align='center' justify='right'>
          <NetWeight weight={analysis.accumulatedTotalWeight} />
        </Flex>
        <Flex align='center' justify='right'>
          100%
        </Flex>
        <Flex>
          <MultiSegPsdCell
            segmentations={[...materialClassCaptures.values()]
              .flatMap((c) => c.captures)
              .map((c) => c.segmentation)}
          />
        </Flex>
      </Box>

      {analysis.wholeSampleCaptures.captures.length > 0 ? (
        <div style={{ display: 'contents' }}>
          <Flex align='center'>
            <Badge>Whole Sample</Badge>
          </Flex>
          <Stack>
            {analysis.wholeSampleCaptures.captures.map((c) => (
              <Card key={c.captureId} shadow='sm'>
                <Card.Section>
                  <SamplingSuiteCapturePngThumbnail capture={c} />
                </Card.Section>
              </Card>
            ))}
          </Stack>
          <Flex justify='right' align='center'>
            <NetWeight weight={analysis.wholeSampleCaptures.totalNetWeight} />
          </Flex>
          <Flex justify='right' align='center'>
            {analysis.wholeSampleCaptures.weightProportion === null
              ? '100'
              : (analysis.wholeSampleCaptures.weightProportion * 100).toFixed(
                  1,
                )}
            %
          </Flex>
          <Flex align='center'>
            <MultiSegPsdCell
              segmentations={analysis.wholeSampleCaptures.captures.map(
                (c) => c.segmentation,
              )}
            />
          </Flex>
        </div>
      ) : null}
    </Paper>
  );
}

function MultiSegPsdCell(
  props: {
    segmentations: SamplingSuiteCaptureSegmentationDTO[];
  } & Omit<ParticleSizeDistributionChartProps, 'sizes' | 'binWidthInches'>,
) {
  const {
    segmentations,
    w = '100%',
    h = '100px',
    hideYLabel = true,
    ...rest
  } = props;
  const completedSegs: CompleteSamplingSuiteCaptureSegmentationDTO[] = [];
  const pendingSegs: PendingSamplingSuiteCaptureSegmentationDTO[] = [];
  const failedSegs: FailedSamplingSuiteCaptureSegmentationDTO[] = [];
  for (const seg of segmentations) {
    match(seg)
      .with({ status: 'complete' }, (completed) => {
        completedSegs.push(completed);
      })
      .with({ status: 'pending' }, (pending) => pendingSegs.push(pending))
      .with({ status: 'failed' }, (failed) => failedSegs.push(failed))
      .exhaustive();
  }

  const allMasks = completedSegs.flatMap((seg) => seg.masks);
  const allSizes = allMasks.map((mask) => mask.maxFeretDiameterInches);
  return (
    <ParticleSizeDistributionChart
      h={h}
      w={w}
      sizes={allSizes}
      binWidthInches={0.5}
      hideYLabel={hideYLabel}
      {...rest}
    />
  );
}
