import { Text } from '@mantine/core';
import { OutputContainerChangeDTO } from '../rest-client';
import { ProcessName } from './ProcessName';

export function OutputContainerChangeName({
  outputContainerChange,
  withIcon,
}: {
  outputContainerChange: OutputContainerChangeDTO;
  withIcon?: boolean;
}) {
  return (
    <>
      <ProcessName
        process={outputContainerChange.process}
        withIcon={withIcon}
      />
      <Text span ml='.25em'>
        / {outputContainerChange.outputPort.name}
      </Text>
    </>
  );
}
