import { TextInput } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { MaterialClassMultiSelect } from '../MaterialClass/MaterialClassMultiSelect';

const RecoveryGoalFormSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  shortName: z
    .string()
    .max(4, { message: 'Short name must be 1-4 characters' })
    .nullable(),
  description: z.string().nullable(),
  materialClassIds: z.string().uuid().array(),
});

export type RecoveryGoalFormValues = z.infer<typeof RecoveryGoalFormSchema>;

export function useRecoveryGoalForm() {
  return useForm<RecoveryGoalFormValues>({
    initialValues: {
      name: '',
      shortName: null,
      description: null,
      materialClassIds: [],
    },
    validate: zodResolver(RecoveryGoalFormSchema),
  });
}

export function RecoveryGoalFormFields(props: {
  form: UseFormReturnType<RecoveryGoalFormValues>;
}) {
  const { form } = props;

  return (
    <>
      <TextInput
        label='Name'
        description='Label to refer to the recovery goal.'
        placeholder='Name'
        autoFocus
        withAsterisk
        {...form.getInputProps('name')}
      />
      <TextInput
        label='Short Name'
        placeholder='Short name'
        description='Abbreviated label for the recovery goal to use across the application [1-4 characters].'
        {...form.getInputProps('shortName')}
      />
      <TextInput
        label='Description'
        placeholder='Description'
        description='Describe the intended behavior of the recovery goal.'
        {...form.getInputProps('description')}
      />
      <MaterialClassMultiSelect
        description='Which material classes does this recovery goal target?'
        {...form.getInputProps('materialClassIds')}
      />
    </>
  );
}
