import { LinkText } from '../Link';
import { Router } from '../router';

export interface MaterialClassNameProps {
  name: string;
  id: string;
}

export function MaterialClassName(props: MaterialClassNameProps) {
  const { name, id } = props;

  return (
    <LinkText to={Router.MaterialClassDetail({ materialClassId: id })}>
      {name}
    </LinkText>
  );
}
