/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProcessStopReasonCreationDTO } from '../models/ProcessStopReasonCreationDTO';
import type { ProcessStopReasonDTO } from '../models/ProcessStopReasonDTO';
import type { ProcessStopReasonPatchDTO } from '../models/ProcessStopReasonPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProcessStopReasonService {
    /**
     * @returns ProcessStopReasonDTO Success
     * @throws ApiError
     */
    public static getProcessStopReasons(): CancelablePromise<Array<ProcessStopReasonDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/process-stop-reasons',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createProcessStopReason(
        requestBody: ProcessStopReasonCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/process-stop-reasons',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param reasonGuid
     * @returns ProcessStopReasonDTO Success
     * @throws ApiError
     */
    public static getProcessStopReasonById(
        reasonGuid: string,
    ): CancelablePromise<ProcessStopReasonDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/process-stop-reasons/{reasonGuid}',
            path: {
                'reasonGuid': reasonGuid,
            },
        });
    }
    /**
     * @param reasonGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchProcessStopReason(
        reasonGuid: string,
        requestBody: ProcessStopReasonPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/process-stop-reasons/{reasonGuid}',
            path: {
                'reasonGuid': reasonGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param reasonGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteProcessStopReason(
        reasonGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/process-stop-reasons/{reasonGuid}',
            path: {
                'reasonGuid': reasonGuid,
            },
        });
    }
}
