import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CommodityIcon } from '../Icons';
import {
  AddCommoditySpotPriceDrawerForm,
  AddCommoditySpotPriceDrawerFormProps,
} from './AddCommoditySpotPriceDrawerForm';

export type AddCommoditySpotPriceButtonProps = Omit<ButtonProps, 'onClick'> & {
  addCommoditySpotPriceDrawerFormProps: Omit<
    AddCommoditySpotPriceDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddCommoditySpotPriceButton(
  props: AddCommoditySpotPriceButtonProps,
) {
  const {
    addCommoditySpotPriceDrawerFormProps,
    leftIcon = <CommodityIcon />,
    children = 'Add Commodity Spot Price',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button leftIcon={leftIcon} onClick={openDrawer} {...buttonProps}>
        {children}
      </Button>
      <AddCommoditySpotPriceDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addCommoditySpotPriceDrawerFormProps}
      />
    </>
  );
}
