import { Button, ButtonProps, Tooltip } from '@mantine/core';
import { IconEye, IconEyeOff } from '@tabler/icons-react';

export type ShowDetailsToggleButton = ButtonProps & {
  value: boolean;
  onToggle: () => void;
  tooltip: string;
  disabled: boolean;
};

export function ShowDetailsToggleButton(props: ShowDetailsToggleButton) {
  const { value, onToggle, tooltip, disabled, ...rest } = props;
  return (
    <Tooltip label={tooltip}>
      <Button
        disabled={disabled}
        variant={value ? 'outline' : 'filled'}
        onClick={() => onToggle()}
        leftIcon={value ? <IconEyeOff /> : <IconEye />}
        {...rest}
      >
        {value ? 'Hide Details' : 'Show Details'}
      </Button>
    </Tooltip>
  );
}
