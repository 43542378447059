import { Group, MantineColor, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { CommodityIcon } from '../Icons';
import { LinkText } from '../Link';
import { useCommodity } from '../api/commodity';
import { CommodityDTO, CommodityId } from '../rest-client';
import { Router } from '../router';

export interface CommodityNameProps {
  commodity: CommodityDTO;
  noLink?: boolean;
  withIcon?: boolean;
  color?: 'dimmed' | MantineColor;
}

export function CommodityName(props: CommodityNameProps) {
  const { commodity, noLink, withIcon, color } = props;

  const IconWrapper = ({ children }: { children: ReactNode }) => {
    if (withIcon) {
      return (
        <Group spacing='xs'>
          <CommodityIcon />
          {children}
        </Group>
      );
    } else {
      return <>{children}</>;
    }
  };

  if (noLink) {
    return (
      <IconWrapper>
        <Text color={color}>{commodity.name}</Text>
      </IconWrapper>
    );
  }

  return (
    <IconWrapper>
      <LinkText
        to={Router.CommodityDetail({
          commodityId: commodity.id,
        })}
      >
        {commodity.name}
      </LinkText>
    </IconWrapper>
  );
}

export type CommodityIdNameProps = Omit<CommodityNameProps, 'commodity'> & {
  commodityId: CommodityId;
};

export function CommodityIdName(props: CommodityIdNameProps) {
  const { commodityId, ...rest } = props;
  const { data: commodity, isLoadingError } = useCommodity(commodityId);

  if (commodity !== undefined) {
    return <CommodityName commodity={commodity} {...rest} />;
  }
  if (isLoadingError) {
    return <Text color='red'>Error getting commodity name</Text>;
  }
  return <Text color='dimmed'>Loading...</Text>;
}
