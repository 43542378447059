import { Alert } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { UseMutationResult } from '@tanstack/react-query';

//TODO(2282): update all delete modals to use this
export function useDeleteEntityModal(
  entityId: string,
  deleteMutation: UseMutationResult<void, unknown, string>,
  entityName: string,
  onSuccess?: () => void,
) {
  const openDeleteModal = openDeleteEntityConfirmModal(`${entityName}`, () => {
    deleteMutation.mutate(entityId, {
      onError() {
        showNotification({
          title: `Error Deleting ${entityName}`,
          message: `An error occurred deleting the ${entityName.toLowerCase()}.`,
          color: 'red',
          icon: <IconX />,
        });
      },
      onSuccess() {
        showNotification({
          title: `${entityName} Successfuly Deleted`,
          message: `The ${entityName.toLowerCase()} has successfully been deleted.`,
          color: 'green',
          icon: <IconCheck />,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      onSettled() {
        deleteMutation.reset();
      },
    });
  });

  return openDeleteModal;
}

export function openDeleteEntityConfirmModal(
  entityName: string,
  onConfirm: () => void,
) {
  return () =>
    openConfirmModal({
      title: `Confirm ${entityName} Deletion`,
      centered: true,
      labels: { confirm: `Delete ${entityName}`, cancel: 'Back to Safety' },
      confirmProps: { color: 'red' },
      onConfirm,
      children: (
        <Alert color='red' variant='filled' title='Warning!'>
          Deleting this {entityName.toLowerCase()} is irreversible! Are you
          absolutely sure you wish to continue?
        </Alert>
      ),
    });
}
