import { ActionIcon, Group, Select, Switch } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  IconBackspace,
  IconMinus,
  IconPlus,
  IconSquarePlus,
} from '@tabler/icons-react';
import { useRecoveryGoals } from '../api/recoveryGoal';

interface RecoveryGoalPath {
  steps: RecovoeryGoalStep[];
}

interface RecovoeryGoalStep {
  recoveryGoalId: string | null;
  negative: boolean;
}

export function RecoveryPathEdit<TValues>(props: {
  form: UseFormReturnType<TValues>;
  formPathPrefix?: string;
  accessor: (values: TValues) => RecoveryGoalPath;
  disabled?: boolean;
}) {
  const { form, formPathPrefix = '', accessor, disabled = false } = props;
  const recoveryGoalsQuery = useRecoveryGoals();

  const path = accessor(form.values);

  return (
    <>
      {path.steps.map((_, i) => (
        <Group key={i} spacing={'sm'}>
          <Switch
            color='gray'
            size='lg'
            onLabel={<IconMinus />}
            offLabel={<IconPlus />}
            disabled={disabled}
            {...form.getInputProps(`${formPathPrefix}steps.${i}.negative`, {
              type: 'checkbox',
            })}
          />
          <Select
            required
            placeholder='Select Goal'
            data={
              recoveryGoalsQuery.data?.map((rg) => ({
                label: rg.name,
                value: rg.id,
              })) ?? []
            }
            disabled={disabled}
            {...form.getInputProps(
              `${formPathPrefix}steps.${i}.recoveryGoalId`,
            )}
          />
        </Group>
      ))}

      <div>
        {path.steps.length > 0 ? (
          <ActionIcon
            color='red'
            onClick={() =>
              form.removeListItem(
                `${formPathPrefix}steps`,
                path.steps.length - 1,
              )
            }
            disabled={path.steps.length <= 1 || disabled}
          >
            <IconBackspace />
          </ActionIcon>
        ) : null}
        <ActionIcon
          color='blue'
          disabled={disabled}
          onClick={() =>
            form.insertListItem(`${formPathPrefix}steps`, {
              recoveryGoalId: null,
              negative: true,
            })
          }
        >
          <IconSquarePlus />
        </ActionIcon>
      </div>
    </>
  );
}
