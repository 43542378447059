import { Loader } from '@mantine/core';
import { useSortSystem } from '../../api/sortSystem';
import Temporal from '../../Temporal/temporal.ts';
import { ProcessOutputPortDTO } from '../../rest-client';
import { DifferentialSystemMetrics } from './DifferentialSystemMetrics';

export function OutputPortEdgeMetrics(props: {
  outputPort: ProcessOutputPortDTO;
  startTime: Temporal.Instant;
  endTime: Temporal.Instant;
}) {
  const { outputPort, startTime, endTime } = props;
  const systemQuery = useSortSystem(outputPort.sortSystemId);
  if (systemQuery.data) {
    return (
      <DifferentialSystemMetrics
        system={systemQuery.data}
        port={{ type: 'output', portId: outputPort.outputPortId }}
        startTime={startTime}
        endTime={endTime}
      />
    );
  }
  return <Loader />;
}
