import { Button, Flex } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useCallback, useState } from 'react';
import { useFacilityContext } from '../Facility/FacilityContext.tsx';
import { DateRange } from './dateRangeAndOffset.ts';
import Temporal from './temporal.ts';

export interface TemporalPlainDateRangePickerProps {
  dateRange: Partial<DateRange>;
  onSelect: (newDateRange: Partial<DateRange>) => void;
  onCancel: () => void;
}

function plainDateFromDate(date: Date) {
  return Temporal.PlainDate.from({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  });
}

function dateFromPlainDate(plainDate: Temporal.PlainDate): Date {
  const { year, month, day } = plainDate;
  return new Date(year, month - 1, day);
}

export function TemporalPlainDateRangePicker(
  props: TemporalPlainDateRangePickerProps,
) {
  const { dateRange, onSelect, onCancel } = props;
  const { timeZoneId: timeZone } = useFacilityContext();
  const [selectedStartDate, setSelectedStartDate]: [
    Temporal.PlainDate | null,
    (startDate: Temporal.PlainDate | null) => void,
  ] = useState<Temporal.PlainDate | null>(dateRange.startDate ?? null);
  const [selectedEndDate, setSelectedEndDate]: [
    Temporal.PlainDate | null,
    (endDate: Temporal.PlainDate | null) => void,
  ] = useState<Temporal.PlainDate | null>(dateRange.endDate ?? null);

  let DatePickerStart = null;
  let DatePickerEnd = null;
  if (selectedStartDate) {
    DatePickerStart = dateFromPlainDate(selectedStartDate);
  }
  if (selectedEndDate) {
    DatePickerEnd = dateFromPlainDate(selectedEndDate);
  }

  const handleDateSelect = useCallback(
    (newDateRange: [Date | null, Date | null]) => {
      setSelectedStartDate(
        newDateRange[0] ? plainDateFromDate(newDateRange[0]) : null,
      );
      setSelectedEndDate(
        newDateRange[1] ? plainDateFromDate(newDateRange[1]) : null,
      );
    },
    [setSelectedStartDate, setSelectedEndDate],
  );

  const handleSelectClick = useCallback(() => {
    onSelect({
      startDate: selectedStartDate ?? undefined,
      endDate: selectedEndDate ?? undefined,
      id: 'custom',
      name: 'Custom',
    });
  }, [onSelect, selectedStartDate, selectedEndDate]);

  const handleCancelClick = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const disableSubmit = !selectedStartDate || !selectedEndDate;
  return (
    <Flex direction='column'>
      <DatePicker
        type='range'
        value={[DatePickerStart, DatePickerEnd]}
        onChange={handleDateSelect}
        maxDate={
          new Date(Temporal.Now.zonedDateTimeISO(timeZone).epochMilliseconds)
        }
      />
      <Flex justify='flex-end'>
        <Button variant='subtle' onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          variant='filled'
          onClick={handleSelectClick}
          disabled={disableSubmit}
        >
          Select
        </Button>
      </Flex>
    </Flex>
  );
}
