import { Stack } from '@mantine/core';
import { MaterialSetDTO } from '../rest-client';
import { RepositoryOccupationStart } from './RepositoryOccupationStart';

export function OccupationHistory({
  materialSet,
}: {
  materialSet: MaterialSetDTO;
}) {
  return (
    <Stack spacing='xs'>
      {materialSet.historicalRepositoryOccupationStarts.map(
        (occupationStart) => (
          <RepositoryOccupationStart
            key={`${occupationStart.kind}-${occupationStart.startTime}`}
            occupationStart={occupationStart}
          />
        ),
      )}
    </Stack>
  );
}
