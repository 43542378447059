import { Avatar, Center, rem, Text } from '@mantine/core';
import { IconDatabaseOff } from '@tabler/icons-react';
import { ReactNode } from 'react';

// TODO move to /common
export function TableEmptyBasicContent({ children }: { children: ReactNode }) {
  return (
    <>
      <Center>
        <Avatar radius='xl' size={rem(50)}>
          <IconDatabaseOff />
        </Avatar>
      </Center>
      <Text size='sm' color='dimmed'>
        {children}
      </Text>
    </>
  );
}
