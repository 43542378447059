/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SoldMaterialCreationDTO } from '../models/SoldMaterialCreationDTO';
import type { SoldMaterialDTO } from '../models/SoldMaterialDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SoldMaterialService {
    /**
     * @param customerId
     * @returns SoldMaterialDTO Success
     * @throws ApiError
     */
    public static allSoldMaterials(
        customerId?: string,
    ): CancelablePromise<Array<SoldMaterialDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sold-materials',
            query: {
                'customerId': customerId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createSoldMaterial(
        requestBody: SoldMaterialCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sold-materials',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param soldMaterialGuid
     * @returns SoldMaterialDTO Success
     * @throws ApiError
     */
    public static getSoldMaterialById(
        soldMaterialGuid: string,
    ): CancelablePromise<SoldMaterialDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sold-materials/{soldMaterialGuid}',
            path: {
                'soldMaterialGuid': soldMaterialGuid,
            },
        });
    }
}
