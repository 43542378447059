import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ProcessBufferRestorationCreationDTO,
  ProcessBufferRestorationId,
  ProcessBufferRestorationPatchDTO,
  ProcessBufferRestorationService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchProcessBufferRestoration(
  ctx: QueryFunctionContexts['processBufferRestoration']['detail'],
) {
  const [{ processBufferRestorationId }] = ctx.queryKey;
  if (!processBufferRestorationId) {
    throw new Error('process buffer restoration is required');
  }
  return await ProcessBufferRestorationService.getProcessBufferRestorationById(
    processBufferRestorationId,
  );
}

export function useProcessBufferRestoration(
  processBufferRestorationId?: string,
) {
  return useQuery({
    queryKey: queryKeys.processBufferRestoration.detail(
      processBufferRestorationId,
    ),
    queryFn: fetchProcessBufferRestoration,
    enabled: processBufferRestorationId !== undefined,
  });
}

async function createProcessBufferRestoration(
  processBufferRestoration: ProcessBufferRestorationCreationDTO,
) {
  await ProcessBufferRestorationService.createProcessBufferRestoration(
    processBufferRestoration,
  );
}

export function useCreateProcessBufferRestoration() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createProcessBufferRestoration,
    onSettled() {
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function patchProcessBufferRestoration({
  processBufferRestorationId,
  patch,
}: {
  processBufferRestorationId: string;
  patch: ProcessBufferRestorationPatchDTO;
}) {
  await ProcessBufferRestorationService.patchProcessBufferRestoration(
    processBufferRestorationId,
    patch,
  );
}

export function usePatchProcessBufferRestoration(
  processBufferRestorationId: ProcessBufferRestorationId,
) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: ProcessBufferRestorationPatchDTO) =>
      await patchProcessBufferRestoration({
        processBufferRestorationId,
        patch,
      }),
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.processBufferRestoration.detail(processBufferRestorationId),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function deleteProcessBufferRestoration(
  processBufferRestorationId: string,
) {
  await ProcessBufferRestorationService.deleteProcessBufferRestoration(
    processBufferRestorationId,
  );
}

export function useDeleteProcessBufferRestoration() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteProcessBufferRestoration,
    onSettled(_data, _error, processBufferRestorationId) {
      invalidator.invalidateKeys(
        queryKeys.processBufferRestoration.detail(processBufferRestorationId),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}
