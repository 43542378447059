import { Intl, Temporal, toTemporalInstant } from '@js-temporal/polyfill';

declare global {
  interface Date {
    toTemporalInstant: typeof toTemporalInstant;
  }
}
Date.prototype.toTemporalInstant = toTemporalInstant;
export const BrowserCalendar = new Intl.DateTimeFormat().resolvedOptions()
  .calendar;
export default Temporal;
