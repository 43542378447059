import { useMutation, useQuery } from '@tanstack/react-query';
import {
  InternalMaterialSinkService,
  InternalSinkContainerTransferCreationDTO,
  InternalSinkContainerTransferId,
  InternalSinkContainerTransferPatchDTO,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchInternalSinkContainerTransfer(
  ctx: QueryFunctionContexts['transaction']['internalSinkContainerTransfer']['detail'],
) {
  const [{ internalSinkContainerTransferId }] = ctx.queryKey;
  return await InternalMaterialSinkService.getInternalMaterialSinkContainerTransferById(
    internalSinkContainerTransferId,
  );
}

export function useInternalSinkContainerTransfer(
  internalSinkContainerTransferId: string,
) {
  return useQuery({
    queryKey: queryKeys.transaction.internalSinkContainerTransfer.detail(
      internalSinkContainerTransferId,
    ),
    queryFn: fetchInternalSinkContainerTransfer,
  });
}

async function createInternalSinkContainerTransfer(
  dto: InternalSinkContainerTransferCreationDTO,
) {
  await InternalMaterialSinkService.createInternalMaterialSinkContainerTransfer(
    dto,
  );
}

export function useCreateInternalSinkContainerTransfer() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createInternalSinkContainerTransfer,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.transaction.internalSinkContainerTransfer.lists(),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function deleteInternalMaterialSinkContainerTransfer(id: string) {
  await InternalMaterialSinkService.deleteInternalMaterialSinkContainerTransfer(
    id,
  );
}

export function useDeleteInternalMaterialSinkContainerTransfer() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteInternalMaterialSinkContainerTransfer,
    onSettled() {
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function patchInternalMaterialSinkContainerTransfer({
  sinkTransferId,
  patch,
}: {
  sinkTransferId: InternalSinkContainerTransferId;
  patch: InternalSinkContainerTransferPatchDTO;
}) {
  await InternalMaterialSinkService.patchInternalMaterialSinkContainerTransfer(
    sinkTransferId,
    patch,
  );
}

export function usePatchInternalMaterialSinkContainerTransfer(
  sinkTransferId: InternalSinkContainerTransferId,
) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: InternalSinkContainerTransferPatchDTO) =>
      await patchInternalMaterialSinkContainerTransfer({
        sinkTransferId,
        patch,
      }),
    onSettled(_data, _error, variables) {
      invalidator.invalidateInventoryLedger();
      invalidator.invalidateKey(
        queryKeys.transaction.internalSinkContainerTransfer.detail(
          sinkTransferId,
        ),
      );
      if (variables.internalSinkId) {
        invalidator.invalidateKey(
          queryKeys.internalMaterialSink.detail(variables.internalSinkId),
        );
      }
    },
  });
}

async function fetchInternallySinkedMaterialSets(
  ctx: QueryFunctionContexts['materialSet']['internallySinkedMaterials'],
) {
  const [{ facilityId, internalMaterialSinkId }] = ctx.queryKey;
  return await InternalMaterialSinkService.getInternallySinkedMaterialSets(
    facilityId,
    internalMaterialSinkId,
  );
}

export function useInternallySinkedMaterialSets(
  facilityId?: string,
  internalMaterialSinkId?: string,
) {
  return useQuery({
    queryKey: queryKeys.materialSet.internallySinkedMaterials(
      facilityId,
      internalMaterialSinkId,
    ),
    queryFn: fetchInternallySinkedMaterialSets,
  });
}

async function fetchInternallySinkedMaterialDetails(
  ctx: QueryFunctionContexts['internallySinkedMaterial']['detail'],
) {
  const [{ internalSinkContainerTransferId }] = ctx.queryKey;
  return await InternalMaterialSinkService.getInternallySinkedMaterialDetails(
    internalSinkContainerTransferId,
  );
}

export function useInternallySinkedMaterialDetails(
  internalSinkContainerTransferId: string,
) {
  return useQuery({
    queryKey: queryKeys.internallySinkedMaterial.detail({
      internalSinkContainerTransferId,
    }),
    queryFn: fetchInternallySinkedMaterialDetails,
  });
}
