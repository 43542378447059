import { AppPage } from '../App/AppPage';
import { Router } from '../router';
import { AddTruckLoadForm } from './AddTruckLoadForm';

export function AddTruckLoadPage() {
  return (
    <AppPage
      breadcrumbs={[
        { routeName: Router.TruckLoadList(), title: 'Truck Loads' },
      ]}
      title='Add Truck Load'
    >
      <AppPage.Section>
        <AddTruckLoadForm />
      </AppPage.Section>
    </AppPage>
  );
}
