// from https://carbondesignsystem.com/data-visualization/color-palettes/

import { useMantineColorScheme } from '@mantine/styles';

const categoricalColors = {
  light: [
    '#6929c4',
    '#1192e8',
    '#005d5d',
    '#9f1853',
    '#fa4d56',
    '#570408',
    '#198038',
    '#002d9c',
    '#ee538b',
    '#b28600',
    '#009d9a',
    '#012749',
    '#8a3800',
    '#a56eff',
  ] as const,
  dark: [
    '#8a3ffc',
    '#33b1ff',
    '#007d79',
    '#ff7eb6',
    '#fa4d56',
    '#fff1f1',
    '#6fdc8c',
    '#4589ff',
    '#d12771',
    '#d2a106',
    '#08bdba',
    '#bae6ff',
    '#ba4e00',
    '#d4bbff',
  ] as const,
} as const;

export function useCategoricalColors() {
  const { colorScheme } = useMantineColorScheme();
  return categoricalColors[colorScheme];
}

const alertColors = {
  light: {
    red: '#da1e28',
    orange: '#ff832b',
    yellow: '#f1c21b',
    green: '#198038',
  },
  dark: {
    red: '#fa4d56',
    orange: '#ff832b',
    yellow: '#f1c21b',
    green: '#24a148',
  },
} as const;

export function useAlertColors() {
  const { colorScheme } = useMantineColorScheme();
  return alertColors[colorScheme];
}
