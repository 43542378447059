import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ProcessBufferRestorationIcon } from '../Icons';
import {
  AddProcessBufferRestorationDrawerForm,
  AddProcessBufferRestorationDrawerFormProps,
} from './AddProcessBufferRestorationDrawerForm';

export type AddProcessBufferRestorationButtonProps = Omit<
  ButtonProps,
  'onClick'
> & {
  addProcessBufferRestorationDrawerFormProps: Omit<
    AddProcessBufferRestorationDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddProcessBufferRestorationButton(
  props: AddProcessBufferRestorationButtonProps,
) {
  const {
    addProcessBufferRestorationDrawerFormProps,
    leftIcon = <ProcessBufferRestorationIcon />,
    children = 'Add Feedstock Restoration',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddProcessBufferRestorationDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addProcessBufferRestorationDrawerFormProps}
      />
    </>
  );
}
