import { useMutation, useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import {
  OutputContainerChangeCreationDTO,
  OutputContainerChangeId,
  OutputContainerChangePatchDTO,
  OutputContainerChangeService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchOutputContainerChange(
  ctx: QueryFunctionContexts['outputContainerChange']['detail'],
) {
  const [{ outputContainerChangeId }] = ctx.queryKey;
  if (!outputContainerChangeId) {
    throw new Error();
  }
  return await OutputContainerChangeService.getOutputContainerChangeById(
    outputContainerChangeId,
  );
}

export function useOutputContainerChange(
  outputContainerChangeId: string | undefined,
) {
  return useQuery({
    queryKey: queryKeys.outputContainerChange.detail(outputContainerChangeId),
    queryFn: fetchOutputContainerChange,
    enabled: outputContainerChangeId !== undefined,
  });
}

async function createOutputContainerChange(
  outputContainerChange: OutputContainerChangeCreationDTO,
) {
  await OutputContainerChangeService.createOutputContainerChange(
    outputContainerChange,
  );
}

export function useCreateOutputContainerChange() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createOutputContainerChange,
    onSettled() {
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function patchOutputContainerChange({
  outputContainerChangeId,
  patch,
}: {
  outputContainerChangeId: string;
  patch: OutputContainerChangePatchDTO;
}) {
  await OutputContainerChangeService.patchOutputContainerChange(
    outputContainerChangeId,
    patch,
  );
}

export function usePatchOutputContainerChange(
  outputContainerChangeId: OutputContainerChangeId,
) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: OutputContainerChangePatchDTO) =>
      await patchOutputContainerChange({ outputContainerChangeId, patch }),
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.outputContainerChange.detail(outputContainerChangeId),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function deleteOutputContainerChange(outputContainerChangeId: string) {
  await OutputContainerChangeService.deleteOutputContainerChange(
    outputContainerChangeId,
  );
}

export function useDeleteOutputContainerChange() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteOutputContainerChange,
    onSettled(_data, _error, outputContainerChangeId) {
      invalidator.invalidateKeys(
        queryKeys.outputContainerChange.detail(outputContainerChangeId),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function fetchPreviousProcessOutputPortState(
  ctx: QueryFunctionContexts['outputContainerChange']['detailByProcessOutputPort'],
) {
  const [{ processId, outputPortId, beforeTime }] = ctx.queryKey;
  if (!processId || !outputPortId) {
    throw new Error();
  }

  return await OutputContainerChangeService.getPreviousProcessOutputPortState(
    processId,
    outputPortId,
    beforeTime?.utc().toISOString(),
  );
}

export function usePreviousProcessOutputPortState(args: {
  processId: string | undefined;
  outputPortId: string | undefined;
  beforeTime: Dayjs | undefined;
}) {
  const { processId, outputPortId } = args;
  return useQuery({
    queryKey: queryKeys.outputContainerChange.detailByProcessOutputPort(args),
    queryFn: fetchPreviousProcessOutputPortState,
    enabled: processId !== undefined && outputPortId !== undefined,
  });
}
