import { Text } from '@mantine/core';
import { useInternalMaterialSource } from '../api/internalMaterialSource';
import { InternalMaterialSourceId } from '../rest-client';
import {
  InternalMaterialSourceName,
  InternalMaterialSourceNameProps,
} from './InternalMaterialSourceName';

export type InternalMaterialSourceIdNameProps = Omit<
  InternalMaterialSourceNameProps,
  'internalMaterialSource'
> & {
  internalMaterialSourceId: InternalMaterialSourceId;
};

export function InternalMaterialSourceIdName(
  props: InternalMaterialSourceIdNameProps,
) {
  const { internalMaterialSourceId, ...rest } = props;
  const internalMaterialSourceQuery = useInternalMaterialSource(
    internalMaterialSourceId,
  );
  if (internalMaterialSourceQuery.data !== undefined) {
    return (
      <InternalMaterialSourceName
        internalMaterialSource={internalMaterialSourceQuery.data}
        {...rest}
      />
    );
  }
  if (internalMaterialSourceQuery.isLoadingError) {
    return <Text color='red'>Error getting upstream material source name</Text>;
  }
  return <Text color='dimmed'>Loading...</Text>;
}
