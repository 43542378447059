import { Group, Stack, Title } from '@mantine/core';
import { AppPage } from '../App/AppPage';
import { VendorIcon } from '../Icons';
import { LinkButton } from '../Link';
import { PurchasedMaterialTable } from '../PurchasedMaterial/PurchasedMaterialTable';
import { Router } from '../router';
import { VendorTable } from './VendorTable';

export function VendorsPage() {
  return (
    <AppPage title='Vendors'>
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Material Vendors</Title>
            <LinkButton to={Router.VendorCreate()} leftIcon={<VendorIcon />}>
              Add Vendor
            </LinkButton>
          </Group>
          <VendorTable />
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Title order={2}>Purchased Materials</Title>
          <PurchasedMaterialTable />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
