/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LedgerErrorProductionMassStatsResultDTO } from '../models/LedgerErrorProductionMassStatsResultDTO';
import type { ProcessPlannedProductionIntervalDTO } from '../models/ProcessPlannedProductionIntervalDTO';
import type { ProcessPlannedProductionIntervalPatchDTO } from '../models/ProcessPlannedProductionIntervalPatchDTO';
import type { ProcessStopCreationDTO } from '../models/ProcessStopCreationDTO';
import type { ProductionAggregateTemporalMetricsDTO } from '../models/ProductionAggregateTemporalMetricsDTO';
import type { ProductionIntervalTemporalStatusDTO } from '../models/ProductionIntervalTemporalStatusDTO';
import type { SuccessfulProductionMassStatsDTO } from '../models/SuccessfulProductionMassStatsDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProductionService {
    /**
     * @param productionIntervalGuid
     * @returns ProductionIntervalTemporalStatusDTO Success
     * @throws ApiError
     */
    public static getProductionIntervalStatus(
        productionIntervalGuid: string,
    ): CancelablePromise<ProductionIntervalTemporalStatusDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/production-intervals/{productionIntervalGuid}/status',
            path: {
                'productionIntervalGuid': productionIntervalGuid,
            },
        });
    }
    /**
     * @param productionIntervalGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getProductionIntervalMassStats(
        productionIntervalGuid: string,
    ): CancelablePromise<(SuccessfulProductionMassStatsDTO | LedgerErrorProductionMassStatsResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/production-intervals/{productionIntervalGuid}/mass-stats',
            path: {
                'productionIntervalGuid': productionIntervalGuid,
            },
        });
    }
    /**
     * @param facilityGuid
     * @param processGuid
     * @param date
     * @returns ProcessPlannedProductionIntervalDTO Success
     * @throws ApiError
     */
    public static getProductionIntervals(
        facilityGuid: string,
        processGuid?: string,
        date?: string,
    ): CancelablePromise<Array<ProcessPlannedProductionIntervalDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/production-intervals/{facilityGuid}',
            path: {
                'facilityGuid': facilityGuid,
            },
            query: {
                'processGuid': processGuid,
                'date': date,
            },
        });
    }
    /**
     * @param productionIntervalGuid
     * @returns ProcessPlannedProductionIntervalDTO Success
     * @throws ApiError
     */
    public static getProductionInterval(
        productionIntervalGuid: string,
    ): CancelablePromise<ProcessPlannedProductionIntervalDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/production-intervals/{productionIntervalGuid}/detail',
            path: {
                'productionIntervalGuid': productionIntervalGuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createProductionIntervals(
        requestBody: Array<ProcessPlannedProductionIntervalDTO>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/production-intervals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param productionIntervalGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchProductionInterval(
        productionIntervalGuid: string,
        requestBody: ProcessPlannedProductionIntervalPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/production-intervals/{productionIntervalGuid}',
            path: {
                'productionIntervalGuid': productionIntervalGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param productionIntervalGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteProductionInterval(
        productionIntervalGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/production-intervals/{productionIntervalGuid}',
            path: {
                'productionIntervalGuid': productionIntervalGuid,
            },
        });
    }
    /**
     * @param productionIntervalGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createProcessStop(
        productionIntervalGuid: string,
        requestBody: ProcessStopCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/production-intervals/{productionIntervalGuid}/stops',
            path: {
                'productionIntervalGuid': productionIntervalGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param productionIntervalGuid
     * @param stopGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteProcessStop(
        productionIntervalGuid: string,
        stopGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/production-intervals/{productionIntervalGuid}/stops/{stopGuid}',
            path: {
                'productionIntervalGuid': productionIntervalGuid,
                'stopGuid': stopGuid,
            },
        });
    }
    /**
     * @param after
     * @param before
     * @param facilityId
     * @returns ProductionAggregateTemporalMetricsDTO Success
     * @throws ApiError
     */
    public static getProductionAggregateTemporalMetrics(
        after: string,
        before: string,
        facilityId?: string,
    ): CancelablePromise<ProductionAggregateTemporalMetricsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/production-intervals/agg-temporal-metrics',
            query: {
                'facilityId': facilityId,
                'after': after,
                'before': before,
            },
        });
    }
}
