/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProcessBufferRestorationCreationDTO } from '../models/ProcessBufferRestorationCreationDTO';
import type { ProcessBufferRestorationDTO } from '../models/ProcessBufferRestorationDTO';
import type { ProcessBufferRestorationPatchDTO } from '../models/ProcessBufferRestorationPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProcessBufferRestorationService {
    /**
     * @param processBufferRestorationGuid
     * @returns ProcessBufferRestorationDTO Success
     * @throws ApiError
     */
    public static getProcessBufferRestorationById(
        processBufferRestorationGuid: string,
    ): CancelablePromise<ProcessBufferRestorationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/process-buffer-restorations/{processBufferRestorationGuid}',
            path: {
                'processBufferRestorationGuid': processBufferRestorationGuid,
            },
        });
    }
    /**
     * @param processBufferRestorationGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchProcessBufferRestoration(
        processBufferRestorationGuid: string,
        requestBody: ProcessBufferRestorationPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/process-buffer-restorations/{processBufferRestorationGuid}',
            path: {
                'processBufferRestorationGuid': processBufferRestorationGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param processBufferRestorationGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteProcessBufferRestoration(
        processBufferRestorationGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/process-buffer-restorations/{processBufferRestorationGuid}',
            path: {
                'processBufferRestorationGuid': processBufferRestorationGuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createProcessBufferRestoration(
        requestBody: ProcessBufferRestorationCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/process-buffer-restorations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
