import { match } from 'ts-pattern';
import { InternallySourcedMaterialDetailPage } from '../InternallySourcedMaterial/InternallySourcedMaterialDetailPage.tsx';
import { The404 } from '../The404';
import { Router } from '../router';
import { InternalMaterialSourceDetail } from './InternalMaterialSourceDetailPage';
import { InternalSourcingPage } from './InternalMaterialSourcesPage.tsx';

export function InternalSourceArea() {
  const route = Router.useRoute([
    'InternalSourceList',
    'InternalSourceDetail',
    'InternalSourceMaterialsMaterialDetail',
  ]);
  if (route === undefined) {
    return <The404 />;
  }
  return match(route)
    .with({ name: 'InternalSourceList' }, () => <InternalSourcingPage />)
    .with(
      { name: 'InternalSourceDetail' },
      ({ params: { internalMaterialSourceId } }) => (
        <InternalMaterialSourceDetail
          internalMaterialSourceId={internalMaterialSourceId}
        />
      ),
    )
    .with(
      { name: 'InternalSourceMaterialsMaterialDetail' },
      ({ params: { internallySourcedMaterialId } }) => (
        <InternallySourcedMaterialDetailPage
          internallySourcedMaterialId={internallySourcedMaterialId}
        />
      ),
    )
    .exhaustive();
}
