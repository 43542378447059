import { Menu } from '@mantine/core';
import { EditIcon } from '../Icons';

export function EditMenuItem(props: { onClick: () => void }) {
  const { onClick } = props;
  return (
    <Menu.Item icon={<EditIcon size={14} />} onClick={onClick}>
      Edit
    </Menu.Item>
  );
}
