import {
  Alert,
  Divider,
  Flex,
  Input,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { useToggle } from '@mantine/hooks';
import { IconAlertTriangle } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';
import ContainerSelect, {
  ContainerSelectVariant,
} from '../Container/ContainerSelect';
import { RequiredDayjsDateTimePicker } from '../DayjsDateTimePicker';
import { useFacilityContext } from '../Facility/FacilityContext';
import ContainerFullnessSwitch from '../Input/ContainerFullnessSwitch';
import { InvalidVisibilityToggle } from '../Input/InvalidVisibilityToggle';
import SwitchAsLabeledInputClasses from '../Input/SwitchAsLabeledInput.module.css';
import { InternalMaterialSinkSelect } from '../InternalMaterialSink/InternalMaterialSinkSelect';
import QuantizedWeightTicksInput from '../Weights/QuantizedWeightTicksInput';
import { OptionalWeightSchema } from '../Weights/WeightZodSchemas';
import {
  InternalMaterialSinkId,
  MaterialContainerId,
  WeightUnit,
} from '../rest-client';

const InternalSinkContainerTransferFormSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  timestamp: z.instanceof(dayjs as unknown as typeof Dayjs, {
    message: 'Transfer time is required',
  }),
  containerId: z.string({ message: 'Container is required' }).uuid(),
  containerEmptied: z.boolean(),
  internalMaterialSinkId: z
    .string({ message: 'Material export destination is required' })
    .uuid(),
  netRemovedWeight: OptionalWeightSchema,
});

export type InternalSinkContainerTransferFormValues = z.infer<
  typeof InternalSinkContainerTransferFormSchema
>;

export type InternalSinkContainerTransferFormProps = {
  internalMaterialSinkId?: InternalMaterialSinkId;
  containerId?: MaterialContainerId;
};

export function useInternalSinkContainerTransferForm(
  props: InternalSinkContainerTransferFormProps,
) {
  const { internalMaterialSinkId, containerId } = props;

  return useForm<InternalSinkContainerTransferFormValues>({
    initialValues: {
      name: '',
      timestamp: dayjs.utc(),
      containerId: containerId ?? null!,
      containerEmptied: true,
      internalMaterialSinkId: internalMaterialSinkId ?? null!,
      netRemovedWeight: {
        enabled: false,
        unit: WeightUnit.POUND,
        tickSize: 1,
        ticks: null,
      },
    },
    validate: zodResolver(InternalSinkContainerTransferFormSchema),
  });
}

export function InternalSinkContainerTransferFormFields(props: {
  form: UseFormReturnType<InternalSinkContainerTransferFormValues>;
  sourceContainerSelectVariant: ContainerSelectVariant;
}) {
  const { form, sourceContainerSelectVariant = 'material' } = props;
  const facility = useFacilityContext();
  const [showEmptyContainers, toggleShowEmptyContainers] = useToggle();

  const containerSelectDisabled =
    form.values.containerId !== null && !form.isDirty('containerId');

  return (
    <>
      <TextInput
        label='Name'
        description='Label to refer to this batch of material being exported.'
        placeholder='Name'
        withAsterisk
        {...form.getInputProps('name')}
      />
      <RequiredDayjsDateTimePicker
        label='Transfer Time'
        description='When was the material considered to be removed from the source container?'
        tz={facility.timeZoneId}
        withAsterisk
        {...form.getInputProps('timestamp')}
      />

      <Flex direction='column' gap='sm'>
        {/* TODO(new): change to be mantine v7 form input section */}
        <Divider label={<Text size='md'>Source</Text>} labelPosition='center' />
        <ContainerSelect
          label='Source Container'
          description='Which container is having its material exported.'
          placeholder='Select container'
          variant={sourceContainerSelectVariant}
          timestamp={form.values.timestamp}
          facilityId={facility.id}
          hideEmpty={!showEmptyContainers}
          hideFull={false}
          withAsterisk
          clearable
          disabled={containerSelectDisabled}
          {...form.getInputProps('containerId')}
        />
        <Flex gap='md'>
          <ContainerFullnessSwitch
            inputWrapperProps={{
              label: 'Source Emptied',
              description: 'Did this export empty the source container?',
            }}
            variant='source'
            orientation='horizontal'
            size='lg'
            {...form.getInputProps('containerEmptied', {
              type: 'checkbox',
            })}
          />
          {!containerSelectDisabled && (
            <InvalidVisibilityToggle
              label='Show Empty Containers'
              inputWrapperProps={{
                description: 'Include empty containers in container select?',
              }}
              labelVariant='top'
              actionIconProps={{}}
              value={showEmptyContainers}
              onToggle={toggleShowEmptyContainers}
            />
          )}
        </Flex>
      </Flex>

      <Flex gap='md'>
        <Input.Wrapper
          label='Record Exported Weight'
          description='Is the net weight of the exported material known?'
          maw='50%'
        >
          <Switch
            size='lg'
            maw='50%'
            className={SwitchAsLabeledInputClasses.switch}
            checked={form.values.netRemovedWeight.enabled}
            onChange={(e) => {
              form.setFieldValue(
                'netRemovedWeight.enabled',
                e.currentTarget.checked,
              );
              // reset net removed weight on switch being disabled
              if (!e.currentTarget.checked) {
                form.setFieldValue('netRemovedWeight.ticks', null);
              }
            }}
          />
        </Input.Wrapper>

        <QuantizedWeightTicksInput
          label='Net Weight Exported'
          description='The net weight of material exported from the source container.'
          required={form.values.netRemovedWeight.enabled}
          disabled={!form.values.netRemovedWeight.enabled}
          maw='20ch'
          unit={form.values.netRemovedWeight.unit ?? WeightUnit.POUND}
          tickSize={form.values.netRemovedWeight.tickSize ?? 1}
          {...form.getInputProps('netRemovedWeight.ticks')}
        />
      </Flex>
      <Flex direction='column' gap='sm'>
        {/* TODO(new): change to be mantine v7 form input section */}
        <Divider
          label={<Text size='md'>Destination</Text>}
          labelPosition='center'
        />
        <InternalMaterialSinkSelect
          label='Material Export Destination'
          description='Where the material is being exported to.'
          withAsterisk
          disabled={
            form.values.internalMaterialSinkId !== null &&
            !form.isDirty('internalMaterialSinkId')
          }
          {...form.getInputProps('internalMaterialSinkId')}
        />
      </Flex>
      {showEmptyContainers ? (
        <Alert color='orange' icon={<IconAlertTriangle />} title=''>
          Exporting material from an empty container from inventory will result
          in an ledger error. You can still create the material export, but the
          error will need to be corrected later.
        </Alert>
      ) : undefined}
    </>
  );
}
