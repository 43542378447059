import { MantineColor, Text } from '@mantine/core';
import { VendorIcon } from '../Icons';
import { LinkText } from '../Link';
import { MaterialVendorDTO } from '../rest-client';
import { Router } from '../router';

export interface VendorNameProps {
  vendor: MaterialVendorDTO;
  noLink?: boolean;
  withIcon?: boolean;
  color?: 'dimmed' | MantineColor;
}

export function VendorName(props: VendorNameProps) {
  const { vendor, noLink, withIcon, color } = props;

  const textContent = (
    <>
      {withIcon ? <VendorIcon /> : undefined}
      {vendor.name}
    </>
  );

  if (noLink) {
    return <Text color={color}>{textContent}</Text>;
  }

  return (
    <LinkText to={Router.VendorDetail({ vendorId: vendor.id })}>
      {textContent}
    </LinkText>
  );
}
