import { useMemo } from 'react';
import { match } from 'ts-pattern';
import {
  MaterialEffectDTO,
  MaterialSetDTO,
  ProcessDirectOutputEffectDTO,
} from '../rest-client';
import {
  GenealogyGraphDataResult,
  SuccessfulGenealogyGraphDataResult,
} from './useGenealogyGraphData';

export interface StructuralMaterialSetNode {
  kind: 'material-set';
  id: string;
  materialSet: MaterialSetDTO;
  onAncestorFringe: boolean;
  onDescendantFringe: boolean;
}

export interface StructuralFeedFlowGroupNode {
  kind: 'feed-flow-group';
  id: string;
}

export interface StructuralInternalSinkNode {
  kind: 'internal-sink';
  id: string;

  internalMaterialSinkId: string;
}

interface InterMaterialSetEdge {
  id: string;

  kind: 'inter-material-set';
  sourceHash: string;
  targetHash: string;
}

function mEdge(sourceHash: string, targetHash: string): InterMaterialSetEdge {
  return {
    kind: 'inter-material-set',
    id: `${sourceHash}-${targetHash}`,
    sourceHash,
    targetHash,
  };
}

interface FeedstockEdge {
  id: string;
  kind: 'feedstock';
  materialSetHash: string;
  feedFlowGroupId: string;
}

function feedstockEdge(
  materialSetHash: string,
  feedFlowGroupId: string,
): FeedstockEdge {
  return {
    kind: 'feedstock',
    id: `${materialSetHash}-${feedFlowGroupId}`,
    materialSetHash,
    feedFlowGroupId,
  };
}

interface ProcessOutputEdge {
  id: string;
  kind: 'process-output';
  effect: ProcessDirectOutputEffectDTO;
}

function outputEdge(effect: ProcessDirectOutputEffectDTO): ProcessOutputEdge {
  return {
    kind: 'process-output',
    id: `${effect.feedFlowGroupId}-${effect.output}`,
    effect,
  };
}

interface InternalMaterialSinkEdge {
  id: string;
  kind: 'internal-material-sink';
  materialSetHash: string;
  internalMaterialSinkId: string;
}

function internalSinkEdge(
  materialSetHash: string,
  internalMaterialSinkId: string,
): InternalMaterialSinkEdge {
  return {
    kind: 'internal-material-sink',
    id: `${materialSetHash}-${internalMaterialSinkId}`,
    materialSetHash,
    internalMaterialSinkId,
  };
}

export type GenealogyGraphEdge =
  | InterMaterialSetEdge
  | FeedstockEdge
  | ProcessOutputEdge
  | InternalMaterialSinkEdge;

export interface GenealogyGraphStructure {
  materialSetNodes: StructuralMaterialSetNode[];
  feedFlowGroupNodes: StructuralFeedFlowGroupNode[];
  internalSinkNodes: StructuralInternalSinkNode[];

  edges: GenealogyGraphEdge[];

  fringeEffects: Map<string, MaterialEffectDTO>;
}

export type GenealogyGraphStructureResult =
  | {
      status: 'success';
      structure: GenealogyGraphStructure;
    }
  | Exclude<GenealogyGraphDataResult, SuccessfulGenealogyGraphDataResult>;

function getGenealogyGraphStructure(
  materialSets: Map<string, MaterialSetDTO>,
): GenealogyGraphStructureResult {
  const materialSetNodes: StructuralMaterialSetNode[] = [];

  // build a set of unique effects
  const effects = new Map<string, MaterialEffectDTO>();

  for (const materialSet of materialSets.values()) {
    const { consumingEffect, producingEffect } = materialSet;
    if (consumingEffect) {
      effects.set(consumingEffect.effectHash, consumingEffect);
    }
    effects.set(producingEffect.effectHash, producingEffect);

    materialSetNodes.push({
      kind: 'material-set',
      materialSet,
      id: materialSet.hash,
      onAncestorFringe: materialSet.directAncestors.some(
        (a) => !materialSets.has(a),
      ),
      onDescendantFringe: materialSet.directDescendants.some(
        (d) => !materialSets.has(d),
      ),
    });
  }

  const feedFlowGroupIds = new Set<string>();
  const internalSinkIds = new Set<string>();
  const edges: GenealogyGraphEdge[] = [];
  const fringeEffects = new Map<string, MaterialEffectDTO>();

  for (const effect of effects.values()) {
    for (const edge of effectEdges(effect)) {
      const includeEdge = match(edge)
        .with({ kind: 'inter-material-set' }, ({ sourceHash, targetHash }) => {
          return materialSets.has(sourceHash) && materialSets.has(targetHash);
        })
        .with({ kind: 'feedstock' }, ({ materialSetHash, feedFlowGroupId }) => {
          if (materialSets.has(materialSetHash)) {
            feedFlowGroupIds.add(feedFlowGroupId);
            return true;
          }
          return false;
        })
        .with({ kind: 'process-output' }, ({ effect }) => {
          if (materialSets.has(effect.output)) {
            feedFlowGroupIds.add(effect.feedFlowGroupId);
            return true;
          }
          return false;
        })
        .with(
          { kind: 'internal-material-sink' },
          ({ materialSetHash, internalMaterialSinkId }) => {
            if (materialSets.has(materialSetHash)) {
              internalSinkIds.add(internalMaterialSinkId);
              return true;
            }
            return false;
          },
        )
        .exhaustive();

      if (includeEdge) {
        edges.push(edge);
      } else {
        fringeEffects.set(effect.effectHash, effect);
      }
    }
  }

  const feedFlowGroupNodes: StructuralFeedFlowGroupNode[] = [
    ...feedFlowGroupIds,
  ].map((feedFlowGroupId) => ({
    kind: 'feed-flow-group' as const,
    id: feedFlowGroupId,
  }));

  const internalSinkNodes = [...internalSinkIds].map(
    (internalMaterialSinkId) => ({
      internalMaterialSinkId,
      kind: 'internal-sink' as const,
      id: internalMaterialSinkId,
    }),
  );

  return {
    status: 'success' as const,
    structure: {
      materialSetNodes,
      feedFlowGroupNodes,
      internalSinkNodes,

      edges,
      fringeEffects,
    },
  };
}

export default function useGenealogyGraphStructure(
  materialSetsResult: GenealogyGraphDataResult,
): GenealogyGraphStructureResult {
  return useMemo(
    () =>
      match(materialSetsResult)
        .with({ status: 'success' }, ({ materialSets }) =>
          getGenealogyGraphStructure(materialSets),
        )
        .otherwise((s) => s),
    [materialSetsResult],
  );
}

function effectEdges(effect: MaterialEffectDTO): GenealogyGraphEdge[] {
  return match(effect)
    .with({ kind: 'PurchasedMaterial' }, () => [])
    .with({ kind: 'InternallySourcedMaterial' }, () => [])
    .with({ kind: 'Partition' }, ({ whole, parts }) =>
      parts.map((p) => mEdge(whole, p)),
    )
    .with(
      { kind: 'PartitionMerge' },
      ({ part, destinationContents, merged }) => [
        mEdge(part, merged),
        mEdge(destinationContents, merged),
      ],
    )
    .with(
      { kind: 'TransferSplit' },
      ({ sourceContents, offshoot, remainder }) => [
        mEdge(sourceContents, offshoot),
        mEdge(sourceContents, remainder),
      ],
    )
    .with(
      {
        kind: 'TransferMerge',
      },
      ({ sourceContents, destinationContents, merged }) => [
        mEdge(sourceContents, merged),
        mEdge(destinationContents, merged),
      ],
    )
    .with(
      { kind: 'TransferIntermediateSplit' },
      ({ sourceContents, intermediate, remainder }) => [
        mEdge(sourceContents, intermediate),
        mEdge(sourceContents, remainder),
      ],
    )
    .with(
      { kind: 'TransferIntermediateMerge' },
      ({ intermediate, destinationContents, merged }) => [
        mEdge(intermediate, merged),
        mEdge(destinationContents, merged),
      ],
    )
    .with({ kind: 'ProcessBufferTransferSplit' }, (s) => [
      mEdge(s.sourceContents, s.remainder),
      mEdge(s.sourceContents, s.offshoot),
    ])
    .with({ kind: 'ProcessBufferTransferMerge' }, (m) => [
      mEdge(m.bufferContents, m.merged),
      mEdge(m.newFeedstock, m.merged),
    ])
    .with({ kind: 'TotalProcessFeedstock' }, (p) => [
      feedstockEdge(p.feedstock, p.feedFlowGroupId),
    ])
    .with({ kind: 'PartialProcessFeedstock' }, (p) => [
      mEdge(p.bufferContents, p.feedstock),
      feedstockEdge(p.feedstock, p.feedFlowGroupId),
      mEdge(p.bufferContents, p.bufferRemainder),
    ])
    .with({ kind: 'ProcessDirectOutput' }, (o) => [outputEdge(o)])
    .with({ kind: 'ProcessContainerOutputMerge' }, (m) => [
      mEdge(m.output, m.merged),
      mEdge(m.destinationContents, m.merged),
    ])
    .with({ kind: 'PartialSinkTransfer' }, (s) => [
      mEdge(s.sourceContents, s.sinkedMaterial),
      mEdge(s.sourceContents, s.remainder),
      internalSinkEdge(s.sinkedMaterial, s.sinkTransfer.internalSinkId),
    ])
    .with({ kind: 'TotalSinkTransfer' }, (s) => [
      internalSinkEdge(s.sourceContents, s.sinkTransfer.internalSinkId),
    ])
    .exhaustive();
}
