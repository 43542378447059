import { LinkText } from '../Link';
import { MaterialClassSetDTO } from '../rest-client';
import { Router } from '../router';

export function MaterialClassSetName(props: {
  materialClassSet: MaterialClassSetDTO;
}) {
  const { materialClassSet } = props;
  return (
    <LinkText
      to={Router.MaterialClassSetDetail({
        materialClassSetId: materialClassSet.id,
      })}
    >
      {materialClassSet.name}
    </LinkText>
  );
}
