import type { EChartsCoreOption } from 'echarts/core';
import { useMemo } from 'react';
import { EChart, EChartProps } from '../echarts/BareEChart';

export type ParticleSizeDistributionChartProps = {
  sizes: number[];
  binWidthInches: number;
  hideYLabel?: boolean;
  xMax?: number;
} & Omit<EChartProps<EChartsCoreOption>, 'option'>;

export function ParticleSizeDistributionChart(
  props: ParticleSizeDistributionChartProps,
) {
  const { sizes, binWidthInches, hideYLabel = false, xMax, ...rest } = props;

  const binnedData = useMemo(
    () => toBins(sizes, binWidthInches),
    [sizes, binWidthInches],
  );

  if (!binnedData) {
    return null;
  }

  const { bins, rangeStart, rangeEnd } = binnedData;
  // TODO(2186): Make x axis extend to range end

  return (
    <EChart
      option={{
        grid: {
          top: 10,
          bottom: 20,
        },
        xAxis: [
          {
            type: 'value',
            interval: binWidthInches * 2,
            axisLabel: {
              formatter: (v: number) => `${v}"`,
            },
            max: xMax === undefined ? rangeEnd : xMax + binWidthInches,
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              show: !hideYLabel,
            },
            axisTick: {
              show: !hideYLabel,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: 'bar',
            barWidth: '95%',
            data: bins.map((count, binIdx) => [
              rangeStart + binIdx * binWidthInches + binWidthInches / 2,
              count,
            ]),
          },
        ],
      }}
      {...rest}
    />
  );
}

interface BinnedData {
  rangeStart: number;
  rangeEnd: number;
  bins: number[];
}
function toBins(lengths: number[], binWidth: number): BinnedData | undefined {
  if (lengths.length === 0) return undefined;
  const minLength = Math.min(...lengths);
  const maxLength = Math.max(...lengths);

  const rangeStart = Math.floor(minLength / binWidth) * binWidth;
  const rangeEnd = Math.ceil(maxLength / binWidth) * binWidth;

  const numBins = Math.ceil((rangeEnd - rangeStart) / binWidth);
  const bins = new Array<number>(numBins).fill(0);

  // Assign each diameter to a bin
  for (const length of lengths) {
    const binIndex = Math.floor((length - rangeStart) / binWidth);
    bins[binIndex]++;
  }

  return {
    rangeStart,
    rangeEnd,
    bins,
  };
}
