import { ReactNode, createContext, useContext } from 'react';
import { MaterialSetDTO } from '../rest-client';

export interface GenealogyGraphContextState {
  rootMaterialSet: MaterialSetDTO;
  materialSetHashes: string[];
  addProducingEffect: (materialSet: MaterialSetDTO) => void;
  addConsumingEffect: (materialSet: MaterialSetDTO) => void;
}

const GenealogyGraphContext = createContext<
  GenealogyGraphContextState | undefined
>(undefined);

export function useGenealogyGraphCtx(): GenealogyGraphContextState {
  const ctx = useContext(GenealogyGraphContext);
  if (ctx === undefined) {
    throw new Error('component must be within a genealogy graph context');
  }

  return ctx;
}

export function GenealogyGraphProvider(props: {
  rootMaterialSet: MaterialSetDTO;
  materialSetHashes: string[];
  children: ReactNode;
  addProducingEffect: (materialSet: MaterialSetDTO) => void;
  addConsumingEffect: (materialSet: MaterialSetDTO) => void;
}) {
  const { children, ...value } = props;
  return (
    <GenealogyGraphContext.Provider value={value}>
      {children}
    </GenealogyGraphContext.Provider>
  );
}
