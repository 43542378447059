import { AppPage } from '../App/AppPage';
import { TruckLoadIcon } from '../Icons';
import { LinkButton } from '../Link';
import { Router } from '../router';
import { TruckLoadTable } from './TruckLoadTable';

export function TruckLoadsPage() {
  return (
    <AppPage
      title='Truck Loads'
      titleRight={
        <LinkButton leftIcon={<TruckLoadIcon />} to={Router.TruckLoadAdd()}>
          Add Truck Load
        </LinkButton>
      }
    >
      <AppPage.Section>
        {/* TODO(2335): hook up the timestamp query */}
        <TruckLoadTable timestamp={undefined} />
      </AppPage.Section>
    </AppPage>
  );
}
