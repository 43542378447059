import {
  Alert,
  Box,
  Button,
  Drawer,
  Grid,
  Group,
  Loader,
  Skeleton,
  Stack,
  Tabs,
  Text,
  Title,
} from '@mantine/core';
import { ScrollAreaComponent } from '@mantine/core/lib/Modal/Modal.context';
import { useDisclosure } from '@mantine/hooks';
import {
  IconBinaryTree,
  IconBinaryTree2,
  IconBoxOff,
} from '@tabler/icons-react';
import { UseQueryResult } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { AppPage } from '../App/AppPage';
import { CompositionChart } from '../CompositionChart';
import { useFacilityContext } from '../Facility/FacilityContext';
import GenealogyGraph2 from '../GenealogyGraph/GenealogyGraph';
import { ContainerSamplingIcon, ScaleIcon } from '../Icons';
import { InternalMaterialSourceIdName } from '../InternalMaterialSource/InternalMaterialSourceIdName';
import { LinkText } from '../Link';
import { AllMaterialSetMasses } from '../Mass/MaterialSetMass';
import MaterialSetGlobalRecoveryGoalPaths from '../RecoveryGoal/MaterialSetGlobalRecoveryGoalPaths';
import {
  RedWaveClassMetadata,
  RedWaveCompositionChartControls,
  useRedWaveChartControls,
} from '../RedWaveCompositionChartControls';
import { VendorIdName } from '../Vendor/VendorIdName';
import { useInferredProperties } from '../api/inference';
import { useMaterialClassSets } from '../api/materialClassSet';
import { LabeledValue } from '../common';
import {
  ContainerSampleId,
  EmptyGenealogyResultDTO,
  LedgerErrorGenealogyResultDTO,
  ManualSampleAnalysisClaimDTO,
  MaterialSetDTO,
  OccupiedGenealogyResultDTO,
  RedWaveStream,
} from '../rest-client';
import { Router } from '../router';
import { composition, mixture } from '../util/mixture';
import cssClasses from './GenealogyExplorer.module.css';
import { OccupationIntervalContainerSampleForm } from './OccupationIntervalContainerSampleForm';
import {
  RepositoryOccupationIntervalScaleReadingForm,
  getOccupationIntervals,
} from './OccupationIntervalScaleReadingForm';

export const MaterialSetSelectionContext = createContext<
  | {
      selectedMaterialSet: MaterialSetDTO | null;
      setSelectedMaterialSet: Dispatch<SetStateAction<MaterialSetDTO | null>>;
    }
  | undefined
>(undefined);

export type GenealogyResultDTO =
  | LedgerErrorGenealogyResultDTO
  | EmptyGenealogyResultDTO
  | OccupiedGenealogyResultDTO;

type GenealogyQueryResult = UseQueryResult<GenealogyResultDTO>;

export function GenealogyExplorer(props: {
  genealogyQuery: GenealogyQueryResult;
}) {
  const { genealogyQuery } = props;

  const [selectedMaterialSet, setSelectedMaterialSet] =
    useState<MaterialSetDTO | null>(null);

  if (genealogyQuery.data?.status === 'empty') {
    return <EmptyMaterialDataSection />;
  }

  if (genealogyQuery.data?.status === 'error') {
    return <ErrantMaterialDataSection />;
  }

  const occupiedGenealogyResult = genealogyQuery.data;

  return (
    <MaterialSetSelectionContext.Provider
      value={{ selectedMaterialSet, setSelectedMaterialSet }}
    >
      <Grid className={cssClasses.wrapper}>
        <Grid.Col span={7}>
          <AppPage.Section h='100%'>
            <Stack h='100%' mih={1000}>
              <Title ml={'md'} order={3}>
                Material Derivation
              </Title>
              <GenealogyGraphSection
                occupiedGenealogyResult={occupiedGenealogyResult}
              />
            </Stack>
          </AppPage.Section>
        </Grid.Col>
        <Grid.Col span={5}>
          <MaterialDataSection
            occupiedGenealogyResult={occupiedGenealogyResult}
          />
        </Grid.Col>
      </Grid>
    </MaterialSetSelectionContext.Provider>
  );
}

export function EmptyMaterialDataSection() {
  return (
    <AppPage.Section>
      <Stack>
        <Title order={3}>Material Data</Title>
        <Alert color='blue' icon={<IconBoxOff />} title='Empty Container'>
          There is no material data to display because this material container
          is empty.
          {/* TODO(2315): Actions to change? */}
        </Alert>
      </Stack>
    </AppPage.Section>
  );
}

export function ErrantMaterialDataSection() {
  return (
    <AppPage.Section>
      <Stack>
        <Title order={3}>Material Data</Title>
        <Alert title='Ledger Error' color='orange'>
          {/* TODO(2315): Actions to resolve */}
          Material data cannot be computed because there is an error in the
          inventory ledger.
        </Alert>
      </Stack>
    </AppPage.Section>
  );
}

export function MaterialDataSection({
  occupiedGenealogyResult,
}: {
  occupiedGenealogyResult: OccupiedGenealogyResultDTO | undefined;
}) {
  const selectionContext = useContext(MaterialSetSelectionContext);
  if (selectionContext === undefined) {
    throw new Error(
      'MaterialSetDataSection must be in a MaterialSetSelectionContext.Provider',
    );
  }
  const { selectedMaterialSet } = selectionContext;

  const materialSet =
    selectedMaterialSet ??
    occupiedGenealogyResult?.materialSets[occupiedGenealogyResult.occupantHash];

  return (
    <SelectedMaterialDetailsSection
      materialSet={materialSet}
      readOnly={false}
    />
  );
}

export interface SelectedMaterialDetailsProps {
  materialSet: MaterialSetDTO | undefined;
  readOnly: boolean;
}

export function SelectedMaterialDetailsSection(
  props: SelectedMaterialDetailsProps,
) {
  const { materialSet, readOnly } = props;

  const facility = useFacilityContext();
  const [
    addWeightDrawerOpened,
    { open: openAddWeightDrawer, close: closeAddWeightDrawer },
  ] = useDisclosure(false);
  const [
    addContainerSampleDrawerOpened,
    {
      open: openAddContainerSampleDrawer,
      close: closeAddContainerSampleDrawer,
    },
  ] = useDisclosure(false);

  const occupationIntervals =
    materialSet && getOccupationIntervals(materialSet);

  const sampleAnalyses = materialSet?.manualSampleAnalysisClaims;

  return (
    <>
      {!readOnly && (
        <>
          <Drawer
            size='md'
            opened={addWeightDrawerOpened}
            onClose={closeAddWeightDrawer}
            title='Record Weight'
            position='right'
            styles={{ inner: { height: '100%' } }}
            scrollAreaComponent={Box as ScrollAreaComponent}
          >
            {occupationIntervals ? (
              <RepositoryOccupationIntervalScaleReadingForm
                key={materialSet.hash}
                occupationIntervals={occupationIntervals}
                onCancel={() => closeAddWeightDrawer()}
                onSuccess={() => closeAddWeightDrawer()}
              />
            ) : (
              <Alert color='red'>
                This material set has no time intervals where a weight could be
                recorded.
              </Alert>
            )}
          </Drawer>

          <Drawer
            size='md'
            opened={addContainerSampleDrawerOpened}
            onClose={closeAddContainerSampleDrawer}
            title='Add Container Sample'
            position='right'
            styles={{ inner: { height: '100%' } }}
            scrollAreaComponent={Box as ScrollAreaComponent}
          >
            {occupationIntervals ? (
              <OccupationIntervalContainerSampleForm
                key={materialSet.hash}
                samplableIntervals={occupationIntervals.filter(
                  (interval) => interval.kind === 'container',
                )}
                onClose={closeAddContainerSampleDrawer}
                onSuccess={(containerSampleId: ContainerSampleId) => {
                  Router.push('ContainerSampleDetail', {
                    containerSampleId,
                  });
                }}
              />
            ) : (
              <Alert color='red'>
                This material set has no time intervals where a container sample
                could be associated.
              </Alert>
            )}
          </Drawer>
        </>
      )}

      <AppPage.Section h='100%'>
        <Stack key={materialSet?.hash}>
          <Stack>
            <Title order={3}>Selected Material Details</Title>

            <LabeledValue label='Mass'>
              <Group>
                {materialSet ? (
                  <AllMaterialSetMasses
                    materialSet={materialSet}
                    showDeleteButton={!readOnly}
                  />
                ) : (
                  <Skeleton w={140}>Loading...</Skeleton>
                )}
                {!readOnly &&
                materialSet?.massClaims.filter(
                  (mc) => mc.kind !== 'redwave-normal-estimate',
                ).length === 0 &&
                occupationIntervals ? (
                  <Button
                    size='sm'
                    compact
                    variant='outline'
                    leftIcon={<ScaleIcon />}
                    onClick={openAddWeightDrawer}
                  >
                    Record Weight
                  </Button>
                ) : undefined}
              </Group>
            </LabeledValue>

            {/* TODO(2315): Commodity */}

            {materialSet ? (
              <MaterialSetGlobalRecoveryGoalPaths materialSet={materialSet} />
            ) : (
              <Skeleton>Loading...</Skeleton>
            )}

            {/* TODO(2315): Render different titles for different producing effects */}
            {materialSet?.producingEffect.kind ===
            'InternallySourcedMaterial' ? (
              <LabeledValue label='Upstream Source'>
                <InternalMaterialSourceIdName
                  internalMaterialSourceId={
                    materialSet.producingEffect.internalMaterialSourceId
                  }
                />
              </LabeledValue>
            ) : undefined}

            {materialSet?.producingEffect.kind === 'PurchasedMaterial' ? (
              <LabeledValue label='Vendor'>
                <VendorIdName vendorId={materialSet.producingEffect.vendorId} />
              </LabeledValue>
            ) : undefined}

            <LabeledValue label='Container Samples'>
              <Group>
                {materialSet ? (
                  <Stack>
                    {sampleAnalyses !== undefined ? (
                      sampleAnalyses.length > 0 ? (
                        sampleAnalyses.map((sample) => (
                          <LinkText
                            to={Router.ContainerSampleDetail({
                              containerSampleId: sample.containerSampleId,
                            })}
                            key={sample.containerSampleId}
                          >
                            {sample.containerName} on{' '}
                            {dayjs
                              .utc(sample.sampleTakenAt)
                              .tz(facility.timeZoneId)
                              .format('l')}
                          </LinkText>
                        ))
                      ) : (
                        <Text c='dimmed'>None</Text>
                      )
                    ) : (
                      <Skeleton>Loading...</Skeleton>
                    )}
                  </Stack>
                ) : (
                  <Skeleton w={140}>Loading...</Skeleton>
                )}
                {!readOnly && occupationIntervals && (
                  <Button
                    size='sm'
                    compact
                    variant='outline'
                    leftIcon={<ContainerSamplingIcon />}
                    onClick={openAddContainerSampleDrawer}
                  >
                    Add Sample
                  </Button>
                )}
              </Group>
            </LabeledValue>

            <Title order={3}>Composition</Title>
            {materialSet ? (
              <CompositionalData materialSet={materialSet} />
            ) : (
              <Skeleton />
            )}
          </Stack>
        </Stack>
      </AppPage.Section>
    </>
  );
}

export function CompositionalData({
  materialSet,
}: {
  materialSet: MaterialSetDTO;
}) {
  const materialClassSetsQuery = useMaterialClassSets();
  const mcsLookup =
    materialClassSetsQuery.data &&
    new Map(materialClassSetsQuery.data.map((mcs) => [mcs.id, mcs]));
  const inferredPropertiesQuery = useInferredProperties(materialSet);

  const sampleAnalyses = materialSet.manualSampleAnalysisClaims;
  const inferredCompositions =
    inferredPropertiesQuery.data?.materialClassCompositions;

  const redWaveAgg = materialSet.redWaveFlowAggregation;
  const redWaveInferredMixtures =
    inferredPropertiesQuery.data?.redWaveMaterialClassAreaMixtures;

  const facility = useFacilityContext();

  if (
    sampleAnalyses.length === 0 &&
    !redWaveAgg &&
    redWaveInferredMixtures !== undefined &&
    redWaveInferredMixtures.length === 0 &&
    inferredCompositions !== undefined &&
    Object.keys(inferredCompositions).length === 0
  ) {
    return (
      <Alert color='blue' title='No Composition Data'>
        No sort system material class composition or hand sampled composition
        was directly measured on this set of material nor could it be inferred.
      </Alert>
    );
  }

  return (
    <Tabs
      variant='outline'
      defaultValue={
        sampleAnalyses.at(0)?.manualSampleAnalysisId ??
        (inferredCompositions ? Object.keys(inferredCompositions) : []).at(0) ??
        (redWaveAgg && 'redwave-claimed-mixture') ??
        ((redWaveInferredMixtures?.length ?? 0) > 0
          ? `redwave-inferred-mixture-0`
          : 'empty')
      }
    >
      <Tabs.List>
        {sampleAnalyses.map((a) => (
          <Tabs.Tab
            value={a.manualSampleAnalysisId}
            key={a.manualSampleAnalysisId}
            icon={<ContainerSamplingIcon />}
          >
            {a.containerName} on{' '}
            {dayjs.utc(a.sampleTakenAt).tz(facility.timeZoneId).format('l')}
          </Tabs.Tab>
        ))}
        {inferredCompositions &&
          Object.entries(inferredCompositions).map(([mcsId]) => (
            <Tabs.Tab
              key={mcsId}
              value={mcsId}
              icon={<ContainerSamplingIcon />}
            >
              {mcsLookup?.get(mcsId)?.name ?? '...'}
            </Tabs.Tab>
          ))}
        {redWaveAgg && (
          <Tabs.Tab
            key='redwave-claimed-mixture'
            value='redwave-claimed-mixture'
            icon={<IconBinaryTree2 size='1.1rem' />}
          >
            {redWaveAgg.sortProgramName}
          </Tabs.Tab>
        )}
        {redWaveInferredMixtures?.map((inferredRedWaveMixture, i) => (
          <Tabs.Tab
            key={i}
            value={`redwave-inferred-mixture-${i}`}
            icon={<IconBinaryTree size='1.1rem' />}
          >
            {inferredRedWaveMixture.currentSortProgramName}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {sampleAnalyses.map((a) => (
        <Tabs.Panel
          key={a.manualSampleAnalysisId}
          value={a.manualSampleAnalysisId}
          pt='sm'
        >
          <SampleAnalysisCompositionalChart sampleAnalysisClaim={a} />
        </Tabs.Panel>
      ))}
      {inferredCompositions &&
        Object.entries(inferredCompositions).map(([mcsId, inference]) => {
          const mcs = mcsLookup?.get(mcsId);
          if (!mcs) {
            return <Loader key={mcsId} />;
          }
          const mcNames = new Map(
            mcs.materialClasses.map((mc) => [mc.id, mc.name]),
          );
          const comp = composition(
            Object.entries(inference.materialClassComposition).map(
              ([mcId, p]) => [mcNames.get(mcId) ?? '...', p],
            ),
          );
          return (
            <Tabs.Panel key={mcsId} value={mcsId} pt='sm'>
              <CompositionChart composition={comp} rotateXAxisLabels />
            </Tabs.Panel>
          );
        })}
      {redWaveAgg && (
        <Tabs.Panel
          pt='sm'
          key='redwave-claimed-mixture'
          value='redwave-claimed-mixture'
        >
          <RedWaveAreaCompositionChart
            areas={redWaveAgg.materialClassAreas}
            stream={redWaveAgg.eject ? RedWaveStream.EJECT : RedWaveStream.PASS}
            classMetadata={redWaveAgg.materialClasses}
          />
        </Tabs.Panel>
      )}

      {redWaveInferredMixtures?.map((inferredMixture, i) => (
        <Tabs.Panel
          pt='sm'
          key={`redwave-inferred-mixture-${i}`}
          value={`redwave-inferred-mixture-${i}`}
        >
          <RedWaveAreaCompositionChart
            areas={inferredMixture.materialClassAreas}
            stream={inferredMixture.stream}
            classMetadata={inferredMixture.materialClasses}
          />
        </Tabs.Panel>
      ))}

      {/* TODO(chutec): Chutec Tab Panes */}
    </Tabs>
  );
}

function SampleAnalysisCompositionalChart(props: {
  sampleAnalysisClaim: ManualSampleAnalysisClaimDTO;
}) {
  const {
    sampleAnalysisClaim: { materialClassSet, materialClassComposition },
  } = props;

  const massMixture = mixture(
    materialClassSet.materialClasses.map((materialClass) => [
      materialClass.name,
      materialClassComposition[materialClass.id],
    ]),
  );

  return (
    <CompositionChart
      composition={massMixture.composition()}
      rotateXAxisLabels
    />
  );
}

function RedWaveAreaCompositionChart(props: {
  areas: Record<string, number>;
  classMetadata: RedWaveClassMetadata;
  stream: RedWaveStream;
}) {
  const { areas, classMetadata, stream } = props;

  const areaMixture = mixture(
    Object.entries(areas).map(([classNumber, area]) => [
      Number(classNumber),
      area,
    ]),
  );

  const initiallySelectedClasses = areaMixture
    .submixture((c) => c != 0)
    .topKeys(7);

  const { chartComposition, controlState, setControlState } =
    useRedWaveChartControls(classMetadata, {
      backgroundClassIncluded: false,
      renormalize: false,
      selectedClasses: new Set(initiallySelectedClasses),
    });

  return (
    <>
      <RedWaveCompositionChartControls
        classMetadata={classMetadata}
        stream={stream}
        value={controlState}
        onChange={setControlState}
      />
      <CompositionChart composition={chartComposition(areaMixture)} />
    </>
  );
}

export function GenealogyGraphSection({
  occupiedGenealogyResult,
}: {
  occupiedGenealogyResult: OccupiedGenealogyResultDTO | undefined;
}) {
  const selectionContext = useContext(MaterialSetSelectionContext);
  if (selectionContext === undefined) {
    throw new Error(
      'Genealogy graph must be within a MaterialSetSelectionContext',
    );
  }
  const { setSelectedMaterialSet } = selectionContext;

  if (occupiedGenealogyResult === undefined) {
    return <Skeleton h='100%' w='100%' />;
  }

  return (
    <GenealogyGraph2
      setSelectedMaterialSet={setSelectedMaterialSet}
      rootMaterialSet={
        occupiedGenealogyResult.materialSets[
          occupiedGenealogyResult.occupantHash
        ]
      }
    />
  );
}
