import {
  Badge,
  Box,
  Card,
  Center,
  Flex,
  Grid,
  Group,
  Loader,
  Paper,
  Stack,
  Table,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import dayjs, { Dayjs } from 'dayjs';
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { P, match } from 'ts-pattern';
import { ContainerIdName } from '../Container/ContainerIdName';
import { useFacilityContext } from '../Facility/FacilityContext';
import NetWeight from '../Weights/NetWeight';
import { useDeleteProcessStop } from '../api/processStop';
import {
  useProductionIntervalMassStats,
  useProductionIntervalStatus,
} from '../api/production';
import { DeleteButtonWithConfirmation, LabeledValue } from '../common';
import { EChart } from '../echarts/BareEChart';
import {
  FeedFlowGroupDTO,
  ProcessPlannedProductionIntervalId,
  ProcessStopStatusDTO,
  ProductionIntervalAnalysisProcessState,
} from '../rest-client';
import { Router } from '../router';
import {
  analyzeProductionInterval,
  analyzeProductionIntervalPattern,
} from './ProcessProductionIntervalStatusTable';
import { ProductionIntervalDetail } from './ProductionIntervalDetail';
import cssClasses from './ProductionIntervalStatus.module.css';
import { ProductionIntervalTimelineChart } from './ProductionIntervalTimelineChart';

echarts.use([PieChart]);

const durationFormat = 'H[h] m[m]';

interface FeedFlowGroupEvent {
  kind: 'feed-flow-group';
  feedFlowGroup: FeedFlowGroupDTO;
  start: Dayjs;
}

interface ProcessStopEvent {
  kind: 'stop';
  stopStatus: ProcessStopStatusDTO;
  start: Dayjs | undefined;
}

type ProductionIntervalEvent = FeedFlowGroupEvent | ProcessStopEvent;

export function ProductionIntervalStatus(props: {
  productionIntervalId: ProcessPlannedProductionIntervalId;
}) {
  const { productionIntervalId } = props;

  const statusQuery = useProductionIntervalStatus(productionIntervalId);
  const massStatsQuery = useProductionIntervalMassStats(productionIntervalId);
  const theme = useMantineTheme();

  if (statusQuery.isLoadingError) {
    throw statusQuery.error;
  }

  if (statusQuery.data) {
    const {
      feedFlowGroups,
      processOutputPorts,
      stopStatuses,
      subIntervalStates,
    } = statusQuery.data;

    const outputPortNameMap = new Map(
      processOutputPorts.map((pop) => [pop.outputPortId, pop.name]),
    );

    const {
      hours,
      activeHours,
      engagedHours,
      disengagedHours,
      faultedHours,
      issueHours,
    } = analyzeProductionInterval({
      subIntervalStates,
      ignoreUnplannedStops: false,
    });

    const activeDuration = dayjs.duration(activeHours, 'hours');
    const inactiveHours = analyzeProductionIntervalPattern({
      subIntervalStates,
      pattern: { active: false },
      ignoreUnplannedStops: false,
    });
    const inactiveDuration = dayjs.duration(inactiveHours, 'hours');

    const engagedDuration = dayjs.duration(engagedHours, 'hours');
    const disengagedDuration = dayjs.duration(disengagedHours, 'hours');
    const faultedDuration = dayjs.duration(faultedHours, 'hours');

    const issueDuration = dayjs.duration(issueHours, 'hours');
    const activeOrEngagedHours = analyzeProductionIntervalPattern({
      subIntervalStates,
      pattern: P.union(
        { active: true },
        { processState: ProductionIntervalAnalysisProcessState.ENGAGED },
      ),
      ignoreUnplannedStops: false,
    });
    const activeOrEngagedDuration = dayjs.duration(
      activeOrEngagedHours,
      'hours',
    );
    const noIssueAndNotActiveOrEngagedHours =
      hours - activeOrEngagedHours - issueHours;
    const noIssueAndNotActiveOrEngagedDuration = dayjs.duration(
      noIssueAndNotActiveOrEngagedHours,
      'hours',
    );

    const operationalHours = hours - issueHours;
    const operationalDuration = dayjs.duration(operationalHours, 'hours');

    const ffgEvents: FeedFlowGroupEvent[] = feedFlowGroups.map((ffg) => ({
      feedFlowGroup: ffg,
      kind: 'feed-flow-group',
      start: dayjs.utc(ffg.effectiveTimestamp),
    }));

    const processStopEvents: ProcessStopEvent[] = stopStatuses.map(
      (stopStatus) => ({
        stopStatus,
        kind: 'stop',
        start: match(stopStatus.stop.startBoundResult)
          .with({ status: 'success', instant: P.select() }, (t) => dayjs.utc(t))
          .otherwise(() => undefined),
      }),
    );

    const events: ProductionIntervalEvent[] = [
      ...ffgEvents,
      ...processStopEvents,
    ];
    events.sort((a, b) => {
      if (a.start && b.start) {
        return a.start.diff(b.start);
      }

      if (a.start) {
        return -1;
      }

      if (b.start) {
        return 1;
      }

      return 0;
    });

    return (
      <Stack align='stretch'>
        <Grid>
          <Grid.Col span={4}>
            <Paper
              p='md'
              shadow='xs'
              w='fit-content'
              className={cssClasses.productionIntervalDetailContainer}
            >
              <ProductionIntervalDetail
                productionIntervalId={productionIntervalId}
              />
            </Paper>
          </Grid.Col>
          <Grid.Col span={4}>
            <Stack spacing='lg' align='stretch'>
              <Card shadow='md' w='fit-content' radius='md'>
                <LabeledValue label='Processed Mass'>
                  {massStatsQuery.data ? (
                    match(massStatsQuery.data)
                      .with({ status: 'ledger-error' }, () => (
                        <Text color='red'>Ledger Error</Text>
                      ))
                      .with(
                        { status: 'success' },
                        ({
                          netWeightProcessed,
                          unknownFeedstockFlowGroupCount,
                        }) => (
                          <div
                            style={{
                              display: 'inline-flex',
                              gap: '0.5ch',
                              fontSize: theme.fontSizes.xl,
                            }}
                          >
                            <NetWeight
                              weight={netWeightProcessed}
                              sourceIconMode='icon-tooltip'
                            />{' '}
                            <Text color='dimmed'>
                              {unknownFeedstockFlowGroupCount > 0
                                ? ` + ${unknownFeedstockFlowGroupCount} unknown runs`
                                : ''}
                            </Text>
                          </div>
                        ),
                      )
                      .exhaustive()
                  ) : (
                    <Text color='dimmed'>Loading...</Text>
                  )}
                </LabeledValue>
              </Card>
              <Group spacing='lg' align='stretch'>
                <Card shadow='md' w='fit-content' radius='md'>
                  <LabeledValue
                    label='Operational Time'
                    infoIconContent='(Total Time - Unplanned Stop Time - System Fault Time) / Total Time'
                  >
                    <Text weight='bold' size='lg'>
                      {operationalDuration.format(durationFormat)} (
                      {((100 * operationalHours) / hours).toFixed(1)}
                      %)
                    </Text>
                  </LabeledValue>
                </Card>

                <Card shadow='md' w='fit-content' radius='md'>
                  <LabeledValue
                    label='Operational Activity Rate'
                    infoIconContent='Active Time / Operational Time'
                  >
                    <Text weight='bold' size='lg'>
                      {((100 * activeHours) / operationalHours).toFixed(1)}%
                    </Text>
                  </LabeledValue>
                </Card>
              </Group>
            </Stack>
          </Grid.Col>
          <Grid.Col span={4}>
            <Flex justify='flex-start' wrap='nowrap'>
              <EChart
                className={cssClasses.productionIntervalActivityPieChart}
                option={{
                  tooltip: {
                    trigger: 'item',
                    valueFormatter: hoursDurationFormatter,
                  },
                  series: [
                    {
                      type: 'pie',
                      startAngle: 180,
                      radius: ['40%', '70%'],
                      data: [
                        {
                          value: activeHours,
                          name: 'Active',
                          itemStyle: { color: theme.colors.green[5] },
                        },
                        {
                          value: inactiveHours,
                          name: 'Inactive',
                          itemStyle: { color: theme.colors.gray[5] },
                        },
                      ],
                    },
                  ],
                }}
              />

              <Flex direction='column' justify='space-evenly'>
                <Card w='max-content' shadow='md'>
                  <LabeledValue
                    label='Active'
                    infoIconContent='Total time where any material flow was observed through the process.'
                  >
                    <Text weight='bold' color='green'>
                      {activeDuration.format(durationFormat)} (
                      {((100 * activeHours) / hours).toFixed(1)}%)
                    </Text>
                  </LabeledValue>
                </Card>
                <Card w='max-content' shadow='md'>
                  <LabeledValue
                    label='Inactive'
                    infoIconContent='Total time where there was no material flowing through the process.'
                  >
                    <Text weight='bold'>
                      {inactiveDuration.format(durationFormat)} (
                      {((100 * inactiveHours) / hours).toFixed(1)}%)
                    </Text>
                  </LabeledValue>
                </Card>
              </Flex>
            </Flex>
          </Grid.Col>
          <Grid.Col span={8}>
            <Box miw='30rem' className={cssClasses.timelineChartContainer}>
              <ProductionIntervalTimelineChart status={statusQuery.data} />
            </Box>
          </Grid.Col>
          <Grid.Col span={4}>
            <Stack>
              <Flex justify='flex-start' wrap='nowrap'>
                <EChart
                  className={cssClasses.productionIntervalActivityPieChart}
                  option={{
                    tooltip: {
                      trigger: 'item',
                      valueFormatter: hoursDurationFormatter,
                    },
                    series: [
                      {
                        type: 'pie',
                        startAngle: 180,
                        radius: ['40%', '70%'],
                        data: [
                          {
                            value: engagedHours,
                            name: 'Engaged',
                            itemStyle: { color: theme.colors.blue[8] },
                          },
                          {
                            value: disengagedHours,
                            name: 'Disengaged',
                            itemStyle: { color: theme.colors.gray[5] },
                          },
                          {
                            value: faultedHours,
                            name: 'Fault',
                            itemStyle: { color: theme.colors.red[3] },
                          },
                        ],
                      },
                    ],
                  }}
                />
                <Flex direction='column' justify='space-evenly' gap='sm'>
                  <Card w='max-content' shadow='md'>
                    <LabeledValue
                      label='Engaged'
                      infoIconContent='Total time where systems are engaged.'
                      className={cssClasses.productionIntervalLabel}
                    >
                      <Text weight='bold' color='blue'>
                        {engagedDuration.format(durationFormat)} (
                        {((100 * engagedHours) / hours).toFixed(1)}
                        %)
                      </Text>
                    </LabeledValue>
                  </Card>
                  <Card w='max-content' shadow='md'>
                    <LabeledValue
                      label='Disengaged'
                      infoIconContent='Total time where the systems are disengaged, not engaged or faulted.'
                      className={cssClasses.productionIntervalLabel}
                    >
                      <Text weight='bold' color='gray'>
                        {disengagedDuration.format(durationFormat)} (
                        {((100 * disengagedHours) / hours).toFixed(1)}
                        %)
                      </Text>
                    </LabeledValue>
                  </Card>
                  <Card w='max-content' shadow='md'>
                    <LabeledValue
                      label='Faulted'
                      infoIconContent='Total time where there was a system fault.'
                      className={cssClasses.productionIntervalLabel}
                    >
                      <Text weight='bold' color='red'>
                        {faultedDuration.format(durationFormat)} (
                        {((100 * faultedHours) / hours).toFixed(1)}
                        %)
                      </Text>
                    </LabeledValue>
                  </Card>
                </Flex>
              </Flex>
              <Flex justify='flex-start' wrap='nowrap'>
                <EChart
                  className={cssClasses.productionIntervalActivityPieChart}
                  option={{
                    tooltip: {
                      trigger: 'item',
                      valueFormatter: hoursDurationFormatter,
                    },
                    series: [
                      {
                        type: 'pie',
                        startAngle: 180,
                        radius: ['40%', '70%'],
                        data: [
                          {
                            value: activeOrEngagedHours,
                            name: 'Processing',
                            itemStyle: { color: theme.colors.blue[8] },
                          },
                          {
                            value: hours - engagedHours - issueHours,
                            name: 'Other',
                            itemStyle: { color: theme.colors.gray[5] },
                          },
                          {
                            value: issueHours,
                            name: 'Issue',
                            itemStyle: { color: theme.colors.red[3] },
                          },
                        ],
                      },
                    ],
                  }}
                />
                <Flex direction='column' justify='space-evenly' gap='sm'>
                  <Card w='max-content' shadow='md'>
                    <LabeledValue
                      label='Processing'
                      infoIconContent='Total time where systems are engaged or material flow is observed.'
                      className={cssClasses.productionIntervalLabel}
                    >
                      <Text weight='bold' color='blue'>
                        {activeOrEngagedDuration.format(durationFormat)} (
                        {((100 * activeOrEngagedHours) / hours).toFixed(1)}
                        %)
                      </Text>
                    </LabeledValue>
                  </Card>
                  <Card w='max-content' shadow='md'>
                    <LabeledValue
                      label='Other'
                      infoIconContent='Total time where systems are not engaged or faulted, no material flow is observed, and there are no unplanned process stops.'
                      className={cssClasses.productionIntervalLabel}
                    >
                      <Text weight='bold' color='gray'>
                        {noIssueAndNotActiveOrEngagedDuration.format(
                          durationFormat,
                        )}{' '}
                        (
                        {(
                          (100 * noIssueAndNotActiveOrEngagedHours) /
                          hours
                        ).toFixed(1)}
                        %)
                      </Text>
                    </LabeledValue>
                  </Card>
                  <Card w='max-content' shadow='md'>
                    <LabeledValue
                      label='Issues'
                      infoIconContent='Total time the process was stopped due to an unplanned reason or there was a system fault.'
                      className={cssClasses.productionIntervalLabel}
                    >
                      <Text weight='bold' color='red'>
                        {issueDuration.format(durationFormat)} (
                        {((100 * issueHours) / hours).toFixed(1)}
                        %)
                      </Text>
                    </LabeledValue>
                  </Card>
                </Flex>
              </Flex>
            </Stack>
          </Grid.Col>
        </Grid>
        <Paper>
          <FeedFlowGroupTable
            feedFlowGroups={feedFlowGroups}
            outputPortNameMap={outputPortNameMap}
          />
        </Paper>
        <Paper shadow='md' w='fit-content'>
          <StopTable
            stops={stopStatuses}
            productionIntervalId={productionIntervalId}
          />
        </Paper>
      </Stack>
    );
  }

  return (
    <Center>
      <Loader size='xl' variant='bars' />
    </Center>
  );
}

function StopTable(props: {
  stops: ProcessStopStatusDTO[];
  productionIntervalId: string;
}) {
  const { stops, productionIntervalId } = props;
  const facility = useFacilityContext();
  const deleteMutation = useDeleteProcessStop();

  return (
    <Table>
      <thead>
        <tr>
          <th>Start</th>
          <th>End</th>
          <th>Duration</th>
          <th>Reason</th>
          <th>Notes</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {stops.map((stopStatus) => (
          <tr key={stopStatus.stop.id}>
            <td>
              {match(stopStatus.stop.startBoundResult)
                .with({ status: 'success' }, ({ instant }) =>
                  dayjs.utc(instant).tz(facility.timeZoneId).format('LT'),
                )
                .with({ status: 'AmbiguousAdjacentStop' }, () => (
                  <Text color='red'>
                    Previous process stop missing end time
                  </Text>
                ))
                .with({ status: 'OutOfRange' }, ({ outOfBoundsInstant }) => (
                  <Text color='red'>
                    The start time of{' '}
                    {dayjs
                      .utc(outOfBoundsInstant)
                      .tz(facility.timeZoneId)
                      .format('LLLL')}{' '}
                    is outside the production shift time interval.
                  </Text>
                ))
                .exhaustive()}
            </td>
            <td>
              {match(stopStatus.stop.endBoundResult)
                .with({ status: 'success' }, ({ instant }) =>
                  dayjs.utc(instant).tz(facility.timeZoneId).format('LT'),
                )
                .with({ status: 'AmbiguousAdjacentStop' }, () => (
                  <Text color='red'>Next process stop missing start time</Text>
                ))
                .with({ status: 'OutOfRange' }, ({ outOfBoundsInstant }) => (
                  <Text color='red'>
                    The end time of{' '}
                    {dayjs
                      .utc(outOfBoundsInstant)
                      .tz(facility.timeZoneId)
                      .format('LLLL')}{' '}
                    is outside the production shift time interval.
                  </Text>
                ))
                .exhaustive()}
            </td>
            <td>
              {stopStatus.stop.startBoundResult.status === 'success' &&
              stopStatus.stop.endBoundResult.status === 'success' ? (
                dayjs
                  .duration(
                    dayjs
                      .utc(stopStatus.stop.endBoundResult.instant)
                      .diff(
                        dayjs.utc(stopStatus.stop.startBoundResult.instant),
                      ),
                  )
                  .format(durationFormat)
              ) : (
                <Text color='red'>?</Text>
              )}
            </td>
            <td>
              <Badge
                color={stopStatus.stop.reason.unplanned ? 'red' : 'orange'}
                variant='outline'
              >
                {stopStatus.stop.reason.shorthandCode
                  ? stopStatus.stop.reason.shorthandCode + ' - '
                  : null}
                {stopStatus.stop.reason.name}
              </Badge>
            </td>
            <td>{stopStatus.stop.notes}</td>
            <td>
              {stopStatus.inverted ? (
                <Text color='red'>End before start</Text>
              ) : undefined}
              {stopStatus.stopOverlapErrors.map(({ otherStopId }) => (
                // TODO(2331): Indicate which stop
                <div key={otherStopId}>overlaps with another process stop</div>
              ))}
              <DeleteButtonWithConfirmation
                entityName='Process Stop'
                onConfirm={() => {
                  deleteMutation.mutate(
                    { productionIntervalId, stopId: stopStatus.stop.id },
                    {
                      onError() {
                        showNotification({
                          title: 'Error Deleting Process Stop',
                          message:
                            'An error occurred deleting the process stop.',
                          color: 'red',
                        });
                      },
                      onSuccess() {
                        showNotification({
                          title: 'Process Stop Deleted',
                          message:
                            'The process stop has successfully been deleted.',
                          color: 'teal',
                        });
                      },
                    },
                  );
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

// TODO rename to avoid confusion, or should this use FeedFlowGroupTable.tsx?
function FeedFlowGroupTable(props: {
  feedFlowGroups: FeedFlowGroupDTO[];
  outputPortNameMap: Map<string, string>;
}) {
  const { feedFlowGroups, outputPortNameMap } = props;
  const facility = useFacilityContext();

  const tableRows = feedFlowGroups.map((ffg) => {
    const outputPortCells = ffg.portFlowGroupsByKey.map(
      ({ outputPortId, portFlowGroups }) => {
        return (
          <td key={outputPortId}>
            <Stack>
              {portFlowGroups.map((pfg, i) =>
                pfg.containerId ? (
                  <ContainerIdName
                    key={i}
                    containerId={pfg.containerId}
                    time={null}
                  />
                ) : (
                  <Text key={i} color='red' weight='bold'>
                    dropped
                  </Text>
                ),
              )}
            </Stack>
          </td>
        );
      },
    );

    return (
      <tr
        key={ffg.id}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          Router.push('FeedFlowGroupDetail', { feedFlowGroupId: ffg.id })
        }
      >
        <td>
          {dayjs
            .utc(ffg.effectiveTimestamp)
            .tz(facility.timeZoneId)
            .format('LT')}
        </td>
        <td>
          {ffg.lastFlowIntervalEnd
            ? dayjs
                .utc(ffg.lastFlowIntervalEnd)
                .tz(facility.timeZoneId)
                .format('LT')
            : '-'}
        </td>
        <td>
          {Object.values(ffg.processState.effectiveConfigurationChanges).map(
            (configChange) => configChange.systemConfiguration.name,
          )}
        </td>
        {outputPortCells}
      </tr>
    );
  });

  return (
    <Table highlightOnHover>
      <thead>
        <tr>
          <th>Start</th>
          <th>End</th>
          <th>Config</th>
          {[...outputPortNameMap].map(([outputPortId, outputPortName]) => (
            <th key={outputPortId}>{outputPortName}</th>
          ))}
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </Table>
  );
}

function hoursDurationFormatter(v: number) {
  return dayjs.duration(v, 'hours').format(durationFormat);
}
