import { Text } from '@mantine/core';
import { LinkText } from '../../Link';
import { RedWaveSortProgramSnapshotDTO, SortSystemId } from '../../rest-client';
import { Router } from '../../router';

export interface RedWaveSortProgramNameProps {
  sortProgram: RedWaveSortProgramSnapshotDTO;
  sortSystemId: SortSystemId;
  noLink?: boolean;
}
export function RedWaveSortProgramName(props: RedWaveSortProgramNameProps) {
  const { sortProgram, sortSystemId, noLink = false } = props;
  if (noLink) {
    return <Text>{sortProgram.name}</Text>;
  }
  return (
    <LinkText
      to={Router.SortSystemRedWaveSortProgramDetail({
        sortSystemId: sortSystemId,
        sortProgramNumber: sortProgram.sortProgramNumber.toString(),
        timestamp: undefined,
      })}
    >
      {sortProgram.name}
    </LinkText>
  );
}
