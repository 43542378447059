import {
  ActionIcon,
  Alert,
  Center,
  Group,
  Loader,
  Paper,
  Stack,
  Title,
} from '@mantine/core';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { DeleteIcon, EditIcon } from '../Icons';
import { RecoveryGoalStepName } from '../RecoveryGoal/RecoveryGoalPathName';
import { RecoveryGoalTree } from '../RecoveryGoal/RecoveryGoalTree';
import {
  useDeleteRecoveryStrategy,
  useRecoveryStrategy,
} from '../api/recoveryStrategy';

import { openDeleteEntityConfirmModal } from '../Form/useDeleteEntityModal';
import { AddRecoveryStrategySimulationButton } from '../RecoveryStrategySimulation/AddRecoveryStrategySimulationButton';
import { RecoveryStrategySimulationTable } from '../RecoveryStrategySimulation/RecoveryStrategySimulationTable';
import { RecoveryStrategyDTO } from '../rest-client';
import { Router } from '../router';
import cssClasses from './RecoveryStrategyDetailPage.module.css';
import { RecoveryStrategyEditForm } from './RecoveryStrategyEditForm';

export default function RecoveryStrategyDetailPage(props: {
  recoveryStrategyId: string;
}) {
  const { recoveryStrategyId } = props;
  const { data: recoveryStrategy } = useRecoveryStrategy(recoveryStrategyId);

  const [editing, setEditing] = useState(false);

  return (
    <AppPage
      breadcrumbs={[
        {
          routeName: Router.RecoveryStrategyList(),
          title: 'Recovery Strategies',
        },
      ]}
      title={match(recoveryStrategy)
        .with(undefined, () => null)
        .with({ name: '' }, () => 'unnamed')
        .otherwise(({ name }) => name)}
    >
      {recoveryStrategy === undefined ? (
        <Center>
          <Loader variant='bars' size='xl' />
        </Center>
      ) : (
        <Stack>
          <AppPage.Section>
            <Stack>
              <Title order={3}>Recovery Sequence</Title>
              <RecoveryStrategySequenceDetail
                recoveryStrategy={recoveryStrategy}
                editing={editing}
                setEditing={setEditing}
              />
            </Stack>
          </AppPage.Section>
          <AppPage.Section>
            <Stack spacing='xs'>
              <Title order={3}>Recovery Strategy Behavior Tree</Title>
              <RecoveryStrategyBehaviorTree
                recoveryStrategy={recoveryStrategy}
                noLink
              />
            </Stack>
          </AppPage.Section>
          <AppPage.Section>
            <Stack>
              <Group>
                <Title order={3}>Recovery Strategy Simulations</Title>
                <AddRecoveryStrategySimulationButton
                  recoveryStrategyId={recoveryStrategyId}
                />
              </Group>
              <RecoveryStrategySimulationTable
                recoveryStrategyId={recoveryStrategyId}
              />
            </Stack>
          </AppPage.Section>
        </Stack>
      )}
    </AppPage>
  );
}

export function RecoveryStrategyBehaviorTree(props: {
  recoveryStrategy: RecoveryStrategyDTO;
  noLink?: boolean;
}) {
  const { recoveryStrategy, noLink } = props;
  return recoveryStrategy.trees.length > 0 ? (
    <Paper
      className={noLink ? '' : cssClasses.withLink}
      mt={0}
      key={recoveryStrategy.id}
      onClick={
        noLink
          ? undefined
          : () =>
              Router.push('RecoveryStrategyDetail', {
                recoveryStrategyId: recoveryStrategy.id,
              })
      }
      withBorder
      p='md'
    >
      <Group>
        {recoveryStrategy.trees.map((tree, i) => (
          <RecoveryGoalTree tree={tree} key={i} />
        ))}
      </Group>
    </Paper>
  ) : (
    <Alert title='No Recovery Goal Tree' color='blue'>
      There has been no recovery sequence configured for this recovery strategy.
      Please specify the recovery sequence to view the recovery goal behavior
      tree for this recovery stretegy.
    </Alert>
  );
}

export function RecoveryStrategySequenceDetail(props: {
  recoveryStrategy: RecoveryStrategyDTO;
  editing: boolean;
  setEditing: (value: boolean) => void;
}) {
  const { recoveryStrategy, editing, setEditing } = props;

  const deleteMutation = useDeleteRecoveryStrategy();

  const openDeleteModal = openDeleteEntityConfirmModal('Strategy', () =>
    deleteMutation.mutate(recoveryStrategy.id, {
      onSuccess() {
        Router.replace('RecoveryStrategyList');
      },
    }),
  );

  return (
    <Group spacing='md'>
      {editing ? (
        <Paper withBorder p={10}>
          <RecoveryStrategyEditForm
            recoveryStrategy={recoveryStrategy}
            onCancel={() => setEditing(false)}
            onComplete={() => setEditing(false)}
          />
        </Paper>
      ) : (
        <>
          {recoveryStrategy.paths.length === 0 ? (
            <Alert color='yellow'>No sequences have been specified.</Alert>
          ) : null}

          {/* TODO(2333): Render in group like RecoveryGoalPathName or just use RecoveryGoalPathName somehow */}
          {recoveryStrategy.paths.map((path) => (
            <Group key={path.id}>
              {path.steps.map((step) => (
                <RecoveryGoalStepName key={step.recoveryGoalId} step={step} />
              ))}
            </Group>
          ))}
        </>
      )}
      {!editing && (
        <Group spacing='xs'>
          <ActionIcon disabled={editing} onClick={() => setEditing(true)}>
            <EditIcon />
          </ActionIcon>
          <ActionIcon color='red' onClick={openDeleteModal} ml='auto'>
            <DeleteIcon />
          </ActionIcon>
        </Group>
      )}
    </Group>
  );
}
