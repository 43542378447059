/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContainerSampleAnalysesByMaterialClassSetDTO } from '../models/ContainerSampleAnalysesByMaterialClassSetDTO';
import type { ContainerSampleAnalysisCreationDTO } from '../models/ContainerSampleAnalysisCreationDTO';
import type { ContainerSampleAnalysisPatchDTO } from '../models/ContainerSampleAnalysisPatchDTO';
import type { ContainerSampleCreationDTO } from '../models/ContainerSampleCreationDTO';
import type { ContainerSampleDTO } from '../models/ContainerSampleDTO';
import type { ManualContainerSampleAnalysisDTO } from '../models/ManualContainerSampleAnalysisDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ContainerSamplingService {
    /**
     * @param containerId
     * @returns ContainerSampleDTO Success
     * @throws ApiError
     */
    public static getContainerSamples(
        containerId?: string,
    ): CancelablePromise<Array<ContainerSampleDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/container-samples',
            query: {
                'containerId': containerId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createContainerSample(
        requestBody: ContainerSampleCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/container-samples',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param containerSampleGuid
     * @returns ContainerSampleDTO Success
     * @throws ApiError
     */
    public static getContainerSampleById(
        containerSampleGuid: string,
    ): CancelablePromise<ContainerSampleDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/container-samples/{containerSampleGuid}',
            path: {
                'containerSampleGuid': containerSampleGuid,
            },
        });
    }
    /**
     * @param containerSampleGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteContainerSampleById(
        containerSampleGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/container-samples/{containerSampleGuid}',
            path: {
                'containerSampleGuid': containerSampleGuid,
            },
        });
    }
    /**
     * @returns ContainerSampleAnalysesByMaterialClassSetDTO Success
     * @throws ApiError
     */
    public static getMaterialClassSetsContainerSampleAnalyses(): CancelablePromise<ContainerSampleAnalysesByMaterialClassSetDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/container-samples/material-class-sets',
        });
    }
    /**
     * @param sampleId
     * @returns ManualContainerSampleAnalysisDTO Success
     * @throws ApiError
     */
    public static getContainerSampleAnalyses(
        sampleId?: string,
    ): CancelablePromise<Array<ManualContainerSampleAnalysisDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/container-samples/analyses',
            query: {
                'sampleId': sampleId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createContainerSampleAnalysis(
        requestBody: ContainerSampleAnalysisCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/container-samples/analyses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param containerSampleAnalysisGuid
     * @returns ManualContainerSampleAnalysisDTO Success
     * @throws ApiError
     */
    public static getContainerSampleAnalysisById(
        containerSampleAnalysisGuid: string,
    ): CancelablePromise<ManualContainerSampleAnalysisDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/container-samples/analyses/{containerSampleAnalysisGuid}',
            path: {
                'containerSampleAnalysisGuid': containerSampleAnalysisGuid,
            },
        });
    }
    /**
     * @param containerSampleAnalysisGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchContainerSampleAnalysis(
        containerSampleAnalysisGuid: string,
        requestBody: ContainerSampleAnalysisPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/container-samples/analyses/{containerSampleAnalysisGuid}',
            path: {
                'containerSampleAnalysisGuid': containerSampleAnalysisGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param containerSampleAnalysisGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteContainerSampleAnalysisById(
        containerSampleAnalysisGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/container-samples/analyses/{containerSampleAnalysisGuid}',
            path: {
                'containerSampleAnalysisGuid': containerSampleAnalysisGuid,
            },
        });
    }
}
