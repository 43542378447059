import { Alert, Badge, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { match } from 'ts-pattern';
import { useDeleteEntityModal } from '../../Form/useDeleteEntityModal.tsx';
import { ProcessBufferDepletionIcon } from '../../Icons';
import { ProcessIdName } from '../../Process/ProcessIdName';
import { EditProcessBufferDepletionDrawerForm } from '../../ProcessBufferDepletion/EditProcessBufferDepletionDrawerForm.tsx';
import { useDeleteProcessBufferDepletion } from '../../api/processBufferDepletion.ts';
import { CalendarDateTime } from '../../common';
import {
  BufferDepletionErrorDTO,
  ProcessBufferDepletionDTO,
} from '../../rest-client';
import { DeleteMenuItem } from '../DeleteMenuItem.tsx';
import { EditMenuItem } from '../EditMenuItem.tsx';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { useInventoryLedgerStatusContext } from '../LedgerStatusContext';
import { RowActionsMenu } from '../RowActionsMenu.tsx';

export function ProcessBufferDepletionRow(props: {
  processBufferDepletion: ProcessBufferDepletionDTO;
}) {
  const { processBufferDepletion } = props;

  const ledgerStatus = useInventoryLedgerStatusContext();
  const status = ledgerStatus.bufferDepletionStatus(processBufferDepletion);

  const deleteMutation = useDeleteProcessBufferDepletion();
  const openDeleteModal = useDeleteEntityModal(
    processBufferDepletion.id,
    deleteMutation,
    'Feedstock Depletion',
  );

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  let errorExplanationContent = null;
  if (status.status === 'conflict') {
    errorExplanationContent = (
      <>
        {status.errors.map((error, i) => (
          <ProcessBufferDepletionErrorExplaination
            bufferDepletionError={error}
            key={i}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <InventoryLedgerRowTemplate
        entryStatus={status}
        date={
          <CalendarDateTime
            iso8601={processBufferDepletion.effectiveTimestamp}
          />
        }
        eventType={
          <Group spacing='xs'>
            <ProcessBufferDepletionIcon />
            <Badge color='yellow'>Feedstock Depletion</Badge>
          </Group>
        }
        source={
          <ProcessIdName
            processId={processBufferDepletion.processId}
            withIcon
          />
        }
        destination={
          <Group spacing='xs'>
            <ProcessBufferDepletionIcon />
            <Text c='dimmed'>Feedstock Depleted</Text>
          </Group>
        }
        actions={
          <RowActionsMenu>
            <EditMenuItem onClick={openDrawer} />
            <DeleteMenuItem onClick={openDeleteModal} />
          </RowActionsMenu>
        }
      />
      {errorExplanationContent}
      <EditProcessBufferDepletionDrawerForm
        processBufferDepletionId={processBufferDepletion.id}
        opened={drawerOpened}
        onClose={closeDrawer}
      />
    </>
  );
}

function ProcessBufferDepletionErrorExplaination(props: {
  bufferDepletionError: BufferDepletionErrorDTO;
}) {
  const { bufferDepletionError } = props;
  return match(bufferDepletionError)
    .with({ kind: 'SimultaneousBufferDepletionError' }, (error) => (
      <Alert
        color='red'
        title='Process Feedstock Depletion Concurrency Conflict'
      >
        The feedstock depletion event fonclits with another feedstock depletion
        event for the same process:{' '}
        <ProcessIdName processId={error.depletionA.processId} />.
      </Alert>
    ))
    .exhaustive();
}
