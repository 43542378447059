import { Skeleton, Text } from '@mantine/core';
import { useProcess } from '../api/process';
import { ProcessName, ProcessNameProps } from './ProcessName';

export type ProcessIdNameProps = Omit<ProcessNameProps, 'process'> & {
  processId: string;
};

export function ProcessIdName(props: ProcessIdNameProps) {
  const { processId, ...rest } = props;
  const { data: process, isError } = useProcess(processId);

  if (process) {
    return <ProcessName process={process} {...rest} />;
  }

  if (isError) {
    return <Text color='red'>Error getting name</Text>;
  }

  return (
    <Skeleton visible>
      <Text>Loading name...</Text>
    </Skeleton>
  );
}
