import {
  Badge,
  Loader,
  Skeleton,
  Table,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { Dayjs } from 'dayjs';
import Fuse from 'fuse.js';
import { P, match } from 'ts-pattern';
import { MaterialSetCommodity } from '../Commodity/MaterialSetCommodity';
import { ContainerTypeBadge } from '../ContainerType/ContainerTypeBadge.tsx';
import { useFacilityContext } from '../Facility/FacilityContext';
import {
  EmptyContainerIcon,
  FullContainerIcon,
  PartiallyFullContainerIcon,
} from '../Icons';
import { MaterialSetMass } from '../Mass/MaterialSetMass';
import { ContainerName } from '../Repository/RepositoryName';
import { TableEmptyBasicContent } from '../TableEmptyBasicContent.tsx';
import {
  useContainerStateLookup,
  useFacilityContainers,
  useOccupiedContainerStates,
} from '../api/container';
import { MaterialContainerDTO, MaterialSetDTO } from '../rest-client';

export interface ContainerTableProps {
  timestamp: Dayjs | undefined;
  searchQuery?: string;
  showEmpty?: boolean;
  containerFilter?: (container: MaterialContainerDTO) => boolean;
  materialSetFilter?: (materialSet: MaterialSetDTO | null) => boolean;
}

export function ContainerTable(props: ContainerTableProps) {
  const {
    timestamp,
    showEmpty = false,
    searchQuery = '',
    containerFilter,
    materialSetFilter,
  } = props;

  const facility = useFacilityContext();
  const theme = useMantineTheme();
  const containersQuery = useFacilityContainers(facility.id);

  const occupiedContainerStatesQuery = useOccupiedContainerStates(
    facility.id,
    timestamp,
  );
  const getContainerState = useContainerStateLookup({
    facilityId: facility.id,
    timestamp,
  });

  const containersFiltered = containersQuery.data
    ?.filter((container) => containerFilter?.(container) ?? true)
    .filter((container) => {
      const containerState = getContainerState(container.id);

      return (
        match(containerState)
          // We can't do any container state filtering if there are ledger errors
          .with({ status: 'ledger-error' }, () => true)
          .with(
            { status: 'occupied' },
            ({ state }) => materialSetFilter?.(state.materialSet) ?? true,
          )
          .with({ status: 'empty' }, () => showEmpty)
          .with({ status: 'loading' }, () => materialSetFilter === undefined)
          .with({ status: 'request-error' }, () => true)
          .exhaustive()
      );
    });

  const fuse = new Fuse(containersFiltered ?? [], {
    keys: ['name'],
    threshold: 0.0,
    ignoreLocation: true,
  });
  const searchResults = fuse.search(searchQuery);
  const searchedContainers =
    searchQuery.length > 0
      ? searchResults.map((result) => result.item)
      : containersFiltered;

  const isLoading =
    containersQuery.isLoading ||
    (materialSetFilter && occupiedContainerStatesQuery.isLoading);
  const isEmpty = searchedContainers && searchedContainers.length === 0;

  return (
    <Table>
      {(isLoading || isEmpty) && (
        <caption
          style={{
            captionSide: 'bottom',
            textAlign: 'center',
            padding: theme.spacing.md,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <TableEmptyBasicContent>No Containers</TableEmptyBasicContent>
          )}
        </caption>
      )}
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Commodity</th>
          <th style={{ textAlign: 'right' }}>Net Weight</th>
          <th style={{ textAlign: 'center' }}>Fullness</th>
        </tr>
      </thead>
      <tbody>
        {searchedContainers?.map((container) => {
          const containerState = getContainerState(container.id);
          return (
            <tr key={container.id}>
              <td>
                <ContainerName
                  container={container}
                  variant='name-only-link'
                  time={timestamp ?? null}
                />
              </td>
              <td>
                <ContainerTypeBadge containerType={container.containerType} />
              </td>
              <td>
                {match(containerState)
                  .with({ status: 'loading' }, () => (
                    <Text color='dimmed' size='xs'>
                      Loading...
                    </Text>
                  ))
                  .with({ status: 'request-error' }, () => (
                    <Text size='xs'>Error</Text>
                  ))
                  .with({ status: 'empty' }, () => (
                    <Badge color='gray' variant='outline'>
                      Empty
                    </Badge>
                  ))
                  .with({ status: 'ledger-error' }, () => (
                    <Text color='red' size='xs'>
                      Ledger Error
                    </Text>
                  ))
                  .with(
                    { status: 'occupied', state: P.select() },
                    ({ materialSet }) => (
                      <MaterialSetCommodity materialSet={materialSet} />
                    ),
                  )
                  .exhaustive()}
              </td>
              <td style={{ textAlign: 'right' }}>
                {match(containerState)
                  .with({ status: 'loading' }, () => (
                    <Text color='dimmed' size='xs'>
                      Loading...
                    </Text>
                  ))
                  .with({ status: 'request-error' }, () => (
                    <Text size='xs'>Error</Text>
                  ))
                  .with({ status: 'empty' }, () => (
                    <Badge color='gray' variant='outline'>
                      Empty
                    </Badge>
                  ))
                  .with({ status: 'ledger-error' }, () => (
                    <Text color='red' size='xs'>
                      Ledger Error
                    </Text>
                  ))
                  .with(
                    { status: 'occupied', state: P.select() },
                    ({ materialSet }) => (
                      <MaterialSetMass materialSet={materialSet} />
                    ),
                  )
                  .exhaustive()}
              </td>
              <td style={{ textAlign: 'center' }}>
                {match(containerState)
                  .with({ status: 'loading' }, () => (
                    <Skeleton visible width='1ch' />
                  ))
                  .with({ status: 'request-error' }, () => (
                    <Text size='xs'>Error</Text>
                  ))
                  .with({ status: 'empty' }, () => <EmptyContainerIcon />)
                  .with({ status: 'ledger-error' }, () => (
                    <Text color='red' size='xs'>
                      Ledger Error
                    </Text>
                  ))
                  .with(
                    { status: 'occupied', state: P.select() },
                    ({ full }) =>
                      full ? (
                        <FullContainerIcon />
                      ) : (
                        <PartiallyFullContainerIcon />
                      ),
                  )
                  .exhaustive()}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
