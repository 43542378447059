import {
  Avatar,
  Center,
  Flex,
  Grid,
  Group,
  Stack,
  Table,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { IconDatabaseOff } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { ContainerIdName } from '../Container/ContainerIdName';
import { DurationTooltip, IntradayDateTime } from '../common';
import { PortFlowGroupDTO, PortFlowGroupsByKeyDTO } from '../rest-client';

const TableEmptyBasicContent = () => (
  <>
    <Center>
      <Avatar radius='xl'>
        <IconDatabaseOff />
      </Avatar>
    </Center>
    <Text size='sm' color='dimmed'>
      No Flows
    </Text>
  </>
);

export function PortFlowGroupOutputRow({
  portFlowGroup,
}: {
  portFlowGroup: PortFlowGroupDTO;
}) {
  return (
    <tr>
      <td>
        <IntradayDateTime iso8601={portFlowGroup.flowInterval.start} />
      </td>
      <td>
        <DurationTooltip
          startIso8601={portFlowGroup.flowInterval.start}
          endIso8601={portFlowGroup.flowInterval.end}
        />
      </td>
      <td>
        {portFlowGroup.containerId ? (
          <ContainerIdName
            containerId={portFlowGroup.containerId}
            time={dayjs.utc(portFlowGroup.flowInterval.end)}
          />
        ) : (
          <Text c='red' weight='bold'>
            Dropped
          </Text>
        )}
      </td>
    </tr>
  );
}

export const FeedFlowGroupOutput = ({
  portFlowGroupsByKey,
}: {
  portFlowGroupsByKey: PortFlowGroupsByKeyDTO[];
}) => {
  const theme = useMantineTheme();
  return (
    <Grid>
      {portFlowGroupsByKey.map(
        ({ outputPortId, outputPortName, portFlowGroups }) => {
          const isEmpty = portFlowGroups.length === 0;
          return (
            <Grid.Col span='auto' key={outputPortId}>
              <Stack>
                <Group position='apart'>
                  <Flex align={'baseline'} c='teal'>
                    <Title order={3} mr='xs'>
                      {outputPortName}
                    </Title>
                  </Flex>
                </Group>
                <Table>
                  {isEmpty && (
                    <caption
                      style={{
                        captionSide: 'bottom',
                        textAlign: 'center',
                        padding: theme.spacing.md,
                      }}
                    >
                      <TableEmptyBasicContent />
                    </caption>
                  )}
                  <thead>
                    <tr>
                      <th>Time Stamp</th>
                      <th>Duration</th>
                      <th>Destination</th>
                    </tr>
                  </thead>
                  <tbody>
                    {portFlowGroups.map((portFlowGroup) => {
                      return (
                        <PortFlowGroupOutputRow
                          key={outputPortId}
                          portFlowGroup={portFlowGroup}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </Stack>
            </Grid.Col>
          );
        },
      )}
    </Grid>
  );
};
