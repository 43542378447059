import { Alert, Button, Drawer, Group, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormActionButton } from '../Form/FormActionButton';
import { AddIcon } from '../Icons';
import { useMaterialClassSets } from '../api/materialClassSet';
import { useRecoveryStrategies } from '../api/recoveryStrategy';
import { useCreateRecoveryStrategySimulation } from '../api/recoveryStrategySimulation';
import {
  RecoveryStrategySimulationCreationDTO,
  RecoveryStrategySimulationId,
} from '../rest-client';
import { Router } from '../router';
import {
  RecoveryStrategySimulationFormFields,
  RecoveryStrategySimulationFormProps,
  useRecoveryStrategySimulationForm,
} from './RecoveryStrategySimulationForm';

export function AddRecoveryStrategySimulationButton(
  props: RecoveryStrategySimulationFormProps,
) {
  let { recoveryStrategyId, materialClassSetId } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const { data: materialClassSets } = useMaterialClassSets();
  const { data: recoveryStrategies } = useRecoveryStrategies();

  if (materialClassSets?.length === 1) {
    materialClassSetId = materialClassSets[0].id;
  }
  if (recoveryStrategies?.length === 1) {
    recoveryStrategyId = recoveryStrategies[0].id;
  }

  return (
    <>
      <Button color='teal' leftIcon={<AddIcon />} onClick={openDrawer}>
        Add Simulation
      </Button>
      <Drawer
        size='md'
        opened={drawerOpened}
        onClose={closeDrawer}
        title='Add Recovery Strategy Simulation'
        position='right'
        styles={{ inner: { height: '100%' } }}
      >
        <AddRecoveryStrategySimulationForm
          onCancel={closeDrawer}
          recoveryStrategyId={recoveryStrategyId}
          materialClassSetId={materialClassSetId}
        />
      </Drawer>
    </>
  );
}

export type AddRecoveryStrategySimulationFormProps =
  RecoveryStrategySimulationFormProps & {
    onCancel: () => void;
    onSuccess?: (id: RecoveryStrategySimulationId) => void;
  };

export function AddRecoveryStrategySimulationForm(
  props: AddRecoveryStrategySimulationFormProps,
) {
  const {
    onSuccess = (simulationId: RecoveryStrategySimulationId) => {
      Router.push('RecoveryStrategySimulationDetail', {
        simulationId,
      });
    },
    onCancel,
    recoveryStrategyId,
    materialClassSetId,
  } = props;

  const mutation = useCreateRecoveryStrategySimulation();
  const newSimulationId = useMemo(() => uuidv4(), []);

  const form = useRecoveryStrategySimulationForm({
    recoveryStrategyId,
    materialClassSetId,
  });

  if (mutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Creating Recovery Strategy Simulation'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => mutation.reset()}
      >
        The recovery strategy simulation may or may not have been saved. You can
        clear the error and try again.
      </Alert>
    );
  }

  const onSubmit = form.onSubmit((formValues) => {
    if (
      formValues.recoveryStrategyId === null ||
      formValues.materialClassSetId === null ||
      formValues.name.length < 1
    ) {
      throw new Error();
    }

    const simulation: RecoveryStrategySimulationCreationDTO = {
      id: newSimulationId,
      name: formValues.name,
      recoveryStrategyId: formValues.recoveryStrategyId,
      materialClassSetId: formValues.materialClassSetId,
    };

    mutation.mutate(simulation, {
      onError() {
        showNotification({
          title: 'Error Creating Recovery Strategy Simulation',
          message: 'An error occurred creating recovery strategy simulation.',
          color: 'red',
          icon: <IconX />,
        });
      },
      onSuccess(_, { id: simulationId }) {
        showNotification({
          title: 'Recovery Strategy Simulation Created',
          message: 'The recovery strategy simulation was sucessfully recorded',
          color: 'green',
          icon: <IconCheck />,
        });
        onSuccess(simulationId);
      },
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack>
        <RecoveryStrategySimulationFormFields
          form={form}
          recoveryStrategyId={recoveryStrategyId}
          materialClassSetId={materialClassSetId}
        />

        <Group position='right' mt='md'>
          <FormActionButton
            action='cancel'
            onClick={onCancel}
            loading={mutation.isLoading}
          />
          <FormActionButton
            type='submit'
            action='saveCreation'
            loading={mutation.isLoading}
          >
            Add Simulation
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
