import { UrlString } from '@azure/msal-common';
import { Center, Loader, Stack, Title } from '@mantine/core';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { z } from 'zod';
import App from './App/App';
import NoDynamicConfig from './App/NoDynamicConfig';
import { setupClient } from './api/restClient';
import { OpenAPI } from './rest-client';

const isMsalAuthPopup = () =>
  UrlString.hashContainsKnownProperties(window.location.hash);

const rootElement = document.getElementById('root');
if (rootElement === null) {
  throw new Error('root element missing');
}
const root = ReactDOM.createRoot(rootElement);

// Show a loader while we wait for MSAL or for the dynamic config
root.render(
  <Center h='100vh'>
    <Stack>
      <Loader size='xl' variant='bars' color='teal' />
      <Title order={4} color='dimmed'>
        Loading...
      </Title>
    </Stack>
  </Center>,
);

const DynamicConfig = z.object({
  API_BASE_URL: z.string(),
  SENTRY: z
    .object({
      ENABLED: z.boolean().default(false),
      DSN: z.string().optional(),
      ENVIRONMENT: z.string().optional(),
      RELEASE: z.string().optional(),
    })
    .optional(),
});
type DynamicConfig = z.infer<typeof DynamicConfig>;

// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5091#issuecomment-1487404260
if (!isMsalAuthPopup()) {
  fetch('/config.json')
    .then((configResponse) => configResponse.json())
    .then((configJson) => {
      const config = DynamicConfig.parse(configJson);

      if (config.SENTRY && config.SENTRY.DSN && config.SENTRY.ENABLED) {
        console.debug('sentry init start');
        Sentry.init({
          dsn: config.SENTRY.DSN,
          release: config.SENTRY.RELEASE,
          environment: config.SENTRY.ENVIRONMENT,
          initialScope: {}, // TODO: unsure what this should contain
          autoSessionTracking: true,
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
              stickySession: true,
              minReplayDuration: 5000,
              maskAllInputs: false,
              maskAllText: false,
            }),
            Sentry.replayCanvasIntegration(),
          ],
          // Performance Monitoring
          tracesSampleRate: 1.0, //  Capture 100% of the transactions
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            '127.0.0.1',
            // TODO: Trace propogation target config
            // /^https:\/\/yourserver\.io\/api/,
          ],
          // Session Replay
          replaysSessionSampleRate: 1.0,
          replaysOnErrorSampleRate: 1.0,
        });
      } else {
        console.debug('sentry init skipped');
      }

      console.debug(`API base url: ${config.API_BASE_URL}`);
      OpenAPI.BASE = config.API_BASE_URL;
      setupClient({ baseUrl: config.API_BASE_URL });

      // Once we have our API base URL specified, we can actually render the real app
      root.render(<App />);
    })
    .catch((e) => {
      root.render(<NoDynamicConfig />);
      console.error(e);
    });
}
