import {
  Alert,
  Loader,
  Pagination,
  Stack,
  Table,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { RedWaveSortProgramName } from '../../SortProgram/redwave/RedWaveSortProgramName';
import { TableEmptyBasicContent } from '../../TableEmptyBasicContent';
import { useRedWaveCurrentSortProgramSnapshots } from '../../api/redWave';
import { SortSystemId } from '../../rest-client';
import cssClasses from './RedWaveSortProgramTable.module.css';

export function RedWaveSortProgramTable(props: {
  sortSystemId: SortSystemId;
  paginate?: boolean;
  pageSize?: number;
}) {
  const { sortSystemId, pageSize = 10, paginate = true } = props;
  const theme = useMantineTheme();
  const { data, isLoadingError, isLoading } =
    useRedWaveCurrentSortProgramSnapshots(sortSystemId);
  const [page, setPage] = useState(1);

  if (isLoadingError) {
    return (
      <Alert title='Error Loading System Sort Programs' color='red'>
        An error occurred loading Sort Program data for this Sort System.
      </Alert>
    );
  }
  const isEmpty = data && data.length == 0;
  const sortPrograms = data ?? [];

  const pages = Math.ceil(sortPrograms.length / pageSize);
  const from = (page - 1) * pageSize;
  const to = from + pageSize;
  const sortProgramsPage = paginate
    ? sortPrograms.slice(from, to)
    : sortPrograms;

  // TODO(534): add last updated, last used to RedWave SortProgram table
  return (
    <Stack>
      <Table>
        {(isLoading || isEmpty) && (
          <caption
            style={{
              captionSide: 'bottom',
              textAlign: 'center',
              padding: theme.spacing.md,
            }}
          >
            {isLoading && <Loader />}
            {!isLoading && isEmpty && (
              <TableEmptyBasicContent>No Sort Programs</TableEmptyBasicContent>
            )}
          </caption>
        )}
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Name</th>
            <th style={{ textAlign: 'right' }}>Number</th>
          </tr>
        </thead>
        <tbody>
          {sortProgramsPage.map((sp, idx) => (
            <tr key={idx}>
              <td>
                <RedWaveSortProgramName
                  sortProgram={sp}
                  sortSystemId={sortSystemId}
                />
              </td>
              <td style={{ textAlign: 'right' }}>{sp.sortProgramNumber + 1}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {paginate && (
        <Pagination
          value={page}
          onChange={setPage}
          total={pages}
          className={cssClasses.pagination}
        />
      )}
    </Stack>
  );
}
