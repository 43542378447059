import { Loader, Select, SelectProps } from '@mantine/core';
import { useFacilityContext } from '../Facility/FacilityContext';
import { useProcesses } from '../api/process';
import { ProcessId } from '../rest-client';

export type ProcessSelectBaseProps = {
  value: ProcessId | null;
  onChange: (id: ProcessId | null) => void;
};

export type ProcessSelectProps = Omit<
  SelectProps,
  keyof ProcessSelectBaseProps | 'data' | 'rightSection'
> &
  ProcessSelectBaseProps;

export function ProcessSelect(props: ProcessSelectProps) {
  const {
    value,
    onChange,
    disabled = false,
    searchable = false,
    label = 'Process',
    nothingFound = 'No processes found',
    placeholder = 'Select process',
    ...selectProps
  } = props;
  const facility = useFacilityContext();
  const {
    data: processes,
    isLoading,
    isLoadingError,
    error,
  } = useProcesses(facility.id);
  if (isLoadingError) {
    throw error;
  }

  return (
    <Select
      data={
        processes?.map((process) => ({
          value: process.id,
          label: process.name,
        })) ?? []
      }
      value={value}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      disabled={isLoading || disabled}
      searchable={searchable}
      nothingFound={nothingFound}
      rightSection={isLoading ? <Loader size='xs' /> : undefined}
      {...selectProps}
    />
  );
}
