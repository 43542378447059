import { useMutation, useQuery } from '@tanstack/react-query';
import { components } from '../openapi';
import {
  SamplingSuiteContainerSampleAnalysisCreationDTO,
  SamplingSuiteService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';
import { client } from './restClient';

async function fetchSamplingSuiteContainerSampleAnalyses(
  ctx: QueryFunctionContexts['samplingSuiteContainerSampleAnalysis']['list'],
) {
  const [{ suiteId, containerSampleId }] = ctx.queryKey;

  return await SamplingSuiteService.getSamplingSuiteContainerSampleAnalyses(
    suiteId ?? undefined,
    containerSampleId ?? undefined,
  );
}

export function useSamplingSuiteContainerSampleAnalyses(args: {
  suiteId: string | null;
  containerSampleId: string | null;
}) {
  return useQuery({
    queryKey: queryKeys.samplingSuiteContainerSampleAnalysis.list(args),
    queryFn: fetchSamplingSuiteContainerSampleAnalyses,
  });
}

async function fetchSamplingSuiteContainerSampleAnalysis(
  ctx: QueryFunctionContexts['samplingSuiteContainerSampleAnalysis']['detail'],
) {
  const [{ analysisId }] = ctx.queryKey;
  const { data } = await client.GET(
    '/sampling-suites/container-sample-analyses/{containerSampleAnalysisGuid}',
    {
      params: {
        path: {
          containerSampleAnalysisGuid: analysisId,
        },
      },
    },
  );

  if (!data) {
    throw new Error();
  }

  return data;
}

export function useSamplingSuiteContainerSampleAnalysis(analysisId: string) {
  return useQuery({
    queryKey: queryKeys.samplingSuiteContainerSampleAnalysis.detail(analysisId),
    queryFn: fetchSamplingSuiteContainerSampleAnalysis,
  });
}

async function createSamplingSuiteContainerSampleAnalysis(
  dto: SamplingSuiteContainerSampleAnalysisCreationDTO,
) {
  const { response } = await client.POST(
    '/sampling-suites/container-sample-analyses',
    {
      body: dto,
    },
  );
  if (!response.ok) {
    throw new Error();
  }
}

export function useCreateSamplingSuiteContainerSampleAnalysis() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createSamplingSuiteContainerSampleAnalysis,
    onSettled() {
      invalidator.invalidateMaterialState();
      invalidator.invalidateKeys(
        queryKeys.samplingSuiteContainerSampleAnalysis.lists(),
      );
    },
  });
}

async function patchSamplingSuiteContainerSampleAnalysis(args: {
  analysisId: string;
  patch: components['schemas']['SamplingSuiteContainerSampleAnalysisPatchDTO'];
}) {
  const { response } = await client.PATCH(
    '/sampling-suites/container-sample-analyses/{containerSampleAnalysisGuid}',
    {
      params: {
        path: {
          containerSampleAnalysisGuid: args.analysisId,
        },
      },
      body: args.patch,
    },
  );
  if (!response.ok) {
    throw new Error();
  }
}

export function usePatchSamplingSuiteContainerSampleAnalysis() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchSamplingSuiteContainerSampleAnalysis,
    onSettled(_data, _error, { analysisId }) {
      invalidator.invalidateMaterialState();
      invalidator.invalidateKeys(
        queryKeys.samplingSuiteContainerSampleAnalysis.detail(analysisId),
        queryKeys.samplingSuiteContainerSampleAnalysis.lists(),
        queryKeys.samplingSuiteCapture.lists(),
      );
    },
  });
}

async function deleteSamplingSuiteContainerSampleAnalysis(analysisId: string) {
  const { response } = await client.DELETE(
    '/sampling-suites/container-sample-analyses/{containerSampleAnalysisGuid}',
    {
      params: {
        path: {
          containerSampleAnalysisGuid: analysisId,
        },
      },
    },
  );
  if (!response.ok) {
    throw new Error();
  }
}

export function useDeleteSamplingSuiteContainerSampleAnalysis() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteSamplingSuiteContainerSampleAnalysis,
    onSettled() {
      invalidator.invalidateMaterialState();
      invalidator.invalidateKeys(
        queryKeys.samplingSuiteContainerSampleAnalysis.all,
      );
    },
  });
}
