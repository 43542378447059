import { Skeleton, Text } from '@mantine/core';
import { Dayjs } from 'dayjs';
import { RecoveryGoalIdName } from '../../RecoveryGoal/RecoveryGoalIdName';
import { RedWaveSortProgramName } from '../../SortProgram/redwave/RedWaveSortProgramName';
import {
  useRedWaveActiveSortProgramSnapshot,
  useRedWaveProfileRevision,
} from '../../api/redWave';
import {
  RedWaveSortProgramProfileRevisionId,
  SortSystemId,
} from '../../rest-client';

export interface RedWaveActiveSystemProgramProps {
  systemId: SortSystemId;
  timestamp: Dayjs;
  useRecoveryGoalName?: boolean;
  noLink?: boolean;
}

export function RedWaveActiveSystemProgram(
  props: RedWaveActiveSystemProgramProps,
) {
  const {
    systemId,
    timestamp,
    useRecoveryGoalName = false,
    noLink = false,
  } = props;

  const activeSortProgramQuery = useRedWaveActiveSortProgramSnapshot(
    systemId,
    timestamp,
  );

  if (activeSortProgramQuery.isLoadingError) {
    throw activeSortProgramQuery.error;
  }

  const activeSortProgram = activeSortProgramQuery.data;

  if (activeSortProgram === null) {
    return <Text color={'dimmed'}>unknown</Text>;
  }

  // TODO(544): This should be at the given timestamp
  if (activeSortProgram) {
    if (useRecoveryGoalName) {
      if (activeSortProgram.profileRevisionId) {
        return (
          <RedWaveActiveSystemProgramRecoveryGoal
            profileRevisionId={activeSortProgram.profileRevisionId}
            noLink={noLink}
          />
        );
      } else {
        return <Text color={'dimmed'}>unknown</Text>;
      }
    } else {
      return (
        <RedWaveSortProgramName
          sortProgram={activeSortProgram}
          sortSystemId={systemId}
          noLink={noLink}
        />
      );
    }
  } else {
    return (
      <Skeleton visible>
        <Text>Loading sort program name...</Text>
      </Skeleton>
    );
  }
}

interface RedWaveActiveSystemProgramRecoveryGoalProps {
  profileRevisionId: RedWaveSortProgramProfileRevisionId;
  noLink?: boolean;
}

function RedWaveActiveSystemProgramRecoveryGoal(
  props: RedWaveActiveSystemProgramRecoveryGoalProps,
) {
  const { profileRevisionId, noLink = false } = props;

  const profileRevisionQuery = useRedWaveProfileRevision(profileRevisionId);
  if (profileRevisionQuery.isLoadingError) {
    throw profileRevisionQuery.error;
  }

  const profileRevision = profileRevisionQuery.data;

  if (profileRevision === undefined) {
    return (
      <Skeleton visible>
        <Text>Loading...</Text>
      </Skeleton>
    );
  }

  if (profileRevision.recoveryGoal === null) {
    return <Text color={'dimmed'}>unknown</Text>;
  }

  return (
    <RecoveryGoalIdName
      recoveryGoalId={profileRevision.recoveryGoal.recoveryGoalId}
      noLink={noLink}
    />
  );
}
