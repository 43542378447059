import { Loader } from '@mantine/core';
import { useSortSystem } from '../../api/sortSystem';
import Temporal from '../../Temporal/temporal.ts';
import { ProcessDTO } from '../../rest-client';
import { DifferentialSystemMetrics } from './DifferentialSystemMetrics';

export function FeedstockEdgeMetrics(props: {
  process: ProcessDTO;
  startTime: Temporal.Instant;
  endTime: Temporal.Instant;
}) {
  const { process, startTime, endTime } = props;
  const inputSystemQuery = useSortSystem(process.inputSystemId);
  if (inputSystemQuery.data) {
    return (
      <DifferentialSystemMetrics
        system={inputSystemQuery.data}
        port={{ type: 'input' }}
        startTime={startTime}
        endTime={endTime}
      />
    );
  }
  return <Loader />;
}
