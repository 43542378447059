import { useState } from 'react';
import { match } from 'ts-pattern';
import { LinkText } from '../../../../Link';
import { plainDateToStartOfDayInstant } from '../../../../Temporal/dateRangeAndOffset';
import Temporal from '../../../../Temporal/temporal';
import { useProductionAggregateTemporalMetrics } from '../../../../api/production';
import {
  PlannedProductionTemporalMetricsDTO,
  ProcessId,
  ProcessProductionAggregateTemporalMetricsDTO,
  ProductionIntervalTemporalMetricsDTO,
} from '../../../../rest-client';
import { Router } from '../../../../router';
import { useFacilityContext } from '../../../FacilityContext';
import { useInsightsSelectedDateRange } from '../../DateRange/InsightsDateRange';
import { MetricDetailPageTemplate } from '../MetricDetailPageTemplate';

// TODO abstract
const formatPercentage = (val: number) => `${(val * 100).toFixed(1)}%`;

function calculateActivityRate(
  productionIntervalTemporalMetric: PlannedProductionTemporalMetricsDTO,
) {
  const { totalActiveMinutes, totalPlannedProductionMinutes } =
    productionIntervalTemporalMetric;
  return totalActiveMinutes / totalPlannedProductionMinutes;
}

interface RenderColumnArgs {
  productionInterval: ProductionIntervalTemporalMetricsDTO;
  tz: string;
}

const COLUMNS = [
  {
    title: 'Production Interval',
    numeric: false,
    renderTableValue: ({
      productionInterval: prodIntervalDTO,
      tz,
    }: Required<RenderColumnArgs>) => {
      const { productionIntervalId, productionInterval } = prodIntervalDTO;
      const startDate = Temporal.Instant.from(
        productionInterval.startTime,
      ).toZonedDateTimeISO(tz);
      return (
        <td>
          <LinkText
            to={Router.ProductionDaily({
              date: startDate.toPlainDate().toString(),
              processId: productionInterval.processId,
              productionIntervalId,
            })}
          >
            {startDate.toLocaleString(undefined, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </LinkText>
        </td>
      );
    },
  },
  {
    renderTableValue: ({ productionInterval }: RenderColumnArgs) => (
      <td>
        {formatPercentage(calculateActivityRate(productionInterval.metrics))}
      </td>
    ),
    numeric: false,
    title: 'Activity Rate',
  },
  {
    renderTableValue: ({ productionInterval }: RenderColumnArgs) => (
      <td>
        {formatPercentage(
          productionInterval.metrics.totalPlannedStopMinutes /
            productionInterval.metrics.totalPlannedProductionMinutes,
        )}
      </td>
    ),
    numeric: false,
    title: 'Planned Stops',
  },
  {
    renderTableValue: ({ productionInterval }: RenderColumnArgs) => (
      <td>
        {formatPercentage(
          productionInterval.metrics.totalUnplannedStopMinutes /
            productionInterval.metrics.totalPlannedProductionMinutes,
        )}
      </td>
    ),
    numeric: false,
    title: 'Unplanned Stops',
  },
  {
    renderTableValue: ({ productionInterval }: RenderColumnArgs) => (
      <td>
        {formatPercentage(
          productionInterval.metrics.totalFaultedMinutes /
            productionInterval.metrics.totalPlannedProductionMinutes,
        )}
      </td>
    ),
    numeric: false,
    title: 'Faulted',
  },
];

export function getSeriesData(
  metricData: ProcessProductionAggregateTemporalMetricsDTO,
) {
  const processName = metricData.process.name.toLowerCase();
  return [
    {
      name: processName,
      entries: metricData.productionIntervalStats.map(
        (productionIntervalTemporalMetric) => {
          const { productionInterval, metrics } =
            productionIntervalTemporalMetric;
          return {
            timestamp: productionInterval.endTime,
            name: processName,
            value: (calculateActivityRate(metrics) * 100).toFixed(1),
          };
        },
      ),
    },
  ];
}

export function SystemUtilizationProcessSubMetricPage({
  processId,
}: {
  processId: ProcessId;
}) {
  const { id: facilityId, timeZoneId: tz } = useFacilityContext();
  const [nowZonedDateTime] = useState(Temporal.Now.zonedDateTimeISO(tz));
  const thirtyDaysAgo = nowZonedDateTime.subtract({ days: 30 });

  const { dateRange, setDateRange } = useInsightsSelectedDateRange({
    startDate: thirtyDaysAgo.toPlainDate(),
    endDate: nowZonedDateTime.toPlainDate(),
    name: 'Last 30 Days',
    id: 'd30',
  });
  const { startDate, endDate } = dateRange;
  const rangeInstants = {
    startInstant: plainDateToStartOfDayInstant(startDate, tz),
    // TODO end of day, probably
    endInstant: plainDateToStartOfDayInstant(endDate, tz),
  };
  const { startInstant, endInstant } = rangeInstants;

  const query = useProductionAggregateTemporalMetrics({
    facilityId,
    after: startInstant,
    before: endInstant,
  });

  const { processName, seriesData, tableData } = match(query)
    .with({ status: 'loading' }, () => ({
      loading: true,
      error: null,
      processName: '...',
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'error' }, (query) => ({
      loading: false,
      error: query.error as Error,
      processName: 'Unknown',
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'success' }, (query) => {
      const metricData = query.data.processProductionMetrics.find(
        (metric) => metric.process.id === processId,
      );
      if (!metricData) {
        return {
          loading: false,
          error: new ReferenceError('Metric facet not found.'),
          processName: 'Unknown',
          seriesData: [],
          tableData: [],
        };
      }

      return {
        loading: false,
        error: null,
        processName: metricData.process.name,
        seriesData: getSeriesData(metricData),
        tableData: metricData.productionIntervalStats,
      };
    })
    .exhaustive();

  const renderTableRow = (
    productionInterval: ProductionIntervalTemporalMetricsDTO,
  ) => {
    return (
      <tr key={productionInterval.productionIntervalId}>
        {COLUMNS.map((col) => {
          return col.renderTableValue({ productionInterval, tz });
        })}
      </tr>
    );
  };

  return (
    <MetricDetailPageTemplate<ProductionIntervalTemporalMetricsDTO>
      dateRange={dateRange}
      setDateRange={setDateRange}
      title={`System Utilization for ${processName}`}
      graphTitle={`Activity Rate for ${processName}`}
      tableTitle={`Production Intervals for ${processName}`}
      renderTableRow={renderTableRow}
      tableColumns={COLUMNS}
      seriesData={seriesData}
      tableData={tableData}
      query={query}
      yAxisLabel={'Activity Rate'}
    />
  );
}
