import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateContainerTransfer } from '../api/containerTransfer';
import { MaterialContainerTransferCreationDTO } from '../rest-client';
import { Router } from '../router';
import {
  ContainerTransferFormFields,
  ContainerTransferFormProps,
  ContainerTransferFormValues,
  useContainerTransferForm,
} from './ContainerTransferForm';

export type AddContainerTransferDrawerFormProps = ContainerTransferFormProps & {
  onSuccess?: (transfer: MaterialContainerTransferCreationDTO) => void;
} & DrawerFormDrawerProps;

export function AddContainerTransferDrawerForm(
  props: AddContainerTransferDrawerFormProps,
) {
  const {
    sourceContainerId,
    destinationContainerId,
    onSuccess = (transfer: MaterialContainerTransferCreationDTO) => {
      /**
       * if the source container was emptied, there's nothing to show on that container
       * details page, so we'll route the user to the destination container details page
       */
      if (transfer.sourceEmptied) {
        Router.replace('ContainerDetail', {
          containerId: transfer.destinationContainerId,
          timestamp: transfer.timestamp,
        });
      }
    },
    onClose,
    ...drawerFormDrawerProps
  } = props;

  const createMutation = useCreateContainerTransfer();
  const form = useContainerTransferForm({
    sourceContainerId,
    destinationContainerId,
  });
  const transferId = useMemo(() => uuidv4(), []);

  const formValuesToDto = ({
    timestamp,
    sourceContainerId,
    sourceEmptied,
    destinationContainerId,
    destinationFilled,
  }: ContainerTransferFormValues) => {
    if (sourceContainerId === null) {
      throw new Error('Source container cannot be null');
    }
    if (destinationContainerId === null) {
      throw new Error('Destination container cannot be null');
    }

    const transfer: MaterialContainerTransferCreationDTO = {
      id: transferId,
      timestamp: timestamp.utc().toISOString(),
      sourceContainerId,
      sourceEmptied,
      destinationContainerId,
      destinationFilled,
    };

    return transfer;
  };

  return (
    <DrawerForm
      entityName='Container Material Transfer'
      form={form}
      drawerFormVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <ContainerTransferFormFields
        form={form}
        containerSelectVariant='material'
      />
    </DrawerForm>
  );
}
