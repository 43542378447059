import { Alert, Group, Radio, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import { ContainerIdName } from '../Container/ContainerIdName';
import { useFacilityContext } from '../Facility/FacilityContext';
import { FormActionButton } from '../Form/FormActionButton';
import { ScaleIcon } from '../Icons';
import { ScaleSelect } from '../Scale/ScaleSelect';
import { TruckLoadIdName } from '../TruckLoad/TruckLoadIdName';
import ScaleDisplayDivisionsInput from '../Weights/ScaleDisplayDivisionsInput';
import { useAddScaleReading } from '../api/scale';
import {
  MaterialSetDTO,
  ScaleDTO,
  ScaleReadingCreationDTO,
  WeightUnit,
} from '../rest-client';

interface ContainerOccupationInterval {
  kind: 'container';
  containerId: string;
  interval: [Dayjs, Dayjs | null];
}

interface TruckLoadOccupationInterval {
  kind: 'truck-load';
  truckLoadId: string;
  interval: [Dayjs, Dayjs | null];
}

export type OccupationInterval =
  | ContainerOccupationInterval
  | TruckLoadOccupationInterval;

export function getOccupationIntervals(
  materialSet: MaterialSetDTO,
): OccupationInterval[] {
  const currentStart = materialSet.currentRepositoryOccupationStart;
  const pastStarts = materialSet.historicalRepositoryOccupationStarts;

  const historicalIntervalEnd = (i: number) => {
    if (i + 1 < pastStarts.length) {
      // If there is another historical start after this one, then the interval ends what the following start begins
      return pastStarts[i + 1].startTime;
    }

    if (i !== pastStarts.length - 1) {
      throw new Error('out of bounds');
    }

    // Last historical start. If this material set currently occupies a container, then that is the interval end
    if (currentStart) {
      return currentStart.startTime;
    }

    // This material set doesn't currently occupy a container. It's interval ends when it was consumed
    if (!materialSet.consumingEffect) {
      throw new Error('expected consuming effect');
    }
    return materialSet.consumingEffect.effectiveTimestamp;
  };

  const intervals: OccupationInterval[] = [];

  for (const [i, pastStart] of pastStarts.entries()) {
    if (pastStart.kind === 'Container') {
      intervals.push({
        kind: 'container',
        containerId: pastStart.containerId,
        interval: [
          dayjs.utc(pastStart.startTime),
          dayjs.utc(historicalIntervalEnd(i)),
        ],
      });
    }

    if (pastStart.kind === 'TruckLoad') {
      intervals.push({
        kind: 'truck-load',
        truckLoadId: pastStart.truckLoadId,
        interval: [
          dayjs.utc(pastStart.startTime),
          dayjs.utc(historicalIntervalEnd(i)),
        ],
      });
    }
  }

  if (currentStart) {
    if (currentStart.kind === 'Container') {
      intervals.push({
        kind: 'container',
        containerId: currentStart.containerId,
        interval: [dayjs.utc(currentStart.startTime), null],
      });
    }

    if (currentStart.kind === 'TruckLoad') {
      intervals.push({
        kind: 'truck-load',
        truckLoadId: currentStart.truckLoadId,
        interval: [dayjs.utc(currentStart.startTime), null],
      });
    }
  }

  return intervals;
}

export interface OccupationIntervalSelectProps {
  occupationIntervals: OccupationInterval[];
  selectedOccupationIntervalIdx: string;
  setSelectedOccupationIntervalIdx: React.Dispatch<
    React.SetStateAction<string>
  >;
  disabled: boolean;
}

export function OccupationIntervalSelect(props: OccupationIntervalSelectProps) {
  const {
    occupationIntervals,
    selectedOccupationIntervalIdx,
    setSelectedOccupationIntervalIdx,
    disabled,
  } = props;
  const facility = useFacilityContext();

  return (
    <Radio.Group
      value={selectedOccupationIntervalIdx}
      onChange={setSelectedOccupationIntervalIdx}
    >
      <Stack spacing='xs'>
        {occupationIntervals.map((occupationInterval, i) => {
          const interval = (
            <div>
              {occupationInterval.interval[0]
                .tz(facility.timeZoneId)
                .format('llll')}{' '}
              -{' '}
              {occupationInterval.interval[1]
                ?.tz(facility.timeZoneId)
                .format('llll') ?? 'Now'}
            </div>
          );
          return (
            <Radio
              key={i}
              value={i.toString()}
              disabled={disabled}
              label={
                <Group>
                  {match(occupationInterval)
                    .with({ kind: 'container' }, ({ containerId }) => (
                      <ContainerIdName
                        time={occupationInterval.interval[0]}
                        containerId={containerId}
                        variant='icon-name'
                      />
                    ))
                    .with({ kind: 'truck-load' }, ({ truckLoadId }) => (
                      <TruckLoadIdName
                        truckLoadId={truckLoadId}
                        variant='icon-name'
                      />
                    ))
                    .exhaustive()}
                  {interval}
                </Group>
              }
            />
          );
        })}
      </Stack>
    </Radio.Group>
  );
}

export interface OccupationIntervalScaleReadingFormValues {
  scale: ScaleDTO | null;
  displayDivisions: number | null;
}

export interface OccupationIntervalScaleReadingFormProps {
  occupationIntervals: OccupationInterval[];
  onCancel: () => void;
  onSuccess: () => void;
}

export function RepositoryOccupationIntervalScaleReadingForm(
  props: OccupationIntervalScaleReadingFormProps,
) {
  const { occupationIntervals, onCancel } = props;

  if (occupationIntervals.length === 0) {
    throw new Error('empty interval list not allowed');
  }

  const facility = useFacilityContext();
  const mutation = useAddScaleReading();
  const newReadingId = useMemo(() => uuidv4(), []);

  // TODO(2254): We need to adjust the user input mass into ticks of the scale types display division
  // TODO(2254): how should we handle numbers that are not clean multiples of the underlying tick?

  const form = useForm<OccupationIntervalScaleReadingFormValues>({
    initialValues: {
      scale: null,
      displayDivisions: 0,
    },
    validate: {
      scale: (scale) => (scale ? null : 'Scale is required'),
      displayDivisions: (divs) =>
        divs === null
          ? 'Gross weight is required'
          : divs <= 0
            ? 'Gross weight must be > 0'
            : null,
    },
  });

  const [selectedOccupationIntervalIdx, setSelectedOccupationIntervalIdx] =
    useState<string>('0');

  if (Number(selectedOccupationIntervalIdx) >= occupationIntervals.length) {
    setSelectedOccupationIntervalIdx('0');
  }

  const occupationInterval =
    occupationIntervals[Number(selectedOccupationIntervalIdx)];

  const association = match(occupationInterval)
    .with(
      { kind: 'container' },
      ({ containerId }) =>
        ({
          kind: 'Container',
          containerId,
        }) as const,
    )
    .with(
      { kind: 'truck-load' },
      ({ truckLoadId }) =>
        ({
          kind: 'TruckLoad',
          truckLoadId,
        }) as const,
    )
    .exhaustive();

  const [intervalStart, intervalEnd] = occupationInterval.interval;
  const timestamp = dayjs.min(
    intervalStart.add(5, 'minutes'),
    intervalEnd ?? dayjs(),
  );

  if (timestamp === null) {
    throw new Error('timestamp cannot be null');
  }

  if (mutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Creating Scale Reading'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => mutation.reset()}
      >
        The scale reading may or may not have been saved. You can clear the
        error and try again.
      </Alert>
    );
  }

  const onSubmit = form.onSubmit((formValues) => {
    if (formValues.scale === null) {
      throw new Error();
    }

    if (formValues.displayDivisions === null) {
      throw new Error();
    }

    const reading: ScaleReadingCreationDTO = {
      id: newReadingId,
      timestamp: timestamp.utc().toISOString(),
      displayDivisions: formValues.displayDivisions,
      association,
    };

    mutation.mutate(
      { scaleId: formValues.scale.id, reading },
      {
        onError() {
          showNotification({
            title: 'Error Creating Scale Reading',
            message: 'An error occurred creating scale reading.',
            color: 'red',
            icon: <IconX />,
          });
        },
        onSuccess() {
          props.onSuccess();
          showNotification({
            title: 'Scale Reading Created',
            message: 'Scale reading was successfuly created.',
            color: 'green',
            icon: <IconCheck />,
          });
        },
      },
    );
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack>
        <OccupationIntervalSelect
          occupationIntervals={occupationIntervals}
          selectedOccupationIntervalIdx={selectedOccupationIntervalIdx}
          setSelectedOccupationIntervalIdx={setSelectedOccupationIntervalIdx}
          disabled={!mutation.isIdle}
        />
        <ScaleSelect
          label='Scale'
          type='object'
          icon={<ScaleIcon />}
          dropdownPosition='bottom'
          placeholder='Select scale...'
          facilityId={facility.id}
          disabled={!mutation.isIdle}
          required
          {...form.getInputProps('scale')}
        />
        <ScaleDisplayDivisionsInput
          label='Gross Weight'
          description='Gross weight of container, including the tare weight plus the container contents'
          disabled={!form.values.scale || !mutation.isIdle}
          required
          maw='20ch'
          scale={
            form.values.scale ?? { displayDivision: 1, unit: WeightUnit.POUND }
          }
          {...form.getInputProps('displayDivisions')}
        />
        <Alert color='blue'>
          Reading will be created at{' '}
          {timestamp.tz(facility.timeZoneId).format('LLLL')}
        </Alert>

        <Group position='right' mt='md'>
          <FormActionButton
            action='cancel'
            onClick={onCancel}
            loading={mutation.isLoading}
          />
          <FormActionButton
            type='submit'
            action='saveCreation'
            loading={mutation.isLoading}
          >
            Add Weight
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
