import { Stack, Title } from '@mantine/core';
import { match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { InternalMaterialSourceCompositionsByCommoditySection } from '../InternalMaterialSource/InternalMaterialSourceCompositionsByCommodityChart';
import { useCommodityInternalMaterialSourceCompositions } from '../api/commodity';
import { CommodityId } from '../rest-client';

export function CommodityInternalMaterialSourceCompositions(props: {
  commodityId: CommodityId;
}) {
  const { commodityId } = props;

  const commodityImsCompQuery = useCommodityInternalMaterialSourceCompositions({
    commodityId,
    intervalStart: undefined,
    intervalEnd: undefined,
  });

  if (commodityImsCompQuery.isError) {
    throw commodityImsCompQuery.error;
  }

  if (commodityImsCompQuery.isLoading) {
    return undefined;
  }

  return match(commodityImsCompQuery.data)
    .with({ status: 'NoSources' }, () => undefined)
    .with({ status: 'SourcesPresent' }, (result) => (
      <AppPage.Section>
        <Stack>
          <Title order={2}>Commodity Sources</Title>
          <InternalMaterialSourceCompositionsByCommoditySection
            commodityMcsImsComps={
              result.commodityInternalMaterialSourceAggregateCompositions
            }
          />
        </Stack>
      </AppPage.Section>
    ))
    .exhaustive();
}
