import { Alert, Group, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { FormActionButton } from '../Form/FormActionButton';
import { useFormNavBlocker } from '../Form/useFormNavBlocker';
import { usePatchVendor, useVendor } from '../api/vendor';
import { MaterialVendorId, MaterialVendorPatchDTO } from '../rest-client';
import { Router } from '../router';
import { VendorFormFields, useVendorForm } from './VendorForm';

export function EditVendorForm(props: { vendorId: MaterialVendorId }) {
  const { vendorId } = props;
  const vendorQuery = useVendor(vendorId);
  const vendor = vendorQuery.data;
  const patchVendorMutation = usePatchVendor(vendorId);
  const form = useFormNavBlocker(useVendorForm());
  const [waitingForInitialLoad, setWaitingForInitialLoad] = useState(true);

  if (waitingForInitialLoad && vendor) {
    form.setValues({
      name: vendor.name,
      location: vendor.location ?? undefined,
      contactName: vendor.contactName ?? undefined,
    });
    setWaitingForInitialLoad(false);
  }

  const onSubmit = form.onSubmit(({ name, location, contactName }) => {
    const patch: MaterialVendorPatchDTO = {
      ...(form.isDirty('name') && { name }),
      ...(form.isDirty('location') && {
        location: location ?? null,
      }),
      ...(form.isDirty('contactName') && {
        contactName: contactName ?? null,
      }),
    };
    patchVendorMutation.mutate(patch, {
      onError(data, variables) {
        showNotification({
          title: 'Error Updating Vendor',
          message: `An error occurred updating ${variables.name ?? 'Vendor'}.`,
          color: 'red',
          icon: <IconX />,
        });
      },
      onSuccess(data, variables) {
        Router.push('VendorDetail', { vendorId });
        showNotification({
          title: 'Vendor Updated',
          message: `${variables.name ?? 'Vendor'} was successfully updated.`,
          color: 'green',
          icon: <IconCheck />,
        });
      },
    });
  });

  if (vendorQuery.isLoadingError) {
    return (
      <Alert color='red' title='Error Loading Vendor'>
        An error occurred loading this vendor.
      </Alert>
    );
  }
  if (patchVendorMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Updating Vendor'
        withCloseButton
        onClose={() => patchVendorMutation.reset()}
      >
        Your changes to the Vendor may or may not have been saved. You can clear
        the error and try again.
      </Alert>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack>
        <VendorFormFields
          form={form}
          loading={waitingForInitialLoad || patchVendorMutation.isLoading}
        />
        <Group position='right'>
          <FormActionButton
            action='cancel'
            onClick={() => Router.push('VendorDetail', { vendorId })}
            loading={patchVendorMutation.isLoading}
          />
          <FormActionButton
            type='submit'
            action='saveEdits'
            loading={patchVendorMutation.isLoading}
            disabled={!form.isDirty()}
          >
            Save Vendor
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
