import { Skeleton, Text } from '@mantine/core';
import {
  TruckLoadName,
  TruckLoadNameProps,
} from '../Repository/RepositoryName';
import { useTruckLoad } from '../api/truckLoad';

export type TruckLoadIdNameProps = Omit<TruckLoadNameProps, 'truckLoad'> & {
  truckLoadId: string;
};

export function TruckLoadIdName(props: TruckLoadIdNameProps) {
  const { truckLoadId, ...rest } = props;
  const { data: truckLoad, isError } = useTruckLoad(truckLoadId);

  if (truckLoad) {
    return <TruckLoadName truckLoad={truckLoad} {...rest} />;
  }

  if (isError) {
    return <Text color='red'>Error getting name</Text>;
  }

  return (
    <Skeleton visible>
      <Text>Loading name...</Text>
    </Skeleton>
  );
}
