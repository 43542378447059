import dayjs from 'dayjs';
import Temporal from '../../../Temporal/temporal.ts';
import { ChutecSortSystemDTO, ChutecStream } from '../../../rest-client';
import { ChutecMaterialClassTimeseriesChart } from './ChutecMaterialClassTimeseriesChart';

const WINDOW_HACK_BEFORE_SECONDS = 7;
const WINDOW_HACK_AFTER_SECONDS = 4;

export function DifferentialChutecSystemMetrics(props: {
  system: ChutecSortSystemDTO;
  stream: ChutecStream;
  startTime: Temporal.Instant;
  endTime?: Temporal.Instant;
}) {
  const { system, stream, startTime, endTime } = props;

  // TODO(chutec): Gross hack to not deal with alignment of windows
  const windowStartTime = dayjs.utc(
    startTime.subtract({ seconds: WINDOW_HACK_BEFORE_SECONDS })
      .epochMilliseconds,
  );
  const windowEndTime = endTime
    ? dayjs.utc(
        endTime.add({ seconds: WINDOW_HACK_AFTER_SECONDS }).epochMilliseconds,
      )
    : undefined;
  return (
    <ChutecMaterialClassTimeseriesChart
      systemId={system.id}
      startTime={windowStartTime}
      endTime={windowEndTime}
      stream={stream}
      mode='differential'
    />
  );
}
