import { match } from 'ts-pattern';
import { Router } from '../router';
import { RecoveryGoalDetailPage } from './RecoveryGoalDetailPage';

export function RecoveryGoalArea() {
  const route = Router.useRoute(['RecoveryGoalDetail']);

  if (route === undefined) {
    throw new Error('bad route');
  }

  return match(route)
    .with({ name: 'RecoveryGoalDetail' }, ({ params: { recoveryGoalId } }) => (
      <RecoveryGoalDetailPage recoveryGoalId={recoveryGoalId} />
    ))
    .exhaustive();
}
