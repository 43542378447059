import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import Temporal from '../Temporal/temporal.ts';
import { ProcessId, ProcessService } from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys } from './queryKeys';

async function fetchProcesses() {
  return await ProcessService.allProcesses();
}

export function useProcesses(facilityId: string) {
  return useQuery({
    queryKey: queryKeys.process.list(facilityId),
    queryFn: fetchProcesses,
  });
}

async function fetchProcess(ctx: QueryFunctionContexts['process']['detail']) {
  const [{ processId }] = ctx.queryKey;
  if (processId === undefined) {
    throw new Error('process id must be defined');
  }
  return await ProcessService.getProcessById(processId);
}

export function useProcess(processId: ProcessId | undefined) {
  return useQuery({
    queryKey: queryKeys.process.detail(processId),
    queryFn: fetchProcess,
    enabled: processId !== undefined,
  });
}

async function fetchProcessSystemConfigChanges(
  ctx: QueryFunctionContexts['processSystemConfigChanges']['forProcess'],
) {
  const [{ processId, after, before }] = ctx.queryKey;
  return await ProcessService.getSystemConfigChanges(
    processId,
    after.utc().toISOString(),
    before?.utc().toISOString(),
  );
}

export function useProcessSystemConfigChanges(args: {
  processId: string;
  after: Dayjs;
  before: Dayjs | null;
}) {
  return useQuery({
    queryKey: queryKeys.processSystemConfigChanges.forProcess(args),
    queryFn: fetchProcessSystemConfigChanges,
  });
}

async function fetchProcessSystemObjectQuantities(
  ctx: QueryFunctionContexts['processSystemObjectQuantities']['forProcess'],
) {
  const [{ processId, after, before }] = ctx.queryKey;
  return await ProcessService.getSystemObjectQuantities(
    processId,
    after.utc().toISOString(),
    before.utc().toISOString(),
  );
}

export function useProcessSystemObjectQuantities(args: {
  processId: string;
  after: Dayjs;
  before: Dayjs;
}) {
  return useQuery({
    queryKey: queryKeys.processSystemObjectQuantities.forProcess(args),
    queryFn: fetchProcessSystemObjectQuantities,
  });
}

async function fetchSystemOperationalStateHistories(
  ctx: QueryFunctionContexts['systemOperationalStateHistory']['processInterval'],
) {
  const [{ processId, after, before }] = ctx.queryKey;
  return await ProcessService.getSystemOperationalStateHistories(
    processId,
    after.utc().toISOString(),
    before.utc().toISOString(),
  );
}

export function useSystemOperationalStateHistories(args: {
  processId: string;
  after: Dayjs;
  before: Dayjs;
}) {
  return useQuery({
    queryKey: queryKeys.systemOperationalStateHistory.processInterval(args),
    queryFn: fetchSystemOperationalStateHistories,
  });
}

async function fetchProcessTemporalProductionHistory(
  ctx: QueryFunctionContexts['processPlannedProductionInterval']['temporalStats']['byProcess'],
) {
  const [{ processId, after, before }] = ctx.queryKey;
  return await ProcessService.getTemporalProductionHistory(
    processId,
    before?.toString(),
    after?.toString(),
  );
}

export function useProcessTemporalProductionHistory(args: {
  processId: string;
  after: Temporal.Instant | null;
  before: Temporal.Instant | null;
}) {
  return useQuery({
    queryKey:
      queryKeys.processPlannedProductionInterval.temporalStats.byProcess(args),
    queryFn: fetchProcessTemporalProductionHistory,
  });
}

async function fetchProcessMassProductionHistory(
  ctx: QueryFunctionContexts['processPlannedProductionInterval']['massStats']['byProcess'],
) {
  const [{ processId, after, before }] = ctx.queryKey;
  return await ProcessService.getMassProductionHistory(
    processId,
    before?.toString(),
    after?.toString(),
  );
}

export function useProcessMassProductionHistory(args: {
  processId: string;
  after: Temporal.Instant | null;
  before: Temporal.Instant | null;
}) {
  return useQuery({
    queryKey:
      queryKeys.processPlannedProductionInterval.massStats.byProcess(args),
    queryFn: fetchProcessMassProductionHistory,
  });
}
