import { Alert, Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RequiredDayjsDateTimePicker } from '../DayjsDateTimePicker';
import { useFacilityContext } from '../Facility/FacilityContext';
import { FormActionButton } from '../Form/FormActionButton';
import { ProcessMultiSelect } from '../Process/ProcessMultiSelect';
import Temporal from '../Temporal/temporal.ts';
import { useProcesses } from '../api/process';
import { useAddProductionIntervals } from '../api/production';
import { ProcessId, ProcessPlannedProductionIntervalDTO } from '../rest-client';

export interface ProductionIntervalFormValues {
  processIds: ProcessId[];
  startTime: Dayjs;
  endTime: Dayjs;
}

export interface AddProductionIntervalFormProps {
  date: Temporal.PlainDate;
  onCancel?: () => void;
  onSuccess: () => void;
}

export function AddProductionIntervalForm(
  props: AddProductionIntervalFormProps,
) {
  const { date, onCancel } = props;

  const facility = useFacilityContext();
  const tz = facility.timeZoneId;

  const addMutation = useAddProductionIntervals();

  const processesQuery = useProcesses(facility.id);
  const processes = processesQuery.data;

  const processPlannedProductionIntervalIds = useMemo(
    () =>
      processes &&
      Object.fromEntries(processes.map((process) => [process.id, uuidv4()])),
    [processes],
  );

  if (processesQuery.isLoadingError) {
    throw processesQuery.error;
  }
  const datetime = dayjs.tz(date.toString(), tz);

  const form = useForm<ProductionIntervalFormValues>({
    initialValues: {
      processIds: [],
      startTime: datetime.set('hour', 5).set('minute', 30).set('second', 0),
      endTime: datetime.set('hour', 13).set('minute', 0).set('second', 0),
    },
    validate: {
      processIds: (processIds) =>
        processIds.length > 0 ? null : 'Process(es) is required',
    },
  });

  if (addMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Creating Production Shift'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => addMutation.reset()}
      >
        The production shift may or may not have been saved. You can clear the
        error and try again.
      </Alert>
    );
  }

  return (
    <form
      onSubmit={form.onSubmit(({ processIds, startTime, endTime }) => {
        if (processIds.length < 1) {
          throw new Error('At least one process must be selected');
        }

        const productionIntervals: ProcessPlannedProductionIntervalDTO[] =
          processIds.map((processId) => {
            if (!processPlannedProductionIntervalIds) {
              throw new Error();
            }
            return {
              id: processPlannedProductionIntervalIds[processId],
              processId,
              startTime: startTime.utc().toISOString(),
              endTime: endTime.utc().toISOString(),
              notes: null,
            };
          });

        addMutation.mutate(productionIntervals, {
          onError() {
            showNotification({
              title: 'Error Creating Production Shift',
              message: 'An error occurred creating the production shift',
              color: 'red',
              icon: <IconX />,
            });
          },
          onSuccess() {
            props.onSuccess();
            showNotification({
              title: 'Production Shift Created',
              message: 'The production shift was sucessfully recorded',
              color: 'green',
              icon: <IconCheck />,
            });
          },
        });
      })}
    >
      <Stack maw={500}>
        <ProcessMultiSelect
          emptyContent={
            <Alert title='No Processes' color='red'>
              Cannot create a production shift without a process.
            </Alert>
          }
          {...form.getInputProps('processIds')}
        />
        {/* TODO(2330): add time constraints to these */}
        <RequiredDayjsDateTimePicker
          {...form.getInputProps('startTime')}
          tz={facility.timeZoneId}
          withAsterisk
          label='Start Time'
          description='When the production shift starts'
        />
        <RequiredDayjsDateTimePicker
          {...form.getInputProps('endTime')}
          tz={facility.timeZoneId}
          withAsterisk
          label='End Time'
          description='When the production shift ends'
        />
        <Group position='right' mt='md'>
          <FormActionButton
            action='cancel'
            onClick={onCancel}
            loading={addMutation.isLoading}
          />
          <FormActionButton
            type='submit'
            action='saveCreation'
            loading={addMutation.isLoading}
          >
            Add Production Shift
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
