import {
  Alert,
  Badge,
  Box,
  Button,
  Center,
  Container,
  Group,
  Loader,
  Modal,
  Paper,
  Skeleton,
  Stack,
  Switch,
  Table,
  Tabs,
  Text,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconAlertCircle, IconReload, IconUpload } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { match } from 'ts-pattern';
import { ErrorBoundary, FallbackRender } from '../ErrorBoundary';
import { The404 } from '../The404';
import {
  useCreateSchupanRimasSnapshot,
  usePatchSchupanRimasSnapshot,
  useSchupanRimasSnapshotAnalysis,
  useSchupanRimasSnapshots,
} from '../api/schupan';
import { Router } from '../router';
import { RimasBalerAgGridTable } from './RimasReportAgGrid';
import { RimasReportCompositionLineChart } from './RimasReportCompositionLineChart';
import { RimasReportMetricsLineChart } from './RimasReportKeyMetricsLineChart';
import { SchupanHeader } from './SchupanHeader';
import { SchupanRimasSnapshotUploadForm } from './SchupanRimasSnapshotUploadForm';

const ErrorFallback: FallbackRender = (errorData) => {
  return (
    <Alert color='red' title='Error' icon={<IconAlertCircle size={16} />}>
      <Stack>
        Something went wrong displaying this data.
        <Button onClick={() => errorData.resetError()}>Try Again</Button>
      </Stack>
    </Alert>
  );
};

export function SchupanApp() {
  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <SchupanHeader />
      <SchupanAppBody />
    </ErrorBoundary>
  );
}

function SchupanAppBody() {
  const route = Router.useRoute(['Home', 'SchupanRimasSnapshots']);
  return (
    <Box
      p='md'
      maw='100%'
      sx={{
        height: 'calc(100vh - 56px - 12px)',
        minHeight: '800px',
      }}
    >
      {match(route)
        .with({ name: 'Home' }, () => <SchupanDashboard />)
        .with({ name: 'SchupanRimasSnapshots' }, () => (
          <SchupanRimasSnapshotsPage />
        ))
        .with(undefined, () => <The404 />)
        .exhaustive()}
    </Box>
  );
}

function SchupanDashboard() {
  const analysisQuery = useSchupanRimasSnapshotAnalysis();

  // TODO: fix graph re-size flashing on tab change
  if (analysisQuery.data) {
    return (
      <Stack h='100%'>
        <RimasBalerAgGridTable report={analysisQuery.data} />
        <Tabs
          orientation='vertical'
          defaultValue='composition'
          variant='outline'
        >
          <Tabs.List>
            <Center pb={10}>
              <Title order={4} c='black'>
                Graph Type
              </Title>
            </Center>
            <Tabs.Tab value='composition'>Composition</Tabs.Tab>
            <Tabs.Tab value='poundsPerLaborHour'>Pounds / Labor Hour</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value='composition'>
            <RimasReportCompositionLineChart />
          </Tabs.Panel>
          <Tabs.Panel value='poundsPerLaborHour'>
            <RimasReportMetricsLineChart />
          </Tabs.Panel>
        </Tabs>
      </Stack>
    );
  }

  if (analysisQuery.isLoading) {
    return <Loader />;
  }

  throw analysisQuery.error;
}

function SchupanRimasSnapshotsPage() {
  const [opened, { open, close }] = useDisclosure(false);
  const createSnapshotMutation = useCreateSchupanRimasSnapshot();

  return (
    <Container maw={'150ch'}>
      <Paper shadow='md' p='md' withBorder>
        <Group position='apart'>
          <Title order={2}>RIMAS Snapshots</Title>

          <Button
            color={createSnapshotMutation.isError ? 'red' : 'teal'}
            size='md'
            leftIcon={
              createSnapshotMutation.isError ? <IconReload /> : <IconUpload />
            }
            onClick={
              createSnapshotMutation.isError
                ? () => createSnapshotMutation.reset()
                : open
            }
            loading={createSnapshotMutation.isLoading}
          >
            {createSnapshotMutation.isError
              ? 'Upload Failed'
              : 'Upload Snapshot'}
          </Button>
          {opened ? (
            <Modal opened={opened} onClose={close} title='Snapshot Upload'>
              <SchupanRimasSnapshotUploadForm
                onUpload={(csvs) => {
                  close();
                  createSnapshotMutation.mutate(csvs);
                }}
              />
            </Modal>
          ) : null}
        </Group>

        <SchupanRimasSnapshotTable />
      </Paper>
    </Container>
  );
}

function SchupanRimasSnapshotTable() {
  return (
    <Table>
      <thead>
        <tr>
          <th>Id</th>
          <th>Inserted At</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <SchupanRimasSnapshotTableBody />
      </tbody>
    </Table>
  );
}
function SchupanRimasSnapshotTableBody() {
  const snapshotsQuery = useSchupanRimasSnapshots();

  if (snapshotsQuery.data) {
    const snapshots = snapshotsQuery.data;
    if (snapshots.length === 0) {
      return (
        <tr>
          <td colSpan={3} style={{ textAlign: 'center' }}>
            <Text color='dimmed' size='md'>
              no snapshots
            </Text>
          </td>
        </tr>
      );
    }

    const snapshotRows: ReactNode[] = [];
    let activeFound = false;
    for (const snapshot of snapshots) {
      // The first non-disabled snapshot is active
      const disabled = snapshot.disabled;
      const active: boolean = !(disabled || activeFound);
      activeFound = activeFound || active;

      let status: ReactNode;
      if (active) {
        status = <Badge color='teal'>Active</Badge>;
      } else {
        if (disabled) {
          status = <Badge color='red'>Disabled</Badge>;
        } else {
          status = <Badge color='gray'>Archived</Badge>;
        }
      }

      snapshotRows.push(
        <SchupanRimasSnapshotTableRow
          key={snapshot.id}
          id={snapshot.id}
          disabled={snapshot.disabled}
          insertedAt={snapshot.insertedAt}
          status={status}
        />,
      );
    }

    if (snapshots.length > 0 && !activeFound) {
      snapshotRows.unshift(
        <tr>
          <td colSpan={3}>
            <Alert color='orange' title='No Active Snapshot'>
              All snapshots have been disabled. The dashboard will not show
              anything unless at least one snapshot is enabled.
            </Alert>
          </td>
        </tr>,
      );
    }

    return snapshotRows;
  }

  if (snapshotsQuery.isLoading) {
    return (
      <tr style={{ height: '2rem' }}>
        <td>
          <Skeleton visible />
        </td>
        <td>
          <Skeleton visible />
        </td>
        <td>
          <Skeleton visible />
        </td>
      </tr>
    );
  }

  throw snapshotsQuery.error;
}

function SchupanRimasSnapshotTableRow(props: {
  id: number;
  insertedAt: string;
  disabled: boolean;
  status: ReactNode;
}) {
  const { id, insertedAt, disabled, status } = props;

  const patchMutation = usePatchSchupanRimasSnapshot();

  return (
    <tr>
      <td>{id}</td>
      <td>{insertedAt}</td>
      <td>
        <Group position='apart'>
          {status}
          <Switch
            label={'Enabled'}
            checked={!disabled}
            disabled={patchMutation.isLoading}
            onClick={() => {
              patchMutation.mutate(
                { id, patch: { disabled: !disabled } },
                {
                  onSettled() {
                    patchMutation.reset();
                  },
                },
              );
            }}
          />
        </Group>
      </td>
    </tr>
  );
}
