import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Divider,
  Flex,
  Group,
  Paper,
  Radio,
  Stack,
  Text,
} from '@mantine/core';
import {
  IconCheck,
  IconPin,
  IconPinInvoke,
  IconPinnedOff,
  IconRotate2,
  IconUnlink,
  IconX,
} from '@tabler/icons-react';
import { Fragment, ReactNode, useCallback, useState } from 'react';
// import { match } from 'ts-pattern';
import { MutationErrorAlert } from '../Form/MutationErrorAlert';
import { SaveIcon } from '../Icons';
import { SamplingSuiteCapturePngThumbnail } from '../SamplingSuite/SamplingSuiteCapturePng';
import NetWeight from '../Weights/NetWeight';
import { WithUnit } from '../WithUnit';
import {
  usePatchSamplingSuiteCapture,
  useSamplingSuiteCaptures,
} from '../api/samplingSuite';
import { usePatchSamplingSuiteContainerSampleAnalysis } from '../api/samplingSuiteContainerSampleAnalysis';
import {
  NetWeightDTO,
  SamplingSuiteCaptureDTO,
  SamplingSuiteContainerSampleAnalysisDTO,
} from '../rest-client';
import cssClasses from './SamplingSuiteAnalysisEntryForm.module.css';

type WholeSampleCaptureOrder = 'first' | 'last' | 'none';

interface ManualMaterialClassLink {
  type: 'material-class';
  materialClassId: string;
}

type ManualCaptureLink = ManualMaterialClassLink | { type: 'whole-sample' };

export function SamplingSuiteAnalysisEntryForm(props: {
  analysis: SamplingSuiteContainerSampleAnalysisDTO;
}) {
  const { analysis } = props;
  const { materialClassSet } = analysis;
  const { materialClasses } = materialClassSet;

  const patchMutation = usePatchSamplingSuiteContainerSampleAnalysis();

  const [wholeSampleCaptureOrder, setWholeSampleCaptureOrder] =
    useState<WholeSampleCaptureOrder>('first');

  const [ignoredCaptureIdList, setIgnoredCaptureIdList] = useState<string[]>(
    [],
  );
  const ignoredCaptureIds = new Set(ignoredCaptureIdList);

  const ignoreCaptureId = useCallback(
    (captureId: string) => {
      setIgnoredCaptureIdList((l) => [
        ...l.filter((id) => id !== captureId),
        captureId,
      ]);
    },
    [setIgnoredCaptureIdList],
  );
  const unIgnoreCaptureId = useCallback(
    (captureId: string) => {
      setIgnoredCaptureIdList((l) => l.filter((id) => id !== captureId));
    },
    [setIgnoredCaptureIdList],
  );

  const unlinkedCapturesQuery = useSamplingSuiteCaptures({
    unlinked: true,
    suiteId: analysis.samplingSuiteId,
  });

  const unlinkedCaptureLookup =
    unlinkedCapturesQuery.data &&
    new Map(unlinkedCapturesQuery.data.map((c) => [c.captureId, c]));

  const materialClassCaptures = new Map(
    Object.entries(analysis.materialClassCaptures),
  );

  const [materialClassEmptiness, setMaterialClassEmptiness] = useState<
    Record<string, boolean>
  >(Object.fromEntries(materialClasses.map((mc) => [mc.id, false])));
  const [manualCaptureLinks, setManualCaptureLinks] = useState<
    Record<string, ManualCaptureLink>
  >({});

  const removeManualCaptureLink = useCallback(
    (captureId: string) => {
      setManualCaptureLinks((links) =>
        Object.fromEntries(
          Object.entries(links).filter(([cId]) => cId !== captureId),
        ),
      );
    },
    [setManualCaptureLinks],
  );

  const addManualCaptureLink = useCallback(
    (captureId: string, link: ManualCaptureLink) => {
      setManualCaptureLinks((links) => ({ ...links, [captureId]: link }));
    },
    [setManualCaptureLinks],
  );

  const [pinningCaptureId, setPinningCaptureId] = useState<string | null>(null);

  const pinCapture = useCallback(
    (link: ManualCaptureLink) => {
      if (!pinningCaptureId) return;
      setPinningCaptureId(null);
      addManualCaptureLink(pinningCaptureId, link);
    },
    [pinningCaptureId, setPinningCaptureId, addManualCaptureLink],
  );

  const uncapturedMaterialClassIds: string[] = [];
  for (const materialClass of analysis.materialClassSet.materialClasses) {
    if (materialClassCaptures.get(materialClass.id)) continue;
    if (materialClassEmptiness[materialClass.id]) continue;
    if (
      Object.values(manualCaptureLinks).some(
        (link) =>
          link.type === 'material-class' &&
          link.materialClassId === materialClass.id,
      )
    ) {
      continue;
    }

    uncapturedMaterialClassIds.push(materialClass.id);
  }

  const manuallyLinkedWholeSampleCaptures: SamplingSuiteCaptureDTO[] = [];
  for (const [captureId, link] of Object.entries(manualCaptureLinks)) {
    if (link.type !== 'whole-sample') continue;
    const capture = unlinkedCaptureLookup?.get(captureId);
    if (!capture) continue;
    manuallyLinkedWholeSampleCaptures.push(capture);
  }

  let autoLinkedWholeSampleCapture: SamplingSuiteCaptureDTO | undefined =
    undefined;
  let autoLinkedMaterialClassCaptures:
    | Map<string, SamplingSuiteCaptureDTO>
    | undefined = undefined;
  const linkedWholeSampleCaptures = analysis.wholeSampleCaptures.captures;

  let unlinkedCaptures = unlinkedCapturesQuery.data?.filter(
    (capture) =>
      !(capture.captureId in manualCaptureLinks) &&
      !ignoredCaptureIds.has(capture.captureId),
  );
  if (unlinkedCaptures) {
    if (
      wholeSampleCaptureOrder === 'first' &&
      linkedWholeSampleCaptures.length === 0 &&
      manuallyLinkedWholeSampleCaptures.length === 0 &&
      unlinkedCaptures.length > 0
    ) {
      // if all these conditions are met, then the first unlinked capture gets auto-linked to the whole sample
      autoLinkedWholeSampleCapture = unlinkedCaptures[0];
      unlinkedCaptures = unlinkedCaptures.slice(1);
    }

    autoLinkedMaterialClassCaptures = new Map(
      unlinkedCaptures
        .filter(
          (c, i) =>
            i < uncapturedMaterialClassIds.length &&
            !(c.captureId in manualCaptureLinks),
        )
        .map((c, i) => [uncapturedMaterialClassIds[i], c] as const),
    );
    unlinkedCaptures = unlinkedCaptures.slice(
      autoLinkedMaterialClassCaptures.size,
    );

    const allClassesCovered = materialClasses.every(
      (mc) =>
        materialClassEmptiness[mc.id] ||
        materialClassCaptures.has(mc.id) ||
        mc.id in manualCaptureLinks ||
        (autoLinkedMaterialClassCaptures?.has(mc.id) ?? false),
    );

    if (
      wholeSampleCaptureOrder === 'last' &&
      linkedWholeSampleCaptures.length === 0 &&
      manuallyLinkedWholeSampleCaptures.length === 0 &&
      unlinkedCaptures.length > 0 &&
      allClassesCovered
    ) {
      // if all these conditions are met, then the first unlinked capture gets auto-linked to the whole sample
      autoLinkedWholeSampleCapture = unlinkedCaptures[0];
      unlinkedCaptures = unlinkedCaptures.slice(1);
    }
  }

  // TODO(2289): validate that we don't need to set row heights
  const captureRowHeight = '8rem';
  // const materialClassRowHeights = materialClasses.map((mc) => {
  //   const isEmpty = materialClassEmptiness[mc.id];
  //   const noManualLinks = !Object.entries(manualCaptureLinks).some(
  //     ([, link]) =>
  //       link.type === 'material-class' && link.materialClassId === mc.id,
  //   );
  //   const noCaptures = isEmpty && noManualLinks;
  //   return noCaptures ? '2.5rem' : captureRowHeight;
  // });

  // const rowHeights = match(wholeSampleCaptureOrder)
  //   .with('first', () => [captureRowHeight, ...materialClassRowHeights])
  //   .with('last', () => [...materialClassRowHeights, captureRowHeight])
  //   .with('none', () => materialClassRowHeights)
  //   .exhaustive();

  const wholeSampleCaptureWeights: NetWeightDTO[] =
    manuallyLinkedWholeSampleCaptures.map((c) => c.netWeight);
  if (analysis.wholeSampleCaptures.captures.length > 0) {
    wholeSampleCaptureWeights.push(analysis.wholeSampleCaptures.totalNetWeight);
  }
  if (autoLinkedWholeSampleCapture) {
    wholeSampleCaptureWeights.push(autoLinkedWholeSampleCapture.netWeight);
  }

  const canBeCompleted =
    materialClasses.every(
      (materialClass) =>
        materialClassCaptures.has(materialClass.id) ||
        materialClassEmptiness[materialClass.id] ||
        (autoLinkedMaterialClassCaptures?.has(materialClass.id) ?? false) ||
        Object.values(manualCaptureLinks).some(
          (l) =>
            l.type === 'material-class' &&
            l.materialClassId === materialClass.id,
        ),
    ) &&
    (wholeSampleCaptureOrder === 'none' ||
      linkedWholeSampleCaptures.length > 0 ||
      manuallyLinkedWholeSampleCaptures.length > 0 ||
      autoLinkedWholeSampleCapture !== undefined) &&
    unlinkedCaptures &&
    unlinkedCaptures.length === 0;

  const anyUncreatedLinks =
    (autoLinkedMaterialClassCaptures?.size ?? 0) > 0 ||
    Object.keys(manualCaptureLinks).length > 0 ||
    autoLinkedWholeSampleCapture !== undefined;

  const wholeSampleCaptureRow =
    wholeSampleCaptureOrder === 'none' ? null : (
      <div style={{ display: 'contents' }}>
        <Flex justify='center' align='center'>
          <Badge size='lg' variant='filled' color='blue'>
            Whole Sample
          </Badge>
        </Flex>
        <Flex justify='center' align='center'>
          <Checkbox size='md' disabled />
        </Flex>
        <Group noWrap>
          {pinningCaptureId ? (
            <ManualCaptureLinkTarget
              onClick={() => pinCapture({ type: 'whole-sample' })}
            />
          ) : null}
          {linkedWholeSampleCaptures.map((c) => (
            <PinnableCaptureCard key={c.captureId} capture={c} />
          ))}
          {manuallyLinkedWholeSampleCaptures.map((c) => (
            <PinnableCaptureCard
              key={c.captureId}
              capture={c}
              pinned
              onPinClick={() => removeManualCaptureLink(c.captureId)}
            />
          ))}
          {autoLinkedWholeSampleCapture ? (
            <PinnableCaptureCard
              key={autoLinkedWholeSampleCapture.captureId}
              capture={autoLinkedWholeSampleCapture}
              pinned={false}
              pinning={
                pinningCaptureId === autoLinkedWholeSampleCapture.captureId
              }
              onPinClick={() =>
                setPinningCaptureId(
                  pinningCaptureId === autoLinkedWholeSampleCapture.captureId
                    ? null
                    : autoLinkedWholeSampleCapture.captureId,
                )
              }
            />
          ) : null}{' '}
          {linkedWholeSampleCaptures.length === 0 &&
          manuallyLinkedWholeSampleCaptures.length === 0 &&
          !autoLinkedWholeSampleCapture ? (
            <Text c='dimmed'>waiting for capture...</Text>
          ) : null}
        </Group>
        <Flex align='center' justify='flex-end'>
          {wholeSampleCaptureWeights.map((w, i) => (
            <Fragment key={i}>
              {i > 0 ? ' + ' : null}
              <NetWeight weight={w} />
            </Fragment>
          ))}
        </Flex>
      </div>
    );

  const mutate = (completed: boolean) =>
    patchMutation.mutate(
      {
        analysisId: analysis.id,
        patch: {
          completed,
          newWholeSampleCaptures: [
            ...manuallyLinkedWholeSampleCaptures.map((c) => c.captureId),
            ...(autoLinkedWholeSampleCapture
              ? [autoLinkedWholeSampleCapture.captureId]
              : []),
          ],
          newCaptureToMaterialClassLinks: Object.fromEntries([
            ...(autoLinkedMaterialClassCaptures
              ? [...autoLinkedMaterialClassCaptures.entries()].map(
                  ([mcId, capture]) => [capture.captureId, mcId] as const,
                )
              : []),
            ...Object.entries(manualCaptureLinks)
              .filter(([, link]) => link.type === 'material-class')
              .map(
                ([captureId, link]) =>
                  [
                    captureId,
                    (link as ManualMaterialClassLink).materialClassId,
                  ] as const,
              ),
          ]),
        },
      },
      {
        onSuccess(_data, { patch: { completed } }) {
          if (!completed) {
            patchMutation.reset();
          }
        },
      },
    );

  const patchIncludedCompletion =
    patchMutation.variables?.patch.completed === true;

  return (
    <Stack>
      <Radio.Group
        label='Whole Sample Capture Order'
        description='When the capture of the entire sample is taken'
        value={wholeSampleCaptureOrder}
        onChange={(v) =>
          setWholeSampleCaptureOrder(v as WholeSampleCaptureOrder)
        }
      >
        <Group mt='xs'>
          <Radio value='first' label='First' />
          <Radio value='last' label='Last' />
          <Radio
            value='none'
            label='None'
            disabled={
              manuallyLinkedWholeSampleCaptures.length > 0 ||
              linkedWholeSampleCaptures.length > 0 ||
              Object.values(materialClassEmptiness).every((e) => e)
            }
          />
        </Group>
      </Radio.Group>
      <Group align='flex-start' noWrap>
        {/* TODO(2289): validate, this is where rowHeights was used */}
        <Paper w='fit-content' className={cssClasses.table} withBorder>
          <div style={{ display: 'contents' }}>
            <Text className={cssClasses.headerCell}>Material Class</Text>

            <Text className={cssClasses.headerCell}>Skip</Text>

            <Text className={cssClasses.headerCell}>Capture</Text>

            <Text className={cssClasses.headerCell}>Weight</Text>
          </div>

          {wholeSampleCaptureOrder === 'first' ? wholeSampleCaptureRow : null}

          {materialClasses.map((materialClass) => {
            const isEmpty = materialClassEmptiness[materialClass.id];
            const linkedCaptures = materialClassCaptures.get(materialClass.id);

            const manuallyLinkedCaptures: SamplingSuiteCaptureDTO[] = [];

            for (const [captureId, link] of Object.entries(
              manualCaptureLinks,
            )) {
              if (
                link.type !== 'material-class' ||
                link.materialClassId !== materialClass.id
              )
                continue;
              const capture = unlinkedCaptureLookup?.get(captureId);
              if (!capture) continue;
              manuallyLinkedCaptures.push(capture);
            }

            const autoLinkedCapture = autoLinkedMaterialClassCaptures?.get(
              materialClass.id,
            );

            const allCaptures = [
              ...(linkedCaptures?.captures ?? []),
              ...manuallyLinkedCaptures,
              ...(autoLinkedCapture ? [autoLinkedCapture] : []),
            ];

            const weights = [
              ...(linkedCaptures ? [linkedCaptures.totalNetWeight] : []),
              ...manuallyLinkedCaptures.map((c) => c.netWeight),
              ...(autoLinkedCapture ? [autoLinkedCapture.netWeight] : []),
            ];

            return (
              <div
                key={materialClass.id}
                style={{
                  display: 'contents',
                  background: isEmpty
                    ? 'var(--mantine-color-gray-4)'
                    : undefined,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Text p='md' weight={isEmpty ? undefined : 600}>
                    {materialClass.name}
                  </Text>
                </div>
                <Flex align='center' justify='center'>
                  <Checkbox
                    size='md'
                    disabled={
                      !isEmpty &&
                      (manuallyLinkedCaptures.length > 0 ||
                        !!linkedCaptures ||
                        (wholeSampleCaptureOrder === 'none' &&
                          Object.values(materialClassEmptiness).reduce(
                            (acc, e) => acc + (e ? 1 : 0),
                            0,
                          ) ===
                            materialClasses.length - 1))
                    }
                    checked={isEmpty}
                    onChange={(event) =>
                      setMaterialClassEmptiness((emptiness) => ({
                        ...emptiness,
                        [materialClass.id]: event.target.checked,
                      }))
                    }
                  />
                </Flex>

                <Group noWrap>
                  {pinningCaptureId && !isEmpty ? (
                    <ManualCaptureLinkTarget
                      onClick={() => {
                        pinCapture({
                          type: 'material-class',
                          materialClassId: materialClass.id,
                        });
                      }}
                    />
                  ) : null}
                  {allCaptures.length > 0 ? (
                    allCaptures.map((c) => {
                      const pinning = pinningCaptureId === c.captureId;
                      const pinned = c.captureId in manualCaptureLinks;
                      return (
                        <PinnableCaptureCard
                          key={c.captureId}
                          capture={c}
                          pinning={pinning}
                          pinned={pinned}
                          onPinClick={() => {
                            if (pinned) {
                              removeManualCaptureLink(c.captureId);
                              return;
                            }

                            setPinningCaptureId(pinning ? null : c.captureId);
                          }}
                        />
                      );
                    })
                  ) : isEmpty ? (
                    <Text c='dimmed'>none</Text>
                  ) : (
                    <Text c='dimmed'>waiting for capture...</Text>
                  )}
                </Group>
                <Group position='right' align='center'>
                  {isEmpty && weights.length === 0 ? (
                    <WithUnit unit='g'>0</WithUnit>
                  ) : null}
                  {weights.map((w, i) => (
                    <Fragment key={i}>
                      {i > 0 ? <Text>+</Text> : null}
                      <NetWeight weight={w} />
                    </Fragment>
                  ))}
                </Group>
              </div>
            );
          })}

          {wholeSampleCaptureOrder === 'last' ? wholeSampleCaptureRow : null}
        </Paper>
        <Divider orientation='vertical' />
        <Stack className={cssClasses.stickyAlert}>
          <Alert color='indigo' title='Ignored Captures'>
            {ignoredCaptureIds.size === 0 ? (
              <Text>No captures have been ignored.</Text>
            ) : null}
            <Group
              h={
                pinningCaptureId || ignoredCaptureIds.size > 0
                  ? captureRowHeight
                  : undefined
              }
            >
              {pinningCaptureId !== null ? (
                <ManualCaptureLinkTarget
                  onClick={() => {
                    ignoreCaptureId(pinningCaptureId);
                    setPinningCaptureId(null);
                  }}
                />
              ) : null}
              {[...ignoredCaptureIds].map((captureId) => {
                const capture = unlinkedCaptureLookup?.get(captureId);
                if (!capture) return null;
                return (
                  <PinnableCaptureCard
                    key={captureId}
                    capture={capture}
                    pinned
                    pinning={false}
                    onPinClick={() => unIgnoreCaptureId(captureId)}
                  />
                );
              })}
            </Group>
          </Alert>
          {unlinkedCaptures && unlinkedCaptures.length > 0 ? (
            <Alert color='orange' title='Unlinked Captures'>
              There are unlinked captures. Please manually link them to the
              correct material classes or ignore them to continue.
              <Group>
                {unlinkedCaptures.map((capture) => (
                  <PinnableCaptureCard
                    key={capture.captureId}
                    capture={capture}
                    pinned={false}
                    pinning={pinningCaptureId === capture.captureId}
                    onPinClick={() =>
                      setPinningCaptureId(
                        pinningCaptureId === capture.captureId
                          ? null
                          : capture.captureId,
                      )
                    }
                  />
                ))}
              </Group>
            </Alert>
          ) : null}
          {patchMutation.isError ? (
            <MutationErrorAlert
              errorTitle='Error Editing VALI-Sample Analysis'
              entityName='Sample Analysis'
              mutation={patchMutation}
              formVariant='edit'
            />
          ) : null}
          <Group>
            <Button
              disabled={!anyUncreatedLinks || patchMutation.isError}
              loading={patchMutation.isLoading || patchIncludedCompletion}
              m='sm'
              style={{ gridColumn: '3/4' }}
              variant='outline'
              color='blue'
              leftIcon={<SaveIcon />}
              onClick={() => {
                mutate(false);
              }}
            >
              Save Progress
            </Button>

            <Button
              m='sm'
              leftIcon={<IconCheck />}
              loading={patchMutation.isLoading}
              disabled={
                !canBeCompleted ||
                patchMutation.isError ||
                patchIncludedCompletion
              }
              onClick={() => {
                mutate(true);
              }}
            >
              Complete Analysis
            </Button>
          </Group>
        </Stack>
      </Group>
    </Stack>
  );
}

function ManualCaptureLinkTarget(props: { onClick: () => void }) {
  const { onClick } = props;
  return (
    <Button
      onClick={onClick}
      color='blue'
      variant='filled'
      h='100%'
      leftIcon={<IconPinInvoke />}
    >
      Pin
    </Button>
  );
}

function PinnableCaptureCard(props: {
  capture: SamplingSuiteCaptureDTO;
  pinned?: boolean;
  pinning?: boolean;
  onPinClick?: () => void;
}) {
  const { capture, pinned, pinning, onPinClick } = props;

  const patchMutation = usePatchSamplingSuiteCapture();

  let iconParams: {
    icon: ReactNode;
    onClick: () => void;
    color: string;
    variant: string;
    content: ReactNode;
  };
  if (patchMutation.isError) {
    iconParams = {
      icon: <IconRotate2 />,
      onClick: () => patchMutation.reset(),
      color: 'red',
      variant: 'filled',
      content: 'Reset',
    };
  } else if (capture.containerAnalysisLink) {
    iconParams = {
      icon: <IconUnlink />,
      onClick: () =>
        patchMutation.mutate({
          captureId: capture.captureId,
          patch: {
            containerSampleAnalysisLink: null,
          },
        }),
      color: 'orange',
      variant: 'outline',
      content: 'Unlink',
    };
  } else if (pinning) {
    iconParams = {
      icon: <IconX />,
      onClick: () => onPinClick?.(),
      color: 'gray',
      variant: 'filled',
      content: 'Cancel',
    };
  } else if (pinned) {
    iconParams = {
      icon: <IconPinnedOff />,
      onClick: () => onPinClick?.(),
      color: 'blue',
      variant: 'filled',
      content: 'Unpin',
    };
  } else {
    iconParams = {
      icon: <IconPin />,
      onClick: () => onPinClick?.(),
      color: 'blue',
      variant: 'outline',
      content: 'Pin',
    };
  }

  return (
    <div
      style={{
        width: '15rem',
        display: 'inline-flex',
        alignItems: 'stretch',
      }}
    >
      <SamplingSuiteCapturePngThumbnail
        key={capture.captureId}
        capture={capture}
      />
      <div>
        <Button
          px='xs'
          size='sm'
          radius='md'
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: 'none',
          }}
          loading={patchMutation.isLoading}
          variant={iconParams.variant}
          color={iconParams.color}
          h='100%'
          onClick={iconParams.onClick}
          leftIcon={iconParams.icon}
        >
          {iconParams.content}
        </Button>
      </div>
    </div>
  );
}
