import { Skeleton, Text } from '@mantine/core';
import {
  PurchasedMaterialName,
  PurchasedMaterialNameProps,
} from '../Repository/RepositoryName';
import { usePurchasedMaterial } from '../api/purchasedMaterial';

type PurchasedMaterialIdNameProps = Omit<
  PurchasedMaterialNameProps,
  'purchasedMaterial'
> & {
  purchasedMaterialId: string;
};

export function PurchasedMaterialIdName(props: PurchasedMaterialIdNameProps) {
  const { purchasedMaterialId, ...rest } = props;
  const { data: purchasedMaterial, isError } =
    usePurchasedMaterial(purchasedMaterialId);

  if (purchasedMaterial) {
    return (
      <PurchasedMaterialName purchasedMaterial={purchasedMaterial} {...rest} />
    );
  }

  if (isError) {
    return <Text color='red'>Error geting name</Text>;
  }

  return (
    <Skeleton visible>
      <Text>Loading name...</Text>
    </Skeleton>
  );
}
