import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MaterialClassIcon } from '../Icons';
import {
  AddMaterialClassDrawerForm,
  AddMaterialClassDrawerFormProps,
} from './AddMaterialClassDrawerForm';

export type AddMaterialClassButtonProps = Omit<ButtonProps, 'onClick'> & {
  addMaterialClassDrawerFormProps: Omit<
    AddMaterialClassDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddMaterialClassButton(
  props: AddMaterialClassButtonProps,
) {
  const {
    addMaterialClassDrawerFormProps,
    leftIcon = <MaterialClassIcon />,
    children = 'Add Material Class',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddMaterialClassDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addMaterialClassDrawerFormProps}
      />
    </>
  );
}
