/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RecoveryGoalClassificationPerformancesDTO } from '../models/RecoveryGoalClassificationPerformancesDTO';
import type { RecoveryGoalCreationDTO } from '../models/RecoveryGoalCreationDTO';
import type { RecoveryGoalDTO } from '../models/RecoveryGoalDTO';
import type { RecoveryGoalFeedFlowGroupClassificationPerformancesDTO } from '../models/RecoveryGoalFeedFlowGroupClassificationPerformancesDTO';
import type { RecoveryGoalPatchDTO } from '../models/RecoveryGoalPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RecoveryGoalService {
    /**
     * @returns RecoveryGoalDTO Success
     * @throws ApiError
     */
    public static getAllRecoveryGoals(): CancelablePromise<Array<RecoveryGoalDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/recovery-goals',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createRecoveryGoal(
        requestBody: RecoveryGoalCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/recovery-goals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param recoveryGoalGuid
     * @returns RecoveryGoalDTO Success
     * @throws ApiError
     */
    public static getRecoveryGoal(
        recoveryGoalGuid: string,
    ): CancelablePromise<RecoveryGoalDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/recovery-goals/{recoveryGoalGuid}',
            path: {
                'recoveryGoalGuid': recoveryGoalGuid,
            },
        });
    }
    /**
     * @param recoveryGoalGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchRecoveryGoal(
        recoveryGoalGuid: string,
        requestBody: RecoveryGoalPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/recovery-goals/{recoveryGoalGuid}',
            path: {
                'recoveryGoalGuid': recoveryGoalGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param recoveryGoalGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteRecoveryGoal(
        recoveryGoalGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/recovery-goals/{recoveryGoalGuid}',
            path: {
                'recoveryGoalGuid': recoveryGoalGuid,
            },
        });
    }
    /**
     * @param after
     * @param before
     * @param facilityId
     * @returns RecoveryGoalClassificationPerformancesDTO Success
     * @throws ApiError
     */
    public static getRecoveryGoalClassificationPerformances(
        after: string,
        before: string,
        facilityId?: string,
    ): CancelablePromise<Array<RecoveryGoalClassificationPerformancesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/recovery-goals/classification-performances',
            query: {
                'facilityId': facilityId,
                'after': after,
                'before': before,
            },
        });
    }
    /**
     * @param recoveryGoalGuid
     * @param after
     * @param before
     * @param facilityId
     * @returns RecoveryGoalFeedFlowGroupClassificationPerformancesDTO Success
     * @throws ApiError
     */
    public static getRecoveryGoalFeedFlowGroupClassificationPerformances(
        recoveryGoalGuid: string,
        after: string,
        before: string,
        facilityId?: string,
    ): CancelablePromise<RecoveryGoalFeedFlowGroupClassificationPerformancesDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/recovery-goals/{recoveryGoalGuid}/classification-performances',
            path: {
                'recoveryGoalGuid': recoveryGoalGuid,
            },
            query: {
                'facilityId': facilityId,
                'after': after,
                'before': before,
            },
        });
    }
}
