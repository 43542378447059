import { ActionIcon, Badge, Group, Text } from '@mantine/core';
import {
  IconSquareChevronDown,
  IconSquareChevronUp,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { ContainerIdName } from '../../Container/ContainerIdName';
import { FeedFlowGroupName } from '../../FeedFlowGroup/FeedFlowGroupName';
import {
  ContainerIcon,
  EmptyContainerIcon,
  FeedFlowGroupIcon,
  NoContainerIcon,
  OutputFlowGroupIcon,
  PartiallyFullContainerIcon,
  RecoveryGoalIcon,
} from '../../Icons';
import { RecoveryGoalPathName } from '../../RecoveryGoal/RecoveryGoalPathName';
import { CalendarDateTime, DurationTooltip } from '../../common';
import {
  FeedFlowGroupDTO,
  PortFlowGroupDTO,
  RecoveryGoalPathDTO,
} from '../../rest-client';
import { InventoryLedgerRowTemplate } from '../InventoryLedgerRowTemplate';
import { useInventoryLedgerStatusContext } from '../LedgerStatusContext';

export function FeedFlowGroupRow({
  feedFlowGroup,
}: {
  feedFlowGroup: FeedFlowGroupDTO;
}) {
  const ledgerStatus = useInventoryLedgerStatusContext();
  const status = ledgerStatus.feedFlowGroupStatus(feedFlowGroup);
  const timestamp = feedFlowGroup.effectiveTimestamp;

  const [showingOutputFlowGroups, setShowingOutputFlowGroups] = useState(false);

  const eventTypeCell = (
    <Group spacing='xs'>
      <FeedFlowGroupIcon />
      <Badge color='teal'>Process Run</Badge>
    </Group>
  );

  const sourceCell = (
    <Group spacing='xs'>
      {/* TODO(1809): add link to process buffer details once that's implemented */}
      {feedFlowGroup.bufferDepleted ? (
        <>
          <EmptyContainerIcon />
          <Text>Feedstock Completely Consumed</Text>
        </>
      ) : (
        <>
          <PartiallyFullContainerIcon />
          <Text>Feedstock Partially Consumed</Text>
        </>
      )}
    </Group>
  );

  const destinationCell = (
    <Group spacing='xs'>
      <FeedFlowGroupName feedFlowGroup={feedFlowGroup} noTimestamp withIcon />
      <ActionIcon onClick={() => setShowingOutputFlowGroups((v) => !v)}>
        {showingOutputFlowGroups ? (
          <IconSquareChevronUp />
        ) : (
          <IconSquareChevronDown />
        )}
      </ActionIcon>
    </Group>
  );

  // TODO allow edit/delete actions here?
  const actionCell = null;

  const portFlowGroups = new Map(
    feedFlowGroup.processState.processRecoveryGoalPaths.map((path) => [
      path.outputPortId,
      path.recoveryGoalPath,
    ]),
  );

  return (
    <>
      <InventoryLedgerRowTemplate
        eventType={eventTypeCell}
        date={<CalendarDateTime iso8601={timestamp} />}
        entryStatus={status}
        source={sourceCell}
        destination={destinationCell}
        actions={actionCell}
      />
      {showingOutputFlowGroups &&
        feedFlowGroup.portFlowGroupsByKey.map((portFlowGroupByKey) => (
          <>
            {portFlowGroupByKey.portFlowGroups.map((portFlowGroup, i) => (
              <OutputPortFlowGroupRow
                recoveryGoalPath={
                  portFlowGroups.get(portFlowGroupByKey.outputPortId) ??
                  undefined
                }
                portFlowGroup={portFlowGroup}
                key={i}
              />
            ))}
          </>
        ))}
    </>
  );
}

function OutputPortFlowGroupRow({
  recoveryGoalPath,
  portFlowGroup,
}: {
  recoveryGoalPath: RecoveryGoalPathDTO | undefined;
  portFlowGroup: PortFlowGroupDTO;
}) {
  const eventTypeCell = (
    <Group spacing='xs'>
      <OutputFlowGroupIcon />
      <Badge color='teal' variant='outline'>
        Process Run Output
      </Badge>
    </Group>
  );

  const sourceCell = (
    <Group spacing='xs'>
      <RecoveryGoalIcon />
      {recoveryGoalPath === undefined ? (
        <Text c='dimmed'>Unknown Recovery Goal</Text>
      ) : (
        <RecoveryGoalPathName recoveryGoalPath={recoveryGoalPath} />
      )}
      <DurationTooltip
        startIso8601={portFlowGroup.flowInterval.start}
        endIso8601={portFlowGroup.flowInterval.end}
      />
    </Group>
  );

  const destinationCell = (
    <Group spacing='xs'>
      {portFlowGroup.containerId === null ? (
        <>
          <NoContainerIcon color='red' />
          <Text c='red'>No Container</Text>
        </>
      ) : (
        <>
          <ContainerIcon />
          <ContainerIdName
            containerId={portFlowGroup.containerId}
            time={dayjs.utc(portFlowGroup.flowInterval.end)}
          />
        </>
      )}
    </Group>
  );

  return (
    <InventoryLedgerRowTemplate
      eventType={eventTypeCell}
      date={
        <Group position='center'>
          <CalendarDateTime iso8601={portFlowGroup.flowInterval.start} />
        </Group>
      }
      entryStatus={undefined}
      source={sourceCell}
      destination={destinationCell}
      actions={null}
    />
  );
}
