import { match } from 'ts-pattern';
import { The404 } from '../The404';
import { Router } from '../router';
import { MaterialClassDetailPage } from './MaterialClassDetailPage';
import { MaterialClassesPage } from './MaterialClassesPage';

export function MaterialClassArea() {
  const route = Router.useRoute(['MaterialClassList', 'MaterialClassDetail']);

  if (route === undefined) return <The404 />;

  return match(route)
    .with({ name: 'MaterialClassList' }, () => <MaterialClassesPage />)
    .with(
      { name: 'MaterialClassDetail' },
      ({ params: { materialClassId } }) => (
        <MaterialClassDetailPage materialClassId={materialClassId} />
      ),
    )
    .exhaustive();
}
