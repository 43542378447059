import { Loader, TextInput } from '@mantine/core';
import { UseFormReturnType, useForm } from '@mantine/form';

interface VendorFormValues {
  name: string;
  location: string | null;
  contactName: string | null;
}

export function VendorFormFields({
  form,
  loading = false,
}: {
  form: UseFormReturnType<VendorFormValues>;
  loading?: boolean;
}) {
  const inputProps = {
    rightSection: loading && <Loader size='xs' />,
    disabled: loading,
  };
  return (
    <>
      <TextInput
        label='Vendor Name'
        withAsterisk
        {...inputProps}
        {...form.getInputProps('name')}
      />
      <TextInput
        label='Location'
        {...inputProps}
        {...form.getInputProps('location')}
      />
      <TextInput
        label='Contact Name'
        {...inputProps}
        {...form.getInputProps('contactName')}
      />
    </>
  );
}

export function useVendorForm() {
  return useForm<VendorFormValues>({
    initialValues: {
      name: '',
      location: null,
      contactName: null,
    },
    validate: {
      name: (name) => (name ? null : 'Name is required'),
    },
  });
}
