import { Stack, Title } from '@mantine/core';
import { AppPage } from '../../App/AppPage';
import { FacilityIdName } from '../../Facility/FacilityIdName';
import { LabeledValue } from '../../common';
import { RedWaveSortSystemDTO } from '../../rest-client';
import { Router } from '../../router';
import { RedWaveSortProgramTable } from './RedWaveSortProgramTable';

export function RedWaveSortSystemDetail(props: {
  redWaveSystem: RedWaveSortSystemDTO;
}) {
  const { redWaveSystem } = props;

  const breadcrumbs = [
    { routeName: Router.ProcessList(), title: 'Processes' },
    'Sort Systems',
  ];

  return (
    <AppPage breadcrumbs={breadcrumbs} title={redWaveSystem.name}>
      <AppPage.Section>
        <Stack>
          <Title order={2}>Sort System Details</Title>
          <Stack align='flex-start'>
            <LabeledValue label='Facility'>
              <FacilityIdName facilityId={redWaveSystem.facilityId} />
            </LabeledValue>
          </Stack>
        </Stack>
      </AppPage.Section>

      <AppPage.Section>
        <Stack>
          <Title order={2}>Sort Programs</Title>
          <RedWaveSortProgramTable
            sortSystemId={redWaveSystem.id}
            paginate={false}
          />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
