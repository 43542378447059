import { ReactNode, createContext, useContext } from 'react';
import {
  ProcessOutputPortDTO,
  ProcessPlannedProductionIntervalDTO,
} from '../rest-client';

export interface ProductionIntervalContext {
  productionInterval: ProcessPlannedProductionIntervalDTO;
  processOutputPorts: ProcessOutputPortDTO[];
}

const ProductionIntervalContext = createContext<
  ProductionIntervalContext | undefined
>(undefined);

export function useProductionIntervalContext(): ProductionIntervalContext {
  const productionInterval = useContext(ProductionIntervalContext);
  if (productionInterval === undefined) {
    throw new Error('component must be within a production interval context');
  }
  return productionInterval;
}

export function ProductionIntervalContextProvider(props: {
  productionInterval: ProcessPlannedProductionIntervalDTO;
  processOutputPorts: ProcessOutputPortDTO[];
  children: ReactNode;
}) {
  const { productionInterval, processOutputPorts, children } = props;
  return (
    <ProductionIntervalContext.Provider
      value={{ productionInterval, processOutputPorts }}
    >
      {children}
    </ProductionIntervalContext.Provider>
  );
}
