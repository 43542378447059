import { Group, Stack, Title } from '@mantine/core';
import { AppPage } from '../App/AppPage';
import AddInternalSinkContainerTransferButton from '../InternalSinkContainerTransfer/AddInternalSinkContainerTransferButton';
import { InternallySinkedMaterialTable } from '../InternalSinkContainerTransferredMaterials/InternallySinkedMaterialsTable';
import AddInternalMaterialSinkButton from './AddInternalMaterialSinkButton';
import { InternalMaterialSinkTable } from './InternalMaterialSinkTable';

export function InternalMaterialSinksPage() {
  return (
    <AppPage title='Material Exports'>
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Material Export Destinations</Title>
            <AddInternalMaterialSinkButton
              addInternalMaterialSinkDrawerFormProps={{}}
            />
          </Group>
          <InternalMaterialSinkTable />
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Exported Materials</Title>
            <AddInternalSinkContainerTransferButton
              addInternalSinkTransferDrawerFormProps={{}}
            />
          </Group>
          <InternallySinkedMaterialTable />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
