import { Loader, Select, SelectProps } from '@mantine/core';
import { useEffect, useMemo } from 'react';
import { OutputPortId, ProcessOutputPortDTO } from '../rest-client';

export interface ProcessOutputPortSelectBaseProps {
  processOutputPorts?: ProcessOutputPortDTO[];
  value: OutputPortId | null;
  onChange: (outputPortId: OutputPortId | null) => void;
  isLoading?: boolean;
}

export type ProcessOutputPortSelectProps = Omit<
  SelectProps,
  keyof ProcessOutputPortSelectBaseProps | 'data' | 'rightSection'
> &
  ProcessOutputPortSelectBaseProps;

export function ProcessOutputPortSelect(props: ProcessOutputPortSelectProps) {
  const {
    processOutputPorts = [],
    value,
    onChange,
    disabled = false,
    searchable = false,
    label = 'Process Output',
    nothingFound = 'No process output ports found',
    placeholder = 'Select process output port',
    isLoading = false,
    ...selectProps
  } = props;

  const noProcess = processOutputPorts === undefined;

  const data = useMemo(() => {
    return processOutputPorts.map(({ name, outputPortId }) => ({
      label: name,
      value: outputPortId,
    }));
  }, [processOutputPorts]);

  // reset value to null if possible values changes to no longer include value
  useEffect(() => {
    if (value === null || disabled) {
      return;
    }
    const validIds = processOutputPorts.map(({ outputPortId }) => outputPortId);
    if (!validIds.includes(value)) {
      onChange(null);
    }
  }, [processOutputPorts, value, onChange, disabled]);

  return (
    <Select
      data={data}
      value={value}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      disabled={noProcess || disabled}
      searchable={searchable}
      nothingFound={nothingFound}
      rightSection={isLoading && !disabled ? <Loader size='xs' /> : undefined}
      {...selectProps}
    />
  );
}
