import {
  Alert,
  Group,
  SimpleGrid,
  Skeleton,
  Stack,
  Tabs,
  Title,
} from '@mantine/core';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { match } from 'ts-pattern';
import { z } from 'zod';
import { AppPage } from '../App/AppPage';
import { useFacilityContext } from '../Facility/FacilityContext';
import { GenealogyExplorer } from '../GenealogyExplorer/GenealogyExplorer';
import {
  ProcessTopologyDiagram,
  ProcessTopologyEdge,
  ProcessTopologyNode,
} from '../ProcessTopologyDiagram';
import { SortSystemName } from '../SortSystem/SortSystemName.tsx';
import {
  RedWaveActiveSystemProgram,
  RedWaveActiveSystemProgramProps,
} from '../SortSystem/redwave/RedWaveActiveSystemProgram';
import Temporal from '../Temporal/temporal.ts';
import {
  useFeedFlowGroup,
  useFeedFlowGroupFeedstockGenealogy,
} from '../api/feedFlowGroup';
import { useProcess } from '../api/process';
import { useSortSystem } from '../api/sortSystem';
import { LabeledValue } from '../common';
import {
  ChutecSortSystemDTO,
  ProcessId,
  RedWaveSortSystemDTO,
  SortSystemId,
} from '../rest-client';
import { usePersistentRouteParams } from '../util/useRouteParams.ts';
import { FeedFlowGroupClassificationPerformance } from './FeedFlowGroupClassificationPerformance';
import { FeedFlowGroupOutput } from './FeedFlowGroupOutput';
import { ProcessEdgeMetrics } from './metrics/ProcessEdgeMetrics';
import { ProcessNodeMetrics } from './metrics/ProcessNodeMetrics';

// Careful changing any of this because it could break links if you are not careful
// breaking a link isn't the end of the world but should be avoided if at all possible
const METRICS_TAB = 'metrics';
const FEEDSTOCK_TAB = 'feedstock';
const OUTPUTS_TAB = 'outputs';
const PERFORMANCE_TAB = 'performance';

export const FeedFlowGroupDetailPageTabs = {
  [METRICS_TAB]: METRICS_TAB,
  [FEEDSTOCK_TAB]: FEEDSTOCK_TAB,
  [OUTPUTS_TAB]: OUTPUTS_TAB,
  [PERFORMANCE_TAB]: PERFORMANCE_TAB,
} as const;

const FeedFlowGroupDetailTab = z.union([
  z.literal(METRICS_TAB),
  z.literal(FEEDSTOCK_TAB),
  z.literal(OUTPUTS_TAB),
  z.literal(PERFORMANCE_TAB),
]);
type FeedFlowGroupDetailTab = z.infer<typeof FeedFlowGroupDetailTab>;

function ChutecSystemParameterDetail(props: {
  system: ChutecSortSystemDTO;
  timestamp: Dayjs;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { system, timestamp } = props;
  return <Alert>Chutec system parameter detail not implemented</Alert>;
}

export type RedWaveSystemParameterDetailProps = Omit<
  RedWaveActiveSystemProgramProps,
  'systemId'
> & {
  system: RedWaveSortSystemDTO;
  noLabel?: boolean;
};

function RedWaveSystemParameterDetail(
  props: RedWaveSystemParameterDetailProps,
) {
  const { system, noLabel = false, ...otherProps } = props;

  if (noLabel) {
    return <RedWaveActiveSystemProgram systemId={system.id} {...otherProps} />;
  }

  return (
    <LabeledValue label='Sort Program' key={system.id}>
      <RedWaveActiveSystemProgram systemId={system.id} {...otherProps} />
    </LabeledValue>
  );
}

export type SystemParameterDetailProps = Omit<
  RedWaveSystemParameterDetailProps,
  'system'
> & {
  systemId: SortSystemId;
};

export function SystemParameterDetail(props: SystemParameterDetailProps) {
  const { systemId, ...otherProps } = props;
  const systemQuery = useSortSystem(systemId);

  if (systemQuery.isLoadingError) {
    throw systemQuery.error;
  }

  if (systemQuery.isLoading) return <Skeleton visible>Loading...</Skeleton>;

  const system = systemQuery.data;

  return match(system)
    .with({ kind: 'RedWave' }, (redWave) => (
      <RedWaveSystemParameterDetail system={redWave} {...otherProps} />
    ))
    .with({ kind: 'Chutec' }, (chutec) => (
      <ChutecSystemParameterDetail
        system={chutec}
        timestamp={otherProps.timestamp}
      />
    ))
    .exhaustive();
}

export type ProcessParameterDetailProps = Omit<
  SystemParameterDetailProps,
  'systemId'
> & {
  processId: ProcessId;
};

export function ProcessParameterDetail(props: ProcessParameterDetailProps) {
  const { processId, ...otherProps } = props;
  const processQuery = useProcess(processId);

  if (processQuery.isLoadingError) {
    throw processQuery.error;
  }

  if (processQuery.isLoading) return <Skeleton visible>Loading...</Skeleton>;

  const systems = processQuery.data.sortSystems;

  if (systems.length === 1) {
    if (otherProps.noLabel) {
      return (
        <Group>
          <SortSystemName sortSystem={systems[0]} />
          <SystemParameterDetail systemId={systems[0].id} {...otherProps} />
        </Group>
      );
    } else {
      return (
        <Group>
          <LabeledValue label='Sort System'>
            <SortSystemName sortSystem={systems[0]} />
          </LabeledValue>
          <SystemParameterDetail systemId={systems[0].id} {...otherProps} />
        </Group>
      );
    }
  }
}

export const FeedFlowGroupDetail = (props: { feedFlowGroupId: string }) => {
  const { feedFlowGroupId } = props;
  const { timeZoneId: tz } = useFacilityContext();

  const [routeParams, updateRouteParams] = usePersistentRouteParams([
    'FeedFlowGroupDetail',
  ]);
  const tabParseResult =
    routeParams.tab !== undefined
      ? FeedFlowGroupDetailTab.safeParse(routeParams.tab)
      : undefined;
  const tab = tabParseResult?.success ? tabParseResult.data : undefined;

  const [selectedNode, setSelectedNode] = useState<ProcessTopologyNode | null>(
    null,
  );
  const [selectedEdge, setSelectedEdge] = useState<ProcessTopologyEdge | null>(
    null,
  );

  const feedstockGenealogyQuery = useFeedFlowGroupFeedstockGenealogy({
    feedFlowGroupId,
  });

  const feedFlowGroupQuery = useFeedFlowGroup(feedFlowGroupId);
  const feedFlowGroup = feedFlowGroupQuery.data;
  const processId = feedFlowGroup?.processState.processId;
  const processQuery = useProcess(processId);
  const portFlowGroups = feedFlowGroup?.portFlowGroupsByKey;

  if (feedFlowGroupQuery.status === 'loading') {
    // TODO
  } else if (feedFlowGroupQuery.status === 'error') {
    // TODO
  } else if (feedFlowGroup) {
    const {
      activeIntervals,
      processState: { processId },
    } = feedFlowGroup;

    if (!activeIntervals.length) {
      // TODO
      throw new Error('No active intervals');
    }
    const startTime = Temporal.Instant.from(activeIntervals[0].start);
    const endTime = Temporal.Instant.from(
      activeIntervals[activeIntervals.length - 1].end,
    );

    const facilityStartTime = startTime.toZonedDateTimeISO(tz);
    const facilityEndTime = endTime.toZonedDateTimeISO(tz);
    const duration = facilityEndTime.since(facilityStartTime);

    // TODO include breadcrumbs
    return (
      <AppPage
        title={`Process Run: ${facilityStartTime.toLocaleString(undefined, {
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}`}
      >
        <SimpleGrid cols={2}>
          <AppPage.Section>
            <Stack>
              <Title order={3}>System Parameters</Title>
              <ProcessParameterDetail
                processId={processId}
                timestamp={dayjs.utc(startTime.epochMilliseconds)}
              />
            </Stack>
          </AppPage.Section>
          <AppPage.Section>
            <Stack>
              <Title order={3}>Process Run Parameters</Title>
              <LabeledValue label='Processing Date'>
                {facilityStartTime.toLocaleString(undefined, {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </LabeledValue>
              <Group>
                <LabeledValue label='Start Time'>
                  {facilityStartTime.toLocaleString(undefined, {
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </LabeledValue>
                <LabeledValue label='End Time'>
                  {facilityEndTime.toLocaleString(undefined, {
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </LabeledValue>
                <LabeledValue label='Duration'>
                  {/* Note: "locale string" features on Temporal.Duration are not fully baked */}
                  {duration.days ? `${duration.days} Days ` : null}
                  {duration.hours ? `${duration.hours} Hours ` : null}
                  {duration.minutes ? `${duration.minutes} Minutes ` : null}
                </LabeledValue>
              </Group>
            </Stack>
          </AppPage.Section>
        </SimpleGrid>
        <Tabs
          h='100%'
          value={tab ?? METRICS_TAB}
          onTabChange={(tabValue) =>
            updateRouteParams({ tab: tabValue ?? undefined })
          }
          keepMounted={false}
        >
          <Tabs.List>
            <Tabs.Tab value={METRICS_TAB}>Metrics</Tabs.Tab>
            <Tabs.Tab value={FEEDSTOCK_TAB}>Feedstock</Tabs.Tab>
            <Tabs.Tab value={OUTPUTS_TAB}>Outputs</Tabs.Tab>
            <Tabs.Tab value={PERFORMANCE_TAB}>Performance</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={OUTPUTS_TAB}>
            <AppPage.Section>
              <Stack>
                {processQuery.data && portFlowGroups && (
                  <FeedFlowGroupOutput portFlowGroupsByKey={portFlowGroups} />
                )}
              </Stack>
            </AppPage.Section>
          </Tabs.Panel>
          <Tabs.Panel value={FEEDSTOCK_TAB}>
            <GenealogyExplorer genealogyQuery={feedstockGenealogyQuery} />
          </Tabs.Panel>
          <Tabs.Panel value={METRICS_TAB}>
            <AppPage.Section>
              <Stack>
                <ReactFlowProvider>
                  {processQuery.data && (
                    <Stack>
                      <Title order={4}>Process Layout</Title>
                      <ProcessTopologyDiagram
                        process={processQuery.data}
                        onNodeSelect={setSelectedNode}
                        onEdgeSelect={setSelectedEdge}
                      />
                    </Stack>
                  )}
                </ReactFlowProvider>
                {selectedNode === null && selectedEdge === null ? (
                  <Alert color='blue'>
                    Select a node or link in the graph to see processing
                    metrics.
                  </Alert>
                ) : undefined}
                {selectedNode && processQuery.data && (
                  <ProcessNodeMetrics
                    node={selectedNode}
                    process={processQuery.data}
                    startTime={startTime}
                    endTime={endTime}
                  />
                )}
                {selectedEdge && processQuery.data && (
                  <ProcessEdgeMetrics
                    edge={selectedEdge}
                    process={processQuery.data}
                    startTime={startTime}
                    endTime={endTime}
                  />
                )}
              </Stack>
            </AppPage.Section>
          </Tabs.Panel>
          <Tabs.Panel value={PERFORMANCE_TAB}>
            <AppPage.Section mt='md'>
              <FeedFlowGroupClassificationPerformance
                feedFlowGroupId={feedFlowGroupId}
              />
            </AppPage.Section>
          </Tabs.Panel>
        </Tabs>
      </AppPage>
    );
  }
};
