/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MaterialClassCreationDTO } from '../models/MaterialClassCreationDTO';
import type { MaterialClassDTO } from '../models/MaterialClassDTO';
import type { MaterialClassPatchDTO } from '../models/MaterialClassPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MaterialClassService {
    /**
     * @returns MaterialClassDTO Success
     * @throws ApiError
     */
    public static allMaterialClasses(): CancelablePromise<Array<MaterialClassDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-classes',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createMaterialClass(
        requestBody: MaterialClassCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/material-classes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param materialClassGuid
     * @returns MaterialClassDTO Success
     * @throws ApiError
     */
    public static getMaterialClassById(
        materialClassGuid: string,
    ): CancelablePromise<MaterialClassDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-classes/{materialClassGuid}',
            path: {
                'materialClassGuid': materialClassGuid,
            },
        });
    }
    /**
     * @param materialClassGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchMaterialClass(
        materialClassGuid: string,
        requestBody: MaterialClassPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/material-classes/{materialClassGuid}',
            path: {
                'materialClassGuid': materialClassGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param materialClassGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteMaterialClass(
        materialClassGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/material-classes/{materialClassGuid}',
            path: {
                'materialClassGuid': materialClassGuid,
            },
        });
    }
}
