import { Loader, Stack, Title } from '@mantine/core';
import { useSortSystem } from '../../api/sortSystem';
import Temporal from '../../Temporal/temporal.ts';
import { ProcessDTO } from '../../rest-client';
import { CumulativeSystemMetrics } from './CumulativeSystemMetrics';

export function FeedstockNodeMetrics(props: {
  process: ProcessDTO;
  startTime: Temporal.Instant;
  endTime: Temporal.Instant;
}) {
  const { process, startTime, endTime } = props;
  const inputSystemQuery = useSortSystem(process.inputSystemId);
  return (
    <Stack>
      <Title order={2}>Feedstock Metrics</Title>
      {inputSystemQuery.data ? (
        <CumulativeSystemMetrics
          system={inputSystemQuery.data}
          port={{ type: 'input' }}
          startTime={startTime}
          endTime={endTime}
        />
      ) : (
        <Loader />
      )}
    </Stack>
  );
}
