import { Group, GroupPosition, Text, TextProps } from '@mantine/core';
import { match } from 'ts-pattern';
import { WithUnit } from '../WithUnit';

export interface FormatTimeWithUnitsProps {
  totalMinutes: number;
  position?: GroupPosition;
  verbosity?: 'verbose' | 'normal' | 'succinct';
  timeTextProps?: TextProps;
  unitTextProps?: TextProps;
}

export function FormatTimeWithUnits(props: FormatTimeWithUnitsProps) {
  const {
    totalMinutes,
    position = 'right',
    verbosity = 'succinct',
    timeTextProps,
    unitTextProps,
  } = props;

  const hours = Math.floor(totalMinutes / 60);
  let minutes = Math.floor(totalMinutes % 60).toString();

  if (hours > 0) {
    minutes = minutes.toString().padStart(2, '0');
  }

  return (
    <Group spacing='.25ch' noWrap position={position}>
      {hours > 0 && (
        <WithUnit
          unit={match(verbosity)
            .with('verbose', () => 'hours')
            .with('normal', () => 'hr')
            .with('succinct', () => 'h')
            .exhaustive()}
          {...unitTextProps}
        >
          <Text {...timeTextProps}>{hours}</Text>
        </WithUnit>
      )}
      <WithUnit
        unit={match(verbosity)
          .with('verbose', () => 'minutes')
          .with('normal', () => 'min')
          .with('succinct', () => 'm')
          .exhaustive()}
        {...unitTextProps}
      >
        <Text {...timeTextProps} w='2ch' align='right'>
          {minutes}
        </Text>
      </WithUnit>
    </Group>
  );
}
