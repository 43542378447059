import {
  Alert,
  Flex,
  Grid,
  Group,
  Loader,
  SegmentedControl,
  Stack,
  Switch,
  Text,
  Title,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useState } from 'react';
import { AppPage } from '../App/AppPage';
import { useFacilityContext } from '../Facility/FacilityContext';
import Temporal from '../Temporal/temporal.ts';
import { useProcesses } from '../api/process';
import { LabeledValue } from '../common';
import { usePersistentRouteParams } from '../util/useRouteParams.ts';
import ProcessProductionHistoryStopReasonBreakdown from './ProcessProductionHistoryStopReasonBreakdown';
import ProcessProductionIntervalStatusTable from './ProcessProductionIntervalStatusTable';

export default function ProcessProductionHistory() {
  const [{ processId, after: rawAfter, before: rawBefore }, updateRouteParams] =
    usePersistentRouteParams(['ProductionHistory']);

  const facility = useFacilityContext();
  const processesQuery = useProcesses(facility.id);
  const [normalizeByUnplannedStops, setNormalizeByUnplannedStops] =
    useState(true);

  let after: Temporal.PlainDate | undefined;
  try {
    if (rawAfter !== undefined) {
      after = Temporal.PlainDate.from(rawAfter);
    }
  } catch {
    updateRouteParams(
      {
        after: undefined,
      },
      {
        replace: true,
      },
    );
  }

  let before: Temporal.PlainDate | undefined;
  try {
    if (rawBefore !== undefined) {
      before = Temporal.PlainDate.from(rawBefore);
    }
  } catch {
    updateRouteParams(
      {
        before: undefined,
      },
      {
        replace: true,
      },
    );
  }

  if (processesQuery.isLoadingError) {
    throw processesQuery.error;
  }

  if (processesQuery.isLoading) {
    return <Loader variant='bars' size='xl' />;
  }

  const processes = processesQuery.data;

  if (processes.length === 0) {
    return <Alert color='yellow'>No processes have been defined yet.</Alert>;
  }

  if (processId === undefined) {
    updateRouteParams(
      {
        processId: processes[0].id,
      },
      {
        replace: true,
      },
    );
  }

  const systemTimeZone = Temporal.Now.timeZoneId();
  const afterZdt = after?.toPlainDateTime().toZonedDateTime(systemTimeZone);
  const beforeZdt = before
    ?.toPlainDateTime('23:59')
    .toZonedDateTime(systemTimeZone);

  return (
    <Grid grow>
      <Grid.Col span={2}>
        <AppPage.Section>
          <Stack>
            <Title order={3}>Production History Inputs</Title>
            <SegmentedControl
              size='md'
              data={processes.map((p) => ({ label: p.name, value: p.id }))}
              value={processId}
              onChange={(pId) =>
                updateRouteParams({
                  processId: pId,
                })
              }
              w={300}
            />
            <DatePicker
              size='md'
              type='range'
              maxDate={new Date()}
              value={[
                afterZdt === undefined
                  ? null
                  : new Date(afterZdt.epochMilliseconds),
                beforeZdt === undefined
                  ? null
                  : new Date(beforeZdt.epochMilliseconds),
              ]}
              onChange={([newAfter, newBefore]) => {
                updateRouteParams({
                  after: newAfter
                    ?.toTemporalInstant()
                    .toZonedDateTimeISO(systemTimeZone)
                    .toPlainDate()
                    .toString(),
                  before: newBefore
                    ?.toTemporalInstant()
                    .toZonedDateTimeISO(systemTimeZone)
                    .toPlainDate()
                    .toString(),
                });
              }}
            />
            <Stack>
              <Group>
                <LabeledValue label='Start Date' w={'10ch'}>
                  {after === undefined ? (
                    <Text c='dimmed'>None</Text>
                  ) : (
                    <Text>{after.toLocaleString()}</Text>
                  )}
                </LabeledValue>
                <LabeledValue label='End Date' w={'10ch'}>
                  {before === undefined ? (
                    <Text c='dimmed'>None</Text>
                  ) : (
                    <Text>{before.toLocaleString()}</Text>
                  )}
                </LabeledValue>
                <LabeledValue label='Duration'>
                  {after === undefined || before === undefined ? (
                    <Text c='dimmed'>None</Text>
                  ) : (
                    <Text>
                      {(after.until(before).days + 1).toLocaleString() +
                        ' days'}
                    </Text>
                  )}
                </LabeledValue>
              </Group>
            </Stack>
          </Stack>
        </AppPage.Section>
      </Grid.Col>
      <Grid.Col span={9}>
        <AppPage.Section h='100%'>
          <Stack>
            <Title order={3}>Stop Reason Temporal Breakdown</Title>
            {after !== undefined && before !== undefined && processId ? (
              <ProcessProductionHistoryStopReasonBreakdown
                processId={processId}
                after={afterZdt?.toInstant() ?? null}
                before={beforeZdt?.toInstant() ?? null}
              />
            ) : (
              <Alert color='blue' title={'Select Date Range'}>
                No production history date range is selected. Select a date
                range to view the processs stop reason temporal breakdown for
                the selected process.
              </Alert>
            )}
          </Stack>
        </AppPage.Section>
      </Grid.Col>

      <Grid.Col>
        <AppPage.Section>
          <Stack>
            <Flex gap='md'>
              <Title order={3}>Production Interval Metrics</Title>
              <LabeledValue
                label='Ignore Unplanned Stops'
                infoIconContent='Calculate metrics ignoring unplanned stops'
              >
                <Switch
                  checked={normalizeByUnplannedStops}
                  onChange={(e) =>
                    setNormalizeByUnplannedStops(e.currentTarget.checked)
                  }
                  my='.2em'
                />
              </LabeledValue>
            </Flex>
            {after !== undefined && before !== undefined && processId ? (
              <ProcessProductionIntervalStatusTable
                processId={processId}
                after={afterZdt?.toInstant() ?? null}
                before={beforeZdt?.toInstant() ?? null}
                normalizeByUnplannedStops={normalizeByUnplannedStops}
              />
            ) : (
              <Alert title='Select Date Range' color='blue'>
                Select a date range to see production data.
              </Alert>
            )}
          </Stack>
        </AppPage.Section>
      </Grid.Col>
    </Grid>
  );
}
