import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateOutputContainerChange } from '../api/outputContainerChange';
import { OutputContainerChangeCreationDTO } from '../rest-client';
import {
  OutputContainerChangeFormFields,
  OutputContainerChangeFormProps,
  OutputContainerChangeFormValues,
  useOutputContainerChangeForm,
} from './OutputContainerChangeForm';

export type AddOutputContainerChangeDrawerFormProps =
  OutputContainerChangeFormProps & {
    onSuccess?: (
      outputContainerChange: OutputContainerChangeCreationDTO,
    ) => void;
  } & DrawerFormDrawerProps;

export function AddOutputContainerChangeDrawerForm(
  props: AddOutputContainerChangeDrawerFormProps,
) {
  const {
    processId,
    outputPortId,
    newContainerId,
    onSuccess,
    onClose,
    ...drawerFormDrawerProps
  } = props;

  const createMutation = useCreateOutputContainerChange();
  const form = useOutputContainerChangeForm({
    processId,
    outputPortId,
    newContainerId,
  });
  const outputContainerChangeId = useMemo(() => uuidv4(), []);

  const formValuesToDto = ({
    changeTime,
    processId,
    outputPortId,
    newContainerId,
    previousContainerFilled,
  }: OutputContainerChangeFormValues) => {
    if (processId === null) {
      throw new Error('Process cannot be null');
    }
    if (outputPortId === null) {
      throw new Error('Output port cannot be null');
    }

    const outputContainerChange: OutputContainerChangeCreationDTO = {
      id: outputContainerChangeId,
      effectiveTimestamp: changeTime.utc().toISOString(),
      processId,
      outputPortId,
      containerId: newContainerId,
      previousContainerFilled,
    };

    return outputContainerChange;
  };

  return (
    <DrawerForm
      entityName='Output Container Change'
      form={form}
      drawerFormVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      size='lg'
      {...drawerFormDrawerProps}
    >
      <OutputContainerChangeFormFields form={form} />
    </DrawerForm>
  );
}
