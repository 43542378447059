import { match } from 'ts-pattern';
import { The404 } from '../The404';
import { Router } from '../router';
import { FeedFlowGroupDetail } from './FeedFlowGroupDetail';

export const FeedFlowGroupArea = () => {
  const route = Router.useRoute(['FeedFlowGroupDetail']);
  if (route === undefined) {
    return <The404 />;
  }

  return match(route)
    .with(
      { name: 'FeedFlowGroupDetail' },
      ({ params: { feedFlowGroupId } }) => (
        <FeedFlowGroupDetail feedFlowGroupId={feedFlowGroupId} />
      ),
    )
    .exhaustive();
};
