import { Center, Group, Loader, Stack, Title } from '@mantine/core';
import { match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { useInternalMaterialSourceCompositionsByCommodity } from '../api/internalMaterialSource';
import AddInternalMaterialSourceButton from './AddInternalMaterialSourceButton';
import { InternalMaterialSourceCompositionByCommodityTabs } from './InternalMaterialSourceCompositionsByCommodityChart';
import { InternalMaterialSourceTable } from './InternalMaterialSourceTable';

export function InternalSourcingPage() {
  const compositionsByCommodityQuery =
    useInternalMaterialSourceCompositionsByCommodity(undefined, undefined);

  if (compositionsByCommodityQuery.isError) {
    throw compositionsByCommodityQuery.error;
  }

  const commoditySourcesSection = match(compositionsByCommodityQuery.data)
    .with(undefined, () => (
      <Center>
        <Loader variant='bars' size='xl' />
      </Center>
    ))
    .when(
      (result) => Object.entries(result).length === 0,
      () => undefined,
    )
    .otherwise((result) => {
      return (
        <InternalMaterialSourceCompositionByCommodityTabs
          commoditesMcsImsComps={result}
        />
      );
    });

  return (
    <AppPage title='Upstream Material'>
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Upstream Material Sources</Title>
            <AddInternalMaterialSourceButton
              addInternalMaterialSourceDrawerFormProps={{}}
            />
          </Group>
          <InternalMaterialSourceTable />
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Title order={2}>Commodity Sources</Title>
          {commoditySourcesSection}
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
