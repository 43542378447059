import { Title } from '@mantine/core';
import { ReactNode } from 'react';
import { DateRange } from '../../../Temporal/dateRangeAndOffset.ts';
import { MetricDateRangeButton } from '../DateRange/MetricDateRangeButton.tsx';
import {
  MetricHeaderBreadcrumbs,
  MetricHeaderBreadcrumbsNode,
} from './MetricHeaderBreadcrumbs.tsx';
import { MetricHeaderExportButton } from './MetricHeaderExportButton.tsx';
import classes from './css/MetricHeader.module.css';

export interface MetricHeaderProps {
  title: ReactNode;
  breadcrumbs?: MetricHeaderBreadcrumbsNode[];
  dateRange: DateRange;
  setDateRange: (dateRange: Partial<DateRange> | null) => void;
}

export function MetricHeader(props: MetricHeaderProps) {
  const { title, breadcrumbs, setDateRange, dateRange } = props;

  return (
    <div className={classes.header}>
      <div className={classes.leftSection}>
        <MetricHeaderBreadcrumbs data={breadcrumbs ?? []} />
        <Title order={1}>{title}</Title>
      </div>
      <div className={classes.rightSection}>
        <MetricHeaderExportButton title={title} />
        <MetricDateRangeButton
          setDateRange={setDateRange}
          dateRange={dateRange}
        />
      </div>
    </div>
  );
}
