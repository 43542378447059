import { TextInput } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';

const CommodityFormValues = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().nullable(),
});
export type CommodityFormValues = z.infer<typeof CommodityFormValues>;

export function CommodityFormFields(props: {
  form: UseFormReturnType<CommodityFormValues>;
}) {
  const { form } = props;

  return (
    <>
      <TextInput
        label='Commodity Name'
        description='Succinctly name this commodity.'
        placeholder='Name'
        withAsterisk
        {...form.getInputProps('name')}
      />
      <TextInput
        label='Description'
        placeholder='Description'
        {...form.getInputProps('description')}
      />
    </>
  );
}

export function useCommodityForm() {
  return useForm<CommodityFormValues>({
    initialValues: {
      name: '',
      description: null,
    },
    validate: zodResolver(CommodityFormValues),
  });
}
