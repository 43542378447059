import { match } from 'ts-pattern';
import { Router } from '../router';
import RecoveryStrategySimulationDetailPage from './RecoveryStrategySimulationDetailPage';

export default function RecoveryStrategySimulationArea() {
  const route = Router.useRoute(['RecoveryStrategySimulationDetail']);
  if (route === undefined) throw new Error('unknown route');

  return match(route)
    .with(
      { name: 'RecoveryStrategySimulationDetail' },
      ({ params: { simulationId } }) => (
        <RecoveryStrategySimulationDetailPage simulationId={simulationId} />
      ),
    )
    .exhaustive();
}
