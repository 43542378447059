import { Group, MantineColor, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { SortSystemIcon } from '../Icons';
import { LinkText } from '../Link';
import { SortSystemDTO } from '../rest-client';
import { Router } from '../router';

export interface SortSystemNameProps {
  sortSystem: SortSystemDTO;
  noLink?: boolean;
  withIcon?: boolean;
  color?: 'dimmed' | MantineColor;
}

export function SortSystemName(props: SortSystemNameProps) {
  const { sortSystem, noLink, withIcon, color } = props;

  const IconWrapper = ({ children }: { children: ReactNode }) => {
    if (withIcon) {
      return (
        <Group spacing='xs'>
          <SortSystemIcon />
          {children}
        </Group>
      );
    } else {
      return <>{children}</>;
    }
  };

  if (noLink) {
    return (
      <IconWrapper>
        <Text color={color}>{sortSystem.name}</Text>
      </IconWrapper>
    );
  }

  return (
    <IconWrapper>
      <LinkText to={Router.SortSystemDetail({ sortSystemId: sortSystem.id })}>
        {sortSystem.name}
      </LinkText>
    </IconWrapper>
  );
}
