import { useQuery } from '@tanstack/react-query';
import Temporal from '../Temporal/temporal.ts';
import {
  FeedFlowGroupService,
  MaterialStateService,
  ProcessStateService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys } from './queryKeys';

async function fetchFeedFlowGroup(
  ctx: QueryFunctionContexts['feedFlowGroup']['byId'],
) {
  const [{ id }] = ctx.queryKey;
  return await ProcessStateService.getFeedFlowGroupById(id);
}

export function useFeedFlowGroup(id: string) {
  return useQuery({
    queryKey: queryKeys.feedFlowGroup.byId(id),
    queryFn: fetchFeedFlowGroup,
  });
}

async function fetchFeedFlowGroupMaterialSets(
  ctx: QueryFunctionContexts['feedFlowGroupMaterialSets']['byId'],
) {
  const [{ inferenceHash, feedFlowGroupId }] = ctx.queryKey;

  return await MaterialStateService.getFeedFlowGroupMaterialSets(
    inferenceHash,
    feedFlowGroupId,
  );
}

export function useFeedFlowGroupMaterialSets(args: {
  inferenceHash: string;
  feedFlowGroupId: string;
}) {
  return useQuery({
    queryKey: queryKeys.feedFlowGroupMaterialSets.byId(args),
    queryFn: fetchFeedFlowGroupMaterialSets,
  });
}

async function fetchFeedFlowGroupFeedstockGenealogy(
  ctx: QueryFunctionContexts['feedFlowGroup']['feedstockGenealogy'],
) {
  const [{ feedFlowGroupId }] = ctx.queryKey;
  return await FeedFlowGroupService.getFeedFlowGroupFeedstockGenealogy(
    feedFlowGroupId,
  );
}

export function useFeedFlowGroupFeedstockGenealogy(args: {
  feedFlowGroupId: string;
}) {
  return useQuery({
    queryKey: queryKeys.feedFlowGroup.feedstockGenealogy(args),
    queryFn: fetchFeedFlowGroupFeedstockGenealogy,
  });
}

async function fetchFeedFlowGroupClassificationPerformance(
  ctx: QueryFunctionContexts['feedFlowGroup']['classificationPerformance'],
) {
  const [{ feedFlowGroupId }] = ctx.queryKey;
  return await FeedFlowGroupService.getFeedFlowGroupClassificationPerformance(
    feedFlowGroupId,
  );
}

export function useFeedFlowGroupClassificationPerformance(args: {
  feedFlowGroupId: string;
}) {
  return useQuery({
    queryKey: queryKeys.feedFlowGroup.classificationPerformance(args),
    queryFn: fetchFeedFlowGroupClassificationPerformance,
  });
}

async function fetchFeedFlowGroupsForTimeRange(
  ctx: QueryFunctionContexts['feedFlowGroup']['forTimeRange'],
) {
  const [{ before, after, processId, systemId }] = ctx.queryKey;
  return await FeedFlowGroupService.getFeedFlowGroups(
    after?.toString(),
    before?.toString(),
    processId,
    systemId,
  );
}

export function useFeedFlowGroups(args: {
  before?: Temporal.Instant;
  after?: Temporal.Instant;
  processId?: string;
  systemId?: string;
}) {
  return useQuery({
    queryKey: queryKeys.feedFlowGroup.forTimeRange(args),
    queryFn: fetchFeedFlowGroupsForTimeRange,
  });
}
