import { ChutecSortSystemDTO, ChutecStream } from '../../../rest-client';
import { SystemPort } from '../SystemPort';

export function chutecStreamFromPort(
  system: ChutecSortSystemDTO,
  port: SystemPort,
): ChutecStream {
  if (port.type === 'input') {
    return ChutecStream.INPUT;
  }
  if (port.portId === system.ejectPortId) {
    return ChutecStream.EJECT;
  }
  if (port.portId === system.rejectPortId) {
    return ChutecStream.REJECT;
  }
  throw new Error('Output port id does not exist on sort system');
}
