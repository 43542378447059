import { Text } from '@mantine/core';
import { useFacility } from '../api/facilities';
import { FacilityId } from '../rest-client';
import { FacilityName } from './FacilityName';

export function FacilityIdName({ facilityId }: { facilityId: FacilityId }) {
  const { data: facility, isLoading } = useFacility(facilityId);
  if (facility) {
    return <FacilityName facility={facility} />;
  }
  if (isLoading) {
    return <Text color='dimmed'>Loading...</Text>;
  }
  return <Text color='red'>Error getting facility name</Text>;
}
