import { Loader, Table, useMantineTheme } from '@mantine/core';
import { FacilityIdName } from '../Facility/FacilityIdName';
import { TableEmptyBasicContent } from '../TableEmptyBasicContent.tsx';
import { useAllInternalMaterialSinks } from '../api/internalMaterialSink';
import { InternalMaterialSinkIdName } from './InternalMaterialSinkIdName';

export function InternalMaterialSinkTable() {
  const theme = useMantineTheme();

  const sinksQuery = useAllInternalMaterialSinks();
  if (sinksQuery.isLoadingError) {
    throw sinksQuery.error;
  }

  const sinks = sinksQuery.data;
  const isLoading = sinksQuery.isLoading;
  const isEmpty = sinks && sinks.length === 0;

  const rows = sinks?.map((sink) => (
    <tr key={sink.id}>
      <td>
        <InternalMaterialSinkIdName internalMaterialSinkId={sink.id} />
      </td>
      <td>
        <FacilityIdName facilityId={sink.facilityId} />
      </td>
    </tr>
  ));

  return (
    <Table>
      {(isLoading || isEmpty) && (
        <caption
          style={{
            captionSide: 'bottom',
            textAlign: 'center',
            padding: theme.spacing.md,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <TableEmptyBasicContent>
              No Material Export Destinations
            </TableEmptyBasicContent>
          )}
        </caption>
      )}
      <thead>
        <tr>
          <th>Name</th>
          <th>Facility</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}
