import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateProcessBufferRestoration } from '../api/processBufferRestoration';
import { ProcessBufferRestorationCreationDTO } from '../rest-client';
import {
  ProcessBufferRestorationFormFields,
  ProcessBufferRestorationFormProps,
  ProcessBufferRestorationFormValues,
  useProcessBufferRestorationForm,
} from './ProcessBufferRestorationForm';

export type AddProcessBufferRestorationDrawerFormProps =
  ProcessBufferRestorationFormProps & {
    onSuccess?: (
      processBufferRestoration: ProcessBufferRestorationCreationDTO,
    ) => void;
  } & DrawerFormDrawerProps;

export function AddProcessBufferRestorationDrawerForm(
  props: AddProcessBufferRestorationDrawerFormProps,
) {
  const { processId, onSuccess, onClose, ...drawerFormDrawerProps } = props;

  const createMutation = useCreateProcessBufferRestoration();
  const form = useProcessBufferRestorationForm({
    processId,
  });
  const processBufferRestorationId = useMemo(() => uuidv4(), []);

  const formValuesToDto = ({
    restoredAt,
    processId,
  }: ProcessBufferRestorationFormValues) => {
    if (processId === null) {
      throw new Error('Process cannot be null');
    }

    const processBufferRestoration: ProcessBufferRestorationCreationDTO = {
      id: processBufferRestorationId,
      effectiveTimestamp: restoredAt.utc().toISOString(),
      processId,
    };

    return processBufferRestoration;
  };

  return (
    <DrawerForm
      entityName='Feedstock Restoration'
      form={form}
      drawerFormVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <ProcessBufferRestorationFormFields form={form} />
    </DrawerForm>
  );
}
