import { Box, Flex, Group } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppPage } from '../App/AppPage.tsx';
import { FormActionButton } from '../Form/FormActionButton.tsx';
import { MutationErrorAlert } from '../Form/MutationErrorAlert.tsx';
import { useFormNavBlocker } from '../Form/useFormNavBlocker.ts';
import { SelectedContainerGenealogy } from '../GenealogyExplorer/SelectedContainerGenealogy.tsx';
import { useAddContainerSample } from '../api/containerSample.ts';
import {
  ContainerSampleCreationDTO,
  MaterialContainerId,
} from '../rest-client/index.ts';
import { Router } from '../router.ts';
import {
  ContainerSampleFormAlerts,
  ContainerSampleFormFields,
  useContainerSampleForm,
} from './ContainerSampleForm.tsx';

export interface ContainerSampleCreateProps {
  containerId?: MaterialContainerId;
  sampleTakenTimestamp?: Dayjs;
}

export function CreateContainerSamplePage(props: ContainerSampleCreateProps) {
  const id = useMemo(() => uuidv4(), []);
  const form = useFormNavBlocker(useContainerSampleForm(props));
  const addSampleMutation = useAddContainerSample();

  const [showContainerDetails, toggleShowContainerDetails] = useToggle();
  const [initialContainerId] = useState(props.containerId);
  const onCancel = () => {
    if (initialContainerId) {
      Router.push('ContainerDetail', { containerId: initialContainerId });
    } else {
      Router.push('ContainerSampleList');
    }
  };

  if (addSampleMutation.isError) {
    return (
      <MutationErrorAlert
        errorTitle='Error Creating Container Sample'
        entityName='Container Sample'
        mutation={addSampleMutation}
        formVariant='create'
      />
    );
  }

  return (
    <AppPage
      breadcrumbs={[
        { routeName: Router.ContainerSampleList(), title: 'Container Samples' },
      ]}
      title='Add'
    >
      <AppPage.Section>
        <form
          onSubmit={form.onSubmit((values) => {
            if (values.containerId === null) {
              throw new Error('container id must be defined');
            }

            const sample: ContainerSampleCreationDTO = {
              id,
              containerId: values.containerId,
              sampleTakenTimestamp: values.sampleTakenTimestamp
                .utc()
                .toISOString(),
            };

            addSampleMutation.mutate(sample, {
              onError() {
                showNotification({
                  title: 'Error Creating Container Sample',
                  message: 'An error ocurred creating the container sample',
                  color: 'red',
                  icon: <IconX />,
                });
              },
              onSuccess() {
                Router.replace('ContainerSampleDetail', {
                  containerSampleId: id,
                });
                showNotification({
                  title: 'Container Sample Created',
                  message: `The container sample was successfully created`,
                  color: 'green',
                  icon: <IconCheck />,
                });
              },
            });
          })}
        >
          <Flex gap='sm' justify='flex-start' align='flex-start'>
            <ContainerSampleFormFields
              form={form}
              disableContainerSelect={props.containerId !== undefined}
              showContainerDetails={showContainerDetails}
              onToggleContainerDetails={toggleShowContainerDetails}
            />
            {form.values.sampleTakenTimestamp !== null &&
            form.values.containerId !== null ? (
              <ContainerSampleFormAlerts
                containerId={form.values.containerId}
                timestamp={form.values.sampleTakenTimestamp}
              />
            ) : undefined}
          </Flex>

          <Group position='right' mt='xs'>
            <FormActionButton
              action='cancel'
              onClick={onCancel}
              loading={addSampleMutation.isLoading}
            />
            <FormActionButton
              type='submit'
              action='saveCreation'
              loading={addSampleMutation.isLoading}
            >
              Record Sample
            </FormActionButton>
          </Group>
        </form>
      </AppPage.Section>
      {form.values.containerId !== null &&
        form.values.sampleTakenTimestamp !== null && (
          <Box style={{ display: showContainerDetails ? 'inline' : 'none' }}>
            <SelectedContainerGenealogy
              containerId={form.values.containerId}
              timestamp={form.values.sampleTakenTimestamp}
            />
          </Box>
        )}
    </AppPage>
  );
}
