import { Chip, Group, Stack, Switch } from '@mantine/core';
import { IconArrowRampRight3 } from '@tabler/icons-react';
import { Fragment, useState } from 'react';
import { RedWaveClassMetadata } from '../RedWaveCompositionChartControls.tsx';
import { RedWaveMaterialClassNumber, RedWaveStream } from '../rest-client';

export function RedWaveMaterialClassMultiselect(props: {
  value: Set<RedWaveMaterialClassNumber>;
  onChange: (selected: Set<RedWaveMaterialClassNumber>) => void;
  classMetadata: RedWaveClassMetadata;
  stream: RedWaveStream;
  showBackground?: boolean;
  minSelected?: number | null;
  maxSelected?: number | null;
}) {
  const {
    value,
    onChange,
    classMetadata,
    stream,
    showBackground = true,
    minSelected = 1,
    maxSelected = null,
  } = props;

  const canSelectMore = value.size < (maxSelected ?? Number.POSITIVE_INFINITY);
  const canSelectFewer = value.size > (minSelected ?? Number.NEGATIVE_INFINITY);

  const outOfStream = (classNumber: number) => {
    return (
      stream !== RedWaveStream.INPUT &&
      (stream === RedWaveStream.EJECT) !== classMetadata[classNumber].ejected
    );
  };

  const [showOffStreamClasses, setShowOffStreamClasses] =
    useState<boolean>(false);

  return (
    <Stack>
      {stream !== RedWaveStream.INPUT ? (
        <Switch
          checked={showOffStreamClasses}
          onChange={(e) => setShowOffStreamClasses(e.currentTarget.checked)}
          onLabel={<IconArrowRampRight3 size='1rem' stroke={2.5} />}
          label={`Show ${
            stream === RedWaveStream.EJECT ? 'passed' : 'ejected'
          } material classes`}
        />
      ) : (
        <></>
      )}

      <Chip.Group
        multiple
        value={Array.from(value).map((v) => `${v}`)}
        onChange={(values) => onChange(new Set(values.map((v) => Number(v))))}
      >
        <Group spacing={'xs'}>
          {Object.entries(classMetadata)
            .filter(
              ([classNumber]) => showBackground || Number(classNumber) != 0,
            )
            .map(([classNumberStr, metadata]) => {
              const classNumber = Number(classNumberStr);
              const notSelectable = outOfStream(classNumber);
              if (notSelectable && !showOffStreamClasses) {
                return <Fragment key={classNumberStr} />;
              }
              return (
                <Chip
                  key={classNumberStr}
                  value={classNumberStr}
                  size='sm'
                  variant='filled'
                  disabled={
                    notSelectable ||
                    (!canSelectMore && !value.has(classNumber)) ||
                    (!canSelectFewer && value.has(classNumber))
                  }
                >
                  {metadata.name}
                  {notSelectable ? (
                    <>
                      {' '}
                      <IconArrowRampRight3
                        height={'1em'}
                        width={'1em'}
                        style={{ top: '0.125em', position: 'relative' }}
                      />{' '}
                    </>
                  ) : (
                    <></>
                  )}
                </Chip>
              );
            })}
        </Group>
      </Chip.Group>
    </Stack>
  );
}
