import { Button, Group, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { AppPage, TitleRoute } from '../App/AppPage';
import { DeleteIcon, EditIcon } from '../Icons';
import { MaterialClassName } from '../MaterialClass/MaterialClassName';
import { useDeleteRecoveryGoal, useRecoveryGoal } from '../api/recoveryGoal';
import { LabeledValue } from '../common';
import { Router } from '../router';
import { EditRecoveryGoalDrawerForm } from './EditRecoveryGoalDrawerForm';

export function RecoveryGoalDetailPage(props: { recoveryGoalId: string }) {
  const { recoveryGoalId } = props;
  const recoveryGoalQuery = useRecoveryGoal(recoveryGoalId);

  const breadcrumbs: (TitleRoute | string)[] = [
    {
      routeName: Router.RecoveryStrategyList(),
      title: 'Recovery Strategies',
    },
    'Recovery Goals',
  ];

  return (
    <AppPage
      breadcrumbs={breadcrumbs}
      title={recoveryGoalQuery.data?.name ?? null}
    >
      <AppPage.Section>
        <RecoveryGoalDetails recoveryGoalId={recoveryGoalId} />
      </AppPage.Section>

      {/* TODO: throughput table */}
    </AppPage>
  );
}

function RecoveryGoalDetails(props: { recoveryGoalId: string }) {
  const { recoveryGoalId } = props;

  const {
    data: recoveryGoal,
    isLoading,
    isError,
    error,
  } = useRecoveryGoal(recoveryGoalId);
  const [editDrawerOperned, { open: openEditDrawer, close: closeEditDrawer }] =
    useDisclosure(false);
  const deleteMutation = useDeleteRecoveryGoal();

  if (!recoveryGoal && isError) {
    throw error;
  }

  return (
    <Stack>
      <Group>
        <Title order={2}>Recovery Goal Details</Title>
        <>
          <Button onClick={openEditDrawer} leftIcon={<EditIcon />}>
            Edit
          </Button>
          <EditRecoveryGoalDrawerForm
            opened={editDrawerOperned}
            onClose={closeEditDrawer}
            recoveryGoalId={recoveryGoalId}
          />
        </>
        <Button
          leftIcon={<DeleteIcon />}
          color='red'
          disabled={!deleteMutation.isIdle}
          onClick={() =>
            deleteMutation.mutate(recoveryGoalId, {
              onError() {
                showNotification({
                  title: 'Error Deleting Recovery Goal',
                  message: 'An error occurred deleting this recovery goal.',
                  color: 'red',
                  icon: <IconX />,
                });
              },
              onSuccess() {
                Router.replace('RecoveryStrategyList');
                showNotification({
                  title: 'Recovery Goal Deleted',
                  message: `${
                    recoveryGoal?.name ?? 'The recovery gaol'
                  } was successfully deleted.`,
                  color: 'green',
                  icon: <IconCheck />,
                });
              },
              onSettled() {
                deleteMutation.reset();
              },
            })
          }
        >
          Delete
        </Button>
      </Group>
      <Group spacing='xl'>
        <LabeledValue label='Name'>
          <Text color={isLoading ? 'dimmed' : undefined}>
            {recoveryGoal ? recoveryGoal.name : 'Loading...'}
          </Text>
        </LabeledValue>
        <LabeledValue label='Short Name'>
          <Text
            color={isLoading || !recoveryGoal.shortName ? 'dimmed' : undefined}
          >
            {recoveryGoal ? recoveryGoal.shortName || 'none' : 'Loading...'}
          </Text>
        </LabeledValue>
        <LabeledValue label='Description'>
          <Text
            color={
              isLoading || !recoveryGoal.description ? 'dimmed' : undefined
            }
          >
            {recoveryGoal ? recoveryGoal.description || 'none' : 'Loading...'}
          </Text>
        </LabeledValue>
      </Group>
      <LabeledValue label='Material Classes'>
        {recoveryGoal?.materialClasses.length === 0 || isLoading ? (
          <Text color='dimmed'>{isLoading ? 'Loading...' : 'none'}</Text>
        ) : (
          recoveryGoal.materialClasses.map((m) => (
            <MaterialClassName key={m.id} name={m.name} id={m.id} />
          ))
        )}
      </LabeledValue>
    </Stack>
  );
}
