import { useMutation, useQuery } from '@tanstack/react-query';
import {
  InternalMaterialSinkCreationDTO,
  InternalMaterialSinkDTO,
  InternalMaterialSinkId,
  InternalMaterialSinkPatchDTO,
  InternalMaterialSinkService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchInternalMaterialSink(
  ctx: QueryFunctionContexts['internalMaterialSink']['detail'],
) {
  const [{ internalMaterialSinkId }] = ctx.queryKey;

  return await InternalMaterialSinkService.getInternalMaterialSinkById(
    internalMaterialSinkId,
  );
}

export function useInternalMaterialSink(internalMaterialSinkId: string) {
  return useQuery({
    queryKey: queryKeys.internalMaterialSink.detail(internalMaterialSinkId),
    queryFn: fetchInternalMaterialSink,
  });
}

async function fetchAllInternalMaterialSinks() {
  return await InternalMaterialSinkService.allInternalMaterialSinks();
}

export function useAllInternalMaterialSinks() {
  return useQuery({
    queryKey: queryKeys.internalMaterialSink.lists(),
    queryFn: fetchAllInternalMaterialSinks,
  });
}

async function createInternalMaterialSink(
  internalMaterialSinkDto: InternalMaterialSinkCreationDTO,
) {
  await InternalMaterialSinkService.createInternalMaterialSink(
    internalMaterialSinkDto,
  );
}

export function useCreateInternalMaterialSink() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createInternalMaterialSink,
    onSettled() {
      invalidator.invalidateKey(queryKeys.internalMaterialSink.lists());
    },
  });
}

async function patchInternalMaterialSink(
  internalMaterialSinkId: InternalMaterialSinkId,
  internalMaterialSinkPatchDto: InternalMaterialSinkPatchDTO,
) {
  await InternalMaterialSinkService.patchInternalMaterialSink(
    internalMaterialSinkId,
    internalMaterialSinkPatchDto,
  );
}

export function usePatchInternalMaterialSink(
  internalMaterialSinkId: InternalMaterialSinkId,
) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: InternalMaterialSinkPatchDTO) =>
      await patchInternalMaterialSink(internalMaterialSinkId, patch),
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.internalMaterialSink.detail(internalMaterialSinkId),
        queryKeys.internalMaterialSink.lists(),
      );
    },
  });
}

async function deleteInternalMaterialSink(
  internalMaterialSinkId: InternalMaterialSinkId,
) {
  await InternalMaterialSinkService.deleteInternalMaterialSink(
    internalMaterialSinkId,
  );
}

export function useDeleteInternalMaterialSink() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (dto: InternalMaterialSinkDTO) =>
      await deleteInternalMaterialSink(dto.id),
    onSuccess(data, sink) {
      invalidator.removeKey(queryKeys.internalMaterialSink.detail(sink.id));
    },
    onError(error, sink) {
      invalidator.resetKey(queryKeys.internalMaterialSink.detail(sink.id));
    },
    onSettled(data, error, sink) {
      invalidator.invalidateKey(
        queryKeys.internalMaterialSink.list(sink.facilityId),
      );
    },
  });
}
