import { Button, ButtonProps } from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/utils';
import { match } from 'ts-pattern';
import { CancelIcon, EditIcon, RunIcon, SaveIcon } from '../Icons';

/**
 * cancel - exit form
 * addFormSubmission - submitting add form
 * save - editing something
 * run - running a process/analysis
 * record - creating a recording (same semantics as "add")
 */
export type FormActionButtonSubmitAction =
  | 'saveCreation'
  | 'saveEdits'
  | 'run'
  | 'edit';
export type FormActionButtonAction = 'cancel' | FormActionButtonSubmitAction;

export interface FormActionButtonBaseProps {
  action: FormActionButtonAction;
}

type MantineButtonProps = PolymorphicComponentProps<'button', ButtonProps>;

export type FormActionButtonProps = MantineButtonProps &
  FormActionButtonBaseProps;

export function FormActionButton(props: FormActionButtonProps) {
  const { action, ...restProps } = props;

  const actionVariantProps: MantineButtonProps = match(action)
    .with('cancel', () => ({
      variant: 'outline',
      leftIcon: <CancelIcon />,
      children: 'Cancel',
      color: 'orange',
    }))
    .with('saveCreation', () => ({
      leftIcon: <SaveIcon />,
      children: 'Create',
    }))
    .with('saveEdits', () => ({
      leftIcon: <SaveIcon />,
      children: 'Save',
    }))
    .with('run', () => ({ leftIcon: <RunIcon />, children: 'Run' }))
    .with('edit', () => ({ leftIcon: <EditIcon />, children: 'Edit' }))
    .exhaustive();

  return <Button type='button' {...actionVariantProps} {...restProps} />;
}
