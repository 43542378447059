import { useQuery } from '@tanstack/react-query';
import { MaterialSetDTO, MaterialStateService } from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys } from './queryKeys';

async function fetchInferredProperties(
  ctx: QueryFunctionContexts['inferredProperties']['forMaterialSet'],
) {
  const [{ materialStateHash, materialSetHash }] = ctx.queryKey;
  return MaterialStateService.getMaterialSetInferredProperties(
    materialStateHash,
    materialSetHash,
  );
}

export function useInferredProperties(materialSet: MaterialSetDTO) {
  const stateHash = materialSet.materialStateInferenceHash;
  const materialSetHash = materialSet.hash;
  return useQuery({
    queryKey: queryKeys.inferredProperties.forMaterialSet(
      stateHash,
      materialSetHash,
    ),
    queryFn: fetchInferredProperties,
    staleTime: Infinity, // Immutable - never expires
  });
}
