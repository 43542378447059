import { Skeleton, Text } from '@mantine/core';
import {
  InternallySourcedMaterialName,
  InternallySourcedMaterialNameProps,
} from '../Repository/RepositoryName';
import { useInternallySourcedMaterial } from '../api/internallySourcedMaterial';

export type InternallySourcedMaterialIdNameProps = Omit<
  InternallySourcedMaterialNameProps,
  'internallySourcedMaterial'
> & {
  internallySourcedMaterialId: string;
};

export function InternallySourcedMaterialIdName(
  props: InternallySourcedMaterialIdNameProps,
) {
  const { internallySourcedMaterialId, ...rest } = props;
  const { data: internallySourcedMaterial, isError } =
    useInternallySourcedMaterial(internallySourcedMaterialId);

  if (internallySourcedMaterial) {
    return (
      <InternallySourcedMaterialName
        internallySourcedMaterial={internallySourcedMaterial}
        {...rest}
      />
    );
  }

  if (isError) {
    return <Text color='red'>Error geting name</Text>;
  }

  return (
    <Skeleton visible>
      <Text>Loading name...</Text>
    </Skeleton>
  );
}
