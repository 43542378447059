/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MaterialClassSetCreationDTO } from '../models/MaterialClassSetCreationDTO';
import type { MaterialClassSetDTO } from '../models/MaterialClassSetDTO';
import type { MaterialClassSetPatchDTO } from '../models/MaterialClassSetPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MaterialClassSetService {
    /**
     * @returns MaterialClassSetDTO Success
     * @throws ApiError
     */
    public static getMaterialClassSets(): CancelablePromise<Array<MaterialClassSetDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-class-sets',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createMaterialClassSet(
        requestBody: MaterialClassSetCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/material-class-sets',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param materialClassSetGuid
     * @returns MaterialClassSetDTO Success
     * @throws ApiError
     */
    public static getMaterialClassSetById(
        materialClassSetGuid: string,
    ): CancelablePromise<MaterialClassSetDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-class-sets/{materialClassSetGuid}',
            path: {
                'materialClassSetGuid': materialClassSetGuid,
            },
        });
    }
    /**
     * @param materialClassSetGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchMaterialClassSet(
        materialClassSetGuid: string,
        requestBody: MaterialClassSetPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/material-class-sets/{materialClassSetGuid}',
            path: {
                'materialClassSetGuid': materialClassSetGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param materialClassSetGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteMaterialClassSet(
        materialClassSetGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/material-class-sets/{materialClassSetGuid}',
            path: {
                'materialClassSetGuid': materialClassSetGuid,
            },
        });
    }
}
