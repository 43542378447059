/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MaterialVendorDTO } from '../models/MaterialVendorDTO';
import type { MaterialVendorPatchDTO } from '../models/MaterialVendorPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class VendorsService {
    /**
     * @returns MaterialVendorDTO Success
     * @throws ApiError
     */
    public static allVendors(): CancelablePromise<Array<MaterialVendorDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vendors',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createVendor(
        requestBody: MaterialVendorDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/vendors',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param vendorGuid
     * @returns MaterialVendorDTO Success
     * @throws ApiError
     */
    public static getVendorById(
        vendorGuid: string,
    ): CancelablePromise<MaterialVendorDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vendors/{vendorGuid}',
            path: {
                'vendorGuid': vendorGuid,
            },
        });
    }
    /**
     * @param vendorGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchVendor(
        vendorGuid: string,
        requestBody: MaterialVendorPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/vendors/{vendorGuid}',
            path: {
                'vendorGuid': vendorGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param vendorGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteVendor(
        vendorGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/vendors/{vendorGuid}',
            path: {
                'vendorGuid': vendorGuid,
            },
        });
    }
}
