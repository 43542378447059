/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InternallySinkedMaterialDetailsDTO } from '../models/InternallySinkedMaterialDetailsDTO';
import type { InternallySinkedMaterialSetsDTO } from '../models/InternallySinkedMaterialSetsDTO';
import type { InternalMaterialSinkCreationDTO } from '../models/InternalMaterialSinkCreationDTO';
import type { InternalMaterialSinkDTO } from '../models/InternalMaterialSinkDTO';
import type { InternalMaterialSinkPatchDTO } from '../models/InternalMaterialSinkPatchDTO';
import type { InternalSinkContainerTransferCreationDTO } from '../models/InternalSinkContainerTransferCreationDTO';
import type { InternalSinkContainerTransferDTO } from '../models/InternalSinkContainerTransferDTO';
import type { InternalSinkContainerTransferPatchDTO } from '../models/InternalSinkContainerTransferPatchDTO';
import type { LedgerErrorInternallySinkedMaterialDetailsDTO } from '../models/LedgerErrorInternallySinkedMaterialDetailsDTO';
import type { LedgerErrorInternallySinkedMaterialSetsResultDTO } from '../models/LedgerErrorInternallySinkedMaterialSetsResultDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InternalMaterialSinkService {
    /**
     * @param facilityGuid
     * @returns InternalMaterialSinkDTO Success
     * @throws ApiError
     */
    public static allInternalMaterialSinks(
        facilityGuid?: string,
    ): CancelablePromise<Array<InternalMaterialSinkDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sinks',
            query: {
                'facilityGuid': facilityGuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createInternalMaterialSink(
        requestBody: InternalMaterialSinkCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal-material-sinks',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param internalMaterialSinkGuid
     * @returns InternalMaterialSinkDTO Success
     * @throws ApiError
     */
    public static getInternalMaterialSinkById(
        internalMaterialSinkGuid: string,
    ): CancelablePromise<InternalMaterialSinkDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sinks/{internalMaterialSinkGuid}',
            path: {
                'internalMaterialSinkGuid': internalMaterialSinkGuid,
            },
        });
    }
    /**
     * @param internalMaterialSinkGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchInternalMaterialSink(
        internalMaterialSinkGuid: string,
        requestBody: InternalMaterialSinkPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal-material-sinks/{internalMaterialSinkGuid}',
            path: {
                'internalMaterialSinkGuid': internalMaterialSinkGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param internalMaterialSinkGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternalMaterialSink(
        internalMaterialSinkGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal-material-sinks/{internalMaterialSinkGuid}',
            path: {
                'internalMaterialSinkGuid': internalMaterialSinkGuid,
            },
        });
    }
    /**
     * @param sinkContainerTransferGuid
     * @returns InternalSinkContainerTransferDTO Success
     * @throws ApiError
     */
    public static getInternalMaterialSinkContainerTransferById(
        sinkContainerTransferGuid: string,
    ): CancelablePromise<InternalSinkContainerTransferDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sinks/container-transfers/{sinkContainerTransferGuid}',
            path: {
                'sinkContainerTransferGuid': sinkContainerTransferGuid,
            },
        });
    }
    /**
     * @param sinkContainerTransferGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternalMaterialSinkContainerTransfer(
        sinkContainerTransferGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal-material-sinks/container-transfers/{sinkContainerTransferGuid}',
            path: {
                'sinkContainerTransferGuid': sinkContainerTransferGuid,
            },
        });
    }
    /**
     * @param sinkContainerTransferGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchInternalMaterialSinkContainerTransfer(
        sinkContainerTransferGuid: string,
        requestBody: InternalSinkContainerTransferPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal-material-sinks/container-transfers/{sinkContainerTransferGuid}',
            path: {
                'sinkContainerTransferGuid': sinkContainerTransferGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createInternalMaterialSinkContainerTransfer(
        requestBody: InternalSinkContainerTransferCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal-material-sinks/container-transfers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param facilityGuid
     * @param internalMaterialSinkGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getInternallySinkedMaterialSets(
        facilityGuid?: string,
        internalMaterialSinkGuid?: string,
    ): CancelablePromise<(InternallySinkedMaterialSetsDTO | LedgerErrorInternallySinkedMaterialSetsResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sinks/sinked-material-sets',
            query: {
                'facilityGuid': facilityGuid,
                'internalMaterialSinkGuid': internalMaterialSinkGuid,
            },
        });
    }
    /**
     * @param sinkContainerTransferGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getInternallySinkedMaterialDetails(
        sinkContainerTransferGuid: string,
    ): CancelablePromise<(LedgerErrorInternallySinkedMaterialDetailsDTO | InternallySinkedMaterialDetailsDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sinks/container-transfers-details/{sinkContainerTransferGuid}',
            path: {
                'sinkContainerTransferGuid': sinkContainerTransferGuid,
            },
        });
    }
}
