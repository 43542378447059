import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../../Form/DrawerForm';
import { useCreateDefaultCommodityAssignment } from '../../api/defaultCommodityAssignment';
import { DefaultCommodityAssignmentCreationDTO } from '../../rest-client';
import {
  DefaultCommodityAssignmentFormFields,
  DefaultCommodityAssignmentFormValues,
  useDefaultCommodityAssignmentForm,
} from './DefaultCommodityAssignmentForm';

export type AddDefaultCommodityAssignmentDrawerFormProps = {
  onSuccess?: (
    defaultCommodityAssignment: DefaultCommodityAssignmentCreationDTO,
  ) => void;
} & DrawerFormDrawerProps;

export function AddDefaultCommodityAssignmentDrawerForm(
  props: AddDefaultCommodityAssignmentDrawerFormProps,
) {
  const { onSuccess = () => null, onClose, ...drawerFormDrawerProps } = props;

  const createMutation = useCreateDefaultCommodityAssignment();
  const form = useDefaultCommodityAssignmentForm();

  const defaultCommodityAssignementId = useMemo(() => uuidv4(), []);

  const formValuesToDto = ({
    inputCommodityId,
    steps,
    outputCommodityId,
    intermediate,
  }: DefaultCommodityAssignmentFormValues) => {
    if (!inputCommodityId) {
      throw new Error('Input commodity cannot be null');
    }

    const defaultCommodityAssignment: DefaultCommodityAssignmentCreationDTO = {
      id: uuidv4(),
      inputCommodityId: inputCommodityId,
      path: {
        id: defaultCommodityAssignementId,
        steps: steps.map((s) => {
          if (s.recoveryGoalId === null) {
            throw new Error('Recovery goal cannot be null');
          }
          return {
            id: uuidv4(),
            recoveryGoalId: s.recoveryGoalId,
            negative: s.negative,
          };
        }),
      },
      outputCommodityId: intermediate ? null : outputCommodityId,
      intermediate: intermediate,
    };

    return defaultCommodityAssignment;
  };

  return (
    <DrawerForm
      entityName='Default Output Commodity Assignment'
      form={form}
      drawerFormVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <DefaultCommodityAssignmentFormFields form={form} />
    </DrawerForm>
  );
}
