import { Box, Text, TextProps } from '@mantine/core';
import { ReactNode } from 'react';
import cssClasses from './WithUnit.module.css';

export type WithUnitProps = TextProps & {
  children: ReactNode;
  unit: string;
};

export function WithUnit(props: WithUnitProps) {
  const { children, unit, size = 'xs', c = 'dimmed', ...textProps } = props;
  return (
    <Box className={cssClasses.container}>
      {children}
      <Text size={size} c={c} {...textProps}>
        {unit}
      </Text>
    </Box>
  );
}
