import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ProcessStopReasonCreationDTO,
  ProcessStopReasonPatchDTO,
  ProcessStopReasonService,
} from '../rest-client';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchProcessStopReasons() {
  return await ProcessStopReasonService.getProcessStopReasons();
}

export function useProcessStopReasons() {
  return useQuery({
    queryKey: queryKeys.processStopReason.list(),
    queryFn: fetchProcessStopReasons,
  });
}

async function createProcessStopReason(dto: ProcessStopReasonCreationDTO) {
  await ProcessStopReasonService.createProcessStopReason(dto);
}

export function useAddProcessStopReason() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createProcessStopReason,
    onSettled() {
      invalidator.invalidateKeys(queryKeys.processStopReason.list());
    },
  });
}

async function patchProcessStopReason(args: {
  id: string;
  patch: ProcessStopReasonPatchDTO;
}) {
  await ProcessStopReasonService.patchProcessStopReason(args.id, args.patch);
}

export function usePatchProcessStopReason() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchProcessStopReason,
    onSettled() {
      invalidator.invalidateKeys(queryKeys.processStopReason.list());
    },
  });
}

async function deleteProcessStopReason(id: string) {
  await ProcessStopReasonService.deleteProcessStopReason(id);
}

export function useDeleteProcessStopReason() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteProcessStopReason,
    onSettled() {
      invalidator.invalidateKeys(queryKeys.processStopReason.list());
    },
  });
}
