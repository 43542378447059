/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MaterialClassUnionCreationDTO } from '../models/MaterialClassUnionCreationDTO';
import type { MaterialClassUnionDTO } from '../models/MaterialClassUnionDTO';
import type { MaterialClassUnionPatchDTO } from '../models/MaterialClassUnionPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MaterialClassUnionService {
    /**
     * @param materialClassSetId
     * @returns MaterialClassUnionDTO Success
     * @throws ApiError
     */
    public static getMaterialClassUnions(
        materialClassSetId?: string,
    ): CancelablePromise<Array<MaterialClassUnionDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-class-unions',
            query: {
                'materialClassSetId': materialClassSetId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createMaterialClassUnion(
        requestBody: MaterialClassUnionCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/material-class-unions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param unionGuid
     * @returns MaterialClassUnionDTO Success
     * @throws ApiError
     */
    public static getMaterialClassUnion(
        unionGuid: string,
    ): CancelablePromise<MaterialClassUnionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-class-unions/{unionGuid}',
            path: {
                'unionGuid': unionGuid,
            },
        });
    }
    /**
     * @param unionGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchMaterialClassUnion(
        unionGuid: string,
        requestBody: MaterialClassUnionPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/material-class-unions/{unionGuid}',
            path: {
                'unionGuid': unionGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param unionGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteMaterialClassUnion(
        unionGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/material-class-unions/{unionGuid}',
            path: {
                'unionGuid': unionGuid,
            },
        });
    }
}
