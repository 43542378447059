import { Button, Group, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconCheck, IconCsv, IconUpload, IconX } from '@tabler/icons-react';
import { useState } from 'react';

export function SchupanRimasSnapshotUploadForm(props: {
  onUpload: (csvs: { controlCsv: string; tagCsv: string }) => void;
}) {
  const { onUpload } = props;
  const [controlCsv, setControlCsv] = useState<string | null>(null);
  const [tagCsv, setTagCsv] = useState<string | null>(null);

  return (
    <Group>
      {controlCsv ? (
        <Text>
          Control
          <Text span color='teal'>
            <IconCheck style={{ marginBottom: '-.4em' }} />
          </Text>
        </Text>
      ) : (
        <CsvDropzone name='Control' onUpload={setControlCsv} />
      )}
      {tagCsv ? (
        <Text>
          Tags
          <Text span color='teal'>
            <IconCheck style={{ marginBottom: '-.4em' }} />
          </Text>
        </Text>
      ) : (
        <CsvDropzone name='Tags' onUpload={setTagCsv} />
      )}
      {controlCsv && tagCsv ? (
        <Button
          size='lg'
          leftIcon={<IconUpload />}
          onClick={() => {
            onUpload({ controlCsv, tagCsv });
          }}
        >
          Upload
        </Button>
      ) : null}
    </Group>
  );
}

function CsvDropzone(props: { name: string; onUpload: (csv: string) => void }) {
  const { name, onUpload } = props;
  return (
    <Dropzone
      onDrop={(files) => {
        if (files.length > 1) {
          console.error('ignoring everything after first file');
        }
        if (files.length === 0) {
          throw new Error('expected at least one file');
        }

        const [file] = files;
        file
          .text()
          .then(onUpload)
          .catch((e) => {
            // TODO: Show alert that upload failed
            console.error(e);
          });
      }}
      onReject={(files) => {
        // TODO: Show alert that files were rejected
        console.log('reject', files);
      }}
      accept={['text/csv', 'application/vnd.ms-excel']}
    >
      <Group
        position='center'
        spacing='xl'
        mih={220}
        style={{ pointerEvents: 'none' }}
      >
        <Dropzone.Accept>
          <IconUpload size='3.2rem' stroke={1.5} />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX size='3.2rem' stroke={1.5} color='red' />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconCsv size='3.2rem' stroke={1.5} />
        </Dropzone.Idle>

        <div>
          <Text size='xl' inline>
            Drag {name} CSV here or click to select {name} CSV
          </Text>
        </div>
      </Group>
    </Dropzone>
  );
}
