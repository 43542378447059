import { useMutation, useQuery } from '@tanstack/react-query';
import {
  RecoveryStrategySimulationCreationDTO,
  RecoveryStrategySimulationPatchDTO,
  RecoveryStrategySimulationService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchRecoveryStrategySimulation(
  ctx: QueryFunctionContexts['recoveryStrategySimulation']['detail'],
) {
  const [{ simulationId }] = ctx.queryKey;
  return await RecoveryStrategySimulationService.getSimulation(simulationId);
}

export function useRecoveryStrategySimulation(simulationId: string) {
  return useQuery({
    queryFn: fetchRecoveryStrategySimulation,
    queryKey: queryKeys.recoveryStrategySimulation.detail(simulationId),
  });
}

async function fetchRecoveryStrategySimulations(
  ctx: QueryFunctionContexts['recoveryStrategySimulation']['list'],
) {
  const [{ strategyId }] = ctx.queryKey;
  return await RecoveryStrategySimulationService.getSimulations(strategyId);
}

export function useRecoveryStrategySimulations(args: { strategyId?: string }) {
  return useQuery({
    queryKey: queryKeys.recoveryStrategySimulation.list(args),
    queryFn: fetchRecoveryStrategySimulations,
  });
}

async function createRecoveryStrategySimulation(
  dto: RecoveryStrategySimulationCreationDTO,
) {
  await RecoveryStrategySimulationService.createSimulation(dto);
}

export function useCreateRecoveryStrategySimulation() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createRecoveryStrategySimulation,
    onSettled() {
      invalidator.invalidateKey(queryKeys.recoveryStrategySimulation.list({}));
    },
  });
}

async function patchRecoveryStrategySimulation(args: {
  simulationId: string;
  patch: RecoveryStrategySimulationPatchDTO;
}) {
  await RecoveryStrategySimulationService.patchSimulation(
    args.simulationId,
    args.patch,
  );
}

export function usePatchRecoveryStrategySimulation() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchRecoveryStrategySimulation,
    onSettled(_data, _error, { simulationId }) {
      invalidator.invalidateKeys(
        queryKeys.recoveryStrategySimulation.list({}),
        queryKeys.recoveryStrategySimulation.detail(simulationId),
      );
    },
  });
}

async function deleteRecoveryStategySimulation(id: string) {
  await RecoveryStrategySimulationService.deleteSimulation(id);
}

export function useDeleteRecoveryStategySimulation() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteRecoveryStategySimulation,
    onSettled(data, error, id) {
      invalidator.invalidateKeys(
        queryKeys.recoveryStrategySimulation.list({}),
        queryKeys.recoveryStrategySimulation.detail(id),
      );
    },
  });
}
