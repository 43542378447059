import { Button, Group, Skeleton, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { P, match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { FacilityIdName } from '../Facility/FacilityIdName';
import { DeleteIcon, EditIcon } from '../Icons';
import AddInternalSinkContainerTransferButton from '../InternalSinkContainerTransfer/AddInternalSinkContainerTransferButton';
import { InternallySinkedMaterialTable } from '../InternalSinkContainerTransferredMaterials/InternallySinkedMaterialsTable';
import {
  useDeleteInternalMaterialSink,
  useInternalMaterialSink,
} from '../api/internalMaterialSink';
import { LabeledValue } from '../common';
import { InternalMaterialSinkId } from '../rest-client';
import { Router } from '../router';
import { EditInternalMaterialSinkDrawerForm } from './EditInternalMaterialSinkDrawerForm';

export interface InternalMaterialSinkDetailProps {
  internalMaterialSinkId: InternalMaterialSinkId;
}

export function InternalMaterialSinkDetailPage(
  props: InternalMaterialSinkDetailProps,
) {
  const { internalMaterialSinkId } = props;

  const {
    data: internalMaterialSink,
    isLoading,
    isError,
    error,
  } = useInternalMaterialSink(internalMaterialSinkId);
  const [editDrawerOperned, { open: openEditDrawer, close: closeEditDrawer }] =
    useDisclosure(false);
  const deleteMutation = useDeleteInternalMaterialSink();

  const breadcrumbs = [
    { routeName: Router.InternalSinkList(), title: 'Material Exports' },
  ];

  if (!internalMaterialSink && isError) {
    throw error;
  }

  return (
    <AppPage
      breadcrumbs={breadcrumbs}
      title={internalMaterialSink?.name ?? null}
    >
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Material Export Destination Details</Title>
            <Group>
              <>
                <Button onClick={openEditDrawer} leftIcon={<EditIcon />}>
                  Edit
                </Button>
                <EditInternalMaterialSinkDrawerForm
                  opened={editDrawerOperned}
                  onClose={closeEditDrawer}
                  internalMaterialSinkId={internalMaterialSinkId}
                />
              </>
              <Button
                leftIcon={<DeleteIcon />}
                color='red'
                disabled={internalMaterialSink === undefined}
                onClick={() => {
                  if (internalMaterialSink === undefined) {
                    throw new Error('InternalMaterialSink must be defined');
                  }
                  deleteMutation.mutate(internalMaterialSink, {
                    onError(data, variables) {
                      showNotification({
                        title: 'Error Deleting Material Export Destination',
                        message: `An error occurred deleting ${variables.name}. This could be due to ${variables.name} having associated exported material or it could be an internal error`,
                        color: 'red',
                        icon: <IconX />,
                      });
                    },
                    onSuccess(data, variables) {
                      Router.push('InternalSinkList');
                      showNotification({
                        title: 'Material Export Destination Deleted',
                        message: `${variables.name} was successfuly deleted.`,
                        color: 'green',
                        icon: <IconCheck />,
                      });
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Group>
          </Group>
          <LabeledValue label='Facility'>
            <Skeleton visible={isLoading}>
              {match(internalMaterialSink?.facilityId)
                .with(undefined, () => <Text>Loading...</Text>)
                .with(P.string, (facilityId) => (
                  <FacilityIdName facilityId={facilityId} />
                ))
                .exhaustive()}
            </Skeleton>
          </LabeledValue>
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Exported Materials</Title>
            <AddInternalSinkContainerTransferButton
              addInternalSinkTransferDrawerFormProps={{
                internalMaterialSinkId: internalMaterialSinkId,
              }}
            />
          </Group>
          <InternallySinkedMaterialTable
            internalMaterialSinkId={internalMaterialSinkId}
          />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
