import { Loader, Select, SelectProps } from '@mantine/core';
import { useState } from 'react';
import { useMaterialClassSets } from '../api/materialClassSet';

type MaterialClassSelectProps = Omit<
  SelectProps,
  'data' | 'value' | 'onChange'
> & {
  value: string | null;
  onChange: (id: string | null) => void;
};

// TODO(2323): using this component without a non-null value passed, results in bad setState() call warnings
export function MaterialClassSetSelect(props: MaterialClassSelectProps) {
  const {
    placeholder = 'Select material class set',
    rightSection,
    ...rest
  } = props;
  const materialClassSets = useMaterialClassSets();

  const [initialized, setIsInitialized] = useState(false);

  if (!initialized && props.value === null && materialClassSets.data) {
    if (materialClassSets.data.length === 1) {
      props.onChange(materialClassSets.data[0].id);
    }
    setIsInitialized(true);
  }

  return (
    <Select
      placeholder={materialClassSets.isLoading ? 'Loading...' : placeholder}
      rightSection={
        materialClassSets.isLoading ? <Loader size='xs' /> : rightSection
      }
      data={
        materialClassSets.data?.map((mcs) => ({
          value: mcs.id,
          label: mcs.name,
        })) ?? []
      }
      {...rest}
    />
  );
}
