import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { InventoryService } from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys } from './queryKeys';

async function fetchInventoryLedgerEventHistorySegment(
  ctx: QueryFunctionContexts['inventory']['ledger']['events']['history'],
) {
  const [{ startTime, endTime }] = ctx.queryKey;
  return await InventoryService.getInventoryLedgerEvents(
    startTime?.utc().toISOString(),
    endTime?.utc().toISOString(),
  );
}

export function useInventoryLedgerEventHistorySegment({
  startTime,
  endTime,
}: {
  startTime: Dayjs | null;
  endTime: Dayjs | null;
}) {
  return useQuery({
    queryKey: queryKeys.inventory.ledger.events.history(startTime, endTime),
    queryFn: fetchInventoryLedgerEventHistorySegment,
  });
}

async function fetchInventoryLedgerStatus() {
  return await InventoryService.getInventoryLedgerStatus();
}

export function useInventoryLedgerStatus() {
  return useQuery({
    queryKey: queryKeys.inventory.ledger.status,
    queryFn: fetchInventoryLedgerStatus,
  });
}

async function fetchInventoryLedgerCalendar() {
  return await InventoryService.getInventoryLedgerEventCalendar();
}

export function useInventoryLedgerCalendar() {
  return useQuery({
    queryKey: queryKeys.inventory.ledger.events.calendar(),
    queryFn: fetchInventoryLedgerCalendar,
  });
}
