import { Group, Stack, Title } from '@mantine/core';
import { AppPage } from '../App/AppPage.tsx';
import AddContainerTransferButton from '../ContainerTransfer/AddContainerTransferButton.tsx';
import AddInternalSinkContainerTransferButton from '../InternalSinkContainerTransfer/AddInternalSinkContainerTransferButton.tsx';
import AddOutputContainerChangeButton from '../OutputContainerChange/AddOutputContainerChangeButton.tsx';
import AddProcessBufferDepletionButton from '../ProcessBufferDepletion/AddProcessBufferDepletionButton.tsx';
import AddProcessBufferRestorationButton from '../ProcessBufferRestoration/AddProcessBufferRestorationButton.tsx';
import AddProcessBufferTransferButton from '../ProcessBufferTransfer/AddProcessBufferTransferButton.tsx';
import { InventoryLedger } from './InventoryLedger.tsx';

export function InventoryLedgerPage() {
  return (
    <AppPage title='Inventory Ledger'>
      <AppPage.Section>
        <Stack>
          <Group position='apart'>
            <Title order={2}>History</Title>
            <Group>
              <AddContainerTransferButton
                addContainerTransferDrawerFormProps={{}}
              />
              <AddProcessBufferTransferButton
                addProcessBufferTransferDrawerFormProps={{}}
              />
              <AddProcessBufferDepletionButton
                addProcessBufferDepletionDrawerFormProps={{}}
              />
              <AddProcessBufferRestorationButton
                addProcessBufferRestorationDrawerFormProps={{}}
              />
              <AddOutputContainerChangeButton
                addOutputContainerChangeDrawerFormProps={{}}
              />
              <AddInternalSinkContainerTransferButton
                addInternalSinkTransferDrawerFormProps={{}}
              />
            </Group>
          </Group>
          <InventoryLedger />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
