/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommodityInternalMaterialSourceAggregateCompositionsDTO } from '../models/CommodityInternalMaterialSourceAggregateCompositionsDTO';
import type { InternallySourcedMaterialCreationDTO } from '../models/InternallySourcedMaterialCreationDTO';
import type { InternallySourcedMaterialDTO } from '../models/InternallySourcedMaterialDTO';
import type { InternallySourcedMaterialPartitionCreationDTO } from '../models/InternallySourcedMaterialPartitionCreationDTO';
import type { InternallySourcedMaterialPatchDTO } from '../models/InternallySourcedMaterialPatchDTO';
import type { InternallySourcedMaterialSetsDTO } from '../models/InternallySourcedMaterialSetsDTO';
import type { InternalMaterialSourceBulkCompositionAnalysisResultDTO } from '../models/InternalMaterialSourceBulkCompositionAnalysisResultDTO';
import type { InternalMaterialSourceCreationDTO } from '../models/InternalMaterialSourceCreationDTO';
import type { InternalMaterialSourceDTO } from '../models/InternalMaterialSourceDTO';
import type { InternalMaterialSourcePatchDTO } from '../models/InternalMaterialSourcePatchDTO';
import type { InternalMaterialSourceSourcedCountAndMassDTO } from '../models/InternalMaterialSourceSourcedCountAndMassDTO';
import type { LedgerErrorGenealogyResultDTO } from '../models/LedgerErrorGenealogyResultDTO';
import type { LedgerErrorInternallySourcedMaterialSetsResultsDTO } from '../models/LedgerErrorInternallySourcedMaterialSetsResultsDTO';
import type { LedgerErrorInternalMaterialSourceSourceCountAndMassDTO } from '../models/LedgerErrorInternalMaterialSourceSourceCountAndMassDTO';
import type { LedgerErrorMaterialSetResultDTO } from '../models/LedgerErrorMaterialSetResultDTO';
import type { MaterialSetSuccessDTO } from '../models/MaterialSetSuccessDTO';
import type { OccupiedGenealogyResultDTO } from '../models/OccupiedGenealogyResultDTO';
import type { PartitionedInternallySourcedMaterialPartitionStatusDTO } from '../models/PartitionedInternallySourcedMaterialPartitionStatusDTO';
import type { UnpartitionedInternallySourcedMaterialPartitionStatusDTO } from '../models/UnpartitionedInternallySourcedMaterialPartitionStatusDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InternalMaterialSourceService {
    /**
     * @returns InternalMaterialSourceDTO Success
     * @throws ApiError
     */
    public static allInternalMaterialSources(): CancelablePromise<Array<InternalMaterialSourceDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createInternalMaterialSource(
        requestBody: InternalMaterialSourceCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal-material-sources',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param internalMaterialSourceGuid
     * @returns InternalMaterialSourceDTO Success
     * @throws ApiError
     */
    public static getInternalMaterialSourceById(
        internalMaterialSourceGuid: string,
    ): CancelablePromise<InternalMaterialSourceDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources/{internalMaterialSourceGuid}',
            path: {
                'internalMaterialSourceGuid': internalMaterialSourceGuid,
            },
        });
    }
    /**
     * @param internalMaterialSourceGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchInternalMaterialSource(
        internalMaterialSourceGuid: string,
        requestBody: InternalMaterialSourcePatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal-material-sources/{internalMaterialSourceGuid}',
            path: {
                'internalMaterialSourceGuid': internalMaterialSourceGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param internalMaterialSourceGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternalMaterialSource(
        internalMaterialSourceGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal-material-sources/{internalMaterialSourceGuid}',
            path: {
                'internalMaterialSourceGuid': internalMaterialSourceGuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createInternallySourcedMaterial(
        requestBody: InternallySourcedMaterialCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal-material-sources/internally-sourced-materials',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param internalMaterialSourceGuid
     * @returns InternallySourcedMaterialDTO Success
     * @throws ApiError
     */
    public static allInternallySourcedMaterials(
        internalMaterialSourceGuid?: string,
    ): CancelablePromise<Array<InternallySourcedMaterialDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources/internally-sourced-materials',
            query: {
                'internalMaterialSourceGuid': internalMaterialSourceGuid,
            },
        });
    }
    /**
     * @param internallySourcedMaterialGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternallySourcedMaterial(
        internallySourcedMaterialGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal-material-sources/internally-sourced-materials/{internallySourcedMaterialGuid}',
            path: {
                'internallySourcedMaterialGuid': internallySourcedMaterialGuid,
            },
        });
    }
    /**
     * @param internallySourcedMaterialGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchInternallySourcedMaterial(
        internallySourcedMaterialGuid: string,
        requestBody: InternallySourcedMaterialPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/internal-material-sources/internally-sourced-materials/{internallySourcedMaterialGuid}',
            path: {
                'internallySourcedMaterialGuid': internallySourcedMaterialGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param internallySourcedMaterialGuid
     * @returns InternallySourcedMaterialDTO Success
     * @throws ApiError
     */
    public static getInternallySourcedMaterialById(
        internallySourcedMaterialGuid: string,
    ): CancelablePromise<InternallySourcedMaterialDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources/internally-sourced-materials/{internallySourcedMaterialGuid}',
            path: {
                'internallySourcedMaterialGuid': internallySourcedMaterialGuid,
            },
        });
    }
    /**
     * @param internalMaterialSourceGuid
     * @returns any Success
     * @throws ApiError
     */
    public static allInternallySourcedMaterialSets(
        internalMaterialSourceGuid?: string,
    ): CancelablePromise<(InternallySourcedMaterialSetsDTO | LedgerErrorInternallySourcedMaterialSetsResultsDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources/internally-sourced-materials/material-sets',
            query: {
                'internalMaterialSourceGuid': internalMaterialSourceGuid,
            },
        });
    }
    /**
     * @param internallySourcedMaterialGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getInternallySourcedMaterialSetById(
        internallySourcedMaterialGuid: string,
    ): CancelablePromise<(LedgerErrorMaterialSetResultDTO | MaterialSetSuccessDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources/internally-sourced-materials/{internallySourcedMaterialGuid}/material-set',
            path: {
                'internallySourcedMaterialGuid': internallySourcedMaterialGuid,
            },
        });
    }
    /**
     * @param internallySourcedMaterialGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getInternallySourcedMaterialGenealogyById(
        internallySourcedMaterialGuid: string,
    ): CancelablePromise<(OccupiedGenealogyResultDTO | LedgerErrorGenealogyResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources/internally-sourced-materials/{internallySourcedMaterialGuid}/genealogy',
            path: {
                'internallySourcedMaterialGuid': internallySourcedMaterialGuid,
            },
        });
    }
    /**
     * @param internallySourcedMaterialGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getInternallySourcedMaterialPartitionStatus(
        internallySourcedMaterialGuid: string,
    ): CancelablePromise<(PartitionedInternallySourcedMaterialPartitionStatusDTO | UnpartitionedInternallySourcedMaterialPartitionStatusDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources/internally-sourced-materials/{internallySourcedMaterialGuid}/partition',
            path: {
                'internallySourcedMaterialGuid': internallySourcedMaterialGuid,
            },
        });
    }
    /**
     * @param internallySourcedMaterialGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createInternallySourcedMaterialPartition(
        internallySourcedMaterialGuid: string,
        requestBody: InternallySourcedMaterialPartitionCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal-material-sources/internally-sourced-materials/{internallySourcedMaterialGuid}/partition',
            path: {
                'internallySourcedMaterialGuid': internallySourcedMaterialGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param internallySourcedMaterialGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternallySourcedMaterialPartition(
        internallySourcedMaterialGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal-material-sources/internally-sourced-materials/{internallySourcedMaterialGuid}/partition',
            path: {
                'internallySourcedMaterialGuid': internallySourcedMaterialGuid,
            },
        });
    }
    /**
     * @param internalMaterialSourceGuid
     * @param after
     * @param before
     * @returns InternalMaterialSourceBulkCompositionAnalysisResultDTO Success
     * @throws ApiError
     */
    public static getInternalMaterialSourceComposition(
        internalMaterialSourceGuid: string,
        after?: string,
        before?: string,
    ): CancelablePromise<InternalMaterialSourceBulkCompositionAnalysisResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources/{internalMaterialSourceGuid}/composition',
            path: {
                'internalMaterialSourceGuid': internalMaterialSourceGuid,
            },
            query: {
                'after': after,
                'before': before,
            },
        });
    }
    /**
     * @param commodityGuid
     * @param after
     * @param before
     * @returns CommodityInternalMaterialSourceAggregateCompositionsDTO Success
     * @throws ApiError
     */
    public static getInternalMaterialSourceCompositionsForCommodity(
        commodityGuid: string,
        after?: string,
        before?: string,
    ): CancelablePromise<CommodityInternalMaterialSourceAggregateCompositionsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources/compositions-by-commodity/{commodityGuid}',
            path: {
                'commodityGuid': commodityGuid,
            },
            query: {
                'after': after,
                'before': before,
            },
        });
    }
    /**
     * @param after
     * @param before
     * @returns CommodityInternalMaterialSourceAggregateCompositionsDTO Success
     * @throws ApiError
     */
    public static getInternalMaterialSourceCompositionsByCommodity(
        after?: string,
        before?: string,
    ): CancelablePromise<Array<CommodityInternalMaterialSourceAggregateCompositionsDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources/compositions-by-commodity',
            query: {
                'after': after,
                'before': before,
            },
        });
    }
    /**
     * @param internalMaterialSourceGuid
     * @param maybeInstant
     * @returns any Success
     * @throws ApiError
     */
    public static getInternalMaterialSourceDetailed(
        internalMaterialSourceGuid: string,
        maybeInstant?: string,
    ): CancelablePromise<(InternalMaterialSourceSourcedCountAndMassDTO | LedgerErrorInternalMaterialSourceSourceCountAndMassDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources/{internalMaterialSourceGuid}/sourced-mass',
            path: {
                'internalMaterialSourceGuid': internalMaterialSourceGuid,
            },
            query: {
                'maybeInstant': maybeInstant,
            },
        });
    }
    /**
     * @param maybeInstant
     * @returns any Success
     * @throws ApiError
     */
    public static getInternalMaterialSourcesDetailed(
        maybeInstant?: string,
    ): CancelablePromise<Array<(InternalMaterialSourceSourcedCountAndMassDTO | LedgerErrorInternalMaterialSourceSourceCountAndMassDTO)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal-material-sources/detailed',
            query: {
                'maybeInstant': maybeInstant,
            },
        });
    }
}
