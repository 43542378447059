import { Temporal } from '@js-temporal/polyfill';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  InternalMaterialSourceCreationDTO,
  InternalMaterialSourceDTO,
  InternalMaterialSourceId,
  InternalMaterialSourcePatchDTO,
  InternalMaterialSourceService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchInternalMaterialSources() {
  return await InternalMaterialSourceService.allInternalMaterialSources();
}

export function useInternalMaterialSources() {
  return useQuery({
    queryKey: queryKeys.internalSource.list(),
    queryFn: fetchInternalMaterialSources,
  });
}

async function fetchInternalMaterialSource(
  ctx: QueryFunctionContexts['internalSource']['detail'],
) {
  const [{ internalMaterialSourceId }] = ctx.queryKey;
  if (internalMaterialSourceId === undefined) {
    throw new Error('id must be provided');
  }
  return await InternalMaterialSourceService.getInternalMaterialSourceById(
    internalMaterialSourceId,
  );
}

export function useInternalMaterialSource(
  internalMaterialSourceId: InternalMaterialSourceId | undefined,
) {
  return useQuery({
    queryKey: queryKeys.internalSource.detail(internalMaterialSourceId),
    queryFn: fetchInternalMaterialSource,
  });
}

async function createInternalMaterialSource(
  internalMaterialSourceCreation: InternalMaterialSourceCreationDTO,
) {
  await InternalMaterialSourceService.createInternalMaterialSource(
    internalMaterialSourceCreation,
  );
}

export function useCreateInternalMaterialSource() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createInternalMaterialSource,
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.internalSource.list(),
        queryKeys.internalSource.analysis.sourcedMass.list(undefined),
      );
    },
  });
}

async function patchInternalMaterialSource(
  internalMaterialSourceId: InternalMaterialSourceId,
  internalMaterialSourcePatch: InternalMaterialSourcePatchDTO,
) {
  await InternalMaterialSourceService.patchInternalMaterialSource(
    internalMaterialSourceId,
    internalMaterialSourcePatch,
  );
}

export function usePatchInternalMaterialSource(
  internalMaterialSourceId: InternalMaterialSourceId,
) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: InternalMaterialSourcePatchDTO) =>
      await patchInternalMaterialSource(internalMaterialSourceId, patch),
    onSettled() {
      invalidator.invalidateMaterialState();
      invalidator.invalidateKeys(
        queryKeys.internalSource.detail(internalMaterialSourceId),
        queryKeys.internalSource.list(),
      );
    },
  });
}

async function deleteInternalMaterialSource(
  internalMaterialSourceId: InternalMaterialSourceId,
) {
  await InternalMaterialSourceService.deleteInternalMaterialSource(
    internalMaterialSourceId,
  );
}

export function useDeleteInternalMaterialSource() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (internalMaterialSource: InternalMaterialSourceDTO) =>
      await deleteInternalMaterialSource(internalMaterialSource.id),
    onSuccess(data, deletedInternalMaterialSource) {
      invalidator.removeKey(
        queryKeys.internalSource.detail(deletedInternalMaterialSource.id),
      );
    },
    onError(error, deletedInternalMaterialSource) {
      invalidator.resetKey(
        queryKeys.internalSource.detail(deletedInternalMaterialSource.id),
      );
    },
    onSettled() {
      invalidator.invalidateKeys(queryKeys.internalSource.list());
    },
  });
}

async function fetchInternalMaterialSourceDetailed(
  ctx: QueryFunctionContexts['internalSource']['analysis']['sourcedMass']['detail'],
) {
  const [{ internalMaterialSourceId, instant }] = ctx.queryKey;
  return await InternalMaterialSourceService.getInternalMaterialSourceDetailed(
    internalMaterialSourceId,
    instant?.toString(),
  );
}

export function useInternalMaterialSourceDetailed({
  internalMaterialSourceId,
  instant,
}: {
  internalMaterialSourceId: string;
  instant: Temporal.Instant | undefined;
}) {
  return useQuery({
    queryFn: fetchInternalMaterialSourceDetailed,
    queryKey: queryKeys.internalSource.analysis.sourcedMass.detail(
      internalMaterialSourceId,
      instant,
    ),
  });
}

async function fetchInternalMaterialSourcesDetailed(
  ctx: QueryFunctionContexts['internalSource']['analysis']['sourcedMass']['list'],
) {
  const [{ instant }] = ctx.queryKey;
  return await InternalMaterialSourceService.getInternalMaterialSourcesDetailed(
    instant?.toString(),
  );
}

export function useInternalMaterialSourcesDetailed(
  instant: Temporal.Instant | undefined,
) {
  return useQuery({
    queryFn: fetchInternalMaterialSourcesDetailed,
    queryKey: queryKeys.internalSource.analysis.sourcedMass.list(instant),
  });
}

async function fetchInternalMaterialSourceComposition(
  ctx: QueryFunctionContexts['internalSource']['analysis']['composition']['detail'],
) {
  const [{ internalMaterialSourceId, intervalStart, intervalEnd }] =
    ctx.queryKey;
  if (internalMaterialSourceId === null) throw new Error('ID must be provided');
  return await InternalMaterialSourceService.getInternalMaterialSourceComposition(
    internalMaterialSourceId,
    intervalStart,
    intervalEnd,
  );
}

export function useInternalMaterialSourceComposition(args: {
  internalMaterialSourceId: string | null;
  intervalStart: string | undefined;
  intervalEnd: string | undefined;
}) {
  return useQuery({
    queryFn: fetchInternalMaterialSourceComposition,
    queryKey: queryKeys.internalSource.analysis.composition.detail(args),
    enabled: args.internalMaterialSourceId !== null,
  });
}

async function fetchInternalMaterialSourceCompositionsByCommodity(
  ctx: QueryFunctionContexts['internalSource']['analysis']['compositionByCommodity']['list'],
) {
  const [{ intervalStart, intervalEnd }] = ctx.queryKey;
  return await InternalMaterialSourceService.getInternalMaterialSourceCompositionsByCommodity(
    intervalStart,
    intervalEnd,
  );
}

export function useInternalMaterialSourceCompositionsByCommodity(
  intervalStart: string | undefined,
  intervalEnd: string | undefined,
) {
  return useQuery({
    queryFn: fetchInternalMaterialSourceCompositionsByCommodity,
    queryKey: queryKeys.internalSource.analysis.compositionByCommodity.list(
      intervalStart,
      intervalEnd,
    ),
  });
}
