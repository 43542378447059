import { Loader, MultiSelect, MultiSelectProps } from '@mantine/core';
import { useMaterialClasses } from '../api/materialClass';
import { MaterialClassId } from '../rest-client';

export type MaterialClassMultiSelectProps = Omit<
  MultiSelectProps,
  | 'value'
  | 'onChange'
  | 'data'
  | 'disabled'
  | 'placeholder'
  | 'searchable'
  | 'nothingFound'
  | 'rightSection'
> & {
  value: MaterialClassId[];
  onChange: (materialClassIds: MaterialClassId[]) => void;
};

export function MaterialClassMultiSelect(props: MaterialClassMultiSelectProps) {
  const {
    value,
    onChange,
    label = 'Material Classes',
    ...multiSelectProps
  } = props;
  const {
    isLoadingError,
    error,
    isLoading,
    data: materialClasses,
  } = useMaterialClasses();

  if (isLoadingError) {
    throw error;
  }

  return (
    <MultiSelect
      data={
        materialClasses?.map((mc) => ({
          value: mc.id,
          label: mc.name,
        })) ?? []
      }
      disabled={isLoading}
      label={label}
      placeholder={isLoading ? 'Loading...' : 'Select material classes'}
      nothingFound='No material classes found'
      rightSection={isLoading ? <Loader size='xs' /> : undefined}
      searchable
      hoverOnSearchChange
      value={value}
      onChange={onChange}
      {...multiSelectProps}
    />
  );
}
