/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommodityInventoryAggregationDTO } from '../models/CommodityInventoryAggregationDTO';
import type { ContainerSampleLedgerEventDTO } from '../models/ContainerSampleLedgerEventDTO';
import type { InvalidInventoryLedgerStatusDTO } from '../models/InvalidInventoryLedgerStatusDTO';
import type { InventoryLedgerEventCalendarDTO } from '../models/InventoryLedgerEventCalendarDTO';
import type { LedgerErrorCommodityInventoryAggregationResultDTO } from '../models/LedgerErrorCommodityInventoryAggregationResultDTO';
import type { LedgerErrorRecoveryGoalPathBehaviorInventoryAggregationResultDTO } from '../models/LedgerErrorRecoveryGoalPathBehaviorInventoryAggregationResultDTO';
import type { OutputContainerChangeLedgerEventDTO } from '../models/OutputContainerChangeLedgerEventDTO';
import type { ProcessBufferDepletionLedgerEventDTO } from '../models/ProcessBufferDepletionLedgerEventDTO';
import type { ProcessBufferRestorationLedgerEventDTO } from '../models/ProcessBufferRestorationLedgerEventDTO';
import type { ScaleReadingLedgerEventDTO } from '../models/ScaleReadingLedgerEventDTO';
import type { SuccessfulRecoveryGoalPathBehaviorInventoryAggregationDTO } from '../models/SuccessfulRecoveryGoalPathBehaviorInventoryAggregationDTO';
import type { TransactionInventoryLedgerEventDTO } from '../models/TransactionInventoryLedgerEventDTO';
import type { ValidInventoryLedgerStatusDTO } from '../models/ValidInventoryLedgerStatusDTO';
import type { WarningInventoryLedgerStatusDTO } from '../models/WarningInventoryLedgerStatusDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InventoryService {
    /**
     * @param start
     * @param end
     * @returns any Success
     * @throws ApiError
     */
    public static getInventoryLedgerEvents(
        start?: string,
        end?: string,
    ): CancelablePromise<Array<(TransactionInventoryLedgerEventDTO | ScaleReadingLedgerEventDTO | ContainerSampleLedgerEventDTO | OutputContainerChangeLedgerEventDTO | ProcessBufferRestorationLedgerEventDTO | ProcessBufferDepletionLedgerEventDTO)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inventory/ledger-events',
            query: {
                'start': start,
                'end': end,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getInventoryLedgerStatus(): CancelablePromise<(ValidInventoryLedgerStatusDTO | WarningInventoryLedgerStatusDTO | InvalidInventoryLedgerStatusDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inventory/ledger-status',
        });
    }
    /**
     * @param facilityGuid
     * @param instant
     * @returns any Success
     * @throws ApiError
     */
    public static getFacilityCommodityAggregatedInventory(
        facilityGuid: string,
        instant?: string,
    ): CancelablePromise<(CommodityInventoryAggregationDTO | LedgerErrorCommodityInventoryAggregationResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inventory/{facilityGuid}/mass-totals',
            path: {
                'facilityGuid': facilityGuid,
            },
            query: {
                'instant': instant,
            },
        });
    }
    /**
     * @param facilityGuid
     * @param maybeInstant
     * @returns any Success
     * @throws ApiError
     */
    public static getRecoveryGoalPathBehaviorTotals(
        facilityGuid: string,
        maybeInstant?: string,
    ): CancelablePromise<(LedgerErrorRecoveryGoalPathBehaviorInventoryAggregationResultDTO | SuccessfulRecoveryGoalPathBehaviorInventoryAggregationDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inventory/{facilityGuid}/recovery-goal-path-behavior-totals',
            path: {
                'facilityGuid': facilityGuid,
            },
            query: {
                'maybeInstant': maybeInstant,
            },
        });
    }
    /**
     * @returns InventoryLedgerEventCalendarDTO Success
     * @throws ApiError
     */
    public static getInventoryLedgerEventCalendar(): CancelablePromise<InventoryLedgerEventCalendarDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inventory/calendar',
        });
    }
}
