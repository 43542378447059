import {
  Center,
  Group,
  Loader,
  Select,
  SelectProps,
  Text,
} from '@mantine/core';
import { Dayjs } from 'dayjs';
import { forwardRef } from 'react';
import { P, match } from 'ts-pattern';
import { MaterialSetCommodity } from '../Commodity/MaterialSetCommodity';
import { ContainerIcon } from '../Icons';
import { MaterialSetMass } from '../Mass/MaterialSetMass';
import {
  ContainerLookupState,
  useContainerStateLookup,
  useFacilityContainers,
} from '../api/container';
import { MaterialContainerId } from '../rest-client';

export interface ContainerFilterOptions {
  hideEmpty: boolean;
  hideFull: boolean;
  hiddenTypes?: Set<string>;
}

export type ContainerSelectVariant = 'name' | 'material';

export interface ContainerSelectSpecificProps extends ContainerFilterOptions {
  facilityId: string;
  timestamp: Dayjs | undefined;
  hiddenContainerIds?: Set<MaterialContainerId>;
  value: MaterialContainerId | null;
  onChange: (containerId: MaterialContainerId | null) => void;
  variant?: ContainerSelectVariant;
}

export type ContainerSelectProps = Omit<
  SelectProps,
  keyof ContainerSelectSpecificProps | 'data' | 'rightSection'
> &
  ContainerSelectSpecificProps;

export default function ContainerSelect(props: ContainerSelectProps) {
  const {
    facilityId,
    timestamp,
    hideEmpty,
    hideFull,
    hiddenTypes = new Set(),
    hiddenContainerIds = new Set(),
    value,
    onChange,
    disabled = false,
    searchable = true,
    nothingFound = 'No containers found',
    placeholder = 'Select container',
    variant = 'material',
    ...selectProps
  } = props;

  const {
    data: containers,
    isLoading,
    isLoadingError,
    error,
  } = useFacilityContainers(facilityId);

  if (isLoadingError) {
    throw error;
  }

  const getContainerState = useContainerStateLookup({
    facilityId,
    timestamp,
  });

  // We need state information to hide empty or full containers
  const ready = containers !== undefined;

  const selectData =
    containers
      ?.filter((c) => !hiddenTypes.has(c.containerType.id))
      .filter((c) => !hiddenContainerIds.has(c.id))
      .map((container) => ({
        container,
        state: getContainerState(container.id),
        label: container.name,
        value: container.id,
      }))
      .filter(
        ({ state, value }) =>
          value === props.value ||
          (hideEmpty ? state.status !== 'empty' : true),
      )
      .filter(({ state, value }) =>
        value === props.value || hideFull
          ? !(state.status === 'occupied' && state.state.full)
          : true,
      ) ?? [];

  return (
    <Select
      itemComponent={match(variant)
        .with('material', () => ContainerSelectItem)
        .with('name', () => undefined)
        .exhaustive()}
      data={selectData}
      value={value}
      onChange={onChange}
      placeholder={!ready ? 'Loading containers...' : placeholder}
      disabled={!ready || disabled}
      searchable={searchable}
      nothingFound={nothingFound}
      rightSection={isLoading ? <Loader size='xs' /> : undefined}
      {...selectProps}
    />
  );
}

export interface ContainerSelectItemProps
  extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  state: ContainerLookupState;
}

export const ContainerSelectItem = forwardRef<
  HTMLDivElement,
  ContainerSelectItemProps
>(function ContainerSelectItem({ label, state, ...divProps }, ref) {
  const stateSection = match(state)
    .with({ status: 'loading' }, () => <Loader size='xs' />)
    .with({ status: 'request-error' }, () => (
      <Text size='xs' color='red'>
        Error
      </Text>
    ))
    .with({ status: 'ledger-error' }, () => (
      <Text size='xs' color='orange'>
        Ledger Error
      </Text>
    ))
    .with({ status: 'empty' }, () => (
      <Text color='dimmed' size='xs'>
        empty
      </Text>
    ))
    .with(
      { status: 'occupied', state: P.select() },
      ({ materialSet, full }) => (
        <>
          <MaterialSetMass materialSet={materialSet} />
          <MaterialSetCommodity materialSet={materialSet} variant='succint' />
          {full ? 'Full' : undefined}
        </>
      ),
    )
    .exhaustive();

  return (
    <div ref={ref} {...divProps} style={{ width: 'fit-content' }}>
      <Group noWrap position='apart'>
        <Center>
          <ContainerIcon size='1.2em' />
          <Text> {label}</Text>
        </Center>
        {stateSection}
      </Group>
    </div>
  );
});
