import { TransformComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { useCallback } from 'react';
import Temporal from '../../Temporal/temporal.ts';
import { EChart } from '../../echarts/BareEChart';
import { useRouteParams } from '../../util/useRouteParams.ts';
import { useFacilityContext } from '../FacilityContext';
import cssClasses from './MetricGraph.module.css';

echarts.use(TransformComponent);

export interface SeriesLine {
  name: string;
  entries: {
    timestamp: string;
    value: number | string;
    name: string;
  }[];
}
export interface MetricGraphProps {
  seriesLines: SeriesLine[];
  yAxisLabel: string;
}

export function MetricGraph(props: MetricGraphProps) {
  const { seriesLines, yAxisLabel } = props;

  const { timeZoneId: tz } = useFacilityContext();

  const [{ startDate, endDate }] = useRouteParams([
    'FacilityInsightsDashboard',
    'FacilityInsightsMetricDetail',
    'FacilityInsightsMetricFacetDetail',
  ]);

  const tooltipFormatter = useCallback(
    (
      series: {
        data: {
          timestamp: string;
          value: number;
          name: string;
        };
      }[],
    ) => {
      // TODO improve styling, color dot, etc.
      const {
        data: { timestamp, name, value },
      } = series[0];
      return `${Temporal.Instant.from(timestamp)
        .toZonedDateTimeISO(tz)
        .toLocaleString(undefined, {
          month: 'short',
          day: 'numeric',
        })}: ${value.toFixed(1)} ${name}`;
    },
    [tz],
  );
  const xAxisLabelFormatter = useCallback(
    (value: number) => {
      return Temporal.Instant.fromEpochMilliseconds(value)
        .toZonedDateTimeISO(tz)
        .toLocaleString(undefined, {
          month: 'numeric',
          day: 'numeric',
        });
    },
    [tz],
  );

  const chartSeries = seriesLines.map((seriesLineOpts, index) => ({
    name: seriesLineOpts.name,
    datasetIndex: index + 1,
    type: 'line',
    colorBy: 'series',
    animation: false,
    dimensions: ['timestamp', 'value'],
    smooth: 0.2,
  }));

  const sourceData = seriesLines.flatMap(
    (seriesLineOpts) => seriesLineOpts.entries,
  );

  const transforms = seriesLines.map((seriesLineOps) => {
    return {
      transform: {
        type: 'filter',
        config: {
          dimension: 'name',
          '=': seriesLineOps.name,
        },
      },
    };
  });

  const maxSeriesValueDigits = Math.max(
    ...seriesLines.flatMap((seriesLine) =>
      seriesLine.entries.map((entry) => Number(entry.value).toFixed(0).length),
    ),
  );

  // TODO support onclick for charts to link to metric pages
  return (
    <EChart
      className={cssClasses.graph}
      option={{
        legend: {
          top: 20,
        },
        dataset: [
          {
            source: sourceData,
          },
          ...transforms,
        ],
        xAxis: {
          min: startDate,
          max: endDate,
          name: 'Date',
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 40,
          },
          type: 'time',
          axisLabel: {
            formatter: xAxisLabelFormatter,
            hideOverlap: true,
          },
        },
        yAxis: {
          name: yAxisLabel,
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 40 + 12 * (maxSeriesValueDigits - 2),
          },
        },
        series: chartSeries,
        tooltip: {
          show: true,
          trigger: 'axis',
          formatter: tooltipFormatter,
        },
      }}
    />
  );
}
