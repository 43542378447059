import {
  Alert,
  Container,
  Flex,
  Loader,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { UseQueryResult } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { match } from 'ts-pattern';
import { AppPage } from '../../../App/AppPage';
import { DateRange } from '../../../Temporal/dateRangeAndOffset';
import { MetricHeader } from '../Header/MetricHeader';
import { MetricGraph, SeriesLine } from '../MetricGraph';
import cssClasses from './MetricDetailPageTemplate.module.css';

export interface MetricDetailTableColumn {
  title: string;
  numeric: boolean;
}

interface MetricDetailPageTemplateProps<TRowData> {
  title: string;
  tableTitle: string;
  graphTitle: string;
  dateRange: DateRange;
  setDateRange: (dateRange: Partial<DateRange> | null) => void;
  renderTableRow: (rowData: TRowData) => ReactNode;
  tableColumns: {
    title: string;
    numeric: boolean;
  }[];
  seriesData: SeriesLine[];
  tableData: TRowData[];
  query: UseQueryResult;
  yAxisLabel: string;
}

export function MetricDetailPageTemplate<TRowData>({
  title,
  graphTitle,
  tableTitle,
  dateRange,
  setDateRange,
  renderTableRow,
  tableColumns,
  seriesData,
  tableData,
  query,
  ...metricGraphProps
}: MetricDetailPageTemplateProps<TRowData>) {
  return (
    <AppPage>
      <MetricHeader
        title={title}
        setDateRange={setDateRange}
        dateRange={dateRange}
      />
      <AppPage.Section>
        <Stack spacing='xs'>
          <Title order={3}>{graphTitle}</Title>
          {match(query)
            .with({ isLoadingError: true }, ({ error }) => {
              throw error;
            })
            .with({ isLoading: true }, () => (
              <Flex className={cssClasses.cardLoading}>
                <Loader variant='bars' size='xl' />
              </Flex>
            ))
            .otherwise(() => {
              if (
                seriesData.length === 0 ||
                seriesData.filter((line) => line.entries.length > 0).length ===
                  0
              ) {
                return (
                  <Alert color='blue' title='No Data for Selected Date Range'>
                    <Text>
                      No {graphTitle.toLowerCase()} data was found for the
                      selected date range. Please select a date range where you
                      would expect to have {graphTitle.toLowerCase()} data to
                      view the timeseries graph.
                    </Text>
                  </Alert>
                );
              }
              return (
                <Container h='500px' w='100%'>
                  <MetricGraph seriesLines={seriesData} {...metricGraphProps} />
                </Container>
              );
            })}
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack spacing='xs'>
          <Title order={3}>{tableTitle}</Title>
          {match(query)
            .with({ isLoadingError: true }, ({ error }) => {
              throw error;
            })
            .with({ isLoading: true }, () => (
              <Flex className={cssClasses.cardLoading}>
                <Loader variant='bars' size='xl' />
              </Flex>
            ))
            .otherwise(() => {
              if (
                seriesData.length === 0 ||
                seriesData.filter((line) => line.entries.length > 0).length ===
                  0
              ) {
                return (
                  <Alert color='blue' title='No Data for Selected Date Range'>
                    <Text>
                      No {tableTitle.toLowerCase()} data was found for the
                      selected date range. Please select a date range where you
                      would expect to have {tableTitle.toLowerCase()} data to
                      view the table.
                    </Text>
                  </Alert>
                );
              }
              return (
                <Table>
                  <thead>
                    <tr>
                      {tableColumns.map((col) => (
                        <th
                          key={col.title}
                          style={{
                            textAlign: col.numeric ? 'right' : undefined,
                          }}
                        >
                          {col.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{tableData.map(renderTableRow)}</tbody>
                </Table>
              );
            })}
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
