import { match, P } from 'ts-pattern';
import { Router } from '../../router';
import { The404 } from '../../The404';
import { DashboardPage } from './DashboardPage';
import { CommodityMassThroughputMetricPage } from './Metrics/CommodityMassThroughputMetric';
import { CommodityPurityMetricPage } from './Metrics/CommodityPurityMetric';
import { MaterialLossMetricPage } from './Metrics/MaterialLossMetric';
import { MassThroughputCommoditySubMetricPage } from './Metrics/SubMetrics/MassThroughputCommoditySubMetric';
import { MaterialLossRecoveryGoalSubMetricPage } from './Metrics/SubMetrics/MaterialLossRecoveryGoalSubMetric';
import { MaterialPurityCommoditySubMetricPage } from './Metrics/SubMetrics/MaterialPurityCommoditySubMetric';
import { SystemUtilizationProcessSubMetricPage } from './Metrics/SubMetrics/SystemUtilizationProcessSubMetric';
import { SystemUtilizationMetricPage } from './Metrics/SystemUtilizationMetric';

export const FacilityInsightsArea = () => {
  const route = Router.useRoute([
    'FacilityInsightsArea',
    'FacilityInsightsDashboard',
    'FacilityInsightsMetricDetail',
    'FacilityInsightsMetricFacetDetail',
  ]);
  if (route === undefined) {
    throw new Error('Invalid route');
  }

  return (
    match(route)
      .with(
        { name: 'FacilityInsightsDashboard' },
        { name: 'FacilityInsightsArea' },
        () => <DashboardPage />,
      )
      .with(
        { name: 'FacilityInsightsMetricDetail' },
        ({ params: { metricName } }) => {
          return match(metricName)
            .with('system-utilization', () => <SystemUtilizationMetricPage />)
            .with('commodity-mass-throughput', () => (
              <CommodityMassThroughputMetricPage />
            )) // TODO
            .with('material-loss', () => {
              return <MaterialLossMetricPage />;
            })
            .with('material-purity', () => {
              return <CommodityPurityMetricPage />;
            })
            .otherwise(() => <The404 />);
        },
      )
      // this route structure/matching will probably change, but works for now
      .with({ name: 'FacilityInsightsMetricFacetDetail' }, ({ params }) =>
        match(params)
          .with(
            { metricName: 'commodity-mass-throughput' },
            ({ facetName }) => {
              return (
                <MassThroughputCommoditySubMetricPage commodityId={facetName} />
              );
            },
          )
          .with(
            { metricName: 'system-utilization', facetName: P.string },
            ({ facetName }) => {
              return (
                <SystemUtilizationProcessSubMetricPage processId={facetName} />
              );
            },
          )
          .with(
            { metricName: 'material-loss', facetName: P.string },
            ({ facetName }) => {
              return (
                <MaterialLossRecoveryGoalSubMetricPage
                  recoveryGoalId={facetName}
                />
              );
            },
          )
          .with(
            { metricName: 'material-purity', facetName: P.string },
            ({ facetName }) => {
              return (
                <MaterialPurityCommoditySubMetricPage commodityId={facetName} />
              );
            },
          )
          .otherwise(() => <The404 />),
      )
      .exhaustive()
  );
};
