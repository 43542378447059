import { Group, Title } from '@mantine/core';
import { AppPage } from '../App/AppPage';
import { MaterialClassIcon } from '../Icons';
import { LinkButton } from '../Link';
import { MaterialClassSetTable } from '../MaterialClassSet/MaterialClassSetTable';
import { Router } from '../router';
import AddMaterialClassButton from './AddMaterialClassButton';
import { MaterialClassTable } from './MaterialClassTable';

export function MaterialClassesPage() {
  return (
    <AppPage title='Material Classes'>
      <AppPage.Section>
        <Group>
          <Title order={2}>Material Classes</Title>
          <AddMaterialClassButton addMaterialClassDrawerFormProps={{}} />
        </Group>
        <MaterialClassTable />
      </AppPage.Section>
      <AppPage.Section>
        <Group>
          <Title order={2}>Material Class Sets</Title>
          <LinkButton
            to={Router.MaterialClassSetCreate()}
            leftIcon={<MaterialClassIcon />}
          >
            Add Material Class Set
          </LinkButton>
        </Group>
        <MaterialClassSetTable />
      </AppPage.Section>
    </AppPage>
  );
}
