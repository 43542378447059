import {
  Alert,
  Button,
  Group,
  Paper,
  Select,
  Skeleton,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import { AppPage } from '../App/AppPage';
import { CompositionChart } from '../CompositionChart';
import { ContainerIdName } from '../Container/ContainerIdName';
import { useFacilityContext } from '../Facility/FacilityContext';
import { MutationErrorAlert } from '../Form/MutationErrorAlert';
import { DeleteIcon, EditIcon } from '../Icons';
import { LinkButton } from '../Link';
import { MaterialClassSetName } from '../MaterialClassSet/MaterialClassSetName';
import { MaterialClassSetSelect } from '../MaterialClassSet/MaterialClassSetSelect';
import NetWeight from '../Weights/NetWeight';
import QuantizedWeight from '../Weights/QuantizedWeight';
import { useContainer } from '../api/container';
import {
  useContainerSample,
  useDeleteContainerSample,
} from '../api/containerSample';
import {
  useAddContainerSampleAnalysis,
  useContainerSampleAnalyses,
  useDeleteContainerSampleAnalysis,
} from '../api/containerSampleAnalysis';
import { useMaterialClassSets } from '../api/materialClassSet';
import { useSamplingSuites } from '../api/samplingSuite';
import {
  useCreateSamplingSuiteContainerSampleAnalysis,
  useSamplingSuiteContainerSampleAnalyses,
} from '../api/samplingSuiteContainerSampleAnalysis';
import { LabeledValue } from '../common';
import { ManualContainerSampleAnalysisDTO } from '../rest-client';
import { Router } from '../router';
import { composition } from '../util/mixture';
import { ContainerSampleAnalysisTable } from './ContainerSampleAnalysis/ContainerSampleAnalysisTable';
import { EditContainerSampleAnalysisForm } from './ContainerSampleAnalysis/EditContainerSampleAnalysisForm';
import { SamplingSuiteAnalysisDetail } from './SamplingSuiteAnalysisDetail';

export function ContainerSampleDetailPage(props: {
  containerSampleId: string;
}) {
  const { containerSampleId } = props;

  const containerSampleQuery = useContainerSample(containerSampleId);
  const containerQuery = useContainer(containerSampleQuery.data?.containerId);
  const facility = useFacilityContext();

  return (
    <AppPage
      breadcrumbs={[
        { routeName: Router.ContainerSampleList(), title: 'Container Samples' },
      ]}
      title={
        containerSampleQuery.data &&
        containerQuery.data &&
        `${containerQuery.data.name} @ ${dayjs
          .utc(containerSampleQuery.data.sampleTakenTimestamp)
          .tz(facility.timeZoneId)
          .format('l')}`
      }
    >
      <ContainerSampleDetailsSection containerSampleId={containerSampleId} />
      <ContainerSampleAnalysisSection containerSampleId={containerSampleId} />
    </AppPage>
  );
}

function ContainerSampleDetailsSection(props: { containerSampleId: string }) {
  const { containerSampleId } = props;
  const containerSampleQuery = useContainerSample(containerSampleId);
  const facility = useFacilityContext();

  const deleteMutation = useDeleteContainerSample();

  if (containerSampleQuery.isLoadingError) {
    throw containerSampleQuery.error;
  }

  return (
    <AppPage.Section>
      <Stack>
        <Group position='apart'>
          <Title order={2}>Sample Details</Title>
          <Button
            variant='outline'
            leftIcon={<DeleteIcon />}
            color='red'
            onClick={() => {
              deleteMutation.mutate(containerSampleId, {
                onError() {
                  showNotification({
                    title: 'Error Deleting Container Sample',
                    message: 'An error ocurred deleting the container sample.',
                    color: 'red',
                    icon: <IconX />,
                  });
                },
                onSuccess() {
                  showNotification({
                    title: 'Container Sample Created',
                    message: 'The container sample was successfully deleted.',
                    color: 'green',
                    icon: <IconCheck />,
                  });
                  Router.replace('ContainerSampleList');
                },
                onSettled() {
                  deleteMutation.reset();
                },
              });
            }}
          >
            Delete Sample
          </Button>
        </Group>
        <Group>
          <LabeledValue label='Sampled Container'>
            {containerSampleQuery.data !== undefined ? (
              <ContainerIdName
                variant='name-only-link'
                time={dayjs.utc(containerSampleQuery.data.sampleTakenTimestamp)}
                containerId={containerSampleQuery.data.containerId}
              />
            ) : (
              <Skeleton visible>Loading...</Skeleton>
            )}
          </LabeledValue>
          {/* TODO(2156): Indicate stacking behavior of container / whether or not top of stack was relevant */}
          <LabeledValue label='Sample Taken'>
            {containerSampleQuery.data &&
              dayjs
                .utc(containerSampleQuery.data.sampleTakenTimestamp)
                .tz(facility.timeZoneId)
                .format('LLLL z')}
          </LabeledValue>
        </Group>
      </Stack>
    </AppPage.Section>
  );
}

function ContainerSampleAnalysisSection(props: { containerSampleId: string }) {
  const { containerSampleId } = props;
  const manualAnalysesQuery = useContainerSampleAnalyses({ containerSampleId });
  const samplingSuiteAnalysesQuery = useSamplingSuiteContainerSampleAnalyses({
    containerSampleId,
    suiteId: null,
  });
  const materialClassSetsQuery = useMaterialClassSets();
  const samplingSuitesQuery = useSamplingSuites();

  const addManualAnalysisMutation = useAddContainerSampleAnalysis();
  const addSamplingSuiteAnalysisMutation =
    useCreateSamplingSuiteContainerSampleAnalysis();

  const [materialClassSetId, setMaterialClassSetId] = useState<string | null>(
    null,
  );
  const [samplingSuiteId, setSamplingSuiteId] = useState<string | null>(null);

  const addSamplingSuiteAnalysis = (suiteId: string) => {
    if (materialClassSetId === null) throw new Error();
    addSamplingSuiteAnalysisMutation.mutate(
      {
        id: uuidv4(),
        containerSampleId,
        samplingSuiteId: suiteId,
        materialClassSetId,
      },
      {
        onError() {
          showNotification({
            title: 'Error Creating Sample Analysis',
            message: 'An error occurred creating the analysis',
            color: 'red',
            icon: <IconX />,
          });
        },
        onSuccess() {
          showNotification({
            title: 'Analysis Created',
            message: 'A new container sample analysis has been created',
            color: 'green',
            icon: <IconCheck />,
          });
        },
        onSettled() {
          addManualAnalysisMutation.reset();
        },
      },
    );
  };

  const addManualAnalysis = () => {
    addManualAnalysisMutation.mutate(
      {
        containerSampleId,
        id: uuidv4(),
        materialClassSetId,
        analysisTime: null,
        scaleId: null,
        scaleDisplayDivisions: null,
      },

      {
        onError() {
          showNotification({
            title: 'Error Creating Sample Analysis',
            message: 'An error occurred creating the analysis',
            color: 'red',
            icon: <IconX />,
          });
        },
        onSuccess() {
          showNotification({
            title: 'Analysis Created',
            message: 'A new container sample analysis has been created',
            color: 'green',
            icon: <IconCheck />,
          });
        },
        onSettled() {
          addManualAnalysisMutation.reset();
        },
      },
    );
  };

  if (manualAnalysesQuery.isLoadingError) {
    throw manualAnalysesQuery.error;
  }

  if (addManualAnalysisMutation.isError) {
    return (
      <MutationErrorAlert
        errorTitle='Error Creating Manual Sample Analysis'
        entityName='Sample Analysis'
        mutation={addManualAnalysisMutation}
        formVariant='create'
      />
    );
  }
  if (addSamplingSuiteAnalysisMutation.isError) {
    return (
      <MutationErrorAlert
        errorTitle='Error Creating VALI-Sample Analysis'
        entityName='Sample Analysis'
        mutation={addManualAnalysisMutation}
        formVariant='create'
      />
    );
  }

  const unanalyzed =
    manualAnalysesQuery.data &&
    manualAnalysesQuery.data.length === 0 &&
    samplingSuiteAnalysesQuery.data &&
    samplingSuiteAnalysesQuery.data.length === 0;

  if (
    materialClassSetsQuery.data &&
    materialClassSetsQuery.data.length > 0 &&
    materialClassSetId === null
  ) {
    setMaterialClassSetId(materialClassSetsQuery.data[0].id);
  }

  if (unanalyzed) {
    return (
      <Stack>
        {materialClassSetsQuery.data &&
          match(materialClassSetsQuery.data.length)
            .with(0, () => (
              <Alert color='orange' title='No Material Class Sets'>
                <Text>
                  No material class sets have been defined yet. This sample
                  cannot be analyzed until one is defined.
                </Text>
                <LinkButton to={Router.MaterialClassSetCreate()}>
                  Add Material Class Set
                </LinkButton>
              </Alert>
            ))
            .with(1, () => null)
            .otherwise(() => (
              <MaterialClassSetSelect
                w='fit-content'
                label='Material Class Set'
                value={materialClassSetId}
                onChange={setMaterialClassSetId}
              />
            ))}

        <Group>
          {samplingSuitesQuery.data && samplingSuitesQuery.data.length > 0 ? (
            samplingSuitesQuery.data.length === 1 ? (
              <Button
                size='lg'
                disabled={materialClassSetId === null}
                onClick={() =>
                  addSamplingSuiteAnalysis(samplingSuitesQuery.data[0].id)
                }
              >
                Analyze with VALI-Vision
              </Button>
            ) : (
              <Group>
                <Select
                  value={samplingSuiteId}
                  onChange={setSamplingSuiteId}
                  data={samplingSuitesQuery.data.map((ss) => ({
                    value: ss.id,
                    label: ss.name,
                  }))}
                />
                <Button
                  size='lg'
                  onClick={() => {
                    if (samplingSuiteId === null) throw new Error();
                    addSamplingSuiteAnalysis(samplingSuiteId);
                  }}
                  disabled={
                    samplingSuiteId === null || materialClassSetId === null
                  }
                >
                  Analyze with VALI-Vision
                </Button>
              </Group>
            )
          ) : null}
          <Button
            size='lg'
            variant={
              samplingSuitesQuery.data && samplingSuitesQuery.data.length > 0
                ? 'outline'
                : undefined
            }
            onClick={addManualAnalysis}
            loading={addManualAnalysisMutation.isLoading}
            disabled={materialClassSetsQuery.data?.length === 0}
          >
            {addManualAnalysisMutation.isLoading
              ? 'Creating Analysis...'
              : 'Add Manual Analysis'}
          </Button>
        </Group>
      </Stack>
    );
  }

  return (
    <Stack>
      {manualAnalysesQuery.data?.map((a) => (
        <Paper key={a.id} withBorder p='sm'>
          <ManualContainerSampleAnalysisDetail analysis={a} />
        </Paper>
      ))}
      {samplingSuiteAnalysesQuery.data?.map((a) => (
        <Paper key={a.id} withBorder p='sm'>
          <SamplingSuiteAnalysisDetail
            analysis={a}
            isFetching={samplingSuiteAnalysesQuery.isFetching}
            isError={samplingSuiteAnalysesQuery.isError}
          />
        </Paper>
      ))}
    </Stack>
  );
}

function ManualContainerSampleAnalysisDetail(props: {
  analysis: ManualContainerSampleAnalysisDTO;
}) {
  const { analysis } = props;
  const facility = useFacilityContext();
  const [isEditing, setIsEditing] = useState(false);
  const [initialOpenComplete, setInitialOpenComplete] = useState(false);
  const deleteMutation = useDeleteContainerSampleAnalysis();

  if (!analysis.isComplete && !isEditing && !initialOpenComplete) {
    setIsEditing(true);
    setInitialOpenComplete(true);
  }

  const materialClassComposition = analysis.isComplete
    ? composition(
        analysis.materialClassSet.materialClasses.map(
          (mc) =>
            [
              mc.name,
              analysis.materialClassMeasurements[mc.id].weight.ticks,
            ] as const,
        ),
      )
    : undefined;

  return (
    <Stack>
      <Group position='apart'>
        <Group>
          <LabeledValue label='Analysis Time'>
            {dayjs
              .utc(analysis.analysisTime)
              .tz(facility.timeZoneId)
              .format('LLLL z')}
          </LabeledValue>
          <LabeledValue label='Material Class Set'>
            <MaterialClassSetName
              materialClassSet={analysis.materialClassSet}
            />
          </LabeledValue>
          <LabeledValue label='Status'>
            <Text
              weight='bold'
              color={analysis.isComplete ? 'green' : 'orange'}
            >
              {analysis.isComplete ? 'Complete' : 'Incomplete'}
            </Text>
          </LabeledValue>
        </Group>
        <Button
          variant='outline'
          leftIcon={<DeleteIcon />}
          color='red'
          onClick={() => {
            modals.openConfirmModal({
              title: 'Delete sample analysis forever',
              centered: true,
              children: (
                <Text size='sm'>
                  Are you sure you want to delete this analysis? This action is
                  irreversible and the analysis will be deleted forever.
                </Text>
              ),
              labels: {
                confirm: 'Delete Analysis Forever',
                cancel: 'Back to Safety',
              },
              confirmProps: { color: 'red' },
              onConfirm: () => {
                deleteMutation.mutate(analysis.id, {
                  onError() {
                    showNotification({
                      title: 'Error Deleting Analysis',
                      message: `An error occurred the analysis.`,
                      color: 'red',
                      icon: <IconX />,
                    });
                  },
                  onSuccess() {
                    showNotification({
                      title: 'Analysis Deleted',
                      message: `The analysis was successfully deleted`,
                      color: 'green',
                      icon: <IconCheck />,
                    });
                  },
                  onSettled() {
                    deleteMutation.reset();
                  },
                });
              },
            });
          }}
        >
          Delete
        </Button>
      </Group>
      <Group>
        <LabeledValue label='Measured Total Weight'>
          <div style={{ display: 'inline-flex', gap: '1ch' }}>
            {analysis.specifiedTotalWeight ? (
              <QuantizedWeight weight={analysis.specifiedTotalWeight} />
            ) : (
              <Text color='dimmed'>not recorded</Text>
            )}
          </div>
        </LabeledValue>
        <LabeledValue label='Accumulated Total Weight'>
          <NetWeight weight={analysis.accumulatedTotalWeight} />
        </LabeledValue>
        <LabeledValue label='Scale'>
          {/* TODO(1739): Link to scale detail page, once that's implemented */}
          {analysis.scale.name}
        </LabeledValue>
      </Group>
      {materialClassComposition ? (
        <CompositionChart
          composition={materialClassComposition}
          rotateXAxisLabels
        />
      ) : null}

      {isEditing ? (
        <EditContainerSampleAnalysisForm
          analysis={analysis}
          onClose={() => setIsEditing(false)}
        />
      ) : (
        <>
          <ContainerSampleAnalysisTable analysis={analysis} />
          <Button
            maw='max-content'
            leftIcon={<EditIcon />}
            onClick={() => setIsEditing(true)}
            variant={analysis.isComplete ? 'outline' : 'filled'}
            color={analysis.isComplete ? 'gray' : 'teal'}
          >
            Edit Measurements
          </Button>
        </>
      )}
    </Stack>
  );
}
