/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommodityCreationDTO } from '../models/CommodityCreationDTO';
import type { CommodityDTO } from '../models/CommodityDTO';
import type { CommodityPatchDTO } from '../models/CommodityPatchDTO';
import type { CommoditySpotPriceCreationDTO } from '../models/CommoditySpotPriceCreationDTO';
import type { CommoditySpotPriceDTO } from '../models/CommoditySpotPriceDTO';
import type { CommoditySpotPricePatchDTO } from '../models/CommoditySpotPricePatchDTO';
import type { FacilityMassMetricsDTO } from '../models/FacilityMassMetricsDTO';
import type { NoSourcesCommodityInternalMaterialSourceAggregateCompositionsResultDTO } from '../models/NoSourcesCommodityInternalMaterialSourceAggregateCompositionsResultDTO';
import type { ProducedCommodityFeedFlowGroupPuritiesDTO } from '../models/ProducedCommodityFeedFlowGroupPuritiesDTO';
import type { ProducedCommodityPuritiesDTO } from '../models/ProducedCommodityPuritiesDTO';
import type { SourcesPresentCommodityInternalMaterialSourceAggregateCompositionsResultDTO } from '../models/SourcesPresentCommodityInternalMaterialSourceAggregateCompositionsResultDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CommodityService {
    /**
     * @returns CommodityDTO Success
     * @throws ApiError
     */
    public static getCommodities(): CancelablePromise<Array<CommodityDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commodities',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createCommodity(
        requestBody: CommodityCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/commodities',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param commodityGuid
     * @returns CommodityDTO Success
     * @throws ApiError
     */
    public static getCommodity(
        commodityGuid: string,
    ): CancelablePromise<CommodityDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commodities/{commodityGuid}',
            path: {
                'commodityGuid': commodityGuid,
            },
        });
    }
    /**
     * @param commodityGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchCommodity(
        commodityGuid: string,
        requestBody: CommodityPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/commodities/{commodityGuid}',
            path: {
                'commodityGuid': commodityGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param commodityGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteCommodity(
        commodityGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/commodities/{commodityGuid}',
            path: {
                'commodityGuid': commodityGuid,
            },
        });
    }
    /**
     * @param after
     * @param before
     * @param facilityId
     * @returns ProducedCommodityPuritiesDTO Success
     * @throws ApiError
     */
    public static getProducedCommodityPurities(
        after: string,
        before: string,
        facilityId?: string,
    ): CancelablePromise<Array<ProducedCommodityPuritiesDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commodities/produced-purities',
            query: {
                'after': after,
                'before': before,
                'facilityId': facilityId,
            },
        });
    }
    /**
     * @param commodityGuid
     * @param after
     * @param before
     * @param facilityId
     * @returns ProducedCommodityFeedFlowGroupPuritiesDTO Success
     * @throws ApiError
     */
    public static getProducedCommodityFeedFlowGroupPurities(
        commodityGuid: string,
        after: string,
        before: string,
        facilityId?: string,
    ): CancelablePromise<ProducedCommodityFeedFlowGroupPuritiesDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commodities/{commodityGuid}/produced-feed-flow-group-purities',
            path: {
                'commodityGuid': commodityGuid,
            },
            query: {
                'after': after,
                'before': before,
                'facilityId': facilityId,
            },
        });
    }
    /**
     * @param facilityId
     * @param after
     * @param before
     * @param includeNegativeWeights
     * @returns FacilityMassMetricsDTO Success
     * @throws ApiError
     */
    public static getProducedCommodityMasses(
        facilityId: string,
        after: string,
        before: string,
        includeNegativeWeights?: boolean,
    ): CancelablePromise<FacilityMassMetricsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commodities/produced-commodity-masses',
            query: {
                'facilityId': facilityId,
                'after': after,
                'before': before,
                'includeNegativeWeights': includeNegativeWeights,
            },
        });
    }
    /**
     * @param commodityGuid
     * @param after
     * @param before
     * @returns any Success
     * @throws ApiError
     */
    public static getCommodityInternalMaterialSourceCompositions(
        commodityGuid: string,
        after?: string,
        before?: string,
    ): CancelablePromise<(NoSourcesCommodityInternalMaterialSourceAggregateCompositionsResultDTO | SourcesPresentCommodityInternalMaterialSourceAggregateCompositionsResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commodities/{commodityGuid}/internal-material-source-compositions',
            path: {
                'commodityGuid': commodityGuid,
            },
            query: {
                'after': after,
                'before': before,
            },
        });
    }
    /**
     * @param commodityGuid
     * @returns CommoditySpotPriceDTO Success
     * @throws ApiError
     */
    public static getCommoditySpotPrices(
        commodityGuid: string,
    ): CancelablePromise<Array<CommoditySpotPriceDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commodities/{commodityGuid}/spot-prices',
            path: {
                'commodityGuid': commodityGuid,
            },
        });
    }
    /**
     * @param commodityGuid
     * @param commoditySpotPriceGuid
     * @returns CommoditySpotPriceDTO Success
     * @throws ApiError
     */
    public static getCommoditySpotPrice(
        commodityGuid: string,
        commoditySpotPriceGuid: string,
    ): CancelablePromise<CommoditySpotPriceDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commodities/{commodityGuid}/spot-price/{commoditySpotPriceGuid}',
            path: {
                'commodityGuid': commodityGuid,
                'commoditySpotPriceGuid': commoditySpotPriceGuid,
            },
        });
    }
    /**
     * @param commodityGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createCommoditySpotPrice(
        commodityGuid: string,
        requestBody: CommoditySpotPriceCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/commodities/{commodityGuid}/spot-price',
            path: {
                'commodityGuid': commodityGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param commodityGuid
     * @param spotPriceGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteCommoditySpotPrice(
        commodityGuid: string,
        spotPriceGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/commodities/{commodityGuid}/spot-price/{spotPriceGuid}',
            path: {
                'commodityGuid': commodityGuid,
                'spotPriceGuid': spotPriceGuid,
            },
        });
    }
    /**
     * @param commodityGuid
     * @param spotPriceGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchCommoditySpotPrice(
        commodityGuid: string,
        spotPriceGuid: string,
        requestBody: CommoditySpotPricePatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/commodities/{commodityGuid}/spot-price/{spotPriceGuid}',
            path: {
                'commodityGuid': commodityGuid,
                'spotPriceGuid': spotPriceGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
