import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { OutputContainerChangeIcon } from '../Icons';
import {
  AddOutputContainerChangeDrawerForm,
  AddOutputContainerChangeDrawerFormProps,
} from './AddOutputContainerChangeDrawerForm';

export type AddOutputContainerChangeButtonProps = Omit<
  ButtonProps,
  'onClick'
> & {
  addOutputContainerChangeDrawerFormProps: Omit<
    AddOutputContainerChangeDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddOutputContainerChangeButton(
  props: AddOutputContainerChangeButtonProps,
) {
  const {
    addOutputContainerChangeDrawerFormProps,
    leftIcon = <OutputContainerChangeIcon />,
    children = 'Add Output Container Change',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddOutputContainerChangeDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addOutputContainerChangeDrawerFormProps}
      />
    </>
  );
}
