import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { The404 } from '../The404';
import { Router } from '../router';
import { ContainerDetailPage } from './ContainerDetail';
import { ContainerInventoryPage } from './ContainerInventoryPage';

export function ContainerArea() {
  const route = Router.useRoute(['ContainerList', 'ContainerDetail']);

  return match(route)
    .with({ name: 'ContainerList' }, () => <ContainerInventoryPage />)
    .with(
      { name: 'ContainerDetail' },
      ({ params: { containerId, timestamp } }) => (
        <ContainerDetailPage
          containerId={containerId}
          timestamp={timestamp === undefined ? undefined : dayjs.utc(timestamp)}
        />
      ),
    )
    .with(undefined, () => <The404 />)
    .exhaustive();
}
