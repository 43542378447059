import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ContainerSampleAnalysisCreationDTO,
  ContainerSampleAnalysisPatchDTO,
  ContainerSamplingService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchContainerSampleAnalysesByMaterialClassSet() {
  return await ContainerSamplingService.getMaterialClassSetsContainerSampleAnalyses();
}

export function useMaterialClassSetsContainerSampleAnalyses() {
  return useQuery({
    queryKey: queryKeys.materialClassSetsContainerSampleAnalyses.list(),
    queryFn: fetchContainerSampleAnalysesByMaterialClassSet,
  });
}

async function fetchContainerSampleAnalyses(
  ctx: QueryFunctionContexts['containerSampleAnalysis']['list'],
) {
  const [{ containerSampleId }] = ctx.queryKey;
  return await ContainerSamplingService.getContainerSampleAnalyses(
    containerSampleId,
  );
}

export function useContainerSampleAnalyses(args: {
  containerSampleId?: string;
}) {
  return useQuery({
    queryKey: queryKeys.containerSampleAnalysis.list(args),
    queryFn: fetchContainerSampleAnalyses,
  });
}

async function fetchContainerSampleAnalysis(
  ctx: QueryFunctionContexts['containerSampleAnalysis']['detail'],
) {
  const [{ containerSampleAnalysisId }] = ctx.queryKey;
  return await ContainerSamplingService.getContainerSampleAnalysisById(
    containerSampleAnalysisId,
  );
}

export function useContainerSampleAnalysis(id: string) {
  return useQuery({
    queryKey: queryKeys.containerSampleAnalysis.detail(id),
    queryFn: fetchContainerSampleAnalysis,
  });
}

async function createContainerSampleAnalysis(
  dto: ContainerSampleAnalysisCreationDTO,
) {
  await ContainerSamplingService.createContainerSampleAnalysis(dto);
}

export function useAddContainerSampleAnalysis() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createContainerSampleAnalysis,
    onSettled(_data, _error, analysis) {
      invalidator.invalidateInventoryLedger();
      invalidator.invalidateKeys(
        queryKeys.containerSampleAnalysis.list({}),
        queryKeys.containerSampleAnalysis.list({
          containerSampleId: analysis.containerSampleId,
        }),
        queryKeys.materialClassSetsContainerSampleAnalyses.list(),
      );
    },
  });
}

async function patchContainerSampleAnalysis(args: {
  id: string;
  patch: ContainerSampleAnalysisPatchDTO;
}) {
  await ContainerSamplingService.patchContainerSampleAnalysis(
    args.id,
    args.patch,
  );
}

export function usePatchContainerSampleAnalysis() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchContainerSampleAnalysis,
    onSettled(_data, _error, { id }) {
      invalidator.invalidateInventoryLedger();
      invalidator.invalidateKeys(
        queryKeys.containerSampleAnalysis.lists(),
        queryKeys.containerSampleAnalysis.detail(id),
        queryKeys.materialClassSetsContainerSampleAnalyses.list(),
      );
    },
  });
}

async function deleteContainerSampleAnalysis(id: string) {
  await ContainerSamplingService.deleteContainerSampleAnalysisById(id);
}

export function useDeleteContainerSampleAnalysis() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteContainerSampleAnalysis,
    onSettled() {
      invalidator.invalidateInventoryLedger();
      invalidator.invalidateKeys(
        queryKeys.containerSampleAnalysis.lists(),
        queryKeys.materialClassSetsContainerSampleAnalyses.list(),
      );
    },
  });
}
