import { Alert, Table, Text } from '@mantine/core';
import { useMaterialClasses } from '../api/materialClass';
import { MaterialClassName } from './MaterialClassName';

export function MaterialClassTable() {
  const materialClassesQuery = useMaterialClasses();

  if (materialClassesQuery.isLoadingError) {
    throw materialClassesQuery.error;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {materialClassesQuery.data && materialClassesQuery.data.length === 0 ? (
          <tr>
            <td colSpan={2}>
              <Alert color='blue'>
                No material classes have been defined yet
              </Alert>
            </td>
          </tr>
        ) : undefined}
        {materialClassesQuery.data?.map((m) => (
          <tr key={m.id}>
            <td>
              <MaterialClassName name={m.name} id={m.id} />
            </td>
            <td>
              {m.description ? (
                m.description
              ) : (
                <Text color='dimmed'>no description</Text>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
