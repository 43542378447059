import { Paper, Text } from '@mantine/core';
import { Handle, NodeProps, Position } from 'reactflow';
import { InternalMaterialSinkIcon } from '../Icons';
import { InternalMaterialSinkIdName } from '../InternalMaterialSink/InternalMaterialSinkIdName';
import { FlowInternalNodeSinkData } from './GenealogyGraph';

export function InternalMaterialSinkNode(
  props: NodeProps<FlowInternalNodeSinkData>,
) {
  const {
    data: { internalMaterialSinkId },
  } = props;

  // TODO(2315): CSS
  // const { classes } = useNodeStyles({
  //   type: 'sink',
  //   zIndex: props.zIndex,
  //   selected: props.selected,
  //   isOrigin: false,
  // });

  return (
    <Paper style={{ zIndex: props.zIndex, outline: '1px solid gray' }} p='md'>
      <Handle type='target' id='input' position={Position.Top} />
      <Text weight={500}>
        <InternalMaterialSinkIcon style={{ marginBottom: '-0.2em' }} /> Internal
        Destination
      </Text>
      <InternalMaterialSinkIdName
        internalMaterialSinkId={internalMaterialSinkId}
      />
    </Paper>
  );
}
