import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { useCreateMaterialClass } from '../api/materialClass';
import { MaterialClassCreationDTO } from '../rest-client';
import {
  MaterialClassFormFields,
  MaterialClassFormValues,
  useMaterialClassForm,
} from './MaterialClassForm';

export type AddMaterialClassDrawerFormProps = {
  onSuccess?: (materialClass: MaterialClassCreationDTO) => void;
} & DrawerFormDrawerProps;

export function AddMaterialClassDrawerForm(
  props: AddMaterialClassDrawerFormProps,
) {
  const { onSuccess, onClose, ...drawerFormDrawerProps } = props;

  const createMutation = useCreateMaterialClass();
  const form = useMaterialClassForm();
  const materialClassId = useMemo(() => uuidv4(), []);

  const formValuesToDto = ({ name, description }: MaterialClassFormValues) => {
    const materialClass: MaterialClassCreationDTO = {
      id: materialClassId,
      name,
      description: description === '' ? null : description,
    };
    return materialClass;
  };

  return (
    <DrawerForm
      entityName='Material Class'
      form={form}
      drawerFormVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <MaterialClassFormFields form={form} />
    </DrawerForm>
  );
}
