import { useQueries, useQuery } from '@tanstack/react-query';
import type { Dayjs } from 'dayjs';
import { ChutecService, ChutecStream, SortSystemId } from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys } from './queryKeys';

const fetchChutecSortSystems = async () =>
  await ChutecService.getChutecSystems();

export function useChutecSortSystems() {
  return useQuery({
    queryKey: queryKeys.sortSystem.list({ systemType: 'Chutec' }),
    queryFn: fetchChutecSortSystems,
  });
}

async function fetchChutecSortSystem(
  ctx: QueryFunctionContexts['sortSystem']['detail'],
) {
  const [{ sortSystemId }] = ctx.queryKey;
  return ChutecService.getChutecSystemById(sortSystemId);
}

export function useChutecSortSystem(sortSystemId: SortSystemId) {
  return useQuery({
    queryKey: queryKeys.sortSystem.detail(sortSystemId),
    queryFn: fetchChutecSortSystem,
  });
}

async function fetchChutecSortPrograms(
  ctx: QueryFunctionContexts['sortSystem']['chutec']['sortProgram']['list'],
) {
  const [{ sortSystemId }] = ctx.queryKey;
  return await ChutecService.getChutecSortPrograms(sortSystemId);
}

export function useChutecSortPrograms(sortSystemId?: SortSystemId) {
  return useQuery({
    queryKey: queryKeys.sortSystem.chutec.sortProgram.list(sortSystemId),
    queryFn: fetchChutecSortPrograms,
  });
}

async function fetchChutecMaterialClass(
  ctx: QueryFunctionContexts['sortSystem']['chutec']['materialClass']['detail'],
) {
  const [{ chutecMaterialClassId }] = ctx.queryKey;
  return await ChutecService.getChutecMaterialClassById(chutecMaterialClassId);
}

export function useChutecMaterialClasses(
  materialClassIds: string[] | undefined,
) {
  return useQueries({
    queries:
      materialClassIds?.map((materialClassId) => ({
        queryKey:
          queryKeys.sortSystem.chutec.materialClass.detail(materialClassId),
        queryFn: fetchChutecMaterialClass,
      })) ?? [],
  });
}

async function fetchChutecMaterialClassAreaWindows(
  ctx: QueryFunctionContexts['sortSystem']['chutec']['telemetry']['materialClassAreaWindow']['list'],
) {
  const [{ systemId, stream, start, end }] = ctx.queryKey;
  return await ChutecService.getMaterialClassAreaWindows(
    systemId,
    stream,
    start.utc().toISOString(),
    30,
    end?.utc().toISOString(),
  );
}

export function useChutecMaterialClassAreaWindows(
  systemId: SortSystemId,
  stream: ChutecStream,
  start: Dayjs,
  end?: Dayjs,
) {
  return useQuery({
    queryKey:
      queryKeys.sortSystem.chutec.telemetry.materialClassAreaWindow.list(
        systemId,
        stream,
        start,
        end,
      ),
    queryFn: fetchChutecMaterialClassAreaWindows,
    // TODO(chutec): Figure out sensible caching behavior to make this work in real-time
  });
}
