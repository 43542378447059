import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { InternalMaterialSinkTransferIcon } from '../Icons.tsx';
import {
  AddInternalSinkContainerTransferDrawerForm,
  AddInternalSinkContainerTransferDrawerFormProps,
} from './AddInternalSinkContainerTransferDrawerForm.tsx';

export type AddInternalSinkContainerTransferButtonProps = Omit<
  ButtonProps,
  'onClick'
> & {
  addInternalSinkTransferDrawerFormProps: Omit<
    AddInternalSinkContainerTransferDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddInternalSinkContainerTransferButton(
  props: AddInternalSinkContainerTransferButtonProps,
) {
  const {
    addInternalSinkTransferDrawerFormProps,
    leftIcon = <InternalMaterialSinkTransferIcon />,
    children = 'Add Material Export',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddInternalSinkContainerTransferDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addInternalSinkTransferDrawerFormProps}
      />
    </>
  );
}
