import { Button, ButtonProps } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { DeleteIcon } from '../Icons';
import { useDeleteMaterialClassSet } from '../api/materialClassSet';
import { Router } from '../router';

type DeleteMaterialClassSetButtonProps = ButtonProps & {
  materialClassSetId: string;
};

export function DeleteMaterialClassSetButton(
  props: DeleteMaterialClassSetButtonProps,
) {
  const {
    materialClassSetId,
    leftIcon = <DeleteIcon />,
    color = 'red',
    ...buttonProps
  } = props;
  const deleteMutation = useDeleteMaterialClassSet();

  return (
    <Button
      leftIcon={leftIcon}
      color={color}
      onClick={() => {
        deleteMutation.mutate(materialClassSetId, {
          onError: () => {
            showNotification({
              title: 'Error Deleting Material Class Set',
              message:
                'An error occurred deleting this material class set. You changes may have not applied.',
              color: 'red',
              icon: <IconX />,
            });
          },
          onSuccess: () => {
            showNotification({
              title: 'Material Class Set Deleted',
              message: 'The material class set was successfully deleted.',
              color: 'green',
              icon: <IconCheck />,
            });
            Router.push('MaterialClassList');
          },
          onSettled: () => {
            deleteMutation.reset();
          },
        });
      }}
      {...buttonProps}
    >
      Delete
    </Button>
  );
}
