import { WithUnit } from '../WithUnit';
import { QuantizedWeightDTO } from '../rest-client';
import { getWeightUnitAbbreviation } from './units';

export interface QuantizedWeightProps {
  weight: QuantizedWeightDTO;
  plusMinus?: boolean;
}

export default function QuantizedWeight(props: QuantizedWeightProps) {
  const { weight, plusMinus = false } = props;

  return (
    <WithUnit unit={getWeightUnitAbbreviation(weight.unit)}>
      {weight.humanReadableCentralValue}
      {plusMinus ? `± ${weight.tickSize}}` : ''}
    </WithUnit>
  );
}
