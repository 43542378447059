import { Alert, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { useFacilityContext } from '../../Facility/FacilityContext';
import { SortSystemName } from '../../SortSystem/SortSystemName';
import {
  ContainerClaimFlowConcurrencyWarningDTO,
  TransactionClaimConcurrencyErrorDTO,
} from '../../rest-client';
import { InventoryLedgerErrorExplanationRow } from '../InventoryLedgerErrorExplanationRow';
import { ClaimStatus, ScaleReadingStatus } from '../LedgerStatusContext';

export function ContainerClaimStatusExplanationRow({
  status,
}: {
  status: ClaimStatus | ScaleReadingStatus;
}) {
  const explanation = match(status)
    .with({ status: 'orphaned' }, () => (
      <ContainerClaimOrphanedErrorExplanationRow />
    ))
    .with({ status: 'conflict' }, ({ errors }) => (
      <>
        {errors.map((error, i) => (
          <ContainerClaimConflictErrorExplanationRow error={error} key={i} />
        ))}
      </>
    ))
    .with({ status: 'warning' }, ({ warnings }) => (
      <>
        {warnings.map((warning, i) => (
          <ContainerClaimWarningExplanationRow warning={warning} key={i} />
        ))}
      </>
    ))
    .otherwise(() => null);
  if (explanation == null) {
    return undefined;
  }
  return (
    <InventoryLedgerErrorExplanationRow>
      {explanation}
    </InventoryLedgerErrorExplanationRow>
  );
}

export function ContainerClaimWarningExplanationRow({
  warning,
}: {
  warning: ContainerClaimFlowConcurrencyWarningDTO;
}) {
  const { timeZoneId } = useFacilityContext();
  const startTime = dayjs.utc(warning.outputFlowInterval.start).tz(timeZoneId);
  const endTime = dayjs.utc(warning.outputFlowInterval.end).tz(timeZoneId);
  return (
    <Alert color='orange' title='Container Claim Flow Concurrency Warning'>
      This container claim overlaps with material flow on{' '}
      <SortSystemName sortSystem={warning.outputFlowInterval.sortSystem} />
      <Text mx='0.5ch' weight={700} span>
        •
      </Text>
      {warning.outputFlowInterval.systemPortName} on {startTime.format('ll')}{' '}
      between {startTime.format('LT')} and {endTime.format('LT')}. A claim on a
      container during an interval where material was flowing into that
      container may result in the claim being associated to the wrong material.
    </Alert>
  );
}

export function ContainerClaimOrphanedErrorExplanationRow() {
  return (
    <Alert color='red' title='Orphaned Container Claim Error'>
      This container claim is not attached to a valid container (e.g. the
      container may have been empty when the sample was taken).
    </Alert>
  );
}

export function ContainerClaimConflictErrorExplanationRow(props: {
  error: TransactionClaimConcurrencyErrorDTO;
}) {
  // TODO(1809): Link to transaction
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error } = props;

  return (
    <Alert color='red' title='Transaction-Claim Concurrency Conflict'>
      This container claim was made on the material in the container at the
      exact same time that a transaction occurred involving the container. A
      container cannot have a container claim made at the same time that a
      transaction occurs.
    </Alert>
  );
}
