import { Alert, Anchor, Group, Loader, Text } from '@mantine/core';
import {
  IconAlertTriangle,
  IconCircleArrowRightFilled,
  IconCircleCheckFilled,
} from '@tabler/icons-react';
import { useCallback } from 'react';
import { match } from 'ts-pattern';
import { useFacilityContext } from '../Facility/FacilityContext.tsx';
import Temporal from '../Temporal/temporal.ts';
import { useInventoryLedgerStatus } from '../api/transaction.ts';
import type {
  BufferDepletionErrorDTO,
  BufferRestorationErrorDTO,
  ContainerClaimFlowConcurrencyWarningDTO,
  MaterialTransactionApplicationErrorDTO,
  OrphanedClaimsDTO,
  OutputContainerChangeErrorDTO,
  TransactionFlowConcurrencyWarningDTO,
} from '../rest-client/index.ts';
import classes from './css/TransactionLedgerStatus.module.css';

const ErrorEntryLink = ({
  entryId,
  handleErrorClick,
  date,
  variant = 'error',
  label,
}: {
  entryId: string;
  date: Temporal.PlainDate;
  handleErrorClick: ({
    entryId,
    date,
  }: {
    entryId: string;
    date: Temporal.PlainDate;
  }) => void;
  variant?: 'error' | 'warning';
  label?: string;
}) => (
  <Anchor
    c={variant === 'error' ? 'red' : 'orange'}
    href={`#entry-${entryId}`}
    onClick={(evt) => {
      evt.preventDefault();
      handleErrorClick({
        date,
        entryId,
      });
    }}
  >
    Jump to {label ?? variant} (
    {date.toLocaleString(undefined, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })}
    )
    <span className={classes.errorLinkIconWrapper}>
      <IconCircleArrowRightFilled width='1rem' height='1rem' />
    </span>
  </Anchor>
);

const getTransactionErrorDetails = ({
  transactionErr,
  timeZoneId,
}: {
  transactionErr: MaterialTransactionApplicationErrorDTO;
  timeZoneId: string;
}) => {
  const { ledgerId, effectiveTimestamp } = match(transactionErr)
    .with(
      { kind: 'MaterialTransactionConflict' },
      (trnsErr) => trnsErr.transactionA,
    )
    .with(
      { kind: 'AdditionFullDestination' },
      (trnsErr) => trnsErr.failedTransaction,
    )
    .with(
      { kind: 'ExtractionEmptySource' },
      (trnsErr) => trnsErr.failedTransaction,
    )
    .with(
      { kind: 'TransactionClaimConcurrency' },
      (trnsErr) => trnsErr.transaction,
    )
    .exhaustive();
  return {
    entryId: ledgerId,
    date: Temporal.Instant.from(effectiveTimestamp)
      .toZonedDateTimeISO(timeZoneId)
      .toPlainDate(),
  };
};

type ClaimType = OrphanedClaimsDTO[keyof OrphanedClaimsDTO][number];

const getOutputContainerChangeErrorDetails = ({
  outputContainerChangeError,
  timeZoneId,
}: {
  outputContainerChangeError: OutputContainerChangeErrorDTO;
  timeZoneId: string;
}) => {
  const { id, effectiveTimestamp } = match(outputContainerChangeError)
    .with(
      { kind: 'SimultaneousOutputContainerChangeError' },
      (error) => error.outputContainerChangeA,
    )
    .exhaustive();
  return {
    entryId: id,
    date: Temporal.Instant.from(effectiveTimestamp)
      .toZonedDateTimeISO(timeZoneId)
      .toPlainDate(),
  };
};

const getProcessBufferRestorationErrorDetails = ({
  bufferRestorationError,
  timeZoneId,
}: {
  bufferRestorationError: BufferRestorationErrorDTO;
  timeZoneId: string;
}) => {
  const { id, effectiveTimestamp } = match(bufferRestorationError)
    .with(
      { kind: 'DuplicateBufferRestorationError' },
      (error) => error.duplicateBufferRestoration,
    )
    .otherwise((error) => {
      throw new Error(
        'Buffer restoration error of kind ' +
          error.kind +
          ' has not been implemented',
      );
    });
  return {
    entryId: id,
    date: Temporal.Instant.from(effectiveTimestamp)
      .toZonedDateTimeISO(timeZoneId)
      .toPlainDate(),
  };
};

const getProcessBufferDepletionErrorDetails = ({
  bufferDepletionError,
  timeZoneId,
}: {
  bufferDepletionError: BufferDepletionErrorDTO;
  timeZoneId: string;
}) => {
  const { id, effectiveTimestamp } = match(bufferDepletionError)
    .with(
      { kind: 'SimultaneousBufferDepletionError' },
      (error) => error.depletionA,
    )
    .exhaustive();
  return {
    entryId: id,
    date: Temporal.Instant.from(effectiveTimestamp)
      .toZonedDateTimeISO(timeZoneId)
      .toPlainDate(),
  };
};

const getTransactionFlowConcurrencyWarningDetails = ({
  transactionWarning,
  timeZoneId,
}: {
  transactionWarning: TransactionFlowConcurrencyWarningDTO;
  timeZoneId: string;
}) => {
  const { ledgerId, effectiveTimestamp } = transactionWarning.transaction;
  return {
    entryId: ledgerId,
    date: Temporal.Instant.from(effectiveTimestamp)
      .toZonedDateTimeISO(timeZoneId)
      .toPlainDate(),
  };
};

const getContainerClaimFlowConcurrencyWarningDetails = ({
  containerClaimWarning,
  timeZoneId,
}: {
  containerClaimWarning: ContainerClaimFlowConcurrencyWarningDTO;
  timeZoneId: string;
}) => {
  const { id } = containerClaimWarning.affectedContainer;
  const { effectiveTimestamp } = containerClaimWarning;
  return {
    entryId: id,
    date: Temporal.Instant.from(effectiveTimestamp)
      .toZonedDateTimeISO(timeZoneId)
      .toPlainDate(),
  };
};

export function InventoryLedgerStatus({
  findErrorEntry,
}: {
  findErrorEntry: ({
    entryId,
    date,
  }: {
    entryId: string;
    date: Temporal.PlainDate;
  }) => void;
}) {
  const { timeZoneId } = useFacilityContext();
  const {
    data: ledgerStatusData,
    isFetching,
    isLoading,
    isError,
  } = useInventoryLedgerStatus();
  const handleErrorClick = useCallback(
    ({ date, entryId }: { date: Temporal.PlainDate; entryId: string }) => {
      findErrorEntry({
        entryId,
        date,
      });
    },
    [findErrorEntry],
  );

  if (isFetching || isLoading) {
    return (
      <Group spacing='xs'>
        <Loader size='sm' variant='dots' />
        <Text color='dimmed' size='sm'>
          Checking for problems
        </Text>
      </Group>
    );
  }

  if (isError) {
    return (
      <Text color='red' size='sm'>
        Error checking inventory ledger status
      </Text>
    );
  }

  return match(ledgerStatusData)
    .with({ status: 'valid' }, () => (
      <Text c='green'>
        No ledger errors{' '}
        <IconCircleCheckFilled
          height={'1em'}
          width={'1em'}
          style={{ top: '0.125em', position: 'relative' }}
        />
      </Text>
    ))
    .with({ status: 'warning' }, (warningStatus) => {
      const {
        transactionFlowConcurrencyWarnings,
        containerClaimFlowConcurrencyWarnings,
        commodityMixingTransactions,
      } = warningStatus;

      if (
        transactionFlowConcurrencyWarnings.length === 0 &&
        containerClaimFlowConcurrencyWarnings.length === 0
      ) {
        throw new Error(
          'Ledger warning but does not contain any transaction flow conccurrency warning or container claim flow concurrency warnings',
        );
      }

      let txnFlowConcurrencyWarningContent = null;
      if (transactionFlowConcurrencyWarnings.length > 0) {
        const latestTxnFlowConcurrencyWarning =
          transactionFlowConcurrencyWarnings[0];
        const { entryId, date } = getTransactionFlowConcurrencyWarningDetails({
          transactionWarning: latestTxnFlowConcurrencyWarning,
          timeZoneId,
        });
        txnFlowConcurrencyWarningContent = (
          <li key={entryId}>
            <ErrorEntryLink
              entryId={entryId}
              date={date}
              handleErrorClick={handleErrorClick}
              variant='warning'
              label='latest transaction-flow concurrency warning'
            />
          </li>
        );
      }

      const latestCommodityMixingTxn = commodityMixingTransactions.at(-1);
      let txnCommodityMixingWarningContent = null;
      if (latestCommodityMixingTxn) {
        txnCommodityMixingWarningContent = (
          <li key={latestCommodityMixingTxn.ledgerId}>
            <ErrorEntryLink
              entryId={latestCommodityMixingTxn.ledgerId}
              date={Temporal.Instant.from(
                latestCommodityMixingTxn.effectiveTimestamp,
              )
                .toZonedDateTimeISO(timeZoneId)
                .toPlainDate()}
              handleErrorClick={handleErrorClick}
              variant='warning'
              label='latest commodity mixing warning'
            />
          </li>
        );
      }

      let containerClaimWarningContent = null;
      if (containerClaimFlowConcurrencyWarnings.length > 0) {
        const containerClaimWarning = containerClaimFlowConcurrencyWarnings[0];
        const { entryId, date } =
          getContainerClaimFlowConcurrencyWarningDetails({
            containerClaimWarning,
            timeZoneId,
          });
        containerClaimWarningContent = (
          <li key={entryId}>
            <ErrorEntryLink
              entryId={entryId}
              date={date}
              handleErrorClick={handleErrorClick}
              variant='warning'
              label='latest claim-flow concurrency warning'
            />
          </li>
        );

        return (
          <Alert
            className={classes.errorStatus}
            color='orange'
            title={
              <Text>
                <span className={classes.alertIconWrapper}>
                  <IconAlertTriangle />
                </span>
                Ledger has{' '}
                {transactionFlowConcurrencyWarnings.length +
                  containerClaimFlowConcurrencyWarnings.length}{' '}
                warnings
              </Text>
            }
          >
            <div className={classes.warningStatusDescription}>
              <Text className={classes.textContent}>
                Your data may be inaccurate until resolved.
              </Text>

              <ol className={classes.errorList}>
                {txnCommodityMixingWarningContent}
                {txnFlowConcurrencyWarningContent}
                {containerClaimWarningContent}
              </ol>
            </div>
          </Alert>
        );
      }
    })
    .with({ status: 'invalid' }, (invalidStatus) => {
      const {
        transactionErrors,
        orphanedClaims,
        outputContainerChangeErrors,
        bufferErrors,
      } = invalidStatus;
      let ledgerErrorsCount = 0;

      if (
        !transactionErrors &&
        !orphanedClaims &&
        !outputContainerChangeErrors &&
        !bufferErrors
      ) {
        throw new Error(
          'Ledger invalid but does not contain any transaction errors, orphaned claims, output container change errors, or buffer errors',
        );
      }

      const transactionErr = transactionErrors?.transactionApplicationErrors[0];
      let transactionErrorsContent = null;
      if (transactionErr) {
        const { entryId, date } = getTransactionErrorDetails({
          transactionErr,
          timeZoneId,
        });
        transactionErrorsContent = (
          <li key={entryId}>
            <ErrorEntryLink
              entryId={entryId}
              date={date}
              handleErrorClick={handleErrorClick}
              variant='error'
              label='transaction error'
            />
          </li>
        );
        ledgerErrorsCount +=
          transactionErrors.transactionApplicationErrors.length;
      }

      let orphanedMaterialDataContent = null;
      if (orphanedClaims) {
        const flattenedOrphanedClaimLedgerInfo = Object.values(
          orphanedClaims,
        ).flatMap((claimTypeSubset: ClaimType[]) =>
          claimTypeSubset.map((claim: ClaimType) => ({
            entryId: claim.ledgerId,
            date: Temporal.Instant.from(claim.effectiveTimestamp)
              .toZonedDateTimeISO(timeZoneId)
              .toPlainDate(),
          })),
        );

        orphanedMaterialDataContent = flattenedOrphanedClaimLedgerInfo.map(
          ({ entryId, date }) => (
            <li key={entryId}>
              <ErrorEntryLink
                entryId={entryId}
                date={date}
                handleErrorClick={handleErrorClick}
                variant='error'
                label='orphaned container claim'
              />
            </li>
          ),
        );
        ledgerErrorsCount += flattenedOrphanedClaimLedgerInfo.length;
      }

      let outputContainerChangeErrorsContent = null;
      const outputContainerChangeError = outputContainerChangeErrors
        ? outputContainerChangeErrors[0]
        : null;
      if (outputContainerChangeError) {
        const { entryId, date } = getOutputContainerChangeErrorDetails({
          outputContainerChangeError,
          timeZoneId,
        });
        outputContainerChangeErrorsContent = (
          <li key={entryId}>
            <ErrorEntryLink
              entryId={entryId}
              date={date}
              handleErrorClick={handleErrorClick}
              variant='error'
              label='output container change error'
            />
          </li>
        );
        ledgerErrorsCount += outputContainerChangeErrors?.length ?? 0;
      }

      let bufferRestorationErrorsContent = null;
      let bufferDepletionErrorsContent = null;
      if (bufferErrors) {
        const { bufferRestorationErrors, bufferDepletionErrors } = bufferErrors;
        const bufferRestorationError =
          bufferRestorationErrors.length > 0
            ? bufferRestorationErrors[0]
            : null;
        const bufferDepletionError =
          bufferDepletionErrors.length > 0 ? bufferDepletionErrors[0] : null;
        if (bufferRestorationError) {
          const { entryId, date } = getProcessBufferRestorationErrorDetails({
            bufferRestorationError,
            timeZoneId,
          });
          bufferRestorationErrorsContent = (
            <li key={entryId}>
              <ErrorEntryLink
                entryId={entryId}
                date={date}
                handleErrorClick={handleErrorClick}
                variant='error'
                label='feedstock restoration error'
              />
            </li>
          );
          ledgerErrorsCount += bufferDepletionErrors.length;
        }
        if (bufferDepletionError) {
          const { entryId, date } = getProcessBufferDepletionErrorDetails({
            bufferDepletionError,
            timeZoneId,
          });
          bufferDepletionErrorsContent = (
            <li key={entryId}>
              <ErrorEntryLink
                entryId={entryId}
                date={date}
                handleErrorClick={handleErrorClick}
                variant='error'
                label='feedstock depletion error'
              />
            </li>
          );
          ledgerErrorsCount += bufferDepletionErrors.length;
        }
      }

      return (
        <Alert
          className={classes.errorStatus}
          color='red'
          title={
            <Text>
              <span className={classes.alertIconWrapper}>
                <IconAlertTriangle />
              </span>
              Ledger has {ledgerErrorsCount} errors
            </Text>
          }
        >
          <div className={classes.errorStatusDescription}>
            <Text className={classes.textContent}>
              Your data will be missing or inaccurate until resolved.
            </Text>

            <ol className={classes.errorList}>
              {transactionErrorsContent}
              {orphanedMaterialDataContent}
              {outputContainerChangeErrorsContent}
              {bufferRestorationErrorsContent}
              {bufferDepletionErrorsContent}
            </ol>
          </div>
        </Alert>
      );
    })
    .exhaustive();
}
