import { Alert, Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { RequiredDayjsDateTimePicker } from '../DayjsDateTimePicker';
import { useFacilityContext } from '../Facility/FacilityContext';
import { FormActionButton } from '../Form/FormActionButton';
import ScaleDisplayDivisionsInput from '../Weights/ScaleDisplayDivisionsInput';
import { usePatchScaleReading, useScale, useScaleReading } from '../api/scale';
import { useSetFormInitialValuesFromQuery } from '../lib/useSetFormInitialValuesFromQuery';
import {
  ScaleId,
  ScaleReadingId,
  ScaleReadingPatchDTO,
  WeightUnit,
} from '../rest-client';

export interface EditScaleReadingFormValues {
  displayDivisions: number | null;
  timestamp: Dayjs | null;
}

export interface EditScaleReadingFormProps {
  scaleId: ScaleId;
  scaleReadingId: ScaleReadingId;
  onCancel: () => void;
  onSuccess: () => void;
}

export function EditScaleReadingForm(props: EditScaleReadingFormProps) {
  const { scaleId, scaleReadingId, onCancel, onSuccess } = props;

  const facility = useFacilityContext();
  const mutation = usePatchScaleReading();
  const scaleQuery = useScale(scaleId);
  const scaleReadingQuery = useScaleReading(scaleId, scaleReadingId);

  const form = useForm<EditScaleReadingFormValues>({
    initialValues: {
      displayDivisions: null,
      timestamp: null,
    },
    validate: {
      displayDivisions: (divs) =>
        divs === null
          ? 'Gross weight is required'
          : divs <= 0
            ? 'Gross weight must be > 0'
            : null,
    },
  });

  useSetFormInitialValuesFromQuery(
    form,
    scaleReadingQuery.data && {
      displayDivisions: scaleReadingQuery.data.displayDivisions,
      timestamp: dayjs
        .utc(scaleReadingQuery.data.timestamp)
        .tz(facility.timeZoneId),
    },
  );

  const onSubmit = form.onSubmit(({ displayDivisions, timestamp }) => {
    if (!scaleReadingQuery.data) {
      throw new Error('Scale reading must already exist');
    }

    const patch: ScaleReadingPatchDTO = {
      ...(form.isDirty('displayDivisions') && {
        displayDivisions: displayDivisions ?? undefined,
      }),
      ...(form.isDirty('timestamp') && {
        timestamp: timestamp ? timestamp.utc().toISOString() : undefined,
      }),
    };

    mutation.mutate(
      { reading: scaleReadingQuery.data, patch },
      {
        onSuccess() {
          onSuccess();
          showNotification({
            title: 'Scale Reading Updated',
            message: `Scale reading was successfully updated.`,
            color: 'green',
            icon: <IconCheck />,
          });
        },
        onError() {
          showNotification({
            title: 'Error Editing Scale Reading',
            message: `An error occurred editing the scale reading`,
            color: 'red',
            icon: <IconX />,
          });
        },
      },
    );
  });

  if (scaleQuery.isLoadingError) {
    throw scaleQuery.error;
  }

  if (scaleReadingQuery.isLoadingError) {
    throw scaleReadingQuery.error;
  }

  if (mutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Updating Scale Reading'
        withCloseButton
        onClose={() => mutation.reset()}
      >
        Your changes to the scale reading may or may not have been saved. You
        can clear the error and try again.
      </Alert>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack>
        <ScaleDisplayDivisionsInput
          label='Gross Weight'
          description='Gross weight of container, including the tare weight plus the container contents'
          disabled={!scaleQuery.data || !mutation.isIdle}
          required
          maw='20ch'
          scale={
            scaleQuery.data ?? { displayDivision: 1, unit: WeightUnit.POUND }
          }
          {...form.getInputProps('displayDivisions')}
        />
        <RequiredDayjsDateTimePicker
          tz={facility.timeZoneId}
          withAsterisk
          label='Scale Reading Time'
          description='What time the scale reading occurred at'
          {...form.getInputProps('timestamp')}
        />
        <Group position='right'>
          <FormActionButton action='cancel' onClick={onCancel} />
          <FormActionButton
            type='submit'
            action='saveEdits'
            loading={mutation.isLoading}
          >
            Save Scale Reading
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
