import { NumberInput, NumberInputProps } from '@mantine/core';

export type TickInputProps = Omit<NumberInputProps, 'value' | 'onChange'> & {
  tickSize: number;
  ticks: number | null;
  onTicksChange: (ticks: number | null) => void;
};

/**
 * A numerical input that rounds to the nearest tick and maps to the number of ticks.
 * For example, with tickSize of 2, the input 6.1 would get mapped to 3.
 */
export default function TickInput(props: TickInputProps) {
  const { ticks, onTicksChange, tickSize, ...rest } = props;

  if (tickSize === 0) {
    throw new Error('tick size cannot be 0');
  }

  return (
    <NumberInput
      styles={{ input: { textAlign: 'right' } }}
      step={tickSize}
      value={ticks === null ? '' : ticks * tickSize}
      onChange={(v) =>
        onTicksChange(v === '' ? null : Math.round(v / tickSize))
      }
      {...rest}
    />
  );
}
