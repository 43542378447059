import { Center, Loader, Stack, Title } from '@mantine/core';
import { AppPage } from '../App/AppPage';
import { ContainerSamplingIcon } from '../Icons';
import { LinkButton } from '../Link';
import { MaterialClassSetName } from '../MaterialClassSet/MaterialClassSetName';
import { useMaterialClassSetsContainerSampleAnalyses } from '../api/containerSampleAnalysis';
import { Router } from '../router';
import { MaterialClassSetContainerSampleAnalysesTable } from './MaterialClassSetContainerSampleAnalysesTable';
import { UnanalyzedContainerSamplesTable } from './UnanalyzedContainerSamplesTable';

export function ContainerSamplingPAge() {
  const { isLoading, data, isError, error } =
    useMaterialClassSetsContainerSampleAnalyses();

  if (isError) {
    throw error;
  }
  if (isLoading) {
    return (
      <Center>
        <Loader variant='bars' size='lg' />
      </Center>
    );
  }

  const {
    unanalyzedContainerSamples,
    materialClassSetContainerSampleAnalyses,
  } = data;

  return (
    <AppPage
      title='Container Samples'
      titleRight={
        <LinkButton
          leftIcon={<ContainerSamplingIcon />}
          to={Router.ContainerSampleCreate()}
        >
          Record New Sample
        </LinkButton>
      }
    >
      {Object.values(materialClassSetContainerSampleAnalyses).map(
        (materialClassSetContainerSampleAnalysis) =>
          materialClassSetContainerSampleAnalysis.containerSampleAnalyses
            .length > 0 ? (
            <AppPage.Section
              key={materialClassSetContainerSampleAnalysis.materialClassSet.id}
            >
              <Stack>
                <Title order={2}>
                  <MaterialClassSetName
                    materialClassSet={
                      materialClassSetContainerSampleAnalysis.materialClassSet
                    }
                  />{' '}
                  Container Sample Analyses
                </Title>
                <MaterialClassSetContainerSampleAnalysesTable
                  materialClassSetContainerSampleAnalyses={
                    materialClassSetContainerSampleAnalysis
                  }
                />
              </Stack>
            </AppPage.Section>
          ) : undefined,
      )}
      <AppPage.Section>
        <Stack>
          <Title order={2}>Unanalyzed Container Samples</Title>
          <UnanalyzedContainerSamplesTable
            unanalyzedContainerSamples={unanalyzedContainerSamples}
          />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
