/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RecoveryStrategyCreationDTO } from '../models/RecoveryStrategyCreationDTO';
import type { RecoveryStrategyDTO } from '../models/RecoveryStrategyDTO';
import type { RecoveryStrategyPatchDTO } from '../models/RecoveryStrategyPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RecoveryStrategyService {
    /**
     * @returns RecoveryStrategyDTO Success
     * @throws ApiError
     */
    public static getRecoveryStrategies(): CancelablePromise<Array<RecoveryStrategyDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/recovery-strategies',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createRecoveryStrategy(
        requestBody: RecoveryStrategyCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/recovery-strategies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param recoveryStrategyGuid
     * @returns RecoveryStrategyDTO Success
     * @throws ApiError
     */
    public static getRecoveryStrategy(
        recoveryStrategyGuid: string,
    ): CancelablePromise<RecoveryStrategyDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/recovery-strategies/{recoveryStrategyGuid}',
            path: {
                'recoveryStrategyGuid': recoveryStrategyGuid,
            },
        });
    }
    /**
     * @param recoveryStrategyGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchRecoveryStrategy(
        recoveryStrategyGuid: string,
        requestBody: RecoveryStrategyPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/recovery-strategies/{recoveryStrategyGuid}',
            path: {
                'recoveryStrategyGuid': recoveryStrategyGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param recoveryStrategyGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteRecoveryStrategy(
        recoveryStrategyGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/recovery-strategies/{recoveryStrategyGuid}',
            path: {
                'recoveryStrategyGuid': recoveryStrategyGuid,
            },
        });
    }
}
