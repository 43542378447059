import {
  ActionIcon,
  Alert,
  Button,
  Center,
  Group,
  HoverCard,
  List,
  Loader,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  IconArrowBarToRight,
  IconCheck,
  IconInfoCircle,
  IconX,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { P, match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { ContainerIdName } from '../Container/ContainerIdName';
import { useFacilityContext } from '../Facility/FacilityContext';
import { GenealogyExplorer } from '../GenealogyExplorer/GenealogyExplorer';
import {
  DeleteIcon,
  FullContainerIcon,
  PartiallyFullContainerIcon,
} from '../Icons';
import { InternalMaterialSourceIdName } from '../InternalMaterialSource/InternalMaterialSourceIdName';
import { TruckLoadIdName } from '../TruckLoad/TruckLoadIdName';
import QuantizedWeight from '../Weights/QuantizedWeight';
import {
  useInternallySourcedMaterial,
  useInternallySourcedMaterialGenealogy,
} from '../api/internallySourcedMaterial';
import {
  useDeleteInternallySourcedMaterialPartition,
  useInternallySourcedMaterialPartitionStatus,
} from '../api/internallySourcedMaterialPartition';
import { LabeledValue } from '../common';
import {
  InternallySourcedMaterialDTO,
  InternallySourcedMaterialId,
  InternallySourcedMaterialPartitionDTO,
} from '../rest-client';
import { Router } from '../router';
import { DeleteInternallySoucedMaterialButton } from './DeleteInternallySourcedMaterialButton';
import EditInternallySourcedMaterialButton from './EditInternallySourcedMaterialButton';
import { InternallySourcedMaterialPartitionForm } from './InternallySourcedMaterialPartitionForm';

export function InternallySourcedMaterialDetailPage({
  internallySourcedMaterialId,
}: {
  internallySourcedMaterialId: InternallySourcedMaterialId;
}) {
  const internallySourcedMaterialQuery = useInternallySourcedMaterial(
    internallySourcedMaterialId,
  );
  const internallySourcedMaterial = internallySourcedMaterialQuery.data;

  const partitonStatusQuery = useInternallySourcedMaterialPartitionStatus(
    internallySourcedMaterialId,
  );

  if (partitonStatusQuery.isLoadingError) {
    throw partitonStatusQuery.error;
  }

  return (
    <AppPage
      breadcrumbs={[
        {
          title: internallySourcedMaterial ? (
            <InternalMaterialSourceIdName
              internalMaterialSourceId={
                internallySourcedMaterial.internalMaterialSourceId
              }
              noLink
            />
          ) : null,
          routeName: internallySourcedMaterial
            ? Router.InternalSourceDetail({
                internalMaterialSourceId:
                  internallySourcedMaterial.internalMaterialSourceId,
              })
            : Router.InternalSourceList(),
        },
      ]}
      title={internallySourcedMaterialQuery.data?.name}
    >
      {/* TODO(2317): Style loader */}
      {partitonStatusQuery.isLoading ? <Loader /> : undefined}

      {/* If this has been partitioned, then we show the genealogy graph and a link to the partition itself */}
      {partitonStatusQuery.data?.status === 'partitioned' ? (
        <>
          <SimpleGrid cols={2}>
            <DetailSection
              internallySourcedMaterial={internallySourcedMaterial}
            />
            <PartitionDetailSection
              partition={partitonStatusQuery.data.partition}
            />
          </SimpleGrid>

          <InternallySourcedMaterialGenealogyExplorer
            internallySourcedMaterialId={internallySourcedMaterialId}
          />
        </>
      ) : undefined}

      {/* If it has not yet been partitioned, we show the form to partition it */}
      {partitonStatusQuery.data?.status === 'unpartitioned' ? (
        <>
          <DetailSection
            internallySourcedMaterial={internallySourcedMaterial}
          />
          <AppPage.Section>
            <Stack maw={700}>
              <Group>
                <Title order={3}>Add Partition Transfer</Title>
                <HoverCard width={250} shadow='md'>
                  <HoverCard.Target>
                    <IconInfoCircle style={{ marginBottom: '-0.2em' }} />
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <Text size='sm' weight='normal'>
                      This upstream-sourced material has not been partitioned to
                      transfer its contents into a container. Add a partition
                      transfer below to view the material details of this
                      material. One or more containers or truck loads can be
                      selected as the partition destination(s), but you cannot
                      partition a upstream-sourced material into both containers
                      and truck loads.
                    </Text>
                  </HoverCard.Dropdown>
                </HoverCard>
              </Group>

              <InternallySourcedMaterialPartitionForm
                internallySourcedMaterial={internallySourcedMaterial}
              />
            </Stack>
          </AppPage.Section>
        </>
      ) : undefined}
    </AppPage>
  );
}

function InternallySourcedMaterialGenealogyExplorer(props: {
  internallySourcedMaterialId: string;
}) {
  const { internallySourcedMaterialId } = props;
  const genealogyQuery = useInternallySourcedMaterialGenealogy(
    internallySourcedMaterialId,
  );
  return <GenealogyExplorer genealogyQuery={genealogyQuery} />;
}

function DetailSection(props: {
  internallySourcedMaterial: InternallySourcedMaterialDTO | undefined;
}) {
  const { internallySourcedMaterial } = props;

  const facility = useFacilityContext();

  return (
    <>
      <AppPage.Section>
        <Stack>
          <Group position='apart'>
            <Title order={3}>Upstream Sourced Material Details</Title>
            {internallySourcedMaterial && (
              <Group>
                <EditInternallySourcedMaterialButton
                  editInternallySourcedMaterialDrawerFormProps={{
                    internallySourcedMaterialId: internallySourcedMaterial.id,
                  }}
                />
                <DeleteInternallySoucedMaterialButton
                  internallySourcedMaterialId={internallySourcedMaterial.id}
                />
              </Group>
            )}
          </Group>
          <Group spacing='xl'>
            <LabeledValue label='Created'>
              <Skeleton visible={internallySourcedMaterial === undefined}>
                {match(internallySourcedMaterial?.creationTime)
                  .with(undefined, () => <Text>Loading...</Text>)
                  .with(P.string, (creationTime) => (
                    <Text>
                      {dayjs
                        .utc(creationTime)
                        .tz(facility.timeZoneId)
                        .format('LLLL')}
                    </Text>
                  ))
                  .exhaustive()}
              </Skeleton>
            </LabeledValue>
            <LabeledValue label='Mass'>
              <Skeleton visible={internallySourcedMaterial === undefined}>
                {match(internallySourcedMaterial?.weight)
                  .with(undefined, () => <Text>Loading...</Text>)
                  .with(null, () => <Text c='dimmed'>Unknown</Text>)
                  .otherwise((weight) => (
                    <QuantizedWeight weight={weight} />
                  ))}
              </Skeleton>
            </LabeledValue>
          </Group>
        </Stack>
      </AppPage.Section>
    </>
  );
}

function PartitionDetailSection(props: {
  partition: InternallySourcedMaterialPartitionDTO;
}) {
  const { partition } = props;

  const [showDeletePromp, setShowDeletePrompt] = useState(false);

  const deleteMutation = useDeleteInternallySourcedMaterialPartition();

  if (deleteMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Deleting Transfer'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => deleteMutation.reset()}
      >
        The transfer may or may not have been deleted. You can clear the error
        and try again.
      </Alert>
    );
  }

  if (showDeletePromp) {
    return (
      <Alert color='red' variant='filled' title='Confirm Transfer Deletion'>
        Deleting this transfer is irreversible and may result in ledger errors.
        Are you sure you want to delete it?
        <Group position='apart'>
          <Button
            variant='light'
            color='red'
            loading={deleteMutation.isLoading}
            onClick={() => {
              deleteMutation.mutate(partition.internallySourcedMaterialId, {
                onError() {
                  showNotification({
                    title: 'Error Deleting Transfer',
                    message: 'An error occurred deleting the transfer',
                    color: 'red',
                    icon: <IconX />,
                  });
                },
                onSuccess() {
                  showNotification({
                    title: 'Transfer Deleted',
                    message: 'The transfer was sucessfully deleted',
                    color: 'green',
                    icon: <IconCheck />,
                  });
                },
                onSettled() {
                  setShowDeletePrompt(false);
                },
              });
            }}
          >
            Delete Forever
          </Button>
          <Button color='teal' onClick={() => setShowDeletePrompt(false)}>
            Back to Safety
          </Button>
        </Group>
      </Alert>
    );
  }

  return (
    <AppPage.Section>
      <Stack>
        <Group position='apart'>
          <Title order={3}>Partition Transfer Destinations</Title>
          <ActionIcon
            color='red'
            variant='light'
            onClick={() => {
              setShowDeletePrompt(true);
            }}
          >
            <DeleteIcon />
          </ActionIcon>
        </Group>

        <List icon={<IconArrowBarToRight />}>
          {partition.destinations.map((d, idx) => (
            <List.Item key={idx}>
              {match(d)
                .with(
                  { kind: 'Container' },
                  ({ containerId, destinationFilled }) => (
                    <Group align='center'>
                      <ContainerIdName
                        key={containerId}
                        containerId={containerId}
                        variant='icon-name-link'
                        time={dayjs.utc(partition.effectiveTimestamp)}
                      />
                      <Center>
                        {destinationFilled ? (
                          <FullContainerIcon />
                        ) : (
                          <PartiallyFullContainerIcon />
                        )}
                      </Center>
                    </Group>
                  ),
                )
                .with({ kind: 'TruckLoad' }, ({ truckLoadId }) => (
                  <TruckLoadIdName
                    key={truckLoadId}
                    truckLoadId={truckLoadId}
                    variant='icon-name-link'
                  />
                ))
                .exhaustive()}
            </List.Item>
          ))}
        </List>
      </Stack>
    </AppPage.Section>
  );
}
