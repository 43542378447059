import { Text } from '@mantine/core';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { LinkText } from '../../../Link';
import { FormatTimeWithUnits } from '../../../Temporal/FormatTimeWithUnits';
import {
  DateRange,
  plainDateToStartOfDayInstant,
} from '../../../Temporal/dateRangeAndOffset';
import Temporal from '../../../Temporal/temporal';
import NetWeight from '../../../Weights/NetWeight';
import { NetWeightThroughputWithUnits } from '../../../Weights/NetWeightThroughputWithUnits';
import { useProducedCommodityMasses } from '../../../api/commodity';
import {
  FacilityMassMetricsDTO,
  ProducedCommodityMassesDTO,
} from '../../../rest-client';
import { Router } from '../../../router';
import { useFacilityContext } from '../../FacilityContext';
import { useInsightsSelectedDateRange } from '../DateRange/InsightsDateRange';
import {
  MetricDetailPageTemplate,
  MetricDetailTableColumn,
} from './MetricDetailPageTemplate';

export function getSeriesData(data: FacilityMassMetricsDTO) {
  return data.producedCommodityMasses.map((producedCommodityMass) => {
    const commodityName = producedCommodityMass.commodity.name;
    return {
      name: commodityName,
      entries: producedCommodityMass.weeklyMassThroughputs.map((mt) => ({
        timestamp: Temporal.PlainDate.from(mt.startOfWeekLocalDate)
          .toZonedDateTime({ timeZone: Temporal.Now.timeZoneId() })
          .toInstant()
          .toString(),
        name: commodityName,
        value: mt.poundsPerHour,
      })),
      // TODO(2307): Allow toggling mode between point/day/week/month/etc
      // producedCommodityMass.knownWeightProducingFeedFlowGroups
      // .sort((ffgA, ffgB) =>
      //   ffgA.feedFlowGroupStart < ffgB.feedFlowGroupStart ? -1 : 1,
      // )
      // .map((ffg) => {
      //   return {
      //     timestamp: ffg.feedFlowGroupStart,
      //     name: commodityName,
      //     value: getWeightFromNetWeight(ffg.massMetrics.producedWeight),
      //   };
      // }),
    };
  });
}

export function CommodityMassThroughputMetricPage() {
  const { timeZoneId: tz, id: facilityId } = useFacilityContext();
  const [nowZonedDateTime] = useState(Temporal.Now.zonedDateTimeISO(tz));
  const thirtyDaysAgo = nowZonedDateTime.subtract({ days: 30 });
  const { dateRange, setDateRange } = useInsightsSelectedDateRange({
    startDate: thirtyDaysAgo.toPlainDate(),
    endDate: nowZonedDateTime.toPlainDate(),
    name: 'Last 30 Days',
    id: 'd30',
  });

  const { startDate, endDate } = dateRange;
  const rangeInstants = {
    startInstant: plainDateToStartOfDayInstant(startDate, tz),
    // TODO end of day, probably
    endInstant: plainDateToStartOfDayInstant(endDate, tz),
  };
  const { startInstant, endInstant } = rangeInstants;

  const query = useProducedCommodityMasses({
    after: startInstant,
    before: endInstant,
    facilityId,
  });

  const { seriesData, tableData } = match(query)
    .with({ status: 'loading' }, () => ({
      loading: true,
      error: null,
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'error' }, (query) => ({
      loading: false,
      error: query.error as Error,
      seriesData: [],
      tableData: [],
    }))
    .with({ status: 'success' }, (query) => ({
      loading: false,
      error: null,
      seriesData: getSeriesData(query.data),
      tableData: query.data.producedCommodityMasses,
    }))
    .exhaustive();

  interface RenderColumnArgs {
    commodityMasses: ProducedCommodityMassesDTO;
    dateRange?: DateRange;
  }

  type CommodityMassThroughputColumn = MetricDetailTableColumn & {
    renderTableValue: ({
      commodityMasses,
      dateRange,
    }: Required<RenderColumnArgs>) => JSX.Element;
  };

  const columns: CommodityMassThroughputColumn[] = [
    {
      title: 'Commodity',
      numeric: false,
      renderTableValue: ({ commodityMasses }: Required<RenderColumnArgs>) => (
        <td key='commodity'>
          <LinkText
            to={Router.FacilityInsightsMetricFacetDetail({
              metricName: 'commodity-mass-throughput',
              facetName: commodityMasses.commodity.id,
            })}
          >
            {commodityMasses.commodity.name}
          </LinkText>
        </td>
      ),
    },
    {
      title: 'Total Active Time',
      numeric: true,
      renderTableValue: ({ commodityMasses }: RenderColumnArgs) => (
        <td style={{ textAlign: 'right' }} key='total-active'>
          <FormatTimeWithUnits
            totalMinutes={commodityMasses.totalActiveMinutes}
            verbosity='normal'
          />
        </td>
      ),
    },
    {
      title: 'Produced Weight',
      numeric: true,
      renderTableValue: ({ commodityMasses }: RenderColumnArgs) => (
        <td key='produced-weight' style={{ textAlign: 'right' }}>
          {commodityMasses.massMetrics === null ? (
            <Text c='dimmed'>Unknown</Text>
          ) : (
            <NetWeight
              weight={commodityMasses.massMetrics.producedWeight}
              sourceIconMode='icon-tooltip'
            />
          )}
        </td>
      ),
    },
    {
      title: 'Average Throughput',
      numeric: true,
      renderTableValue: ({ commodityMasses }: RenderColumnArgs) => (
        <td key='average-throughput' style={{ textAlign: 'right' }}>
          {commodityMasses.massMetrics === null ? (
            <Text c='dimmed'>Unknown</Text>
          ) : (
            <NetWeightThroughputWithUnits
              netWeightPerHour={
                commodityMasses.massMetrics.averageProducedWeightPerActiveHour
              }
              verbosity='normal'
            />
          )}
        </td>
      ),
    },
  ];

  function renderTableRow(commodityMasses: ProducedCommodityMassesDTO) {
    return (
      <tr key={commodityMasses.commodity.id}>
        {columns.map((col) => {
          return col.renderTableValue({
            commodityMasses,
            dateRange,
          });
        })}
      </tr>
    );
  }

  return (
    <MetricDetailPageTemplate<ProducedCommodityMassesDTO>
      dateRange={dateRange}
      setDateRange={setDateRange}
      title='Commodity Mass Throughput'
      graphTitle='Commodity Mass Throughput Rate'
      tableTitle='Mass Throughput by Commodity'
      tableColumns={columns}
      tableData={tableData}
      seriesData={seriesData}
      renderTableRow={renderTableRow}
      query={query}
      yAxisLabel='Lbs/Hr'
    />
  );
}
