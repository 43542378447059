import { useMutation, useQuery } from '@tanstack/react-query';
import { SoldMaterialCreationDTO, SoldMaterialService } from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchSoldMaterial(
  ctx: QueryFunctionContexts['soldMaterial']['detail'],
) {
  const [{ soldMaterialId }] = ctx.queryKey;
  return await SoldMaterialService.getSoldMaterialById(soldMaterialId);
}

export function useSoldMaterial(soldMaterialId: string) {
  return useQuery({
    queryKey: queryKeys.soldMaterial.detail(soldMaterialId),
    queryFn: fetchSoldMaterial,
  });
}

async function fetchSoldMaterials(
  ctx: QueryFunctionContexts['soldMaterial']['list'],
) {
  const [{ customerId }] = ctx.queryKey;
  return await SoldMaterialService.allSoldMaterials(customerId);
}

export function useSoldMaterials(customerId?: string) {
  return useQuery({
    queryKey: queryKeys.soldMaterial.list(customerId),
    queryFn: fetchSoldMaterials,
  });
}

async function addSoldMaterial(dto: SoldMaterialCreationDTO) {
  await SoldMaterialService.createSoldMaterial(dto);
}

export function useAddSoldMaterial() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: addSoldMaterial,
    onSettled(data, error, dto) {
      invalidator.invalidateKeys(
        queryKeys.soldMaterial.list(dto.customerId),
        queryKeys.soldMaterial.list(undefined),
      );
    },
  });
}
