/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProcessBufferDepletionCreationDTO } from '../models/ProcessBufferDepletionCreationDTO';
import type { ProcessBufferDepletionDTO } from '../models/ProcessBufferDepletionDTO';
import type { ProcessBufferDepletionPatchDTO } from '../models/ProcessBufferDepletionPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProcessBufferDepletionService {
    /**
     * @param processBufferDepletionGuid
     * @returns ProcessBufferDepletionDTO Success
     * @throws ApiError
     */
    public static getProcessBufferDepletionById(
        processBufferDepletionGuid: string,
    ): CancelablePromise<ProcessBufferDepletionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/process-buffer-depletions/{processBufferDepletionGuid}',
            path: {
                'processBufferDepletionGuid': processBufferDepletionGuid,
            },
        });
    }
    /**
     * @param processBufferDepletionGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchProcessBufferDepletion(
        processBufferDepletionGuid: string,
        requestBody: ProcessBufferDepletionPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/process-buffer-depletions/{processBufferDepletionGuid}',
            path: {
                'processBufferDepletionGuid': processBufferDepletionGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param processBufferDepletionGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteProcessBufferDepletion(
        processBufferDepletionGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/process-buffer-depletions/{processBufferDepletionGuid}',
            path: {
                'processBufferDepletionGuid': processBufferDepletionGuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createProcessBufferDepletion(
        requestBody: ProcessBufferDepletionCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/process-buffer-depletions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
