import { Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCalendar } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CommoditySelect } from '../Commodity/CommoditySelect';
import { DayjsDateInput } from '../DayjsDateTimePicker';
import { useFacilityContext } from '../Facility/FacilityContext';
import { FormActionButton } from '../Form/FormActionButton';
import { useFormNavBlocker } from '../Form/useFormNavBlocker.ts';
import QuantizedWeightTicksInput from '../Weights/QuantizedWeightTicksInput';
import { useAddPurchasedMaterial } from '../api/purchasedMaterial';
import {
  MaterialVendorId,
  PurchasedMaterialCreationDTO,
  WeightUnit,
} from '../rest-client';
import { Router } from '../router';

export interface AddPurchasedMaterialFormValues {
  name: string;
  purchasedDate: Dayjs;
  commodityId: string | null;
  weightUnit: WeightUnit | null;
  weightTickSize: number;
  weightTicks: number | null;
}

export interface AddPurchasedMaterialFormProps {
  vendorId: MaterialVendorId;
}

export function AddPurchasedMaterialForm(props: AddPurchasedMaterialFormProps) {
  const { vendorId } = props;
  const facility = useFacilityContext();

  const id = useMemo(() => uuidv4(), []);

  const form = useFormNavBlocker(
    useForm<AddPurchasedMaterialFormValues>({
      initialValues: {
        name: '',
        commodityId: null,
        weightUnit: WeightUnit.POUND,
        weightTickSize: 1,
        weightTicks: null,
        purchasedDate: dayjs(),
      },
      validate: {
        name: (name) => (name ? null : 'name is required'),
        purchasedDate: (purchasedDate) =>
          purchasedDate.isAfter(dayjs())
            ? 'purchased materials cannot be created in the future'
            : null,
        commodityId: (commodityId) =>
          commodityId ? null : 'Commodity is required',
        weightUnit: (unit) => (unit ? null : 'Unit is required'),
        weightTicks: (ticks) =>
          ticks !== null && ticks <= 0
            ? 'Weight must be a positive number'
            : null,
      },
    }),
  );
  const addPurchasedMaterialMutation = useAddPurchasedMaterial();

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (values.weightUnit === null) {
          throw new Error('unit required');
        }

        if (values.commodityId === null) {
          throw new Error('commodity is required');
        }

        const purchasedMaterial: PurchasedMaterialCreationDTO = {
          id,
          vendorId,
          name: values.name,
          commodityId: values.commodityId,
          weight:
            values.weightTicks === null
              ? null
              : {
                  unit: values.weightUnit,
                  tickSize: `${values.weightTickSize}`,
                  ticks: values.weightTicks,
                },
          creationTime: values.purchasedDate.utc().toISOString(),
        };
        addPurchasedMaterialMutation.mutate(purchasedMaterial, {
          onSuccess(data, variables) {
            Router.replace('VendorPurchasedMaterialDetail', {
              vendorId,
              purchasedMaterialId: variables.id,
            });
          },
        });
      })}
    >
      <Stack maw={500}>
        <TextInput
          label='Purchase Name'
          placeholder='PO [purchase number], e.g. PO 00001'
          withAsterisk
          {...form.getInputProps('name')}
        />
        <DayjsDateInput
          label='Date Purchased'
          icon={<IconCalendar size={16} />}
          placeholder='Select date'
          maxDate={dayjs()}
          withAsterisk
          tz={facility.timeZoneId}
          {...form.getInputProps('purchasedDate')}
        />

        <CommoditySelect {...form.getInputProps('commodityId')} />

        {/* TODO(1405): Allow tick size and unit to be changed  */}
        <QuantizedWeightTicksInput
          label='Net Weight'
          description='The net weight of the purchased material'
          unit={form.values.weightUnit ?? WeightUnit.POUND}
          tickSize={form.values.weightTickSize}
          maw='20ch'
          {...form.getInputProps('weightTicks')}
        />

        <Group position='right'>
          <FormActionButton
            action='cancel'
            onClick={() => Router.push('VendorDetail', { vendorId })}
            loading={addPurchasedMaterialMutation.isLoading}
          />
          <FormActionButton
            type='submit'
            action='saveCreation'
            loading={addPurchasedMaterialMutation.isLoading}
          >
            Add Purchased Material
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
