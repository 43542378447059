import { useQuery } from '@tanstack/react-query';
import { FacilityId, SortSystemId, SortSystemService } from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys } from './queryKeys';

async function fetchSortSystem(
  ctx: QueryFunctionContexts['sortSystem']['detail'],
) {
  const [{ sortSystemId }] = ctx.queryKey;
  return await SortSystemService.getSortSystemById(sortSystemId);
}

export function useSortSystem(sortSystemId: SortSystemId) {
  return useQuery({
    queryKey: queryKeys.sortSystem.detail(sortSystemId),
    queryFn: fetchSortSystem,
  });
}

async function fetchSortSystems(
  ctx: QueryFunctionContexts['sortSystem']['list'],
) {
  const [{ facilityId }] = ctx.queryKey;
  return await SortSystemService.allSortSystems(facilityId);
}

export function useSortSystems(facilityId?: FacilityId) {
  return useQuery({
    queryKey: queryKeys.sortSystem.list({ facilityId }),
    queryFn: fetchSortSystems,
  });
}
