import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { InternalMaterialSinkIcon } from '../Icons';
import {
  AddInternalMaterialSinkDrawerForm,
  AddInternalMaterialSinkDrawerFormProps,
} from './AddInternalMaterialSinkDrawerForm';

export type AddInternalMaterialSinkButtonProps = Omit<
  ButtonProps,
  'onClick'
> & {
  addInternalMaterialSinkDrawerFormProps: Omit<
    AddInternalMaterialSinkDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddInternalMaterialSinkButton(
  props: AddInternalMaterialSinkButtonProps,
) {
  const {
    addInternalMaterialSinkDrawerFormProps,
    leftIcon = <InternalMaterialSinkIcon />,
    children = 'Add Material Export Destination',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddInternalMaterialSinkDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addInternalMaterialSinkDrawerFormProps}
      />
    </>
  );
}
