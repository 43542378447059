import { useMutation, useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import {
  ApiError,
  RedWaveMaterialClassMetadataCreationDTO,
  RedWaveMaterialClassMetadataDTO,
  RedWaveMaterialClassMetadataId,
  RedWaveProfileRevisionPatchDTO,
  RedWaveService,
  RedWaveSortProgramNumber,
  RedWaveSortProgramProfileRevisionId,
  SortSystemId,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchRedWaveCurrentSortProgramSnapshots(
  ctx: QueryFunctionContexts['sortSystem']['redWave']['sortProgramSnapshot']['currentSnapshots'],
) {
  const [{ sortSystemId }] = ctx.queryKey;
  return await RedWaveService.getRedWaveCurrentSortProgramSnapshots(
    sortSystemId,
  );
}

export const useRedWaveCurrentSortProgramSnapshots = (
  sortSystemId: SortSystemId,
) => {
  return useQuery({
    queryKey:
      queryKeys.sortSystem.redWave.sortProgramSnapshot.currentSnapshots(
        sortSystemId,
      ),
    queryFn: fetchRedWaveCurrentSortProgramSnapshots,
  });
};

async function fetchRedWaveActiveSortProgramSnapshot(
  ctx: QueryFunctionContexts['sortSystem']['redWave']['sortProgramSnapshot']['active'],
) {
  const [{ sortSystemId, timestamp }] = ctx.queryKey;
  try {
    return await RedWaveService.getActiveRedWaveSortProgramSnapshot(
      sortSystemId,
      timestamp?.utc().toISOString(),
    );
  } catch (error) {
    const apiError = error as ApiError;
    if (apiError.status === 404) return null; // TODO(2296): Return a union type instead of abusing 404
    throw error;
  }
}

export function useRedWaveActiveSortProgramSnapshot(
  systemId: SortSystemId,
  timestamp: Dayjs | undefined,
) {
  return useQuery({
    queryKey: queryKeys.sortSystem.redWave.sortProgramSnapshot.active(
      systemId,
      timestamp,
    ),
    queryFn: fetchRedWaveActiveSortProgramSnapshot,
    refetchOnWindowFocus: timestamp === undefined,
  });
}

async function fetchRedWaveSortProgramSnapshot(
  ctx: QueryFunctionContexts['sortSystem']['redWave']['sortProgramSnapshot']['detail'],
) {
  const [{ sortSystemId, sortProgramNumber, timestamp }] = ctx.queryKey;
  return await RedWaveService.getRedWaveSortProgramSnapshot(
    sortSystemId,
    sortProgramNumber,
    timestamp?.utc().toISOString(),
  );
}

export function useRedWaveSortProgramSnapshot(
  sortSystemId: SortSystemId,
  sortProgramNumber: RedWaveSortProgramNumber,
  timestamp: Dayjs | undefined,
) {
  return useQuery({
    queryKey: queryKeys.sortSystem.redWave.sortProgramSnapshot.detail(
      sortSystemId,
      sortProgramNumber,
      timestamp,
    ),
    queryFn: fetchRedWaveSortProgramSnapshot,
    refetchOnWindowFocus: timestamp === undefined,
  });
}

async function fetchRedWaveProfileRevision(
  ctx: QueryFunctionContexts['sortSystem']['redWave']['profileRevision']['detail'],
) {
  const [{ profileRevisionId }] = ctx.queryKey;
  return await RedWaveService.getRedWaveProfileRevisionById(profileRevisionId);
}

export function useRedWaveProfileRevision(profileRevisionId: string) {
  return useQuery({
    queryKey:
      queryKeys.sortSystem.redWave.profileRevision.detail(profileRevisionId),
    queryFn: fetchRedWaveProfileRevision,
  });
}

async function patchRedWaveProfileRevision(
  profileRevisionid: string,
  patch: RedWaveProfileRevisionPatchDTO,
) {
  await RedWaveService.patchRedWaveProfileRevision(profileRevisionid, patch);
}

export function usePatchRedWaveProfileRevision(profileRevisionId: string) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: RedWaveProfileRevisionPatchDTO) =>
      await patchRedWaveProfileRevision(profileRevisionId, patch),
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.sortSystem.redWave.profileRevision.detail(profileRevisionId),
        queryKeys.sortSystem.redWave.sortProgramSnapshot.all,
      );
    },
  });
}

async function createRedWaveMaterialClassMetadata(
  materialClassMetadataDto: RedWaveMaterialClassMetadataCreationDTO,
  profileRevisionId: RedWaveSortProgramProfileRevisionId,
) {
  await RedWaveService.createRedWaveMaterialClassMetadata(
    profileRevisionId,
    materialClassMetadataDto,
  );
}

export function useAddRedWaveMaterialClassMetadata(
  profileRevisionId: RedWaveSortProgramProfileRevisionId,
) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (dto: RedWaveMaterialClassMetadataCreationDTO) =>
      await createRedWaveMaterialClassMetadata(dto, profileRevisionId),
    onSettled(data, error, patch) {
      invalidator.invalidateKeys(
        queryKeys.sortSystem.redWave.materialClassMetadata.detail(patch.id),
        queryKeys.sortSystem.redWave.profileRevision.detail(profileRevisionId),
      );
    },
  });
}

async function deleteRedWaveMaterialClassMetadata(
  materialClassMetadataId: RedWaveMaterialClassMetadataId,
) {
  await RedWaveService.deleteRedWaveMaterialClassMetadata(
    materialClassMetadataId,
  );
}

export function useDeleteRedwaveMaterialClassMetadata(
  profileRevisionId: string,
) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (dto: RedWaveMaterialClassMetadataDTO) =>
      await deleteRedWaveMaterialClassMetadata(dto.id),
    onSuccess(data, dto) {
      invalidator.removeKey(
        queryKeys.sortSystem.redWave.materialClassMetadata.detail(dto.id),
      );
    },
    onError(data, dto) {
      invalidator.resetKey(
        queryKeys.sortSystem.redWave.materialClassMetadata.detail(dto.id),
      );
      invalidator.resetKey(
        queryKeys.sortSystem.redWave.profileRevision.detail(profileRevisionId),
      );
    },
    onSettled(data, error, dto) {
      invalidator.invalidateKeys(
        queryKeys.sortSystem.redWave.materialClassMetadata.detail(dto.id),
        queryKeys.sortSystem.redWave.profileRevision.detail(profileRevisionId),
      );
    },
  });
}

async function fetchRedWaveTelemetry(
  ctx: QueryFunctionContexts['sortSystem']['redWave']['telemetry']['list'],
) {
  const [{ sortSystemId, start, end }] = ctx.queryKey;
  return await RedWaveService.getRedWaveSystemTelemetry(
    sortSystemId,
    start.utc().toISOString(),
    end?.utc().toISOString(),
  );
}

export function useRedWaveTelemetry(
  sortSystemId: string,
  start: Dayjs,
  end?: Dayjs,
) {
  return useQuery({
    queryKey: queryKeys.sortSystem.redWave.telemetry.list(
      sortSystemId,
      start,
      end,
    ),
    queryFn: fetchRedWaveTelemetry,
  });
}

async function fetchRedWaveSystemMetrics(
  ctx: QueryFunctionContexts['sortSystem']['redWave']['metrics']['list'],
) {
  const [{ sortSystemId, start, end }] = ctx.queryKey;
  return await RedWaveService.getRedWaveSystemMetrics(
    sortSystemId,
    start.utc().toISOString(),
    end?.utc().toISOString() ?? undefined,
  );
}

export function useRedWaveSystemMetrics(
  systemId: string,
  start: Dayjs,
  end: Dayjs | null,
) {
  return useQuery({
    queryKey: queryKeys.sortSystem.redWave.metrics.list(systemId, start, end),
    queryFn: fetchRedWaveSystemMetrics,
  });
}
