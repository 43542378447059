import { Badge, Group, GroupPosition, MantineColor } from '@mantine/core';
import { ReactNode } from 'react';
import { FullTransferIcon, PartialTransferIcon, PartitionIcon } from '../Icons';

export function EventTypeCellTemplate(props: {
  icon: ReactNode;
  color: MantineColor;
  name: ReactNode;
  position?: GroupPosition | undefined;
}) {
  const { icon, color, name, position } = props;
  return (
    <Group spacing='xs' position={position}>
      {icon}
      <Badge color={color}>{name}</Badge>
    </Group>
  );
}

export function PartitionTypeCell() {
  return (
    <EventTypeCellTemplate
      icon={<PartitionIcon />}
      color='grape'
      name='Partition'
    />
  );
}

export function TransferTypeCell(props: { partial: boolean }) {
  const { partial } = props;
  return (
    <EventTypeCellTemplate
      icon={partial ? <PartialTransferIcon /> : <FullTransferIcon />}
      color={partial ? 'violet' : 'blue'}
      name={partial ? 'Partial Transfer' : 'Full Transfer'}
    />
  );
}
