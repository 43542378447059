import { Switch, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { IconMoonStars, IconSun } from '@tabler/icons-react';
import classes from './css/ColorSchemeToggle.module.css';

export function ColorSchemeToggle() {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();

  return (
    <Switch
      className={classes.toggleButton}
      checked={colorScheme === 'dark'}
      onChange={() => toggleColorScheme()}
      size='lg'
      onLabel={<IconSun color={theme.white} size={20} stroke={1.5} />}
      offLabel={
        <IconMoonStars color={theme.colors.gray[6]} size={20} stroke={1.5} />
      }
    />
  );
}
