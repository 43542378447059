/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompleteSamplingSuiteCaptureSegmentationDTO } from '../models/CompleteSamplingSuiteCaptureSegmentationDTO';
import type { FailedSamplingSuiteCaptureSegmentationDTO } from '../models/FailedSamplingSuiteCaptureSegmentationDTO';
import type { PendingSamplingSuiteCaptureSegmentationDTO } from '../models/PendingSamplingSuiteCaptureSegmentationDTO';
import type { SamplingSuiteCaptureDTO } from '../models/SamplingSuiteCaptureDTO';
import type { SamplingSuiteCapturePatchDTO } from '../models/SamplingSuiteCapturePatchDTO';
import type { SamplingSuiteContainerSampleAnalysisCreationDTO } from '../models/SamplingSuiteContainerSampleAnalysisCreationDTO';
import type { SamplingSuiteContainerSampleAnalysisDTO } from '../models/SamplingSuiteContainerSampleAnalysisDTO';
import type { SamplingSuiteContainerSampleAnalysisPatchDTO } from '../models/SamplingSuiteContainerSampleAnalysisPatchDTO';
import type { SamplingSuiteDTO } from '../models/SamplingSuiteDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SamplingSuiteService {
    /**
     * @returns SamplingSuiteDTO Success
     * @throws ApiError
     */
    public static getSamplingSuites(): CancelablePromise<Array<SamplingSuiteDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites',
        });
    }
    /**
     * @param suiteId
     * @param unlinked
     * @returns SamplingSuiteCaptureDTO Success
     * @throws ApiError
     */
    public static getSamplingSuiteCaptures(
        suiteId?: string,
        unlinked?: boolean,
    ): CancelablePromise<Array<SamplingSuiteCaptureDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/captures',
            query: {
                'suiteId': suiteId,
                'unlinked': unlinked,
            },
        });
    }
    /**
     * @param suiteGuid
     * @returns any Success
     * @throws ApiError
     */
    public static uploadCapture(
        suiteGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sampling-suites/{suiteGuid}/captures',
            path: {
                'suiteGuid': suiteGuid,
            },
        });
    }
    /**
     * @param captureGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getCapturePng(
        captureGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/captures/{captureGuid}/image',
            path: {
                'captureGuid': captureGuid,
            },
        });
    }
    /**
     * @param captureGuid
     * @returns SamplingSuiteCaptureDTO Success
     * @throws ApiError
     */
    public static getCapture(
        captureGuid: string,
    ): CancelablePromise<SamplingSuiteCaptureDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/captures/{captureGuid}',
            path: {
                'captureGuid': captureGuid,
            },
        });
    }
    /**
     * @param captureGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchCapture(
        captureGuid: string,
        requestBody: SamplingSuiteCapturePatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/sampling-suites/captures/{captureGuid}',
            path: {
                'captureGuid': captureGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param captureGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getCaptureSegmentation(
        captureGuid: string,
    ): CancelablePromise<(PendingSamplingSuiteCaptureSegmentationDTO | FailedSamplingSuiteCaptureSegmentationDTO | CompleteSamplingSuiteCaptureSegmentationDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/captures/{captureGuid}/segmentation',
            path: {
                'captureGuid': captureGuid,
            },
        });
    }
    /**
     * @param samplingSuiteId
     * @param containerSampleId
     * @returns SamplingSuiteContainerSampleAnalysisDTO Success
     * @throws ApiError
     */
    public static getSamplingSuiteContainerSampleAnalyses(
        samplingSuiteId?: string,
        containerSampleId?: string,
    ): CancelablePromise<Array<SamplingSuiteContainerSampleAnalysisDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/container-sample-analyses',
            query: {
                'samplingSuiteId': samplingSuiteId,
                'containerSampleId': containerSampleId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createSamplingSuiteContainerSampleAnalysis(
        requestBody: SamplingSuiteContainerSampleAnalysisCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sampling-suites/container-sample-analyses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param containerSampleAnalysisGuid
     * @returns SamplingSuiteContainerSampleAnalysisDTO Success
     * @throws ApiError
     */
    public static getSamplingSuiteContainerSampleAnalysis(
        containerSampleAnalysisGuid: string,
    ): CancelablePromise<SamplingSuiteContainerSampleAnalysisDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sampling-suites/container-sample-analyses/{containerSampleAnalysisGuid}',
            path: {
                'containerSampleAnalysisGuid': containerSampleAnalysisGuid,
            },
        });
    }
    /**
     * @param containerSampleAnalysisGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchSamplingSuiteContainerSampleAnalysis(
        containerSampleAnalysisGuid: string,
        requestBody: SamplingSuiteContainerSampleAnalysisPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/sampling-suites/container-sample-analyses/{containerSampleAnalysisGuid}',
            path: {
                'containerSampleAnalysisGuid': containerSampleAnalysisGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param containerSampleAnalysisGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteSamplingSuiteContainerSampleAnalysis(
        containerSampleAnalysisGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sampling-suites/container-sample-analyses/{containerSampleAnalysisGuid}',
            path: {
                'containerSampleAnalysisGuid': containerSampleAnalysisGuid,
            },
        });
    }
}
