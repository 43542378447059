import { Loader, Table, useMantineTheme } from '@mantine/core';
import { TableEmptyBasicContent } from '../TableEmptyBasicContent';
import { useVendors } from '../api/vendor';
import { VendorName } from './VendorName';

export function VendorTable() {
  const theme = useMantineTheme();
  const { data: vendors, isLoading, isLoadingError, error } = useVendors();
  if (isLoadingError) {
    throw error;
  }

  const isEmpty = vendors && vendors.length === 0;

  return (
    <Table>
      {(isLoading || isEmpty) && (
        <caption
          style={{
            captionSide: 'bottom',
            textAlign: 'center',
            padding: theme.spacing.md,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <TableEmptyBasicContent>No Vendors</TableEmptyBasicContent>
          )}
        </caption>
      )}
      <thead>
        <tr>
          <th>Name</th>
          <th>Contact</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        {vendors?.map((vendor) => (
          <tr key={vendor.id}>
            <td>
              <VendorName vendor={vendor} />
            </td>
            <td>{vendor.contactName}</td>
            <td>{vendor.location}</td>
          </tr>
        )) ?? (
          <tr>
            <td colSpan={3}>
              <Loader />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
