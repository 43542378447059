import { Badge, Text } from '@mantine/core';
import { Dayjs } from 'dayjs';
import { P, match } from 'ts-pattern';
import { useFacilityContext } from '../Facility/FacilityContext';
import { MaterialSetMass } from '../Mass/MaterialSetMass';
import { useContainerStateLookup } from '../api/container';
import { MaterialContainerId } from '../rest-client';

export function ContainerNetWeight(props: {
  containerId: MaterialContainerId;
  timestamp: Dayjs | undefined;
}) {
  const { containerId, timestamp } = props;

  const facility = useFacilityContext();
  const getContainerState = useContainerStateLookup({
    facilityId: facility.id,
    timestamp,
  });
  const containerState = getContainerState(containerId);

  return match(containerState)
    .with({ status: 'loading' }, () => (
      <Text color='dimmed' size='xs'>
        Loading...
      </Text>
    ))
    .with({ status: 'request-error' }, () => <Text size='xs'>Error</Text>)
    .with({ status: 'empty' }, () => (
      <Badge color='gray' variant='outline'>
        Empty
      </Badge>
    ))
    .with({ status: 'ledger-error' }, () => (
      <Text color='red' size='xs'>
        Ledger Error
      </Text>
    ))
    .with({ status: 'occupied', state: P.select() }, ({ materialSet }) => (
      <MaterialSetMass materialSet={materialSet} />
    ))
    .exhaustive();
}
