/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RecoveryStrategySimulationCreationDTO } from '../models/RecoveryStrategySimulationCreationDTO';
import type { RecoveryStrategySimulationDTO } from '../models/RecoveryStrategySimulationDTO';
import type { RecoveryStrategySimulationPatchDTO } from '../models/RecoveryStrategySimulationPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RecoveryStrategySimulationService {
    /**
     * @param strategyId
     * @returns RecoveryStrategySimulationDTO Success
     * @throws ApiError
     */
    public static getSimulations(
        strategyId?: string,
    ): CancelablePromise<Array<RecoveryStrategySimulationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/recovery-strategy-simulations',
            query: {
                'strategyId': strategyId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createSimulation(
        requestBody?: RecoveryStrategySimulationCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/recovery-strategy-simulations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param simulationGuid
     * @returns RecoveryStrategySimulationDTO Success
     * @throws ApiError
     */
    public static getSimulation(
        simulationGuid: string,
    ): CancelablePromise<RecoveryStrategySimulationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/recovery-strategy-simulations/{simulationGuid}',
            path: {
                'simulationGuid': simulationGuid,
            },
        });
    }
    /**
     * @param simulationGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchSimulation(
        simulationGuid: string,
        requestBody: RecoveryStrategySimulationPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/recovery-strategy-simulations/{simulationGuid}',
            path: {
                'simulationGuid': simulationGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param simulationGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteSimulation(
        simulationGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/recovery-strategy-simulations/{simulationGuid}',
            path: {
                'simulationGuid': simulationGuid,
            },
        });
    }
}
