import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { InternallySourcedMaterialIcon } from '../Icons';
import {
  AddInternallySourceMaterialDrawerFormProps,
  AddInternallySourcedMaterialDrawerForm,
} from './AddInternallySourcedMaterialDrawerForm';

export type AddInternallySourcedMaterialButtonProps = Omit<
  ButtonProps,
  'onClick'
> & {
  addInternallySourcedMaterialDrawerFormProps: Omit<
    AddInternallySourceMaterialDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddInternallySourcedMaterialButton(
  props: AddInternallySourcedMaterialButtonProps,
) {
  const {
    addInternallySourcedMaterialDrawerFormProps,
    leftIcon = <InternallySourcedMaterialIcon />,
    children = 'Add Sourced Material',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddInternallySourcedMaterialDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addInternallySourcedMaterialDrawerFormProps}
      />
    </>
  );
}
