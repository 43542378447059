import { RedWaveSortSystemDTO, RedWaveStream } from '../../../rest-client';
import { SystemPort } from '../SystemPort';

export function redWaveStreamFromPort(
  system: RedWaveSortSystemDTO,
  port: SystemPort,
): RedWaveStream {
  if (port.type === 'input') return RedWaveStream.INPUT;
  if (port.portId === system.ejectPortId) return RedWaveStream.EJECT;
  if (port.portId === system.rejectPortId) return RedWaveStream.PASS;
  throw new Error('Output port id does not exist on sort system');
}
