/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum WeightUnit {
    GRAM = 'Gram',
    KILOGRAM = 'Kilogram',
    LONG_TON = 'LongTon',
    POUND = 'Pound',
    SHORT_TON = 'ShortTon',
    TONNE = 'Tonne',
}
