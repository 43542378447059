import { match } from 'ts-pattern';
import { AddPurchasedMaterialPage } from '../PurchasedMaterial/AddPurchasedMaterialPage';
import { PurchasedMaterialDetail } from '../PurchasedMaterial/PurchasedMaterialDetail';
import { The404 } from '../The404';
import { Router } from '../router';
import { AddVendorPage } from './AddVendorPage';
import { EditVendorPage } from './EditVendorPage';
import { VendorDetail } from './VendorDetail';
import { VendorsPage } from './VendorsPage';

export function VendorArea() {
  const route = Router.useRoute([
    'VendorList',
    'VendorDetail',
    'VendorCreate',
    'VendorEdit',
    'VendorPurchasedMaterialCreate',
    'VendorPurchasedMaterialDetail',
  ]);
  if (route === undefined) {
    return <The404 />;
  }
  return match(route)
    .with({ name: 'VendorList' }, () => <VendorsPage />)
    .with({ name: 'VendorDetail' }, ({ params: { vendorId } }) => (
      <VendorDetail vendorId={vendorId} />
    ))
    .with({ name: 'VendorCreate' }, () => <AddVendorPage />)
    .with({ name: 'VendorEdit' }, ({ params: { vendorId } }) => (
      <EditVendorPage vendorId={vendorId} />
    ))
    .with(
      { name: 'VendorPurchasedMaterialCreate' },
      ({ params: { vendorId } }) => (
        <AddPurchasedMaterialPage vendorId={vendorId} />
      ),
    )
    .with(
      { name: 'VendorPurchasedMaterialDetail' },
      ({ params: { purchasedMaterialId } }) => (
        <PurchasedMaterialDetail purchasedMaterialId={purchasedMaterialId} />
      ),
    )
    .exhaustive();
}
