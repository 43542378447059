import NetWeight from '../Weights/NetWeight';
import { MassClaimDTO } from '../rest-client';

export interface MassClaimTextProps {
  massClaim: MassClaimDTO;
}

export function MassClaimText(props: MassClaimTextProps) {
  const { massClaim } = props;

  return (
    <NetWeight weight={massClaim.netWeight} sourceIconMode='icon-tooltip' />
  );
}
