import { Alert, Divider, Flex, Text } from '@mantine/core';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import { useToggle } from '@mantine/hooks';
import { IconAlertTriangle } from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';
import ContainerSelect, {
  ContainerSelectVariant,
} from '../Container/ContainerSelect';
import { RequiredDayjsDateTimePicker } from '../DayjsDateTimePicker';
import { useFacilityContext } from '../Facility/FacilityContext';
import ContainerFullnessSwitch from '../Input/ContainerFullnessSwitch';
import { InvalidVisibilityToggle } from '../Input/InvalidVisibilityToggle';
import { MaterialContainerId } from '../rest-client';

const ContainerTransferFormSchema = z.object({
  timestamp: z.instanceof(dayjs as unknown as typeof Dayjs, {
    message: 'Transfer time is required',
  }),
  sourceContainerId: z
    .string()
    .uuid()
    .nullable()
    .refine((val) => val !== null, { message: 'Source container is required' }),
  sourceEmptied: z.boolean(),
  destinationContainerId: z
    .string()
    .uuid()
    .nullable()
    .refine((val) => val !== null, {
      message: 'Destination container is required',
    }),
  destinationFilled: z.boolean(),
});

export type ContainerTransferFormValues = z.infer<
  typeof ContainerTransferFormSchema
>;

export type ContainerTransferFormProps = {
  sourceContainerId?: MaterialContainerId;
  destinationContainerId?: MaterialContainerId;
};

export function useContainerTransferForm(props: ContainerTransferFormProps) {
  const { sourceContainerId, destinationContainerId } = props;
  return useForm<ContainerTransferFormValues>({
    initialValues: {
      timestamp: dayjs.utc(),
      sourceContainerId: sourceContainerId ?? null,
      sourceEmptied: true,
      destinationContainerId: destinationContainerId ?? null,
      destinationFilled: true,
    },
    validate: zodResolver(ContainerTransferFormSchema),
  });
}

export function ContainerTransferFormFields(props: {
  form: UseFormReturnType<ContainerTransferFormValues>;
  containerSelectVariant: ContainerSelectVariant;
}) {
  const { form, containerSelectVariant = 'material' } = props;

  const facility = useFacilityContext();
  const [showEmptySources, toggleShowEmptySources] = useToggle();
  const [showFullDestinations, toggleShowFullDestinations] = useToggle();

  const sourceContainerSelectDisabled =
    form.values.sourceContainerId !== null &&
    !form.isDirty('sourceContainerId');
  const destinationContainerSelectDisable =
    form.values.destinationContainerId !== null &&
    !form.isDirty('destinationContainerId');

  return (
    <>
      <RequiredDayjsDateTimePicker
        label='Transfer Time'
        description='When the material was considered transferred.'
        tz={facility.timeZoneId}
        withAsterisk
        {...form.getInputProps('timestamp')}
      />

      <Flex direction='column' gap='sm'>
        {/* TODO(new): change to be mantine v7 form input section */}
        <Divider label={<Text size='md'>Source</Text>} labelPosition='center' />
        <ContainerSelect
          label='Source Container'
          description='Container material is being transferred out of.'
          variant={containerSelectVariant}
          timestamp={form.values.timestamp}
          facilityId={facility.id}
          hiddenContainerIds={
            form.values.destinationContainerId
              ? new Set([form.values.destinationContainerId])
              : undefined
          }
          hideEmpty={!showEmptySources}
          hideFull={false}
          withAsterisk
          clearable
          disabled={sourceContainerSelectDisabled}
          {...form.getInputProps('sourceContainerId')}
        />
        <Flex gap='md'>
          <ContainerFullnessSwitch
            inputWrapperProps={{
              label: 'Container Emptied',
              description: 'Was the container completely emptied?',
            }}
            variant='source'
            orientation='horizontal'
            size='lg'
            {...form.getInputProps('sourceEmptied', { type: 'checkbox' })}
          />

          {!sourceContainerSelectDisabled && (
            <InvalidVisibilityToggle
              labelVariant='top'
              label='Show Empty Containers'
              value={showEmptySources}
              onToggle={toggleShowEmptySources}
              actionIconProps={{
                style: { alignSelf: 'flex-end' },
              }}
              inputWrapperProps={{
                description: 'Include empty containers in container select?',
              }}
            />
          )}
        </Flex>
      </Flex>
      <Flex direction='column' gap='sm'>
        {/* TODO(new): change to be mantine v7 form input section */}
        <Divider
          label={<Text size='md'>Destination</Text>}
          labelPosition='center'
        />
        <Flex direction='column' gap='sm'>
          <ContainerSelect
            label='Destination Container'
            description='Container the material is being transferred into.'
            timestamp={form.values.timestamp}
            hiddenContainerIds={
              form.values.sourceContainerId
                ? new Set([form.values.sourceContainerId])
                : undefined
            }
            facilityId={facility.id}
            hideEmpty={false}
            hideFull={!showFullDestinations}
            clearable
            withAsterisk
            disabled={destinationContainerSelectDisable}
            {...form.getInputProps('destinationContainerId')}
          />

          <Flex gap='sm'>
            <ContainerFullnessSwitch
              variant='destination'
              orientation='horizontal'
              size='lg'
              {...form.getInputProps('destinationFilled', { type: 'checkbox' })}
              inputWrapperProps={{
                label: 'Container Filled',
                description: 'Was the container completely filled?',
              }}
            />

            {!destinationContainerSelectDisable && (
              <InvalidVisibilityToggle
                labelVariant='top'
                label='Show Full Containers'
                value={showFullDestinations}
                onToggle={toggleShowFullDestinations}
                actionIconProps={{
                  style: { alignSelf: 'flex-end' },
                }}
                inputWrapperProps={{
                  description: 'Include empty containers in container select?',
                }}
              />
            )}
          </Flex>
        </Flex>
      </Flex>

      {showEmptySources || showFullDestinations ? (
        <Alert color='orange' icon={<IconAlertTriangle />} title=''>
          Creating a transfer with an empty source or a full destination will
          result in an ledger error. You can still create the transfer, but the
          error will need to be corrected later.
        </Alert>
      ) : undefined}
    </>
  );
}
