import {
  CommodityDTO,
  DefaultCommodityAssignmentDTO,
  RecoveryGoalProbTreeDTO,
  WeightUnit,
} from '../rest-client';

// TODO: Move all of this to the backend

export type IndividualCommodityShare = {
  isOutput: boolean;
  massShare: number;
  usdPerUnitOfWeight: number | null;
  weightUnit: WeightUnit;
};

function findRecoveryGoalTree(
  recoveryGoalId: string | null,
  probTree: RecoveryGoalProbTreeDTO | null,
): RecoveryGoalProbTreeDTO | null {
  if (recoveryGoalId === null) return null;
  if (probTree === null) return null;
  if (probTree.recoveryGoal.id === recoveryGoalId) {
    return probTree;
  } else {
    const resultLeft = findRecoveryGoalTree(
      recoveryGoalId,
      probTree.negativeTree,
    );
    if (resultLeft !== null) {
      return resultLeft;
    } else {
      return findRecoveryGoalTree(recoveryGoalId, probTree.positiveTree);
    }
  }
}

function findMassShareOfCommodityAssignment(
  commodityAssignment: DefaultCommodityAssignmentDTO,
  probTree: RecoveryGoalProbTreeDTO | null,
  totalFeedstockMass: number,
): number {
  const recoveryGoal = commodityAssignment.path.steps.at(-1) ?? null;
  const recoveryGoalTree = findRecoveryGoalTree(
    recoveryGoal?.recoveryGoalId ?? null,
    probTree,
  );

  if (recoveryGoal !== null && recoveryGoalTree !== null) {
    const outputProb =
      recoveryGoalTree[
        `${recoveryGoal.negative ? 'negative' : 'positive'}Prob`
      ];
    const outputTotalProb = Object.values(outputProb).reduce(
      (a, b) => a + b,
      0,
    );
    const massShare = totalFeedstockMass * outputTotalProb;
    return massShare;
  }

  return 0;
}

export function getDefaultCommodityShares(
  inputCommodity: CommodityDTO | null,
  commodityAssignments: DefaultCommodityAssignmentDTO[] | null,
  probTree: RecoveryGoalProbTreeDTO | null,
  defaultInputMass: number,
): Record<string, IndividualCommodityShare> {
  const shares = commodityAssignments?.reduce(
    (
      acc: Record<string, IndividualCommodityShare>,
      dca: DefaultCommodityAssignmentDTO,
    ) => {
      const commoditySpotPrice =
        dca.outputCommodity?.commoditySpotPrices?.at(-1);
      if (dca.outputCommodityId) {
        acc[dca.outputCommodityId] = {
          isOutput: true,
          usdPerUnitOfWeight: commoditySpotPrice?.usdPerUnitOfWeight ?? null,
          weightUnit: commoditySpotPrice?.weightUnit ?? WeightUnit.POUND,
          massShare: findMassShareOfCommodityAssignment(
            dca,
            probTree,
            defaultInputMass,
          ),
        };
      }
      return acc;
    },
    {},
  );

  if (shares && inputCommodity) {
    const commoditySpotPrice = inputCommodity.commoditySpotPrices?.at(-1);
    shares[inputCommodity.id] = {
      isOutput: false,
      usdPerUnitOfWeight: commoditySpotPrice?.usdPerUnitOfWeight ?? null,
      weightUnit: commoditySpotPrice?.weightUnit ?? WeightUnit.POUND,
      massShare: defaultInputMass,
    };
  }

  return shares ?? {};
}
