import { Loader, Stack, Text, Title } from '@mantine/core';
import { AppPage } from '../App/AppPage';
import { GenealogyExplorer } from '../GenealogyExplorer/GenealogyExplorer';
import { VendorIdName } from '../Vendor/VendorIdName';
import {
  usePurchasedMaterial,
  usePurchasedMaterialGenealogy,
} from '../api/purchasedMaterial';
import { usePurchasedMaterialPartitionStatus } from '../api/purchasedMaterialPartition';
import {
  PurchasedMaterialId,
  PurchasedMaterialPartitionDTO,
} from '../rest-client';
import { Router } from '../router';
import { PurchasedMaterialPartitionForm } from './PurchasedMaterialPartitionForm';

// TODO(2268): align purchased material details page with ISM details page
export function PurchasedMaterialDetail({
  purchasedMaterialId,
}: {
  purchasedMaterialId: PurchasedMaterialId;
}) {
  const purchasedMaterialQuery = usePurchasedMaterial(purchasedMaterialId);
  const partitonStatusQuery =
    usePurchasedMaterialPartitionStatus(purchasedMaterialId);

  if (partitonStatusQuery.isLoadingError) {
    throw partitonStatusQuery.error;
  }

  return (
    <AppPage
      breadcrumbs={[
        {
          title: purchasedMaterialQuery.data ? (
            <VendorIdName vendorId={purchasedMaterialQuery.data.vendorId} />
          ) : null,
          routeName: purchasedMaterialQuery.data
            ? Router.VendorDetail({
                vendorId: purchasedMaterialQuery.data.vendorId,
              })
            : Router.VendorList(),
        },
      ]}
      title={purchasedMaterialQuery.data?.name}
    >
      {/* TODO(2317): Style loader */}
      {partitonStatusQuery.isLoading ? <Loader /> : undefined}
      {/* If this has been partitioned, then we show the genealogy graph and a link to the partition itself */}
      {partitonStatusQuery.data?.status === 'partitioned' ? (
        <>
          <PartitionDetailSection
            partition={partitonStatusQuery.data.partition}
          />
          <PurchasedMaterialGenealogyExplorer
            purchasedMaterialId={purchasedMaterialId}
          />
        </>
      ) : undefined}

      {/* If it has not yet been partitioned, we show the form to partition it */}
      {partitonStatusQuery.data?.status === 'unpartitioned' ? (
        <AppPage.Section>
          <Stack>
            <Title order={3}>Transfer</Title>
            <Text>This purchased material has not been transferred yet.</Text>
            <PurchasedMaterialPartitionForm
              maw={700}
              purchasedMaterial={purchasedMaterialQuery.data}
            />
          </Stack>
        </AppPage.Section>
      ) : undefined}
    </AppPage>
  );
}

function PartitionDetailSection(props: {
  partition: PurchasedMaterialPartitionDTO;
}) {
  const { partition } = props;
  return (
    <AppPage.Section>
      <Stack>
        <Title order={3}>Transfer</Title>
        {/* TODO(2268): More details */}
        <Text>
          This purchased material was transferred on{' '}
          {partition.effectiveTimestamp}.{/* TODO(2268): To destinations.... */}
          {/* TODO(2268): Link to transaction ledger for when it occurred */}
        </Text>
      </Stack>
    </AppPage.Section>
  );
}

function PurchasedMaterialGenealogyExplorer(props: {
  purchasedMaterialId: string;
}) {
  const { purchasedMaterialId } = props;
  const genealogyQuery = usePurchasedMaterialGenealogy(purchasedMaterialId);
  return <GenealogyExplorer genealogyQuery={genealogyQuery} />;
}
