/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeedFlowGroupMaterialSetsDTO } from '../models/FeedFlowGroupMaterialSetsDTO';
import type { InferredPropertiesDTO } from '../models/InferredPropertiesDTO';
import type { LedgerErrorMaterialSetResultDTO } from '../models/LedgerErrorMaterialSetResultDTO';
import type { MaterialSetSuccessDTO } from '../models/MaterialSetSuccessDTO';
import type { ProcessingMaterialStateStatus } from '../models/ProcessingMaterialStateStatus';
import type { ReadyMaterialStateStatus } from '../models/ReadyMaterialStateStatus';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MaterialStateService {
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getMaterialStateStatus(): CancelablePromise<(ProcessingMaterialStateStatus | ReadyMaterialStateStatus)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-state/status',
        });
    }
    /**
     * @param materialStateInferenceHashHex
     * @param materialSetHashHex
     * @returns any Success
     * @throws ApiError
     */
    public static getMaterialSet(
        materialStateInferenceHashHex: string,
        materialSetHashHex: string,
    ): CancelablePromise<(LedgerErrorMaterialSetResultDTO | MaterialSetSuccessDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-state/{materialStateInferenceHashHex}/material-sets/{materialSetHashHex}',
            path: {
                'materialStateInferenceHashHex': materialStateInferenceHashHex,
                'materialSetHashHex': materialSetHashHex,
            },
        });
    }
    /**
     * @param materialStateInferenceHashHex
     * @param materialSetHashHex
     * @returns InferredPropertiesDTO Success
     * @throws ApiError
     */
    public static getMaterialSetInferredProperties(
        materialStateInferenceHashHex: string,
        materialSetHashHex: string,
    ): CancelablePromise<InferredPropertiesDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-state/{materialStateInferenceHashHex}/material-sets/{materialSetHashHex}/inferences',
            path: {
                'materialStateInferenceHashHex': materialStateInferenceHashHex,
                'materialSetHashHex': materialSetHashHex,
            },
        });
    }
    /**
     * @param materialStateInferenceHashHex
     * @param feedFlowGroupId
     * @returns FeedFlowGroupMaterialSetsDTO Success
     * @throws ApiError
     */
    public static getFeedFlowGroupMaterialSets(
        materialStateInferenceHashHex: string,
        feedFlowGroupId: string,
    ): CancelablePromise<FeedFlowGroupMaterialSetsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-state/{materialStateInferenceHashHex}/feed-flow-group-material-sets/{feedFlowGroupId}',
            path: {
                'materialStateInferenceHashHex': materialStateInferenceHashHex,
                'feedFlowGroupId': feedFlowGroupId,
            },
        });
    }
}
