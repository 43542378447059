import { P, match } from 'ts-pattern';
import { TransactionStatus } from '../LedgerStatusContext';
import {
  CommodityMixingWarningExplanationRow,
  TransactionErrorExplanationRow,
  TransactionWarningExplanationRow,
} from './TransactionErrorAndWarningExplanationRow';
import {
  EmptyingStatusExplanationRow,
  FillingStatusExplanationRow,
} from './TranscationEmptyingAndFillingStatusExplanations';

export function TransactionStatusExplanationRows(props: {
  status: TransactionStatus;
}) {
  const { status } = props;

  return match(status)
    .with({ status: P.union('applied', 'loading', 'unapplied') }, () => <></>)
    .with({ status: 'filling' }, (fillingStatus) => (
      <FillingStatusExplanationRow status={fillingStatus} />
    ))
    .with({ status: 'emptying' }, (emptyingStatus) => (
      <EmptyingStatusExplanationRow status={emptyingStatus} />
    ))
    .with({ status: 'errant' }, ({ errors }) => (
      <>
        {errors.map((error, i) => (
          <TransactionErrorExplanationRow key={i} error={error} />
        ))}
      </>
    ))
    .with(
      { status: 'warning' },
      ({ flowConcurrencyWarnings, commodityMixingWarning }) => (
        <>
          {commodityMixingWarning ? (
            <CommodityMixingWarningExplanationRow />
          ) : null}
          {flowConcurrencyWarnings.map((warning, i) => (
            <TransactionWarningExplanationRow warning={warning} key={i} />
          ))}
        </>
      ),
    )
    .exhaustive();
}
