import { useMutation, useQuery } from '@tanstack/react-query';
import {
  RecoveryStrategyCreationDTO,
  RecoveryStrategyPatchDTO,
  RecoveryStrategyService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchRecoveryStrategy(
  ctx: QueryFunctionContexts['recoveryStrategy']['detail'],
) {
  const [{ recoveryStrategyId }] = ctx.queryKey;
  return await RecoveryStrategyService.getRecoveryStrategy(recoveryStrategyId);
}

export function useRecoveryStrategy(id: string) {
  return useQuery({
    queryFn: fetchRecoveryStrategy,
    queryKey: queryKeys.recoveryStrategy.detail(id),
  });
}

async function createRecoveryStrategy(dto: RecoveryStrategyCreationDTO) {
  await RecoveryStrategyService.createRecoveryStrategy(dto);
}

async function fetchRecoveryStrategies() {
  return await RecoveryStrategyService.getRecoveryStrategies();
}

export function useRecoveryStrategies() {
  return useQuery({
    queryKey: queryKeys.recoveryStrategy.list(),
    queryFn: fetchRecoveryStrategies,
  });
}

export function useCreateRecoveryStrategy() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createRecoveryStrategy,
    onSettled() {
      invalidator.invalidateKeys(queryKeys.recoveryStrategy.list());
    },
  });
}

async function patchRecoveryStrategy(args: {
  id: string;
  patch: RecoveryStrategyPatchDTO;
}) {
  await RecoveryStrategyService.patchRecoveryStrategy(args.id, args.patch);
}

export function usePatchRecoveryStrategy() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchRecoveryStrategy,
    onSettled(_data, _error, { id }) {
      invalidator.invalidateKeys(
        queryKeys.recoveryStrategy.list(),
        queryKeys.recoveryStrategy.detail(id),
      );
    },
  });
}

async function deleteRecoveryStrategy(id: string) {
  await RecoveryStrategyService.deleteRecoveryStrategy(id);
}

export function useDeleteRecoveryStrategy() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteRecoveryStrategy,
    onSettled(_data, _error, id) {
      invalidator.removeKey(queryKeys.recoveryStrategy.detail(id));
      invalidator.invalidateKeys(queryKeys.recoveryStrategy.list());
    },
  });
}
