import {
  Alert,
  Box,
  Center,
  ColorScheme,
  Container,
  MantineThemeOverride,
} from '@mantine/core';
import React, { useState } from 'react';
import { Providers } from './App';
import cssClasses from './NoDynamicConfig.module.css';

// TODO(2423): we don't need a special UI component here, migrate to other error
// existing error handling contexts
export default function NoDynamicConfig() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value ?? (colorScheme === 'dark' ? 'light' : 'dark'));

  const theme: MantineThemeOverride = {
    colorScheme,
    primaryColor: 'teal',
    components: {
      Container: {
        defaultProps: {
          sizes: {
            xl: 1550,
          },
        },
      },
    },
  };

  return (
    <React.StrictMode>
      <Providers
        theme={theme}
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <Box className={cssClasses.noConfigContainer}>
          <Container size={420} h={'100vh'}>
            <Center h='100%'>
              <Alert title='Cannot Render Application' color='red'>
                The dynamic configuration for the application could not be
                retrieved. The application cannot be rendered.
              </Alert>
            </Center>
          </Container>
        </Box>
      </Providers>
    </React.StrictMode>
  );
}
