import { match } from 'ts-pattern';
import {
  InventoryLedgerEventDTO,
  MaterialTransactionDTO,
} from '../rest-client';

export function getLedgerEventKey(event: InventoryLedgerEventDTO) {
  return match(event)
    .with({ eventType: 'Transaction' }, ({ transaction }) =>
      getTransactionKey(transaction),
    )
    .with({ eventType: 'ScaleReading' }, ({ scaleReading }) => scaleReading.id)
    .with(
      { eventType: 'ContainerSample' },
      ({ containerSampleEvent }) => containerSampleEvent.id,
    )
    .with(
      { eventType: 'OutputContainerChange' },
      ({ outputContainerChange }) => outputContainerChange.id,
    )
    .with(
      { eventType: 'ProcessBufferRestoration' },
      ({ processBufferRestoration }) => processBufferRestoration.id,
    )
    .with(
      { eventType: 'ProcessBufferDepletion' },
      ({ processBufferDepletion }) => processBufferDepletion.id,
    )
    .exhaustive();
}

export function getTransactionKey(txn: MaterialTransactionDTO) {
  return match(txn)
    .with(
      { kind: 'PurchasedMaterialPartition' },
      (partition) => partition.purchasedMaterialId,
    )
    .with(
      { kind: 'InternallySourcedMaterialPartition' },
      (partition) => partition.internallySourcedMaterialId,
    )
    .otherwise((t) => t.ledgerId);
}
