import { useMutation, useQuery } from '@tanstack/react-query';
import Temporal from '../Temporal/temporal';
import {
  CommodityCreationDTO,
  CommodityId,
  CommodityPatchDTO,
  CommodityService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchCommodity(
  ctx: QueryFunctionContexts['commodity']['detail'],
) {
  const [{ commodityId }] = ctx.queryKey;
  if (!commodityId) {
    throw new Error('commodity id must be defined');
  }
  return await CommodityService.getCommodity(commodityId);
}

export function useCommodity(commodityId: string | undefined) {
  return useQuery({
    queryKey: queryKeys.commodity.detail(commodityId),
    queryFn: fetchCommodity,
    enabled: !!commodityId,
  });
}

async function fetchCommodities() {
  return await CommodityService.getCommodities();
}

export function useCommodities() {
  return useQuery({
    queryKey: queryKeys.commodity.list(),
    queryFn: fetchCommodities,
  });
}

async function createCommodity(dto: CommodityCreationDTO) {
  await CommodityService.createCommodity(dto);
}

export function useCreateCommodity() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createCommodity,
    onSettled() {
      invalidator.invalidateKeys(queryKeys.commodity.list());
      invalidator.invalidateMaterialState();
    },
  });
}

async function patchCommodity({
  commodityId,
  patch,
}: {
  commodityId: string;
  patch: CommodityPatchDTO;
}) {
  await CommodityService.patchCommodity(commodityId, patch);
}

export function usePatchCommodity(commodityId: CommodityId) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: CommodityPatchDTO) =>
      await patchCommodity({ commodityId, patch }),
    onSettled() {
      invalidator.invalidateMaterialState();
      invalidator.invalidateKeys(
        queryKeys.commodity.detail(commodityId),
        queryKeys.commodity.list(),
      );
    },
  });
}

async function deleteCommodity(commodityId: string) {
  await CommodityService.deleteCommodity(commodityId);
}

export function useDeleteCommodity() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteCommodity,
    onSettled(data, error, commodityId) {
      invalidator.invalidateMaterialState();
      invalidator.invalidateKeys(queryKeys.commodity.list());
      invalidator.removeKey(queryKeys.commodity.detail(commodityId));
    },
  });
}

async function fetchCommodityPurities(
  ctx: QueryFunctionContexts['commodity']['purities'],
) {
  const [{ after, before, facilityId }] = ctx.queryKey;
  const beforeInstant = before ?? Temporal.Now.instant();
  return await CommodityService.getProducedCommodityPurities(
    after.toString(),
    beforeInstant.toString(),
    facilityId,
  );
}

export function useGetCommodityPurities({
  after,
  before,
  facilityId,
}: {
  after: Temporal.Instant;
  before?: Temporal.Instant;
  facilityId: string;
}) {
  return useQuery({
    queryKey: queryKeys.commodity.purities(facilityId, after, before),
    queryFn: fetchCommodityPurities,
  });
}

async function fetchProducedCommodityMasses(
  ctx: QueryFunctionContexts['commodity']['masses'],
) {
  const [{ after, before, facilityId, includeNegativeMasses }] = ctx.queryKey;
  return await CommodityService.getProducedCommodityMasses(
    facilityId,
    after.toString(),
    before.toString(),
    includeNegativeMasses,
  );
}

export function useProducedCommodityMasses({
  after,
  before,
  facilityId,
  includeNegativeMasses = false,
}: {
  after: Temporal.Instant;
  before: Temporal.Instant;
  facilityId: string;
  includeNegativeMasses?: boolean;
}) {
  return useQuery({
    queryKey: queryKeys.commodity.masses({
      after,
      before,
      facilityId,
      includeNegativeMasses,
    }),
    queryFn: fetchProducedCommodityMasses,
  });
}

async function fetchCommodityInternalMaterialSourceCompositions(
  ctx: QueryFunctionContexts['internalSource']['analysis']['compositionByCommodity']['detail'],
) {
  const [{ commodityId, intervalStart, intervalEnd }] = ctx.queryKey;
  return await CommodityService.getCommodityInternalMaterialSourceCompositions(
    commodityId,
    intervalStart,
    intervalEnd,
  );
}

export function useCommodityInternalMaterialSourceCompositions(args: {
  commodityId: string;
  intervalStart: string | undefined;
  intervalEnd: string | undefined;
}) {
  return useQuery({
    queryFn: fetchCommodityInternalMaterialSourceCompositions,
    queryKey:
      queryKeys.internalSource.analysis.compositionByCommodity.detail(args),
  });
}
