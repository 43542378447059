import { Container } from '@mantine/core';
import { AppPage } from '../App/AppPage';
import { AddTruckLoadTransferForm } from './AddTruckLoadTransferForm';

export function AddTruckLoadTransferPage() {
  return (
    <AppPage breadcrumbs={[]} title='Add Truck Load Transfer'>
      <AppPage.Section>
        <Container maw={500}>
          <AddTruckLoadTransferForm />
        </Container>
      </AppPage.Section>
    </AppPage>
  );
}
