import { Group, Stack, Title } from '@mantine/core';
import { AppPage } from '../App/AppPage';
import AddCommodityButton from './AddCommodityButton';
import { CommodityTable } from './CommodityTable';
import AddDefaultCommodityAssignmentButton from './DefaultCommodityAssignment/AddDefaultCommodityAssignmentButton';
import { DefaultCommodityAssignmentTable } from './DefaultCommodityAssignment/DefaultCommodityAssignmentTable';
import { UnassignedCommodityPathTable } from './UnassignedCommodityPathTable';

export function CommoditiesPage() {
  return (
    <AppPage title='Commodities'>
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Commodities</Title>
            <AddCommodityButton addCommodityDrawerFormProps={{}} />
          </Group>
          <CommodityTable />
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Default Commodity Assignments</Title>
            <AddDefaultCommodityAssignmentButton
              addDefaultCommodityAssignmentFormProps={{}}
            />
          </Group>
          <DefaultCommodityAssignmentTable />
        </Stack>
      </AppPage.Section>
      <AppPage.Section>
        <Stack>
          <Title order={2}>Unassigned Commodity Paths</Title>
          <UnassignedCommodityPathTable />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
