import { AppPage } from '../App/AppPage';
import ProcessProductionHistory from './ProcessProductionHistory';

export default function ProcessProductionHistoryPage() {
  return (
    <AppPage title='Process Production History'>
      <ProcessProductionHistory />
    </AppPage>
  );
}
