import {
  Anchor,
  AnchorProps,
  Button,
  ButtonProps,
  MantineColor,
  NavLink,
} from '@mantine/core';
import { useLinkProps } from '@swan-io/chicane';
import { ReactNode } from 'react';

interface LinkProps {
  to: string;
  label: ReactNode;
  icon?: ReactNode;
  color?: 'dimmed' | MantineColor;
  className?: string;
}

export function Link({ to, icon, label, className = '' }: LinkProps) {
  const linkProps = useLinkProps({ href: to });
  return (
    <NavLink {...linkProps} icon={icon} label={label} className={className} />
  );
}

export type LinkTextProps = Omit<AnchorProps, 'href' | 'onClick'> & {
  to: string;
  replace?: boolean;
};

export function LinkText(props: LinkTextProps) {
  const { to, replace = false, ...rest } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { active, onClick } = useLinkProps({ href: to, replace });
  return (
    <Anchor
      href={to}
      onClick={(e) => {
        onClick(e);
      }}
      {...rest}
    />
  );
}

type LinkButtonProps = Omit<ButtonProps, 'component' | 'href' | 'onClick'> & {
  to: string;
  replace?: boolean;
};
export function LinkButton(props: LinkButtonProps) {
  const { to, replace, children, ...other } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { active, onClick } = useLinkProps({ href: to, replace });

  return (
    <Button component='a' href={to} onClick={onClick} {...other}>
      {children}
    </Button>
  );
}
