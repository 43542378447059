export const PCTFORMAT = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 1,
});

export function formatPercentage(v: number | null) {
  if (v === null) {
    return 'unknown%';
  }
  return PCTFORMAT.format(v);
}
