import { Skeleton, Text } from '@mantine/core';
import {
  InternalMaterialSinkName,
  InternalMaterialSinkNameProps,
} from '../Repository/RepositoryName';
import { useInternalMaterialSink } from '../api/internalMaterialSink';

export type InternalMaterialSinkIdNameProps = Omit<
  InternalMaterialSinkNameProps,
  'internalMaterialSink'
> & { internalMaterialSinkId: string };

export function InternalMaterialSinkIdName(
  props: InternalMaterialSinkIdNameProps,
) {
  const { internalMaterialSinkId, ...rest } = props;
  const { data: internalMaterialSink, isError } = useInternalMaterialSink(
    internalMaterialSinkId,
  );

  if (internalMaterialSink) {
    return (
      <InternalMaterialSinkName
        internalMaterialSink={internalMaterialSink}
        {...rest}
      />
    );
  }

  if (isError) {
    return <Text color='red'>Error getting name</Text>;
  }

  return (
    <Skeleton visible>
      <Text>Loading name...</Text>
    </Skeleton>
  );
}
