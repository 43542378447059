import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ScaleId,
  ScaleReadingCreationDTO,
  ScaleReadingDTO,
  ScaleReadingPatchDTO,
  ScaleService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchScale(ctx: QueryFunctionContexts['scale']['detail']) {
  const [{ scaleId }] = ctx.queryKey;
  return await ScaleService.getScaleById(scaleId);
}

export function useScale(scaleId: ScaleId) {
  return useQuery({
    queryKey: queryKeys.scale.detail(scaleId),
    queryFn: fetchScale,
  });
}

async function fetchScales(ctx: QueryFunctionContexts['scale']['list']) {
  const [
    {
      facilityId,
      scaleTypeId,
      containerTypeId,
      truckLoadCapable,
      sampleCapable,
    },
  ] = ctx.queryKey;
  return await ScaleService.getAllScales(
    facilityId,
    scaleTypeId,
    containerTypeId,
    truckLoadCapable,
    sampleCapable,
  );
}

export function useScales({
  facilityId,
  scaleTypeId,
  containerTypeId,
  truckLoadCapable,
  sampleCapable,
}: {
  facilityId?: string;
  scaleTypeId?: string;
  containerTypeId?: string;
  truckLoadCapable?: boolean;
  sampleCapable?: boolean;
}) {
  return useQuery({
    queryKey: queryKeys.scale.list({
      facilityId,
      scaleTypeId,
      containerTypeId,
      truckLoadCapable,
      sampleCapable,
    }),
    queryFn: fetchScales,
  });
}

async function fetchScaleTypes() {
  return await ScaleService.getAllScaleTypes();
}

export function useScaleTypes() {
  return useQuery({
    queryKey: queryKeys.scale.type.list(),
    queryFn: fetchScaleTypes,
  });
}

async function fetchScaleType(
  ctx: QueryFunctionContexts['scale']['type']['detail'],
) {
  const [{ scaleTypeId }] = ctx.queryKey;
  return await ScaleService.getScaleTypeById(scaleTypeId);
}

export function useScaleType(scaleTypeId: string) {
  return useQuery({
    queryKey: queryKeys.scale.type.detail(scaleTypeId),
    queryFn: fetchScaleType,
  });
}

async function fetchScaleReadings(
  ctx: QueryFunctionContexts['scale']['reading']['list'],
) {
  const [{ scaleId }] = ctx.queryKey;
  return await ScaleService.getAllScaleReadings(scaleId);
}

export function useScaleReadings(scaleId: string) {
  return useQuery({
    queryKey: queryKeys.scale.reading.list(scaleId),
    queryFn: fetchScaleReadings,
  });
}

async function fetchScaleReading(
  ctx: QueryFunctionContexts['scale']['reading']['detail'],
) {
  const [{ scaleId, scaleReadingId }] = ctx.queryKey;
  return await ScaleService.getScaleReadingById(scaleId, scaleReadingId);
}

export function useScaleReading(scaleId: string, scaleReadingId: string) {
  return useQuery({
    queryKey: queryKeys.scale.reading.detail(scaleId, scaleReadingId),
    queryFn: fetchScaleReading,
  });
}

async function addScaleReading({
  scaleId,
  reading,
}: {
  scaleId: string;
  reading: ScaleReadingCreationDTO;
}) {
  await ScaleService.createScaleReading(scaleId, reading);
}

export function useAddScaleReading() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: addScaleReading,
    onSettled(data, error, { scaleId }) {
      invalidator.invalidateInventoryLedger();
      invalidator.invalidateKey(queryKeys.scale.reading.list(scaleId));
    },
  });
}

async function patchScaleReading({
  reading,
  patch,
}: {
  reading: ScaleReadingDTO;
  patch: ScaleReadingPatchDTO;
}) {
  await ScaleService.patchScaleReading(reading.scaleId, reading.id, patch);
}

export function usePatchScaleReading() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: patchScaleReading,
    onSettled(data, error, { reading }) {
      invalidator.invalidateKeys(
        queryKeys.scale.reading.detail(reading.scaleId, reading.id),
        queryKeys.scale.reading.list(reading.scaleId),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function deleteScaleReading(reading: ScaleReadingDTO) {
  await ScaleService.deleteScaleReading(reading.scaleId, reading.id);
}

export function useDeleteScaleReading() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteScaleReading,
    onError(error, reading) {
      invalidator.resetKey(
        queryKeys.scale.reading.detail(reading.scaleId, reading.id),
      );
      invalidator.resetKey(queryKeys.scale.reading.list(reading.scaleId));
    },
    onSuccess(data, reading) {
      invalidator.removeKey(
        queryKeys.scale.reading.detail(reading.scaleId, reading.id),
      );
    },
    onSettled(data, error, reading) {
      invalidator.invalidateKeys(queryKeys.scale.reading.list(reading.scaleId));
      invalidator.invalidateInventoryLedger();
    },
  });
}
