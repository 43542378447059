import { Group, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { ContainerIdName } from '../Container/ContainerIdName';
import { useFacilityContext } from '../Facility/FacilityContext';
import { InternallySourcedMaterialIdName } from '../InternallySourcedMaterial/InternallySourcedMaterialIdName';
import { PurchasedMaterialIdName } from '../PurchasedMaterial/PurchasedMaterialIdName';
import { SoldMaterialIdName } from '../SoldMaterial/SoldMaterialIdName';
import { TruckLoadIdName } from '../TruckLoad/TruckLoadIdName';
import { RepositoryOccupationStartDTO } from '../rest-client';

export function RepositoryOccupationStart({
  occupationStart,
}: {
  occupationStart: RepositoryOccupationStartDTO;
}) {
  const facility = useFacilityContext();

  const { startTime } = occupationStart;

  const name = match(occupationStart)
    .with({ kind: 'Container' }, ({ containerId }) => (
      <ContainerIdName
        containerId={containerId}
        variant='icon-name-link'
        time={dayjs.utc(occupationStart.startTime)}
      />
    ))
    .with(
      { kind: 'InternallySourcedMaterial' },
      ({ internallySourcedMaterialId }) => (
        <InternallySourcedMaterialIdName
          variant='icon-name-link'
          internallySourcedMaterialId={internallySourcedMaterialId}
        />
      ),
    )
    .with({ kind: 'PurchasedMaterial' }, ({ purchasedMaterialId }) => (
      <PurchasedMaterialIdName
        purchasedMaterialId={purchasedMaterialId}
        variant='icon-name-link'
      />
    ))
    .with({ kind: 'SoldMaterial' }, ({ soldMaterialId }) => (
      <SoldMaterialIdName
        soldMaterialId={soldMaterialId}
        variant='icon-name-link'
      />
    ))
    .with({ kind: 'TruckLoad' }, ({ truckLoadId }) => (
      <TruckLoadIdName truckLoadId={truckLoadId} variant='icon-name-link' />
    ))
    .exhaustive();

  return (
    <Group position='apart'>
      {name}
      <Text>{dayjs.utc(startTime).tz(facility.timeZoneId).format('l')}</Text>
    </Group>
  );
}
