import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ContainerIcon } from '../Icons';
import {
  AddContainerDrawerForm,
  AddContainerDrawerFormProps,
} from './AddContainerDrawerForm';

export type AddContainerButtonProps = Omit<ButtonProps, 'onClick'> & {
  addContainerDrawerFormProps: Omit<
    AddContainerDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function AddContainerButton(props: AddContainerButtonProps) {
  const {
    addContainerDrawerFormProps,
    leftIcon = <ContainerIcon />,
    children = 'Add Container',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <AddContainerDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...addContainerDrawerFormProps}
      />
    </>
  );
}
