import {
  ActionIcon,
  Alert,
  Button,
  Card,
  Center,
  Checkbox,
  Flex,
  FocusTrap,
  Grid,
  Group,
  Loader,
  Modal,
  NumberInput,
  Paper,
  Progress,
  RingProgress,
  Select,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { IconPencil, IconRefresh } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { CommodityName } from '../Commodity/CommodityName';
import { InternalMaterialSourceIcon } from '../Icons';
import { InternalMaterialSourceIdName } from '../InternalMaterialSource/InternalMaterialSourceIdName';
import { InternallySourcedMaterialIdName } from '../InternallySourcedMaterial/InternallySourcedMaterialIdName';
import { MaterialClassSetName } from '../MaterialClassSet/MaterialClassSetName';
import { BinaryConfusionMatrixStats } from '../RecoveryGoal/BinaryConfusionMatrixStats';
import {
  RecoveryGoalExcludedIcon,
  RecoveryGoalIncludedIcon,
} from '../RecoveryGoal/RecoveryGoalIcons';
import { RecoveryGoalTree } from '../RecoveryGoal/RecoveryGoalTree';
import { RecoveryStrategyName } from '../RecoveryStrategy/RecoveryStategyName';
import { TableEmptyBasicContent } from '../TableEmptyBasicContent';
import { useCommodities } from '../api/commodity';
import {
  useInternalMaterialSourceComposition,
  useInternalMaterialSources,
} from '../api/internalMaterialSource';
import { usePatchMaterialClassSetComposition } from '../api/materialClassSetComposition';
import { useRecoveryGoals } from '../api/recoveryGoal';
import {
  usePatchRecoveryStrategySimulation,
  useRecoveryStrategySimulation,
} from '../api/recoveryStrategySimulation';
import { LabeledValue } from '../common';
import { EChart } from '../echarts/BareEChart';
import { useCategoricalColors } from '../lib/colors';
import { formatPercentage } from '../lib/percentages';
import {
  CommodityDTO,
  CommoditySpotPriceDTO,
  InternallySourcedMaterialCompositionDTO,
  InternalMaterialSourceBulkCompositionAnalysisResultDTO,
  InternalMaterialSourceDTO,
  InternalMaterialSourceId,
  MaterialClassSetDTO,
  RecoveryGoalProbTreeDTO,
  WeightUnit,
} from '../rest-client';
import { Router } from '../router';
import { getWeightFromNetWeight } from '../util/weightFromNetWeight';
import { DeleteRecoveryStrategySimulationButton } from './DeleteRecoveryStrategySimulationButton';
import { MaterialClassLegendItem } from './MaterialClassLegendItem';
import {
  getDefaultCommodityShares,
  IndividualCommodityShare,
} from './RecoveryStrategySimulationCommodityAssignmentUtils';
import {
  RecoveryStrategySimulationCompositionCtxProvider,
  useRecoveryStrategySimulationCompositionCtx,
} from './RecoveryStrategySimulationCompositionContext';
import {
  RecoveryStrategySimulationCtxProvider,
  useRecoveryStrategySimulationCtx,
} from './RecoveryStrategySimulationContext';
import classes from './RecoveryStrategySimulationDetailPage.module.css';
import { RecoveryStrategySimulationDiagram } from './RecoveryStrategySimulationDiagram';

export default function RecoveryStrategySimulationDetailPage(props: {
  simulationId: string;
}) {
  const { simulationId } = props;

  const { data: simulation } = useRecoveryStrategySimulation(simulationId);

  const [selectedMaterialClassId, setSelectedMaterialClassId] = useState<
    string | null
  >(null);
  const [selectedRecoveryGoalProbTree, setSelectedRecoveryGoalProbTree] =
    useState<RecoveryGoalProbTreeDTO | null>(null);
  const [selectedOutput, setSelectedOutput] = useState<
    'positive' | 'negative' | null
  >(null);
  const [feedTotal, setFeedTotal] = useState<number | null>(null);

  if (!simulation) {
    return (
      <Center>
        <Loader variant='bars' size='xl' />
      </Center>
    );
  }

  return (
    <AppPage
      breadcrumbs={[
        {
          title: 'Recovery Strategies',
          routeName: Router.RecoveryStrategyList(),
        },
        {
          title: simulation.recoveryStrategy.name,
          routeName: Router.RecoveryStrategyDetail({
            recoveryStrategyId: simulation.recoveryStrategy.id,
          }),
        },
        'Simulations',
        simulation.name,
      ]}
      titleRight={
        <DeleteRecoveryStrategySimulationButton simulationId={simulationId} />
      }
    >
      <RecoveryStrategySimulationCtxProvider
        simulation={simulation}
        selectedMaterialClassId={selectedMaterialClassId}
        setSelectedMaterialClassId={setSelectedMaterialClassId}
        selectedRecoveryGoalProbTree={selectedRecoveryGoalProbTree}
        setSelectedRecoveryGoalProbTree={setSelectedRecoveryGoalProbTree}
        selectedOutput={selectedOutput}
        setSelectedOutput={setSelectedOutput}
        feedTotal={feedTotal}
        setFeedTotal={setFeedTotal}
      >
        <RecoveryStrategySimulationDetailsSection />
        <AppPage.Section>
          <Flex justify='space-between' align='center'>
            <Title order={3}>Simulation Parameters</Title>
          </Flex>
          <SimulationParametersInput />
          <Flex gap={'md'} justify={'start'} align={'end'}>
            <NumberInput
              size='md'
              mt='lg'
              w='fit-content'
              label='Feed Total'
              value={feedTotal ?? ''}
              onChange={(v) => setFeedTotal(v === '' ? null : v)}
            />
            <PopulateFromInternalMaterialSourceModal />
          </Flex>
        </AppPage.Section>
        <Stack spacing='xs'>
          <Title order={2} ta='center'>
            Mass Flow
          </Title>
          <RecoveryStrategySimulationDiagram />
        </Stack>
        <Stack>
          <SelectedRecoveryGoalMetrics />
          <SelectedOutputComposition />
        </Stack>
        <Stack spacing='xs'>
          <Title order={2} ta='center'>
            Spread Analysis
          </Title>
          <SimulationSpreadAnalysis />
        </Stack>
      </RecoveryStrategySimulationCtxProvider>
    </AppPage>
  );
}

function RecoveryStrategySimulationDetailsSection() {
  const { simulation } = useRecoveryStrategySimulationCtx();

  return (
    <AppPage.Section>
      <Stack>
        <Stack spacing='xs'>
          <Title order={3}>Simulation Details</Title>
          <Group>
            <LabeledValue label='Name'>{simulation.name}</LabeledValue>
            <LabeledValue label='Created at'>
              {dayjs.utc(simulation.insertedAt).format('LLL')}
            </LabeledValue>
            <LabeledValue label='Recovery Strategy'>
              <RecoveryStrategyName
                recoveryStrategy={simulation.recoveryStrategy}
              />
            </LabeledValue>
            <LabeledValue label='Sample Analysis Material Class Set'>
              <MaterialClassSetName
                materialClassSet={simulation.materialClassSet}
              />
            </LabeledValue>
            <CompositionSourceInfoText />
            <CommoditySourceInfoEditor />
          </Group>
        </Stack>
        <Stack spacing='xs'>
          <Title order={4}>Recovery Strategy Behavior Tree</Title>
          <Paper withBorder p='md'>
            <RecoveryGoalTree tree={simulation.recoveryGoalBehaviorTree} />
          </Paper>
        </Stack>
      </Stack>
    </AppPage.Section>
  );
}

function SimulationParametersInput() {
  const { simulation } = useRecoveryStrategySimulationCtx();
  const colors = useCategoricalColors();

  const rgQuery = useRecoveryGoals();
  const recoveryGoals =
    rgQuery.data && new Map(rgQuery.data.map((rg) => [rg.id, rg]));
  const recoveryGoalIdSet = new Set<string>();
  simulation.recoveryStrategy.paths
    .flatMap((p) => p.steps.map((s) => s.recoveryGoalId))
    .forEach((recoveryGoalId) => {
      recoveryGoalIdSet.add(recoveryGoalId);
    });

  const recoveryGoalIds = [...recoveryGoalIdSet];

  const inputCompositionTotal = Object.values(
    simulation.inputComposition.materialClassesProportions,
  ).reduce((t, v) => t + v, 0);

  // TODO(2339): highlight row and column with color?

  return (
    <Table>
      <thead>
        <tr>
          <th>Material Class</th>
          <th style={{ textAlign: 'end' }}>Composition</th>
          <th>Feedstock %</th>

          {recoveryGoalIds.map((rgId) => (
            <th
              key={rgId}
              onClick={() => {
                // TODO(2339): Change the recovery goal
              }}
            >
              {recoveryGoals?.get(rgId)?.name ?? ''}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {simulation.materialClassSet.materialClasses.map((materialClass, i) => {
          const total = Object.values(
            simulation.inputComposition.materialClassesProportions,
          ).reduce((t, v) => t + v, 0);

          const proportion =
            simulation.inputComposition.materialClassesProportions[
              materialClass.id
            ];

          return (
            <tr key={materialClass.id}>
              <td>
                <MaterialClassLegendItem
                  materialClass={materialClass}
                  color={colors[i]}
                />
              </td>

              <td>
                <InputCompositionInput
                  key={materialClass.id}
                  materialClassId={materialClass.id}
                />
              </td>

              <td>
                <Progress
                  h='100%'
                  radius='xs'
                  color={colors[i]}
                  value={(100 * proportion) / inputCompositionTotal}
                />
                {proportion >= 0
                  ? (100 * (proportion / total)).toFixed(2) + '%'
                  : '?'}
              </td>

              {recoveryGoalIds.map((rgId) => {
                const rg = recoveryGoals?.get(rgId);
                const rgClasses = rg
                  ? new Set(rg.materialClasses.map((mc) => mc.id))
                  : undefined;
                const classIncluded = rgClasses?.has(materialClass.id);
                return (
                  <td
                    key={`${rgId}-${materialClass.id}`}
                    style={{ textAlign: 'right' }}
                  >
                    <Group position='center' noWrap spacing={0}>
                      {match(classIncluded)
                        .with(undefined, () => <Loader size='xs' />)
                        .with(true, () => (
                          <RecoveryGoalIncludedIcon
                            size='1.4em'
                            color='green'
                          />
                        ))
                        .with(false, () => (
                          <RecoveryGoalExcludedIcon
                            size='1.4em'
                            color='orange'
                          />
                        ))
                        .exhaustive()}
                      <RingProgress
                        size={25}
                        thickness={5}
                        sections={[
                          {
                            value:
                              simulation.recoveryGoalAccuracies[rgId][
                                materialClass.id
                              ] * 100,
                            color: 'teal',
                          },
                          {
                            value:
                              100 -
                              simulation.recoveryGoalAccuracies[rgId][
                                materialClass.id
                              ] *
                                100,
                            color: 'red',
                          },
                        ]}
                      />
                      <RecoveryGoalAccuracyInput
                        recoveryGoalId={rgId}
                        materialClassId={materialClass.id}
                      />
                    </Group>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function InputCompositionInput(props: { materialClassId: string }) {
  const { materialClassId } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();

  const patchMutation = usePatchMaterialClassSetComposition();
  const [value, setValue] = useState<number | undefined>(undefined);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const proportions = new Map(
      Object.entries(simulation.inputComposition.materialClassesProportions),
    );

    setValue(proportions.get(materialClassId));
  }, [materialClassId, simulation.inputComposition.materialClassesProportions]);

  const mutate = useCallback(() => {
    if (value === undefined) return;
    return patchMutation.mutate(
      {
        id: simulation.inputComposition.id,
        patch: { materialClassesProportions: { [materialClassId]: value } },
      },
      {
        onSuccess() {
          setEditing(false);
        },
      },
    );
  }, [
    patchMutation,
    simulation.inputComposition.id,
    value,
    materialClassId,
    setEditing,
  ]);

  if (patchMutation.isError) {
    return (
      <Button
        color='red'
        onClick={() => {
          setEditing(false);
          patchMutation.reset();
          setValue(
            simulation.inputComposition.materialClassesProportions[
              materialClassId
            ],
          );
        }}
      >
        <IconRefresh />
      </Button>
    );
  }

  return editing ? (
    <FocusTrap>
      <NumberInput
        data-autofocus
        hideControls
        disabled={patchMutation.isLoading}
        rightSection={patchMutation.isLoading ? <Loader size='xs' /> : null}
        w='8ch'
        value={value ?? ''}
        onChange={(v) => {
          if (v === '') {
            setValue(undefined);
          } else {
            setValue(v);
          }
        }}
        onBlur={() => mutate()}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            mutate();
          }
        }}
        precision={3}
      />
    </FocusTrap>
  ) : (
    <Text ta='right' onClick={() => setEditing(true)}>
      {value?.toFixed(3) ?? '-'}
    </Text>
  );
}

function RecoveryGoalAccuracyInput(props: {
  recoveryGoalId: string;
  materialClassId: string;
}) {
  const { recoveryGoalId, materialClassId } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();

  const [editing, setEditing] = useState(false);
  const patchMutation = usePatchRecoveryStrategySimulation();

  const currentAccuracy =
    simulation.recoveryGoalAccuracies[recoveryGoalId][materialClassId];

  const [accuracy, setAccuracy] = useState(currentAccuracy);

  const mutate = useCallback(() => {
    patchMutation.mutate(
      {
        simulationId: simulation.id,
        patch: {
          recoveryGoalAccuracies: {
            [recoveryGoalId]: { [materialClassId]: accuracy },
          },
        },
      },
      {
        onSuccess() {
          setEditing(false);
        },
      },
    );
  }, [
    patchMutation,
    simulation.id,
    recoveryGoalId,
    materialClassId,
    accuracy,
    setEditing,
  ]);

  if (patchMutation.isError) {
    return (
      <Button
        color='red'
        onClick={() => {
          setEditing(false);
          patchMutation.reset();
          setAccuracy(currentAccuracy);
        }}
      >
        <IconRefresh />
      </Button>
    );
  }

  return editing ? (
    <NumberInput
      disabled={patchMutation.isLoading}
      rightSection={patchMutation.isLoading ? <Loader size='xs' /> : null}
      ref={(e) => e?.focus()}
      w='8ch'
      step={0.01}
      precision={2}
      hideControls
      value={accuracy * 100}
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          mutate();
        }
      }}
      onChange={(v) => {
        if (v === '') {
          setAccuracy(0);
        } else {
          setAccuracy(v / 100);
        }
      }}
      onBlur={() => mutate()}
    />
  ) : (
    <Text weight={500} onClick={() => setEditing(true)}>
      {(accuracy * 100).toFixed(2)}%
    </Text>
  );
}

function SelectedOutputComposition() {
  const {
    simulation,
    selectedRecoveryGoalProbTree,
    selectedOutput,
    feedTotal,
  } = useRecoveryStrategySimulationCtx();

  const colors = useCategoricalColors();

  const valueFormatter = useCallback(
    (val: number | null) => (val === null ? '?' : val.toFixed(2)),
    [],
  );

  if (selectedRecoveryGoalProbTree === null) {
    return null;
  }

  if (simulation.recoveryGoalProbTree === null) {
    return null;
  }

  if (selectedOutput === null) {
    return null;
  }

  const { recoveryGoal } = selectedRecoveryGoalProbTree;

  const outputProb = selectedRecoveryGoalProbTree[`${selectedOutput}Prob`];

  const outputTotalProb = Object.values(outputProb).reduce((a, b) => a + b, 0);

  const matchingCommodityAssignment =
    simulation.defaultCommodityAssignments?.filter(
      (dca) => dca.path.steps.at(-1)?.recoveryGoalId === recoveryGoal.id,
    )[0];
  const commodityPrice =
    matchingCommodityAssignment?.outputCommodity?.commoditySpotPrices?.at(-1) ??
    null;
  const spotPrice =
    commodityPrice?.weightUnit === WeightUnit.POUND
      ? commodityPrice.usdPerUnitOfWeight
      : null;

  return (
    <AppPage.Section>
      <Title order={3}>
        {recoveryGoal.name} {selectedOutput} output
      </Title>
      {feedTotal ? (
        <LabeledValue label='Output Total'>
          {(feedTotal * outputTotalProb).toFixed(1)}
        </LabeledValue>
      ) : null}
      {spotPrice && feedTotal && (
        <LabeledValue label='Output Price'>
          ${(feedTotal * outputTotalProb * spotPrice).toLocaleString()}
        </LabeledValue>
      )}

      <EChart
        h={500}
        w='100%'
        option={{
          grid: { containLabel: true, top: 0, bottom: 0 },
          tooltip: {
            valueFormatter: feedTotal ? valueFormatter : formatPercentage,
          },
          yAxis: {
            type: 'category',
            data: simulation.materialClassSet.materialClasses
              .map((mc) => mc.name)
              .reverse(),
          },
          xAxis: {
            type: 'value',
            axisLabel: {
              formatter: feedTotal ? undefined : formatPercentage,
            },
          },
          series: [
            {
              type: 'bar',
              data: simulation.materialClassSet.materialClasses
                .map((mc, i) => ({
                  value: feedTotal
                    ? feedTotal * outputProb[mc.id]
                    : outputProb[mc.id] / outputTotalProb,
                  itemStyle: {
                    color: colors[i],
                  },
                }))
                .reverse(),
            },
          ],
        }}
      />
    </AppPage.Section>
  );
}

function SelectedRecoveryGoalMetrics() {
  const { simulation, selectedRecoveryGoalProbTree } =
    useRecoveryStrategySimulationCtx();

  if (selectedRecoveryGoalProbTree === null) {
    return null;
  }

  if (simulation.recoveryGoalProbTree === null) {
    return null;
  }

  const { recoveryGoal } = selectedRecoveryGoalProbTree;

  return (
    <>
      <Title order={3}>{recoveryGoal.name} Recovery Performance</Title>
      <Group position='apart'>
        <BinaryConfusionMatrixStats
          matrix={selectedRecoveryGoalProbTree.binaryConfusionMatrix}
        />
      </Group>
    </>
  );
}

function CommoditySourceInfoEditor() {
  const { simulation } = useRecoveryStrategySimulationCtx();
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <LabeledValue label='Input Commodity'>
      <Modal
        opened={opened}
        onClose={close}
        title='Commodity Association'
        centered
        size={'xl'}
      >
        <Stack>
          <Text fw='700' mb='md'>
            Select an input commodity to associate with this simulation
          </Text>
          <CommoditySelector onSelect={close} />
        </Stack>
      </Modal>

      <Flex justify='space-between' align={'center'} gap='lg'>
        <Text>
          {simulation.inputCommodity ? (
            <CommodityName commodity={simulation.inputCommodity} />
          ) : (
            'None'
          )}
        </Text>
        <ActionIcon onClick={open} variant='subtle'>
          <IconPencil />
        </ActionIcon>
      </Flex>
    </LabeledValue>
  );
}

function CommoditySelector(props: { onSelect?: () => void }) {
  const { onSelect } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();
  const [selectedCommodityId, setSelectedCommodityId] = useState<
    string | undefined
  >(undefined);
  const commoditiesQuery = useCommodities();
  const patchRecoverySimulationMutation = usePatchRecoveryStrategySimulation();

  const mutateRecoverySimulationCompositionSource = () => {
    if (selectedCommodityId === undefined) {
      throw new Error('no selected commodity');
    }
    const patchMutationArgs = {
      simulationId: simulation.id,
      patch: {
        inputCommodityId: selectedCommodityId,
      },
    };
    patchRecoverySimulationMutation.mutate(patchMutationArgs, {
      onSuccess() {
        onSelect?.();
      },
    });
  };

  if (patchRecoverySimulationMutation.isError) {
    return (
      <Button
        color='red'
        onClick={() => {
          patchRecoverySimulationMutation.reset();
        }}
      >
        <IconRefresh /> Mutation Failed - Reset
      </Button>
    );
  }

  if (commoditiesQuery.data) {
    return (
      <Stack align='end' m='lg'>
        <Table highlightOnHover={true} p='lg'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {commoditiesQuery.data.map((c) => {
              return (
                <tr
                  className={`${classes.pointer} ${selectedCommodityId === c.id ? classes.selected : ''}`}
                  key={c.id}
                  onClick={() => setSelectedCommodityId(c.id)}
                >
                  <td>{c.name}</td>
                  <td>{c.description ?? '-'}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button
          w='10rem'
          onClick={() => {
            mutateRecoverySimulationCompositionSource();
          }}
          disabled={!selectedCommodityId}
          loading={patchRecoverySimulationMutation.isLoading}
        >
          Apply
        </Button>
      </Stack>
    );
  }

  if (commoditiesQuery.isLoading) {
    return (
      <Skeleton h='10rem'>
        <></>
      </Skeleton>
    );
  }

  if (commoditiesQuery.isLoadingError) {
    return (
      <Flex align={'center'} justify={'center'} w='100%' h='100%'>
        <Alert title='Uh oh...' color='red' miw={'50%'}>
          Failed to load available commodities.
        </Alert>
      </Flex>
    );
  }
}

function CompositionSourceInfoText() {
  const { simulation } = useRecoveryStrategySimulationCtx();
  return (
    <LabeledValue label='Composition Source'>
      {simulation.aggregateCompositionSource !== null ? (
        <Flex gap={4}>
          {`${simulation.aggregateCompositionSource.isWeighted ? 'Weighted' : 'Unweighted'}`}
          <InternalMaterialSourceIdName
            internalMaterialSourceId={
              simulation.aggregateCompositionSource.internalMaterialSourceId
            }
          />
        </Flex>
      ) : simulation.internallySourcedMaterialCompositionSource !== null ? (
        <InternallySourcedMaterialIdName
          internallySourcedMaterialId={
            simulation.internallySourcedMaterialCompositionSource
              .internallySourcedMaterialId
          }
        />
      ) : (
        `Manual Entry`
      )}
    </LabeledValue>
  );
}

interface CompositionSelectorTableStatusTextProps {
  isEmpty: boolean;
  hasMaterialSet: boolean;
}

function CompositionSelectorTableStatusText(
  props: CompositionSelectorTableStatusTextProps,
) {
  const { isEmpty, hasMaterialSet } = props;
  const dataInfoMessage =
    "The data below is the set of observed or computed compositions from material sets matching the material class of this simulation. Select an entry and click 'Apply' to populate the simulation.";
  const nothingMessage = !hasMaterialSet
    ? isEmpty
      ? 'No Compositions Found for Material Source'
      : 'Material Class Set for Simulation does not match that of found compositions.'
    : '';

  return isEmpty || !hasMaterialSet ? (
    <Flex
      justify={'center'}
      align={'center'}
      miw={'100%'}
      p={'md'}
      mih={'100%'}
      mt={'xl'}
    >
      <TableEmptyBasicContent>{nothingMessage}</TableEmptyBasicContent>
    </Flex>
  ) : (
    <Alert title='Compositional Data Breakdown' color='blue' miw={'100%'}>
      {dataInfoMessage}
    </Alert>
  );
}

interface InternallySourcedCompositionSelectorTableProps {
  internallySourcedMaterialCompositions: InternallySourcedMaterialCompositionDTO[];
  materialClassSet: MaterialClassSetDTO;
}

function InternallySourcedCompositionSelectorTable(
  props: InternallySourcedCompositionSelectorTableProps,
) {
  const { materialClassSet, internallySourcedMaterialCompositions } = props;

  const {
    setSelectedMaterialClassSetComposition,
    selectedSourceComposition,
    setSelectedSourceComposition,
  } = useRecoveryStrategySimulationCompositionCtx();

  return (
    <Table w='100%' highlightOnHover={true}>
      <thead>
        <tr>
          <th style={{ textAlign: 'start', width: '10rem' }}>Name</th>
          {materialClassSet.materialClasses.map((materialClass) => {
            return <th key={materialClass.id}>{materialClass.name}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {internallySourcedMaterialCompositions.map(
          (composition: InternallySourcedMaterialCompositionDTO) => {
            const compPercentages =
              composition.materialClassSetCompositions[materialClassSet.id];
            const compValues = Object.fromEntries(
              Object.entries(compPercentages).map(([key, percentage]) => {
                return [
                  key,
                  percentage *
                    (composition.netWeight === null
                      ? 100
                      : getWeightFromNetWeight(composition.netWeight)),
                ];
              }),
            );
            return (
              <tr
                key={composition.internallySourcedMaterial.id}
                className={`${classes.pointer} ${selectedSourceComposition === composition.internallySourcedMaterial.id ? classes.selected : ''}`}
                onClick={() => {
                  setSelectedMaterialClassSetComposition(compValues);
                  setSelectedSourceComposition(
                    composition.internallySourcedMaterial.id,
                  );
                }}
              >
                <td style={{ maxWidth: '10%' }}>
                  {composition.internallySourcedMaterial.name}
                </td>
                {materialClassSet.materialClasses.map((materialClass) => {
                  return (
                    <td key={materialClass.id}>
                      {Number(compPercentages[materialClass.id]).toFixed(2)}
                    </td>
                  );
                })}
              </tr>
            );
          },
        )}
      </tbody>
    </Table>
  );
}

interface AggregateCompositionSourceSelectorTableProps {
  weightedCompositions: Record<string, Record<string, number>>;
  unweightedCompositions: Record<string, Record<string, number>>;
  materialClassSet: MaterialClassSetDTO;
}

function AggregateCompositionSourceSelectorTable(
  props: AggregateCompositionSourceSelectorTableProps,
) {
  const { weightedCompositions, unweightedCompositions, materialClassSet } =
    props;
  const {
    selectedSourceComposition,
    setSelectedSourceComposition,
    setSelectedMaterialClassSetComposition,
  } = useRecoveryStrategySimulationCompositionCtx();

  const weightedSource = 'weighted';
  const unweightedSource = 'unweighted';
  const hasWeightedComp = materialClassSet.id in weightedCompositions;
  const hasUnweightedComp = materialClassSet.id in unweightedCompositions;

  return (
    <Table w='100%' highlightOnHover={true}>
      <thead>
        <tr>
          <th style={{ textAlign: 'start', width: '10rem' }}>Name</th>
          {materialClassSet.materialClasses.map((materialClass) => {
            return <th key={materialClass.id}>{materialClass.name}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {hasUnweightedComp && (
          <tr
            className={`${classes.pointer} ${selectedSourceComposition === unweightedSource ? classes.selected : ''}`}
            onClick={() => {
              const percentages = unweightedCompositions[materialClassSet.id];
              const compVals = Object.fromEntries(
                Object.entries(percentages).map(([key, value]) => [
                  key,
                  value * 100,
                ]),
              );
              setSelectedMaterialClassSetComposition(compVals);
              setSelectedSourceComposition(unweightedSource);
            }}
          >
            <td>Unweighted Composition</td>
            {materialClassSet.materialClasses.map((materialClass) => {
              const compVals = unweightedCompositions[materialClassSet.id];
              return (
                <td key={materialClass.id}>
                  {Number(compVals[materialClass.id]).toFixed(2)}
                </td>
              );
            })}
          </tr>
        )}
        {hasWeightedComp && (
          <tr
            className={`${classes.pointer} ${selectedSourceComposition === weightedSource ? classes.selected : ''}`}
            onClick={() => {
              const percentages = weightedCompositions[materialClassSet.id];
              const compVals = Object.fromEntries(
                Object.entries(percentages).map(([key, value]) => [
                  key,
                  value * 100,
                ]),
              );
              setSelectedMaterialClassSetComposition(compVals);
              setSelectedSourceComposition(weightedSource);
            }}
          >
            <td>Weighted Composition</td>
            {materialClassSet.materialClasses.map((materialClass) => {
              const compVals = weightedCompositions[materialClassSet.id];
              return (
                <td key={materialClass.id}>
                  {Number(compVals[materialClass.id]).toFixed(2)}
                </td>
              );
            })}
          </tr>
        )}
      </tbody>
    </Table>
  );
}

interface CompositionSourceAnalysisProps {
  imsId: string | null;
  imsCommodityId: string | undefined;
  interval: [string | undefined, string | undefined];
  analysis: InternalMaterialSourceBulkCompositionAnalysisResultDTO;
}

function CompositionSelectorTable(
  props: ModalProps & CompositionSourceAnalysisProps,
) {
  const { onClose, imsId, imsCommodityId, interval, analysis } = props;
  const { simulation } = useRecoveryStrategySimulationCtx();

  const [
    selectedMaterialClassSetComposition,
    setSelectedMaterialClassSetComposition,
  ] = useState<Record<string, number>>(
    simulation.inputComposition.materialClassesProportions,
  );
  const [selectedSourceComposition, setSelectedSourceComposition] =
    useState<string>(simulation.inputComposition.id);
  const [associateCommodity, setAssociateCommodity] = useState<boolean>(true);

  const patchRecoverySimulationMutation = usePatchRecoveryStrategySimulation();
  const mutateRecoverySimulationCompositionSource = useCallback(() => {
    const patchMutationArgs = match(selectedSourceComposition)
      .with('', () => {
        return null;
      })
      .with(weightedSource, () => {
        return {
          simulationId: simulation.id,
          patch: {
            aggregateCompositionSource: {
              internalMaterialSourceId: imsId ?? '',
              intervalStart: interval[0] ?? null,
              intervalEnd: interval[1] ?? null,
              isWeighted: true,
            },
            materialClassSetComposition: {
              materialClassesProportions: selectedMaterialClassSetComposition,
            },
            ...(associateCommodity && { inputCommodityId: imsCommodityId }),
          },
        };
      })
      .with(unweightedSource, () => {
        return {
          simulationId: simulation.id,
          patch: {
            aggregateCompositionSource: {
              internalMaterialSourceId: imsId ?? '',
              intervalStart: interval[0] ?? null,
              intervalEnd: interval[1] ?? null,
              isWeighted: false,
            },
            materialClassSetComposition: {
              materialClassesProportions: selectedMaterialClassSetComposition,
            },
            ...(associateCommodity && { inputCommodityId: imsCommodityId }),
          },
        };
      })
      .otherwise(() => {
        return {
          simulationId: simulation.id,
          patch: {
            internallySourcedMaterialCompositionSource: {
              internallySourcedMaterialId: selectedSourceComposition,
            },
            materialClassSetComposition: {
              materialClassesProportions: selectedMaterialClassSetComposition,
            },
            ...(associateCommodity && { inputCommodityId: imsCommodityId }),
          },
        };
      });
    if (patchMutationArgs !== null)
      patchRecoverySimulationMutation.mutate(patchMutationArgs);
  }, [
    selectedSourceComposition,
    patchRecoverySimulationMutation,
    simulation.id,
    imsId,
    imsCommodityId,
    associateCommodity,
    interval,
    selectedMaterialClassSetComposition,
  ]);

  if (analysis === null) return <></>;

  const isEmpty = analysis.internallySourcedMaterialCompositions.length === 0;
  const hasMaterialSet =
    simulation.materialClassSet.id in analysis.materialClassSets;
  const weightedSource = 'weighted';
  const unweightedSource = 'unweighted';

  return (
    <Stack justify={'end'} h='100%'>
      <RecoveryStrategySimulationCompositionCtxProvider
        selectedMaterialClassSetComposition={
          selectedMaterialClassSetComposition
        }
        setSelectedMaterialClassSetComposition={
          setSelectedMaterialClassSetComposition
        }
        selectedSourceComposition={selectedSourceComposition}
        setSelectedSourceComposition={setSelectedSourceComposition}
      >
        <CompositionSelectorTableStatusText
          isEmpty={isEmpty}
          hasMaterialSet={hasMaterialSet}
        />
        {hasMaterialSet && (
          <InternallySourcedCompositionSelectorTable
            internallySourcedMaterialCompositions={
              analysis.internallySourcedMaterialCompositions
            }
            materialClassSet={simulation.materialClassSet}
          />
        )}
        {hasMaterialSet && (
          <AggregateCompositionSourceSelectorTable
            weightedCompositions={analysis.weightedCompositions}
            unweightedCompositions={analysis.unweightedCompositions}
            materialClassSet={simulation.materialClassSet}
          />
        )}
        <Flex align={'center'} justify={'end'} w={'100%'}>
          {patchRecoverySimulationMutation.isError ? (
            <Button
              miw='100%'
              color='red'
              onClick={() => {
                patchRecoverySimulationMutation.reset();
                setSelectedMaterialClassSetComposition(
                  simulation.inputComposition.materialClassesProportions,
                );
                setSelectedSourceComposition(simulation.inputComposition.id);
              }}
            >
              <IconRefresh />
              <Text>An Error Occurred - Click Here to Reset</Text>
            </Button>
          ) : (
            !isEmpty && (
              <Flex justify={'between'} gap={'lg'} align={'center'}>
                <Checkbox
                  checked={associateCommodity}
                  onChange={(e) => setAssociateCommodity(e.target.checked)}
                  label={'Inherit Commodity'}
                  labelPosition='left'
                />
                <Button
                  onClick={() => {
                    mutateRecoverySimulationCompositionSource();
                    onClose?.();
                  }}
                  disabled={
                    patchRecoverySimulationMutation.isLoading ||
                    selectedSourceComposition === simulation.inputComposition.id
                  }
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      mutateRecoverySimulationCompositionSource();
                      onClose?.();
                    }
                  }}
                >
                  Apply
                </Button>
              </Flex>
            )
          )}
        </Flex>
      </RecoveryStrategySimulationCompositionCtxProvider>
    </Stack>
  );
}

interface ModalProps {
  onOpen?: () => void;
  onClose?: () => void;
}

function PopulateFromInternalMaterialSourceWidget(props: ModalProps) {
  const [selectedImsId, setSelectedImsId] =
    useState<InternalMaterialSourceId | null>(null);
  const [interval, setInterval] = useState<
    [string | undefined, string | undefined]
  >([undefined, undefined]);

  const selectionMsg = 'No Material Source Selected';

  const imsQuery = useInternalMaterialSources();

  const imsCompositionQuery = useInternalMaterialSourceComposition({
    internalMaterialSourceId: selectedImsId,
    intervalStart: interval[0],
    intervalEnd: interval[1],
  });

  if (imsQuery.data) {
    return (
      <Stack miw={'100%'}>
        <Flex
          justify={'space-between'}
          align={'center'}
          miw={'100%'}
          mih={'100%'}
        >
          <Select
            size={'md'}
            mt='lg'
            miw={'49%'}
            label='Get Composition From Material Source'
            value={selectedImsId ?? ''}
            onChange={(ims: string) => setSelectedImsId(ims)}
            data={
              imsQuery.data?.map((f) => ({
                value: f.id,
                label: f.name,
              })) ?? []
            }
            disabled={imsQuery.isLoading}
            dropdownPosition={'bottom'}
          />
          <DatePickerInput
            size={'md'}
            mt={'lg'}
            miw={'49%'}
            label='Date Range for Source Composition'
            type='range'
            onChange={(val: DatesRangeValue) => {
              setInterval([val[0]?.toISOString(), val[1]?.toISOString()]);
            }}
          />
        </Flex>
        <Skeleton
          visible={imsCompositionQuery.isLoading && selectedImsId !== null}
          mih='20rem'
          mt='lg'
        >
          {imsCompositionQuery.data ? (
            <CompositionSelectorTable
              {...props}
              imsId={selectedImsId}
              imsCommodityId={
                imsQuery.data.find(
                  (s: InternalMaterialSourceDTO) => s.id === selectedImsId,
                )?.commodityId ?? undefined
              }
              interval={interval}
              analysis={imsCompositionQuery.data}
            />
          ) : (
            <Stack justify='center' align='center' h='100%'>
              <Alert
                title='Select a Material Source'
                color='blue'
                miw={'100%'}
                mb={'xl'}
              >
                In order to autopopulate material composition you must select a
                material source.
              </Alert>
              <TableEmptyBasicContent>{selectionMsg}</TableEmptyBasicContent>
            </Stack>
          )}
        </Skeleton>
      </Stack>
    );
  }

  if (imsQuery.isLoading) {
    return <Skeleton mih='30rem' w='100%'></Skeleton>;
  }

  if (imsQuery.error || imsQuery.isLoadingError) {
    return (
      <Stack h='30rem'>
        <Flex align={'center'} justify={'center'} w='100%' h='100%'>
          <Alert title='Uh oh...' color='red' miw={'50%'}>
            Failed to load Internal Material Sources. Check your network
            connectivity.
          </Alert>
        </Flex>
      </Stack>
    );
  }

  if (imsCompositionQuery.isLoadingError || imsCompositionQuery.isError) {
    return (
      <Stack h='30rem'>
        <Flex align={'center'} justify={'center'} w='100%' h='100%'>
          <Alert title='Uh oh...' color='red' miw={'50%'}>
            Failed to load composition for internal material sources. Check your
            network connectivity.
          </Alert>
        </Flex>
      </Stack>
    );
  }
}

function PopulateFromInternalMaterialSourceModal() {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title='Composition Association'
        centered
        size='100%'
      >
        <Stack mih='100%'>
          <Text fw='700' mb='md'>
            Associate a Material Source to automatically configure your material
            composition.
          </Text>
          <PopulateFromInternalMaterialSourceWidget
            onClose={close}
            onOpen={open}
          />
        </Stack>
      </Modal>

      <Button
        leftIcon={<InternalMaterialSourceIcon />}
        onClick={open}
        w='fit-content'
        size='md'
      >
        Populate Composition from Material Source
      </Button>
    </>
  );
}

function SimulationSpreadAnalysis() {
  const { simulation, feedTotal } = useRecoveryStrategySimulationCtx();

  const defaultFeedstockMass = Object.entries(
    simulation.inputComposition.materialClassesProportions,
  ).reduce((acc, [, v]) => (acc += v), 0);

  const defaultCommodityShares = getDefaultCommodityShares(
    simulation.inputCommodity,
    simulation.defaultCommodityAssignments,
    simulation.recoveryGoalProbTree,
    feedTotal ?? defaultFeedstockMass,
  );

  const [commodityShares, setCommodityShares] = useState<
    Record<string, IndividualCommodityShare>
  >(defaultCommodityShares);

  const entries = Object.entries(commodityShares);
  const inputs = entries.filter(([, v]) => !v.isOutput);
  const outputs = entries.filter(([, v]) => v.isOutput);
  const inputTotalMass = inputs.reduce(
    (acc, [, curV]) => acc + curV.massShare,
    0,
  );
  const outputTotalMass = outputs.reduce(
    (acc, [, curV]) => acc + curV.massShare,
    0,
  );
  const inputTotalPrice = inputs.reduce(
    (acc, [, curV]) => acc + curV.massShare * (curV.usdPerUnitOfWeight ?? NaN),
    0,
  );
  const outputTotalPrice = outputs.reduce(
    (acc, [, curV]) => acc + curV.massShare * (curV.usdPerUnitOfWeight ?? NaN),
    0,
  );

  const delta = Math.round((outputTotalPrice - inputTotalPrice) * 100) / 100;

  /**
   * When the simulation updates, the reference could be stale, so reinitialize
   */
  useEffect(() => {
    setCommodityShares((prev) => {
      const newDefaultShares = getDefaultCommodityShares(
        simulation.inputCommodity,
        simulation.defaultCommodityAssignments,
        simulation.recoveryGoalProbTree,
        feedTotal ?? defaultFeedstockMass,
      );

      // ensure that local state spot prices are persisted
      Object.entries(prev).forEach(([k, v]) => {
        if (k in newDefaultShares) {
          newDefaultShares[k].usdPerUnitOfWeight = v.usdPerUnitOfWeight;
        }
      });
      return newDefaultShares;
    });
  }, [simulation, setCommodityShares, defaultFeedstockMass, feedTotal]);

  return (
    <Stack spacing={'lg'}>
      <Flex>
        <Card w='100%'>
          <Title order={3}>
            Net
            {inputTotalPrice && outputTotalPrice ? (
              <Text color={delta != 0 ? (delta > 0 ? 'green' : 'red') : 'blue'}>
                ${(outputTotalPrice - inputTotalPrice).toLocaleString()}
              </Text>
            ) : (
              <Text color='red'>Not Calculable</Text>
            )}
          </Title>
        </Card>
      </Flex>
      <Flex gap='lg'>
        <Card w='50%'>
          <Title order={3}>Inputs</Title>
          <Flex gap='lg' justify={'space-between'}>
            <LabeledValue label='Feedstock Mass'>
              {inputTotalMass?.toFixed(1)} lbs
            </LabeledValue>
            <LabeledValue label='Total Price'>
              {inputTotalPrice
                ? `$${inputTotalPrice?.toLocaleString()}`
                : 'N/A'}
            </LabeledValue>
            <CommoditySourceInfoEditor />
          </Flex>
        </Card>
        <Card w='50%'>
          <Title order={3}>Outputs</Title>
          <Flex gap='lg' justify={'space-between'}>
            <LabeledValue label='Associated Mass'>
              {outputTotalMass
                ? `${outputTotalMass.toFixed(1)} lbs`
                : 'Not Calculable'}
            </LabeledValue>
            <LabeledValue label='Total Price'>
              {outputTotalPrice
                ? `$${outputTotalPrice?.toLocaleString()}`
                : 'N/A'}
            </LabeledValue>
            <LabeledValue label='Commodities'>
              <Flex gap='sm'>
                {simulation.defaultCommodityAssignments.map((dca) => {
                  return dca.outputCommodity ? (
                    <CommodityName
                      key={dca.outputCommodity?.id}
                      commodity={dca.outputCommodity}
                    />
                  ) : (
                    <></>
                  );
                })}
              </Flex>
            </LabeledValue>
          </Flex>
        </Card>
      </Flex>
      <Grid align='center' justify='center'>
        {simulation.inputCommodity && (
          <Grid.Col span={4}>
            <CommodityWidget
              commodity={simulation.inputCommodity}
              isOutput={false}
              commodityShares={commodityShares}
              setCommodityShares={setCommodityShares}
            />
          </Grid.Col>
        )}
        {simulation.defaultCommodityAssignments.map((dca) =>
          dca.outputCommodity ? (
            <Grid.Col span={4} key={dca.id}>
              <CommodityWidget
                isOutput={true}
                commodity={dca.outputCommodity}
                commodityShares={commodityShares}
                setCommodityShares={setCommodityShares}
              />
            </Grid.Col>
          ) : (
            <></>
          ),
        )}
        {simulation.defaultCommodityAssignments.length !==
          Object.entries(simulation.recoveryGoalAccuracies).length + 1 && (
          <Grid.Col span={4}>
            <Alert title='Hmm...' color='blue' miw={'100%'}>
              Could not find commodities for all recovery goals. Check to see if
              all expected commodity paths have been created.
            </Alert>
          </Grid.Col>
        )}
      </Grid>
    </Stack>
  );
}

interface CommodityWidgetProps {
  commodity: CommodityDTO;
  isOutput: boolean;
  commodityShares: Record<string, IndividualCommodityShare>;
  setCommodityShares: React.Dispatch<
    React.SetStateAction<Record<string, IndividualCommodityShare>>
  >;
}

function CommodityWidget(props: CommodityWidgetProps) {
  const { commodity, isOutput, commodityShares, setCommodityShares } = props;
  const defaultPrice = commodity.commoditySpotPrices?.at(-1) ?? null;
  const [activeSpotPrice, setActiveSpotPrice] =
    useState<CommoditySpotPriceDTO | null>(defaultPrice);

  useEffect(() => {
    setCommodityShares((prev: Record<string, IndividualCommodityShare>) => {
      const updated = { ...prev };
      const prevMassShare = prev[commodity.id]?.massShare ?? 0;
      updated[commodity.id] = {
        isOutput,
        massShare: prevMassShare,
        usdPerUnitOfWeight: activeSpotPrice
          ? activeSpotPrice.usdPerUnitOfWeight
          : NaN,
        weightUnit: activeSpotPrice
          ? activeSpotPrice.weightUnit
          : WeightUnit.POUND,
      };
      return updated;
    });
  }, [activeSpotPrice, isOutput, commodity, setCommodityShares]);

  return (
    <Card>
      <Title align='center' order={4}>
        {isOutput ? 'Output' : 'Input'} &#8594; {commodity.name}
      </Title>
      <Stack>
        <Flex gap='md' justify='space-between'>
          <LabeledValue label='Spot Price'>
            {activeSpotPrice ? (
              activeSpotPrice.weightUnit === WeightUnit.POUND ? (
                `$${activeSpotPrice.usdPerUnitOfWeight}/lb`
              ) : (
                <Text color='red'>Incompatible - Must Override</Text>
              )
            ) : (
              <Text>None - Must Override</Text>
            )}
          </LabeledValue>
          <LabeledValue label='Mass'>
            {commodity.id in commodityShares &&
            commodityShares[commodity.id]?.massShare
              ? `${commodityShares[commodity.id].massShare.toFixed(1)} lbs`
              : 'Unknown'}
          </LabeledValue>
          <LabeledValue label='Total Price'>
            {activeSpotPrice ? (
              activeSpotPrice.weightUnit === WeightUnit.POUND ? (
                <Text>
                  {commodity.id in commodityShares &&
                  commodityShares[commodity.id]?.massShare
                    ? `$${(
                        activeSpotPrice.usdPerUnitOfWeight *
                        commodityShares[commodity.id].massShare
                      ).toLocaleString()}`
                    : 'N/A'}
                </Text>
              ) : (
                <Text color='red'>Incalculable</Text>
              )
            ) : (
              <Text color="red'">Incalculable</Text>
            )}
          </LabeledValue>
        </Flex>
        <Flex gap='lg' justify={'space-between'} align={'center'}>
          <NumberInput
            precision={3}
            placeholder={'4.75'}
            label='Manual Spot Price'
            value={activeSpotPrice?.usdPerUnitOfWeight}
            onChange={(v) => {
              setActiveSpotPrice(
                v !== ''
                  ? {
                      id: commodity.id,
                      pricedAt: new Date().toString(),
                      usdPerUnitOfWeight: v,
                      weightUnit: WeightUnit.POUND,
                    }
                  : defaultPrice,
              );
            }}
          />
        </Flex>
      </Stack>
    </Card>
  );
}
