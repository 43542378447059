import {
  Alert,
  Center,
  Flex,
  Group,
  List,
  Loader,
  Radio,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { IconInfoCircle, IconInfoTriangle } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { CompositionChart } from '../CompositionChart';
import { CompositionComparisonChart } from '../CompositionComparisonChart';
import { InferredPropertyTextWrapper } from '../InferredPropertyWrapper';
import { InternallySourcedMaterialName } from '../Repository/RepositoryName';
import NetWeight from '../Weights/NetWeight';
import { useInternalMaterialSourceComposition } from '../api/internalMaterialSource';
import { LabeledValue } from '../common';
import {
  InternalMaterialSourceId,
  InternallySourcedMaterialCompositionDTO,
  MaterialClassSetDTO,
  MergedInternallySourcedMaterialCompositionDTO,
} from '../rest-client';
import { composition } from '../util/mixture';

export function InternalMaterialSourceConstituentsComposition(props: {
  internalMaterialSourceId: InternalMaterialSourceId;
}) {
  const { internalMaterialSourceId } = props;

  const {
    data: imsComposition,
    isError,
    error,
    isLoading,
  } = useInternalMaterialSourceComposition({
    internalMaterialSourceId,
    intervalStart: undefined,
    intervalEnd: undefined,
  });

  if (isError) {
    throw error;
  }

  const graphSection = match(imsComposition)
    .with(undefined, () => (
      <Center>
        <Loader variant='bars' size='xl' />
      </Center>
    ))
    .when(
      ({
        internallySourcedMaterialCompositions,
        mergedInternallySourcedMaterialCompositions,
        noCompositionInternallySourcedMaterials,
      }) =>
        internallySourcedMaterialCompositions.length === 0 &&
        mergedInternallySourcedMaterialCompositions.length === 0 &&
        noCompositionInternallySourcedMaterials.length === 0,
      () => (
        <Alert color='blue' title='No Upstream Sourced Materials'>
          This upstream material source has not been used. Once an upstream
          sourced material have been created and sample(s) recorded, the
          inferred composition of this material source will be shown here.
        </Alert>
      ),
    )
    .when(
      ({
        internallySourcedMaterialCompositions,
        mergedInternallySourcedMaterialCompositions,
      }) =>
        internallySourcedMaterialCompositions.length === 0 &&
        mergedInternallySourcedMaterialCompositions.length === 0,
      () => (
        <Alert
          color='blue'
          title='No Inferred Upstream Sourced Material Compositions'
        >
          None of the upstream-sourced material constituents&apos; compositions
          for this internally souced material could be inferred. In order for
          the upstream-sourced material&apos;s composition to be able to be
          inferred, one of the following sampling coverage cases needs to be
          true:
          <List size='sm' mr='md'>
            <List.Item>
              <Text fw={700} span>
                Feedstock Sampled
              </Text>
              <Text span>
                : the upstream-sourced material has a direct hand sample, i.e.,
                a sample recorded on feedstock material derived from the
                upstream-sourced material before it was processed.
              </Text>
            </List.Item>
            <List.Item>
              <Text fw={700} span>
                Complete Recovery Sequence Sampling Coverage
              </Text>
              <Text span>
                : for one or more completed recovery sequences processing
                material derived from the upstream-sourced material, all outputs
                must have associated hand sample compositions recorded.
              </Text>
            </List.Item>
            <List.Item>
              <Text fw={700} span>
                Partial Recovery Sequence Sampling Coverage
              </Text>
              <Text span>
                : for one or more completed recovery sequences processing
                material derived from the upstream-sourced material, if not all
                the outputs have associated hand sample compositions, then one
                of the recovery steps must have hand sample compositions
                recorded for both the eject and pass fractions, and the previous
                steps must have hand sample compositions recorded for the eject
                fractions.
              </Text>
            </List.Item>
          </List>
        </Alert>
      ),
    )
    .otherwise((analysis) =>
      Object.entries(analysis.materialClassSets).map(([mcsId, mcs]) => (
        <InternalMaterialSourceCompositionChart
          key={mcsId}
          materialClassSet={mcs}
          singleIsmCompositions={analysis.internallySourcedMaterialCompositions}
          mergedIsmCompositions={
            analysis.mergedInternallySourcedMaterialCompositions
          }
          unweightedSourceComposition={analysis.unweightedCompositions}
          weightedSourceComposition={analysis.weightedCompositions}
        />
      )),
    );

  return (
    <AppPage.Section>
      <Stack>
        <Title order={2}>Inferred Upstream Source Composition</Title>
        <Group>
          <Text c='dimmed' style={{ alignSelf: 'flex-end' }}>
            Upstream Sourced Material Count
          </Text>
          <LabeledValue
            label='Sampled'
            infoIconContent='Number of inferred compositions derived from a single upstream-sourced material'
          >
            <Skeleton visible={isLoading}>
              {match(
                imsComposition?.internallySourcedMaterialCompositions.length,
              )
                .with(undefined, () => <Text>Loading...</Text>)
                .otherwise((singleIsmCount) => singleIsmCount)}
            </Skeleton>
          </LabeledValue>
          <LabeledValue
            label='Mixed & Sampled'
            infoIconContent='Number of inferred compositions derived from multiple upstream-sourced materials. This is a result of mixing of upstream-sourced materials before samples were recorded.'
          >
            <Skeleton visible={isLoading}>
              {match(
                imsComposition?.mergedInternallySourcedMaterialCompositions
                  .length,
              )
                .with(undefined, () => <Text>Loading...</Text>)
                .otherwise((mergedIsmCount) => mergedIsmCount)}
            </Skeleton>
          </LabeledValue>

          <LabeledValue
            label='No Composition'
            infoIconContent='Number of upstream-sourced materials where no composition could be inferred.'
          >
            <Skeleton visible={isLoading}>
              {match(
                imsComposition?.noCompositionInternallySourcedMaterials.length,
              )
                .with(undefined, () => <Text>Loading...</Text>)
                .otherwise((noCompCount) => noCompCount)}
            </Skeleton>
          </LabeledValue>
        </Group>
        {graphSection}
      </Stack>
    </AppPage.Section>
  );
}

function InternalMaterialSourceCompositionChart(props: {
  materialClassSet: MaterialClassSetDTO;
  singleIsmCompositions: InternallySourcedMaterialCompositionDTO[];
  mergedIsmCompositions: MergedInternallySourcedMaterialCompositionDTO[];
  unweightedSourceComposition: Record<string, Record<string, number>>;
  weightedSourceComposition: Record<string, Record<string, number>>;
}) {
  const {
    materialClassSet,
    singleIsmCompositions,
    mergedIsmCompositions,
    unweightedSourceComposition,
    weightedSourceComposition,
  } = props;

  type CompTypeValue =
    | 'individual'
    | 'weightedAggregate'
    | 'unweightedAggregate';

  const [compTypeValue, setCompTypeValue] =
    useState<CompTypeValue>('individual');

  const materialClassNameLookup = new Map(
    materialClassSet.materialClasses.map((mc) => [mc.id, mc.name]),
  );

  const singleISMComps = singleIsmCompositions.map(
    (ismComp) =>
      ({
        name: ismComp.internallySourcedMaterial.name,
        composition: composition(
          Object.entries(
            ismComp.materialClassSetCompositions[materialClassSet.id],
          ).map(
            ([mcId, p]) =>
              [materialClassNameLookup.get(mcId) ?? mcId, p] as const,
          ),
        ),
      }) as const,
  );

  const mergedIsmComps = mergedIsmCompositions.map(
    (ismComp) =>
      ({
        name: ismComp.internallySourcedMaterials
          .map((ism) => ism.name)
          .join(' + '),
        composition: composition(
          Object.entries(
            ismComp.materialClassSetCompositions[materialClassSet.id],
          ).map(
            ([mcId, p]) =>
              [materialClassNameLookup.get(mcId) ?? mcId, p] as const,
          ),
        ),
      }) as const,
  );

  const allIsmComps = [...singleISMComps, ...mergedIsmComps];
  const ismComps = allIsmComps;

  const aggregateUnweightedComp = composition(
    Object.entries(unweightedSourceComposition[materialClassSet.id]).map(
      ([mcId, p]) => [materialClassNameLookup.get(mcId) ?? mcId, p] as const,
    ),
  );
  const aggregatedUnweightedCompInfo = `Aggregate all of the upstream-sourced material
    compositions, weighted equally, ignoring the their inferred masses.`;
  const onlyOneIsmComp = allIsmComps.length === 1;
  const onlyOneIsmCompInfo = `There is only one upstream-sourced material composition 
    that could be inferred, so there is no set of upstream-sourced material compositions 
    to aggregate over.`;

  const materialClassSetWeightedComposition = Object.entries(
    weightedSourceComposition[materialClassSet.id],
  );
  // this is undefined if there is no valid inferred material class set composition
  const aggregateWeightedComp =
    materialClassSetWeightedComposition.length === 0
      ? undefined
      : composition(
          Object.entries(weightedSourceComposition[materialClassSet.id]).map(
            ([mcId, p]) =>
              [materialClassNameLookup.get(mcId) ?? mcId, p] as const,
          ),
        );
  const aggregateWeightedCompInfo = `Aggregate all of the upstream-sourced material
    compositions, weighted by their inferred weights.`;
  const noAggregateWeightedComp = aggregateWeightedComp === undefined;
  const noAggregateWeightedCompInfo = `No weight averaged composition for this internal material 
    source could be computed because there are no upstream-sourced materials with both 
    inferred compositions and weights.`;

  return (
    <Stack>
      <Group position='apart'>
        <Title order={3}>Upstream Sourced Material Compositions</Title>
        <Flex justify='flex-end'>
          <Radio.Group
            value={compTypeValue}
            onChange={(value: CompTypeValue) => setCompTypeValue(value)}
            name='compositionType'
            label='Composition Type'
          >
            <Group>
              <Radio value='individual' label='Individual' />
              <Radio
                value='unweightedAggregate'
                disabled={onlyOneIsmComp}
                label={
                  <Group spacing={5}>
                    <Text>Unweighted Average</Text>
                    <Tooltip
                      label={
                        onlyOneIsmComp
                          ? onlyOneIsmCompInfo
                          : aggregatedUnweightedCompInfo
                      }
                      multiline
                      width={500}
                    >
                      {onlyOneIsmComp ? (
                        <IconInfoTriangle color='orange' size={20} />
                      ) : (
                        <IconInfoCircle color='grey' size={20} />
                      )}
                    </Tooltip>
                  </Group>
                }
              />
              <Radio
                value='weightedAggregate'
                disabled={onlyOneIsmComp || noAggregateWeightedComp}
                label={
                  <Group spacing={5}>
                    <Text>Weighted Average</Text>
                    <Tooltip
                      label={
                        noAggregateWeightedComp
                          ? noAggregateWeightedCompInfo
                          : onlyOneIsmComp
                            ? onlyOneIsmCompInfo
                            : aggregateWeightedCompInfo
                      }
                      multiline
                      width={500}
                    >
                      {noAggregateWeightedComp ? (
                        <IconInfoTriangle color='orange' size={20} />
                      ) : (
                        <IconInfoCircle color='grey' size={20} />
                      )}
                    </Tooltip>
                  </Group>
                }
              />
            </Group>
          </Radio.Group>
        </Flex>
      </Group>
      {match(compTypeValue)
        .with('individual', () => (
          <CompositionComparisonChart
            compositions={ismComps}
            seriesLabelEnabled={false}
          />
        ))
        .with('unweightedAggregate', () => (
          <CompositionChart
            composition={aggregateUnweightedComp}
            rotateXAxisLabels
          />
        ))
        .with('weightedAggregate', () => {
          if (!aggregateWeightedComp) throw new Error();
          return (
            <CompositionChart
              composition={aggregateWeightedComp}
              rotateXAxisLabels
            />
          );
        })
        .exhaustive()}
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Date Sourced</th>
            <th style={{ textAlign: 'right' }}>Mass</th>
            {materialClassSet.materialClasses.map((mc) => (
              <th key={mc.id} style={{ textAlign: 'right' }}>
                {mc.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {singleIsmCompositions.map((ismComp) => (
            <tr key={ismComp.internallySourcedMaterial.id}>
              <td>
                <InternallySourcedMaterialName
                  internallySourcedMaterial={ismComp.internallySourcedMaterial}
                />
              </td>
              <td>
                {dayjs(ismComp.internallySourcedMaterial.creationTime).format(
                  'L',
                )}
              </td>
              <td style={{ textAlign: 'right' }}>
                {match(ismComp.netWeight)
                  .with(null, () => <Text c='dimmed'>?</Text>)
                  .otherwise((netWeight) => (
                    <InferredPropertyTextWrapper>
                      <NetWeight
                        weight={netWeight}
                        sourceIconMode='icon-tooltip'
                      />
                    </InferredPropertyTextWrapper>
                  ))}
              </td>
              {materialClassSet.materialClasses.map((mc) => (
                <td key={mc.id} style={{ textAlign: 'right' }}>
                  {(
                    100 *
                    ismComp.materialClassSetCompositions[materialClassSet.id][
                      mc.id
                    ]
                  ).toFixed(1)}
                  %
                </td>
              ))}
            </tr>
          ))}
          {mergedIsmCompositions.map((ismComp) => (
            <tr
              key={ismComp.internallySourcedMaterials
                .map((ism) => ism.id)
                .join()}
            >
              <td>
                <Stack spacing={0}>
                  {ismComp.internallySourcedMaterials.map((ism) => (
                    <InternallySourcedMaterialName
                      key={ism.id}
                      internallySourcedMaterial={ism}
                    />
                  ))}
                </Stack>
              </td>
              <td>
                <Stack spacing={0}>
                  {ismComp.internallySourcedMaterials.map((ism) => (
                    <Text key={ism.id}>
                      {dayjs(ism.creationTime).format('L')}
                    </Text>
                  ))}
                </Stack>
              </td>
              <td style={{ textAlign: 'right' }}>
                {match(ismComp.netWeight)
                  .with(null, () => <Text c='dimmed'>?</Text>)
                  .otherwise((netWeight) => (
                    <InferredPropertyTextWrapper>
                      <NetWeight
                        weight={netWeight}
                        sourceIconMode='icon-tooltip'
                      />
                    </InferredPropertyTextWrapper>
                  ))}
              </td>
              {materialClassSet.materialClasses.map((mc) => (
                <td key={mc.id} style={{ textAlign: 'right' }}>
                  {(
                    100 *
                    ismComp.materialClassSetCompositions[materialClassSet.id][
                      mc.id
                    ]
                  ).toFixed(1)}
                  %
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Stack>
  );
}
