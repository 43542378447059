import { Group } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { useChutecSortPrograms } from '../../api/chutec';
import { SortSystemIdName } from '../SortSystemIdName';

export function ChutecSortProgramTable({
  sortSystemId,
}: {
  sortSystemId?: string;
}) {
  const sortProgramsQuery = useChutecSortPrograms(sortSystemId);

  if (sortProgramsQuery.isLoadingError) {
    throw sortProgramsQuery.error;
  }
  const sortPrograms = sortProgramsQuery.data;

  return (
    <DataTable
      records={sortPrograms}
      fetching={sortProgramsQuery.isLoading}
      columns={[
        { accessor: 'name' },
        {
          accessor: 'sortSystemIds',
          title: 'Sort Systems',
          hidden: sortSystemId !== undefined,
          render: (record) => (
            <Group>
              {record.sortSystemIds.map((id) => (
                <SortSystemIdName key={id} sortSystemId={id} />
              ))}
            </Group>
          ),
        },
      ]}
      highlightOnHover
      minHeight={100}
      noRecordsText='No Sort Programs'
    />
  );
}
