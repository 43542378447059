/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeedFlowGroupDTO } from '../models/FeedFlowGroupDTO';
import type { FeedFlowGroupMaterialDTO } from '../models/FeedFlowGroupMaterialDTO';
import type { LedgerErrorFeedFlowGroupClassificationPerformanceAnalysisResultDTO } from '../models/LedgerErrorFeedFlowGroupClassificationPerformanceAnalysisResultDTO';
import type { LedgerErrorFeedFlowGroupMassThroughputAnalysisResultDTO } from '../models/LedgerErrorFeedFlowGroupMassThroughputAnalysisResultDTO';
import type { LedgerErrorGenealogyResultDTO } from '../models/LedgerErrorGenealogyResultDTO';
import type { MaterialClassesNotAnalyzedFeedFlowGroupClassificationPerformanceAnalysisResultDTO } from '../models/MaterialClassesNotAnalyzedFeedFlowGroupClassificationPerformanceAnalysisResultDTO';
import type { NoCommonSampleAnalysisMaterialSetFeedFlowGroupClassificationPerformanceAnalysisResultDTO } from '../models/NoCommonSampleAnalysisMaterialSetFeedFlowGroupClassificationPerformanceAnalysisResultDTO';
import type { NoOutputMassFeedFlowGroupClassificationPerformanceAnalysisResultDTO } from '../models/NoOutputMassFeedFlowGroupClassificationPerformanceAnalysisResultDTO';
import type { NoOutputSampleAnalysisFeedFlowGroupClassificationPerformanceAnalysisResultDTO } from '../models/NoOutputSampleAnalysisFeedFlowGroupClassificationPerformanceAnalysisResultDTO';
import type { NoRecoveryGoalFeedFlowGroupClassificationPerformanceAnalysisResultDTO } from '../models/NoRecoveryGoalFeedFlowGroupClassificationPerformanceAnalysisResultDTO';
import type { NotAppliedFeedFlowGroupClassificationPerformanceAnalysisResultDTO } from '../models/NotAppliedFeedFlowGroupClassificationPerformanceAnalysisResultDTO';
import type { OccupiedGenealogyResultDTO } from '../models/OccupiedGenealogyResultDTO';
import type { SortSystemId } from '../models/SortSystemId';
import type { SuccessfulFeedFlowGroupClassificationPerformanceAnalysisResultDTO } from '../models/SuccessfulFeedFlowGroupClassificationPerformanceAnalysisResultDTO';
import type { SuccessfulFeedFlowGroupMassThroughputAnalysisResultDTO } from '../models/SuccessfulFeedFlowGroupMassThroughputAnalysisResultDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FeedFlowGroupService {
    /**
     * @param after
     * @param before
     * @param processId
     * @param systemId
     * @returns FeedFlowGroupDTO Success
     * @throws ApiError
     */
    public static getFeedFlowGroups(
        after?: string,
        before?: string,
        processId?: string,
        systemId?: SortSystemId,
    ): CancelablePromise<Array<FeedFlowGroupDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/feed-flow-groups',
            query: {
                'after': after,
                'before': before,
                'processId': processId,
                'systemId': systemId,
            },
        });
    }
    /**
     * @param after
     * @param before
     * @param processId
     * @param systemId
     * @returns FeedFlowGroupMaterialDTO Success
     * @throws ApiError
     */
    public static getFeedFlowGroupsMaterialSets(
        after?: string,
        before?: string,
        processId?: string,
        systemId?: SortSystemId,
    ): CancelablePromise<Array<FeedFlowGroupMaterialDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/feed-flow-groups/material-sets',
            query: {
                'after': after,
                'before': before,
                'processId': processId,
                'systemId': systemId,
            },
        });
    }
    /**
     * @param feedFlowGroupId
     * @returns any Success
     * @throws ApiError
     */
    public static getFeedFlowGroupFeedstockGenealogy(
        feedFlowGroupId: string,
    ): CancelablePromise<(OccupiedGenealogyResultDTO | LedgerErrorGenealogyResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/feed-flow-groups/{feedFlowGroupId}/feedstock-genealogy',
            path: {
                'feedFlowGroupId': feedFlowGroupId,
            },
        });
    }
    /**
     * @param feedFlowGroupId
     * @returns any Success
     * @throws ApiError
     */
    public static getFeedFlowGroupClassificationPerformance(
        feedFlowGroupId: string,
    ): CancelablePromise<(NotAppliedFeedFlowGroupClassificationPerformanceAnalysisResultDTO | MaterialClassesNotAnalyzedFeedFlowGroupClassificationPerformanceAnalysisResultDTO | NoCommonSampleAnalysisMaterialSetFeedFlowGroupClassificationPerformanceAnalysisResultDTO | NoOutputMassFeedFlowGroupClassificationPerformanceAnalysisResultDTO | NoOutputSampleAnalysisFeedFlowGroupClassificationPerformanceAnalysisResultDTO | NoRecoveryGoalFeedFlowGroupClassificationPerformanceAnalysisResultDTO | SuccessfulFeedFlowGroupClassificationPerformanceAnalysisResultDTO | LedgerErrorFeedFlowGroupClassificationPerformanceAnalysisResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/feed-flow-groups/{feedFlowGroupId}/classification-performance',
            path: {
                'feedFlowGroupId': feedFlowGroupId,
            },
        });
    }
    /**
     * @param feedFlowGroupId
     * @returns any Success
     * @throws ApiError
     */
    public static getFeedFlowGroupMassThroughputPerformance(
        feedFlowGroupId: string,
    ): CancelablePromise<(LedgerErrorFeedFlowGroupMassThroughputAnalysisResultDTO | SuccessfulFeedFlowGroupMassThroughputAnalysisResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/feed-flow-groups/{feedFlowGroupId}/mass-throughput-performance',
            path: {
                'feedFlowGroupId': feedFlowGroupId,
            },
        });
    }
}
