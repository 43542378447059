import { Button, Popover } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { IconCalendar } from '@tabler/icons-react';
import { useCallback, useState } from 'react';
import { TemporalPlainDateRangePicker } from '../../../Temporal/TemporalPlainDateRangePicker.tsx';
import {
  DateRange,
  PredefinedDateOffset,
  predefinedDateOffsets,
} from '../../../Temporal/dateRangeAndOffset.ts';
import Temporal from '../../../Temporal/temporal.ts';
import { useFacilityContext } from '../../FacilityContext.tsx';
import classes from './css/MetricDateRangeButton.module.css';

export function MetricDateRangeButton({
  dateRange,
  setDateRange,
}: {
  dateRange: Partial<DateRange>;
  setDateRange: (dateRange: Partial<DateRange>) => void;
}) {
  const [popoverOpened, togglePopoverOpened] = useToggle();
  const { timeZoneId } = useFacilityContext();
  // TODO make 'now' stateful so that it doesn't trigger fresh queries every render
  const nowPlainDate = Temporal.Now.plainDateISO(timeZoneId);

  const calOnSelect = useCallback(
    (dateRange: Partial<DateRange>) => {
      setDateRange(dateRange);
    },
    [setDateRange],
  );

  const { startDate, endDate, id: selectedId, name } = dateRange;
  const [datePickerVisible, setDatePickerVisible] = useState(
    selectedId === 'custom',
  );
  const customRangeString =
    startDate && endDate
      ? `${startDate.toLocaleString()} - ${endDate.toLocaleString()}`
      : 'select';
  const selectedDateRangeText =
    selectedId === 'custom' ? `Custom (${customRangeString})` : name;
  const customDateRange: Partial<DateRange> =
    selectedId === 'custom'
      ? dateRange
      : {
          id: 'custom',
          startDate: undefined,
          endDate: undefined,
        };
  return (
    <Popover
      opened={popoverOpened}
      onChange={togglePopoverOpened}
      position='bottom'
    >
      <Popover.Target>
        <Button
          leftIcon={<IconCalendar />}
          onClick={() => {
            togglePopoverOpened();
            if (selectedId === 'custom') {
              setDatePickerVisible(true);
            }
          }}
        >
          {selectedDateRangeText}
        </Button>
      </Popover.Target>
      <Popover.Dropdown className={classes.dropdown}>
        {/* TODO if a custom date is set, make sure picker is set to that month */}
        {datePickerVisible && (
          <TemporalPlainDateRangePicker
            dateRange={customDateRange}
            onSelect={(dateRange) => {
              togglePopoverOpened();
              setDatePickerVisible(false);
              calOnSelect({
                id: 'custom',
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
              });
            }}
            onCancel={() => {
              togglePopoverOpened();
              setDatePickerVisible(false);
            }} // TODO
          />
        )}
        {
          <Button.Group orientation='vertical'>
            {[...predefinedDateOffsets.values()].map(
              ({ id, name, calculateStartDate }: PredefinedDateOffset) => (
                <Button
                  key={id}
                  variant={selectedId === id ? 'filled' : 'subtle'}
                  onClick={() => {
                    togglePopoverOpened();
                    setDatePickerVisible(false);
                    calOnSelect({
                      id,
                      name,
                      startDate: calculateStartDate(nowPlainDate),
                      endDate: nowPlainDate,
                    });
                  }}
                >
                  {name}
                </Button>
              ),
            )}
            <Button
              variant={selectedId === 'custom' ? 'filled' : 'subtle'}
              onClick={() => {
                setDatePickerVisible(true);
              }}
            >
              Custom
            </Button>
          </Button.Group>
        }
      </Popover.Dropdown>
    </Popover>
  );
}
