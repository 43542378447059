import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { EditIcon } from '../Icons';
import {
  EditInternallySourcedMaterialDrawerForm,
  EditInternallySourcedMaterialDrawerFormProps,
} from './EditInternallySourcedMaterialDrawerForm';

export type EditInternallySourcedMaterialButtonProps = Omit<
  ButtonProps,
  'onClick'
> & {
  editInternallySourcedMaterialDrawerFormProps: Omit<
    EditInternallySourcedMaterialDrawerFormProps,
    'onClose' | 'opened'
  >;
};

export default function EditInternallySourcedMaterialButton(
  props: EditInternallySourcedMaterialButtonProps,
) {
  const {
    editInternallySourcedMaterialDrawerFormProps,
    leftIcon = <EditIcon />,
    children = 'Edit',
    ...buttonProps
  } = props;

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  return (
    <>
      <Button onClick={openDrawer} leftIcon={leftIcon} {...buttonProps}>
        {children}
      </Button>
      <EditInternallySourcedMaterialDrawerForm
        opened={drawerOpened}
        onClose={closeDrawer}
        {...editInternallySourcedMaterialDrawerFormProps}
      />
    </>
  );
}
