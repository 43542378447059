import { Text } from '@mantine/core';
import { Fragment } from 'react';
import { WithUnit } from '../WithUnit';
import { WeightUnit } from '../rest-client';
import { getWeightUnitAbbreviation } from './units';

export default function WeightUnitExpectedTotals(props: {
  expectedUnitTotals: Record<string, string>;
  totalIsNegative: boolean;
}) {
  const { expectedUnitTotals, totalIsNegative } = props;

  const unitTotalEntries = Object.entries(expectedUnitTotals);

  return (
    <div style={{ display: 'inline-flex' }}>
      {unitTotalEntries.length === 0 ? '0' : null}
      {unitTotalEntries.map(([unit, total], i) => (
        <Fragment key={unit}>
          <WithUnit
            unit={getWeightUnitAbbreviation(unit as WeightUnit)}
            c={totalIsNegative ? 'red' : undefined}
          >
            <Text c={totalIsNegative ? 'red' : undefined}>
              {new Intl.NumberFormat().format(parseInt(total))}
            </Text>
          </WithUnit>
          {i + 1 < unitTotalEntries.length ? <Text weight={500}>+</Text> : ''}
        </Fragment>
      ))}
    </div>
  );
}
