import { match } from 'ts-pattern';
import { WeightUnit } from '../rest-client';

export function getWeightUnitAbbreviation(unit: WeightUnit): string {
  return match(unit)
    .with(WeightUnit.GRAM, () => 'g')
    .with(WeightUnit.KILOGRAM, () => 'kg')
    .with(WeightUnit.POUND, () => 'lb')
    .with(WeightUnit.LONG_TON, () => 'LT')
    .with(WeightUnit.SHORT_TON, () => 'st')
    .with(WeightUnit.TONNE, () => 't')
    .exhaustive();
}

export function getWeightUnitName(unit: WeightUnit): string {
  return match(unit)
    .with(WeightUnit.GRAM, () => 'gram')
    .with(WeightUnit.KILOGRAM, () => 'kilogram')
    .with(WeightUnit.POUND, () => 'pound')
    .with(WeightUnit.LONG_TON, () => 'long ton')
    .with(WeightUnit.SHORT_TON, () => 'short ton')
    .with(WeightUnit.TONNE, () => 'tonne')
    .exhaustive();
}
