import { Flex, Paper } from '@mantine/core';
import {
  RecoveryGoalBehaviorTreeDTO,
  RecoveryGoalTreeDTO,
} from '../rest-client';
import MaterialClassEffects from './MaterialClassEffects';
import { RecoveryGoalName } from './RecoveryGoalName';
import { RecoveryGoalStepName } from './RecoveryGoalPathName';
import cssClasses from './RecoveryGoalTree.module.css';

export function RecoveryGoalTree(props: {
  tree: RecoveryGoalTreeDTO | RecoveryGoalBehaviorTreeDTO;
}) {
  const { tree } = props;
  return (
    <Flex
      w='max-content'
      direction='column'
      justify='flex-start'
      align='center'
      wrap='nowrap'
    >
      <div style={{ height: '1em', border: '1px solid black' }}></div>
      <Paper p={4} withBorder className={cssClasses.recoveryGoalName}>
        <RecoveryGoalName recoveryGoal={tree.recoveryGoal} noLink />
      </Paper>
      <div style={{ height: '1em', border: '1px solid black' }}></div>
      <Flex direction='row' justify='center' align='flex-start'>
        <Flex direction='column' align='center'>
          <Flex h='1em' w='100%'>
            <div style={{ flex: '1 1 auto' }}></div>
            <div
              style={{
                flex: '1 1 auto',
                borderLeft: '2px solid black',
                borderTop: '2px solid black',
              }}
            ></div>
          </Flex>
          <Paper withBorder p={4} mr='xs' shadow='sm'>
            {'negativeMaterialClassEffects' in tree ? (
              <MaterialClassEffects
                effects={tree.negativeMaterialClassEffects}
              />
            ) : (
              <RecoveryGoalStepName
                noLink
                step={{
                  recoveryGoalId: tree.recoveryGoal.id,
                  negative: true,
                }}
              />
            )}
          </Paper>

          {tree.negativeTree !== null ? (
            <RecoveryGoalTree tree={tree.negativeTree} />
          ) : undefined}
        </Flex>
        <Flex direction='column' align='center'>
          <Flex h='1em' w='100%'>
            <div
              style={{
                flex: '1 1 auto',
                borderRight: '2px solid black',
                borderTop: '2px solid black',
              }}
            ></div>
            <div style={{ flex: '1 1 auto' }}></div>
          </Flex>
          <Paper withBorder p={4} mx='xs' shadow='sm'>
            {'positiveMaterialClassEffects' in tree ? (
              <MaterialClassEffects
                effects={tree.positiveMaterialClassEffects}
              />
            ) : (
              <RecoveryGoalStepName
                noLink
                step={{
                  recoveryGoalId: tree.recoveryGoal.id,
                  negative: false,
                }}
              />
            )}
          </Paper>
          {tree.positiveTree !== null ? (
            <RecoveryGoalTree tree={tree.positiveTree} />
          ) : undefined}
        </Flex>
      </Flex>
    </Flex>
  );
}
