import { UseFormReturnType } from '@mantine/form';
import { useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export function useSetFormInitialValuesFromQuery<T>(
  form: UseFormReturnType<T>,
  queryData: T | undefined,
) {
  const [initialValuesSet, setInitialValuesSet] = useState(false);
  const [formDirtyReset, setFormDirtyReset] = useState(false);

  // TODO(1072): If the query refreshes in the background and yields different data, we should alert the user somehow and allow them to choose which values to keep
  useDeepCompareEffect(() => {
    if (queryData === undefined) {
      return;
    }
    form.setValues(queryData);
    setInitialValuesSet(true);
    setFormDirtyReset(false);
  }, [queryData ?? {}]);

  useEffect(() => {
    if (initialValuesSet && !formDirtyReset) {
      form.resetDirty();
      setFormDirtyReset(true);
    }
  }, [initialValuesSet, formDirtyReset, form]);
}
