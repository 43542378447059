import { Alert, Box, BoxProps, Divider, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { FormActionButton } from '../Form/FormActionButton';
import { useFormNavBlocker } from '../Form/useFormNavBlocker';
import {
  PartitionFormFields,
  usePartitionForm,
} from '../Partition/PartitionForm';
import { useAddPurchasedMaterialPartition } from '../api/purchasedMaterialPartition';
import {
  PurchasedMaterialDTO,
  PurchasedMaterialPartitionCreationDTO,
} from '../rest-client';
import { Router } from '../router';

export type PurchasedMaterialPartitionProps = Omit<
  React.ComponentPropsWithoutRef<'form'>,
  'onSubmit'
> &
  Omit<BoxProps, 'component'> & {
    purchasedMaterial: PurchasedMaterialDTO | undefined;
  };

export function PurchasedMaterialPartitionForm(
  props: PurchasedMaterialPartitionProps,
) {
  const { purchasedMaterial, ...rest } = props;
  const addMutation = useAddPurchasedMaterialPartition();
  const form = useFormNavBlocker(usePartitionForm());

  if (addMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Creating Partition'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => addMutation.reset()}
      >
        The transfer may or may not have been saved. You can clear the error and
        try again.
      </Alert>
    );
  }
  const noContainersSelected = form.values.containerDestinations.length === 0;
  const noTruckloadsSelected = form.values.truckLoadDestinations.length === 0;

  return (
    <Box
      {...rest}
      component='form'
      onSubmit={form.onSubmit((formValues) => {
        if (purchasedMaterial === undefined) throw new Error();

        const containerDestinations = formValues.containerDestinations.map(
          ({ containerId, filled }) => {
            if (containerId === null) throw new Error();
            return { containerId, filled };
          },
        );

        const truckLoadDestinations = formValues.truckLoadDestinations.map(
          ({ truckLoadId }) => {
            if (truckLoadId === null) throw new Error();
            return { truckLoadId };
          },
        );

        const partition: PurchasedMaterialPartitionCreationDTO = {
          containerDestinations:
            formValues.destinationMode === 'container'
              ? containerDestinations
              : [],
          truckLoadDestinations:
            formValues.destinationMode === 'truck-load'
              ? truckLoadDestinations
              : [],
        };

        addMutation.mutate(
          { purchasedMaterialId: purchasedMaterial.id, partition },
          {
            onError() {
              showNotification({
                title: 'Error Creating Purchased Material Partition',
                message: 'An error occurred creating the partition',
                color: 'red',
                icon: <IconX />,
              });
            },
            onSuccess() {
              showNotification({
                title: 'Purchased Material Partition Created',
                message:
                  'The purchased materiral partition was sucessfully recorded',
                color: 'green',
                icon: <IconCheck />,
              });
              Router.replace('VendorPurchasedMaterialDetail', {
                vendorId: purchasedMaterial.vendorId,
                purchasedMaterialId: purchasedMaterial.id,
              });
            },
          },
        );
      })}
    >
      <Stack>
        <PartitionFormFields
          form={form}
          timestamp={
            purchasedMaterial && dayjs.utc(purchasedMaterial.creationTime)
          }
        />
        <Divider />
        <FormActionButton
          type='submit'
          action='saveCreation'
          loading={addMutation.isLoading}
          disabled={noContainersSelected && noTruckloadsSelected}
        >
          Add Transfer
        </FormActionButton>
      </Stack>
    </Box>
  );
}
