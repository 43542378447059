import { Box, Center, Container, Paper, Stack, Text } from '@mantine/core';
import cssClasses from './Login.module.css';
import { SignInWithMicrosoftButton } from './Microsoft/SignInWithMicrosoftButton';
import SvgValisLogo from './SVG/ValisLogo';

export function LoginPage() {
  return (
    <Box className={cssClasses.loginPageContainer}>
      <Container size={420} h={'100vh'}>
        <Center h='100%'>
          <Paper radius='md' p='xl' withBorder>
            <Stack align='center'>
              <SvgValisLogo style={{ height: '5rem' }} />
              <Text size='lg' weight={500}>
                Log in to VALI-Sort
              </Text>
              <SignInWithMicrosoftButton />
            </Stack>
          </Paper>
        </Center>
      </Container>
    </Box>
  );
}
