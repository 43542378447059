import {
  Alert,
  Button,
  Flex,
  Group,
  Menu,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCaretDown,
  IconCaretUp,
  IconCheck,
  IconHistory,
  IconHistoryToggle,
  IconTools,
  IconX,
} from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { AppPage } from '../App/AppPage';
import { AddContainerTransferDrawerForm } from '../ContainerTransfer/AddContainerTransferDrawerForm.tsx';
import { ContainerTypeBadge } from '../ContainerType/ContainerTypeBadge.tsx';
import { DayjsDateTimePicker } from '../DayjsDateTimePicker';
import { useFacilityContext } from '../Facility/FacilityContext';
import { GenealogyExplorer } from '../GenealogyExplorer/GenealogyExplorer';
import {
  ContainerSamplingIcon,
  DeleteIcon,
  EditIcon,
  IncomingTransferIcon,
  InternalMaterialSinkTransferIcon,
  InternalMaterialSourcePartitionTransferIcon,
  OutgoingTransferIcon,
} from '../Icons';
import { AddInternalSinkContainerTransferDrawerForm } from '../InternalSinkContainerTransfer/AddInternalSinkContainerTransferDrawerForm.tsx';
import { AddInternallySourcedMaterialDrawerForm } from '../InternallySourcedMaterial/AddInternallySourcedMaterialDrawerForm.tsx';
import { LinkButton } from '../Link';
import Temporal from '../Temporal/temporal.ts';
import QuantizedWeight from '../Weights/QuantizedWeight';
import {
  useContainer,
  useContainerGenealogy,
  useContainerHistory,
  useDeleteContainer,
} from '../api/container';
import { LabeledValue } from '../common';
import { MaterialContainerDTO, MaterialContainerId } from '../rest-client';
import { Router } from '../router';
import { ContainerHistory } from './ContainerHistoryTimeline';
import { ContainerWeighedAndSampledConstituents } from './ContainerWeighedAndSampledConstituents';
import { EditContainerDrawerForm } from './EditContainerDrawerForm.tsx';

function ContainerWeightInfo(props: {
  container: MaterialContainerDTO | undefined;
}) {
  const { container } = props;
  return (
    <>
      <LabeledValue label='Effective Tare Weight'>
        {container ? (
          container.effectiveTareWeight ? (
            <QuantizedWeight weight={container.effectiveTareWeight} />
          ) : (
            <Text c='dimmed'>Unknown</Text>
          )
        ) : (
          'Loading...'
        )}
      </LabeledValue>
      <LabeledValue label='Container Tare'>
        {container ? (
          container.overrideTareWeight ? (
            <QuantizedWeight weight={container.overrideTareWeight} />
          ) : (
            <Text c='dimmed'>Unspecified</Text>
          )
        ) : (
          'Loading...'
        )}
      </LabeledValue>
      <LabeledValue label='Container Type Default Tare'>
        {container ? (
          container.containerTypeDefaultTareWeight ? (
            <QuantizedWeight
              weight={container.containerTypeDefaultTareWeight}
            />
          ) : (
            <Text c='dimmed'>None</Text>
          )
        ) : (
          'Loading...'
        )}
      </LabeledValue>
    </>
  );
}

export function ContainerDetailPage(props: {
  containerId: MaterialContainerId;
  timestamp: Dayjs | undefined;
}) {
  const { containerId, timestamp } = props;

  const facility = useFacilityContext();

  const containerQuery = useContainer(containerId);
  const genealogyQuery = useContainerGenealogy(containerId, timestamp);
  const historyQuery = useContainerHistory(containerId);
  const isEmpty = genealogyQuery.data?.status === 'empty';
  const isUnused = historyQuery.data?.length === 0;

  const breadcrumbs = [
    { routeName: Router.ContainerList(), title: 'Containers' },
  ];

  return (
    <AppPage
      breadcrumbs={breadcrumbs}
      title={containerQuery.data?.name}
      titleRight={
        <Group>
          {containerQuery.data && (
            <ContainerTypeBadge
              containerType={containerQuery.data.containerType}
              size='xl'
            />
          )}
          <ContainerActionsMenu
            containerId={containerId}
            isEmpty={isEmpty}
            isUnused={isUnused}
            timestamp={timestamp}
          />
        </Group>
      }
    >
      <AppPage.Section>
        <Flex gap='sm' justify='flex-start' align='flex-start'>
          <Stack>
            <Group align='flex-start'>
              <Title order={3}>Container Details</Title>
              <DayjsDateTimePicker
                value={timestamp?.tz(facility.timeZoneId) ?? null}
                onChange={(t) =>
                  Router.push('ContainerDetail', {
                    containerId,
                    timestamp: t?.utc().toISOString(),
                  })
                }
                tz={facility.timeZoneId}
                icon={<IconHistory size={16} />}
                maxDate={dayjs()}
                w={200}
              />
              {timestamp !== undefined ? (
                <LinkButton
                  leftIcon={<IconHistoryToggle />}
                  to={Router.ContainerDetail({
                    containerId,
                    timestamp: undefined,
                  })}
                >
                  Jump to now
                </LinkButton>
              ) : undefined}
            </Group>
            <Group spacing='lg'>
              <ContainerWeightInfo container={containerQuery.data} />
            </Group>
          </Stack>
          {timestamp !== undefined ? (
            <Alert
              color='violet'
              title='Historical View'
              icon={<IconHistory />}
              style={{ flexGrow: 1 }}
            >
              <Text>
                You are viewing the historical state of this container on{' '}
                {timestamp.tz(facility.timeZoneId).format('llll')}.
              </Text>
            </Alert>
          ) : undefined}
        </Flex>
      </AppPage.Section>
      <ContainerWeighedAndSampledConstituents
        containerId={containerId}
        instant={
          (timestamp &&
            Temporal.Instant.fromEpochMilliseconds(timestamp.valueOf())) ??
          null
        }
      />
      <GenealogyExplorer genealogyQuery={genealogyQuery} />
      <AppPage.Section>
        <Stack>
          <Title order={3}>Container Event History</Title>
          <ContainerHistory containerId={containerId} />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}

interface ContainerActionMenuProps {
  containerId: MaterialContainerId;
  isEmpty: boolean;
  isUnused: boolean;
  timestamp?: Dayjs;
}

function ContainerActionsMenu(props: ContainerActionMenuProps) {
  const { containerId, isEmpty, isUnused, timestamp } = props;

  const deleteMutation = useDeleteContainer();
  const [menuOpened, setMenuOpened] = useState(false);

  const [
    editContainerDrawerOpened,
    { open: openEditContainerDrawer, close: closeEditContainerDrawer },
  ] = useDisclosure(false);

  const [
    addInternallySourcedMaterialDrawerOpened,
    {
      open: openAddInternallySourcedMaterialDrawer,
      close: closeAddInternallySourcedMaterialDrawer,
    },
  ] = useDisclosure(false);

  const [
    addSinkTransferDrawerOpened,
    { open: openAddSinkTransferDrawer, close: closeAddSinkTransferDrawer },
  ] = useDisclosure(false);

  const [
    addIncomingContainerTransferDrawerOpened,
    {
      open: openAddIncomingContainerTransferDrawer,
      close: closeAddIncomingContainerTransferDrawer,
    },
  ] = useDisclosure(false);

  const [
    addOutgoingContainerTransferDrawerOpened,
    {
      open: openAddOutgoingContainerTransferDrawer,
      close: closeAddOutgoingContainerTransferDrawer,
    },
  ] = useDisclosure(false);

  const openDeleteModal = () => {
    openConfirmModal({
      title: 'Confirm Container Deletion',
      centered: true,
      labels: { confirm: 'Delete Container', cancel: 'Back to Safety' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        deleteMutation.mutate(containerId, {
          onError() {
            showNotification({
              title: 'Error Deleting Container',
              message:
                'An error occurred deleting this container. This could be an internal error',
              color: 'red',
              icon: <IconX />,
            });
          },
          onSuccess() {
            Router.push('ContainerList');
            showNotification({
              title: 'Container Deleted',
              message: 'Container was successfuly deleted.',
              color: 'green',
              icon: <IconCheck />,
            });
          },
          onSettled() {
            deleteMutation.reset();
          },
        });
      },
      children: (
        <Alert title='Warning!' color='red' variant='filled'>
          Although this container is unused, deleting this container is
          irreversible! Are you absolutely sure you want to delete this
          container?
        </Alert>
      ),
    });
  };

  return (
    <>
      <EditContainerDrawerForm
        containerId={containerId}
        opened={editContainerDrawerOpened}
        onClose={closeEditContainerDrawer}
      />
      <AddInternallySourcedMaterialDrawerForm
        containerId={containerId}
        onSuccess={closeAddInternallySourcedMaterialDrawer}
        opened={addInternallySourcedMaterialDrawerOpened}
        onClose={closeAddInternallySourcedMaterialDrawer}
        title='Source Material into Container'
      />
      <AddInternalSinkContainerTransferDrawerForm
        containerId={containerId}
        onSuccess={closeAddSinkTransferDrawer}
        opened={addSinkTransferDrawerOpened}
        onClose={closeAddSinkTransferDrawer}
        title='Export Material from Container'
      />
      {/* Incoming Container Transfer */}
      <AddContainerTransferDrawerForm
        destinationContainerId={containerId}
        onSuccess={closeAddIncomingContainerTransferDrawer}
        opened={addIncomingContainerTransferDrawerOpened}
        onClose={closeAddIncomingContainerTransferDrawer}
        title='Transfer Material into Container'
      />
      {/* Outgoing Container Transfer */}
      <AddContainerTransferDrawerForm
        sourceContainerId={containerId}
        onSuccess={closeAddOutgoingContainerTransferDrawer}
        opened={addOutgoingContainerTransferDrawerOpened}
        onClose={closeAddOutgoingContainerTransferDrawer}
        title='Transfer Material out of Container'
      />
      <Menu opened={menuOpened} onChange={setMenuOpened}>
        <Menu.Target>
          <Button
            leftIcon={<IconTools />}
            rightIcon={menuOpened ? <IconCaretUp /> : <IconCaretDown />}
            size='xl'
            compact
            variant='default'
          >
            Actions
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Sampling</Menu.Label>
          <Menu.Item
            icon={<ContainerSamplingIcon size={14} />}
            onClick={() =>
              Router.push('ContainerSampleCreate', {
                containerId,
                timestamp: timestamp?.utc().toISOString(),
              })
            }
          >
            Add Container Sample
          </Menu.Item>
          <Menu.Label>Transfers</Menu.Label>
          <Menu.Item
            icon={<IncomingTransferIcon size={14} />}
            onClick={openAddIncomingContainerTransferDrawer}
          >
            Transfer Material into Container
          </Menu.Item>
          {!isEmpty && (
            <>
              <Menu.Item
                icon={<OutgoingTransferIcon size={14} />}
                onClick={openAddOutgoingContainerTransferDrawer}
              >
                Transfer Material out of Container
              </Menu.Item>
              <Menu.Item
                icon={<InternalMaterialSinkTransferIcon size={14} />}
                onClick={openAddSinkTransferDrawer}
              >
                Export Material out of Container
              </Menu.Item>
            </>
          )}
          <Menu.Item
            icon={<InternalMaterialSourcePartitionTransferIcon size={14} />}
            onClick={openAddInternallySourcedMaterialDrawer}
          >
            Internally Source Material into Container
          </Menu.Item>
          <Menu.Divider />
          <Menu.Label>Settings</Menu.Label>
          <Menu.Item
            icon={<EditIcon size={14} />}
            onClick={openEditContainerDrawer}
          >
            Edit Container
          </Menu.Item>
          {isUnused && (
            <Menu.Item
              color='red'
              icon={<DeleteIcon size={14} />}
              onClick={openDeleteModal}
            >
              Delete Container
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
