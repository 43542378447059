import { Alert, Center, Loader, Table, useMantineTheme } from '@mantine/core';
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { match } from 'ts-pattern';
import { useRecoveryStrategySimulations } from '../api/recoveryStrategySimulation';
import { RecoveryStrategyId } from '../rest-client';
import { RecoveryStrategySimulationName } from './RecoveryStrategySimulationName';

export function RecoveryStrategySimulationTable(props: {
  recoveryStrategyId: RecoveryStrategyId;
}) {
  const { recoveryStrategyId } = props;

  const simulationsQuery = useRecoveryStrategySimulations({
    strategyId: recoveryStrategyId,
  });
  const { colors } = useMantineTheme();

  if (simulationsQuery.isError) {
    throw simulationsQuery.error;
  }

  return match(simulationsQuery)
    .with({ isLoading: true }, () => (
      <Center>
        <Loader variant='bars' size='lg' />
      </Center>
    ))
    .when(
      ({ data }) => data.length === 0,
      () => (
        <Alert title='No Recovery Simulations' color='blue'>
          No recovery simulations have been created for this recovery strategy
          with the selected hand sampling material class set.
        </Alert>
      ),
    )
    .otherwise(({ data: recoverySimulations }) => (
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Created At</th>
            <th>Complete</th>
          </tr>
        </thead>
        <tbody>
          {recoverySimulations.map((rs) => (
            <tr key={rs.id}>
              <td>
                <RecoveryStrategySimulationName simulation={rs} />
              </td>
              <td>{dayjs.utc(rs.insertedAt).format('LLL')}</td>
              <td>
                {Object.entries(rs.inputComposition.materialClassesProportions)
                  .length === rs.materialClassSet.materialClasses.length ? (
                  <IconCheck color={colors.green[6]} />
                ) : (
                  <IconAlertTriangle color={colors.yellow[7]} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    ));
}
