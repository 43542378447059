import { useMutation, useQuery } from '@tanstack/react-query';
import {
  MaterialClassCreationDTO,
  MaterialClassPatchDTO,
  MaterialClassService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchMaterialClasses() {
  return await MaterialClassService.allMaterialClasses();
}

export function useMaterialClasses() {
  return useQuery({
    queryKey: queryKeys.materialClass.list(),
    queryFn: fetchMaterialClasses,
    refetchOnWindowFocus: false,
  });
}

async function fetchMaterialClass(
  ctx: QueryFunctionContexts['materialClass']['detail'],
) {
  const {
    queryKey: [{ materialClassId }],
  } = ctx;
  return await MaterialClassService.getMaterialClassById(materialClassId);
}

export function useMaterialClass(materialClassId: string) {
  return useQuery({
    queryKey: queryKeys.materialClass.detail(materialClassId),
    queryFn: fetchMaterialClass,
  });
}

async function createMaterialClass(dto: MaterialClassCreationDTO) {
  await MaterialClassService.createMaterialClass(dto);
}

export function useCreateMaterialClass() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createMaterialClass,
    onSettled() {
      invalidator.invalidateKeys(queryKeys.materialClass.list());
    },
  });
}

async function patchMaterialClass(
  materialClassId: string,
  patch: MaterialClassPatchDTO,
) {
  await MaterialClassService.patchMaterialClass(materialClassId, patch);
}

export function usePatchMaterialClass(materialClassId: string) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: MaterialClassPatchDTO) =>
      await patchMaterialClass(materialClassId, patch),
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.materialClass.list(),
        queryKeys.materialClass.detail(materialClassId),
        // TODO(765): invalidate all analyses
      );
    },
  });
}

async function deleteMaterialClass(materialClassId: string) {
  await MaterialClassService.deleteMaterialClass(materialClassId);
}

export function useDeleteMaterialClass() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteMaterialClass,
    onSettled(_data, _error, materialClassId) {
      invalidator.invalidateKeys(
        queryKeys.materialClass.list(),
        queryKeys.materialClass.detail(materialClassId),
      );
    },
  });
}
