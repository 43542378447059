import { match } from 'ts-pattern';
import Temporal from '../../Temporal/temporal.ts';
import { SortSystemDTO } from '../../rest-client';
import { SystemPort } from './SystemPort';
import { chutecStreamFromPort } from './chutec/ChutecStream';
import { DifferentialChutecSystemMetrics } from './chutec/DifferentialChutecSystemMetrics';
import { DifferentialRedWaveSystemMetrics } from './redwave/DifferentialRedWaveSystemMetrics';
import { redWaveStreamFromPort } from './redwave/RedWaveStream';

export function DifferentialSystemMetrics(props: {
  system: SortSystemDTO;
  port: SystemPort;
  startTime: Temporal.Instant;
  endTime: Temporal.Instant;
}) {
  const { system, port, startTime, endTime } = props;
  return match(system)
    .with({ kind: 'Chutec' }, (chutecSystem) => (
      <DifferentialChutecSystemMetrics
        system={chutecSystem}
        stream={chutecStreamFromPort(chutecSystem, port)}
        startTime={startTime}
        endTime={endTime}
      />
    ))
    .with({ kind: 'RedWave' }, (redWaveSystem) => (
      <DifferentialRedWaveSystemMetrics
        system={redWaveSystem}
        stream={redWaveStreamFromPort(redWaveSystem, port)}
        startTime={startTime}
        endTime={endTime}
      />
    ))
    .exhaustive();
}
