import { Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormActionButton } from '../Form/FormActionButton';
import { useFormNavBlocker } from '../Form/useFormNavBlocker';
import { useAddTruckLoad } from '../api/truckLoad';
import { MaterialTruckLoadCreationDTO } from '../rest-client';
import { Router } from '../router';

interface AddTruckLoadFormValues {
  name: string;
}

export function AddTruckLoadForm() {
  const truckLoadId = useMemo(() => uuidv4(), []);
  const form = useFormNavBlocker(
    useForm<AddTruckLoadFormValues>({
      initialValues: {
        name: '',
      },
      validate: {
        name: (name) => (name ? null : 'Name is required'),
      },
    }),
  );
  const addTruckLoadMutation = useAddTruckLoad();

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        const truckLoad: MaterialTruckLoadCreationDTO = {
          id: truckLoadId,
          name: values.name,
        };
        addTruckLoadMutation.mutate(truckLoad, {
          onSuccess() {
            Router.replace('TruckLoadDetail', {
              truckLoadId: truckLoadId,
            });
          },
        });
      })}
    >
      <Stack maw={500}>
        <TextInput
          label='Truck Load Name'
          withAsterisk
          placeholder='TL [PO number]-[TL number], e.g. TL 00001-1'
          {...form.getInputProps('name')}
        />

        <Group position='right'>
          <FormActionButton
            action='cancel'
            onClick={() => Router.push('TruckLoadList')}
            loading={addTruckLoadMutation.isLoading}
          />
          <FormActionButton
            type='submit'
            action='saveCreation'
            loading={addTruckLoadMutation.isLoading}
          >
            Add Truck Load
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
