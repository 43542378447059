import {
  Alert,
  Button,
  ButtonProps,
  Group,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { DeleteIcon } from '../Icons';
import { useDeleteInternallySourcedMaterial } from '../api/internallySourcedMaterial';
import { InternallySourcedMaterialId } from '../rest-client';
import { Router } from '../router';

export type DeleteInternallySoucedMaterialButtonProps = Omit<
  ButtonProps,
  'onClick'
> & { internallySourcedMaterialId: InternallySourcedMaterialId };

export function DeleteInternallySoucedMaterialButton(
  props: DeleteInternallySoucedMaterialButtonProps,
) {
  const {
    internallySourcedMaterialId,
    leftIcon = <DeleteIcon />,
    color = 'red',
    ...buttonProps
  } = props;

  const deleteMutation = useDeleteInternallySourcedMaterial();

  const [
    deleteModalOepened,
    { open: openDeleteModal, close: closeDeleteModal },
  ] = useDisclosure(false);

  return (
    <>
      <Modal
        opened={deleteModalOepened}
        onClose={closeDeleteModal}
        title='Confirm Upstream Sourced Material Deletion'
        w={600}
      >
        <Stack>
          <Alert color='red' variant='filled'>
            <Text>
              Deleting this upstream-sourced material is irreversible. This
              upstream-sourced material cannot be deleted if there are any
              depedndent partition transfers. Are you sure you want to delete
              the upstream-sourced material?
            </Text>
          </Alert>

          <Group position='apart'>
            <Button
              variant='light'
              color='red'
              loading={deleteMutation.isLoading}
              onClick={() => {
                deleteMutation.mutate(internallySourcedMaterialId, {
                  onError() {
                    showNotification({
                      title: 'Error Deleting Upstream Sourced Material',
                      message:
                        'An error occurred deleting the upstream-sourced material.',
                      color: 'red',
                      icon: <IconX />,
                    });
                  },
                  onSuccess() {
                    Router.push('InternalSourceList');
                    showNotification({
                      title: 'Upstream Sourced Material Deleted',
                      message:
                        'The upstream-sourced material was sucessfully deleted.',
                      color: 'green',
                      icon: <IconCheck />,
                    });
                  },
                  onSettled() {
                    close();
                  },
                });
              }}
            >
              Delete Forever
            </Button>
            <Button color='teal' onClick={closeDeleteModal}>
              Back to Safety
            </Button>
          </Group>
        </Stack>
      </Modal>
      <Button
        leftIcon={leftIcon}
        color={color}
        onClick={openDeleteModal}
        {...buttonProps}
      >
        Delete
      </Button>
    </>
  );
}
