import { match } from 'ts-pattern';
import { AppPage } from '../App/AppPage';
import { The404 } from '../The404';
import { Router } from '../router';
import { DailyProductionPage } from './DailyProductionPage';
import ProcessProductionHistoryPage from './ProcessProductionHistoryPage';
import { ProductionIntervalStatus } from './ProductionIntervalStatus';

export function ProductionArea() {
  const route = Router.useRoute([
    'ProductionHistory',
    'ProductionDaily',
    'ProductionIntervalStatus',
  ]);
  if (route === undefined) {
    return <The404 />;
  }

  return match(route)
    .with({ name: 'ProductionHistory' }, () => <ProcessProductionHistoryPage />)
    .with({ name: 'ProductionDaily' }, () => <DailyProductionPage />)
    .with(
      { name: 'ProductionIntervalStatus' },
      ({ params: { productionIntervalId } }) => (
        <AppPage>
          <AppPage.Section>
            <ProductionIntervalStatus
              productionIntervalId={productionIntervalId}
            />
          </AppPage.Section>
        </AppPage>
      ),
    )
    .exhaustive();
}
