import { Alert, Flex, Loader, Paper, Title } from '@mantine/core';
import { LinkText } from '../../Link';
import { MetricCardProps } from './MetricCard';
import { MetricGraph, SeriesLine } from './MetricGraph';
import cssClasses from './css/MetricGraphCard.module.css';

type MetricGraphCardProps = MetricCardProps & {
  seriesLines: SeriesLine[] | null;
  yAxisLabel: string;
};

// TODO support tooltips alongside heading
export function MetricGraphCard(props: MetricGraphCardProps) {
  const { href, heading, className, seriesLines, yAxisLabel, query } = props;

  let cardContent;
  if (query.isLoading) {
    cardContent = (
      <Flex className={cssClasses.cardLoading}>
        <Loader variant='bars' size='xl' />
      </Flex>
    );
  } else if (query.isLoadingError) {
    cardContent = (
      <Alert className={`${cssClasses.cardAlert} ${className}`} color='red'>
        <Title className={`${cssClasses.cardAlertText}`} order={5}>
          An error occurred loading this data.
        </Title>
      </Alert>
    );
  } else {
    cardContent = seriesLines?.length ? (
      <MetricGraph seriesLines={seriesLines} yAxisLabel={yAxisLabel} />
    ) : (
      <Alert className={`${cssClasses.cardAlert} ${className}`} color='yellow'>
        <Title className={`${cssClasses.cardAlertText}`} order={5}>
          No data for the selected date range.
        </Title>
      </Alert>
    );
  }

  return (
    <Paper className={`${className} ${cssClasses.metricGraphCard}`}>
      <div className={`${cssClasses.cardLayout} ${cssClasses.metricGraphCard}`}>
        <Title
          order={4}
          className={`${cssClasses.metricGraphCardTitle} ${cssClasses.heading}`}
        >
          <LinkText to={href}>{heading}</LinkText>
        </Title>
        {cardContent}
      </div>
    </Paper>
  );
}
