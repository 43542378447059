import { useQuery } from '@tanstack/react-query';
import { MaterialContainerTypeService } from '../rest-client';
import { queryKeys } from './queryKeys';

async function fetchAllContainerTypes() {
  return await MaterialContainerTypeService.getMaterialContainerTypes();
}

export function useContainerTypes() {
  return useQuery({
    queryKey: queryKeys.containerType.list(),
    queryFn: fetchAllContainerTypes,
  });
}
