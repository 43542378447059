import {
  Alert,
  Loader,
  RingProgress,
  Skeleton,
  Stack,
  Table,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import dayjs from 'dayjs';
import { P, Pattern, isMatching, match } from 'ts-pattern';
import { useFacilityContext } from '../Facility/FacilityContext';
import { LinkText } from '../Link';
import { FormatTimeWithUnits } from '../Temporal/FormatTimeWithUnits.tsx';
import Temporal from '../Temporal/temporal.ts';
import NetWeight, { NetWeightZero } from '../Weights/NetWeight.tsx';
import { PoundsThroughputWithUnits } from '../Weights/NetWeightThroughputWithUnits.tsx';
import {
  useProcessMassProductionHistory,
  useProcessTemporalProductionHistory,
} from '../api/process';
import {
  ProductionIntervalAnalysisProcessState,
  ProductionIntervalSubIntervalStateDTO,
  WeightUnit,
} from '../rest-client';
import { Router } from '../router';

export interface AnalyzeProductionIntervalPatternParams {
  subIntervalStates: ProductionIntervalSubIntervalStateDTO[];
  pattern: Pattern.Pattern<ProductionIntervalSubIntervalStateDTO>;
  ignoreUnplannedStops: boolean;
}

export function analyzeProductionIntervalPattern(
  params: AnalyzeProductionIntervalPatternParams,
) {
  const { subIntervalStates, pattern, ignoreUnplannedStops } = params;

  return subIntervalStates
    .filter(
      (sis) =>
        (!ignoreUnplannedStops || !sis.stopReason?.unplanned) &&
        isMatching(pattern, sis),
    )
    .map((sis) => sis.hours)
    .reduce((a, b) => a + b, 0);
}

export interface AnalyzedProductionIntervalMetrics {
  hours: number;
  activeHours: number;
  engagedHours: number;
  disengagedHours: number;
  faultedHours: number;
  unplannedStopHours: number;
  plannedStopHours: number;
  issueHours: number;
}

export type AnalyzeProductionIntervalParams = Omit<
  AnalyzeProductionIntervalPatternParams,
  'pattern'
>;

export function analyzeProductionInterval(
  params: AnalyzeProductionIntervalParams,
): AnalyzedProductionIntervalMetrics {
  const { subIntervalStates, ignoreUnplannedStops } = params;

  const hours = analyzeProductionIntervalPattern({
    subIntervalStates,
    pattern: P.any,
    ignoreUnplannedStops,
  });

  const activeHours = analyzeProductionIntervalPattern({
    subIntervalStates,
    pattern: {
      active: true,
      stopReason: P.union(null, { unplanned: false }),
    },
    ignoreUnplannedStops,
  });

  const engagedHours = analyzeProductionIntervalPattern({
    subIntervalStates,
    pattern: {
      processState: ProductionIntervalAnalysisProcessState.ENGAGED,
    },
    ignoreUnplannedStops,
  });

  const disengagedHours = analyzeProductionIntervalPattern({
    subIntervalStates,
    pattern: {
      processState: ProductionIntervalAnalysisProcessState.DISENGAGED,
    },
    ignoreUnplannedStops,
  });

  const faultedHours = analyzeProductionIntervalPattern({
    subIntervalStates,
    pattern: {
      processState: ProductionIntervalAnalysisProcessState.FAULTED,
    },
    ignoreUnplannedStops,
  });

  const plannedStopHours = analyzeProductionIntervalPattern({
    subIntervalStates,
    pattern: {
      stopReason: { unplanned: false },
    },
    ignoreUnplannedStops,
  });

  const unplannedStopHours = analyzeProductionIntervalPattern({
    subIntervalStates,
    pattern: {
      stopReason: { unplanned: true },
    },
    ignoreUnplannedStops,
  });

  const issueHours = analyzeProductionIntervalPattern({
    subIntervalStates,
    pattern: P.union(
      {
        processState: ProductionIntervalAnalysisProcessState.FAULTED,
      },
      { stopReason: { unplanned: true } },
    ),
    ignoreUnplannedStops,
  });

  return {
    hours,
    activeHours,
    engagedHours,
    disengagedHours,
    faultedHours,
    unplannedStopHours,
    plannedStopHours,
    issueHours,
  };
}

export interface ProcessProductionIntervalStatusTableProps {
  processId: string;
  after: Temporal.Instant | null;
  before: Temporal.Instant | null;
  normalizeByUnplannedStops: boolean;
}

export default function ProcessProductionIntervalStatusTable(
  props: ProcessProductionIntervalStatusTableProps,
) {
  const {
    processId,
    after,
    before,
    normalizeByUnplannedStops: ignoreUnplannedStops,
  } = props;
  const productionHistoryTemporalQuery = useProcessTemporalProductionHistory({
    processId,
    after,
    before,
  });
  const productionHistoryMassQuery = useProcessMassProductionHistory({
    processId,
    after,
    before,
  });

  const facility = useFacilityContext();
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const lightMode = colorScheme === 'light';

  const defaultHeaderColor = lightMode
    ? theme.colors.gray[1]
    : theme.colors.gray[8];
  const defaultHeaderColor2 = lightMode
    ? theme.colors.gray[0]
    : theme.colors.gray[8];

  const activeHeaderColor = lightMode
    ? theme.colors.green[3]
    : theme.colors.green[7];
  const activeHeaderColor2 = lightMode
    ? theme.colors.green[2]
    : theme.colors.green[8];
  const activeColor = lightMode ? theme.colors.green[1] : theme.colors.green[9];

  const engagedHeaderColor = lightMode
    ? theme.colors.blue[3]
    : theme.colors.blue[7];
  const engagedHeaderColor2 = lightMode
    ? theme.colors.blue[2]
    : theme.colors.blue[8];
  const engagedColor = lightMode ? theme.colors.blue[1] : theme.colors.blue[9];

  const disengagedHeaderColor = lightMode
    ? theme.colors.gray[3]
    : theme.colors.gray[7];
  const disengagedHeaderColor2 = lightMode
    ? theme.colors.gray[2]
    : theme.colors.gray[8];
  const disengagedColor = lightMode
    ? theme.colors.gray[1]
    : theme.colors.gray[9];

  const issueHeaderColor = lightMode
    ? theme.colors.red[3]
    : theme.colors.red[7];
  const issueHeaderColor2 = lightMode
    ? theme.colors.red[2]
    : theme.colors.red[8];
  const issueColor = lightMode ? theme.colors.red[1] : theme.colors.red[9];

  const plannedStopHeaderColor = lightMode
    ? theme.colors.yellow[3]
    : theme.colors.yellow[7];
  const plannedStopHeaderColor2 = lightMode
    ? theme.colors.yellow[2]
    : theme.colors.yellow[8];
  const plannedStopColor = lightMode
    ? theme.colors.yellow[1]
    : theme.colors.yellow[9];

  const unplannedStopHeaderColor = lightMode
    ? theme.colors.orange[3]
    : theme.colors.orange[7];
  const unplannedStopHeaderColor2 = lightMode
    ? theme.colors.orange[2]
    : theme.colors.orange[8];
  const unplannedStopColor = lightMode
    ? theme.colors.orange[1]
    : theme.colors.orange[9];

  if (productionHistoryTemporalQuery.isLoading) {
    return (
      <Stack align='center'>
        <Loader size='xl' variant='bars' />
        <Text color='dimmed'>Analyzing production data...</Text>
      </Stack>
    );
  }
  if (productionHistoryTemporalQuery.isLoadingError) {
    throw productionHistoryTemporalQuery.error;
  }

  const temporalProductionHistory = productionHistoryTemporalQuery.data;
  const temporalStatuses = temporalProductionHistory.productionIntervalStatuses;

  let totalHours = 0;
  let totalActiveHours = 0;
  let totalEngagedHours = 0;
  let totalDisengagedHours = 0;
  let totalFaultedHours = 0;
  let totalUnplannedStopHours = 0;
  let totalPlannedStopHours = 0;
  let totalIssueHours = 0;

  const massProductionHistory = productionHistoryMassQuery.data;

  return (
    <Table withBorder w='fit-content' fontSize='md' withColumnBorders>
      <thead>
        <tr>
          <th
            rowSpan={2}
            style={{
              backgroundColor: defaultHeaderColor,
            }}
          >
            Date
          </th>
          <th
            rowSpan={2}
            style={{
              textAlign: 'center',
              backgroundColor: defaultHeaderColor,
            }}
          >
            Total Time
          </th>
          <th
            colSpan={2}
            style={{ textAlign: 'center', backgroundColor: defaultHeaderColor }}
          >
            <Tooltip label='Net feedstock weight processed' fw={400}>
              <Text>Weight Processed</Text>
            </Tooltip>
          </th>
          <th
            colSpan={2}
            style={{ textAlign: 'center', backgroundColor: activeHeaderColor }}
          >
            <Tooltip label='Total time material flow was observed' fw={400}>
              <Text>Active</Text>
            </Tooltip>
          </th>
          {!ignoreUnplannedStops && (
            <>
              <th
                colSpan={2}
                style={{
                  textAlign: 'center',
                  backgroundColor: issueHeaderColor,
                }}
              >
                <Tooltip
                  label='Total time in unplanned stop or system faulted state'
                  fw={400}
                >
                  <Text>Issue</Text>
                </Tooltip>
              </th>
            </>
          )}
          <th
            colSpan={2}
            style={{
              textAlign: 'center',
              backgroundColor: engagedHeaderColor,
            }}
          >
            <Tooltip label='Total time in system engaged state' fw={400}>
              <Text>Engaged</Text>
            </Tooltip>
          </th>
          <th
            colSpan={2}
            style={{
              textAlign: 'center',
              backgroundColor: disengagedHeaderColor,
            }}
          >
            <Tooltip
              label='Total time in system disengaged state, not engaged or faulted'
              fw={400}
            >
              <Text>Disengaged</Text>
            </Tooltip>
          </th>
          <th
            colSpan={2}
            style={{
              textAlign: 'center',
              backgroundColor: issueHeaderColor,
            }}
          >
            <Tooltip label='Total time in system faulted state' fw={400}>
              <Text>Faulted</Text>
            </Tooltip>
          </th>
          <th
            colSpan={2}
            style={{
              textAlign: 'center',
              backgroundColor: plannedStopHeaderColor,
            }}
          >
            <Tooltip label='Total time in planned stops' fw={400}>
              <Text>Planned Stop</Text>
            </Tooltip>
          </th>
          {!ignoreUnplannedStops && (
            <>
              <th
                colSpan={2}
                style={{
                  textAlign: 'center',
                  backgroundColor: unplannedStopHeaderColor,
                }}
              >
                <Tooltip label='Total time in unplanned stops' fw={400}>
                  <Text>Unplanned Stop</Text>
                </Tooltip>
              </th>
            </>
          )}
        </tr>
        <tr>
          <th
            style={{ textAlign: 'right', backgroundColor: defaultHeaderColor2 }}
          >
            Net
          </th>
          <th
            style={{ textAlign: 'right', backgroundColor: defaultHeaderColor2 }}
          >
            <Tooltip
              label='Net feedstock weight processed / active time'
              fw={400}
            >
              <Text>Rate</Text>
            </Tooltip>
          </th>

          <th
            style={{ textAlign: 'right', backgroundColor: activeHeaderColor2 }}
          >
            Hours
          </th>
          <th
            style={{ textAlign: 'right', backgroundColor: activeHeaderColor2 }}
          >
            %
          </th>

          {!ignoreUnplannedStops && (
            <>
              <th
                style={{
                  textAlign: 'right',
                  backgroundColor: issueHeaderColor2,
                }}
              >
                Hours
              </th>
              <th
                style={{
                  textAlign: 'right',
                  backgroundColor: issueHeaderColor2,
                }}
              >
                %
              </th>
            </>
          )}

          <th
            style={{
              textAlign: 'right',
              backgroundColor: engagedHeaderColor2,
            }}
          >
            Hours
          </th>
          <th
            style={{
              textAlign: 'right',
              backgroundColor: engagedHeaderColor2,
            }}
          >
            %
          </th>

          <th
            style={{
              textAlign: 'right',
              backgroundColor: disengagedHeaderColor2,
            }}
          >
            Hours
          </th>
          <th
            style={{
              textAlign: 'right',
              backgroundColor: disengagedHeaderColor2,
            }}
          >
            %
          </th>

          <th
            style={{
              textAlign: 'right',
              backgroundColor: issueHeaderColor2,
            }}
          >
            Hours
          </th>
          <th
            style={{
              textAlign: 'right',
              backgroundColor: issueHeaderColor2,
            }}
          >
            %
          </th>

          <th
            style={{
              textAlign: 'right',
              backgroundColor: plannedStopHeaderColor2,
            }}
          >
            Hours
          </th>
          <th
            style={{
              textAlign: 'right',
              backgroundColor: plannedStopHeaderColor2,
            }}
          >
            %
          </th>
          {!ignoreUnplannedStops && (
            <>
              <th
                style={{
                  textAlign: 'right',
                  backgroundColor: unplannedStopHeaderColor2,
                }}
              >
                Hours
              </th>
              <th
                style={{
                  textAlign: 'right',
                  backgroundColor: unplannedStopHeaderColor2,
                }}
              >
                %
              </th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {temporalStatuses.map((piStatus) => {
          const productionIntervalAnalysis = analyzeProductionInterval({
            subIntervalStates: piStatus.subIntervalStates,
            ignoreUnplannedStops,
          });

          const {
            hours,
            activeHours,
            engagedHours,
            disengagedHours,
            faultedHours,
            unplannedStopHours,
            plannedStopHours,
            issueHours,
          } = productionIntervalAnalysis;

          totalHours += hours;
          totalActiveHours += activeHours;
          totalEngagedHours += engagedHours;
          totalDisengagedHours += disengagedHours;
          totalFaultedHours += faultedHours;
          totalUnplannedStopHours += unplannedStopHours;
          totalPlannedStopHours += plannedStopHours;
          totalIssueHours += issueHours;

          return (
            <tr key={piStatus.productionInterval.id}>
              <td>
                <LinkText
                  to={Router.ProductionDaily({
                    processId: piStatus.productionInterval.processId,
                    productionIntervalId: piStatus.productionInterval.id,
                    date: Temporal.Instant.from(
                      piStatus.productionInterval.startTime,
                    )
                      .toZonedDateTimeISO(facility.timeZoneId)
                      .toPlainDate()
                      .toString(),
                  })}
                >
                  {dayjs
                    .utc(piStatus.productionInterval.startTime)
                    .tz(facility.timeZoneId)
                    .format('L')}
                </LinkText>
              </td>

              <td style={{ textAlign: 'right' }}>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <FormatTimeWithUnits totalMinutes={hours * 60} />

                  <RingProgress
                    rootColor='red'
                    size={50}
                    thickness={10}
                    sections={[
                      {
                        value: (100 * activeHours) / hours,
                        color: engagedHeaderColor,
                      },
                      {
                        value: (100 * issueHours) / hours,
                        color: issueHeaderColor,
                      },
                      {
                        value:
                          (100 * (hours - activeHours - issueHours)) / hours,
                        color: disengagedHeaderColor,
                      },
                    ]}
                  />
                </div>
              </td>

              <td style={{ textAlign: 'right' }}>
                {match(
                  massProductionHistory?.productionIntervalMassStatuses[
                    piStatus.id
                  ].massStats,
                )
                  .with(undefined, () => <Skeleton visible>...</Skeleton>)
                  .with({ status: 'ledger-error' }, () => (
                    <Text color='red'>?</Text>
                  ))
                  .with({ status: 'success' }, (massStat) => (
                    <Text>
                      {massStat.netWeightProcessed.totalIsZero ? (
                        <NetWeightZero unit={WeightUnit.POUND} />
                      ) : (
                        <NetWeight
                          weight={massStat.netWeightProcessed}
                          sourceIconMode='icon-tooltip'
                        />
                      )}
                      <Text color='dimmed' size='xs'>
                        {massStat.unknownFeedstockFlowGroupCount > 0
                          ? ` + ${massStat.unknownFeedstockFlowGroupCount} unknown`
                          : ''}
                      </Text>
                    </Text>
                  ))
                  .exhaustive()}
              </td>
              <td style={{ textAlign: 'right' }}>
                {match(
                  massProductionHistory?.productionIntervalMassStatuses[
                    piStatus.id
                  ].massStats,
                )
                  .with(undefined, () => <Skeleton visible>...</Skeleton>)
                  .with({ status: 'ledger-error' }, () => (
                    <Text color='red'>?</Text>
                  ))
                  .with({ status: 'success' }, (massStat) =>
                    massStat.unknownFeedstockFlowGroupCount > 0 ? (
                      <Text c='dimmed'>?</Text>
                    ) : (
                      <PoundsThroughputWithUnits
                        lbsPerHour={massStat.netMassThroughput.lbsPerHour}
                      />
                    ),
                  )
                  .exhaustive()}
              </td>

              <td style={{ backgroundColor: activeColor }}>
                <FormatTimeWithUnits totalMinutes={activeHours * 60} />
              </td>
              <td style={{ textAlign: 'right', backgroundColor: activeColor }}>
                {((100 * activeHours) / hours).toFixed(1)}%
              </td>

              {!ignoreUnplannedStops && (
                <>
                  <td style={{ backgroundColor: issueColor }}>
                    <FormatTimeWithUnits totalMinutes={issueHours * 60} />
                  </td>
                  <td
                    style={{ textAlign: 'right', backgroundColor: issueColor }}
                  >
                    {((100 * issueHours) / hours).toFixed(1)}%
                  </td>
                </>
              )}

              <td style={{ backgroundColor: engagedColor }}>
                <FormatTimeWithUnits totalMinutes={engagedHours * 60} />
              </td>
              <td style={{ textAlign: 'right', backgroundColor: engagedColor }}>
                {((100 * engagedHours) / hours).toFixed(1)}%
              </td>

              <td style={{ backgroundColor: disengagedColor }}>
                <FormatTimeWithUnits totalMinutes={disengagedHours * 60} />
              </td>
              <td
                style={{ textAlign: 'right', backgroundColor: disengagedColor }}
              >
                {((100 * disengagedHours) / hours).toFixed(1)}%
              </td>

              <td style={{ backgroundColor: issueColor }}>
                <FormatTimeWithUnits totalMinutes={faultedHours * 60} />
              </td>
              <td style={{ textAlign: 'right', backgroundColor: issueColor }}>
                {((100 * faultedHours) / hours).toFixed(1)}%
              </td>

              <td
                style={{
                  backgroundColor: plannedStopColor,
                }}
              >
                <FormatTimeWithUnits totalMinutes={plannedStopHours * 60} />
              </td>
              <td
                style={{
                  textAlign: 'right',
                  backgroundColor: plannedStopColor,
                }}
              >
                {((100 * plannedStopHours) / hours).toFixed(1)}%
              </td>
              {!ignoreUnplannedStops && (
                <>
                  <td
                    style={{
                      backgroundColor: unplannedStopColor,
                    }}
                  >
                    <FormatTimeWithUnits
                      totalMinutes={unplannedStopHours * 60}
                    />
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      backgroundColor: unplannedStopColor,
                    }}
                  >
                    {((100 * unplannedStopHours) / piStatus.totalHours).toFixed(
                      1,
                    )}
                    %
                  </td>
                </>
              )}
            </tr>
          );
        })}
        {temporalStatuses.length === 0 ? (
          <tr>
            <td colSpan={10}>
              <Alert color='grape'>
                There are no production intervals between the selected dates.
              </Alert>
            </td>
          </tr>
        ) : (
          <tr>
            <th
              style={{
                backgroundColor: defaultHeaderColor2,
              }}
            >
              Totals
            </th>
            <td
              style={{
                backgroundColor: defaultHeaderColor2,
                textAlign: 'right',
              }}
            >
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <FormatTimeWithUnits totalMinutes={totalHours * 60} />
                <RingProgress
                  rootColor='red'
                  size={50}
                  thickness={10}
                  sections={[
                    {
                      value: (100 * totalActiveHours) / totalHours,
                      color: engagedHeaderColor,
                    },
                    {
                      value:
                        (100 *
                          (totalHours - totalIssueHours - totalActiveHours)) /
                        totalHours,
                      color: disengagedHeaderColor,
                    },
                    {
                      value: (100 * totalIssueHours) / totalHours,
                      color: issueHeaderColor,
                    },
                  ]}
                />
              </div>
            </td>

            <td
              style={{
                textAlign: 'right',
                backgroundColor: defaultHeaderColor2,
              }}
            >
              {match(massProductionHistory?.totalMassStats)
                .with(undefined, () => <Skeleton visible>...</Skeleton>)
                .with({ status: 'ledger-error' }, () => (
                  <Text color='red'>?</Text>
                ))
                .with({ status: 'success' }, (massStat) => (
                  <Text>
                    {massStat.netWeightProcessed.totalIsZero ? (
                      <NetWeightZero unit={WeightUnit.POUND} />
                    ) : (
                      <NetWeight
                        weight={massStat.netWeightProcessed}
                        sourceIconMode='icon-tooltip'
                      />
                    )}
                    <Text color='dimmed' size='xs'>
                      {massStat.unknownFeedstockFlowGroupCount > 0
                        ? ` + ${massStat.unknownFeedstockFlowGroupCount} unknown`
                        : ''}
                    </Text>
                  </Text>
                ))
                .exhaustive()}
            </td>
            <td
              style={{
                textAlign: 'right',
                backgroundColor: defaultHeaderColor2,
              }}
            >
              {match(massProductionHistory?.totalMassStats)
                .with(undefined, () => <Skeleton visible>...</Skeleton>)
                .with({ status: 'ledger-error' }, () => (
                  <Text color='red'>?</Text>
                ))
                .with({ status: 'success' }, (massStat) =>
                  massStat.unknownFeedstockFlowGroupCount > 0 ? (
                    <Text c='dimmed'>?</Text>
                  ) : (
                    <PoundsThroughputWithUnits
                      lbsPerHour={massStat.netMassThroughput.lbsPerHour}
                    />
                  ),
                )
                .exhaustive()}
            </td>

            <td
              style={{
                backgroundColor: activeHeaderColor,
              }}
            >
              <FormatTimeWithUnits totalMinutes={totalActiveHours * 60} />
            </td>
            <td
              style={{
                textAlign: 'right',
                backgroundColor: activeHeaderColor,
              }}
            >
              {((100 * totalActiveHours) / totalHours).toFixed(1)}%
            </td>

            {!ignoreUnplannedStops && (
              <>
                <td
                  style={{
                    backgroundColor: issueHeaderColor,
                  }}
                >
                  <FormatTimeWithUnits totalMinutes={totalIssueHours * 60} />
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    backgroundColor: issueHeaderColor,
                  }}
                >
                  {((100 * totalIssueHours) / totalHours).toFixed(1)}%
                </td>
              </>
            )}

            <td
              style={{
                backgroundColor: engagedHeaderColor,
              }}
            >
              <FormatTimeWithUnits totalMinutes={totalEngagedHours * 60} />
            </td>
            <td
              style={{
                textAlign: 'right',
                backgroundColor: engagedHeaderColor,
              }}
            >
              {((100 * totalEngagedHours) / totalHours).toFixed(1)}%
            </td>

            <td
              style={{
                backgroundColor: disengagedHeaderColor,
              }}
            >
              <FormatTimeWithUnits totalMinutes={totalDisengagedHours * 60} />
            </td>
            <td
              style={{
                textAlign: 'right',
                backgroundColor: disengagedHeaderColor,
              }}
            >
              {((100 * totalDisengagedHours) / totalHours).toFixed(1)}%
            </td>

            <td
              style={{
                backgroundColor: issueHeaderColor,
              }}
            >
              <FormatTimeWithUnits totalMinutes={totalFaultedHours * 60} />
            </td>
            <td
              style={{
                textAlign: 'right',
                backgroundColor: issueHeaderColor,
              }}
            >
              {((100 * totalFaultedHours) / totalHours).toFixed(1)}%
            </td>

            <td
              style={{
                backgroundColor: plannedStopHeaderColor,
              }}
            >
              <FormatTimeWithUnits totalMinutes={totalPlannedStopHours * 60} />
            </td>
            <td
              style={{
                textAlign: 'right',
                backgroundColor: plannedStopHeaderColor,
              }}
            >
              {((100 * totalPlannedStopHours) / totalHours).toFixed(1)}%
            </td>
            {!ignoreUnplannedStops && (
              <>
                <td
                  style={{
                    backgroundColor: unplannedStopHeaderColor,
                  }}
                >
                  <FormatTimeWithUnits
                    totalMinutes={totalUnplannedStopHours * 60}
                  />
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    backgroundColor: unplannedStopHeaderColor,
                  }}
                >
                  {((100 * totalUnplannedStopHours) / totalHours).toFixed(1)}%
                </td>
              </>
            )}
          </tr>
        )}
      </tbody>
    </Table>
  );
}
