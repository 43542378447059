/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProcessBufferTransferCreationDTO } from '../models/ProcessBufferTransferCreationDTO';
import type { ProcessBufferTransferDTO } from '../models/ProcessBufferTransferDTO';
import type { ProcessBufferTransferPatchDTO } from '../models/ProcessBufferTransferPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProcessBufferTransferService {
    /**
     * @param processBufferTransferGuid
     * @returns ProcessBufferTransferDTO Success
     * @throws ApiError
     */
    public static getProcessBufferTransferById(
        processBufferTransferGuid: string,
    ): CancelablePromise<ProcessBufferTransferDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/process-buffer-transfers/{processBufferTransferGuid}',
            path: {
                'processBufferTransferGuid': processBufferTransferGuid,
            },
        });
    }
    /**
     * @param processBufferTransferGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchProcessBufferTransfer(
        processBufferTransferGuid: string,
        requestBody: ProcessBufferTransferPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/process-buffer-transfers/{processBufferTransferGuid}',
            path: {
                'processBufferTransferGuid': processBufferTransferGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param processBufferTransferGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteProcessBufferTransfer(
        processBufferTransferGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/process-buffer-transfers/{processBufferTransferGuid}',
            path: {
                'processBufferTransferGuid': processBufferTransferGuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createProcessBufferTransfer(
        requestBody: ProcessBufferTransferCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/process-buffer-transfers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
