import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ProcessBufferTransferCreationDTO,
  ProcessBufferTransferId,
  ProcessBufferTransferPatchDTO,
  ProcessBufferTransferService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchProcessBufferTransfer(
  ctx: QueryFunctionContexts['transaction']['processBufferTransfer']['detail'],
) {
  const [{ processBufferTransferId }] = ctx.queryKey;
  if (!processBufferTransferId) {
    throw new Error('process buffer transfer id is required');
  }
  return await ProcessBufferTransferService.getProcessBufferTransferById(
    processBufferTransferId,
  );
}

export function useProcessBufferTransfer(processBufferTransferId?: string) {
  return useQuery({
    queryKey: queryKeys.transaction.processBufferTransfer.detail(
      processBufferTransferId,
    ),
    queryFn: fetchProcessBufferTransfer,
    enabled: processBufferTransferId !== undefined,
  });
}

async function createProcessBufferTransfer(
  processBufferTransfer: ProcessBufferTransferCreationDTO,
) {
  await ProcessBufferTransferService.createProcessBufferTransfer(
    processBufferTransfer,
  );
}

export function useCreateProcessBufferTransfer() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createProcessBufferTransfer,
    onSettled() {
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function patchProcessBufferTransfer({
  processBufferTransferId,
  patch,
}: {
  processBufferTransferId: string;
  patch: ProcessBufferTransferPatchDTO;
}) {
  await ProcessBufferTransferService.patchProcessBufferTransfer(
    processBufferTransferId,
    patch,
  );
}

export function usePatchProcessBufferTransfer(
  processBufferTransferId: ProcessBufferTransferId,
) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: ProcessBufferTransferPatchDTO) =>
      await patchProcessBufferTransfer({ processBufferTransferId, patch }),
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.transaction.processBufferTransfer.detail(
          processBufferTransferId,
        ),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function deleteProcessBufferTransfer(processBufferTransferId: string) {
  await ProcessBufferTransferService.deleteProcessBufferTransfer(
    processBufferTransferId,
  );
}

export function useDeleteProcessBufferTransfer() {
  const invalidator = useQueryKeyInvalidator();

  return useMutation({
    mutationFn: deleteProcessBufferTransfer,
    onSettled(_data, _error, processBufferTransferId) {
      invalidator.invalidateKeys(
        queryKeys.transaction.processBufferTransfer.detail(
          processBufferTransferId,
        ),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}
