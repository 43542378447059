import { Menu } from '@mantine/core';
import { DeleteIcon } from '../Icons';

export function DeleteMenuItem(props: { onClick: () => void }) {
  const { onClick } = props;
  return (
    <Menu.Item color='red' icon={<DeleteIcon size={14} />} onClick={onClick}>
      Delete
    </Menu.Item>
  );
}
