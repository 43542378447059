import { AppPage } from '../App/AppPage';
import { Router } from '../router';
import { AddMaterialClassSetForm } from './AddMaterialClassSetForm';

export function MaterialClassSetCreatePage() {
  return (
    <AppPage
      breadcrumbs={[
        {
          routeName: Router.MaterialClassList(),
          title: 'Material Classes',
        },
      ]}
      title='Add Material Class Set'
    >
      <AppPage.Section>
        <AddMaterialClassSetForm />
      </AppPage.Section>
    </AppPage>
  );
}
