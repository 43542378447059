import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';
import { RequiredDayjsDateTimePicker } from '../DayjsDateTimePicker';
import { useFacilityContext } from '../Facility/FacilityContext';
import { ProcessSelect } from '../Process/ProcessSelect';
import { ProcessId } from '../rest-client';

const ProcessBufferRestorationFormSchema = z.object({
  restoredAt: z.instanceof(dayjs as unknown as typeof Dayjs, {
    message: 'Restored at time is required',
  }),
  processId: z
    .string()
    .uuid()
    .nullable()
    .refine((val) => val !== null, { message: 'Process is required' }),
});

export type ProcessBufferRestorationFormValues = z.infer<
  typeof ProcessBufferRestorationFormSchema
>;

export type ProcessBufferRestorationFormProps = {
  processId?: ProcessId;
};

export function useProcessBufferRestorationForm(
  props: ProcessBufferRestorationFormProps,
) {
  const { processId } = props;
  return useForm<ProcessBufferRestorationFormValues>({
    initialValues: {
      restoredAt: dayjs.utc(),
      processId: processId ?? null,
    },
    validate: zodResolver(ProcessBufferRestorationFormSchema),
  });
}

export function ProcessBufferRestorationFormFields(props: {
  form: UseFormReturnType<ProcessBufferRestorationFormValues>;
}) {
  const { form } = props;

  const facility = useFacilityContext();

  const processSelectDisabled =
    form.values.processId !== null && !form.isDirty('processId');

  return (
    <>
      <RequiredDayjsDateTimePicker
        label='Feedstock Restoration Time'
        description='The time at which the process feedstock input was restored with material.'
        tz={facility.timeZoneId}
        withAsterisk
        {...form.getInputProps('restoredAt')}
      />

      <ProcessSelect
        label='Process'
        description='Process having its feedstock material restored.'
        withAsterisk
        disabled={processSelectDisabled}
        {...form.getInputProps('processId')}
      />
    </>
  );
}
