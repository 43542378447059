import { Paper, Stack, Text, Title } from '@mantine/core';
import { Fragment, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { EChart } from '../echarts/BareEChart';
import classes from './RimasReportCompositionLineChart.module.css';
import {
  poundsPerLaborHourCalculator,
  useRimasTableStore,
} from './RimasTableState';

interface LineDef {
  id: string;
  name: string;
  key: 'ubcPounds' | 'totalProcessedPounds';
  hideDefault: boolean;
}

const LINES: LineDef[] = [
  {
    id: 'ubc',
    name: 'UBC Recovered',
    key: 'ubcPounds',
    hideDefault: false,
  },
  {
    id: 'total',
    name: 'Total Processed',
    key: 'totalProcessedPounds',
    hideDefault: true,
  },
];

export function RimasReportMetricsLineChart() {
  const { filteredRows, includeReconciled } = useRimasTableStore();
  const completeRows = useMemo(
    () =>
      filteredRows?.flatMap((a) => (a.status === 'complete' ? [a] : [])) ?? [],
    [filteredRows],
  );

  const tooltipFormatter = useCallback(
    (
      params: {
        axisValue: number;
        color: string;
        name: string;
        seriesName: string;
        value: [string, number];
      }[],
    ) => {
      const controlNumberGroups = Object.groupBy(params, (p) => p.name);
      const date = params[0].value[0];
      // TODO: Sort by control number instead of series
      return renderToString(
        <div>
          <Title order={5}>{date}</Title>
          <Stack spacing='xs'>
            {Object.entries(controlNumberGroups).map(
              ([controlNumber, params]) => (
                <Paper
                  p='xs'
                  key={controlNumber}
                  className={classes.rimasGraphTooltip}
                  withBorder
                >
                  {(params ?? []).map((p, i) => (
                    <Fragment key={p.seriesName + p.name}>
                      <Title order={6}>{i === 0 ? p.name : ''}</Title>
                      <Text c={p.color} align='right' fw='bold'>
                        {p.value[1].toFixed(0)} lbs / hour
                      </Text>
                      <Text c={p.color} fw='bolder' align='left'>
                        {p.seriesName}
                      </Text>
                    </Fragment>
                  ))}
                </Paper>
              ),
            )}
          </Stack>
        </div>,
      );
    },
    [],
  );

  // TODO: handle events.selectChanged use useState of some sort to not reset selected on reload
  const option = useMemo(() => {
    return {
      xAxis: {
        type: 'time',
      },
      yAxis: {
        type: 'value',
        name: 'Pounds / Labor Hour',
        axisLabel: {
          formatter: '{value}',
        },
      },
      legend: {
        selected: Object.fromEntries(
          LINES.map((l) => [l.name, !l.hideDefault]),
        ),
      },
      tooltip: {
        trigger: 'axis',
        formatter: tooltipFormatter,
      },
      series: LINES.map((line) => ({
        type: 'line',
        name: line.name,
        data: completeRows.map((row) => ({
          name: row.controlNumber,
          value: [
            row.startDate,
            poundsPerLaborHourCalculator(
              line.key,
              row.outputs,
              row.laborHours,
              includeReconciled,
            ).toNumber(),
          ],
        })),
      })),
    };
  }, [completeRows, includeReconciled, tooltipFormatter]);

  return <EChart option={option} h={350} />;
}
