import { Alert, Button, Group, Stack, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { AppPage } from '../App/AppPage';
import { DeleteIcon, EditIcon } from '../Icons';
import AddInternallySourcedMaterialButton from '../InternallySourcedMaterial/AddInternallySourcedMaterialButton';
import { InternallySourcedMaterialTable } from '../InternallySourcedMaterial/InternallySourcedMaterialTable';
import {
  useDeleteInternalMaterialSource,
  useInternalMaterialSource,
} from '../api/internalMaterialSource';
import { InternalMaterialSourceId } from '../rest-client';
import { Router } from '../router';
import { EditInternalMaterialSourceDrawerForm } from './EditInternalMaterialSourceDrawerForm';
import { InternalMaterialSourceConstituentsComposition } from './InternalMaterialSourceConstituentsComposition';
import { InternalMaterialSourceDetails } from './InternalMaterialSourceDetails';

export function InternalMaterialSourceDetail(props: {
  internalMaterialSourceId: InternalMaterialSourceId;
}) {
  const { internalMaterialSourceId } = props;

  const { data: internalMaterialSource, isLoadingError } =
    useInternalMaterialSource(internalMaterialSourceId);
  const [editDrawerOperned, { open: openEditDrawer, close: closeEditDrawer }] =
    useDisclosure(false);
  const deleteSourceMutation = useDeleteInternalMaterialSource();

  const breadcrumbs = [
    {
      routeName: Router.InternalSourceList(),
      title: 'Upstream Material',
    },
  ];

  if (isLoadingError) {
    return (
      <AppPage breadcrumbs={breadcrumbs}>
        <Alert color='red' title='Error Loading Upstream Material Source'>
          An error occurred loading this upstream material source.
        </Alert>
      </AppPage>
    );
  }

  return (
    <AppPage
      breadcrumbs={breadcrumbs}
      title={internalMaterialSource?.name ?? null}
    >
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Upstream Material Source Details</Title>
            <Group>
              <>
                <Button onClick={openEditDrawer} leftIcon={<EditIcon />}>
                  Edit
                </Button>
                <EditInternalMaterialSourceDrawerForm
                  opened={editDrawerOperned}
                  onClose={closeEditDrawer}
                  internalMaterialSourceId={internalMaterialSourceId}
                />
              </>
              <Button
                leftIcon={<DeleteIcon />}
                color='red'
                disabled={internalMaterialSource === undefined}
                onClick={() => {
                  if (internalMaterialSource === undefined) {
                    throw new Error('InternalMaterialSource must be defined');
                  }
                  deleteSourceMutation.mutate(internalMaterialSource, {
                    onError(data, deletedSource) {
                      showNotification({
                        title: 'Error Deleting Upstream Material Source',
                        message: `An error occurred deleting ${deletedSource.name}. This could be due to ${deletedSource.name} having associated upstream-sourced materials or it could be an internal error.`,
                        color: 'red',
                        icon: <IconX />,
                      });
                    },
                    onSuccess(data, deletedSource) {
                      Router.push('InternalSourceList');
                      showNotification({
                        title: 'Upstream Material Source Deleted',
                        message: `${deletedSource.name} was successfully deleted.`,
                        color: 'green',
                        icon: <IconCheck />,
                      });
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Group>
          </Group>
          <Group>
            <InternalMaterialSourceDetails
              internalMaterialSourceId={internalMaterialSourceId}
            />
          </Group>
        </Stack>
      </AppPage.Section>
      <InternalMaterialSourceConstituentsComposition
        internalMaterialSourceId={internalMaterialSourceId}
      />
      <AppPage.Section>
        <Stack>
          <Group>
            <Title order={2}>Upstream Sourced Materials</Title>
            <AddInternallySourcedMaterialButton
              addInternallySourcedMaterialDrawerFormProps={{
                internalMaterialSourceId: internalMaterialSourceId,
              }}
            />
          </Group>
          <InternallySourcedMaterialTable
            internalMaterialSourceId={internalMaterialSourceId}
            paginate={false}
          />
        </Stack>
      </AppPage.Section>
    </AppPage>
  );
}
