import { Temporal } from '@js-temporal/polyfill';
import { Skeleton, Text } from '@mantine/core';
import { P, match } from 'ts-pattern';
import { CommodityIdName } from '../Commodity/CommodityName';
import { FacilityIdName } from '../Facility/FacilityIdName';
import { InferredPropertyWrapper } from '../InferredPropertyWrapper';
import NetWeight from '../Weights/NetWeight';
import { useInternalMaterialSourceDetailed } from '../api/internalMaterialSource';
import { LabeledValue } from '../common';
import { InternalMaterialSourceId } from '../rest-client';

export function InternalMaterialSourceDetails(props: {
  internalMaterialSourceId: InternalMaterialSourceId;
  instant?: Temporal.Instant;
}) {
  const { internalMaterialSourceId, instant } = props;

  const imsSourcedMassQuery = useInternalMaterialSourceDetailed({
    internalMaterialSourceId,
    instant,
  });

  return (
    <>
      <LabeledValue label='Facility'>
        <Skeleton visible={imsSourcedMassQuery.isLoading}>
          {match(imsSourcedMassQuery.data?.internalMaterialSource.facilityId)
            .with(undefined, () => <Text>Loading...</Text>)
            .with(P.string, (facilityId) => (
              <FacilityIdName facilityId={facilityId} />
            ))
            .exhaustive()}
        </Skeleton>
      </LabeledValue>
      <LabeledValue label='Commodity'>
        <Skeleton visible={imsSourcedMassQuery.isLoading}>
          {match(imsSourcedMassQuery.data?.internalMaterialSource.commodityId)
            .with(undefined, () => <Text>Loading...</Text>)
            .with(null, () => (
              <Text c='red' weight='bold'>
                not set
              </Text>
            ))
            .otherwise((commodityId) => (
              <CommodityIdName commodityId={commodityId} />
            ))}
        </Skeleton>
      </LabeledValue>
      <LabeledValue label='Sourced Material Count'>
        <Skeleton visible={imsSourcedMassQuery.isLoading}>
          {match(imsSourcedMassQuery.data?.internallySourcedMaterialCount)
            .with(undefined, () => <Text>Loading...</Text>)
            .otherwise((count) => (
              <Text>{count}</Text>
            ))}
        </Skeleton>
      </LabeledValue>
      <LabeledValue label='Sourced Mass'>
        <Skeleton visible={imsSourcedMassQuery.isLoading}>
          {match(imsSourcedMassQuery.data)
            .with(undefined, () => <Text>Loading...</Text>)
            .with({ status: 'ledger-error' }, () => (
              <Text color='red'>Ledger Error</Text>
            ))
            .with(
              { status: 'success' },
              ({ netWeight, internallySourcedMaterialUnknownMassCount }) => (
                <div
                  style={{
                    display: 'inline-flex',
                    gap: '0.5ch',
                  }}
                >
                  <InferredPropertyWrapper>
                    <NetWeight
                      weight={netWeight}
                      sourceIconMode='icon-tooltip'
                    />{' '}
                  </InferredPropertyWrapper>
                  <Text color='dimmed'>
                    {internallySourcedMaterialUnknownMassCount > 0
                      ? ` + ${internallySourcedMaterialUnknownMassCount} unknown sourced materials`
                      : ''}
                  </Text>
                </div>
              ),
            )
            .exhaustive()}
        </Skeleton>
      </LabeledValue>
    </>
  );
}
