import { Card, Group, Loader, Progress, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { LineChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { useCallback } from 'react';
import Temporal from '../../../Temporal/temporal.ts';
import { useChutecMaterialClassAreaWindows } from '../../../api/chutec';
import { EChart } from '../../../echarts/BareEChart';
import {
  ChutecSortSystemDTO,
  ChutecStream,
  ProcessDTO,
} from '../../../rest-client';
import cssClasses from './ChutecSystemMetrics.module.css';

const WINDOW_HACK_LENGTH_SECONDS = 7;

echarts.use([
  LineChart,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DatasetComponent,
]);

export function ChutecSystemMetrics(props: {
  system: ChutecSortSystemDTO;
  process: ProcessDTO;
  startTime: Temporal.Instant;
  endTime?: Temporal.Instant;
}) {
  const { system, startTime, endTime } = props;
  // TODO(chutec): Handle in progress process run (endTime != null)
  // TODO(chutec): as commented elsewhere, this time padding is a hack
  const windowStartTime = dayjs.utc(
    startTime.subtract({ seconds: WINDOW_HACK_LENGTH_SECONDS })
      .epochMilliseconds,
  );
  const windowEndTime = dayjs.utc(
    endTime?.add({ seconds: WINDOW_HACK_LENGTH_SECONDS })?.epochMilliseconds,
  );
  const inputWindowsQuery = useChutecMaterialClassAreaWindows(
    system.id,
    ChutecStream.INPUT,
    windowStartTime,
    windowEndTime,
  );
  const ejectWindowsQuery = useChutecMaterialClassAreaWindows(
    system.id,
    ChutecStream.EJECT,
    windowStartTime,
    windowEndTime,
  );

  const percentageFormatter = useCallback(
    (val: number | null) =>
      (val === null ? 'unknown ' : (val * 100).toFixed(1)) + '%',
    [],
  );
  const m2HrFormatter = useCallback(
    (val: number) => `${val.toFixed(2)} ㎡/hr`,
    [],
  );

  if (!(inputWindowsQuery.data && ejectWindowsQuery.data)) {
    return <Loader />;
  }

  const inputWindows = inputWindowsQuery.data;
  const ejectWindows = ejectWindowsQuery.data;
  // TODO(chutec): Assert that we have the same time basis!

  const totalEjectEfficiency =
    ejectWindows.totalAreasM2[ejectWindows.totalAreasM2.length - 1] /
    inputWindows.totalAreasM2[inputWindows.totalAreasM2.length - 1];

  const totalInputArea =
    inputWindows.totalAreasM2[inputWindows.totalAreasM2.length - 1];
  const totalDuration = dayjs.duration(
    dayjs
      .utc(inputWindows.timestamps[inputWindows.timestamps.length - 1])
      .diff(inputWindows.timestamps[0]),
  );
  const totalHours = totalDuration.asHours();

  const differentialEjectTotalAreas = ejectWindows.totalAreasM2
    .slice(1)
    .map((ejectArea, i) => ejectArea - ejectWindows.totalAreasM2[i]);
  const differentialInputTotalAreas = inputWindows.totalAreasM2
    .slice(1)
    .map((inputArea, i) => inputArea - inputWindows.totalAreasM2[i]);
  const inputAreaRatesM2Hr = differentialInputTotalAreas.map((areaDiff, i) => {
    const deltaT = dayjs.duration(
      dayjs
        .utc(inputWindows.timestamps[i + 1])
        .diff(dayjs.utc(inputWindows.timestamps[i])),
    );
    return areaDiff / deltaT.asHours();
  });

  const differentialEjectEfficiencies = differentialEjectTotalAreas.map(
    (diffEjectArea, i) => diffEjectArea / differentialInputTotalAreas[i],
  );

  // TODO(chutec): Eject efficiency graph/metrics
  // TODO(chutec): Sort program
  // TODO(chutec): Material processing rate in M^2/whatever
  return (
    <Stack>
      <Group>
        <Card p='md' radius='md' withBorder shadow='sm'>
          <Text transform='uppercase' color='dimmed' weight={700}>
            Avg. Throughput
          </Text>
          <Group spacing='xs'>
            <Text size='xl' weight={500}>
              {(totalInputArea / totalHours).toFixed(2)}{' '}
            </Text>
            <Text size='md' color='dimmed'>
              ㎡/hr
            </Text>
          </Group>
        </Card>
        <Card p='md' radius='md' withBorder shadow='sm'>
          <Text transform='uppercase' color='dimmed' weight={700}>
            Avg. Eject Efficiency
          </Text>
          <Text size='xl' weight={500}>
            {(totalEjectEfficiency * 100).toFixed(1)}%
          </Text>
          <Progress
            sections={[
              { value: totalEjectEfficiency * 100, color: 'teal' },
              { value: (1 - totalEjectEfficiency) * 100, color: 'orange' },
            ]}
            mt='md'
            size='lg'
            radius='xl'
          />
        </Card>
      </Group>
      {/* TODO(chutec): Time series of eject efficiency and throughput */}
      <EChart
        className={cssClasses.chart}
        option={{
          title: {
            text: 'Eject Efficiency',
          },
          dataset: {
            source: {
              timestamps: inputWindows.timestamps.slice(1),
              ejectEfficiency: differentialEjectEfficiencies,
            },
          },
          xAxis: {
            type: 'time',
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: (val: number | null) =>
                `${val === null ? 'unknown ' : val * 100}%`,
            },
            max: 1,
          },
          series: [
            {
              type: 'line',
              encode: { x: 'timestamps', y: 'ejectEfficiency' },
              name: 'Eject Efficiency',
              areaStyle: {},
            },
          ],
          tooltip: {
            trigger: 'axis',
            valueFormatter: percentageFormatter,
          },
        }}
      />
      <EChart
        className={cssClasses.chart}
        option={{
          title: {
            text: 'Throughput',
          },
          dataset: {
            source: {
              timestamps: inputWindows.timestamps.slice(1),
              areaRates: inputAreaRatesM2Hr,
            },
          },
          xAxis: {
            type: 'time',
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: m2HrFormatter,
            },
          },
          series: [
            {
              type: 'line',
              encode: { x: 'timestamps', y: 'areaRates' },
              name: 'Areal Rate',
              areaStyle: {},
            },
          ],
          tooltip: {
            trigger: 'axis',
            valueFormatter: m2HrFormatter,
          },
        }}
      />
    </Stack>
  );
}
