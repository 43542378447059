import { Button, ButtonProps } from '@mantine/core';
import { useDeleteEntityModal } from '../Form/useDeleteEntityModal';
import { DeleteIcon } from '../Icons';
import { useDeleteInternalMaterialSinkContainerTransfer } from '../api/internalSinkContainerTransfer';
import { InternalSinkContainerTransferId } from '../rest-client';

export type DeleteInternalMaterialSinkContainerTransferButtonProps = Omit<
  ButtonProps,
  'onClick'
> & {
  sinkTransferId: InternalSinkContainerTransferId;
  onSucess?: () => void;
};

export function DeleteInternalSinkContainerTransferButton(
  props: DeleteInternalMaterialSinkContainerTransferButtonProps,
) {
  const {
    sinkTransferId: transferId,
    leftIcon = <DeleteIcon />,
    color = 'red',
    onSucess,
    ...buttonProps
  } = props;
  const deleteMutation = useDeleteInternalMaterialSinkContainerTransfer();
  const openDeleteModal = useDeleteEntityModal(
    transferId,
    deleteMutation,
    'Material Export',
    onSucess,
  );

  return (
    <Button
      leftIcon={leftIcon}
      color={color}
      onClick={openDeleteModal}
      {...buttonProps}
    >
      Delete
    </Button>
  );
}
