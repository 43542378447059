import { match } from 'ts-pattern';
import { The404 } from '../The404';
import { Router } from '../router';
import { AddTruckLoadPage } from './AddTruckLoadPage';
import { AddTruckLoadTransferPage } from './AddTruckLoadTransferPage';
import { TruckLoadDetailPage } from './TruckLoadDetailPage';
import { TruckLoadsPage } from './TruckLoadsPage';

export function TruckLoadArea() {
  const route = Router.useRoute([
    'TruckLoadList',
    'TruckLoadAdd',
    'TruckLoadDetail',
    'TruckLoadTransferAdd',
  ]);

  return match(route)
    .with({ name: 'TruckLoadList' }, () => <TruckLoadsPage />)
    .with({ name: 'TruckLoadAdd' }, () => <AddTruckLoadPage />)
    .with({ name: 'TruckLoadDetail' }, ({ params: { truckLoadId } }) => (
      <TruckLoadDetailPage truckLoadId={truckLoadId} />
    ))
    .with({ name: 'TruckLoadTransferAdd' }, () => <AddTruckLoadTransferPage />)
    .with(undefined, () => <The404 />)
    .exhaustive();
}
