import { v4 as uuidv4 } from 'uuid';
import { useCreateCommoditySpotPrice } from '../api/commoditySpotPrice';
import { DrawerForm, DrawerFormDrawerProps } from '../Form/DrawerForm';
import { CommodityId, CommoditySpotPriceCreationDTO } from '../rest-client';
import {
  CommoditySpotPriceFormFields,
  CommoditySpotPriceFormValues,
  useCommoditySpotPriceForm,
} from './CommoditySpotPriceForm';

export type AddCommoditySpotPriceDrawerFormProps = {
  onSuccess?: (commodity: CommoditySpotPriceCreationDTO) => void;
  commodityId: CommodityId;
} & DrawerFormDrawerProps;

export function AddCommoditySpotPriceDrawerForm(
  props: AddCommoditySpotPriceDrawerFormProps,
) {
  const { onSuccess, onClose, ...drawerFormDrawerProps } = props;

  const createMutation = useCreateCommoditySpotPrice(props.commodityId);
  const form = useCommoditySpotPriceForm();

  const formValuesToDto = ({
    pricedAt,
    weightUnit,
    usdPerUnitOfWeight,
  }: CommoditySpotPriceFormValues) => {
    console.log(`pricedAt: ${pricedAt.toISOString()}`);
    console.log(
      `Trying to remove time part: ${pricedAt.utcOffset(0, true).toISOString()}`,
    );

    const commoditySpotPrice: CommoditySpotPriceCreationDTO = {
      id: uuidv4(),
      pricedAt: pricedAt.format('YYYY-MM-DD'),
      weightUnit,
      usdPerUnitOfWeight,
    };
    return commoditySpotPrice;
  };

  return (
    <DrawerForm
      entityName='Commodity'
      form={form}
      drawerFormVariant='create'
      mutation={createMutation}
      onSuccess={onSuccess}
      formValuesToDto={formValuesToDto}
      onClose={onClose}
      {...drawerFormDrawerProps}
    >
      <CommoditySpotPriceFormFields form={form} />
    </DrawerForm>
  );
}
