import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ProcessBufferDepletionCreationDTO,
  ProcessBufferDepletionId,
  ProcessBufferDepletionPatchDTO,
  ProcessBufferDepletionService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchProcessBufferDepletion(
  ctx: QueryFunctionContexts['processBufferDepletion']['detail'],
) {
  const [{ processBufferDepletionId }] = ctx.queryKey;
  if (!processBufferDepletionId) {
    throw new Error('process buffer depletion is required');
  }

  return await ProcessBufferDepletionService.getProcessBufferDepletionById(
    processBufferDepletionId,
  );
}

export function useProcessBufferDepletion(processBufferDepletionId?: string) {
  return useQuery({
    queryKey: queryKeys.processBufferDepletion.detail(processBufferDepletionId),
    queryFn: fetchProcessBufferDepletion,
    enabled: processBufferDepletionId !== undefined,
  });
}

async function createProcessBufferDepletion(
  processBufferDepletion: ProcessBufferDepletionCreationDTO,
) {
  await ProcessBufferDepletionService.createProcessBufferDepletion(
    processBufferDepletion,
  );
}

export function useCreateProcessBufferDepletion() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createProcessBufferDepletion,
    onSettled() {
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function patchProcessBufferDepletion({
  processBufferDepletionId,
  patch,
}: {
  processBufferDepletionId: string;
  patch: ProcessBufferDepletionPatchDTO;
}) {
  await ProcessBufferDepletionService.patchProcessBufferDepletion(
    processBufferDepletionId,
    patch,
  );
}

export function usePatchProcessBufferDepletion(
  processBufferDepletionId: ProcessBufferDepletionId,
) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: ProcessBufferDepletionPatchDTO) =>
      await patchProcessBufferDepletion({ processBufferDepletionId, patch }),
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.processBufferDepletion.detail(processBufferDepletionId),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}

async function deleteProcessBufferDepletion(processBufferDepletionId: string) {
  await ProcessBufferDepletionService.deleteProcessBufferDepletion(
    processBufferDepletionId,
  );
}

export function useDeleteProcessBufferDepletion() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteProcessBufferDepletion,
    onSettled(_data, _error, processBufferDepletionId) {
      invalidator.invalidateKeys(
        queryKeys.processBufferDepletion.detail(processBufferDepletionId),
      );
      invalidator.invalidateInventoryLedger();
    },
  });
}
