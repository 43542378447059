import { Skeleton } from '@mantine/core';
import { InventoryLedgerRowTemplate } from './InventoryLedgerRowTemplate';

export function InventoryLedgerSkeletonRow() {
  return (
    <InventoryLedgerRowTemplate
      date={<Skeleton>00/00/0000</Skeleton>}
      eventType={<Skeleton>Loading...</Skeleton>}
      source={<Skeleton>Loading...</Skeleton>}
      destination={<Skeleton>Loading...</Skeleton>}
      actions={<Skeleton>Loading...</Skeleton>}
    />
  );
}
