import {
  Accordion,
  ActionIcon,
  ActionIconProps,
  Group,
  List,
  Modal,
  ModalProps,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconMaximize } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { InternallySourcedMaterialIcon } from '../Icons';
import { InternallySourcedMaterialName } from '../Repository/RepositoryName';
import { InternalMaterialSourceDerivedFromDTO } from '../rest-client';
import { InternalMaterialSourceName } from './InternalMaterialSourceName';

export interface InternalMaterialSourcesDerivedFromTextModalProps {
  sources: InternalMaterialSourceDerivedFromDTO[];
  showSourceNames: boolean;
  topSection?: ReactNode;
  actionIconProps?: Omit<ActionIconProps, 'onClick'>;
  modalProps?: Omit<ModalProps, 'opened' | 'onClose'>;
}

export function InternalMaterialSourcesDerivedFromTextModal(
  props: InternalMaterialSourcesDerivedFromTextModalProps,
) {
  const {
    sources,
    showSourceNames,
    topSection,
    actionIconProps = {
      variant: 'outline',
      color: 'teal',
      size: 'sm',
    },
    modalProps = {
      title: 'Material Sources',
      size: 'auto',
    },
  } = props;

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Group spacing='xs'>
        {showSourceNames ? (
          sources.map((source) => (
            <InternalMaterialSourceName
              internalMaterialSource={source.internalMaterialSource}
              key={source.internalMaterialSource.id}
            />
          ))
        ) : (
          <Text>{sources.length} sources</Text>
        )}
        <ActionIcon {...actionIconProps} onClick={open}>
          <IconMaximize />
        </ActionIcon>
      </Group>
      <Modal opened={opened} onClose={close} {...modalProps}>
        <Stack>
          {topSection}
          <Accordion variant='separated'>
            {sources.map((source) => (
              <Accordion.Item
                value={source.internalMaterialSource.id}
                key={source.internalMaterialSource.id}
              >
                <Accordion.Control
                  icon={
                    <Text c='dimmed'>
                      {source.internallySourcedMaterials.length}
                    </Text>
                  }
                  w='100%'
                >
                  <Group position='apart'>
                    <InternalMaterialSourceName
                      withIcon
                      internalMaterialSource={source.internalMaterialSource}
                    />
                  </Group>
                </Accordion.Control>
                <Accordion.Panel>
                  <List
                    spacing='xs'
                    size='sm'
                    center
                    icon={<InternallySourcedMaterialIcon />}
                  >
                    {source.internallySourcedMaterials.map((ism) => (
                      <List.Item key={ism.id}>
                        <InternallySourcedMaterialName
                          internallySourcedMaterial={ism}
                        />
                      </List.Item>
                    ))}
                  </List>
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        </Stack>
      </Modal>
    </>
  );
}
