import { Table, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import { ContainerIdName } from '../Container/ContainerIdName';
import { ContainerNetWeight } from '../Container/ContainerNetWeight';
import { useFacilityContext } from '../Facility/FacilityContext';
import { LinkText } from '../Link';
import { TableEmptyBasicContent } from '../TableEmptyBasicContent.tsx';
import { UnanalyzedContainerSampleDTO } from '../rest-client';
import { Router } from '../router';

export function UnanalyzedContainerSamplesTable(props: {
  unanalyzedContainerSamples: UnanalyzedContainerSampleDTO[];
}) {
  const { unanalyzedContainerSamples } = props;
  const facility = useFacilityContext();
  const { spacing } = useMantineTheme();

  return (
    <Table>
      <caption
        style={{
          captionSide: 'bottom',
          textAlign: 'center',
          padding: spacing.md,
        }}
      >
        {unanalyzedContainerSamples.length === 0 && (
          <TableEmptyBasicContent>
            All container samples have been analyzed
          </TableEmptyBasicContent>
        )}
      </caption>
      <thead>
        <tr>
          <th>Sample Taken Time</th>
          <th>Container</th>
          <th style={{ textAlign: 'right' }}>Container Net Weight</th>
        </tr>
      </thead>
      <tbody>
        {unanalyzedContainerSamples.map((containerSample) => (
          <tr key={containerSample.containerSampleId}>
            <td>
              <LinkText
                to={Router.ContainerSampleDetail({
                  containerSampleId: containerSample.containerSampleId,
                })}
              >
                {dayjs
                  .utc(containerSample.sampleTakenTimestamp)
                  .tz(facility.timeZoneId)
                  .format('LLL')}
              </LinkText>
            </td>
            <td>
              <ContainerIdName
                containerId={containerSample.containerId}
                time={dayjs.utc(containerSample.sampleTakenTimestamp)}
              />
            </td>
            <td style={{ textAlign: 'right' }}>
              <ContainerNetWeight
                containerId={containerSample.containerId}
                timestamp={dayjs.utc(containerSample.sampleTakenTimestamp)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
