import { InteractionRequiredAuthError } from '@azure/msal-browser';
import createClient, { ClientOptions, Middleware } from 'openapi-fetch';
import { msalInstance } from '../Microsoft/msalConfig';
import type { paths } from '../openapi';

type ClientType = ReturnType<typeof createClient<paths>>;

export let client: ClientType;

export function setupClient(options: ClientOptions) {
  client = createClient<paths>(options);
  client.use(msalMiddleware);
}

const msalMiddleware: Middleware = {
  async onRequest(req) {
    const account =
      msalInstance.getActiveAccount() ?? msalInstance.getAllAccounts()[0];

    const accessTokenRequest = {
      scopes: [`api://${import.meta.env.VITE_AZURE_AD_CLIENT_ID}/vali-sort`],
      // TODO(2297): Make this robust against multiple accounts
      account,
    };

    const { accessToken } = await msalInstance
      .acquireTokenSilent(accessTokenRequest)
      .catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
          return msalInstance.acquireTokenPopup(accessTokenRequest);
        }
        throw e;
      });
    req.headers.set('Authorization', `Bearer ${accessToken}`);
    return req;
  },
};
