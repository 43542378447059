import { Alert, Group, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormActionButton } from '../Form/FormActionButton';
import { useFormNavBlocker } from '../Form/useFormNavBlocker';
import { useAddVendor } from '../api/vendor';
import { MaterialVendorDTO } from '../rest-client';
import { Router } from '../router';
import { VendorFormFields, useVendorForm } from './VendorForm';

export function AddVendorForm() {
  const addVendorMutation = useAddVendor();
  const form = useFormNavBlocker(useVendorForm());
  const vendorId = useMemo(() => uuidv4(), []);
  const onSubmit = form.onSubmit((values) => {
    const vendor: MaterialVendorDTO = {
      id: vendorId,
      name: values.name,
      location: values.location ?? null,
      contactName: values.contactName ?? null,
    };
    addVendorMutation.mutate(vendor, {
      onError(data, failedVendorCreation) {
        showNotification({
          title: 'Error Creating Vendor',
          message: `An error occurred creating ${failedVendorCreation.name}.`,
          color: 'red',
          icon: <IconX />,
        });
      },
      onSuccess(data, createdVendor) {
        Router.replace('VendorDetail', { vendorId });
        showNotification({
          title: 'Vendor Created',
          message: `${createdVendor.name} was successfully created.`,
          color: 'green',
          icon: <IconCheck />,
        });
      },
    });
  });

  if (addVendorMutation.isError) {
    return (
      <Alert
        color='red'
        title='Error Creating Vendor'
        withCloseButton
        closeButtonLabel='Clear Error'
        onClose={() => addVendorMutation.reset()}
      >
        The Vendor may or may not have been saved. You can clear the error and
        try again.
      </Alert>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack maw={500}>
        <VendorFormFields form={form} loading={addVendorMutation.isLoading} />
        <Group position='right'>
          <FormActionButton
            action='cancel'
            onClick={() => Router.push('VendorList')}
            loading={addVendorMutation.isLoading}
          />
          <FormActionButton
            type='submit'
            action='saveCreation'
            loading={addVendorMutation.isLoading}
          >
            Add Vendor
          </FormActionButton>
        </Group>
      </Stack>
    </form>
  );
}
