import { ReactNode } from 'react';
import type {
  BufferDepletionStatus,
  BufferRestorationStatus,
  ClaimStatus,
  FeedFlowGroupStatus,
  OutputContainerChangeStatus,
  ScaleReadingStatus,
  TransactionStatus,
} from './LedgerStatusContext';
import classes from './css/InventoryLedgerRowTemplate.module.css';

interface InventoryLedgerRowTemplateProps {
  id?: string;
  entryStatus?:
    | TransactionStatus
    | ScaleReadingStatus
    | ClaimStatus
    | OutputContainerChangeStatus
    | BufferRestorationStatus
    | BufferDepletionStatus
    | FeedFlowGroupStatus;
  date: ReactNode;
  eventType: ReactNode;
  source: ReactNode;
  destination: ReactNode;
  actions: ReactNode;
}

export function InventoryLedgerRowTemplate(
  props: InventoryLedgerRowTemplateProps,
) {
  const {
    entryStatus = { status: '' },
    date,
    eventType,
    source,
    destination,
    actions,
    id = '',
  } = props;

  const { status } = entryStatus;
  return (
    <tr className={`${classes.tableRow} ledger-${status}`} id={`entry-${id}`}>
      <td>{date}</td>
      <td>{eventType}</td>
      <td>{source}</td>
      <td>{destination}</td>
      <td>{actions}</td>
    </tr>
  );
}
