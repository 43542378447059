import { useLocation } from '@swan-io/chicane';
import { Router } from '../router';

const INTERNAL_REDIRECTS: Record<string, Parameters<typeof Router.replace>[0]> =
  {
    '/': 'FacilityInsightsDashboard',
  } as const;

function redirectIfMatched(pathToTest: readonly string[]) {
  const path = pathToTest.join('/') || '/';
  if (path in INTERNAL_REDIRECTS) {
    Router.replace(INTERNAL_REDIRECTS[path]);
  }
}

export function useRedirects() {
  const location = useLocation();

  redirectIfMatched(location.path);
}
