import { Pagination, Table, Text, useMantineTheme } from '@mantine/core';
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { P, match } from 'ts-pattern';
import { ContainerIdName } from '../Container/ContainerIdName';
import { ContainerNetWeight } from '../Container/ContainerNetWeight';
import { useFacilityContext } from '../Facility/FacilityContext';
import { LinkText } from '../Link';
import { TableEmptyBasicContent } from '../TableEmptyBasicContent.tsx';
import NetWeight from '../Weights/NetWeight';
import QuantizedWeight from '../Weights/QuantizedWeight';
import { MaterialClassSetContainerSampleAnalysesDTO } from '../rest-client';
import { Router } from '../router';

export function MaterialClassSetContainerSampleAnalysesTable(props: {
  materialClassSetContainerSampleAnalyses: MaterialClassSetContainerSampleAnalysesDTO;
}) {
  const { materialClassSetContainerSampleAnalyses } = props;
  const facility = useFacilityContext();
  const { colors, spacing } = useMantineTheme();
  const [activePage, setPage] = useState(1);

  const { materialClassSet, containerSampleAnalyses } =
    materialClassSetContainerSampleAnalyses;

  const pageSize = 10;
  const totalPages = Math.ceil(containerSampleAnalyses.length / pageSize);
  const paginatedContainerSampleAnalyses = containerSampleAnalyses.slice(
    (activePage - 1) * pageSize,
    activePage * pageSize,
  );

  return (
    <Table captionSide='bottom'>
      <caption
        style={{
          captionSide: 'bottom',
          textAlign: 'center',
          padding: spacing.md,
        }}
      >
        {containerSampleAnalyses.length === 0 ? (
          <TableEmptyBasicContent>
            No Analyzed Container Samples
          </TableEmptyBasicContent>
        ) : (
          <Pagination
            value={activePage}
            onChange={setPage}
            total={totalPages}
          />
        )}
      </caption>
      <thead>
        <tr>
          <th>Time</th>
          <th>Container</th>
          <th>Complete</th>
          <th style={{ textAlign: 'right' }}>Container Net Weight</th>
          <th style={{ textAlign: 'right' }}>Sample Weight</th>
          {materialClassSet.materialClasses.map((materialClass) => (
            <th
              key={materialClass.id}
              style={{
                borderLeftColor: colors.gray[4],
                borderLeftStyle: 'solid',
                borderLeftWidth: '.5px',
                textAlign: 'right',
              }}
            >
              {materialClass.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {paginatedContainerSampleAnalyses.map((analysis) => {
          const measurementMap = new Map(
            Object.entries(analysis.manualMaterialClassMeasurements),
          );
          const measurements = materialClassSet.materialClasses.map(
            (materialClass) => ({
              materialClass,
              measurement: measurementMap.get(materialClass.id),
            }),
          );

          const totalTicks = Object.values(
            analysis.manualMaterialClassMeasurements,
          ).reduce((total, m) => total + m.weight.ticks, 0);

          const measurementTicks = measurements.map(
            (mcm) => mcm.measurement?.weight.ticks ?? 0,
          );
          const maxMeasurementIdx = measurementTicks.indexOf(
            Math.max(...measurementTicks),
          );

          return (
            <tr key={analysis.manualContainerSampleAnalysisId}>
              <td>
                <LinkText
                  to={Router.ContainerSampleDetail({
                    containerSampleId: analysis.containerSampleId,
                  })}
                >
                  {dayjs
                    .utc(analysis.sampleTakenAtTime)
                    .tz(facility.timeZoneId)
                    .format('LLL')}
                </LinkText>
              </td>
              <td>
                <ContainerIdName
                  containerId={analysis.containerId}
                  time={dayjs.utc(analysis.sampleTakenAtTime)}
                />
              </td>
              <td style={{ textAlign: 'center' }}>
                {analysis.isComplete ? (
                  <IconCheck color={colors.green[6]} />
                ) : (
                  <IconAlertTriangle color={colors.yellow[7]} />
                )}
              </td>
              <td style={{ textAlign: 'right' }}>
                <ContainerNetWeight
                  containerId={analysis.containerId}
                  timestamp={dayjs.utc(analysis.sampleTakenAtTime)}
                />
              </td>
              <td style={{ textAlign: 'right' }}>
                {analysis.specifiedTotalSampleWeight ? (
                  <QuantizedWeight
                    weight={analysis.specifiedTotalSampleWeight}
                  />
                ) : analysis.isComplete ? (
                  <NetWeight weight={analysis.accumulatedTotalSampleWeight} />
                ) : (
                  <Text c='dimmed'>?</Text>
                )}
              </td>
              {measurements.map(({ materialClass, measurement }, idx) => (
                <td
                  key={materialClass.id}
                  style={{
                    textAlign: 'right',
                    borderLeftColor: colors.gray[4],
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '.5px',
                  }}
                >
                  {match(measurement?.weight.ticks)
                    .with(undefined, () => <Text c='dimmed'>?</Text>)
                    .with(P.number.lte(0), () => <Text c='dimmed'>-</Text>)
                    .with(P.number, (ticks) => (
                      <Text fw={idx === maxMeasurementIdx ? 700 : undefined}>
                        {((ticks / totalTicks) * 100).toFixed(1)}%
                      </Text>
                    ))
                    .exhaustive()}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
