import { useMutation, useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import Temporal from '../Temporal/temporal';
import {
  InventoryService,
  RecoveryGoalCreationDTO,
  RecoveryGoalPatchDTO,
  RecoveryGoalService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchRecoveryGoals() {
  return await RecoveryGoalService.getAllRecoveryGoals();
}

export function useRecoveryGoals() {
  return useQuery({
    queryKey: queryKeys.recoveryGoal.list(),
    queryFn: fetchRecoveryGoals,
  });
}

async function fetchRecoveryGoal(
  ctx: QueryFunctionContexts['recoveryGoal']['detail'],
) {
  const [{ recoveryGoalId }] = ctx.queryKey;
  return await RecoveryGoalService.getRecoveryGoal(recoveryGoalId);
}

export function useRecoveryGoal(id: string) {
  return useQuery({
    queryKey: queryKeys.recoveryGoal.detail(id),
    queryFn: fetchRecoveryGoal,
  });
}

async function createRecoveryGoal(dto: RecoveryGoalCreationDTO) {
  await RecoveryGoalService.createRecoveryGoal(dto);
}

export function useCreateRecoveryGoal() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createRecoveryGoal,
    onSettled(data, error, dto) {
      invalidator.invalidateKeys(
        queryKeys.recoveryGoal.list(),
        queryKeys.recoveryGoal.detail(dto.id),
      );
    },
  });
}

async function patchRecoveryGoal(id: string, patch: RecoveryGoalPatchDTO) {
  await RecoveryGoalService.patchRecoveryGoal(id, patch);
}

export function usePatchRecoveryGoal(id: string) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: RecoveryGoalPatchDTO) =>
      await patchRecoveryGoal(id, patch),
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.recoveryGoal.list(),
        queryKeys.recoveryGoal.detail(id),
      );
    },
  });
}

async function deleteRecoveryGoal(id: string) {
  await RecoveryGoalService.deleteRecoveryGoal(id);
}

export function useDeleteRecoveryGoal() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: deleteRecoveryGoal,
    onSuccess(data, id) {
      invalidator.removeKey(queryKeys.recoveryGoal.detail(id));
    },
    onError(error, id) {
      invalidator.resetKey(queryKeys.recoveryGoal.detail(id));
    },
    onSettled() {
      invalidator.invalidateKey(queryKeys.recoveryGoal.list());
    },
  });
}

async function fetchRecoveryGoalPathBehaviorInventoryTotals(
  ctx: QueryFunctionContexts['inventory']['facilityRecoveryGoalPathBehaviorTotals'],
) {
  const [{ facilityId, time }] = ctx.queryKey;
  return InventoryService.getRecoveryGoalPathBehaviorTotals(
    facilityId,
    time?.utc().toISOString(),
  );
}

export function useRecoveryGoalPathBehaviorInventoryTotals(args: {
  facilityId: string;
  time: Dayjs | null;
}) {
  return useQuery({
    queryKey: queryKeys.inventory.facilityRecoveryGoalPathBehaviorTotals(args),
    queryFn: fetchRecoveryGoalPathBehaviorInventoryTotals,
  });
}

async function fetchRecoveryGoalClassificationPerformance(
  ctx: QueryFunctionContexts['recoveryGoal']['classificationPerformance'],
) {
  const [{ facilityId, after, before }] = ctx.queryKey;
  const defaultedBefore = before ?? Temporal.Now.instant();
  return await RecoveryGoalService.getRecoveryGoalClassificationPerformances(
    after.toString(),
    defaultedBefore.toString(),
    facilityId,
  );
}

export function useRecoveryGoalClassificationPerformance({
  facilityId,
  after,
  before,
}: {
  facilityId: string;
  after: Temporal.Instant;
  before?: Temporal.Instant;
}) {
  return useQuery({
    queryKey: queryKeys.recoveryGoal.classificationPerformance({
      facilityId,
      after,
      before,
    }),
    queryFn: fetchRecoveryGoalClassificationPerformance,
  });
}
