/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContainerIncomingTransferEventDTO } from '../models/ContainerIncomingTransferEventDTO';
import type { ContainerIncomingTruckLoadEventDTO } from '../models/ContainerIncomingTruckLoadEventDTO';
import type { ContainerInternallySourcedMaterialPartitionDestinationEventDTO } from '../models/ContainerInternallySourcedMaterialPartitionDestinationEventDTO';
import type { ContainerOutgoingTransferEventDTO } from '../models/ContainerOutgoingTransferEventDTO';
import type { ContainerOutgoingTruckLoadEventDTO } from '../models/ContainerOutgoingTruckLoadEventDTO';
import type { ContainerPurchasedMaterialPartitionDestinationEventDTO } from '../models/ContainerPurchasedMaterialPartitionDestinationEventDTO';
import type { ContainerWeighingEventDTO } from '../models/ContainerWeighingEventDTO';
import type { EmptyContainerWeighedAndSampledConstituentsResultDTO } from '../models/EmptyContainerWeighedAndSampledConstituentsResultDTO';
import type { EmptyGenealogyResultDTO } from '../models/EmptyGenealogyResultDTO';
import type { LedgerErrorContainerWeighedAndSampledConstituentsResultDTO } from '../models/LedgerErrorContainerWeighedAndSampledConstituentsResultDTO';
import type { LedgerErrorGenealogyResultDTO } from '../models/LedgerErrorGenealogyResultDTO';
import type { LedgerErrorOccupiedContainerStatesResultDTO } from '../models/LedgerErrorOccupiedContainerStatesResultDTO';
import type { MaterialContainerCreationDTO } from '../models/MaterialContainerCreationDTO';
import type { MaterialContainerDTO } from '../models/MaterialContainerDTO';
import type { MaterialContainerPatchDTO } from '../models/MaterialContainerPatchDTO';
import type { MaterialContainerTransferCreationDTO } from '../models/MaterialContainerTransferCreationDTO';
import type { MaterialContainerTransferDTO } from '../models/MaterialContainerTransferDTO';
import type { MaterialContainerTransferPatchDTO } from '../models/MaterialContainerTransferPatchDTO';
import type { OccupiedContainerStatesDTO } from '../models/OccupiedContainerStatesDTO';
import type { OccupiedContainerWeighedAndSampledConstituentsResultDTO } from '../models/OccupiedContainerWeighedAndSampledConstituentsResultDTO';
import type { OccupiedGenealogyResultDTO } from '../models/OccupiedGenealogyResultDTO';
import type { OutputContainerChangeEventDTO } from '../models/OutputContainerChangeEventDTO';
import type { ProcessBufferTransferEventDTO } from '../models/ProcessBufferTransferEventDTO';
import type { SinkContainerTransferEventDTO } from '../models/SinkContainerTransferEventDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MaterialContainerService {
    /**
     * @param facilityGuid
     * @returns MaterialContainerDTO Success
     * @throws ApiError
     */
    public static getMaterialContainers(
        facilityGuid?: string,
    ): CancelablePromise<Array<MaterialContainerDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/containers',
            query: {
                'facilityGuid': facilityGuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createMaterialContainer(
        requestBody: MaterialContainerCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/containers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param containerGuid
     * @returns MaterialContainerDTO Success
     * @throws ApiError
     */
    public static getMaterialContainerById(
        containerGuid: string,
    ): CancelablePromise<MaterialContainerDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/containers/{containerGuid}',
            path: {
                'containerGuid': containerGuid,
            },
        });
    }
    /**
     * @param containerGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteMaterialContainerById(
        containerGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/containers/{containerGuid}',
            path: {
                'containerGuid': containerGuid,
            },
        });
    }
    /**
     * @param containerGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchMaterialContainer(
        containerGuid: string,
        requestBody: MaterialContainerPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/containers/{containerGuid}',
            path: {
                'containerGuid': containerGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param transferGuid
     * @returns MaterialContainerTransferDTO Success
     * @throws ApiError
     */
    public static getContainerTransferById(
        transferGuid: string,
    ): CancelablePromise<MaterialContainerTransferDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/containers/transfers/{transferGuid}',
            path: {
                'transferGuid': transferGuid,
            },
        });
    }
    /**
     * @param transferGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchContainerTransfer(
        transferGuid: string,
        requestBody: MaterialContainerTransferPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/containers/transfers/{transferGuid}',
            path: {
                'transferGuid': transferGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param transferGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteContainerTransfer(
        transferGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/containers/transfers/{transferGuid}',
            path: {
                'transferGuid': transferGuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createContainerTransfer(
        requestBody?: MaterialContainerTransferCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/containers/transfers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param facilityGuid
     * @param instant
     * @returns any Success
     * @throws ApiError
     */
    public static occupiedContainerStates(
        facilityGuid?: string,
        instant?: string,
    ): CancelablePromise<(OccupiedContainerStatesDTO | LedgerErrorOccupiedContainerStatesResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/containers/occupied-states',
            query: {
                'facilityGuid': facilityGuid,
                'instant': instant,
            },
        });
    }
    /**
     * @param containerGuid
     * @param timestamp
     * @returns any Success
     * @throws ApiError
     */
    public static getContainerGenealogy(
        containerGuid: string,
        timestamp?: string,
    ): CancelablePromise<(OccupiedGenealogyResultDTO | LedgerErrorGenealogyResultDTO | EmptyGenealogyResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/containers/{containerGuid}/genealogy',
            path: {
                'containerGuid': containerGuid,
            },
            query: {
                'timestamp': timestamp,
            },
        });
    }
    /**
     * @param containerGuid
     * @returns any Success
     * @throws ApiError
     */
    public static getContainerHistoryId(
        containerGuid: string,
    ): CancelablePromise<Array<(ContainerWeighingEventDTO | ContainerIncomingTransferEventDTO | ContainerOutgoingTransferEventDTO | ContainerPurchasedMaterialPartitionDestinationEventDTO | ContainerInternallySourcedMaterialPartitionDestinationEventDTO | ContainerIncomingTruckLoadEventDTO | ContainerOutgoingTruckLoadEventDTO | SinkContainerTransferEventDTO | ProcessBufferTransferEventDTO | OutputContainerChangeEventDTO)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/containers/{containerGuid}/history',
            path: {
                'containerGuid': containerGuid,
            },
        });
    }
    /**
     * @param containerGuid
     * @param instant
     * @returns any Success
     * @throws ApiError
     */
    public static getContainerWeighedAndSampledConstituents(
        containerGuid: string,
        instant?: string,
    ): CancelablePromise<(OccupiedContainerWeighedAndSampledConstituentsResultDTO | EmptyContainerWeighedAndSampledConstituentsResultDTO | LedgerErrorContainerWeighedAndSampledConstituentsResultDTO)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/containers/{containerGuid}/weighed-and-sampled-constituents',
            path: {
                'containerGuid': containerGuid,
            },
            query: {
                'instant': instant,
            },
        });
    }
}
