import { useMutation, useQuery } from '@tanstack/react-query';
import {
  MaterialVendorDTO,
  MaterialVendorId,
  MaterialVendorPatchDTO,
  VendorsService,
} from '../rest-client';
import { QueryFunctionContexts } from './queryKeyTypeUtils';
import { queryKeys, useQueryKeyInvalidator } from './queryKeys';

async function fetchVendors() {
  return await VendorsService.allVendors();
}

export function useVendors() {
  return useQuery({
    queryKey: queryKeys.vendor.list(),
    queryFn: fetchVendors,
  });
}

async function fetchVendor(ctx: QueryFunctionContexts['vendor']['detail']) {
  const [{ vendorId }] = ctx.queryKey;
  if (vendorId === undefined) {
    throw new Error('vendor id cannot be undefined');
  }
  return await VendorsService.getVendorById(vendorId);
}

export function useVendor(vendorId: MaterialVendorId | undefined) {
  return useQuery({
    queryKey: queryKeys.vendor.detail(vendorId),
    queryFn: fetchVendor,
    enabled: vendorId !== undefined,
  });
}

async function createVendor(materialVendorDto: MaterialVendorDTO) {
  await VendorsService.createVendor(materialVendorDto);
}

export function useAddVendor() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: createVendor,
    onSettled() {
      invalidator.invalidateKey(queryKeys.vendor.list());
    },
  });
}

async function patchVendor(
  vendorId: MaterialVendorId,
  vendorPatch: MaterialVendorPatchDTO,
) {
  await VendorsService.patchVendor(vendorId, vendorPatch);
}

export function usePatchVendor(vendorId: MaterialVendorId) {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (patch: MaterialVendorPatchDTO) =>
      await patchVendor(vendorId, patch),
    onSettled() {
      invalidator.invalidateKeys(
        queryKeys.vendor.list(),
        queryKeys.vendor.detail(vendorId),
      );
    },
  });
}

async function deleteVendor(vendorId: MaterialVendorId) {
  await VendorsService.deleteVendor(vendorId);
}

export function useDeleteVendor() {
  const invalidator = useQueryKeyInvalidator();
  return useMutation({
    mutationFn: async (vendor: MaterialVendorDTO) =>
      await deleteVendor(vendor.id),
    onSuccess(data, deletedVendor) {
      invalidator.removeKey(queryKeys.vendor.detail(deletedVendor.id));
    },
    onError(error, deletedVendor) {
      invalidator.invalidateKeys();
      invalidator.resetKey(queryKeys.vendor.detail(deletedVendor.id));
    },
    onSettled() {
      invalidator.invalidateKey(queryKeys.vendor.list());
    },
  });
}
