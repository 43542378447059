import { Text } from '@mantine/core';
import { WeightUnit } from '../rest-client';
import TickInput, { TickInputProps } from './TickInput';
import { getWeightUnitAbbreviation } from './units';

export type QuantizedWeightTicksInputProps = Omit<
  TickInputProps,
  'tickSize' | 'ticks' | 'onTicksChange'
> & {
  unit: WeightUnit;
  tickSize: number;
  value: number | null;
  onChange: (ticks: number | null) => void;
  hideUnit?: boolean;
};

// TODO(2316): Enable Unit and TickSize selection
export default function QuantizedWeightTicksInput(
  props: QuantizedWeightTicksInputProps,
) {
  const {
    unit,
    tickSize,
    value,
    onChange,
    hideUnit = false,
    hideControls = true,
    ...rest
  } = props;

  return (
    <TickInput
      tickSize={tickSize}
      hideControls={hideControls}
      rightSection={
        hideUnit ? undefined : (
          <Text size='sm' color='dimmed'>
            {getWeightUnitAbbreviation(unit)}
          </Text>
        )
      }
      ticks={value}
      onTicksChange={onChange}
      {...rest}
    />
  );
}
