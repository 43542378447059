/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RedWaveLinearMassModelCreationDTO } from '../models/RedWaveLinearMassModelCreationDTO';
import type { RedWaveMaterialClassMetadataCreationDTO } from '../models/RedWaveMaterialClassMetadataCreationDTO';
import type { RedWaveProfileRevisionPatchDTO } from '../models/RedWaveProfileRevisionPatchDTO';
import type { RedWaveSortProgramSnapshotDTO } from '../models/RedWaveSortProgramSnapshotDTO';
import type { RedWaveSortSystemDTO } from '../models/RedWaveSortSystemDTO';
import type { RedWaveSystemMetricsDTO } from '../models/RedWaveSystemMetricsDTO';
import type { RedWaveSystemMetricsSnapshotDTO } from '../models/RedWaveSystemMetricsSnapshotDTO';
import type { RedWaveSystemSortProgramProfileRevisionDTO } from '../models/RedWaveSystemSortProgramProfileRevisionDTO';
import type { RedWaveTelemetryDTO } from '../models/RedWaveTelemetryDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RedWaveService {
    /**
     * @returns RedWaveSortSystemDTO Success
     * @throws ApiError
     */
    public static getRedWaveSystems(): CancelablePromise<Array<RedWaveSortSystemDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redwave/systems',
        });
    }
    /**
     * @param systemGuid
     * @returns RedWaveSortSystemDTO Success
     * @throws ApiError
     */
    public static getRedWaveSystemById(
        systemGuid: string,
    ): CancelablePromise<RedWaveSortSystemDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redwave/systems/{systemGuid}',
            path: {
                'systemGuid': systemGuid,
            },
        });
    }
    /**
     * @param systemGuid
     * @returns RedWaveSortProgramSnapshotDTO Success
     * @throws ApiError
     */
    public static getRedWaveCurrentSortProgramSnapshots(
        systemGuid: string,
    ): CancelablePromise<Array<RedWaveSortProgramSnapshotDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redwave/systems/{systemGuid}/sort-programs',
            path: {
                'systemGuid': systemGuid,
            },
        });
    }
    /**
     * @param systemGuid
     * @param sortProgramNumberInt
     * @param timestamp
     * @returns RedWaveSortProgramSnapshotDTO Success
     * @throws ApiError
     */
    public static getRedWaveSortProgramSnapshot(
        systemGuid: string,
        sortProgramNumberInt: number,
        timestamp?: string,
    ): CancelablePromise<RedWaveSortProgramSnapshotDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redwave/systems/{systemGuid}/sort-programs/{sortProgramNumberInt}',
            path: {
                'systemGuid': systemGuid,
                'sortProgramNumberInt': sortProgramNumberInt,
            },
            query: {
                'timestamp': timestamp,
            },
        });
    }
    /**
     * @param profileRevisionGuid
     * @returns RedWaveSystemSortProgramProfileRevisionDTO Success
     * @throws ApiError
     */
    public static getRedWaveProfileRevisionById(
        profileRevisionGuid: string,
    ): CancelablePromise<RedWaveSystemSortProgramProfileRevisionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redwave/profile-revisions/{profileRevisionGuid}',
            path: {
                'profileRevisionGuid': profileRevisionGuid,
            },
        });
    }
    /**
     * @param profileRevisionGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchRedWaveProfileRevision(
        profileRevisionGuid: string,
        requestBody: RedWaveProfileRevisionPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/redwave/profile-revisions/{profileRevisionGuid}',
            path: {
                'profileRevisionGuid': profileRevisionGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param systemGuid
     * @param timestamp
     * @returns RedWaveSortProgramSnapshotDTO Success
     * @throws ApiError
     */
    public static getActiveRedWaveSortProgramSnapshot(
        systemGuid: string,
        timestamp?: string,
    ): CancelablePromise<RedWaveSortProgramSnapshotDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redwave/systems/{systemGuid}/sort-programs/active',
            path: {
                'systemGuid': systemGuid,
            },
            query: {
                'timestamp': timestamp,
            },
        });
    }
    /**
     * @param profileRevisionGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createRedWaveMaterialClassMetadata(
        profileRevisionGuid: string,
        requestBody: RedWaveMaterialClassMetadataCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/redwave/profile-revisions/{profileRevisionGuid}/material-class-metadata',
            path: {
                'profileRevisionGuid': profileRevisionGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param materialClassMetadataGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteRedWaveMaterialClassMetadata(
        materialClassMetadataGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/redwave/material-class-metadata/{materialClassMetadataGuid}',
            path: {
                'materialClassMetadataGuid': materialClassMetadataGuid,
            },
        });
    }
    /**
     * @param systemGuid
     * @param start
     * @param end
     * @returns RedWaveTelemetryDTO Success
     * @throws ApiError
     */
    public static getRedWaveSystemTelemetry(
        systemGuid: string,
        start: string,
        end?: string,
    ): CancelablePromise<RedWaveTelemetryDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redwave/systems/{systemGuid}/telemetry',
            path: {
                'systemGuid': systemGuid,
            },
            query: {
                'start': start,
                'end': end,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @param systemGuid
     * @param start
     * @param end
     * @returns RedWaveSystemMetricsDTO Success
     * @throws ApiError
     */
    public static getRedWaveSystemMetrics(
        systemGuid: string,
        start: string,
        end?: string,
    ): CancelablePromise<RedWaveSystemMetricsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redwave/systems/{systemGuid}/metrics',
            path: {
                'systemGuid': systemGuid,
            },
            query: {
                'start': start,
                'end': end,
            },
        });
    }
    /**
     * @param systemGuid
     * @returns RedWaveSystemMetricsSnapshotDTO Success
     * @throws ApiError
     */
    public static getRedWaveSystemLatestMetrics(
        systemGuid: string,
    ): CancelablePromise<RedWaveSystemMetricsSnapshotDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redwave/systems/{systemGuid}/latest-metrics',
            path: {
                'systemGuid': systemGuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createOrUpdateLinearMassModel(
        requestBody: RedWaveLinearMassModelCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/redwave/linear-mass-models',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
