import { createStyles, getStylesRef } from '@mantine/core';

export default createStyles(() => {
  return {
    iconDelete: {
      ref: getStylesRef('iconDelete'),
      visibility: 'hidden',
    },
    row: {
      [`&:hover .${getStylesRef('iconDelete')}`]: {
        visibility: 'visible',
      },
    },
  };
});
