import { Loader, Select, SelectProps } from '@mantine/core';
import { useAllInternalMaterialSinks } from '../api/internalMaterialSink';
import { InternalMaterialSinkId } from '../rest-client';

export interface InternalMaterialSinkSpecificProps {
  value: InternalMaterialSinkId | null;
  onChange: (id: InternalMaterialSinkId | null) => void;
}

export type InternalMaterialSinkSelectProps = Omit<
  SelectProps,
  keyof InternalMaterialSinkSpecificProps | 'data' | 'rightSection'
> &
  InternalMaterialSinkSpecificProps;

export function InternalMaterialSinkSelect(
  props: InternalMaterialSinkSelectProps,
) {
  const {
    value,
    onChange,
    disabled = false,
    nothingFound = 'No material export destinations found',
    placeholder = 'Select material export destination',
    ...selectProps
  } = props;

  const sinksQuery = useAllInternalMaterialSinks();
  if (sinksQuery.isLoadingError) {
    throw sinksQuery.error;
  }

  const sinks = sinksQuery.data;

  return (
    <Select
      data={
        sinks !== undefined
          ? sinks.map(({ id, name }) => ({ label: name, value: id }))
          : []
      }
      value={value}
      onChange={onChange}
      placeholder={sinksQuery.isLoading ? '' : placeholder}
      disabled={sinksQuery.isLoading || disabled}
      nothingFound={nothingFound}
      rightSection={sinksQuery.isLoading ? <Loader size='xs' /> : undefined}
      {...selectProps}
    />
  );
}
