import { Skeleton, Text } from '@mantine/core';
import {
  SoldMaterialName,
  SoldMaterialNameProps,
} from '../Repository/RepositoryName';
import { useSoldMaterial } from '../api/soldMaterial';

export type SoldMaterialIdNameProps = Omit<
  SoldMaterialNameProps,
  'soldMaterial'
> & {
  soldMaterialId: string;
};

export function SoldMaterialIdName(props: SoldMaterialIdNameProps) {
  const { soldMaterialId, ...rest } = props;
  const { data: soldMaterial, isError } = useSoldMaterial(soldMaterialId);

  if (soldMaterial) {
    return <SoldMaterialName soldMaterial={soldMaterial} {...rest} />;
  }

  if (isError) {
    return <Text color='red'>Error getting name</Text>;
  }

  return (
    <Skeleton visible>
      <Text>Loading name...</Text>
    </Skeleton>
  );
}
