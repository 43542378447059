import { Loader, Table, useMantineTheme } from '@mantine/core';
import { LinkText } from '../Link';
import { TableEmptyBasicContent } from '../TableEmptyBasicContent';
import { useCommodities } from '../api/commodity';
import { Router } from '../router';

export function CommodityTable() {
  const theme = useMantineTheme();
  const {
    data: commodities,
    isLoading,
    isLoadingError,
    error,
  } = useCommodities();

  if (isLoadingError) {
    throw error;
  }

  const isEmpty = commodities && commodities.length === 0;

  return (
    <Table>
      {(isLoading || isEmpty) && (
        <caption
          style={{
            captionSide: 'bottom',
            textAlign: 'center',
            padding: theme.spacing.md,
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && isEmpty && (
            <TableEmptyBasicContent>No Commodities</TableEmptyBasicContent>
          )}
        </caption>
      )}
      <thead>
        <tr>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {commodities?.map((c) => (
          <tr key={c.id}>
            <td>
              <LinkText to={Router.CommodityDetail({ commodityId: c.id })}>
                {c.name}
              </LinkText>
            </td>
          </tr>
        )) ?? (
          <tr>
            <td colSpan={1}>
              <Loader />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
