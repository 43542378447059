/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MaterialClassSetCompositionCreationDTO } from '../models/MaterialClassSetCompositionCreationDTO';
import type { MaterialClassSetCompositionDTO } from '../models/MaterialClassSetCompositionDTO';
import type { MaterialClassSetCompositionPatchDTO } from '../models/MaterialClassSetCompositionPatchDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MaterialClassSetCompositionService {
    /**
     * @param compositionGuid
     * @returns MaterialClassSetCompositionDTO Success
     * @throws ApiError
     */
    public static getComposition(
        compositionGuid: string,
    ): CancelablePromise<MaterialClassSetCompositionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/material-class-set-compositions/{compositionGuid}',
            path: {
                'compositionGuid': compositionGuid,
            },
        });
    }
    /**
     * @param compositionGuid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static patchComposition(
        compositionGuid: string,
        requestBody?: MaterialClassSetCompositionPatchDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/material-class-set-compositions/{compositionGuid}',
            path: {
                'compositionGuid': compositionGuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param compositionGuid
     * @returns any Success
     * @throws ApiError
     */
    public static deleteComposition(
        compositionGuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/material-class-set-compositions/{compositionGuid}',
            path: {
                'compositionGuid': compositionGuid,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createComposition(
        requestBody: MaterialClassSetCompositionCreationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/material-class-set-compositions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
